import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class MwFeedFtue extends Component {
  @action
  openFeedback() {
    dispatcher.dispatch('feedback', 'openFeedbackDialog');
  }
}
