import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MobileSidebarButton extends Component {
  @action
  onDestroy() {
    this.removeOverlayClickHandler();
  }

  addOverlayClickHandler() {
    if (!this.toggleMobileSidebarBind) this.toggleMobileSidebarBind = this.toggleMobileSidebar.bind(this);
    document.querySelector('.app-dragover-overlay').addEventListener('click', this.toggleMobileSidebarBind);
  }

  removeOverlayClickHandler() {
    this.args.updateMobileSidebarVisible(false);

    const el = document.querySelector('.app-dragover-overlay');
    el?.classList.remove('active');
    el?.removeEventListener('click', this.toggleMobileSidebarBind);
  }

  @action
  toggleMobileSidebar() {
    const isVisible = !this.args.mobileSidebarVisible;

    this.args.updateMobileSidebarVisible(isVisible);

    const dragOverlay = document.querySelector('.app-dragover-overlay');

    if (isVisible) {
      dragOverlay.classList.add('active');
      this.addOverlayClickHandler();
    } else {
      dragOverlay.classList.remove('active');
      this.removeOverlayClickHandler();
    }
  }
}
