import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { on } from '@ember/modifier';

export default class ApplicationController extends Controller {
  @service('dynamic-dialogs') dialogs;

  @service('dynamic-popups') popups;

  @action
  switchCodeVersion(e) {
    e.preventDefault();
    this.dynamicDialogs.openDialog('code-version-dialog');
  }

  @action
  setupKeyListeners() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  @action
  cleanupKeyListeners() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  @action
  handleKeyDown(event) {
    // Check if Command (metaKey) and Shift are pressed along with the '\' key (whose keyCode is 220)
    if (event.metaKey && event.shiftKey && event.keyCode === 220) {
      this.dialogs.openDialog('code-version-dialog');
    }
  }

  constructor() {
    super(...arguments);
    this.setupKeyListeners();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.cleanupKeyListeners();
  }
}
