import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import AccountApi from 'mewe/api/account-api';

export default class SetNewPassword extends Component {

  @tracked requestStatus = null;
  @tracked revokeSessions = false;
  @tracked password = '';
  @tracked passwordError = false;
  @tracked passwordRepeat = '';
  @tracked passwordRepeatError = false;

  passwordRegex = /^[ \S]{8,}$/i;

  @action
  validatePassword() {
    this.passwordError = !this.passwordRegex.test(this.password);

    if (this.passwordRepeat.length) {
      this.validatePasswordRepeat();
    }
  }

  @action
  validatePasswordRepeat() {
    this.passwordRepeatError = this.password !== this.passwordRepeat;
  }

  @action
  submitPasswordReset(e) {
    e.preventDefault();

    let token = this.args.resetToken;
    let isSmsFlow = token === 'sms-code';

    if (isSmsFlow) {
      token = window.location.hash?.slice(1);
    }

    if (!token) {
      window.location = '/login';
    }

    this.validatePassword();
    this.validatePasswordRepeat();

    if (!this.passwordError && !this.passwordRepeatError) {
      let params = {};

      if (isSmsFlow) {
        params.code = token;
        params.newPassword = this.passwordRepeat;

        // this request also logs user into the app
        AccountApi.setPassword(params, this.revokeSessions)
          .then(() => {
            window.location = '/myworld';
          })
          .catch(() => {
            this.requestStatus = 'failure';
          });
      } else {
        params.secret = token;
        params.password = this.passwordRepeat;

        AccountApi.setPasswordEmail(params, this.revokeSessions)
          .then(() => {
            this.requestStatus = 'success';
          })
          .catch(() => {
            this.requestStatus = 'failure';
          });
      }
    }

    return false;
  }
}
