import GroupStore from 'mewe/stores/group-store';
import Storage from 'mewe/shared/storage';
import { gifUrlRegExp } from 'mewe/utils/gif-utils';
import { hexToRGB } from 'mewe/utils/miscellaneous-utils';
import { chatMaxUsers, ChatEventTypes, ChatFilter } from 'mewe/constants';
import twitterText from 'twitter-text';
import { escape, each } from 'lodash';
import { debug } from '@ember/debug';
import EmberObject from '@ember/object';

export default {
  // updating newMessage of thread in LS
  setComposedMsgs({ threadId, text = '', mentions }) {
    let msgsData = this.getComposedMsgs() || {};

    if (text.trim() === '') delete msgsData[threadId];
    else msgsData[threadId] = { text: text, mentions: mentions };

    Storage.set(Storage.keys.composedMsgsData, JSON.stringify(msgsData));
  },

  getComposedMsgs() {
    let msgsData;
    try {
      msgsData = JSON.parse(Storage.get(Storage.keys.composedMsgsData));
      return msgsData ? msgsData : null;
    } catch (error) {
      debug(error.message);
    }
  },

  textForEmpty: function (message) {
    var attachmentType = '';

    if (message.deleted && !message.expiresIn) {
      return __('This message was deleted');
    }

    if (message.isMyMessage) {
      if (message.sticker) {
        return __('You sent a sticker');
      } else if (message.attachments && message.get('attachments.length')) {
        if (message.expiresIn) {
          switch (message.disappearingContentType) {
            case 'mewe_me':
              return __('You sent a Me');
            case 'mewe_we':
              return __('You sent a We');
            case 'mewe_dc':
              return __('You sent a disappearing message');
            default:
              return __('You sent a disappearing message');
          }
        }

        attachmentType = message.attachments[0].aType;
        switch (attachmentType) {
          case 'doc':
            return __('You sent a file');
          case 'photo':
            if (message.attachments[0].animated) return __('You sent a GIF');
            else return __('You sent a photo');
            break;
          case 'video':
            return __('You sent a video');
          case 'audio':
            return __('You sent a voice message');
        }
      } else {
        if (message.textServer.match(gifUrlRegExp)) {
          return __('You sent a GIF');
        }

        if (message.privacyMailId) {
          return __('You sent a private post');
        }

        let links = twitterText.extractUrlsWithIndices(message.textServer, {
          extractUrlsWithoutProtocol: true,
        });

        if (links && links.length) {
          return __('You sent a link');
        }
      }
    } else {
      var authorName = '';
      var author = message.computedAuthor;

      if (author) {
        // TODO - temporarily, otherwise it raises an error, but it doesn't solves the problem itself
        authorName = author.name;
        if (
          typeof authorName !== 'undefined' &&
          authorName !== null &&
          typeof authorName === 'string' &&
          authorName.length > 0
        ) {
          authorName = authorName.split(/[ ,]+/);
        } else if (
          typeof author.get === 'function' &&
          typeof author.get('name') !== 'undefined' &&
          author.get('name') !== null &&
          typeof author.get('name') === 'string' &&
          author.get('name').length > 0
        ) {
          authorName = author.get('name').split(/[ ,]+/);
        } else {
          // just to err on the safe side ...
          // authorName = ['A friend']
          // TODO - or shall we rather return an empty string?
          return '';
        }

        if (authorName && authorName.length) {
          authorName = authorName[0];
        }
      }

      if (!authorName || authorName.length === 0) {
        return;
      }

      authorName = escape(authorName);

      if (message.sticker) {
        return __('{userName} sent a sticker', {
          userName: authorName,
        });
      } else if (message.attachments && message.get('attachments.length')) {
        if (message.expiresIn) {
          switch (message.disappearingContentType) {
            case 'mewe_me':
              return __('{userName} sent a Me', {
                userName: authorName,
              });
            case 'mewe_we':
              return __('{userName} sent a We', {
                userName: authorName,
              });
            case 'mewe_dc':
              return __('{userName} sent a disappearing message', {
                userName: authorName,
              });
            default:
              return __('{userName} sent a disappearing message', {
                userName: authorName,
              });
          }
        }

        attachmentType = message.attachments[0].aType;
        switch (attachmentType) {
          case 'doc':
            return __('{userName} sent a file', {
              userName: authorName,
            });
          case 'photo':
            if (message.get('attachments')[0].animated)
              return __('{userName} sent a GIF', {
                userName: authorName,
              });
            else
              return __('{userName} sent a photo', {
                userName: authorName,
              });
            break;
          case 'video':
            return __('{userName} sent a video', {
              userName: authorName,
            });
          case 'audio':
            return __('{userName} sent a voice message', {
              userName: authorName,
            });
        }
      } else {
        if (message.textServer.match(gifUrlRegExp)) {
          return __('{userName} sent a GIF', {
            userName: authorName,
          });
        }

        if (message.privacyMailId) {
          return __('{userName} sent a private post', {
            userName: authorName,
          });
        }

        let links = twitterText.extractUrlsWithIndices(message.textServer, {
          extractUrlsWithoutProtocol: true,
        });

        if (links && links.length) {
          return __('{userName} sent a link', {
            userName: authorName,
          });
        }
      }
    }
  },

  textForChatEvent(message, ChatStore) {
    const eventType = message.eventType;

    let text = '',
      addText,
      thread;

    if (eventType) {
      switch (eventType) {
        case ChatEventTypes.CHATRENAMED:
          return message.get('text.length')
            ? __('Chat name was changed: {name}', { name: message.get('text') })
            : __('Chat name was deleted');

        case ChatEventTypes.USERJOINED:
          addText = (user) => {
            if (text.length) text += ', ';
            text += (user && user.name) || __('MeWe member');
          };

          if (message.get('participation.added.length')) {
            each(message.get('participation.added'), (user) => {
              if (user.name) addText(user);
              else {
                thread = ChatStore.getThreadById(message.threadId);
                addText(thread.getParticipant(user.id));
              }
            });
          } else {
            addText();
          }

          return __('{name} joined the chat', { name: text });

        case ChatEventTypes.USERLEFT:
          addText = (user) => {
            text += __('{name} left the chat', { name: (user && user.name) || __('MeWe member') });
          };

          if (message.get('participation.removed')) {
            if (message.get('participation.removed.name')) addText(message.get('participation.removed'));
            else {
              thread = ChatStore.getThreadById(message.threadId);
              addText(thread.getParticipant(message.get('participation.removed.id')));
            }
          } else {
            addText();
          }

          return text;
      }
    }
  },

  canOpenSmallChat(CurrentUserStore, authorId, isChatPage, doNotOpenSmallChatIfIAmTheAuthor, message) {
    const userState = CurrentUserStore.getState(),
      isMyMessage = authorId === userState.id,
      autoOpenChat = userState.autoOpenMiniChat != false; // treat undefined as true until backend has been deployed

    let isMsgFromMutedGroup = false;
    if (message?.group?.id) {
      const group = GroupStore.getState({ id: message.group.id });
      if (group && group.groupMuted) isMsgFromMutedGroup = true;
    }

    return (
      ((!isMyMessage && autoOpenChat) || (!doNotOpenSmallChatIfIAmTheAuthor && isMyMessage)) &&
      !isChatPage &&
      !isMsgFromMutedGroup
    );
  },

  uploadCancelAlert(dynamicDialogs, callback) {
    dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Confirm navigation'),
      message: __(`You haven't finished your message. Do you really want to leave without finishing?`),
      cancelButtonText: __('Stay'),
      okButtonText: __('Leave'),
      onConfirm: () => {
        if (callback && typeof callback === 'function') callback();
      },
      allowMultipleInstances: false,
    });
  },

  chatLimitExceeded(dynamicDialogs) {
    dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Chat error'),
      message: __('Sorry, you cannot add more than {limit} people to a single chat.', { limit: chatMaxUsers }),
      okButtonText: __('OK'),
    });
  },

  chatParticipantObj(userE) {
    return userE.getProperties('_links', 'firstName', 'name', 'id', 'status');
  },

  participantsToObj(users) {
    // change to simple javascript object as Ember objects are not casting anymore to json object - changed in Ember 3.9
    return users.map((u) => {
      if (u instanceof EmberObject) {
        return this.chatParticipantObj(u);
      }

      return u;
    });
  },
};

export const readChatFilter = () => {
  const filter = Storage.get(Storage.keys.chatFilter);
  return filter || ChatFilter.ALL;
};
