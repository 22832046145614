import layout from './template.hbs';
import './styles.scss';
import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getOffset } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';
import { action } from '@ember/object';

export default class HeaderChatsDropdown extends PopupOpener {
  layout = layout;

  popupType = 'dropdown-popup';

  closeOnHoverOutside = true;

  delayUntilOpen = 200;

  get dropdownPosition() {
    let position = this.getDefaultDropdownOptions();
    const parentOffset = getOffset(this.parent);

    Object.assign(position, {
      margin: 10,
      reposition: { elementWidth: 300 },
      parentOffset: {
        top: this.parent.offsetTop,
        left: parentOffset.left,
      },
      placement: {
        top: false,
        center: true,
        positionType: 'fixed',
      },
    });

    return position;
  }

  @action
  createNewChat() {
    dispatcher.dispatch('chat', 'createNewChat');
  }

  @action
  markAllAsRead() {
    dispatcher.dispatch('chat', 'markAllAsRead');
  }
}
