import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import groupsApi from 'mewe/api/group-api';
import { A } from '@ember/array';
import { keys } from 'mewe/shared/keys';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import { toGroup } from 'mewe/fetchers/utils';

export default class MwSuggestedGroups extends Component {

  @service dynamicDialogs;

  @tracked opened = this.getInitialOpenedState();
  @tracked groups = A();

  getInitialOpenedState() {
    const opened = localStorage.getItem(keys.groupSuggestionsBannerOpened);
    return opened === null ? true : opened === 'true';
  }

  @action toggle() {
    this.opened = !this.opened;
    localStorage.setItem(keys.groupSuggestionsBannerOpened, this.opened);
  }

  @action chooseInterests() {
    openFtueRecommendations(this.dynamicDialogs, 'myworld-feed', () => {
      this.fetchSuggestedGroups();
    });
  }

  @action fetchSuggestedGroups() {
    groupsApi.getSuggestedGroups().then((response) => {
      this.groups = A(response.results.map((el) => toGroup(el.group)));
    });
  }

  @action
  groupClicked(group) {
    let context = this.args.context === 'all' ? 'home_recommendations' : 'communities_recommendations';
    showGroupPreviewDialog(this.dynamicDialogs, group, {
      isGroupPreview: true,
      context,
      callback: () => {
        this.fetchSuggestedGroups();
      },
    });
  }

}
