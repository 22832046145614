import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class MwEvents extends Component {
  @service router;
  @service dynamicDialogs;

  @reads('args.group') group;

  @tracked isEventPickerOpened;

  @computed('group')
  get calendarLink() {
    return this.group ? 'app.group.calendar.index' : 'app.events.index';
  }

  @computed('group')
  get upcomingLink() {
    return this.group ? 'app.group.calendar.upcoming' : 'app.events.upcoming';
  }

  @computed('group')
  get invitedLink() {
    return this.group ? 'app.group.calendar.invited' : 'app.events.invited';
  }

  @computed('group')
  get pastLink() {
    return this.group ? 'app.group.calendar.past' : 'app.events.past';
  }

  @computed('group')
  get hostingLink() {
    return this.group ? 'app.group.calendar.hosting' : 'app.events.hosting';
  }

  @computed('group')
  get birthdaysLink() {
    return this.group ? 'app.group.calendar.birthdays' : 'app.events.birthdays';
  }

  @computed('router.currentRouteName')
  get shouldShowCalendar() {
    var routeName = this.router.currentRouteName;
    return routeName == 'app.events.index' || routeName == 'app.group.calendar.index';
  }

  @computed('group.canCreateEvent', 'args.scope')
  get canCreateEvent() {
    return this.args.scope === 'events' || this.group.canCreateEvent;
  }

  @action
  openEventCreation() {
    let params = {};

    if (this.group) {
      params.groupId = this.args.scope;
    }

    this.dynamicDialogs.openDialog('event-create-dialog', params);
  }

  @action
  toggleEventPicker() {
    this.isEventPickerOpened = !this.isEventPickerOpened;
  }
}
