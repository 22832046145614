import Component from '@glimmer/component';
import { action, computed, set } from '@ember/object';

export default class MwEdit extends Component {
  @computed
  get title() {
    return __('Edit');
  }

  @action
  click() {
    set(this.args.post, 'isEditing', true);
    this.args.closePostDropdown?.();
  }
}
