import { helper as buildHelper } from '@ember/component/helper';
import config from 'mewe/config';

import CurrentUserStore from 'mewe/stores/current-user-store';

function getPrimaryEmail() {
  const state = CurrentUserStore.getState();

  if (!state || !state.primaryEmail || state.primaryEmail === '') return false;

  return (
    state.primaryEmail.indexOf('@sgrouples.com') !== -1 ||
    state.primaryEmail.indexOf('@mewe.com') !== -1 ||
    state.primaryEmail.indexOf('@fakemail.groupl.es') !== -1 ||
    state.primaryEmail.indexOf('@groupl.es') !== -1 ||
    state.primaryEmail === 'mewenews@gmail.com'
  );
}

export function isMeWeTeam() {
  return config.environment === 'local' || getPrimaryEmail();
}

export default buildHelper(isMeWeTeam);
