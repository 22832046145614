import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Home/MwHomeHeaderButton',
  component: 'MwHomeHeaderButton',
};

const Template = () => ({
  template: hbs`<Home::MwHomeHeaderButton @text="Sign Up" />`,
  context: {},
});

export const Default = Template.bind({});
