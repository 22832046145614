import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';
import { addObserver, removeObserver } from '@ember/object/observers';

import { postsPerPage } from 'mewe/constants';
import { escape } from 'lodash';
import Scrolling from 'mewe/utils/scrolling-utils';
import { getFeed } from 'mewe/fetchers/fetch-feed';
import GroupApi from 'mewe/api/group-api';
import { deserializeAndStoreAuthors } from 'mewe/fetchers/fetch-feed';
import { Post } from 'mewe/stores/models/post-model';
import Contact from 'mewe/stores/models/contact-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import dispatcher from 'mewe/dispatcher';

export default class MwSearchResults extends Component {
  @service chat;
  @service router;
  @service account;

  @reads('args.group') group;
  @reads('args.group.id') groupId;

  @tracked members = A();
  @tracked membersLoading = false;
  @tracked searchFeed;

  searchDelay = 300;
  lastSearchTerm = '';
  resultsPerPage = postsPerPage;
  highlightStart = '<span class="word-highlight">';
  highlightEnd = '</span>';

  serializer = JSONSerializer.create();

  constructor() {
    super(...arguments);

    this.globals = getOwner(this).lookup('globals:main');

    this.scrolling = Scrolling();

    this.searchFeed = getFeed('group-posts-search', 'group-posts-search');

    addObserver(this, 'args.currentRouteName', this.routeChanged);
    addObserver(this, 'args.searchTerm', this.searchTermChanged);

    this.searchTermChanged(); // trigger first search
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    this.scrolling.unbindScrollDown(this.getMemberScrollElem());

    removeObserver(this, 'args.currentRouteName', this.routeChanged);
    removeObserver(this, 'args.searchTerm', this.searchTermChanged);
  }

  routeChanged() {
    if (!this.group.isFetching) {
      if (document.activeElement) document.activeElement.blur();
      this.args.searchTermChanged('');
    }
  }

  searchTermChanged() {
    const term = this.args.searchTerm;

    // this prevents reloading results on focusOut from search input
    if (term && term === this.lastSearchTerm) {
      return;
    } else {
      this.lastSearchTerm = term;
    }

    this.resetSearchData();

    clearTimeout(this.timeoutId);

    if (term) {
      this.timeoutId = setTimeout(() => {
        this.searchPosts(term);
        this.searchMembers(term);
      }, this.searchDelay);
    }
  }

  resetSearchData() {
    this.members.clear();
    this.membersLoading = false;

    this.searchFeed.setProperties({
      isFetching: false,
      canBeMorePosts: false,
      postsOffset: 0,
      posts: A(),
    });
  }

  searchMembers(searchTerm) {
    if (this.group.isUniversal) return; // we don't allow group search in MeWe Group
    if (this.membersLoading) return;

    if (!searchTerm) {
      this.closeGroupSearchResults();
      return;
    }

    const groupId = this.groupId;
    const params = {
      str: searchTerm,
      offset: this.members.length,
      maxResults: this.resultsPerPage,
      byJoin: true,
    };

    this.membersLoading = true;

    GroupApi.membersSearch(groupId, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) return;
        if (this.groupId !== groupId) return;
        if (params.str !== this.args.searchTerm) return;

        let members = data.members.map((member) => {
          let user = Object.assign(member, member.user);
          delete user.user;
          return this.serializer.deserializeOne(Contact, user);
        });


        this.members.pushObjects(members);
        this.members = this.members.uniqBy('id');

        if (members?.length >= params.maxResults) {
          this.scrolling.bindScrollDownElement(this.getMemberScrollElem(), () => this.searchMembers(params.str));
        } else {
          this.scrolling.unbindScrollDown(this.getMemberScrollElem());
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.membersLoading = false;
      });
  }

  searchPosts(searchTerm) {
    if (!searchTerm) {
      this.closeGroupSearchResults();
      return;
    } else if (searchTerm.length < 3) return;

    const feed = getFeed('group-posts-search', 'group-posts-search');

    if (feed.isFetching) return;

    const params = {
      query: searchTerm,
      groupId: this.groupId,
      maxResults: this.resultsPerPage,
      offset: feed.postsOffset || 0, // offset can be different than posts.length due to duplication removal
      highlighting: true,
    };

    feed.set('isFetching', true);

    GroupApi.postsSearch(params)
      .then((data) => {
        if (this.args.searchTerm !== searchTerm) return;
        if (this.groupId !== params.groupId) return;

        if (data.postsData && data.postsData.users) {
          deserializeAndStoreAuthors(data.postsData.users);
        }

        if (data.results.length) {
          feed.posts.pushObjects(this.processPosts(data.results, feed));
          feed.incrementProperty('postsOffset', data.results.length);
        }

        if (data.hasMoreResults) {
          this.scrolling.bindScrollDown(() => this.searchPosts(params.query), 500);
        } else {
          this.scrolling.unbindScrollDown();
        }
      })
      .finally(() => feed.set('isFetching', false));
  }

  processPosts(results, feed) {
    const currentResults = feed.posts;
    let searchResults = A();

    results.map((current) => {
      let post = current.post;

      post.scope = 'group';
      post.group = this.group.getProperties('id', 'name', 'color');

      // checking for duplicated posts - SG-15833
      const existingPost = currentResults.find((c) => c.postItemId === post.postItemId);
      if (!existingPost) {
        searchResults.push(post);
      }
    });

    // checking for duplicated posts - SG-15833
    searchResults = searchResults.uniqBy('postItemId');

    let posts = A();

    const PostThemed = Post.extend({
      postScope: 'group',
    });

    this.serializer.deserializeMany(posts, PostThemed, searchResults);

    return posts;
  }

  @computed('searchFeed.isFetching', 'searchFeed.posts.length')
  get noSearchPosts() {
    return !this.searchFeed.posts?.length && !this.searchFeed.isFetching;
  }

  @computed('members.length', 'membersLoading')
  get noSearchMembers() {
    return !this.members.length && !this.membersLoading;
  }

  @computed('args.searchTerm')
  get showInputMoreText() {
    return this.args.searchTerm && this.args.searchTerm.length < 3;
  }

  @computed('args.searchTerm')
  get searchText() {
    return __('Showing results for {searchTerm}', {
      searchTerm: '<span class="color-app">' + escape(this.args.searchTerm) + '</span>',
    });
  }

  getMemberScrollElem() {
    return document.querySelector('.group-search-results_members .group-search-results_wrapper');
  }

  @action
  closeResults() {
    this.args.searchTermChanged('');
    this.scrolling.unbindScrollDown();
  }

  @action
  openProfile(user) {
    this.closeResults();
    this.router.transitionTo('app.group.members.profile', this.group.id, user.id);
  }

  @action
  chatWith(user) {
    this.chat.openThreadByParticipants([user]);
  }

  @action
  openHashTagFromTop(tag) {
    this.router.transitionTo('app.group.index.feed', this.group.id, { queryParams: { tag: tag } });
    this.closeResults();
  }

  @action
  doFollow(searchMember) {
    dispatcher.dispatch('contact', 'followUser', { user: searchMember });
  }
}
