import { A } from '@ember/array';
import { each } from 'lodash';

import Member from 'mewe/stores/models/member-model';
import GroupApi from 'mewe/api/group-api';
import GroupUtils from 'mewe/utils/group-utils-light';
import { maxResultsMembersList } from 'mewe/constants';
import MemberBase from '../app-group/member-base';
import { next } from '@ember/runloop';

//TODO: Need to refactor the code
export default class AppGroupMembersAdmins extends MemberBase {
  deserializeMembers(json, isFromSearch) {
    if (isFromSearch) {
      return undefined;
    }

    let members = A();
    let data = A();

    each(json, function (m) {
      let user = m.user || m;

      if (m.role) {
        user.role = { name: m.role.name || m.role }; // search results have different format than regular listing
      } else if (!user.role) {
        user.role = { name: 'Admin' };
      }
      user.confirmed = true;
      user.canSendMessage = m.canSendMessage;
      user.isGroupMember = true;
      user.publicLinkId = m.publicLinkId;

      if (user && user.role) {
        if (user.role.name === 'Admin' || user.role.name === 'Owner') {
          user.registered = true;
        }
      }

      data.push(user);
    });

    // eslint-disable-next-line ember/require-tagless-components
    let MemberWithGroup = Member.extend({
      group: this.group,
    });
    this.serializer.deserializeMany(members, MemberWithGroup, data);

    return members;
  }

  loadMembers() {
    if (this.loading) {
      return;
    }

    const membersLen = this.members.length || 0,
      params = {
        offset: membersLen ? membersLen - 1 : membersLen, // current user is added manually and should not count
        maxResults: maxResultsMembersList,
      };

    next(() => {
      this.loading = true;
    });

    GroupApi.supermembers(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        // add current user which is not included in response but only while first fetching
        const member = GroupUtils.getCurrentUserGroupMember(this.group);
        const currentUser = data.supermembers.find((u) => u.id === member.user.id);

        if (!currentUser && !membersLen && (this.group.role === 'Admin' || this.group.role === 'Owner')) {
          data.supermembers.unshift(member);
        }

        this.members.pushObjects(this.deserializeMembers(data.supermembers));

        if (data.supermembers.length >= maxResultsMembersList) {
          //This is temporary solution due to that BE of this API will be refactored in SG-35429
          //Currently API return all of admins at once
          //Offset will be supported later
          // this.scrolling.bindScrollDown(() => this.loadMembers());
        } else {
          this.scrolling.unbindScrollDown();
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }
}
