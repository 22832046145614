import { next } from '@ember/runloop';
import { debug } from '@ember/debug';
import fuHelper from 'mewe/utils/fileupload-utils';
import { isMac } from 'mewe/shared/utils';
import VideoApi from 'mewe/api/video-api';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import toServer from 'mewe/stores/text-parsers/to-server';
import PS from 'mewe/utils/pubsub';
import PostCommons from 'mewe/utils/posting-utils';
import axios from 'axios';
import {
  videoMaxWeightToUploadToServer,
  videoMaxWeightToUploadToServerBig,
  usersAllowedToUploadBiggerVideo,
} from 'mewe/constants';
import config from 'mewe/config';

const CancelToken = axios.CancelToken;

class Video {
  constructor(parent) {
    this.postbox = parent;
    this.uploadStatus = null;
    this.jqXHR = null;
    this.postType = 'video';
    this.uploadFolder = null;
    this.videoUploaded = false;
    this.videoBlobUrl = null;
    this.file = null;
  }

  handleVideo({ files }) {
    let file = files[0],
      fileType = file.type;

    const limit = usersAllowedToUploadBiggerVideo.includes(this.postbox.account.activeUser.id)
      ? videoMaxWeightToUploadToServerBig
      : videoMaxWeightToUploadToServer;

    // checking file size
    if (file && file.size >= limit) {
      fuHelper.showDefaultFileErrorMessage();
      return false;
    }

    if (fileType === '') {
      FunctionalUtils.error(__('Wrong video file format.'));
      return false;
    }

    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    this.setupPreview(file);

    this.file = file;

    let params = {
        doc: {
          title: file.name,
        },
        length: file.size,
      },
      async = false;

    VideoApi.authorize(params, async)
      .then((retdata) => {
        this.uploadVideo(file, retdata);
      })
      .catch((jqXHR) => {
        if (jqXHR.data && jqXHR.data.errorCode === 700) {
          this.postbox.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
          this.removeVideo();
        }
      });
  }

  uploadVideo(file, retdata) {
    // if (!retdata.sessionToken) now what?

    let formData = new FormData();

    formData.append('AWSAccessKeyId', retdata.awsAccessKeyId);
    formData.append('signature', retdata.signature);
    formData.append('policy', retdata.policy);
    formData.append('acl', retdata.acl);
    formData.append('key', retdata.key);
    formData.append('success_action_status', retdata.successActionStatus);
    formData.append('x-amz-security-token', retdata.sessionToken);

    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url:
        config.environment == 'local' ? '/video-proxy' : 'https://' + retdata.instanceBucketName + '.s3.amazonaws.com',
      method: 'POST',
      cancelToken: source.token,
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          let progress = parseInt((data.loaded / data.total) * 100, 10);

          if (progress < 1) progress = 1;

          this.postbox.video.progress = progress;

          this.uploadStatus = progress;
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(res.data);
      })
      .catch((e) => {
        this.fail(e.response);
      });

    this.postbox.video.name = file.name;
    this.postbox.video.loading = true;
    this.postbox.video.fail = false;

    this.jqXHR = source;
    this.postbox.uploadXhr = source;
    this.file = file;
    this.uploadFolder = retdata.uploadFolder;

    this.postbox.updateHeight();
  }

  fail(response) {
    if (response && response.data.jqXHR && response.data.jqXHR.status !== 401) {
      if (this.postbox.isDestroying || this.postbox.isDestroyed) {
        return;
      }

      if (response && response.data.errorThrown === 'abort') {
        FunctionalUtils.info(__('Your video upload has been canceled.'), 'warn');
      } else {
        debug(response && response.data);
        FunctionalUtils.error(__("Sorry, your video couldn't be uploaded"));
      }

      this.postbox.video.fail = true;

      this.cleanAfter(true, true);
    }
  }

  done() {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }
    this.postbox.video.loading = false;
    this.videoUploaded = true;
  }

  setupPreview(file) {
    let canPlayVideos = document.createElement('video');
    canPlayVideos = canPlayVideos && canPlayVideos.canPlayType && canPlayVideos.canPlayType(file.type);
    canPlayVideos = canPlayVideos === 'probably' || canPlayVideos === 'maybe';

    if (file.type === 'video/quicktime' && isMac()) {
      canPlayVideos = true;
    }

    if (canPlayVideos) {
      this.postbox.videoPreviewSrc = URL.createObjectURL(file);
      this.postbox.videoPreviewPlaceholder = false;
    } else {
      this.postbox.videoPreviewSrc = false;
      this.postbox.videoPreviewPlaceholder = true;
    }
  }

  cancel() {
    this.cleanAfter(true);
  }

  removeVideo() {
    this.videoUploaded = false;

    if (this.file) this.postbox.uploadService.remove(this.file.uploadId);

    this.file = null;

    if (this.videoBlobUrl) {
      window.URL.revokeObjectURL(this.videoBlobUrl);
    }

    this.postbox.video.loading = null;
    this.postbox.video.progress = null;
    this.postbox.video.fail = null;

    this.postbox.updateHeight();
  }

  isEmpty() {
    return !this.file && this.postbox.video.loading !== true;
  }

  cleanAfter(dontDestroyFileupload, dontResetText) {
    if (!dontResetText) {
      this.postbox.newPost = '';
      this.postbox.newPostServer = '';
    }

    this.removeVideo();

    if (this.jqXHR) {
      this.jqXHR.cancel();
    }
  }

  shareController(extraParams) {
    if (this.postbox.isSharing) return;

    if (this.videoUploaded) {
      this.postbox.isSharing = true;

      let groupId = this.postbox.group?.id;

      let sendToMyWorld = groupId === 'contacts';

      const params = Object.assign(
        {},
        {
          uploadFolder: this.uploadFolder,
          fileName: this.file.name,
          contacts: sendToMyWorld,
          mime: this.file.type,
        },
        extraParams
      );

      if (this.postbox.newPostServer) {
        params.text = this.postbox.newPostServer;
      }

      if (groupId && !sendToMyWorld) {
        params.groupIds = [groupId];
      }

      if (this.postbox.eventId) {
        params.eventIds = [this.postbox.eventId];
      }

      if (this.postbox.threadIds) {
        params.threadIds = this.postbox.threadIds;
      }

      if (params.text) {
        params.text = toServer(params.text);

        if (this.postbox.mentionsStrategy) {
          params.text = this.postbox.mentionsStrategy.getEncodedText(params.text);
        }
      }

      if (this.postbox.page?.id) {
        params.pageId = this.postbox.page.id;
        params.postedByPage = this.postbox.postedByPage || this.postbox.page.isOwnerAdmin; // we still have to check for owner/ admin - SG-20923
      }

      if (this.postbox.presetEmojisList?.length) {
        params.emojisPreset = this.postbox.presetEmojisList;
      }

      const albumName = this.postbox.selectedAlbumName;
      if (albumName && albumName.length > 0) params.album = albumName;

      if (this.postbox.postingType == 'everyone') params.everyone = true;

      if (this.postbox.postingType == 'friends') params.closeFriends = true;

      if (this.postbox.link) params.link = this.postbox.linkController.getLinkData();

      const scope = PostCommons.scope(this.postbox);

      VideoApi.process(params, scope)
        .then(() => {
          FunctionalUtils.info(__('Your video is being processed and will be ready in a few minutes.'));

          this.postbox.close(true);
          this.cleanAfter(true);
          if (this.videoBlobUrl) {
            window.URL.revokeObjectURL(this.videoBlobUrl);
          }
          if (params.album) {
            PS.Pub('photoAlbums.reload', params.album);
          }
        })
        .catch((data) => {
          if (data.data && data.data.errorCode === 700) storageAlert(this);
          else if (!data.data && data.status === 0)
            FunctionalUtils.showErrorRetryPost(() => this.shareController(params));
          else FunctionalUtils.showDefaultErrorMessage();
        })
        .finally(() => {
          if (this.postbox.isDestroying || this.postbox.isDestroyed) return;
          this.postbox.alwaysAfterPosting();
        });
    } else {
      FunctionalUtils.error(__('Upload video first'));
    }
  }
}

export default Video;
