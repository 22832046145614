import Component from '@glimmer/component';
import { addObserver, removeObserver } from '@ember/object/observers';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Theme } from 'mewe/constants';
import Scrolling from 'mewe/utils/scrolling-utils';
import { fetchMorePagesFeatured } from 'mewe/fetchers/fetch-pages';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';

const EL_SCROLLABLE_CLASS = '.featured-list_wrapper';

export default class MwSidebarFeaturedPages extends Component {
  @service dynamicDialogs;

  @action
  onInsert(element) {
    this.element = element;
    this.scrolling = Scrolling();
    this.bindScrolling();

    addObserver(this, 'args.featured.nextPage', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector(EL_SCROLLABLE_CLASS));
    removeObserver(this, 'args.featured.nextPage', this.bindScrolling);
  }

  bindScrolling() {
    if (this.args.featured.nextPage) {
      this.scrolling.bindScrollDownElement(
        this.element.querySelector(EL_SCROLLABLE_CLASS),
        () => {
          fetchMorePagesFeatured();
        },
        200
      );
    } else {
      this.scrolling.unbindScrollDown(this.element.querySelector(EL_SCROLLABLE_CLASS));
    }
  }

  @action
  openDialog() {
    this.dynamicDialogs.openDialog('browse-communities-dialog', {
      theme: Theme.PAGE,
    });
  }

  @action
  openInterestsPicker() {
    openFtueRecommendations(this.dynamicDialogs, 'sidebar-pages');
  }
}
