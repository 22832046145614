import Component from '@glimmer/component';
import { action } from '@ember/object';
import { A } from '@ember/array';
import { each } from 'lodash';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';

import Scrolling from 'mewe/utils/scrolling-utils';
import MediafeedApi from 'mewe/api/mediafeed-api';
import PS from 'mewe/utils/pubsub';

export default class MwFilesStream extends Component {
  //order: 0, // 1 - ASC oldest -> newest = first ==> last, 0 - DESC last ==> first, newest --> oldest
  maxResults = 20;

  scrolling = Scrolling();

  @tracked loading = false;
  @tracked files = A();

  constructor() {
    super(...arguments);

    this.reloadFilesBind = this.reloadFiles.bind(this);

    this.renderView();
    this.bindPubSub();

    addObserver(this.args, 'order', this.reloadFilesBind);
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.parentNode);
    this.unbindPubSub();
    removeObserver(this.args, 'order', this.reloadFilesBind);
  }

  bindPubSub() {
    this.chatMsWsHandlerBind = this.chatMsWsHandler.bind(this);
    PS.Sub('chat.message.add', this.chatMsWsHandlerBind);
    this.chatAttachmentDeletedBind = this.chatAttachmentDeleted.bind(this);
    PS.Sub('chat.attachment.del', this.chatAttachmentDeletedBind);
    this.postAddBind = this.postAdd.bind(this);
    PS.Sub('documents.reload', this.reloadFilesBind);
    PS.Sub('post.add', this.postAddBind);
  }

  unbindPubSub() {
    PS.Unsub('chat.message.add', this.chatMsWsHandlerBind);
    PS.Unsub('chat.attachment.removed', this.chatAttachmentDeletedBind);
    PS.Unsub('documents.reload', this.reloadFilesBind);
    PS.Unsub('post.add', this.postAddBind);
  }

  chatMsWsHandler(msg) {
    const threadId = msg.threadId || (msg.group ? msg.group.id : msg.event ? msg.event.id : null);

    if (threadId && threadId === this.args.threadId && msg.attachments.length) {
      each(msg.attachments, (a) => {
        // SG-15543 don't add audio att as it can be voice msg which we don't show in files stream
        // photos also aren't included in files stream
        if (a.aType === 'audio' || a.aType === 'photo') return;

        let date = Math.floor(new Date().getTime() / 1000);
        let doc = {
          createdAt: date,
          editedAt: date,
          id: a.fileObjectId,
          owner: {
            id: msg.author.userId,
            name: msg.author.name,
          },
          document: {
            id: a.fileObjectId,
            name: a.fileName,
            _links: a._links,
          },
        };

        if (this.args.order === 0) {
          this.files.unshiftObject(doc);
        } else if (this.args.order === 1 && !this.nextPageUrl) {
          //only push when list is fully loaded
          this.files.pushObject(doc);
        }
      });
    }
  }

  chatAttachmentDeleted(msg) {
    each(msg.attachments, (att) => {
      const deletedFile = this.files.find((f) => f.document.id === att.fileObjectId);
      this.files.removeObject(deletedFile);
    });
  }

  renderView() {
    if (this.loading) return;

    this.loading = true;

    const params = {
      threadId: this.args.threadId,
      nextPage: this.nextPageUrl,
      maxResults: this.maxResults,
      order: this.args.order,
      mediaTypes: 'doc,audio',
    };

    const thenCb = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      let files = data.media || data.feed;

      this.files.pushObjects(files);
      this.files = this.files.uniqBy('id');
      this.nextPageUrl = data._links?.nextPage?.href;

      if (files.length === this.maxResults) {
        this.scrolling.bindScrollDownElement(this.element.parentNode, () => {
          this.renderView();
        });
      } else {
        this.scrolling.unbindScrollDown(this.element.parentNode);
      }
    };

    const finallyCb = () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.loading = false;
    };

    MediafeedApi.getGalleryDataForChatImg(params).then(thenCb).finally(finallyCb);
  }

  postAdd(data) {
    if (data?.post?.files) {
      this.reloadFiles();
    }
  }

  reloadFiles() {
    this.nextPageUrl = null;
    this.files = A();

    this.renderView();
  }
}
