/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { scheduleOnce } from '@ember/runloop';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import EmberObject, { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import GroupUtils from 'mewe/utils/group-utils-light';
import GroupApi from 'mewe/api/group-api';
import EventsApi from 'mewe/api/events-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class ChatInfoMembers extends Component {
  @reads('args.thread') thread;
  @service account;
  @service dynamicPopups;

  @tracked searchTerm = '';
  @tracked users = A();
  @tracked isLoading;
  @tracked dropdownId = '';
  @tracked parent = null;
  @tracked popupSelectedUser = null;

  offset = 0;
  maxResults = 20;
  scrolling = Scrolling();

  @action
  onInsert(element) {
    this.element = element;
    this.bindPS();
    this.openProfilePopupBind = this.openProfilePopup.bind(this);

    addObserver(this, 'args.chatInfoMembersActive', this.membersPanelToggled);
    addObserver(this, 'args.thread.id', this.threadChanged);
    addObserver(this, 'searchTerm', this.searchTermChanged);
  }

  @action
  onDestroy() {
    PS.Unsub('group.member.remove', this.userRemovedFromGroupBind);
    PS.Unsub('chat.participants.changed', this.chatParticipantsChangedBind);
    this.unbindScrollDown();

    removeObserver(this, 'args.chatInfoMembersActive', this.membersPanelToggled);
    removeObserver(this, 'args.thread.id', this.threadChanged);
    removeObserver(this, 'searchTerm', this.searchTermChanged);
  }

  bindPS() {
    this.userRemovedFromGroupBind = this.userRemovedFromGroup.bind(this);
    PS.Sub('group.member.remove', this.userRemovedFromGroupBind);
    this.chatParticipantsChangedBind = this.chatParticipantsChanged.bind(this);
    PS.Sub('chat.participants.changed', this.chatParticipantsChangedBind);
  }

  @computed('args.skin', 'thread.{isGroupChat,isEventChat,isMultiUsersChat}')
  get showMembersSearch() {
    return (
      this.args.skin === 'chat-window' &&
      (this.thread.isGroupChat || this.thread.isEventChat || this.thread.isMultiUsersChat)
    );
  }

  membersPanelToggled() {
    if (this.args.chatInfoMembersActive) {
      this.initUsersList();
    } else {
      this.searchTerm = '';
    }
  }

  threadChanged() {
    this.offset = 0;
    this.users = A();
    this.searchTerm = '';

    this.membersPanelToggled();
  }

  searchTermChanged() {
    clearTimeout(this.timeoutId);

    const loadUsers = () => {
      if (this.isDestroyed || this.isDestroying) return;
      if (!this.args.chatInfoMembersActive) return;

      this.offset = 0;
      this.users = A();
      this.isLoading = true;
      this.initUsersList();
    };

    if (this.searchTerm.length) {
      this.timeoutId = setTimeout(loadUsers, 500);
    } else {
      loadUsers();
    }
  }

  initUsersList() {
    const thread = this.thread;

    if (!thread) return;

    const bindScrollDown = (fn) => this.scrolling.bindScrollDownElement(this.element.querySelector('ul'), () => fn());

    this.unbindScrollDown();

    if (thread.isGroupChat) {
      if (!this.users.length) this.fetchGroupMembers();
      else scheduleOnce('afterRender', this, () => bindScrollDown(() => this.fetchGroupMembers()));
    } else if (thread.isMultiUsersChat) {
      this.setThreadParticipants();
    } else if (thread.isEventChat) {
      if (!this.users.length) this.fetchEventAttendees();
      else scheduleOnce('afterRender', this, () => bindScrollDown(() => this.fetchEventAttendees()));
    }
  }

  setThreadParticipants() {
    let activeParticipants = this.thread.participants
      .filter((p) => p.id && p.status !== 'removed')
      .map((p) => {
        p.profileId = p.publicLinkId;
        // we no longer have this field from BE, also there is no info about 'following' state
        //p.canSendMessage = p.isContact;
        return EmberObject.create(p);
      });

    const searchTerm = this.searchTerm.trim();

    if (searchTerm.length) {
      activeParticipants = activeParticipants.filter(
        (p) => p.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      );
    }

    this.users = A(activeParticipants);
    this.isLoading = false;
  }

  fetchUsers(callApi, callSearchApi, dataToUsers, fetchMore) {
    const thread = this.thread;

    this.isLoading = true;

    const params = {
        offset: this.offset,
        maxResults: this.maxResults,
      },
      searchTerm = this.searchTerm;

    const thenCb = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      let users = dataToUsers(data);

      if (params.offset === 0) {
        this.users.setObjects(users);
      } else {
        this.users.pushObjects(users);
      }

      if (!params.offset && !searchTerm) {
        // add current user which is not included in response for group members
        if (thread.isGroupChat) {
          const currentUser = GroupUtils.getCurrentUserGroupMember(thread.group);
          currentUser.user.isCurrentUser = currentUser.user;
          this.users.unshiftObject(currentUser.user);
        }
      }

      this.offset += users.length;

      if (users.length === params.maxResults) {
        this.scrolling.bindScrollDownElement(this.element.querySelector('ul'), () => fetchMore());
      } else {
        this.unbindScrollDown();
      }
    };

    const finallyCb = () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.isLoading = false;
    };

    if (searchTerm) {
      if (thread.isGroupChat) params.str = searchTerm;
      else if (thread.isEventChat) params.query = searchTerm;

      callSearchApi(params).then(thenCb).finally(finallyCb);
    } else {
      if (thread.isEventChat) {
        params.withOwner = true;
      }
      callApi(params).then(thenCb).finally(finallyCb);
    }
  }

  fetchGroupMembers() {
    const fetch = (params) => GroupApi.members(this.thread.group?.id, params),
      fetchSearch = (params) => GroupApi.membersSearch(this.thread.group?.id, params),
      dataToUsers = (data) => {
        if (data.members) {
          return data.members.map((member) => {
            member.user.canRemove = this.thread.group?.isOwnerAdmin && member.role.name !== 'Owner';
            member.user.canSendMessage = member.canSendMessage;
            member.user.profileId = member.user.publicLinkId;
            return member.user;
          });
        }
      };

    this.fetchUsers(fetch, fetchSearch, dataToUsers, () => this.fetchGroupMembers());
  }

  fetchEventAttendees() {
    const fetch = (params) => EventsApi.getParticipants(this.thread.event?.id, 'attending', params),
      fetchSearch = (params) => EventsApi.searchParticipants(this.thread.event?.id, 'attending', params),
      dataToUsers = (data) => {
        if (data.participants) {
          return data.participants.map((p) => {
            p.participant.canSendMessage = p.following && p.follower;
            p.participant.profileId = p.participant.publicLinkId;
            return p.participant;
          });
        }
      };

    this.fetchUsers(fetch, fetchSearch, dataToUsers, () => this.fetchEventAttendees());
  }

  userRemovedFromGroup(data = {}) {
    if (!data.userId || !data.groupId) return;
    if (this.thread?.group?.id !== data.groupId) return;

    const user = this.users.find((u) => u.id === data.userId);

    if (user) {
      this.users.removeObject(user);
      this.thread.group.decrementProperty('membersCount');
    }
  }

  chatParticipantsChanged(threadId) {
    if (this.thread.id === threadId) {
      this.setThreadParticipants();
    }
  }

  unbindScrollDown() {
    this.scrolling.unbindScrollDown(this.element.querySelector('ul'));
  }

  openProfilePopup(user) {
    if (user.id === this.account.activeUser.id) return;
    if (this.isDestroyed && !this.isDestroying) return;

    this.dynamicPopups.openPopup('mw-profile-popup', {
      parent: this.element.querySelector(`.chat-info-member-${user.id}`),
      owner: user.get ? user : EmberObject.create(user),
      group: this.thread.group,
      insideAnotherScrollable: true,
    });
  }

  @action
  closeOptionsPopup() {
    this.dropdownId = '';
    this.parent = null;
    this.popupSelectedUser = null;
  }

  @action
  openOptionsPopup(user, id) {
    const className = 'dropdown-opener--chat-info-member-dropdown';
    const svgs = Array.from(this.element.getElementsByClassName(className));
    const dropdownId = `${className}-${id}`;
    const dropdownElement = this.element.querySelector('.chat-info-member-dropdown');
    let parent;

    if (svgs.length) {
      parent = svgs.find((svg) => {
        return parseInt(svg.getAttribute('data-id'), 10) === id;
      });
    }

    if (dropdownElement) {
      if (dropdownId === dropdownElement.getAttribute('data-dropdownid')) return;
    }

    this.dropdownId = dropdownId;
    this.parent = parent;
    this.popupSelectedUser = user;
  }

  @action
  closeSearch() {
    this.searchTerm = '';
  }
}
