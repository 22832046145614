import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import MentionsParser from 'mewe/stores/text-parsers/mentions-parser';
import MarkdownParser from 'mewe/stores/text-parsers/markdown-parser';
import { escape } from 'lodash';

// WARNING - IT CAN BE USED ONLY IN NOTIFICATION CENTER!!! (MW)
// ok, also in user profile dialog ... (MW)

export default buildHelper((value, options) => {
  let text = value[0];
  let emojiOptions = { pngSpriteSize: options.pngSpriteSize || 16 };

  return htmlSafe(
    EmojisParser.toDisplay(
      MentionsParser.toDisplay(
        MarkdownParser.toDisplay(options.escape ? escape(text) : text, {}),
        emojiOptions
      )
    )
  );
});
