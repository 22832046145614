import { A } from '@ember/array';
import GroupsApi from 'mewe/api/group-api';
import EventsApi from 'mewe/api/events-api';
import PagesApi from 'mewe/api/pages-api';
import ContactsApi from 'mewe/api/contacts-api';
import MentionsApi from 'mewe/api/mentions-api';
import EnvUtils from 'mewe/utils/environment-utils';
import PostUtils from 'mewe/utils/post-utils';
import MathUtils from 'mewe/utils/math-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import ChatApi from 'mewe/api/chat-api';
import { Theme, mentionAutocompleteMaxResults as maxResults, chatMaxUsers, maxMentionsInText } from 'mewe/constants';
import { serverTo_server } from 'mewe/utils/emoji-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import { each, escape, sortBy, uniq, compact } from 'lodash';

const TRESHOLD = 350;

// https://stackoverflow.com/questions/863800/replacing-diacritics-in-javascript#5960633
// TODO: may need to treat 'aa', 'ae' etc as one letter for search to work properly
let defaultDiacriticsRemovalMap = [
  {
    base: 'a',
    letters:
      /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
  },
  { base: 'aa', letters: /[\uA733]/g },
  { base: 'ae', letters: /[\u00E6\u01FD\u01E3]/g },
  { base: 'ao', letters: /[\uA735]/g },
  { base: 'au', letters: /[\uA737]/g },
  { base: 'av', letters: /[\uA739\uA73B]/g },
  { base: 'ay', letters: /[\uA73D]/g },
  { base: 'b', letters: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
  { base: 'c', letters: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
  { base: 'd', letters: /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
  { base: 'dz', letters: /[\u01F3\u01C6]/g },
  {
    base: 'e',
    letters:
      /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
  },
  { base: 'f', letters: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
  {
    base: 'g',
    letters: /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
  },
  {
    base: 'h',
    letters: /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
  },
  { base: 'hv', letters: /[\u0195]/g },
  {
    base: 'i',
    letters:
      /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
  },
  { base: 'j', letters: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
  { base: 'k', letters: /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
  {
    base: 'l',
    letters:
      /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
  },
  { base: 'lj', letters: /[\u01C9]/g },
  { base: 'm', letters: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
  {
    base: 'n',
    letters:
      /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
  },
  { base: 'nj', letters: /[\u01CC]/g },
  {
    base: 'o',
    letters:
      /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
  },
  { base: 'oi', letters: /[\u01A3]/g },
  { base: 'ou', letters: /[\u0223]/g },
  { base: 'oo', letters: /[\uA74F]/g },
  { base: 'p', letters: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
  { base: 'q', letters: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
  {
    base: 'r',
    letters:
      /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
  },
  {
    base: 's',
    letters:
      /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
  },
  {
    base: 't',
    letters: /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
  },
  { base: 'tz', letters: /[\uA729]/g },
  {
    base: 'u',
    letters:
      /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
  },
  { base: 'v', letters: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
  { base: 'vy', letters: /[\uA761]/g },
  { base: 'w', letters: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
  { base: 'x', letters: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
  {
    base: 'y',
    letters: /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
  },
  { base: 'z', letters: /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g },
];

function removeDiacritics(str) {
  each(defaultDiacriticsRemovalMap, (m) => {
    str = str.replace(m.letters, m.base);
  });

  return str;
}

export default {
  createTextCompleteStrategy: function (scope, textHolder, profileUser) {
    var self = this,
      existingMentions = self.parseMentions(textHolder),
      textComplete = {
        id: 'mention',
        mentions: existingMentions || A(),
      },
      mentionSearchFunc; // used by strategy.search

    if (
      !scope ||
      scope === Theme.PROFILE ||
      scope === Theme.CONTACTS ||
      scope === Theme.PRIVATEPOSTS ||
      scope === 'myworld' ||
      scope === Theme.GROUPS ||
      scope.groupId === Theme.CONTACTS ||
      scope.groupId === Theme.GROUPS
    ) {
      if (profileUser) {
        mentionSearchFunc = (term, callback) => {
          callback([{ user: profileUser }]);
        };
      } else {
        // adding post to myworld, suggest user's contacts
        mentionSearchFunc = self.createContactsSearch();
      }
    } else if (scope.eventId) {
      mentionSearchFunc = self.createEventSearch(scope.eventId);
    } else if (scope.groupId) {
      mentionSearchFunc = self.createGroupSearch(scope.groupId);
    } else if (scope.pageId) {
      mentionSearchFunc = (term, callback) => callback(A());
      // TODO: add when backend is ready: mentionSearchFunc = self.createPageSearch(scope.pageId, scope.postItemId);
    } else if (scope.privacyPost) {
      mentionSearchFunc = self.createPrivacymailSearchFromPost(scope.privacyPost);
    } else if (scope.users) {
      mentionSearchFunc = self.createChatSearch(scope.users);
    }

    textComplete.strategy = {
      // @ followed by two space-separated words
      id: 'mention',
      scope: scope,
      search: mentionSearchFunc,
      template: function (mention) {
        if (mention) {
          let name, avatarUrl;

          if (mention.user) {
            name = mention.user.name;

            let links = mention._links || mention.user._links;

            if (links) {
              avatarUrl = EnvUtils.getImgHost(true) + links.avatar.href.replace('{imageSize}', '150x150');
            } else {
              avatarUrl = EnvUtils.getImgHost(true) + '/api/v2/photo/profile/150x150/' + mention.user.id;
            }
          }

          return (
            '<img src="' +
            avatarUrl +
            '" class="mention-avatar"><span data-testid="notify-mention">' +
            '@' +
            escape(name) +
            '</span>'
          );
        } else {
          return '';
        }
      },
    };

    // TODO: need caret position to know where replacement was done, then can sort by caret pos
    textComplete.getEncodedText = function (encoded) {
      var sorted = sortBy(textComplete.mentions, function (u) {
        if (!u.name) return;
        return -u.name.length;
      });

      each(sorted, function (u) {
        if (!u.name) return;

        u.name = self.prepareName(u.name);
        encoded = encoded.replace('@' + u.name, '@{{u_' + u.id + '}' + u.name + '}');
      });

      return encoded;
    };

    return textComplete;
  },

  isMentionsLimitReached(mentions = [], text) {
    let count = 0;

    if (mentions && mentions.length && text) {
      mentions = uniq(mentions.map((mention) => this.prepareName(mention.name)));

      text = this.prepareText(text);

      each(mentions, function (name) {
        if (text.indexOf('@' + name) !== -1) count++;
      });
    }

    return count >= maxMentionsInText;
  },

  prepareText(text) {
    // SG-20514 - user name with emoji set on android has unicode emoji and text to parse has semicolon notation emojis :shortname:
    return toServer(serverTo_server(text), {
      parseNativeMarkdown: true,
    });
  },

  prepareName(name) {
    // SG-20514 - user name with emoji set on android can have empty character after emoji that must be removed
    return serverTo_server(name.replace(String.fromCharCode(65039), ''));
  },

  getPostMentionsScope: function (post) {
    const { scope, scopeId } = PostUtils.getPostScopeAndId(post);
    let mentionsScope = {};

    if (scope === Theme.EVENT) {
      mentionsScope.eventId = scopeId;
    } else if (scope === Theme.GROUP) {
      mentionsScope.groupId = scopeId;
    } else if (scope === Theme.PRIVATEPOSTS) {
      mentionsScope.privacyPost = post;
    } else if (scope === Theme.CONTACTS) {
      mentionsScope = Theme.CONTACTS;
    } else if (scope === Theme.PAGE) {
      mentionsScope.pageId = scopeId;
      mentionsScope.postItemId = post.postItemId;
    }

    return mentionsScope;
  },

  // private functions
  createChatSearch: function (userData = []) {
    var self = this;

    userData = userData.map((user) => {
      return {
        user: user,
      };
    });

    return function (term, callback, offset = 0) {
      if (term) {
        self.mentionCommonCallback(term, callback)(userData);
      } else {
        callback(userData.slice(offset, offset + maxResults));
      }
    };
  },

  createContactsSearch: function () {
    var self = this,
      searchTimeoutId,
      currentSearchApiCall;

    return function (term, callback, offset = 0) {
      var params = {
        maxResults: maxResults,
        offset: offset,
        withCurrentUser: false,
      };

      // TODO: abstract into function so can be reused elsewhere and implement own memoize function for all search terms and use it instead of textcomplete's
      clearTimeout(searchTimeoutId);
      searchTimeoutId = null;
      if (currentSearchApiCall) {
        currentSearchApiCall = null;
      }

      if (term) {
        searchTimeoutId = setTimeout(function () {
          currentSearchApiCall = MentionsApi.search({ query: term })
            .then((respData) =>
              self.mentionCommonCallback(term, (data) => {
                callback(data);
              })(respData.results)
            )
            .catch(() => callback(A()));
        }, TRESHOLD);
      } else {
        // for contacts we return empty for empty term
        callback([]);
      }
    };
  },

  createGroupSearch: function (groupId) {
    const self = this;
    let searchTimeoutId;

    return function (term, callback, offset = 0) {
      const params = {
        maxResults: maxResults,
        offset: offset,
        withCurrentUser: false,
      };

      clearTimeout(searchTimeoutId);

      searchTimeoutId = setTimeout(function () {
        if (term) {
          params.query = term;
          params.groupId = groupId;

          MentionsApi.search(params)
            .then((data) => {
              return self.mentionCommonCallback(term, function (userResults) {
                let groupAvatarsAdded = userResults.map(function (userData) {
                  if (userData.user._links.avatar.href.indexOf('group=') === -1) {
                    userData.user._links.avatar.href += '&group=' + params.groupId;
                  }
                  return userData;
                });

                callback(groupAvatarsAdded);
              })(data.results);
            })
            .catch(() => callback(A()));
        } else {
          params.onlyOwnerAdmins = true;
          GroupsApi.members(groupId, params)
            .then((data) => {
              let users = data.members.filter((u) => u.confirmed);
              callback(users);
            })
            .catch(() => callback(A()));
        }
      }, TRESHOLD);
    };
  },

  createEventSearch: function (eventId) {
    var self = this,
      searchTimeoutId;

    return function (term, callback, offset = 0) {
      var params = {
          maxResults: maxResults,
          offset: offset,
          withCurrentUser: false,
          withOwner: true,
        },
        currentUserId = CurrentUserStore.getState().get('id'),
        getParticipants = (data) => {
          let users = compact(
            data.participants.map((p) => {
              if (p.participant.id === currentUserId) return;

              p.participant.user = p.participant;
              return p.participant;
            })
          );

          return users;
        };

      if (term) {
        params.query = term;

        clearTimeout(searchTimeoutId);

        searchTimeoutId = setTimeout(function () {
          EventsApi.searchMentionParticipants(eventId, params)
            .then((data) => {
              self.mentionCommonCallback(term, callback)(getParticipants(data));
            })
            .catch(() => callback(A()));
        }, TRESHOLD);
      } else {
        EventsApi.getParticipants(eventId, 'attending', params)
          .then((data) => callback(getParticipants(data)))
          .catch(() => callback(A()));
      }
    };
  },

  /*
    A: Who can be mentioned by follower in the comment? - any page, or other users that commented the post before
    B: Who can be mentioned by admin in the post? This page or other pages only
    C: Who can be mentioned by owner in the post? This page or other pages only
    D : Who can be mentioned by admin in the comment? The page, other pages or users that commented that post.
    E: Who can be mentioned by owner in the comment? The page, other pages or users that commented that post.
*/
  createPageSearch: function (pageId, postItemId) {
    var self = this,
      searchTimeoutId,
      oldSearchResults,
      oldSearchTerm = null;

    return function (term, callback, offset = 0) {
      var params = {
        maxResults: maxResults,
        offset: offset,
        withCurrentUser: false,
        postItemId: postItemId,
      };

      if (term) {
        params.query = term;

        clearTimeout(searchTimeoutId);

        searchTimeoutId = setTimeout(function () {
          PagesApi.commentedFollowersSearch(pageId, params)
            .then((data) =>
              self.mentionCommonCallback(term, (userResults = []) => {
                var avatarsAdded = userResults.map(function (userData) {
                  if (userData.user._links.avatar.href.indexOf('page=') === -1) {
                    userData.user._links.avatar.href += '&page=' + params.pageId;
                  }
                  return userData;
                });
                callback(avatarsAdded);
              })(data.results)
            )
            .catch(() => callback(A()));
        }, TRESHOLD);
      } else {
        PagesApi.commentedFollowers(pageId, params)
          .then((data) => {
            let users = data.followers;
            callback(users);
          })
          .catch(() => callback(A()));
      }
    };
  },

  createPrivacymailSearchFromPost: function (post) {
    const self = this;
    let allParticipantsFetched = false;
    let participants;

    return function (term, callback) {
      var currentUserId = CurrentUserStore.getState().get('id'),
        cb = () => {
          if (term) self.mentionCommonCallback(term, callback)(participants);
          else callback(participants);
        };

      if (!allParticipantsFetched) {
        ChatApi.getReceivers(post.threadId, { maxResults: chatMaxUsers })
          .then((data) => {
            allParticipantsFetched = true;

            // filter out current user, you cannot mention yourself
            data.receivers = data.receivers.filter((r) => {
              return r.id !== currentUserId;
            });
            participants = data.receivers.map((d) => {
              return { user: d };
            });
            cb();
          })
          .catch(() => callback(A()));
      } else {
        cb();
      }
    };
  },

  // filters only those that have names or handles that contain param term
  mentionCommonCallback: function (term, callback) {
    return function (data) {
      var lcTerm = term.toLowerCase(),
        lcUserName,
        lcUserHandle,
        users = data || [],
        currentUserId = CurrentUserStore.getState().get('id');

      users = users.filter((u) => {
        if (u.user) u = u.user;

        lcUserName = u.name ? u.name.toLowerCase() : '';
        lcUserHandle = u.publicLinkId ? u.publicLinkId.toLowerCase() : '';

        return (
          u.id !== currentUserId &&
          (lcUserName.indexOf(lcTerm) !== -1 ||
            removeDiacritics(lcUserName).indexOf(lcTerm) !== -1 ||
            lcUserHandle.indexOf(lcTerm) !== -1)
        );
      });

      callback(users);
    };
  },

  parseMentions: function (textHolder) {
    var foundMentions = A();

    if (!textHolder) {
      return foundMentions;
    }

    let plainText = textHolder.textServer;

    if (plainText) {
      foundMentions = plainText.match(/\@\{\{([a-zA-Z0-9_]{8,26}?)\}(.{1,100}?)\}/g);
      if (!foundMentions) {
        return A();
      }
      // keep them all in the same spot, just rename .users to .mentions or something, as order is important
      foundMentions = foundMentions.map(function (m) {
        var idPartName = m.match(/\@\{\{([mu]?)_([a-zA-Z0-9]{8,24}?)\}(.{1,100}?)\}/);

        if (!idPartName) return m;

        var idPrefix = idPartName[1],
          idSuffix = idPartName[2],
          name = idPartName[3],
          mention;

        switch (idPrefix) {
          case 'u':
            mention = {
              id: idSuffix,
              name: name,
            };
            break;
        }

        return mention;
      });
    }

    return foundMentions;
  },

  stripMentions: function (text) {
    if (!text) return '';
    return text.replace(/\@\{\{(.*?)\}(.*?)\}/gi, '@' + '$2');
  },

  //"@{{u_55746d9bd4c63de5492deb6f}Karol MeWe}" ==> <a class='mention' href='#' data-userid='55746d9bd4c63de5492deb6f'>@Karol MeWe</a>);
  extractMentions: function (text, options, template) {
    if (!text) return '';

    if (!template) {
      template = (prefix, baseUrl, id, name, dataId) => {
        switch (prefix) {
          case 'u':
            return `<a class='h-mention' href='${baseUrl + getHref(id)}' data-userid='${dataId}'>@${name}</a>`;
        }
      };
    }

    let getHref = (userId) => {
        if (userId) {
          return '/profile/' + userId;
        } else {
          return '/myworld';
        }
      },
      baseUrl = '';

    if (options) {
      if (options.eventId) {
        getHref = (userId) => {
          let href = '/event/' + options.eventId;

          return userId ? href + '/attendees/profile/' + userId : href;
        };
      } else if (options.groupId && options.groupId !== 'contacts') {
        let href = '/group/' + options.groupId;

        getHref = (userId) => {
          return userId ? href + '/members/profile/' + userId : href;
        };
      }
    }

    return text.replace(/\@\{\{([mu]?)_([a-zA-Z0-9]{8,24}?)\}(.{1,100}?)\}/gi, (text, prefix, id, name) => {
      if (name && id) {
        let dataId = options.eventId || (options.groupId && options.groupId != 'contacts') ? id : 'id=' + id;

        return template(prefix, baseUrl, id, name, dataId);
      }

      return name || text;
    });
  }
};
