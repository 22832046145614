import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import dispatcher from 'mewe/dispatcher';

export default class MwStoreItem extends Component {
  @service purchase;

  buyNowExcluded = ['donation', 'storage'];
  addToCartExcluded = ['donation', 'storage', 'page'];

  get item() {
    return this.args.item;
  }

  get buyActionText() {
    if (this.item.id === 'page') {
      return __('Buy Now');
    } else {
      return __('Add to Cart');
    }
  }

  get isPremiumItem() {
    return this.item.id === 'premium';
  }

  get showPremiumPeriodOptions() {
    return this.item.canBuy && this.isPremiumItem;
  }

  get showBuyNowOption() {
    return !~this.buyNowExcluded.indexOf(this.item.id);
  }

  get showAddToCartOption() {
    return !~this.addToCartExcluded.indexOf(this.item.id);
  }

  get priceRangeString() {
    // premium is exception - don't show the price range but the price for selected product
    if (this.item.id === 'premium') return this.item.priceComputed;
    // page is exception - item might contain multiple products but those are multiple page subscriptions, there is just one price
    if (this.item.id === 'page') return this.item.priceComputed;
    if (this.item.products.length === 1) return this.item.priceComputed;

    if (this.item.products.length) {
      // list of numerical prices sorted ascending
      const prices = this.item.products.map((p) => parseFloat(p.price)).sort((a, b) => a - b);
      return `${prices[0]} - ${prices[prices.length - 1]}`;
    }
  }

  @action
  openItemDetails() {
    dispatcher.dispatch('purchase', 'showItemDetails', this.item.id, { context: 'store_list' });
  }

  @action
  toggleItemInCart() {
    // some items can't go to cart because some further options selection has to be done in details dialog
    // but if item is in cart then toggling has to be possible to remove it from cart
    const openDetails = !this.item.isInCart && ~this.buyNowExcluded.indexOf(this.item.id);

    if (openDetails) {
      this.openItemDetails();
    } else {
      // in case of pages we open page creation dialog which contains subscription purchase as last step of creation
      if (this.item.id === 'page') {
        dispatcher.dispatch('pages', 'create');
        return;
      }

      dispatcher.dispatch('purchase', 'toggleCartItem', this.item);
    }
  }

  @action
  changePeriod(period) {
    const product = this.item.products.find((p) => p.period === period);
    dispatcher.dispatch('purchase', 'changeSelectedProduct', this.item, product);
  }

  @action
  buyNow() {
    dispatcher.dispatch('purchase', 'checkout', null, [this.item]);
  }

  @action
  reactivate() {
    dispatcher.dispatch('purchase', 'reactivateSubscription', this.item);
  }
}
