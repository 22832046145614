import { A } from '@ember/array';
import { compact } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import { Post } from 'mewe/stores/models/post-model';
import Comment from 'mewe/stores/models/comment-model';
import ChatApi from 'mewe/api/chat-api';
import EnvUtils from 'mewe/utils/environment-utils';
import { deserializeAndStoreAuthors } from 'mewe/fetchers/fetch-feed';
import ThreadFactory from 'mewe/stores/models/thread-model';
import ChatMessageFactory from 'mewe/stores/models/chat-message-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import CurrentUserStore from 'mewe/stores/current-user-store';
import ChatStore from 'mewe/stores/chat-store';

const Thread = ThreadFactory(CurrentUserStore, ChatStore);
const ChatMessage = ChatMessageFactory(CurrentUserStore, ChatStore, Thread);
const serializer = JSONSerializer.create();

import GroupSearch from '../mw-group-search';

export default class MwChatSearch extends GroupSearch {
  inputPlaceholder = __('Search');
  searchMaxResults = 20;
  timeoutId = null;

  @tracked loading = false;
  @tracked noResultsText = '';
  @tracked hasMoreSearchResults = false;

  doSearch(showMore) {
    const str = this.searchPhrase;

    this.loading = true;

    if (!showMore) {
      this.offset = 0;
      this.itemsSearched = A();
    }

    clearTimeout(this.timeoutId);

    if (str) {
      this.timeoutId = setTimeout(() => {
        ChatApi.chatSearch({
          query: str,
          maxResults: this.searchMaxResults,
          offset: this.offset,
          highlighting: true,
        })
          .then((data) => {
            if (!this.isDestroying && !this.isDestroyed) {
              if (data.postsData) {
                deserializeAndStoreAuthors(data.postsData.users);
              }
              this.renderSearchResults(data, str);
              this.loading = false;
            }
          })
          .catch(() => {
            if (this.isDestroying || this.isDestroyed) return;

            this.loading = false;
            this.noResultsText = __('No results for "{searchText}".', { searchText: str });
          });
      }, 300);
    }
  }

  renderSearchResults(data, searchStr) {
    let results = data.results || A();
    const nonFilteredResultsLength = results.length; // so offset stays correct even though we filter out comment results below

    results = this.filterAndDeserializeResults(results);

    this.itemsSearched.pushObjects(results);

    this.offset += nonFilteredResultsLength;
    this.hasMoreSearchResults = data.hasMoreResults;
    this.noResultsText =
      this.itemsSearched.length === 0 ? __('No results for "{searchText}".', { searchText: searchStr }) : '';
  }

  filterAndDeserializeResults(itemsSearched = []) {
    return compact(
      itemsSearched.map((result) => {
        if (result.chatThread && result.chatThread.participants) {
          //TODO fix in backend
          result.chatThread.id = result.chatThread.threadId;
          result.chatThread = serializer.deserializeOne(Thread, result.chatThread);
        }

        if (result.chatMessage) {
          //TODO fix in backend
          result.chatMessage.authorId = result.chatMessage.userId;

          // need to use textPlain as text for proper text highlighting
          result.chatMessage.text = result.chatMessage.textPlain;

          let avatar,
            avatarLinks = result.chatMessage._links,
            badges = result.chatMessage.badges;

          if (avatarLinks) {
            avatar = EnvUtils.getImgHost(true) + avatarLinks.avatar.href.replace('{imageSize}', '150x150');
          } else {
            avatar = EnvUtils.getImgHost(true) + '/api/v2/photo/profile/150x150/' + result.chatMessage.userId;
          }

          if (result.chatMessage.messageType === 'group' && avatar.indexOf('group=') === -1)
            avatar += '&group=' + result.chatMessage.threadId; // missing from backend response

          result.chatMessage = serializer.deserializeOne(ChatMessage, result.chatMessage);
          result.chatMessage.avatar = avatar;
          result.chatMessage.badges = badges;
        } else if (result.post && result.post.comments && result.post.comments.feed) {
          result.comment = serializer.deserializeOne(Comment, result.post.comments.feed[0]);
          result.comment.set('threadId', result.post.threadId);

          delete result.post;
        } else if (result.post) {
          result.post = serializer.deserializeOne(Post, result.post);
        }

        return result;
      })
    );
  }

  @action
  loadMoreChatSearch() {
    this.doSearch(true);
  }
}
