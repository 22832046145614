import {
  getHashMap,
  getPNGSprite,
  getPNG,
  regex,
  isSticker,
  getEditSVG,
  replaceAsciiEmoji,
  getEmojisWithIndices,
  stripModifiers,
} from 'mewe/utils/emoji-utils';
import { isEmpty, trim, each } from 'lodash';

export const getMentionsWithIndices = (text) => {
  let mentions = [];
  text.replace(/\@\{\{([mu]?)_([a-zA-Z0-9]{8,24}?)\}(.{1,100}?)\}/gi, (text, prefix, id, name, i) => {
    mentions.push({
      mention: text,
      indices: [i, i + text.length],
    });
  });
  return mentions;
};

export const notInsideMention = (mentionsWithIndices) => (emojiWithIndices) => {
  const index = emojiWithIndices.indices[0];
  let ret = true;

  mentionsWithIndices.forEach((mention) => {
    if (index >= mention.indices[0] && index <= mention.indices[1]) {
      ret = false;
    }
  });

  return ret;
};

export default {
  // textServer => textDisplay
  // :smile: => <svg>
  // if we don't want to display :text: preview until emojis are loaded - SG-14561

  toDisplay(text, options) {
    if (isEmpty(text)) {
      return '';
    }

    let trimmedText = trim(text), //.replace(/[\u200B-\u200D\uFEFF]/g, ''), // remove emoji modifiers
      startOfP = trimmedText.indexOf('<p dir="auto">'),
      endOfP = trimmedText.indexOf('</p>');

    if (startOfP === 0 && endOfP === trimmedText.length - 4) {
      trimmedText = trimmedText.slice(14, endOfP); // trim surrounding <p dir="auto"></p>
    }

    return text.replace(new RegExp(regex, 'g'), (symbol, i) => {
      if (symbol == '#' && text.substr(i + 1, 1) != ' ' && text.substr(i + 1, 1) != '<') {
        return symbol;
      }

      const symbolFiltered = stripModifiers(symbol);

      const emoji = getHashMap().unicode[symbolFiltered] || getHashMap().shortnameDisplay[symbolFiltered];

      if (emoji && isSticker(symbol, trimmedText) && !(options && options.noStickers)) {
        return getEditSVG(emoji, { isSticker: true });
      }

      return emoji ? getPNGSprite(emoji, (options && options.pngSpriteSize) || 16) : symbolFiltered;
    });
  },

  // textServer => textEdit
  // :smile: => <img>

  toEdit(text) {
    if (isEmpty(text)) {
      return '';
    }

    let emojis = getEmojisWithIndices(text);
    let mentions = getMentionsWithIndices(text);

    if (mentions.length) {
      emojis = emojis.filter(notInsideMention(mentions));
    }

    let richText = '';
    let beginIndex = 0;

    each(emojis, (e) => {
      richText += text.substring(beginIndex, e.indices[0]);
      richText += getPNG(e.emoji, { isSticker: false });
      beginIndex = e.indices[1];
    });

    if (text) {
      richText += text.substring(beginIndex, text.length);
    }

    return richText;
  },

  // textEdit => textServer

  toServer(text) {
    const txt = replaceAsciiEmoji(text, true);

    if (txt) {
      return txt.replace(/<img.*?data-name="(.*?)".*?>/g, ':$1:');
    }

    return text;
  },
};
