import { htmlSafe } from '@ember/template';
import { escape } from 'lodash';
import ExternalApi from 'mewe/api/external-api';
import isUndefined from 'mewe/utils/isUndefined';

class GfycatApi extends ExternalApi {
  constructor() {
    super();
    this.apiRoot = 'https://api.gfycat.com/v1/';
  }

  // maxResults -> count
  optionsToGfycatFormat(options) {
    if (isUndefined(options.count) && !isUndefined(options.maxResults)) {
      options.count = options.maxResults;
      delete options.maxResults;
    }
  }

  // search_text, cursor, count, rating
  search(options = {}) {
    if (isUndefined(options.search_text) && !isUndefined(options.searchTerm)) {
      options.search_text = options.searchTerm;
      delete options.searchTerm;
    }
    this.optionsToGfycatFormat(options);

    if (isUndefined(options.rating)) options.rating = 'g';

    return this.get('gfycats/search', {}, options);
  }

  getGfycatInfo(id, options = {}) {
    return this.get(`gfycats/${encodeURIComponent(id)}`, {}, options);
  }

  transformData(resp) {
    if (!resp || !resp.gfycats) return resp;

    let data = resp && resp.gfycats;
    if (typeof data === 'object' && !Array.isArray(data)) data = [data];

    const transformed = {
      data: data.map((gifData) => {
        const safeData = {
          width: gifData.width,
          height: gifData.height,
          url: htmlSafe(escape(gifData.gif100px)),
          downSampledUrl: htmlSafe(escape(gifData.gif100px)),
          title: gifData.title,
        };

        return safeData;
      }),
    };

    transformed.cursor = resp.cursor;

    return transformed;
  }
}

export default new GfycatApi();
