import { A } from '@ember/array';

export default // Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () {};
  var methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'trace',
    'warn',
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

// log() -- The complete, cross-browser (we don't judge!) console.log wrapper for his or her logging pleasure

if (window.location.host !== 'mewe.com') {
  if (!window.log) {
    window.log = function () {
      log.history = log.history || A(); // store logs to an array for reference
      log.history.push(arguments);
      log.rewind = function () {
        for (var i in log.history) console.log(log.history[i]);
      };

      // Modern browsers
      if (typeof console != 'undefined' && typeof console.log == 'function') {
        // Single argument, which is a string
        if (
          Array.prototype.slice.call(arguments).length === 1 &&
          typeof Array.prototype.slice.call(arguments)[0] === 'string'
        ) {
          console.log(Array.prototype.slice.call(arguments).toString());
        } else {
          console.log(Array.prototype.slice.call(arguments));
        }
      }
    };
  }
} else {
  window.log = function () {};
}
