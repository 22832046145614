import api from 'mewe/api/journals-api';
import TellerModel from 'mewe/stores/models/teller-model';
import EntryModel from 'mewe/stores/models/entry-model';
import JournalModel from 'mewe/stores/models/journal-model';
import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';
import { storeCollectionItems, pushCollectionItems, setNextPage } from 'mewe/stores/models/collection';
import { ds } from 'mewe/stores/ds';
import { fetchUtil, unsetFlag } from 'mewe/fetchers/utils';
import { tap } from 'mewe/utils/fp';

export const toTeller = (data) => s.deserializeOne(TellerModel, data);

export const toEntry = (data) => s.deserializeOne(EntryModel, data);

export const toJournal = (data) => s.deserializeOne(JournalModel, data);

export const fetchTellers = () =>
  fetchUtil(api.tellers(), ds.tellers)
    .then((data) => storeCollectionItems(ds.tellers, data.storytellersInOrder.map(toTeller)))
    .then(unsetFlag(ds.tellers));

export const fetchEntriesByTeller = (params) =>
  fetchUtil(api.entriesByTeller(params), ds.entriesByTeller.for(params.id))
    .then(
      tap((data) =>
        data.stories ? storeCollectionItems(ds.entriesByTeller.for(params.id), data.stories.map(toEntry)) : null
      )
    )
    .then(unsetFlag(ds.entriesByTeller.for(params.id)));

export const fetchEntriesByJournal = (params) =>
  fetchUtil(api.entriesByJournal(params), ds.entriesByJournal.for(params.journalId))
    .then(tap(setNextPage(ds.entriesByJournal.for(params.journalId))))
    .then((data) =>
      (params.nextPage ? pushCollectionItems : storeCollectionItems)(
        ds.entriesByJournal.for(params.journalId),
        data.journalEntries.map(toEntry)
      )
    )
    .then(unsetFlag(ds.entriesByJournal.for(params.journalId)));

export const fetchJournalsBy = (params) =>
  fetchUtil(api.journalsBy(params), ds.journalsByTeller.for(params.id))
    .then((data) => storeCollectionItems(ds.journalsByTeller.for(params.id), data.journals.map(toJournal)))
    .then(unsetFlag(ds.journalsByTeller.for(params.id)));
