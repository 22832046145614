import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class AppComponent extends Component {

  author = {
    id: '525c8e4fe4b0cfcd837583a6',
    publicLinkId: 'kajo.marton',
    firstName: 'Kajom',
    fprint: 'F116391112905013NHMH',
    lastName: 'Marton',
    _links: {
      avatar: {
        href: '/api/v2/photo/profile/{imageSize}/525c8e4fe4b0cfcd837583a6?group=&f=F991770575197MBK5PT',
        templated: true,
      },
    },
    profileImageId: '53a1f69ee4b08dbfe723510d',
    timezone: 'Europe/Oslo',
    locale: 'en',
  };
}