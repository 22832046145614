import { A } from '@ember/array';

/**
 * Publish / Subscribe
 * Based on AmplifyJS 1.1.0 - Core
 * http://amplifyjs.com
 */

var slice = A().slice;
var subscriptions = {};

/**
 * Usage:
 * publish( string topic, ... );
 */
var Publish = function (topic) {
  var args = slice.call(arguments, 1),
    topicSubscriptions,
    subscription,
    length,
    i = 0,
    ret;

  if (!subscriptions[topic]) {
    return true;
  }

  topicSubscriptions = subscriptions[topic].slice();
  for (length = topicSubscriptions.length; i < length; i++) {
    subscription = topicSubscriptions[i];
    if (subscription && subscription.callback) {
      ret = subscription.callback.apply(subscription.context, args);
      if (ret === false) {
        break;
      }
    }
  }
  return ret !== false;
};

/**
 * Usage:
 * Subscribe( string topic, function callback )
 * Subscribe( string topic, object context, function callback )
 * Subscribe( string topic, function callback, number priority )
 * Subscribe(string topic, object context, function callback, number priority )
 */
var Subscribe = function (topic, context, callback, priority) {
  if (arguments.length === 3 && typeof callback === 'number') {
    priority = callback;
    callback = context;
    context = null;
  }

  if (arguments.length === 2) {
    callback = context;
    context = null;
  }

  priority = priority || 10;

  var topicIndex = 0,
    topics = topic.split(/\s/),
    topicLength = topics.length,
    added;

  for (; topicIndex < topicLength; topicIndex++) {
    topic = topics[topicIndex];
    added = false;
    if (!subscriptions[topic]) {
      subscriptions[topic] = A();
    }

    var i = subscriptions[topic].length - 1,
      subscriptionInfo = {
        callback: callback,
        context: context,
        priority: priority,
      };

    for (; i >= 0; i--) {
      if (subscriptions[topic][i].priority <= priority) {
        subscriptions[topic].splice(i + 1, 0, subscriptionInfo);
        added = true;
        break;
      }
    }

    if (!added) {
      subscriptions[topic].unshift(subscriptionInfo);
    }
  }

  return callback;
};

/**
 * Usage:
 * SG.unsubscribe( string topic, function callback );
 */
var Unsubscribe = function (topic, callback) {
  if (!subscriptions[topic]) {
    return;
  }

  var length = subscriptions[topic].length,
    i = 0;

  // remove all callbacks for given topic, no need for reference to specific callback
  if (callback && callback === 'clearAll') {
    delete subscriptions[topic];
  } else {
    for (; i < length; i++) {
      if (subscriptions[topic][i].callback === callback) {
        subscriptions[topic].splice(i, 1);
        break;
      }
    }
  }
};

/**
 * Usage:
 * Unpublish - completely removes function from published;
 */
var Unpublish = function (topic) {
  if (!subscriptions[topic]) {
    return;
  }

  var length = subscriptions[topic].length,
    i = 0;

  for (; i < length; i++) {
    subscriptions[topic].splice(i, 1);
    break;
  }
};

export default {
  Pub: Publish,
  Sub: Subscribe,
  Unsub: Unsubscribe,
  Unpub: Unpublish,
};
