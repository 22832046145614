import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * USAGE
 * {{ mw-image src=src imageLoaded="imageLoaded" }}
 */

export default class MwImage extends Component {
  @action
  onInsert(element) {
    const img = element;
    img.onload = () => {
      if (typeof this.args.imageLoaded === 'function') this.args.imageLoaded();
      img.onload = null;
    };
  }
}
