/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import PagesApi from 'mewe/api/pages-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class AppPageSettingsModeration extends Component {
  @tracked isAutoModerationOn = this.args.model.isAutoModerationOn;
  @tracked commentsRemoved = this.args.model.count || 0;
  @tracked moderationLevel = this.args.model.autoModerationLevel || 'high';

  get getModerationLevel() {
    return this.isAutoModerationOn ? this.moderationLevel : false;
  }

  @action
  async save(params) {
    try {
      await PagesApi.saveSettings(this.args.model.pageId, params);

      const { count } = await PagesApi.getModerationCommentsCount(this.args.model.pageId, this.moderationLevel);

      this.commentsRemoved = count;
    } catch (e) {
      FunctionalUtils.defaultErrorMessage();
    }
  }

  @action
  toggleSwitch() {
    const params = {};
    this.isAutoModerationOn = !this.isAutoModerationOn;

    //when turning off auto moderation, set moderation level to high(default)
    if (!this.isAutoModerationOn) {
      this.moderationLevel = 'high';
      params.autoModerationLevel = 'high';
    }

    params.autoModeration = this.isAutoModerationOn;
    this.save(params);
  }

  @action
  saveLevel(level) {
    this.moderationLevel = level;

    this.save({
      autoModerationLevel: this.moderationLevel,
    });
  }
}
