import CurrentUserStore from 'mewe/stores/current-user-store';
import ChatStore from 'mewe/stores/chat-store';
import ChatApi from 'mewe/api/chat-api';
import { retryHttp500UntilDone } from 'mewe/utils/fetch-utils';
import { ChatFilter } from 'mewe/constants';

const hasFilter = (options) => options && options.chatType && options.chatType !== ChatFilter.ALL;

const addType = (params, options) =>
  hasFilter(options) ? Object.assign(params, { chatType: options.chatType }) : params;

export default function (options) {
  CurrentUserStore.getState().deferred.promise.then(() => {
    ChatApi.getUnreadCount().then((data) => {
      ChatStore.send('setUnreadCount', data.count);
    });

    const params = addType({ addRequests: true && !hasFilter(options) }, options);

    retryHttp500UntilDone(() => ChatApi.getThreads(params))
      .connectionIssue(() => {
        ChatStore.send('connectionIssue');
      })
      .then((data) => {
        if (!data || !data.threads) return;

        let chatSource = params.chatType ? params.chatType : ChatFilter.ALL;

        ChatStore.send('handleRawThreads', data, options, chatSource);

        ChatStore.send('handleChatRequests', data);
      });
  });
}
