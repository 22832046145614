/* eslint-disable curly */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import Scrolling from 'mewe/utils/scrolling-utils';
import dispatcher from 'mewe/dispatcher';
import { inject as service } from '@ember/service';

export default class MwEventsFeed extends Component {
  @service account;

  constructor() {
    super(...arguments);
    this.loadEvents();
  }

  @action
  onInsert() {
    this.scrolling = Scrolling();
    this.bindScrolling();
    addObserver(this, 'args.model.collection.items.length', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'args.model.collection.items.length', this.bindScrolling);
  }

  bindScrolling() {
    if (this.args.model.collection.canShowMore) {
      this.scrolling.bindScrollDown(() => this.loadEvents(), 1200);
    } else {
      this.scrolling.unbindScrollDown();
    }
  }

  loadEvents() {
    let id = this.args.model.collection.id;
    let scope = id.split('-')[1];

    if (
      this.args.model.collection.items.length &&
      this.args.model.collection.items[this.args.model.collection.items.length - 1]
    ) {
      let lastItem = this.args.model.collection.items[this.args.model.collection.items.length - 1];
      let offset;

      if (lastItem.isBday) {
        offset = this.args.model.collection.items.length;
      } else {
        offset = lastItem.startDate;
      }

      dispatcher.dispatch('events', 'loadMore', scope, id, offset);
    }
  }
}
