/*
  it register Handlebars compatibile helper, TODO: remember to move that to HTMLbars helpers/plugins when it's documentation is ready

  usage: {{ i18n "{userName} posted in {groupName}" userName=user.name groupName=group.name }}
*/
import { htmlSafe } from '@ember/template';

import Helper from '@ember/component/helper';
import { map, isNumber, escape } from 'lodash';
export default Helper.extend({
  compute(text, options) {
    if (options && options.namespace) {
      text = options.namespace + ':::' + text;
      delete options.namespace;
    }

    if (options && options.escape === false) {
      var mutableOptions = {};
      for (var o in options) mutableOptions[o] = options[o];

      return htmlSafe(__(text, mutableOptions));
    } else {
      let safeOptions = {};
      map(Object.keys(options), (current) => {
        if (current !== 'escape') {
          let val = options[current];
          if (!isNumber(val)) {
            safeOptions[current] = escape(val);
          } else {
            safeOptions[current] = val;
          }
        }
      });

      const i18nValue = __(text, safeOptions);

      //SG-37272: i18nValue can be an object if it contains pluralization
      // e.g. {one: '1 comment', other: '{count} comments'}
      // we need to parse it and return the correct value
      if (~i18nValue.indexOf('{\"')) {
        const returnedObject = JSON.parse(i18nValue);

        if(returnedObject && returnedObject?.one) {
          return htmlSafe(returnedObject.one);
        } else {
          return htmlSafe(returnedObject.other);
        }
      }

      return htmlSafe(i18nValue);
    }
  },
});
