import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class MwSidebarPhotostream extends Component {
  @tracked streamIsEmpty;

  @action
  checkEmptyStream(value) {
    this.streamIsEmpty = value;
  }
}
