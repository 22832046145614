import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import { isEdge } from 'mewe/shared/utils';
import Scrolling from 'mewe/utils/scrolling-utils';

/**
 * Component is responsible for presenting groups that the user
 * has joind or has an invitation to join (AKA unconfirmed groups).
 *
 * At the moment of writing, it's placed two times one the left sidebar.
 *
 * USAGE example:
 *
 * <Groups::MwGroupsMenu />
 * or
 * <Groups::MwGroupsMenu @unconfirmed={{true}} />
 */

export default class MwGroupsMenu extends Component {
  @tracked offset = 10;
  @tracked isEdge = false;

  scrolling = Scrolling();

  @action
  onInsert(element) {
    this.isEdge = isEdge();

    this.element = element;
    this.scrolling.bindScrollDownElement(this.element, () => this.renderNext(), 500);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element);
  }

  renderNext() {
    this.offset += 10;
    this.scrolling.bindScrollDownElement(this.element, () => this.renderNext(), 500);
  }

  @computed('args.groups.length')
  get universalOnlyGroup() {
    return this.args.groups?.[0]?.isUniversal && this.args.groups?.length === 1;
  }

  @computed('args.groupState.groupsLoaded', 'args.groups.length', 'args.unconfirmed', 'universalOnlyGroup')
  get showGroupsPlaceholder() {
    if (this.args.unconfirmed) return false;
    // show placeholder if there is no group or the only group is 'Universal group'
    return this.args.groupState?.groupsLoaded && (!this.args.groups?.length || this.universalOnlyGroup);
  }

  @computed('args.groups', 'offset')
  get renderedGroups() {
    const groups = this.args.groups;
    return (groups && groups.slice(0, this.offset)) || A();
  }

  @computed('args.unconfirmed', 'args.groups.length')
  get showUnconfirmedGroups() {
    return this.args.unconfirmed && this.args.groups?.length;
  }
}
