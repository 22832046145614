import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import EmberObject from '@ember/object';
import { each } from 'lodash';
import Validator from './validators/validator';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PaymentsApi from 'mewe/api/payments-api';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { objFilter } from 'mewe/utils/function-utils';
import { inject as service } from '@ember/service';
import { loadScript, isMobile } from 'mewe/shared/utils';

export default class MwCreditCardForm extends Component {
  @service account;
  @service settings;

  @tracked validators;

  @tracked cardErrorMessage = false; // error message from stripe about cc info problems
  @tracked cardInputError = false; // show if !cardCompleted but only after submit
  @tracked cardCompleted = false;
  @tracked validatorsError = false;

  isMobile = isMobile();

  get activeUser() {
    return this.account.activeUser;
  }

  constructor() {
    super(...arguments);
    CurrentUserStore.getState().deferred.promise.then(() => {
      loadScript('https://js.stripe.com/v3/').then(() => {
        this.prepareStripe();
      });

      // only name is required as email can be missing in case of using Payment Buttons
      this.validators = EmberObject.create({
        email: Validator.extend({
          name: 'email',
          value: this.activeUser.primaryEmail || '',
          errors: { email: { value: false } },
          validate() {
            this.validateEmail(this);
          },
        }).create(),
        nameOfCard: Validator.extend({
          name: 'nameOfCard',
          value: this.activeUser.name,
          errors: { nameOfCard: { value: false } },
          validate() {
            this.validateSimpleInput(this);
          },
        }).create(),
      });
    });
  }

  /* jshint ignore:start */
  async prepareStripe() {
    const isDarkTheme = this.settings.isDarkThemeEnabled;
    const stripeApiKey = await PaymentsApi.getStripeApiKey();

    const stripe = Stripe(stripeApiKey.apiKeyPub);
    const elements = stripe.elements({
      locale: this.activeUser.locale,
    });
    const card = elements.create('card', {
      // zipCode is integrated with cc field
      hidePostalCode: false,
      style: {
        base: {
          iconColor: isDarkTheme ? '#d6d0cc' : '#373737', // $font1
          color: isDarkTheme ? '#d6d0cc' : '#373737', // $font1
          lineHeight: '40px',
          fontWeight: 400,
          fontFamily: '"Open Sans", sans-serif',
          fontSize: '14px',

          '::placeholder': {
            color: isDarkTheme ? '#d6d0cc' : '#373737', // $font1
          },
        },
      },
    });

    if (!document.getElementById('card-element')) return;
    // cc field mounting in DOM
    card.mount('#card-element');

    const setOutcome = (result = {}) => {
      this.cardCompleted = result.complete || result.token;
      this.cardErrorMessage = null;
      this.cardInputError = false; // remove error after any cc input change, show only after validation

      if (result.token?.id) {
        if (!this.validatorsError) {
          // name can be missing when PaymentButton was used but browser didn't provide name of stored card
          const cardName = result.token.card.name || this.activeUser.name;
          const cardParams = {
            name: cardName,
            token: result.token.id,
            billing: {
              name: cardName,
              email: result.token.email,
              zipCode: result.token.card.address_zip,
            },
          };

          this.args.parent.afterFormValidated(cardParams);
        }
      } else {
        if (result.error?.type === 'validation_error') {
          this.cardErrorMessage = result.error.message;
        } else if (result.error && !result.isOnChange) {
          // other types of errors when getting stripe token, can be server/api/auth/card/rate_limit error
          FunctionalUtils.showDefaultErrorMessage();
        }
      }
    };

    card.on('change', (event) => {
      event.isOnChange = true;
      setOutcome(event);
    });

    document.querySelector('#cc-form').addEventListener('submit', (e) => {
      e.preventDefault();

      if (this.args.parent.purchaseInProgress) {
        return;
      }

      if (this.validateForm()) {
        this.args.parent.updateProgress?.(true);

        const form = document.querySelector('#cc-form');
        const extraDetails = {
          name: form.querySelector('input[name=cardholder-name]').value,
          email: form.querySelector('input[name=user-email]').value,
        };

        stripe.createToken(card, extraDetails).then(setOutcome);
      }
    });

    // payment reuqest buttons are only for purchase process (detected when cartTotalPrice is passed)
    // and it's not needed for updating card info
    if (this.args.parent.cartTotalPrice) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          amount: parseInt(this.args.parent.cartTotalPrice * 100, 10),
          label: 'Total',
        },
      });

      paymentRequest.on('token', (result) => {
        setOutcome(result, true);
        result.complete('success');
      });

      paymentRequest.canMakePayment().then((result) => {
        if (this.isDestroying && this.isDestroyed) return;

        if (result) {
          this.args.parent.setPaymentRequest?.(paymentRequest, result);
        }
      });
    }
  }
  /* jshint ignore:end */

  // form validation is triggered only on form submit, no validation when used Payment Button
  validateForm() {
    each(this.validators, (validator) => {
      if (!validator.omit) validator.validate();
    });

    const filters = objFilter(this.validators, (v) => !v.omit);
    const hasErrors = Object.values(filters).find((el) => el.hasErrors);

    // error displayed on form submit and hidden on any change in cc input
    this.cardInputError = !this.cardCompleted;
    this.validatorsError = !!hasErrors;

    return !this.validatorsError && !this.cardInputError;
  }

  @action
  onInsert() {
    if (this.isMobile) {
      window.scrollTo(0, 0);
    }
  }

  @action
  cancelPurchaseError() {
    this.args.parent.hidePurchaseError?.();
  }
}
