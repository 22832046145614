import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

export default class MwPasswordInput extends Component {

  @action
  passwordUpdated() {
    // has to be postponed, otherwise change is triggered before the value is updated
    next(() => this.args.passwordUpdated?.());
  }
}
