import Component from '@glimmer/component';
import { chatMaxUsers } from 'mewe/constants';
import { action } from '@ember/object';

import { tracked } from '@glimmer/tracking';

export default class MwUserAutocomplete extends Component {
  @tracked inputEl;
  @tracked searchText = '';

  array = [];

  constructor() {
    super(...arguments);
    this.limit = chatMaxUsers - 1;
  }

  @action
  setInputEl(el) {
    this.inputEl = el;
  }

  @action
  unSelectContact(contact) {
    this.args.selectedItems.removeObject(contact);
  }

  @action
  focusSearch() {
    this.inputEl.focus();
  }

  @action
  onTabKey() {
    this.onTabKey();
  }
}
