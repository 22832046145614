import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import Entry from 'mewe/stores/models/entry-model';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import { escape } from 'lodash';

const model = Model.extend({
  id: attr('string'),
  cover: belongsTo(Entry),
  createdAt: attr('string'),
  lastUpdatedAt: attr('string'),
  latestStoryCreatedAt: attr('string'),
  lastStoryAddedAt: attr('string'),
  name: attr('string'),
  scope: attr('string'),

  nameDisplay: computed('name', function () {
    return htmlSafe(EmojisParser.toDisplay(escape(this.name)));
  }),
});

model.reopenClass({
  resourceName: 'journal',
});

export default model;
