import { curry } from 'lodash';
import SmartSearchApi from 'mewe/api/smart-search-api';

import api from 'mewe/api/group-api';
import GroupStore from 'mewe/stores/group-store';
import { retryHttp500UntilDone } from 'mewe/utils/fetch-utils';
import { processMyContactsInGroups } from 'mewe/utils/group-utils-light';
import { get as eGet } from '@ember/object';
import { A } from '@ember/array';

import { ds } from 'mewe/stores/ds';

import { toGroup, fetchUtil, unsetFlag } from 'mewe/fetchers/utils';

import {
  setNextPage,
  getOrCreateCollectionItem,
  storeCollectionItems,
  pushCollectionItems,
} from 'mewe/stores/models/collection';
import { get, pick, tap, map } from 'mewe/utils/fp';

import GroupModel from 'mewe/stores/models/group-model';

export const getPage = getOrCreateCollectionItem(ds.groups, GroupModel);
const setShowMore = curry((collection, data) => collection.set('canShowMore', eGet(data, 'hasMoreResults')));

export const fetchGroups = (promise, collection, isMore) =>
  fetchUtil(promise, collection)
    .then(tap(setNextPage(collection)))
    .then(get('groups'))
    .then(fetchGroupContacts)
    .then(map(toGroup))
    .then((isMore ? pushCollectionItems : storeCollectionItems)(collection))
    .then(unsetFlag(collection));

export const searchGroups = (params, isMore) =>
  fetchUtil(SmartSearchApi.groups(params), ds.searchedGroups)
    .then(tap(setShowMore(ds.searchedGroups)))
    .then(get('results'))
    .then(map(toGroup))
    .then((isMore ? pushCollectionItems : storeCollectionItems)(ds.searchedGroups))
    .then(unsetFlag(ds.searchedGroups));

export const fetchMoreSearchResults = (params) => searchGroups(params, ds.searchedGroups.canShowMore);
export const clearSearchResults = () => ds.searchedGroups.set('items', A([]));

export const searchOpenGroups = (params, isMore) =>
  fetchUtil(SmartSearchApi.publicOpenGroups(params), ds.searchedOpenGroups)
    .then(tap(setShowMore(ds.searchedOpenGroups)))
    .then(get('results'))
    .then(fetchGroupContactsSearch)
    .then(map(toGroup))
    .then((isMore ? pushCollectionItems : storeCollectionItems)(ds.searchedOpenGroups))
    .then(unsetFlag(ds.searchedOpenGroups));

export const fetchMoreSearchOpenGroups = (params) => searchOpenGroups(params, ds.searchedOpenGroups.canShowMore);
export const clearSearchOpenGroups = () => ds.searchedOpenGroups.set('items', A([]));

export const fetchCategories = (params = {}) =>
  fetchUtil(api.getGroupTopics(params), ds.groupCategories)
    .then(pick(['categories', 'featured']))
    .then(({ categories, featured }) => {
      storeCollectionItems(ds.groupCategories)(categories);
      storeCollectionItems(ds.featuredGroupCategories)(featured);
    })
    .then(unsetFlag(ds.groupCategories));

export const fetchGroupsForCategory = (params, nextPage) => {
  fetchGroups(api.getGroupsInTopic(params), ds.groupsForCategory.for(params.topic), nextPage);
};

export const fetchMoreGroupsForCategory = (params) => {
  return fetchGroupsForCategory(params, true);
};

const fetchGroupContacts = (groups, isSearch) => {
  if (groups?.length) {
    const collection = isSearch ? ds.searchedOpenGroups : ds.groupsForCategory.for(groups[0].groupThematicType);

    api
      .myContactsInGroups({ groupIds: groups.map((g) => g.id).join(',') })
      .then((data) => processMyContactsInGroups(collection.items, data));
  }

  return groups;
};

const fetchGroupContactsSearch = (results) => {
  fetchGroupContacts(
    results.map((r) => r.group),
    true
  );
  return results;
};

export default function (cb) {
  retryHttp500UntilDone(() => api.fetch())
    .connectionIssue(() => GroupStore.send('connectionIssue'))
    .then((data) => {
      GroupStore.send('handleMany', data.confirmedGroups);
      GroupStore.send('handleMany', data.unconfirmedGroups);
    })
    .finally(() => {
      if (cb) cb();
    });
}
