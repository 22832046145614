import ApiUtil from './api';

class Api extends ApiUtil {
  getInterestsList(locale) {
    return this.get(`/communities/interests?limit=100&locale=${locale}`);
  }

  getSelectedInterests(locale) {
    return this.get(`/communities/interests/selected`);
  }

  setInterests(params) {
    return this.post(`/communities/recommendations`, {
      data: JSON.stringify(params),
    });
  }

  fetchFeed(params = {}) {
    if (params.nextPage) {
      return this.getNextPageOr(params.nextPage);
    }

    return this.get(`/discover/feed`, {
      data: params,
    });
  }

  recommendPost(options) {
    return this.post(`/${options.scope}/${options.scopeId}/discover`, {
      data: JSON.stringify({ postId: options.postId }),
    });
  }

  unrecommendPost(options) {
    return this.del(`/${options.scope}/${options.scopeId}/discover`, {
      data: JSON.stringify({ postId: options.postId }),
    });
  }

  getRecommendedPosts(options, params) {
    return this.get(`/${options.scope}/${options.scopeId}/discover`, {
      data: params,
    });
  }

  // type: {'post', 'group', 'page'}
  ignoreContent(type, id) {
    return this.put(`/discover/ignore/${type}/${id}`);
  }

  unignoreContent(type, id) {
    return this.del(`/discover/ignore/${type}/${id}`);
  }
}

export default new Api();
