import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppGroupDocuments extends Component {
  @tracked currentFolder = null;

  @action
  setCurrentFolder(folder) {
    this.currentFolder = folder;
  }
}
