import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class ConfirmRoute extends Component {
  constructor() {
    super(...arguments);

    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui';

    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  @action
  resetGoHome() {
    dispatcher.dispatch('app', 'logout');
  }
}
