import Component from '@glimmer/component';
import { action } from '@ember/object';

import dispatcher from 'mewe/dispatcher';

export default class SidebarLeftChat extends Component {
  @action
  createNewChat() {
    dispatcher.dispatch('chat', 'createNewChat');
  }

  @action
  closeChatRequests() {
    dispatcher.dispatch('app', 'showDYKpopup', 'chatRequest');
  }

  @action
  toggleChatRequests() {
    dispatcher.dispatch('chat', 'showToggleChatRequestsPrompt');
  }
}
