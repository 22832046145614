import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';

export default class MwBadgeHintPopup extends PopupOpener {
  @service analytics;

  layout = layout;

  popupType = 'dropdown-popup';

  closeOnScroll = true;
  closeOnClickOutside = true;
  closeOnHoverOutside = true;

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();

    Object.assign(position, {
      margin: 4,
      placement: { top: false, left: isRtl, positionType: 'fixed' },
      translate: { x: -40, y: 0 },
    });

    return position;
  }

  @action
  socialWebInfoClicked() {
    this.analytics.sendEvent('learnMoreClicked', { location: 'tooltip', element: 'social web badge' });
  }
}
