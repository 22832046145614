import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isAndroid } from 'mewe/utils/mobile-utils';
import { iosAppUrl, androidAppUrl } from 'mewe/constants';
import { getPublicProfileParams } from 'mewe/utils/miscellaneous-utils';
import { menuHeaderMewe } from 'mewe/constants';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';


export default class MwPublicHeader extends Component {
  @service router;
  @service dynamicDialogs;

  menuHeaderMeweConst = menuHeaderMewe;

  get downloadAppLink() {
    if (isAndroid()) {
      return androidAppUrl;
    }
    return iosAppUrl;
  }

  @action
  goToSignUp() {
    this.router.transitionTo('register', { queryParams: getPublicProfileParams(this.args.model) });
  }

  @action
  goToLogin() {
    this.router.transitionTo('login', { queryParams: getPublicProfileParams(this.args.model) });
  }

  @action
  displayNewToMeWePopup() {
    showNewToMeWePopup(this.dynamicDialogs);
  }
}
