export const injectScript = (src, attributes, options) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;

    attributes.forEach((attr) => {
      script.setAttribute(attr.key, attr.value);
    });

    if (options.defer) script.defer = true;
    if (options.id) script.id = options.id;

    script.addEventListener('load', resolve);
    script.addEventListener('error', () => reject('Error loading script.'));
    script.addEventListener('abort', () => reject('Script loading aborted.'));
    document.head.appendChild(script);
  });
};
