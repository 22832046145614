import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const Spinner = {
  DOTS: 'dots',
  CIRCLE: 'circle',
};

export default class IsFetching extends Component {
  @tracked spinner;
  @tracked spinners = Spinner;

  constructor() {
    super(...arguments);
    this.spinner = this.args.spinner || Spinner.DOTS;
  }
}
