import Component from '@glimmer/component';
import { action } from '@ember/object';
import { sortBy } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

import PS from 'mewe/utils/pubsub';

export default class MwGroupSearch extends Component {

  @tracked searchDropdownOpened = false;
  @tracked searchPhrase = '';
  @tracked itemsSearched;

  constructor() {
    super(...arguments);

    addObserver(this, 'searchPhrase', this.updateSearch);

    this.clearSearchedTextBind = this.clearSearchedText.bind(this);
    PS.Sub('click.sidebar.search.result', this.clearSearchedTextBind);
  }

  willDestroy() {
    removeObserver(this, 'searchPhrase', this.updateSearch);
    PS.Unsub('click.sidebar.search.result', this.clearSearchedTextBind);
  }

  willDestroyElement() {
    this.unbindPubSub();
  }

  clearSearchedText() {
    if (!this.isDestroying && !this.isDestroyed) {
      this.searchPhrase = '';
    }
  }

  updateSearch() {
    const hasSearchPhrase = !!this.searchPhrase?.length;

    this.searchDropdownOpened = hasSearchPhrase;

    if (hasSearchPhrase) {
      this.doSearch();
    }
  }

  doSearch() {
    const all = this.args.itemsToSearch || [];
    const lowerPhrase = this.searchPhrase ? this.searchPhrase.toLowerCase() : '';
    const searched = all.filter((g) => {
      return g.name.toLowerCase().indexOf(lowerPhrase) > -1;
    });

    this.itemsSearched = sortBy(searched, (g) => {
      return g.name.toLowerCase();
    });
  }

  @action
  click() {
    return false;
  }

  @action
  clearSearch() {
    this.searchPhrase = null;
  }

}