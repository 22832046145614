import EmberObject from '@ember/object';
import { reduce } from 'lodash';
import fuHelper from 'mewe/utils/fileupload-utils';
import PostCommons from 'mewe/utils/posting-utils';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import PS from 'mewe/utils/pubsub';
import Session from 'mewe/shared/session';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import axios from 'axios';
import { fileUploadLimit } from 'mewe/constants';

const CancelToken = axios.CancelToken;

const fileIsEmpty = (file) => {
  if (file.size === 0) {
    FunctionalUtils.error(__('Empty file cannot be uploaded.'));
    return true;
  }
  return false;
};

const fileIsTooBig = (file) => {
  if (file.size >= fileUploadLimit) {
    fuHelper.showDefaultFileErrorMessage();
    return true;
  }
  return false;
};

const fileNotFound = (file) => {
  // https://stackoverflow.com/questions/49918319/jquery-file-upload-cannot-upload-file-in-safari-11
  if (file.name === 'NotFoundError') {
    PS.Pub('open.generic.dialog', {
      title: __('File upload error'),
      message: __('The file path is invalid'),
    });
    return true;
  }
  return false;
};

const fileIsFalsy = (file) => {
  if (!file) {
    PS.Pub('open.generic.dialog', {
      title: __('File upload error'),
      message: __('The file is invalid'),
    });
    return true;
  }
  return false;
};

const checks = [fileIsFalsy, fileNotFound, fileIsTooBig, fileIsEmpty];

const fileIsInvalid = (file) => reduce(checks, (v, fn) => v && fn(file), false);

const postboxIsDestroying = (postbox) => postbox.isDestroying || postbox.isDestroyed;

const getFile = (files, tempId) => [files.find((file) => file.tempId === tempId)];

const calcProgress = (loaded, total) => Math.min(99, parseInt((loaded / total) * 100, 10));

const createFileUI = (file) =>
  EmberObject.create({
    loading: true,
    id: null,
    tempId: file.uploadId,
    name: file.name,
    size: file.size,
    type: file.type,
    progress: 0,
    loaded: false,
    aborted: false,
    xhr: null,
  });

class Document {
  constructor(parent) {
    this.postbox = parent;

    this.documentsUI = this.postbox.documents;
  }

  handleFile({ files }) {
    if (postboxIsDestroying(this.postbox)) return;

    files.forEach((file) => {
      if (fileIsInvalid(file)) return;

      let fileUI = createFileUI(file);

      this.postbox.documents.pushObject(fileUI);

      this.uploadFile(file, fileUI);
    });

    this.postbox.updateHeight();
  }

  uploadFile(file, fileUI) {
    let formData = new FormData();
    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url: EnvironmentUtils.getApiHost() + '/api/v2/doc',
      method: 'POST',
      cancelToken: source.token,
      headers: { 'X-CSRF-Token': Session.getCsrfToken() },
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          fileUI.set('progress', calcProgress(data.loaded, data.total));
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(fileUI, res.data);
      })
      .catch((e) => {
        this.fail(fileUI, e.response);
      });

    fileUI.set('xhr', source);
  }

  done(fileUI, result) {
    const responseFile = result.docs[0];
    fileUI.setProperties({ id: responseFile.id, loaded: true, progress: 100 });
    this.updateGlobalProgress();
  }

  fail(fileUI, response = {}) {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    if (response.status == 401) return;

    fileUI.setProperties({ tempId: null, aborted: true });

    if (response.data && response.data.errorCode === 700) {
      this.postbox.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
    } else if (response.status) {
      FunctionalUtils.showDefaultErrorMessage();
    }

    this.updateGlobalProgress();
  }

  removeFile(documentUI) {
    getFile(this.postbox.documents, documentUI.tempId).map((file) => {
      if (file.xhr) file.xhr.cancel();

      this.postbox.documents.removeObject(file);
    });

    this.postbox.uploadService.remove(documentUI.tempId);

    this.updateGlobalProgress();
    this.postbox.updateHeight();
  }

  isEmpty() {
    return !this.postbox.documents.length;
  }

  updateGlobalProgress() {
    const documents = this.postbox.documents;
    this.postbox.hasDocumentsInProgress = documents.some((d) => !d.loaded);
  }

  shareController(params) {
    if (this.postbox.isSharing) return;

    if (!this.postbox.documents.length) {
      FunctionalUtils.error(__('Upload document first'));
      return;
    }

    this.share(params);
  }

  share(extraParams) {
    const params = Object.assign(
      {},
      {
        text: this.postbox.newPostServer,
        fileIds: this.postbox.documents.map((el) => el.id),
      },
      extraParams
    );

    const options = PostCommons.getStandardPostingOptions(params, this.postbox);
    const mainCallback = options.callback;

    options.callback = (data) => {
      // extending main parent callback defined in commons.js
      if (mainCallback) mainCallback.apply(this, [data]);

      if (params.postType !== 'photo') {
        PS.Pub('documents.reload');
      }
    };

    const folder = this.postbox.args.folder;
    if (folder && folder?.id != 'root') {
      options.folderId = folder.id;
    }

    PostCommons.share(options, this.postbox);
  }
}

export default Document;
