import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MwPopup extends Component {
  @service dynamicPopups;

  constructor() {
    super(...arguments);
    this.dynamicPopups.setupPopupWormhole();
  }
}
