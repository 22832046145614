import Route from 'mewe/routes/route';
import storage from 'mewe/shared/storage';
import Session from 'mewe/shared/session';
import axios from 'axios';

import { task } from 'ember-concurrency';

export default class PaypalSuccessRoute extends Route {
  model({ transactionId, subscriptionId, token, entityId }) {
    return {
      makeSuccessRequest: this.makeSuccessRequest.perform({
        transactionId,
        subscriptionId,
        token,
        entityId,
      }),
    };
  }

  @task({ cancelOn: 'deactivate' })
  *makeSuccessRequest({ transactionId, subscriptionId, token, entityId }) {
    try {
      let requestParams = {
        transactionId: transactionId,
      };
      if (subscriptionId) {
        requestParams.subscriptionId = subscriptionId;
      } else {
        requestParams.orderId = token;
      }
      if (entityId) {
        requestParams.entityId = entityId;
      }

      let reqResponse = yield axios({
        url: `/api/v2/store/paypal/${subscriptionId ? 'subscription' : 'order'}/approved`,
        method: 'POST',
        headers: { 'X-CSRF-Token': Session.getCsrfToken() },
        data: requestParams,
      });
      let returnResponse = {
        confirmationFinished: true,
      };
      if (reqResponse?.status === 200) {
        returnResponse.confirmationSuccess = true;
      } else {
        returnResponse.confirmationFailure = true;
      }

      let response = {};
      response = reqResponse.data;
      response.transactionId = transactionId;
      response.subscriptionId = subscriptionId;
      response.orderId = token;

      storage.set(storage.keys.paypalTransactionStatus, JSON.stringify(response));
      storage.remove(storage.keys.paypalTransactionStatus);

      return returnResponse;
    } catch (e) {
      return { confirmationFinished: true, confirmationFailure: true };
    }
  }
}
