import Route from 'mewe/routes/route';
import { getQueryStringParams } from 'mewe/shared/utils';
import ContactsApi from 'mewe/api/contacts-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default Route.extend({
  model(params) {
    this.provider = params.provider;
    this.initialiseImport();
  },

  initialiseImport() {
    // autoAddContacts param is required for proper functioning of import right
    // after reqistration (https://sgrouples.atlassian.net/browse/SG-30690).
    // later when importing contacts to verified account it's not needed
    const urlParams = getQueryStringParams();

    ContactsApi.uploadAddressbook(this.provider, urlParams.autoAddContacts)
      .then((data) => {
        window.location.href = data._links.oAuthUrl.href;
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      });
  },
});
