/**

tokenManager is responsible for setting, getting and clearing tokens from cookies.

In case of DesktopApp we use localStorage to store tokens, instead of cookies. Cookies are not present in browser when using file:// protocol

*/
import cookie from './cookie';
import appStorage from 'mewe/shared/storage';
import { _refreshTokenOffset } from 'mewe/constants';
import assign from 'object-assign';
import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/ember';
import Session from 'mewe/shared/session';

const storage = cookie;

const tokenManager = {
  /**
   *
   * @param {Object|string} tokens Tokens are key-value object or key as a string (value is required then)
   * @param {*} value
   *
   * Used only for saving user object and needTosAccepted probably
   * might be reworked later into LS Storage file
   *
   * not used for it's original purpose after reworking into new-auth
   */
  set: function (source, value) {
    if (source === null || (value === undefined && typeof source !== 'object')) {
      throw new TypeError('key and value or key-value object is required');
    }

    if (typeof source !== 'object' && typeof source) {
      const _tokens = {};
      _tokens[source] = value;
      source = _tokens;
    }

    const tokens = assign({}, source);

    if (tokens.needTosAccepted) {
      appStorage.set(appStorage.keys.needTosAccepted, true);
      delete tokens.needTosAccepted;
    }

    if (tokens.user) {
      if (tokens.user.locale) storage.setItem('locale', tokens.user.locale);
      else storage.removeItem('locale');

      appStorage.set(appStorage.keys.currentUser, JSON.stringify(tokens.user));

      delete tokens.user;
    }

    Session.refreshAuthentication();
  },

  get: function (key) {
    return storage.getItem(key);
  },

  clear: function () {
    storage.clear();

    // it happens that mixpanel sdk fails here
    try {
      Mixpanel.reset();
    } catch (err) {
      Sentry.captureException(err);
    }
  },
};

export default tokenManager;
