import emojisParser from 'mewe/stores/text-parsers/emojis-parser';
import gifUrlsParser from 'mewe/stores/text-parsers/gif-urls-parser';
import hashtagsParser from 'mewe/stores/text-parsers/hashtags-parser';
import htmlParser from 'mewe/stores/text-parsers/html-parser';
import linksParser from 'mewe/stores/text-parsers/links-parser';
import mentionsParser from 'mewe/stores/text-parsers/mentions-parser';
import markdownParser from 'mewe/stores/text-parsers/markdown-parser';
import { reduce } from 'lodash';

const parsersDefault = [
  htmlParser,
  linksParser,
  markdownParser,
  hashtagsParser,
  emojisParser,
  mentionsParser,
  gifUrlsParser,
];
export const parsersNoMarkdown = [htmlParser, linksParser, hashtagsParser, mentionsParser, emojisParser, gifUrlsParser];

export default function (text, parsers = parsersDefault) {
  if (!text) return '';

  let textOutput = reduce(
    parsers,
    (value, parser) => {
      return parser.toEdit(value);
    },
    text
  );

  return textOutput;
}
