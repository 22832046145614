import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class AppPages extends Component {
  @service router;
  @service settings;

  constructor() {
    super(...arguments);

    //make sure that after changing from myworld feed is at the top
    window.scrollTo(0, 0);
  }

  @computed('args.model.{followed.items.length,feed.posts.length}')
  get hasPagesOrFeed() {
    return this.args.model.feed.posts.length || this.args.model.followed.items.length;
  }

  @computed('args.model.{followed.isFetching,feed.isFetching}')
  get isFetchingPagesOrFeed() {
    return this.args.model.feed.isFetching || this.args.model.followed.isFetching;
  }
}
