import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default class LoaderFullscreen extends Component {
  @service settings;

  get showWhiteLogo() {
    return this.args.whiteLogo ?? get(this, 'settings.activeTheme') === 'dark';
  }
}
