let retryInterval = 200;
let maxRetryInterval = 5000;

const retry = (fn, ms) =>
  new Promise((resolve) => {
    if (window.Testem) return resolve;

    fn()
      .then(resolve)

      .catch((err) => {
        setTimeout(() => {
          console.log(`Error in split-retry, retrying in ${ms} :`, err);

          retry(fn, Math.min(maxRetryInterval, ms * 2)).then(resolve);
        }, ms);
      });
  });

export const SplitRetry = (attempt) => {
  return retry(attempt, retryInterval);
};
