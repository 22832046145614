import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { WrapperScope } from 'mewe/constants';
import { ds } from 'mewe/stores/ds';
import { toWrapper } from 'mewe/stores/models/wrapper-model';
import PS from 'mewe/utils/pubsub';
import { isSimpleClick } from '@ember/-internals/views';

export default class MwFollowedPageItem extends Component {
  @service router;
  @service dynamicDialogs;

  @computed('args.item.id', 'args.selectedItemId')
  get isSelected() {
    return this.args.item.id === this.args.selectedItemId;
  }

  @computed('args.isInTopMenu', 'args.item.newPosts')
  get showPostsIcon() {
    return this.args.isInTopMenu && this.args.item.newPosts > 0;
  }

  @action
  openLink(e) {
    if (!isSimpleClick(e)) return;
    e.preventDefault();
    window.scrollTo(0, 0);
    PS.Pub('click.sidebar.search.result');
    this.router.transitionTo(`${this.args.item.linkToRoute}.feed`, this.args.item.linkToId);
  }

  @action
  openWrapper() {
    const page = this.args.item;

    const isInStore = ds.wrappers.items.some((i) => i.id === page.id);

    if (!isInStore) {
      ds.wrappers.items.pushObject(toWrapper({ postsCount: page.newPosts, id: page.id, type: 'page' }));
    }

    this.dynamicDialogs.openDialog('wrappers-dialog', {
      wrapperId: page.id,
      wrappersScope: WrapperScope.PAGE,
    });
  }
}
