import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { fromJavaLocale } from 'mewe/utils/datetime-utils';

var model = Model.extend({
  productIds: attr('array'),
  amount: attr('object'),
  card: attr('object'),
  createdAt: attr('number'),
  provider: attr('string'),
  renewable: attr('boolean'),
  transactionId: attr('string'),
  transactionIdEncoded: computed('transactionId', function () {
    return encodeURIComponent(this.get('transactionId'));
  }),

  paymentTypeText: computed('provider', 'renewable', function () {
    if (this.provider === 'desktop' || this.provider === 'paypal')
      return this.renewable ? __('Subscription') : __('Purchase');
    else if (this.provider === 'ios') return this.renewable ? __('iOS Subscription') : __('iOS Purchase');
    else if (this.provider === 'android') return this.renewable ? __('Android Subscription') : __('Android Purchase');
  }),

  paymentWireText: computed('provider', 'card.last4', 'amount.amount', function () {
    if (this.get('amount.amount') === 0) {
      return __('Trial');
    }

    switch (this.provider) {
      case 'desktop':
        return '***** **** **** ' + this.get('card.last4');
      case 'paypal':
        return 'PayPal';
      case 'ios':
        return __('Apple Pay');
      case 'android':
        return __('Android Pay');
      default:
        return '';
    }
  }),

  paymentPriceText: computed('amount', function () {
    if (this.get('amount.currency') === 'USD') return this.get('amount.displayValue') + ' USD';
    else return this.get('amount.displayValue');
  }),

  paymentDateText: computed('createdAt', function () {
    const locale = fromJavaLocale(CurrentUserStore.getState().get('locale'));
    return new Date(this.createdAt).toLocaleDateString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' }); // "1/7/2019"
  }),

  canDownloadReceipt: computed('amout.amount', function () {
    return this.get('amount.amount') !== 0;
  }),
});

model.reopenClass({
  resourceName: 'store-subscription',
});

export default model;
