const minimalSize = 50;

const event = {
  moveByX: (x, left) => {
    let moveTo = left + x;
    if (moveTo < 0) moveTo = 0;
    return moveTo;
  },

  moveByY: (y, top) => {
    let moveTo = top + y;
    if (moveTo < 0) moveTo = 0;
    return moveTo;
  },

  resizeByX: (x, width) => {
    let resizeTo = width + x;
    if (resizeTo < minimalSize) resizeTo = minimalSize;
    return resizeTo;
  },

  resizeByY: (y, height) => {
    let resizeTo = height + y;
    if (resizeTo < minimalSize) resizeTo = minimalSize;
    return resizeTo;
  },

  check: (params, wrapper) => {
    if (params.width + params.left > wrapper.width) {
      params.width = wrapper.cropBox.width;
      params.left = wrapper.cropBox.left;
    }

    if (params.height + params.top > wrapper.height) {
      params.height = wrapper.cropBox.height;
      params.top = wrapper.cropBox.top;
    }

    //thanks to JS perfect floating numbers I had to use that beauty, enjoy
    if (Math.floor((params.height / params.width) * 1000000000) != Math.floor(wrapper.ratio * 1000000000)) {
      return;
    }
    return params;
  },

  move: (action, range) => {
    const cropBox = action.wrapper.cropBox;
    var width, height, left, top;

    switch (action.width) {
      case 'increase':
        width = event.resizeByX(range.x, cropBox.width);
        break;
      case 'decrease':
        width = event.resizeByX(-range.x, cropBox.width);
        break;
      default:
      case 'dontChange':
        width = cropBox.width;
        break;
    }

    switch (action.height) {
      case 'increase':
        height = event.resizeByY(range.y, cropBox.height);
        break;
      case 'decrease':
        height = event.resizeByY(-range.y, cropBox.height);
        break;
      default:
      case 'dontChange':
        height = cropBox.height;
        break;
    }

    switch (action.left) {
      case 'increase':
        left = event.moveByX(range.x, cropBox.left);
        break;
      case 'decrease':
        left = event.moveByX(-range.x, cropBox.left);
        break;
      default:
      case 'dontChange':
        left = cropBox.left;
        break;
    }

    switch (action.top) {
      case 'increase':
        top = event.moveByY(range.y, cropBox.top);
        break;
      case 'decrease':
        top = event.moveByY(-range.y, cropBox.top);
        break;
      case 'auto':
        break;
      default:
      case 'dontChange':
        top = cropBox.top;
        break;
    }

    if (action.width == 'auto') width = height / cropBox.ratio;
    if (action.width == 'auto' && action.left == 'auto')
      left = event.moveByX((cropBox.width - width) / 2, cropBox.left);

    if (action.height == 'auto') height = width * cropBox.ratio;
    if (action.height == 'auto' && action.top == 'auto')
      top = event.moveByY((cropBox.height - height) / 2, cropBox.top);

    return event.check({ width, height, left, top }, action.wrapper);
  },
};

export default event;
