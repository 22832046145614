import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import PagesApi from 'mewe/api/pages-api';
import dispatcher from 'mewe/dispatcher';

export default class AppPage extends Component {

  @tracked isPublishing;

  @action
  publish() {
    if (this.isPublishing) return;

    this.isPublishing = true;

    PagesApi.publish({ id: this.args.model.page.id })
      .then(() => {
        this.args.model.page.published = true;
      })
      .catch((response) => {
        if (response && response.status === 403) {
          dispatcher.dispatch('pages', 'buySubscriptionAlert', this.args.model.page);
        }
      })
      .then(() => this.isPublishing = false);
  }

}