import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';
export default class MwMutePosts extends Component {
  @action
  click() {
    dispatcher.dispatch('group', 'muteGroupPosts', this.args.post.groupId, this.args.collection);

    this.closePostDropdown?.();
  }
}
