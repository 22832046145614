import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Home/MwHomeCardBox',
  component: 'MwHomeCardBox',
};

const Template = () => ({
  template: hbs`<Home::MwHomeCardBox/>`,
  context: {},
});

export const Default = Template.bind({});
