import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { scheduleOnce, next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import {
  shiftToUtc,
  shiftFromUtc,
  utcOffsetMillis,
  utcTimeOfDayMillis,
  utcMidnightMillis,
} from 'mewe/utils/datetime-utils';
import { isDefined } from 'mewe/utils/miscellaneous-utils';

export default class MwEventDateSelector extends Component {
  @service account;
  @service dynamicDialogs;

  @tracked timezoneSet;
  @tracked timezoneName;
  @tracked startDateMillis;
  @tracked endDateMillis;
  @tracked startHourMillis;
  @tracked endHourMillis;
  @tracked startHourSuggestion;
  @tracked endHourSuggestion;

  constructor() {
    super(...arguments);

    // timezoneName can be undefined for events created before we introduced 'timeZone' field for events on BE
    // for such case we use timezone from user account info, for new events timezoneName is passed from parent component
    if (!this.args.timezoneName) this.args.updateDate('timezoneName', this.account.activeUser?.timezone);
    // don't show timezone name initially if it's set to users default timezone
    this.timezoneSet = this.args.timezoneName !== this.account.activeUser?.timezone;

    let startTime, endTime;

    if (this.args.startDateTS && this.args.endDateTS) {
      // need to shift to show tz's time in time-picker, offset removed in updateValue
      startTime = shiftFromUtc(this.args.timezoneName, this.args.startDateTS);
      endTime = shiftFromUtc(this.args.timezoneName, this.args.endDateTS);
    } else {
      const now = new Date();
      // offset needs to be added to show tz's time in time-picker, offset removed in updateValue
      const offsetMillis = utcOffsetMillis(this.args.timezoneName, now.getTime());
      startTime =
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours() + 1, 0, 0) + offsetMillis;
      endTime = startTime + 60 * 60 * 1000; // 1h from starttime, 2h from now
    }

    this.startDateMillis = utcMidnightMillis(startTime);
    this.endDateMillis = utcMidnightMillis(endTime);
    this.startHourSuggestion = utcTimeOfDayMillis(startTime);
    this.endHourSuggestion = utcTimeOfDayMillis(endTime);

    // important to run next so changes to properties received from parent will be noticed (SG-29402)
    next(() => {
      if (!this.isDestroying && !this.isDestroyed) this.updateValue();
    });
  }

  @computed('args.skin')
  get allDayId() {
    return `all-day-${this.args.skin}`;
  }

  updateValue() {
    // do not compute timezones for all day events as they should be exactly on same day for every time zone
    if (this.args.isAllDay) {
      this.args.updateDate('startDateTS', this.startDateMillis);
      this.args.updateDate(
        'endDateTS',
        utcMidnightMillis(this.endDateMillis) + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000
      );
    } else {
      this.args.updateDate(
        'startDateTS',
        shiftToUtc(this.args.timezoneName, this.startDateMillis + this.startHourMillis)
      );
      this.args.updateDate('endDateTS', shiftToUtc(this.args.timezoneName, this.endDateMillis + this.endHourMillis));
    }
  }

  @action
  startDateChanged(utcMillis) {
    this.startDateMillis = utcMillis;
    this.dateChanged();
  }

  @action
  endDateChanged(utcMillis) {
    this.endDateMillis = utcMillis;
    this.dateChanged();
  }

  @action
  startTimeChanged(utcMillis) {
    this.startHourMillis = utcMillis;
    this.dateChanged();
  }

  @action
  endTimeChanged(utcMillis) {
    this.endHourMillis = utcMillis;
    this.dateChanged();
  }

  @action
  dateChanged() {
    if (!this.args.isAllDay && (!isDefined(this.startHourMillis) || !isDefined(this.endHourMillis))) {
      return;
    }

    let startTime = this.startDateMillis + (this.args.isAllDay ? 0 : this.startHourMillis),
      endTime = this.endDateMillis + (this.args.isAllDay ? 0 : this.endHourMillis);
    // TODO: IF startHour changed, make endHours jump forward +1h but if endHourMillis changed, forward +1d to endTime!
    if (startTime >= endTime) {
      // +1h to start day
      const startTimeD = new Date(startTime);
      endTime = Date.UTC(
        startTimeD.getUTCFullYear(),
        startTimeD.getUTCMonth(),
        startTimeD.getUTCDate(),
        startTimeD.getUTCHours() + 1,
        0,
        0
      );

      this.endDateMillis = utcMidnightMillis(endTime);
      if (!this.args.isAllDay) {
        //don't call updateValue, need to wait for endHourMillis to be set by time-picker
        const newEndSuggestion = utcTimeOfDayMillis(endTime);

        if (this.endHourSuggestion === newEndSuggestion) {
          // kind of hacky but need a way to force update endHourSuggestion to trigger observers
          this.endHourSuggestion = -1;
          scheduleOnce('afterRender', this, () => {
            if (!this.isDestroyed && !this.isDestroying) {
              this.endHourSuggestion = newEndSuggestion;
            }
          });
        } else this.endHourSuggestion = utcTimeOfDayMillis(endTime);
      } else {
        this.updateValue();
      }
    } else {
      this.updateValue();
    }
  }

  @action
  openTimeZoneDialog() {
    this.dynamicDialogs.openDialog('timezone-dialog', {
      selectedZoneId: this.args.timezoneName,
      onSetZone: (zone) => {
        this.args.updateDate('timezoneName', zone || this.account.activeUser?.timezone);
        this.timezoneSet = isDefined(zone);
        this.updateValue();
      },
    });
  }
}
