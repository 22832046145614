import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set, get, action } from '@ember/object';
import { isEmpty, difference, each, includes } from 'lodash';

import GroupStore from 'mewe/stores/group-store';
import ProfileStore from 'mewe/stores/profile-store';
import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import dispatcher from 'mewe/dispatcher';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class MwGroupPermissions extends Component {
  @service dynamicDialogs;

  @tracked isLoaded = false;
  @tracked isDirty = false;
  @tracked group = GroupStore.getState({ id: this.args.groupId });
  @tracked selectedRole = null;

  @tracked permissions = {
    canPost: {
      checked: false,
      value: 'post',
    },
    canComment: {
      checked: false,
      value: 'comment',
    },
    canInvite: {
      checked: false,
      value: 'invite',
    },
  };

  adminWasUnset = false;

  get member() {
    return this.args.member;
  }

  constructor() {
    super(...arguments);

    this.initialRoleName = this.member.role.name;
    this.initialPermissions = this.member.role.permissions;

    if (!this.member.role?.permissions?.length) {
      // we need to fetch permissions from served for Custom/Admin
      if (this.member.role.name === 'Custom' || this.member.role.name === 'Admin') {
        GroupApi.getPermissions(this.args.groupId, { userIds: this.member.id })
          .then((data) => {
            if (this.isDestroyed || this.isDestroying) return;

            if (data.roles && data.roles.length) {
              const roles = data.roles[0];

              if (roles.permissions) {
                set(this, 'member.role.permissions', roles.permissions);
              } else if (this.groupPermissions[roles.name]) {
                set(this, 'member.role.permissions', this.groupPermissions[roles.name].permissions);
              }

              set(this, 'member.role.origin', roles.origin);
              set(this, 'member.role.name', roles.name);
              this.updatePermissionsFields();
            }
          })
          .finally(() => {
            if (this.isDestroyed || this.isDestroying) return;
            this.setSelectedRole();
            this.isLoaded = true;
          });
      } else {
        const role = this.groupPermissions[this.member.role.name];

        if (role) {
          set(this, 'member.role.permissions', role.permissions);
          this.updatePermissionsFields();
        }
        this.setSelectedRole();
        this.isLoaded = true;
      }
    } else {
      this.updatePermissionsFields();
      this.setSelectedRole();
      this.isLoaded = true;
    }
  }

  get groupPermissions() {
    const roles = [];
    this.group.rolesPermissions.forEach((r) => (roles[r.name] = r));
    return roles;
  }

  get isLimited() {
    return (
      get(this, 'member.role.name') === 'Limited' ||
      (get(this, 'member.role.name') === 'Custom' && get(this, 'member.role.origin') === 'Limited')
    );
  }

  get currentUserIsOwner() {
    return this.group.role === 'Owner';
  }

  get rolesList() {
    let roles = [
      {
        name: 'Admin',
        nameDisplay: __('Admin'),
        isVisible: this.currentUserIsOwner && !this.member.invitation,
      },
      {
        name: 'Contributor',
        nameDisplay: __('Contributor'),
        isVisible: true,
      },
      {
        name: 'Limited',
        nameDisplay: __('Limited'),
        isVisible: true,
      },
      {
        name: 'Viewer',
        nameDisplay: __('Viewer'),
        isVisible: true,
      },
      {
        name: 'Custom',
        nameDisplay: __('Custom'),
        isVisible: false, // will be set after custom changes but not available in select menu
      },
    ];

    return roles;
  }

  setSelectedRole() {
    this.selectedRole = this.rolesList.find((r) => r.name === this.member.role.name);
  }

  setSelectedRoleFromPermissions() {
    let currentPerms = Object.values(this.permissions)
      .filter((p) => p.checked)
      .map((p) => p.value);

    let foundGroupRole = this.group.rolesPermissions.find((role) => {
      if (currentPerms.length === role.permissions.length) {
        return isEmpty(difference(role.permissions, currentPerms));
      }
    });

    if (foundGroupRole) {
      foundGroupRole = foundGroupRole.name;
    }

    if (!foundGroupRole) {
      foundGroupRole = 'Custom';
    }

    if (this.selectedRole.name !== foundGroupRole || foundGroupRole === 'Custom') {
      set(this, 'member.role.name', foundGroupRole);
      set(this, 'member.role.permissions', currentPerms);
      this.setSelectedRole();
      this.updatePermissionsFields();
    }
  }

  updatePermissionsFields() {
    let perms = this.permissions || {},
      rolePerms = this.member.role.permissions;

    each(Object.keys(perms), (p) => {
      set(perms, p + '.checked', includes(rolePerms, perms[p].value));
    });
  }

  restoreInitialPermissions() {
    set(this.member, 'role.name', this.initialRoleName);
    set(this.member, 'role.permissions', this.initialPermissions);
  }

  roleUpdated() {
    if (!this.isLoaded) return;

    const newRole = this.selectedRole.name,
      currentRole = this.initialRoleName;

    if (currentRole === newRole) return;

    if (currentRole === 'Admin') {
      this.adminWasUnset = true;
    }

    if (newRole !== 'Custom' && newRole !== 'Admin') {
      set(this, 'member.role.permissions', this.groupPermissions[newRole].permissions);
      this.updatePermissionsFields();
    }
  }

  @action
  selectRole(role) {
    set(this, 'member.role.name', role.name);
    this.selectedRole = role;
    this.isDirty = this.initialRoleName !== role.name;
    this.roleUpdated();
  }

  @action
  toggleCustomPermission(perm) {
    set(this, 'permissions.' + perm + '.checked', !get(this, 'permissions.' + perm + '.checked'));
    this.setSelectedRoleFromPermissions();
    this.isDirty = true;
  }

  @action
  save() {
    if (!this.isDirty) return;

    let params = {},
      member = this.member,
      memberId = member.id,
      groupId = this.args.groupId,
      currentRole = this.initialRoleName,
      newRole = this.selectedRole.name,
      perms = Object.values(this.permissions)
        .filter((p) => p.checked)
        .map((p) => p.value),
      customSelected = newRole === 'Custom',
      adminSelected = newRole === 'Admin',
      rolePermissions;

    if (!customSelected && !adminSelected) {
      rolePermissions = this.groupPermissions[newRole].permissions;
      rolePermissions = rolePermissions.filter((p) => p !== 'req_moderation');

      if (rolePermissions.length !== perms.length) {
        customSelected = true;
      } else {
        each(perms, (p) => {
          if (rolePermissions.indexOf(p) === -1) {
            customSelected = true;
            return;
          }
        });
      }
    }

    // role.name should never be sent to server as 'Custom', use role.origin in such cases
    if (customSelected) {
      newRole = member.role.origin || newRole;
    }

    if (newRole === 'Viewer' && perms.indexOf('post') !== -1) {
      newRole = 'Contributor';
    }

    if (!adminSelected) {
      params.permissions = {
        members: {
          roles: [
            {
              userId: memberId,
              role: newRole,
            },
          ],
        },
        group: {}, // required by api even thought empty
      };
    }

    if (customSelected) {
      params.permissions.members.roles[0].permissions = perms;
    }

    if (currentRole !== newRole || this.adminWasUnset) {
      if (currentRole === 'Admin' || this.adminWasUnset) {
        params.removeAdminIds = [memberId];
      } else if (newRole === 'Admin') {
        params.addAdminIds = [memberId];
      }
    }

    let rollForward = (userObj) => {
      next(() => {
        if (customSelected) {
          set(userObj, 'role.name', 'Custom');
          set(userObj, 'role.permissions', perms);
        } else if (adminSelected) {
          set(userObj, 'role.name', 'Admin');
        } else {
          set(userObj, 'role.name', newRole);
          if (rolePermissions) {
            set(userObj, 'role.permissions', rolePermissions);
          }
        }
        this.adminWasUnset = false;
      });
    };

    rollForward(member);

    GroupApi.setGroupData(groupId, params)
      .then(() => {
        if (this.isDestroyed || this.isDestroying) return;

        this.isDirty = false;
        this.initialRoleName = this.member.role.name;
        dispatcher.dispatch('group', 'fetchCounters', groupId);
        this.args.onRoleChange?.(this.member);

        let profile = ProfileStore.getState({
          uuid: memberId,
          groupId: groupId,
        });
        if (profile) {
          rollForward(profile);
        }
      })
      .catch((data) => {
        if (data && data.status) {
          FunctionalUtils.error(__(`Couldn't change role`));

          this.restoreInitialPermissions();

          if (data.status === 412) {
            this.dynamicDialogs.openDialog('group-admin-conflict-dialog', {
              member: this.member,
              groupId: this.args.groupId,
            });
          }
        }
      });
  }

  @action
  removeFromGroup() {
    this.args.removeFromGroupAction(this.member);
  }

  @action
  closeDropdown() {
    if (this.isDirty) {
      this.restoreInitialPermissions();
    }
    this.args.closeEditingPermissions();
  }
}
