import Route from 'mewe/routes/route';
import Session from 'mewe/shared/session';

export default class StartRoute extends Route {
  beforeModel() {
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      if (isAuthenticated) {
        window.location.href = '/myworld';
      }
    });
  }
}
