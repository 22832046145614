import ApiUtil from './api';

class Api extends ApiUtil {
  search(params) {
    return this.get(
      `/Desktop/search/mentions`,
      {
        data: params,
      },
      'apiRoot3'
    );
  }
}

export default new Api();
