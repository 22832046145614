import { A } from '@ember/array';
import isUndefined from 'mewe/utils/isUndefined';
import { fromJavaLocale } from 'mewe/utils/datetime-utils';
import Event from 'mewe/stores/models/event-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';

const serializer = JSONSerializer.create();

export const getMonthNames = (locale = 'en', format = 'long') => {
  locale = fromJavaLocale(locale);
  const months = [];

  for (let i = 0; i < 12; ++i) {
    months.push(new Date(Date.UTC(2020, i, 1, 0, 0, 0)).toLocaleDateString(locale, { month: format, timeZone: 'UTC' }));
  }

  return months;
};

export const getWeekDayNames = (locale = 'en', format = 'long') => {
  locale = fromJavaLocale(locale);
  const days = [];

  for (let i = 1; i < 8; ++i) {
    days.push(new Date(Date.UTC(2020, 2, i, 0, 0, 0)).toLocaleDateString(locale, { weekday: format, timeZone: 'UTC' }));
  }

  return days;
};

export const getEventSummaryText = (eventData) => {
  const eventObject = serializer.deserializeOne(Event, eventData),
    getText = (prop, fieldTitle, getPropText = () => eventObject.get(prop), fieldTitlePrefix = '\n\n') => {
      return eventObject.get(prop) ? fieldTitlePrefix + fieldTitle + '\n' + getPropText() : '';
    };

  return (
    getText('fullDateText', __('Date'), undefined, '') +
    getText('fullTimeText', __('Time')) +
    getText('recurrence', __('Repeat'), () => eventObject.get('repeatText')) +
    getText('location', __('Location'), () => eventObject.get('location')) +
    getText('owner', __('Owner'), () => eventObject.get('owner.name')) +
    getText('description', __('Description'))
  );
};

export default {
  getLocalDateFormat() {
    let dateFormat = 'mm/dd/yy';
    switch (document.documentElement.lang) {
      case 'ja':
        dateFormat = 'yy/mm/dd';
        break;
      case 'ko':
        dateFormat = 'yy.mm.dd.';
        break;
      case 'sv':
        dateFormat = 'yy-mm-dd';
        break;
      case 'de':
      case 'da':
      case 'fi':
      case 'he':
      case 'nb':
      case 'pl':
        dateFormat = 'dd.mm.yy';
        break;
      case 'ar':
      case 'fr':
      case 'el':
      case 'es':
      case 'pt-BR':
      case 'it':
        dateFormat = 'dd/mm/yy';
        break;
      case 'nl':
        dateFormat = 'dd-mm-yy';
        break;
      case 'cs':
        dateFormat = 'dd. mm. yy';
        break;
    }

    return dateFormat;
  },

  getLocalDayOfTheWeek(index, locale = 'en') {
    return new Date(Date.UTC(2020, 2, index + 1, 0, 0, 0)).toLocaleDateString(fromJavaLocale(locale), {
      weekday: 'long',
      timeZone: 'UTC',
    });
  },

  getWeekDays(onWeekDay, locale = 'en') {
    return onWeekDay
      .map((d, i) => {
        if (d) {
          return this.getLocalDayOfTheWeek(i, locale);
        }
        return null;
      })
      .filter((d) => d);
  },

  getMonthDayText(startDate, repeatOn, locale) {
    const date = new Date(startDate);

    if (repeatOn === 'OnDayOfMonth') {
      return __('on the {dayNumber, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} day of the month', {
        dayNumber: date.getUTCDate(),
      });
    }

    const year = date.getUTCFullYear(),
      month = date.getUTCMonth(),
      dayOfWeekFormat = { weekday: 'long', timeZone: 'UTC' },
      lastMonthDay = new Date(Date.UTC(year, month + 1, 1, 0, 0) - 1);

    if (date.getUTCDate() + 6 >= lastMonthDay.getUTCDate()) {
      return __('on the last {dayOfWeek} of the month', {
        dayOfWeek: date.toLocaleDateString(locale, dayOfWeekFormat),
      });
    }

    const startDateDayOfMonth = date.getUTCDate(),
      dayOfWeek = date.getUTCDay();

    let dayOfWeekHits = 0;

    for (let t = 1; t <= startDateDayOfMonth; ++t) {
      if (new Date(Date.UTC(year, month, t, 0, 0, 0)).getUTCDay() === dayOfWeek) {
        dayOfWeekHits += 1;
      }
    }

    return __(
      'on the {dayOfWeekNumber, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} {dayOfWeek} of the month',
      {
        dayOfWeekNumber: dayOfWeekHits,
        dayOfWeek: date.toLocaleDateString(locale, dayOfWeekFormat),
      }
    );
  },

  getRepeatOptions() {
    return {
      none: __('None'),
      Daily: __('Daily'),
      Weekly: __('Weekly'),
      Monthly: __('Monthly'),
      Yearly: __('Yearly'),
    };
  },

  getRecurrenceSummaryText(event, locale = 'en') {
    if (event && event.recurrence) {
      return this.getSummaryText(this.getRepeatObjFromRecurrence(event.recurrence), event.startDate, locale);
    } else {
      return '';
    }
  },

  getSummaryText(options, startDate, locale = 'en') {
    locale = fromJavaLocale(locale);
    const tz = 'UTC';

    let text,
      formattedEndsOn,
      endsAfter = parseInt(options.endsAfter);

    if (options.endsOn) {
      let endsOnDate = new Date(options.endsOn);

      if (isNaN(endsOnDate.getTime())) {
        options.endsOn = null;
      } else {
        formattedEndsOn = endsOnDate.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: tz,
        });
      }
    }

    switch (options.repeatValue) {
      case 'Daily':
        if (options.every > 1) {
          if (options.ends === 'after' && endsAfter) {
            text = __('Every {daysCount} days, {count} time', { daysCount: options.every, count: endsAfter });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Every {daysCount} days, until {date}', { daysCount: options.every, date: formattedEndsOn });
          } else {
            text = __('Every {daysCount} days', { daysCount: options.every });
          }
        } else {
          if (options.ends === 'after' && endsAfter) {
            text = __('Daily, {count} time', { count: endsAfter });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Daily, until {date}', { date: formattedEndsOn });
          } else {
            text = __('Daily');
          }
        }
        break;
      case 'Weekly':
        let weekDays = this.getWeekDays(options.onWeekDay, locale);
        let onWeekDays = '';

        if (weekDays.length) {
          onWeekDays = __(' on {weekDays}', { weekDays: weekDays.join(', ') });
        }

        if (options.every > 1) {
          if (options.ends === 'after' && endsAfter) {
            text = __('Every {weekCount} weeks{onWeekDays}, {count} time', {
              weekCount: options.every,
              count: endsAfter,
              onWeekDays: onWeekDays,
            });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Every {weekCount} weeks{onWeekDays}, until {date}', {
              weekCount: options.every,
              date: formattedEndsOn,
              onWeekDays: onWeekDays,
            });
          } else {
            text = __('Every {weekCount} weeks{onWeekDays}', { weekCount: options.every, onWeekDays: onWeekDays });
          }
        } else {
          if (options.ends === 'after' && endsAfter) {
            text = __('Weekly{onWeekDays}, {count} time', { count: endsAfter, onWeekDays: onWeekDays });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Weekly{onWeekDays}, until {date}', { date: formattedEndsOn, onWeekDays: onWeekDays });
          } else {
            text = __('Weekly{onWeekDays}', { onWeekDays: onWeekDays });
          }
        }
        break;
      case 'Monthly':
        let onMonthDayText = this.getMonthDayText(startDate, options.repeatOn, locale, tz);
        if (options.every > 1) {
          if (options.ends === 'after' && endsAfter) {
            text = __('Every {monthCount} months {onMonthDayText}, {count} time', {
              monthCount: options.every,
              count: endsAfter,
              onMonthDayText: onMonthDayText,
            });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Every {monthCount} months {onMonthDayText}, until {date}', {
              monthCount: options.every,
              date: formattedEndsOn,
              onMonthDayText: onMonthDayText,
            });
          } else {
            text = __('Every {monthCount} months {onMonthDayText}', {
              monthCount: options.every,
              onMonthDayText: onMonthDayText,
            });
          }
        } else {
          if (options.ends === 'after' && endsAfter) {
            text = __('Monthly {onMonthDayText}, {count} time', { count: endsAfter, onMonthDayText: onMonthDayText });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Monthly {onMonthDayText}, until {date}', {
              date: formattedEndsOn,
              onMonthDayText: onMonthDayText,
            });
          } else {
            text = __('Monthly {onMonthDayText}', { onMonthDayText: onMonthDayText });
          }
        }
        break;
      case 'Yearly':
        if (options.every > 1) {
          if (options.ends === 'after' && endsAfter) {
            text = __('Every {yearsCount} years, {count} time', { yearsCount: options.every, count: endsAfter });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Every {yearsCount} years, until {date}', { yearsCount: options.every, date: formattedEndsOn });
          } else {
            text = __('Every {yearsCount} years', { yearsCount: options.every });
          }
        } else {
          if (options.ends === 'after' && endsAfter) {
            text = __('Yearly, {count} time', { count: endsAfter });
          } else if (options.ends === 'on' && options.endsOn) {
            text = __('Yearly, until {date}', { date: formattedEndsOn });
          } else {
            text = __('Yearly');
          }
        }
        break;
    }

    return text;
  },

  getRecurrenceFromRepeatObj(repeatObj) {
    let type = repeatObj.repeatValue === 'Monthly' ? repeatObj.repeatValue + repeatObj.repeatOn : repeatObj.repeatValue;
    let recurrence = {
      type: type,
      every: repeatObj.every,
    };

    if (repeatObj.ends === 'after') {
      recurrence.endsAfter = parseInt(repeatObj.endsAfter);
    } else if (repeatObj.ends === 'on') {
      recurrence.endsOn = repeatObj.endsOn;
    }

    if (repeatObj.repeatValue === 'Weekly') {
      let daysOfWeek = repeatObj.onWeekDay
        .map((v, d) => {
          if (v) {
            //days are from 0-6 starting sunday and need to be 1-7 starting monday
            return d === 0 ? 7 : d;
          }
          return null;
        })
        .filter((d) => d);

      if (daysOfWeek.length) {
        recurrence.daysOfWeek = daysOfWeek;
      }
    }

    return recurrence;
  },

  getRepeatObjFromRecurrence(recurrence) {
    let repeatObj = {
      every: recurrence.every,
    };

    if (recurrence.type === 'MonthlyOnDayOfMonth') {
      repeatObj.repeatValue = 'Monthly';
      repeatObj.repeatOn = 'OnDayOfMonth';
    } else if (recurrence.type === 'MonthlyOnDayOfWeek') {
      repeatObj.repeatValue = 'Monthly';
      repeatObj.repeatOn = 'OnDayOfWeek';
    } else {
      repeatObj.repeatValue = recurrence.type;
      repeatObj.repeatOn = 'OnDayOfMonth';
    }

    if (recurrence.endsAfter) {
      repeatObj.endsAfter = recurrence.endsAfter;
      repeatObj.ends = 'after';
    }

    if (recurrence.endsOn) {
      repeatObj.endsOn = recurrence.endsOn;
      repeatObj.ends = 'on';
    }

    if (recurrence.daysOfWeek) {
      let onWeekDay = A();

      for (let i = 0; i < 7; i++) {
        let day = i === 0 ? 7 : i;
        onWeekDay.push(recurrence.daysOfWeek.indexOf(day) !== -1);
      }
      repeatObj.onWeekDay = onWeekDay;
    } else {
      let onWeekDay = A();
      for (let i = 0; i < 7; i++) {
        onWeekDay.push(false);
      }
      repeatObj.onWeekDay = onWeekDay;
    }

    return repeatObj;
  },

  getReminderOptions() {
    return [
      { time: 'none', text: __('None') },
      { time: 0, text: __('When event starts') },
      { time: 5, text: __('{count} minute before', { count: 5 }) },
      { time: 10, text: __('{count} minute before', { count: 10 }) },
      { time: 30, text: __('{count} minute before', { count: 30 }) },
      { time: 60, text: __('1 hour before') },
      { time: 1440, text: __('1 day before') },
    ];
  },

  getEventObjectFromEventData(eventData) {
    const params = {
      eventType: eventData.eventType,
      name: eventData.name,
      description: eventData.description,
      location: eventData.location,
      endDate: eventData.endDate,
      startDate: eventData.startDate,
      everyoneCanInvite: eventData.everyoneCanInvite,
      allDay: eventData.allDay,
      moderated: eventData.moderated,
    };

    // there might be no imageId if it was deleted from MC (SG-31037)
    if (eventData.imageId) {
      params.image = { id: eventData.imageId };
    }

    if (eventData.recurrence) {
      params.recurrence = eventData.recurrence;
    }

    if (isUndefined(eventData.reminder) || eventData.reminder == 'none') {
      params.reminder = null; // deletiion, removal of object field
    } else {
      params.reminder = parseInt(eventData.reminder, 10);
    }

    return params;
  },
};
