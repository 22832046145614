import Component from '@glimmer/component';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class EmojiSendersUser extends Component {
  @service router;
  @service account;

  @reads('args.user') user;

  @action
  openProfile(user) {
    this.router.transitionTo('app.publicid', user.profileId);
    this.args.closeEmojiSendersDialog?.();
  }
}
