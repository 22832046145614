import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import GroupApi from 'mewe/api/group-api';
import dispatcher from 'mewe/dispatcher';

export default class MwJoinGroup extends Component {
  @service router;

  @tracked blockInviting = false;

  @action
  accept() {
    const callback = () => {
      window.location.href = `/group/${this.args.group.id}?isFirstGroupOpening=true`;
    };

    dispatcher.dispatch('group', 'acceptGroupInvitation', this.args.group, {
      context: 'notification_center',
    }, callback);
  }

  @action
  decline() {
    if (this.blockInviting) {
      GroupApi.declineAndBlockGroupInvitation(this.args.group.id).then(() => {
        window.location.href = '/myworld';
      });
    } else {
      GroupApi.declineGroupInvitation(this.args.group.id).then(() => {
        window.location.href = '/myworld';
      });
    }
  }
}
