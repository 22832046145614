import { htmlSafe } from '@ember/template';
import config from 'mewe/config';
import storage from 'mewe/shared/storage';
import CurrentUserStore from 'mewe/stores/current-user-store';

/**
 * import EnvironmentUtils from 'mewe/utils/environment-utils';
 */
export default {
  // adds host, adds /api/v2 if not already in href
  addImgHost(href) {
    const host = config.environment == 'local' ? '' : config.imgHost || config.cHost;
    return host + (href.indexOf('/api/v2') === 0 ? '' : '/api/v2' + href);
  },

  //for local testing of images you need to add a line to /etc/hosts
  //127.0.0.1      mewe.loc c.mewe.loc
  //and enable session cookie in web.xml and start web server with proxy to application
  //if noApiPrefix is true, then return only imgHost, no api prefix - some urls are returned with api prefix from backend already
  getImgHost(noApiPrefix, isPublicContent) {
    const apiPrefix = noApiPrefix ? '' : '/api/v2';
    const host = isPublicContent ? config.publicImgHost : config.imgHost || config.cHost;

    return host + apiPrefix;
  },

  getCdnHost() {
    return config.cdnHost;
  },

  getApiHost() {
    // because of uploading on localhost when connecting to external host like master
    return config.environment == 'local' ? '' : htmlSafe(config.apiHost);
  },

  // used for videos, disappearing content, etc
  getMediaHost(noApiPrefix) {
    var apiPrefix = noApiPrefix ? '' : '/api/v2',
      host = config.environment == 'local' ? '' : config.cHost;

    return host + apiPrefix;
  },

  // when e.g. sharing a link to a post, calendar or invitation
  getHost() {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '')
    );
  },

  processRedirectionUrl(url) {
    if (!url) return '';

    try {
      if (new URL(url).host) {
        return url;
      }
    } catch (e) {
      // passed url in not valid url, it can be a relative path.
      // check if it starts with / in case BE forgot about it for relative path
      if (!url.startsWith('/')) {
        url = '/' + url;
      }

      return this.getHost() + url;
    }
  },

  /**
   * Helper function for proper url manipulation
   */
  getLocation(href) {
    let l = document.createElement('a');
    l.href = href;

    return l;
  },

  correctHostnameAndPort(dataUrl) {
    let location = this.getLocation(dataUrl);

    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      location.pathname
    );
  },

  getCurrentUserAvatar(size, _groupId, user) {
    var avatarSize = size || '150x150',
      groupId = _groupId || '';

    return (
      '/api/v2/photo/profile/' +
      avatarSize +
      '/' +
      user.get('id') +
      '?' +
      (groupId !== '' ? 'group=' + groupId + '&' : '') +
      'f=' +
      user.fprint
    );
  },
};
