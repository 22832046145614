import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import { toMillis, daysDiff, fromJavaLocale } from 'mewe/utils/datetime-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';

export const getDatePrint = (date, nowMillis, dontShowTimeIfToday, tz, locale) => {
  date = new Date(toMillis(date));

  if (isNaN(date.getTime())) {
    return __('N/A');
  }

  locale = fromJavaLocale(locale);

  const now = new Date(nowMillis),
    diffDays = Math.abs(daysDiff(date.getTime(), nowMillis)),
    isSameYear = date.getUTCFullYear() === now.getUTCFullYear();

  if (isSameYear && date.getUTCMonth() === now.getUTCMonth() && date.getUTCDate() === now.getUTCDate()) {
    if (dontShowTimeIfToday) return __('Today');
    else return date.toLocaleTimeString(locale, { timeZone: tz, hour: 'numeric', minute: 'numeric' });
  } else if (diffDays < 6) {
    return date.toLocaleDateString(locale, { timeZone: tz, weekday: 'long' });
  } else if (isSameYear) {
    return date.toLocaleDateString(locale, {
      timeZone: tz,
      month: 'short',
      day: 'numeric',
    });
  } else {
    return date.toLocaleDateString(locale, {
      timeZone: tz,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
};

export default buildHelper((values, opts) => {
  const val = values[0];

  if (!val) return '';

  const tz = CurrentUserStore.getState().get('timezone'),
    locale = CurrentUserStore.getState().get('locale'),
    now = Date.now();

  return htmlSafe(getDatePrint(val, now, opts.dontShowTimeIfToday, tz, locale));
});
