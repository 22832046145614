import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

export default class MwHomeCardDetailsComponent extends Component {

  get cardStyle() {
    let style = '';

    if (this.args.cardsCount) {
      style += `width: calc(100% / ${this.args.cardsCount});`;
    }

    return htmlSafe(style);
  }
}
