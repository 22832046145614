import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { isMobile } from 'mewe/shared/utils';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsSubscription extends Component {
  @alias('args.model.appPage.page') page;

  @service purchase;
  @service dynamicDialogs;

  @tracked pageItem;
  @tracked itemLoaded;

  constructor() {
    super(...arguments);

    this.purchase.getItemPromise('page').then((item) => {
      if (this.isDestroyed || this.isDestroying) return;
      this.pageItem = item;
      this.itemLoaded = true;
    });
  }

  @action
  onInsert() {
    if (isMobile() && !this.page.hasActiveSubscription) {
      this.purchaseSubscription();
    }
  }

  @computed('page.{hasActiveSubscription,subscription.provider}')
  get showManageSubLink() {
    return (
      this.page.hasActiveSubscription &&
      (this.page.subscription.provider === 'desktop' || this.page.subscription.provider === 'paypal')
    );
  }

  @action
  managePayment() {
    this.dynamicDialogs.openDialog('credit-card-info-dialog');
  }

  @action
  purchaseSubscription() {
    dispatcher.dispatch('purchase', 'checkout', null, [this.pageItem], { pageData: this.page });
  }
}
