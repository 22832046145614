/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Scrolling from 'mewe/utils/scrolling-utils';
import GroupApi from 'mewe/api/group-api';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { begin, end } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';
import { maxResultsMembersList } from 'mewe/constants';
import { next } from '@ember/runloop';

//TODO: Need to refactor the code
export default class AppGroupMembersBlocked extends Component {
  @reads('args.model.group') group;

  @service router;

  scrolling = Scrolling();

  @tracked users = A();
  @tracked searchResults = A();
  @tracked isSearching = false;
  @tracked loading = false;

  isMoreResults = false;

  offset = 0;

  lastSearchStr;

  constructor() {
    super(...arguments);
    if (this.group) {
      this.loadUsers();
    }
    // eslint-disable-next-line ember/no-observers
    addObserver(this, 'args.model.membersSearch', this, this.blockedUsersSearchChange);
  }

  willDestroy() {
    this.scrolling.unbindScrollDown();

    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    removeObserver(this, 'args.model.membersSearch', this, this.blockedUsersSearchChange);
    super.willDestroy(...arguments);
  }

  loadSearchResults(searchStr) {
    if (!searchStr) {
      return;
    }

    const searchTermChanged = searchStr !== this.lastSearchStr;

    if (searchTermChanged) {
      this.lastSearchStr = searchStr;
    }

    const params = {
      str: searchStr,
      offset: this.searchResults.length,
      maxResults: maxResultsMembersList,
    };

    GroupApi.blockedUserSearch(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        begin();

        if (searchTermChanged) {
          this.searchResults = A();
        }

        if (data.users && data.users.length) {
          this.searchResults.pushObjects(data.users);

          if (data.users.length === maxResultsMembersList) {
            this.scrolling.bindScrollDown(() => this.loadSearchResults(searchStr));
          } else {
            this.scrolling.unbindScrollDown();
          }
        }

        if (this.searchResults.length === 0) {
          this.loading = false;
        }
        end();
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }

  get usersToDisplay() {
    return this.isSearching ? this.searchResults : this.users;
  }

  blockedUsersSearchChange() {
    if (this.args.model.membersSearch) {
      this.search(this.args.model.membersSearch);
    } else {
      this.isSearching = false;
      this.loading = false;
      this.searchResults = A();

      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = null;
      } else {
        this.scrolling.bindScrollDown(() => this.loadUsers());
      }
    }
  }

  search(term) {
    const timeoutTime = 500;

    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }

    if (term) {
      this.loading = true;

      this.searchTimeoutId = setTimeout(() => {
        this.isSearching = true;
        this.searchResults = A();
        this.searchTimeoutId = null;
        this.filter = null;
        this.loadSearchResults(term);
      }, timeoutTime);
    }
  }

  loadUsers() {
    if (this.loading) {
      return;
    }

    const params = {
      offset: this.users.length || 0,
      maxResults: maxResultsMembersList,
    };

    next(() => {
      this.loading = true;
    });

    GroupApi.blockedList(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (data.users.length >= maxResultsMembersList) {
          this.scrolling.bindScrollDown(() => this.loadUsers());
        } else {
          this.scrolling.unbindScrollDown();
        }

        this.users.pushObjects(data.users);
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }

  @action
  userUnblocked(user) {
    this.users.removeObject(user);
    if (this.searchResults.length) {
      this.searchResults.removeObject(user);
    }
    if (!this.users.length) {
      this.router.transitionTo('app.group.members.all', this.group.id);
    }

    set(this, 'group.applicantsCount', this.group.applicantsCount - 1);
  }
}
