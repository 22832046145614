import { A } from '@ember/array';
import { escape, reduce, each } from 'lodash';
import { getThemeFromHtmlClass } from 'mewe/utils/color-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';

export function getActingUsersHTML(currentUserId, notification, withoutUserId) {
  let actingUsers = A();

  each(notification.actingUsers || A(), (u) => {
    // remove withoutUserId if provided
    if (u.id === withoutUserId) {
      return false;
    }
    // remove current user from actingUsers, if not pageOwner
    if (u.id === currentUserId && !notification.postedByMyPage) {
      return false;
    }

    // replace user with page if user is page admin/owner (acted as page, not as user) SG-31780
    let userActingAsPage =
      notification.page &&
      notification.system === 'page' &&
      notification.notificationType !== 'emojis' && // emoji is added as user also by page owner/admin
      u.isAdmin;

    if (userActingAsPage) {
      u = notification.page;
    }

    // prevent duplicated elements
    if (!actingUsers.find((el) => el.id === u.id)) {
      actingUsers.pushObject(u);
    }
  });

  return _getActingUserHTML(actingUsers, notification.actingUsersCount - notification.actingUsers.length);
}

const _getActingUserHTML = (actingUsers, usersDivide) => {
  var separator = __(', '),
    usersText,
    userNames;

  if (!actingUsers || !actingUsers.length) {
    return '<span class="notification_text_user"></span>';
  } else if (actingUsers.length === 1) {
    return getActingUserHTML(null, null, actingUsers[0]);
  } else {
    userNames = actingUsers.map((u) => u.name);
    usersText = escape(
      reduce(userNames, (a, b) => {
        return a + separator + b;
      })
    );
  }

  if (usersDivide > 0) {
    usersText = __('{userNames} and {count} other', {
      userNames: usersText,
      count: usersDivide,
    });
  }

  return `<span class='notification_text_user'>${usersText}</span>`;
};

export function getActingUserHTML(notification, onlyText, user) {
  let url = '';
  const rawUser = user;
  user = user || (notification.actingUsers && notification.actingUsers[0]);

  if (user) {
    const badges = getActingUserBadgesHtml(notification, rawUser);

    if (onlyText) {
      return `${escape(user.name)}${badges}`;
    }

    if (user.isPage) {
      url = `/page/${user.id}`;
    } else {
      url = `/i/${user.contactInviteId}`;
    }

    return `
      <a href='${url}' class='notification_text_user'>
        ${escape(user.name)}${badges}
      </a>`;
  }

  return '';
}

export function getActingUserHandle(notification) {
  const handle = notification.actingUser?.publicLinkId || notification.actingUsers[0]?.publicLinkId;

  return handle ? `<a href="/${escape(handle)}" class='notification_text_user'>@${escape(handle)}</a>` : '';
}

export function getCommentText(currentUserId, notification) {
  let text = '';

  //someone commented on post where I commented on before
  if (currentUserId !== notification.postData.author.id) {
    if (notification.actingUsers.length > 1) {
      if (notification.page && notification.system === 'page') {
        text = __('{userNames} also commented on a post: ', {
          userNames: getActingUsersHTML(currentUserId, notification),
          authorName: escape(notification.postData.author.name),
        });
      } else {
        text = __("{userNames} also commented on {authorName}'s post: ", {
          userNames: getActingUsersHTML(currentUserId, notification),
          authorName: escape(notification.postData.author.name),
        });
      }
    } else if (notification.page && notification.system === 'page') {
      text = __('{userName} commented on a post: ', {
        userName: getActingUserHTML(notification),
      });
    } else {
      text = __("{userName} commented on {authorName}'s post: ", {
        userName: getActingUserHTML(notification),
        authorName: escape(notification.postData.author.name),
      });
    }
  } else {
    if (!notification.postData || !notification.postData.postType) {
      return text;
    }

    const params = {},
      postType = notification.postData.postType;

    if (notification.actingUsers.length > 1) {
      params.userNames = getActingUsersHTML(currentUserId, notification);

      switch (postType) {
        case 'photo':
          text = __('{userNames} also commented on your photo: ', params);
          break;
        case 'link':
          if (notification.postGifUrl) {
            text = __('{userNames} also commented on your GIF: ', params);
          } else {
            text = __('{userNames} also commented on your link: ', params);
          }
          break;
        case 'doc':
          text = __('{userNames} also commented on your document: ', params);
          break;
        case 'video':
          text = __('{userNames} also commented on your video: ', params);
          break;
        case 'event2':
        case 'recurring_event':
          text = __('{userNames} also commented on your event: ', params);
          break;
        case 'photos':
        case 'multi_photo':
        case 'album':
          text = __('{userNames} also commented on your photos: ', params);
          break;
        case 'multi_doc':
          text = __('{userNames} also commented on your documents: ', params);
          break;
        default:
          // text
          // no info about link, can't check if gif url
          text = __('{userNames} also commented on your post: ', params);
          break;
      }
    } else {
      params.userName = getActingUserHTML(notification);

      switch (postType) {
        case 'photo':
          text = __('{userName} commented on your photo: ', params);
          break;
        case 'link':
          if (notification.postGifUrl) {
            text = __('{userName} commented on your GIF: ', params);
          } else {
            text = __('{userName} commented on your link: ', params);
          }
          break;
        case 'doc':
          text = __('{userName} commented on your document: ', params);
          break;
        case 'video':
          text = __('{userName} commented on your video: ', params);
          break;
        case 'event2':
        case 'recurring_event':
          text = __('{userName} commented on your event: ', params);
          break;
        case 'photos':
        case 'multi_photo':
        case 'album':
          text = __('{userName} commented on your photos: ', params);
          break;
        case 'multi_doc':
          text = __('{userName} commented on your documents: ', params);
          break;
        default:
          // text
          // no info about link, can't check if gif url
          text = __('{userName} commented on your post: ', params);
          break;
      }
    }
  }

  return text;
}

function getActingUserById(notification, userId) {
  return notification.actingUsers.find((u) => u.id === userId);
}

export function getCommentReplyText(currentUserId, notification) {
  //someone replied to my comment
  if (currentUserId === notification.commentData.parentAuthor) {
    if (notification.actingUsers.length > 2) {
      // there are always current user and the user who replied
      const withoutId =
        notification.commentData.author.id !== notification.commentData.parentAuthor
          ? notification.commentData.parentAuthor
          : null;

      return __('{userNames} also replied to your comment: ', {
        userNames: getActingUsersHTML(currentUserId, notification, withoutId),
      });
    } else {
      return __('{userName} replied to your comment: ', {
        userName: getActingUserHTML(notification),
      });
    }
  } else {
    let author;

    if (notification.commentData.parentByPage) {
      author = notification.page;
    } else {
      author = getActingUserById(notification, notification.commentData.parentAuthor);
    }

    if (notification.commentData.postedByPage) {
      // if postedByPage then keep only page in actingUsers,
      // other user could be kept there only in purpose of setting 'author' above
      // (SG-31017 - "PageA" replied to "UserB's" comment)
      notification.actingUsers = notification.actingUsers.filter((u) => u.isPage);
    }

    if (notification.actingUsers.length > 2) {
      // there are always current user and the user who replied
      const withoutId =
        notification.commentData.author.id !== notification.commentData.parentAuthor
          ? notification.commentData.parentAuthor
          : null;

      return __("{userNames} also replied to {authorName}'s comment: ", {
        userNames: getActingUsersHTML(currentUserId, notification, withoutId),
        authorName: escape(author ? author.name : ''),
      });
    } else {
      return __("{userName} replied to {authorName}'s comment: ", {
        userName: getActingUserHTML(notification),
        authorName: escape(author ? author.name : ''),
      });
    }
  }
}

export function getActingUserBadgesHtml(notification, rawUser) {
  const user = rawUser || notification?.actingUsers?.[0];
  const favoriteBadge = user?.isPage ? false : CurrentUserStore.get('favorites')?.includes(user?.id);
  const isDarkTheme = getThemeFromHtmlClass() === 'dark';
  const badges = {
    ...user?.badges,
    favorite: favoriteBadge,
  };

  let badgesHtml = '';
  for (let [key, value] of Object.entries(badges)) {
    if (value === false) {
      continue;
    }

    badgesHtml += `
        <span class="badge-box"">
          <svg class="badge-img icn_badge_${key}${isDarkTheme ? '_dm' : ''}">
            <use xlink:href="#icn_badge_${key}${isDarkTheme ? '_dm' : ''}"></use>
          </svg>
        </span>
      `;
  }

  return `<div class="c-mw-badge badge">${badgesHtml}</div>`;
}
