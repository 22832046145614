import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import dispatcher from 'mewe/dispatcher';
import { each, sortBy } from 'lodash';

export default class MwPostPoll extends Component {
  @service dynamicDialogs;

  @reads('args.post') post;

  @reads('args.post.poll') poll;

  @tracked timeLeft;

  @tracked timeLeftFull;

  @tracked showAll;

  maxOptionsShown = 3;

  @action
  onInsert() {
    //only fire this when poll has ends set and it's in the future
    if (this.poll.ends && this.poll.ends > new Date().getTime() / 1000) {
      this.calculateTimeLeft();

      this.interval = window.setInterval(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.calculateTimeLeft();
        }
      }, 30000);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.clearInterval(this.interval);
  }

  @computed('maxOptionsShown', 'poll.closed', 'poll.options.@each.votes', 'showAll')
  get filteredOptions() {
    each(this.poll.options, (o, index) => {
      set(o, 'index', index);
    });

    if (this.poll.closed) {
      // 999999999 is used to order by votes descending but keep index sort
      // if array was just sorted by votes and index and then reversed
      // it will be showing options with same votes in reversed order instead of in created
      let options = sortBy(this.poll.options, [
        function (o) {
          return 999999999 - o.votes;
        },
        'index',
      ]);

      if (this.showAll) {
        return options;
      }

      return options.slice(0, this.maxOptionsShown);
    }

    if (this.showAll) {
      return this.poll.options;
    }

    if (this.poll.options) {
      return this.poll.options.slice(0, this.maxOptionsShown);
    }
  }

  get selectedIndex() {
    return this.poll.options.findIndex((option) => option.selected);
  }

  @computed(
    'args.postInPostbox',
    'poll.{closed,votesCount}',
    'post.{isCurrentUserPostOwner,postedByPage,permissions.remove}'
  )
  get canClose() {
    if (this.args.postInPostbox) {
      return false;
    }

    const pollCanBeClosed = !this.poll.closed && this.poll.votesCount > 0; // is already closed, got some votes

    if (!pollCanBeClosed) {
      return false;
    }

    if (this.post.postedByPage) {
      // admin can close other's admins or owner's poll - SG-20218
      return this.post.permissions?.remove && pollCanBeClosed;
    }

    return this.post.isCurrentUserPostOwner && pollCanBeClosed;
  }

  @computed(
    'args.isPostDialog',
    'poll.{closeVotesFetched,closed,userVoted}',
    'post.{page.isOwnerAdmin,isCurrentUserPostOwner}'
  )
  get showResults() {
    const conditions =
      this.poll.userVoted || this.poll.closed || this.post.isCurrentUserPostOwner || this.post.page?.isOwnerAdmin;
    const shouldShow = this.args.isPostDialog ? this.poll.closeVotesFetched && conditions : conditions;

    return shouldShow;
  }

  @computed('poll.closed', 'post.{closed,scheduled,poll.closed}')
  get displayTimer() {
    if (this.post.scheduled) {
      return Boolean(this.poll.closed && this.post.scheduled);
    }
    return true;
  }

  calculateTimeLeft() {
    // for scheduled posts need to count difference between post release date and poll end, for non-scheduled counting time since current moment
    // one second subtracte to display exactly same time as user set during post creation (good UX), instead of one minute left as it's displayed just after normal poll creation
    const startsMillis = this.post.scheduled ? this.post.createdAt * 1000 - 1000 : new Date().getTime(),
      daysHoursMinutesLeft = this.poll.getDaysHoursMinutesLeft(startsMillis),
      daysLeft = daysHoursMinutesLeft.days,
      hoursLeft = daysHoursMinutesLeft.hours,
      minutesLeft = daysHoursMinutesLeft.minutes,
      hasSecondsLeft = this.poll.ends > startsMillis / 1000;

    let timeLeft = null;
    let timeLeftFull = '';

    if (daysLeft) {
      timeLeft = __('{count} day left', { count: daysLeft });
    } else if (hoursLeft) {
      timeLeft = __('{count} hour left', { count: hoursLeft });
    } else if (minutesLeft) {
      timeLeft = __('{count} minute left', { count: minutesLeft });
    } else if (hasSecondsLeft) {
      //show 1 minute left when post has less than a minute
      timeLeft = __('{count} minute left', { count: 1 });
    } else {
      this.poll.set('closed', true);
      this.afterPollClosed();
      window.clearInterval(this.interval);
    }

    this.timeLeft = timeLeft;

    if (daysLeft) {
      timeLeftFull += __('{count} day', { count: daysLeft });
    }
    if (hoursLeft) {
      if (timeLeftFull) {
        timeLeftFull += ', ';
      }
      timeLeftFull += __('{count} hour', { count: hoursLeft });
    }
    if (minutesLeft) {
      if (timeLeftFull) {
        timeLeftFull += ', ';
      }
      timeLeftFull += __('{count} minute', { count: minutesLeft });
    } else if (hasSecondsLeft) {
      //show 1 minute left when post has less than a minute
      timeLeftFull += __('{count} minute', { count: 1 });
    }

    if (timeLeftFull) {
      this.timeLeftFull = timeLeftFull;
    }
  }

  afterPollClosed() {
    // this can be passed to api callback so check if component isDestroyed
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.poll.closed && !this.poll.closeVotesFetched) {
      dispatcher.dispatch('poll', 'refreshVotes', this.post);
    }
  }

  @action
  closePoll() {
    if (this.args.isSharedPost) {
      return;
    }

    dispatcher.dispatch('poll', 'close', this.post, this.afterPollClosed.bind(this));
  }

  @action
  openImage(index) {
    if (this.args.isSharedPost || this.post?.isPublicContent) {
      return;
    }

    this.dynamicDialogs.openDialog('media-dialog', {
      pollPost: this.post,
      dataProvided: true,
      mediaType: 'poll',
      postId: this.post.id,
      index,
      isGroupPreview: this.post.isGroupPreview,
      allowMultipleInstances: true,
    });
  }
}
