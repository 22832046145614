import EmberObject from '@ember/object';
import { A } from '@ember/array';
import {
  fromJavaLocale,
  calendarDateFormat,
  calendarDateFormatInUtc
} from 'mewe/utils/datetime-utils';
import { getMonthNames, getWeekDayNames } from 'mewe/utils/event-utils';
import { isUndefined } from 'mewe/utils/miscellaneous-utils';

export default {
  getMonthDetails(opts) {
    const locale = fromJavaLocale(opts.locale || document.documentElement.lang);
    const monthMap = getMonthNames(locale, 'long');
    const todayDate = new Date().toLocaleDateString(locale, calendarDateFormat); // shown in local tz

    let weekMap = getWeekDayNames(locale, 'short');

    if (this.isWeekStartOnMonday(locale)) {
      weekMap.push(weekMap.shift());
    }

    // initially opened month should display date from parent component or current month if param not passed
    let initialMonthYear, year, month;

    initialMonthYear = opts.utcMillis ? new Date(opts.utcMillis) : new Date();
    year = isUndefined(opts.year) ? initialMonthYear.getUTCFullYear() : opts.year;
    month = isUndefined(opts.month) ? initialMonthYear.getUTCMonth() : opts.month;

    const monthStr = monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    const firstDay = this.getFirstDay(year, month, locale);
    const numberOfDays = this.getNumberOfDays(year, month);
    const rows = 6;
    const cols = 7;
    let monthDays = A();
    let currentDay = null;
    let index = 0;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = this.getDayDetails({
          locale,
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });
        currentDay.setProperties({
          columnNumber: col,
          rowNumber: row,
        });
        monthDays.pushObject(currentDay);
        index++;
      }
    }

    return EmberObject.create({ year, month, weekMap, todayDate, monthStr, monthDays });
  },

  getDayDetails(args) {
    let date = args.index - args.firstDay;
    let day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
    }
    const prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
    const _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;

    const monthModifier = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    let month = args.month + monthModifier;

    const yearModifier = month < 0 ? -1 : month > 11 ? 1 : 0;
    let year = args.year + yearModifier;

    if (yearModifier === -1) month = 11;
    else if (yearModifier === 1) month = 0;

    const formattedDate = new Date(Date.UTC(year, month, _date, 0, 0, 0)).toLocaleDateString(
      args.locale,
      calendarDateFormatInUtc
    );
    const utcDayStart = Date.UTC(year, month, _date, 0, 0, 0);
    const utcDayEnd = Date.UTC(year, month, _date, 23, 59, 59, 999);

    return EmberObject.extend({
      date: _date,
      day: day,
      month: month,
      year: year,
      formattedDate: formattedDate,
      utcDayStart: utcDayStart,
      utcDayEnd: utcDayEnd,
      eventsToRender: A(),
      allEvents: A(),
    }).create();
  },

  getFirstDay(year, month, locale) {
    let firstDay = new Date(year, month).getDay(); // 0 - Sunday, 1- Monday ...

    if (this.isWeekStartOnMonday(locale)) {
      return firstDay > 0 ? firstDay - 1 : 6;
    } else {
      return firstDay;
    }
  },

  getNumberOfDays(year, month) {
    return 40 - new Date(year, month, 40).getDate();
  },

  isWeekStartOnMonday(locale) {
    // americas + china + japan + korea (espanyol treated as south america, bigger population than spain)
    const sundayFirstLocales = ['en', 'pt-BR', 'zh-CN', 'zh-HK', 'ja', 'es', 'ko'];
    return sundayFirstLocales.indexOf(locale) === -1;
  },
};
