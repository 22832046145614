import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import ChallengeNames from 'mewe/shared/challenge-constants';
import injectHcaptcha from 'mewe/shared/inject-hcaptcha';
import { injectScript } from 'mewe/shared/inject-script';
import config from 'mewe/config';
import * as Sentry from '@sentry/ember';
import { fetchChallenges } from 'mewe/fetchers/fetch-challenges';
import { ds } from 'mewe/stores/ds';

/*
 * 1. initialisation: fetchChallanges => initChallange
      is automatically done when component is rendered and required properties are passed
      required params: {challengeOrigin, arkoseKey, hcaptchaSiteKey, hcaptchaElemClass, challengeReadyCallback, afterChallengeCallback}
 * 2. startChallange (when setting afterChallangeCallback is observed)
 */

export default class MwCaptcha extends Component {
  options = {
    mandatory: [
      'challengeOrigin', // {'lock' | 'login' | 'registration'}
      'arkoseKey',
      'hcaptchaSiteKey',
      'hcaptchaElemClass',
      'challengeReadyCallback',
      'afterChallengeCallback', // setting callback is observed to be triggered to run
    ],
  };

  enforcement = null;
  challengeName = '';
  challengeRetries = 0;

  constructor() {
    super(...arguments);

    // run challange when callback is passed from parent as a way of triggering action in child component
    addObserver(this, 'args.afterChallengeCallback', this, this.startChallange);
  }

  @action
  onInsert() {
    if (!ds.challenges.items.length) {
      fetchChallenges().then((challengeName) => {
        this.challengeName = ds.challenges.items[0];
        this.initChallenge();
      });
    } else {
      this.challengeName = ds.challenges.items[0];
      this.initChallenge();
    }
  }

  willDestroy() {
    window.setupEnforcement = null;
    window.onHcaptchaInit = null;
    window.onHcaptchaError = null;
    window.onHcaptchaSubmit = null;

    removeObserver(this, 'args.afterChallengeCallback', this, this.startChallange);
  }

  initChallenge() {
    if (this.challengeName === ChallengeNames.arkose) {
      this.enforcement = null;

      let arkoseParams = this.args.challengeOrigin === 'lock' ? '?user_suspicion=2&data[user_suspicion]=2' : '';
      let arkoseConfigData = this.args.challengeOrigin === 'lock' ? { user_suspicion: 2 } : {};

      window.setupEnforcement = (enforcement) => {
        enforcement.setConfig({
          onReady: () => {
            this.enforcement = enforcement;
            this.args.challengeReadyCallback();
          },
          data: arkoseConfigData,
        });
      };

      injectScript(
        config.noArkose ? '/fake_arkose.js' : `//api.arkoselabs.com/v2/${this.args.arkoseKey}/api.js${arkoseParams}}`,
        [{ key: 'data-callback', value: 'setupEnforcement' }],
        { defer: true }
      );
    } else if (this.challengeName === ChallengeNames.hcaptcha) {
      window.onHcaptchaInit = () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.args.challengeReadyCallback();
      };

      window.onHcaptchaError = (err) => {
        this.retryOnHcaptchaError(err, this.args.challengeOrigin);
      };

      if (
        window.hcaptcha &&
        this.args.hcaptchaElemClass &&
        document.querySelectorAll(`.${this.args.hcaptchaElemClass}`).length
      ) {
        window.hcaptcha.render(document.querySelectorAll(`.${this.args.hcaptchaElemClass}`)[0], {
          sitekey: this.args.hcaptchaSiteKey,
          'error-callback': 'onHcaptchaError',
          'data-callback': 'onHcaptchaSubmit',
          'data-size': 'invisible',
        });
        this.args.challengeReadyCallback();
      } else {
        injectHcaptcha('onHcaptchaInit');
      }
    } else {
      this.args.challengeReadyCallback();
    }
  }

  startChallange() {
    if (!this.args.afterChallengeCallback) return;

    if (this.challengeName === ChallengeNames.arkose) {
      const runArkoseChallenge = () => {
        this.enforcement.setConfig({
          onReset: () => {},
          onCompleted: (response) => {
            this.args.afterChallengeCallback(response.token, ChallengeNames.arkose, () => {
              this.enforcement.setConfig({ onReset: runArkoseChallenge });
            });
          },
        });

        this.enforcement.run();
      };

      runArkoseChallenge();
    } else if (this.challengeName === ChallengeNames.hcaptcha) {
      window.onHcaptchaSubmit = (token) => {
        this.args.afterChallengeCallback(token, ChallengeNames.hcaptcha, () => {
          this.retryHcaptchaChallenge(this.getHcaptchaId());
        });
      };

      window.hcaptcha.execute(this.getHcaptchaId());
    } else {
      this.args.afterChallengeCallback(null, null, () => window.location.reload());
    }
  }

  retryOnHcaptchaError(err, section) {
    Sentry.captureException(new Error(`hCaptcha error: ${err}`), {
      tags: {
        challenge: ChallengeNames.hcaptcha,
        section: section,
      },
    });

    let timeoutMs = 1000;

    if (this.captchaTimeout) {
      window.clearTimeout(this.captchaTimeout);
      timeoutMs = 2000;
    }

    this.captchaTimeout = window.setTimeout(() => {
      const captchaId = this.getHcaptchaId();
      window.hcaptcha.reset(captchaId);
      window.hcaptcha.execute(captchaId);
    }, timeoutMs);
  }

  retryHcaptchaChallenge(captchaId) {
    if (this.challengeRetries > 4) {
      FunctionalUtils.showDefaultErrorMessage();
    } else {
      this.challengeRetries += 1;
      window.hcaptcha.reset(captchaId);
      window.hcaptcha.execute(captchaId);
    }
  }

  getHcaptchaId() {
    const elem = document.querySelectorAll(`.${this.args.hcaptchaElemClass} iframe`)[0];

    if (!this.args.hcaptchaElemClass || !elem) {
      return null;
    } else {
      return elem.getAttribute('data-hcaptcha-widget-id');
    }
  }
}
