import { A } from '@ember/array';
import EmberObject, { computed, set } from '@ember/object';
import { forEach } from 'lodash';
import { scheduleOnce } from '@ember/runloop';

import EmojiParser from 'mewe/stores/text-parsers/emojis-parser';

// emojiCounts, userEmojis => emojis
export const emojisList = (emojiCounts, userEmojis = A()) => {
  if (!emojiCounts)
    console.warn('[MeWe] model-emojis-list emojisList() - passed "emojiCounts" parameter is ', emojiCounts);

  let emojis = A();
  let index = 0;

  if (typeof emojiCounts === 'object' && !emojiCounts.hasOwnProperty('length')) {
    emojiCounts = [emojiCounts];
  }

  if (!Array.isArray(emojiCounts)) emojiCounts = [];

  emojiCounts = Object.assign({}, ...emojiCounts);

  forEach(emojiCounts, function (number, key) {
    if (number === 0) return;
    index++;

    emojis.pushObject(
      EmberObject.create({
        unicode: key,
        counter: number,
        index: index,
        html: EmojiParser.toDisplay(key, { noStickers: true }),
        isUser: userEmojis.indexOf(key) > -1,
      })
    );
  });

  return emojis;
};

export let modelEmojisList = {
  // having this private list helps when you don't want replace all items in array - replacing all caused 'blinking' when adding new emoji to post/comment
  // TODO find how to do this without _emojis list (MW)
  init() {
    this._super(...arguments);
    this._emojis = A();
  },

  emojisItems: computed('emojis.emojiCounts', 'emojis.userEmojis', 'emojisReady', function () {
    const emojiCounts = this.get('emojis.emojiCounts'),
      userEmojis = this.get('emojis.userEmojis'),
      emojiCountsSize = emojiCounts.length,
      newEmojisList = emojisList(emojiCounts, userEmojis);

    if (this.get('_emojis.length') < emojiCountsSize) {
      const arr = newEmojisList.slice(this.get('_emojis.length'), emojiCountsSize);

      this.get('_emojis').pushObjects(arr);

      // if emoji added by current user then set isUser flag after rendering so this change can be observed for runing animation
      // emojisInitialized prevents runing animation when initial emojis list calculation is done
      if (this.get('emojisInitialized')) {
        if (arr && arr.length === 1 && arr[0].isUser) {
          arr[0].isUser = false;
          scheduleOnce('afterRender', this, () => {
            if (this.isDestroying || this.isDestroyed) return;
            set(arr[0], 'isUser', true);
          });
        }
      } else {
        this.set('emojisInitialized', true);
      }
    } else {
      // check if some emoji was not removed completely from list
      this._emojis = A(
        this._emojis.filter((oldEmojiObj) => {
          if (oldEmojiObj) {
            let emojiStillExists = newEmojisList.find((e) => e.unicode === oldEmojiObj.unicode);
            return emojiStillExists;
          }
        })
      );

      // update ONLY values which differ
      newEmojisList.forEach((newEmojiObj, index) => {
        Object.keys(newEmojiObj).forEach((key) => {
          let oldEmojiObj = this._emojis.objectAt(index);

          if (newEmojiObj[key] !== oldEmojiObj[key]) {
            /*
              debugs:
              console.log(key);
              console.log(emojiObj[key]);
              console.log(oldEmojiObj[key]);
              console.log('--');
              */

            set(oldEmojiObj, key, newEmojiObj[key]);
          }
        });
      });
    }

    return this._emojis;
  }),
};
