import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import EventsApi from 'mewe/api/events-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EventUtils from 'mewe/utils/event-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwEventSettingsMembership extends Component {
  @reads('args.eventData') eventData;

  @service account;

  @tracked loaded;
  @tracked isSaving;
  @tracked eventType;
  @tracked everyoneCanInvite;

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      this.eventDataReady();
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      this.everyoneCanInvite = this.eventData.everyoneCanInvite;
      this.eventType = this.eventData.eventType;
      this.loaded = true;
    }
  }

  @computed('isEventOwner', 'eventData.participationType')
  get leaveEventDisabled() {
    // Undecided/Invited - those are not participants so they can't leave event
    return (
      this.eventData.participationType === 'Undecided' ||
      this.eventData.participationType === 'Invited' ||
      this.eventData.participationType === 'Owner'
    );
  }

  @computed('loaded', 'eventType', 'eventData.eventType')
  get isSaveVisible() {
    return this.loaded && this.eventType !== this.eventData.eventType;
  }

  @computed('isSaving', 'eventData.eventType')
  get isSavingOrDisabled() {
    return this.isSaving || this.eventData.eventType === 'private';
  }

  @action
  everyoneCanInviteChange(value) {
    this.everyoneCanInvite = value;

    let params = EventUtils.getEventObjectFromEventData(this.eventData);
    params.everyoneCanInvite = this.everyoneCanInvite;

    this.isSaving = true;

    EventsApi.setEventDetails(this.eventData.id, params)
      .then(() => {
        FunctionalUtils.info(__('Event Settings have been successfully saved.'));
        dispatcher.dispatch(
          'events',
          'saveEventState',
          'rootEvent.everyoneCanInvite',
          params.everyoneCanInvite,
          this.eventData.id
        );
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.everyoneCanInvite = this.eventData.everyoneCanInvite;
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  @action
  saveSettings() {
    let params = EventUtils.getEventObjectFromEventData(this.eventData);
    params.eventType = this.eventType;

    this.isSaving = true;

    EventsApi.setEventDetails(this.eventData.id, params)
      .then((data) => {
        FunctionalUtils.info(__('Event Settings have been successfully saved.'));
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.eventType', data.eventType, this.eventData.id);
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.eventType = params.eventType === 'private' ? 'open' : 'private';
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  @action
  confirmLeaving() {
    if (!this.leaveEventDisabled) {
      dispatcher.dispatch('events', 'confirmLeavingEvent', { eventId: this.eventData.id, isEventPage: true });
    }
  }

  @action
  eventTypeChange(eventType) {
    this.eventType = eventType;
  }
}
