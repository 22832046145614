import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend({
  id: attr('string'),
  newFollowers: attr('object', {
    defaultValue: function () {
      return {
        count: 0,
        change: 0,
      };
    },
  }),
  engagement: attr('object', {
    defaultValue: function () {
      return {
        count: 0,
        change: 0,
      };
    },
  }),
  emojis: attr('object', {
    defaultValue: function () {
      return {
        count: 0,
        change: 0,
      };
    },
  }),
  comments: attr('object', {
    defaultValue: function () {
      return {
        count: 0,
        change: 0,
      };
    },
  }),
  shares: attr('object', {
    defaultValue: function () {
      return {
        count: 0,
        change: 0,
      };
    },
  }),
  postCount: attr('number', { defaultValue: 0 }),
  _links: attr('object'),
});

model.reopenClass({
  resourceName: 'page-analytics-overview',
});

export default model;
