import Component from '@glimmer/component';
import { addObserver, removeObserver } from '@ember/object/observers';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MwValidation extends Component {
  @tracked hidden = false;

  blurHidden() {
    this.hidden = false;
  }

  showOnError() {
    if (this.args.visibleOnError) this.hidden = false;
  }

  @action
  hide() {
    if (this.args.visibleOnError) this.hidden = true;
  }

  @action
  onInsert(element) {
    const prev = element.previousElementSibling;

    if (prev?.nodeName === 'INPUT') {
      prev.addEventListener('blur', this.blurHidden(false));
    }

    this.blurHiddenBind = this.blurHidden.bind(this);

    addObserver(this, 'args.visibleOnError', this.showOnError);
  }

  @action
  onDestroy(element) {
    const prev = element.previousElementSibling;

    if (prev?.nodeName === 'INPUT') {
      prev.removeEventListener('blur', this.blurHiddenBind);
    }

    removeObserver(this, 'args.visibleOnError', this.showOnError);
  }
}
