import { each } from 'lodash';
import { action, set } from '@ember/object';

import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import toDisplay from 'mewe/stores/text-parsers/to-display';
import { parsersDefaultBasic } from 'mewe/stores/text-parsers/to-display';
import dispatcher from 'mewe/dispatcher';

import { maxResultsMembersList } from 'mewe/constants';
import MemberBase from '../app-group/member-base';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

//TODO: Need to refactor the code

export default class AppGroupMembersPending extends MemberBase {
  @service router;
  @service dynamicDialogs;

  @tracked applications = A();

  constructor() {
    super(...arguments);
    this.groupApplicationNewWsHandlerBind = this.groupApplicationNewWsHandler.bind(this);
    this.groupApplicationRemoveWsHandlerBind = this.groupApplicationRemoveWsHandler.bind(this);
    PS.Sub('group.application.new', this.groupApplicationNewWsHandlerBind);
    PS.Sub('group.application.remove', this.groupApplicationRemoveWsHandlerBind);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    PS.Unsub('group.application.new', this.groupApplicationNewWsHandlerBind);
    PS.Unsub('group.application.remove', this.groupApplicationNewWsHandlerBind);
  }

  groupApplicationRemoveWsHandler(data) {
    if (data.groupId !== this.group?.id) {
      return;
    }

    each(data.applicationIds, (id) => {
      let application = this.applications.find((app) => app.id === id);
      if (application) {
        this.applications.removeObject(application);
      }
    });
  }

  groupApplicationNewWsHandler(application) {
    if (application.groupId !== this.group.id) {
      return;
    }

    application = this.parseQuestions(application);

    if (!this.applications.find((app) => app.id === application.id)) {
      this.applications.pushObject(application);
    }
  }

  loadMembers() {
    if (this.loading) {
      return;
    }

    const params = {
      offset: this.applications.length || 0,
      maxResults: maxResultsMembersList,
    };

    next(() => {
      this.loading = true;
    });

    GroupApi.applicantsList(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        if (!data.applications || !data.applications.length) {
          this.router.transitionTo('app.group.members.all', this.group.id);
        }

        if (data.applications.length >= maxResultsMembersList) {
          this.scrolling.bindScrollDown(() => this.loadMembers());
        } else {
          this.scrolling.unbindScrollDown();
        }

        data.applications.map((app) => this.parseQuestions(app));

        this.applications.pushObjects(data.applications);
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }

  parseQuestions(application) {
    if (application.answers && application.answers.length) {
      each(application.answers, (a) => {
        a.answerDisplay = toDisplay(a.answer, parsersDefaultBasic);
        a.questionDisplay = toDisplay(a.question, parsersDefaultBasic);
      });
    }

    return application;
  }

  @action
  acceptAll() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      message: __('You are about to approve {count} user into your group. Are you sure you want to do this?', {
        count: this.args.model.counters.pending,
      }),
      noEscaping: true,
      cancelButtonText: __('No, cancel'),
      okButtonText: __('Yes, approve all'),
      onConfirm: () => {
        GroupApi.approveAllApplications(this.group.id).then(() => {
          FunctionalUtils.info(__('All applications have been approved'));

          set(this, 'group.membersCount', this.group.membersCount + this.group.applicantsCount);
          set(this, 'group.applicantsCount', 0);

          let ids = this.applications.map((a) => a.id);
          let userIds = this.applications.map((a) => a.user.id);
          PS.Pub('group.application.remove', {
            groupId: this.group.id,
            userIds: userIds,
            applicationIds: ids,
          });

          this.applications = A();
          dispatcher.dispatch('group', 'fetchCounters', this.group.id);
          this.router.transitionTo('app.group.members.all', this.group.id);
        });
      },
    });
  }

  @action
  declineAll() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      message: __('You are about to decline {count} user. Are you sure you want to do this?', {
        count: this.args.model.counters.pending,
      }),
      noEscaping: true,
      cancelButtonText: __('No, cancel'),
      okButtonText: __('Yes, decline all'),
      onConfirm: () => {
        GroupApi.rejectAllApplications(this.group.id).then(() => {
          FunctionalUtils.info(__('All applications have been declined'));

          set(this, 'group.applicantsCount', 0);

          let ids = this.applications.map((a) => a.id);
          let userIds = this.applications.map((a) => a.user.id);
          PS.Pub('group.application.remove', {
            groupId: this.group.id,
            userIds: userIds,
            applicationIds: ids,
          });

          this.applications = A();
          dispatcher.dispatch('group', 'fetchCounters', this.group.id);
          this.router.transitionTo('app.group.members.all', this.group.id);
        });
      },
    });
  }

  @action
  applicationApproved(application) {
    this.applications.removeObject(application);
    PS.Pub('group.application.remove', {
      groupId: this.group.id,
      userIds: [application.user.id],
      applicationIds: [application.id],
    });
    if (!this.applications.length) {
      this.router.transitionTo('app.group.members.all', this.group.id);
    }

    set(this, 'group.applicantsCount', this.group.applicantsCount - 1);
  }

  @action
  applicationRejected(application) {
    this.applicationApproved(application);
    PS.Pub('group.application.remove', {
      groupId: this.group.id,
      userIds: [application.user.id],
      applicationIds: [application.id],
    });
  }
}
