/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { Theme } from 'mewe/constants';

export default class MwPhotoAlbum extends Component {
  @service account;
  @service dynamicDialogs;

  @tracked albumDropdownOpened = false;

  constructor() {
    super(...arguments);
  }

  openAlbumMediaDialog(post) {
    const options = {
      post: post,
      galleryView: true,
      mediaType: 'album',
      groupId: post.group?.id,
      userId: post.userId,
      pageId: post.pageId,
      album: post.albumPreview,
      scope: this.args.scope,
      isMyCloud: this.args.scope === Theme.MYCLOUD,
      isCurrentUserPostOwner: post.isCurrentUserPostOwner,
      allowMultipleInstances: true,
    };

    this.dynamicDialogs.openDialog('media-dialog', options);
  }

  get showOptions() {
    return this.args.canShowOptions || this.args.userId === this.account.activeUser.id;
  }

  @action
  openAlbum() {
    if (this.args.post) {
      this.openAlbumMediaDialog(this.args.post);
    } else {
      this.args.openAlbumStream(this.args.album);
    }
  }

  @action
  openEditAlbumDialog() {
    // hasFullAlbumRights - if user has permission to edit all photos in album or only those which he uploaded
    let hasFullAlbumRights = false;
    let albumScope = this.args.scope;

    // in 'group' and 'group event' only group owner/admin can edit all photos
    if (this.args.scope === Theme.GROUP || (this.args.scope === Theme.EVENT && this.args.event?.groupId)) {
      // !in case of group events we edit group album!
      if (this.args.event?.groupId) {
        albumScope = Theme.GROUP;
      }

      hasFullAlbumRights = this.args.group?.isOwnerAdmin || this.args.event?.group?.isOwnerAdmin;
    }
    // in 'event' (non-group) only event owner can edit all photos
    else if (this.args.scope === Theme.EVENT) {
      hasFullAlbumRights = this.args.event?.isOwner;
    }
    // in case of PAGE/MYCLOUD/PROFILE -> if user can edit album then he has full rights
    else {
      hasFullAlbumRights = this.args.album.canEdit;
    }

    this.dynamicDialogs.openDialog('edit-album-dialog', {
      album: this.args.album,
      scope: albumScope,
      scopeId: this.args.event?.groupId || this.args.group?.id || this.args.event?.id || this.args.page?.id,
      hasFullAlbumRights: hasFullAlbumRights,
    });
  }

  @action
  closeDropdown() {
    this.albumDropdownOpened = false;
  }
}
