/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import FollowingApi from 'mewe/api/following-api';
import dispatcher from 'mewe/dispatcher';

export default class FollowButton extends Component {
  @service account;

  @tracked showFollowingDropdown;

  get user() {
    return this.args.user;
  }

  get isUserFavorite() {
    return this.account.favorites?.includes(this.user.id);
  }

  get showFollowButton() {
    return !this.user.isMe && this.user.publicLinkId; // checking publicLinkId to know if profile was fetched
  }

  get isFollowingMe() {
    return this.user.follower && !this.isFollowRequestSent;
  }

  get isUserFollowedByMe() {
    return this.user.following;
  }

  get isFollowRequestSent() {
    return !isEmpty(this.user.followRequestSent);
  }

  get followOptions() {
    return {
      user: this.user,
      groupId: this.args.group?.id,
      eventId: this.args.event?.id,
    };
  }

  @action
  toggleFollowingDropdown() {
    this.showFollowingDropdown = !this.showFollowingDropdown;
  }

  @action
  toggleFavoriteUser() {
    if (this.isUserFavorite) {

      dispatcher.dispatch('contact', 'removeUserFromFavorite',
        this.user,
        () => this.user.set('favorite', false),
        () => this.user.set('favorite', true)
      );

    } else {
      if (this.user.muted) {
        this.toggleMutedFeed.perform();
      }


      dispatcher.dispatch('contact', 'addUserToFavorite',
        this.user,
        () => this.user.set('favorite', true),
        () => this.user.set('favorite', false)
      );
    }
  }

  @task
  *toggleMutedFeed() {
    if (this.user.muted) {
      yield FollowingApi.removeMute(this.user.id);
      this.user.set('muted', false);
    } else {
      yield FollowingApi.makeMute(this.user.id);
      this.user.set('muted', true);
    }
  }

  @action
  followUser() {
    dispatcher.dispatch('contact', 'followUser', this.followOptions);
  }

  @action
  unfollowUser() {
    dispatcher.dispatch('contact', 'unfollowUser', this.followOptions);
    this.toggleFollowingDropdown();
  }

  @action
  cancelRequestSent() {
    dispatcher.dispatch('contact', 'cancelRequestSent', this.followOptions);
  }
}
