import Component from '@glimmer/component';
import { alias, sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { scheduleOnce } from '@ember/runloop';

import Scrolling from 'mewe/utils/scrolling-utils';
import dispatcher from 'mewe/dispatcher';
import { loadMoreNotifications } from 'mewe/fetchers/fetch-pages-notifications';
import { getElHeight, getWindowHeight, getOffset } from 'mewe/utils/elements-utils';

export default class AppPageNotifications extends Component {
  @alias('model.page') page;

  notificationsSorting = ['updatedAt:desc'];
  @sort('args.model.notifications.items', 'notificationsSorting') notifications;

  @action
  onInsert(element) {
    this.element = element;
    this.scrolling = Scrolling();
    this.isHeightFilled = false;

    if (this.args.model.notifications.items.length) {
      this.bindScroll();
    }

    addObserver(this, 'args.model.notifications.items.length', this.bindScroll);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'args.model.notifications.items.length', this.bindScroll);
  }

  bindScroll() {
    // after initial load we check if notifications fill the height of page,
    // if not then we instantly load more, otherwise scrolling wouldn't work

    if (this.isHeightFilled) {
      const n = this.args.model.notifications;

      if (n.nextPage && !n.isFetching && !n.isFetchingMore) {
        this.scrolling.bindScrollDown(() => this.loadMore(), 1200, 500);
      }
    } else {
      // wait until notifications are rendered in order to be able
      // to calculate the height of the list
      scheduleOnce('afterRender', this, () => {
        this.checkIfHeightFilled();
      });
    }
  }

  checkIfHeightFilled() {
    const contentEl = this.element.querySelector('.notifications-content');

    // there is more space available than loaded notifications occupy, so we want to load more immediately
    if (getOffset(contentEl).top + getElHeight(contentEl) < getWindowHeight()) {
      this.loadMore();
    } else {
      this.isHeightFilled = true;
      this.bindScroll();
    }
  }

  @action
  markAllAsRead() {
    dispatcher.dispatch('pages', 'notificationMarkVisitedAll', { id: this.args.model.page.id });
    this.notifications.forEach((el) => el.set('visited', true));
  }

  @action
  loadMore() {
    if (this.args.model.notifications.nextPage) {
      loadMoreNotifications({
        id: this.args.model.page.id,
        ref: this.args.model.notifications.items[this.args.model.notifications.items.length - 1].updatedAt,
      });
    }
  }
}
