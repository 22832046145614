import { map, isString, difference } from 'lodash';
import Verbose from 'mewe/utils/verbose';

const _prefix = 'c-';
const hasPrefix = (el) => el.indexOf(_prefix) > -1;
const cutPrefix = (el) => el.replace(_prefix, '');
const cName = (arr) => {
  if (isString(arr)) arr = [arr];

  let firstValue = '';
  arr.forEach((el) => {
    if (isString(el) && hasPrefix(el)) {
      firstValue = cutPrefix(el);
    }
  });

  return firstValue;
};

export const checkOptions = (name, options, passed = {}) => {
  name = cName(name);

  const verbose = Verbose({ prefix: `[${name}]`, enabled: true });

  if (verbose.isEnabled) {
    let allOptions = [].concat(options.mandatory, options.optional);

    if (allOptions.length)
      map(difference(Object.keys(passed), allOptions), (o) =>
        verbose.log(`You passed: "${o}" - but it's not defined in possible options in component.`)
      );

    map(difference(options.mandatory, Object.keys(passed)), (o) => verbose.log(`Missing: "${o}"`));
  }
};
