/* eslint-disable lines-between-class-members */
import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

export default class Storyblok extends Route {
  @service('storyblok') storyblok;

  model(params) {
    const { path } = params;

    return this.storyblok.loadStory(`cms/${path}`);
  }
}
