import ApiUtil from './api';

class Api extends ApiUtil {
  addBookmark(params) {
    return this.post(`/bookmarks`, {
      data: JSON.stringify(params),
    });
  }

  removeBookmark(bookmarkId) {
    return this.del(`/bookmarks/bookmark/${bookmarkId}`);
  }

  getBookmarks(params) {
    return this.getNextPageOr('/bookmarks', params);
  }
}

export default new Api();
