import { htmlSafe } from '@ember/template';
import Helper from '@ember/component/helper';
import Util, { getDisplaySVG } from 'mewe/utils/emoji-utils';
import { isString, includes } from 'lodash';
import Emoji from 'mewe/utils/emoji-utils';
import PS from 'mewe/utils/pubsub';

var emojisReady = false;

export default Helper.extend({
  emojisReady: false,

  init() {
    this._super(...arguments);

    if (Emoji.isHashMapReady()) {
      emojisReady = true;
    } else {
      let callback = () => {
        emojisReady = true;
        this.recompute();
        PS.Unsub('emoji.hashmap.ready', callback);
      };

      PS.Sub('emoji.hashmap.ready', callback);
    }
  },

  compute(value, options) {
    let emoji;

    if (!emojisReady) return ''; //htmlSafe(value); #SG-14561

    if (isString(value[0])) {
      emoji = Util.emoji(value[0]);
    } else {
      emoji = value[0];
    }

    if (!emoji) {
      console.log('Emoji passed to emoji helper is not defined ', value);
      return '';
    }

    const tone = Util.get('selectedSkinTone');

    if (
      !options.noTone &&
      includes(emoji.diversity || includes(emoji.keywords, 'diversity')) &&
      tone > 0 &&
      !emoji.shortname.match(/.*?_tone(.)|:tone[0-9]:/g)
    ) {
      let skinnedEmoji = Util.skins()[emoji.shortname][tone - 1];
      return htmlSafe(getDisplaySVG(skinnedEmoji, options));
    } else {
      return htmlSafe(getDisplaySVG(emoji, options));
    }
  },
});
