import config from 'mewe/config';
import { isBoolean, each } from 'lodash';
import assign from 'object-assign';
import { serverTo_server } from 'mewe/utils/emoji-utils';
import Session from 'mewe/shared/session';
import axios from 'axios';
import { createDeferred as _createDeferred, toUrlParams } from 'mewe/shared/utils';
import RequestError from 'mewe/utils/RequestError';

const logout = () => {
  const loginUrl = '/login';
  const nextRedirect = location.pathname + location.hash;

  Session.logout().finally(() => {
    window.location = nextRedirect.length > 1 ? `${loginUrl}?next=${nextRedirect}` : loginUrl;
  });
};

const getDefaultOptions = () => {
  const _options = {
    method: 'POST',
    data: {},
    headers: { 'content-type': 'application/json; charset=UTF-8' },
  };

  const csrfToken = Session.getCsrfToken();

  if (csrfToken) {
    _options.headers['X-CSRF-Token'] = csrfToken;
  }

  return _options;
};

export const createDeferred = () => {
  var deffered = _createDeferred();
  return deffered.promise;
};

// watchout, it modifies options object
const legacyServerText = (options) => {
  if (options && options.data) {
    if (typeof options.data == 'object') {
      if (options.data.text) {
        options.data.text = serverTo_server(options.data.text);
      }
      if (options.data.message) {
        options.data.message = serverTo_server(options.data.message);
      }
    } else {
      var json = JSON.parse(options.data);

      if (json.text && !isBoolean(json.text)) {
        json.text = serverTo_server(json.text);
        options.data = JSON.stringify(json);
      }
      if (json.message) {
        json.message = serverTo_server(json.message);
        options.data = JSON.stringify(json);
      }
    }
  }
};

const clean = (obj) => {
  var ret = Array.isArray(obj) ? [] : {};
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] !== null) {
      ret[p] = obj[p];
    }
  return ret;
};

export default class Api {
  isDefined(value) {
    return typeof value !== 'undefined' && value !== null;
  }

  createDeferred() {
    return createDeferred();
  }

  post(url, options, apiKey) {
    legacyServerText(options);
    return this.request(
      url,
      assign(
        {
          method: 'POST',
        },
        options
      ),
      apiKey
    );
  }

  get(url, options, apiKey) {
    return this.request(
      url,
      assign(
        {
          method: 'GET',
        },
        options
      ),
      apiKey
    );
  }

  put(url, options, apiKey) {
    legacyServerText(options);
    return this.request(
      url,
      assign(
        {
          method: 'PUT',
        },
        options
      ),
      apiKey
    );
  }

  del(url, options, apiKey) {
    return this.request(
      url,
      assign(
        {
          method: 'DELETE',
        },
        options
      ),
      apiKey
    );
  }

  getNextPageOr(url, params, apiKey) {
    if (params && params.nextPage) return this.get(this.fixNextPageParams(params.nextPage, params), null, apiKey);

    return this.get(url, { data: params }, apiKey);
  }

  // backend can forget to add or update some params to the next page url
  fixNextPageParams(nextPage, params = {}) {
    if (!nextPage) return nextPage;

    each(Object.keys(params), (key) => {
      // some api calls omit passing limit/maxResultsc when fetching the next page, but it's correct in nextPage url, so it's ignored
      if (key === 'nextPage' || key === 'maxResults' || key === 'limit') return;

      const value = params[key],
        uriParam = key + '=';

      if (nextPage.indexOf(uriParam) === -1) {
        if (value != null && value != undefined)
          nextPage = nextPage + (nextPage.indexOf('?') == -1 ? '?' : '&') + uriParam + encodeURIComponent(value);
      } else {
        if (value != null && value != undefined) {
          nextPage = nextPage.replace(
            new RegExp('\\b(' + uriParam + ').*?(&|$)'),
            '$1' + encodeURIComponent(value) + '$2'
          );
        } else {
          // remove empty param
          nextPage = nextPage.replace(new RegExp('([?&])?\\b' + uriParam + '.*?(&|$)'), (match, p1, p2) => {
            if (p1 === '?') return p2 === '&' ? p1 : '';
            else return p1;
          });
        }
      }
    });

    return nextPage;
  }

  serialize(obj) {
    if (Array.isArray(obj)) return JSON.stringify(obj);
    else if (typeof obj === 'object' && obj !== null) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      return str.join('&');
    }
  }

  cleanEmptyProperties(obj) {
    if (!obj) return obj;
    switch (typeof obj) {
      case 'object':
        if (obj instanceof FormData) return obj;
        return clean(obj);
      case 'string':
        return JSON.stringify(this.cleanEmptyProperties(JSON.parse(obj)));
      default:
        return null;
    }
  }

  request(url, options, apiKey) {
    if (url && url.indexOf('/api/') === 0 && apiKey) {
      if (url.indexOf('/api/cache/') === 0) {
        url = url.slice('/api/cache/'.length + 2); // +2 for v2 or v3 part
      } else {
        url = url.slice('/api/'.length + 2); // +2 for v2 or v3 part
      }
    }
    options.data = this.cleanEmptyProperties(options.data);
    var promise = this.createDeferred();

    this.authAndPerformRequest(promise, url, options, apiKey);
    return promise;
  }

  // we need to be authenticated and have accessToken to perform any request, that's why every request is wrapped in authentication promise
  // in most cases it will simply check if there is token and if it's not expired and return it immediately
  authAndPerformRequest(promise, url, options, apiKey) {
    let doRequest = () => {
      let baseUrl;

      // apiRoot may already exist in url from backend
      if (url.indexOf(config.apiRoot) > -1 || url.indexOf(config.apiRoot3) > -1) baseUrl = url;
      else baseUrl = config.apiRoot + url;

      if (typeof apiKey === 'string' && apiKey.length > 0 && config.hasOwnProperty(apiKey)) {
        baseUrl = config[apiKey] + url;
      }

      // axios is not adding urlParams to GET requests
      if (options.method === 'GET' && options.data) baseUrl += `?${this.toUrlParams(options.data)}`;

      let _options = assign({ url: baseUrl }, getDefaultOptions(), options);

      // https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
      if (options.contentType === 'application/x-www-form-urlencoded') {
        _options.headers['content-type'] = options.contentType;
        _options.data = this.toUrlParams(options.data);
      }

      // DSNP related requests https://sgrouples.atlassian.net/browse/SG-40475
      if (options.traceId) {
        _options.headers['X-Trace-Id'] = options.traceId;
      }

      // DSNP related: https://sgrouples.atlassian.net/browse/SG-43336
      // this header is needed to pass login/signup requests via DSNP without captcha params
      // and in such case captcha is verified earlier in process
      if (options.xDsnp) {
        _options.headers['X-DSNP'] = true;
      }

      // console.log('_options', _options);
      let req = axios(_options);

      req
        .then((response) => promise.resolve(response.data))
        .catch((error) => {
          if (error.response && error.response.status == 401 && !options.non401) {
            logout();
          } else {
            return promise.reject(new RequestError(`API Error, url: ${baseUrl}`, { cause: error }));
          }
        });

      // -------------------------------
      // FOR COLLECTING DATA FOR API mock
      if (window.APIMOCK) {
        req.then((_data) => {
          if (options.type == 'GET') {
            var o = this.serialize(options.data);
            if (o && o.length) baseUrl += '?' + o;
          }

          window.APIMOCK[baseUrl] = { _200: _data };
        });
        // -------------------------------
      }

      promise.request = req;
    };

    return doRequest();
  }

  toUrlParams(params) {
    return toUrlParams(params);
  }
}
