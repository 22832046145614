import config from 'mewe/config';
import * as configcat from 'configcat-js';
import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[ConfigCat]', color: 'red', enabled: true }).log;
import CurrentUserStore from 'mewe/stores/current-user-store';
import Session from 'mewe/shared/session';

export function getConfigCatFlagPromise(flagName, skipAuthentication) {
  const promise = new Promise((resolve) => {
    let sdkKey = config.configCatSDKKey;
    verbose(`sdkKey is taken from config: ${!!sdkKey}`);

    if (!sdkKey) {
      sdkKey = '5hTu19xMREmFtYLW3x61mA'; // default sdk key for testing, not present in config for local dev
    }

    const getFlag = (distinctId) => {
      verbose(`getFlag ${flagName} with distinctId: ${distinctId}`);

      const client = configcat.getClient(
        `configcat-sdk-1/t6bcCKT0LUKJKqqnjxwGNQ/${sdkKey}`,
        configcat.PollingMode.AutoPoll
      );

      if (distinctId) {
        const userObject = new configcat.User(distinctId);
        resolve(client.getValueAsync(flagName, false, userObject));
      } else {
        resolve(client.getValueAsync(flagName, false));
      }
    };

    // skipAuthentication - get the flag right away without waiting for distinctId
    // used for getting mixpanel proxy flag as soon as possible
    if (skipAuthentication) {
      getFlag();
      return;
    }

    // for authenticated users we wait for the currentUser to use the distinctId
    // and get the flag value for that particular user
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      if (isAuthenticated) {
        const currentUser = CurrentUserStore.getState();
        currentUser.deferred.promise.then(() => {
          getFlag(currentUser.distinctId);
        });
      } else {
        getFlag();
      }
    });
  });

  return promise;
}
