import Model from './model';

var ReadOnlyModel = Model.extend({
  serialize: null,
  saveRecord: null,
  deleteRecord: null,
  didDefineProperty: null,
  _onPropertyChange: function () {
    return this;
  },
});

export default ReadOnlyModel;
