import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SidebarLeftEvents extends Component {
  @tracked isEventPickerOpened = false;
  @tracked isTopEventPickerOpened = false;

  @action
  toggleEventPicker() {
    this.isEventPickerOpened = !this.isEventPickerOpened;
  }

  @action
  toggleTopEventPicker() {
    this.isTopEventPickerOpened = !this.isTopEventPickerOpened;
  }
}
