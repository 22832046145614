import { set, get, computed } from '@ember/object';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { isElementInViewport } from 'mewe/utils/miscellaneous-utils-light';
import { localCopy } from 'tracked-toolbox';
import { get as lodashGet } from 'lodash';
import { tracked } from '@glimmer/tracking';

export default class MwGifImage extends Component {
  viewportTolerance = 300;

  gifStaticText = '{static}';

  @localCopy('args.gifImageSize', '400x400')
  gifImageSize;

  @tracked gifModel;

  constructor() {
    super(...arguments);
    this.gifModel = this.args.gifModel;
    this.gifModel.imageSize = this.args.imageSize || '800x800';
  }

  @action
  onInsert(element) {
    this.el = element;
    this.bindObservers();
  }

  createIO(cb) {
    const el = this.el;
    const options = {
      root: null,
      rootMargin: `${this.viewportTolerance}px`, // how many px allowed to be outside of viewport height or width for gif to load
      threshold: 0, // how much of the element should get into intersection to trigger callback (0 = just first pixel)
    };

    const io = new IntersectionObserver(cb, options);
    io.observe(el);

    return { io, el };
  }

  bindObservers() {
    // create IO for uploaded photoGif (gfy gifs are handled by gfy-gif modifier)
    if (this.gifModel) {
      this.giphyIO = this.createIO(this.setUpGifState.bind(this));

      // set initial state of gifs
      scheduleOnce('afterRender', this, () => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.setUpGifState();
        }
      });
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.unbindObservers();
    if (this.gifModel) {
      this.unloadGif();
    }
  }

  unbindObservers() {
    if (this.giphyIO) {
      const { io, el } = this.giphyIO;
      io.unobserve(el);
    }
  }

  setUpGifState(ioState) {
    let isInView;
    if (ioState?.length) {
      // MSEDGE might not support 'isIntersecting' property, fallback to 'intersectionRatio'
      isInView = ioState[0].isIntersecting || ioState[0].intersectionRatio > 0;
    } else {
      isInView = isElementInViewport(this.el, this.viewportTolerance, this.viewportTolerance);
    }

    // photoGif
    if (this.gifModel) {
      this.loadOrUnloadGifImage(isInView);
    }
  }

  loadOrUnloadGifImage(isInView) {
    if (this.gifModel.gifStopped) {
      return;
    }

    if (isInView) {
      if (!this.gifModel.gifOpened && !this.gifModel.gifLoading) {
        this.loadGif();
      }
    } else if (this.gifModel.gifOpened || this.gifModel.gifLoading) {
      this.unloadGif();
    }
  }

  loadGif() {
    const isGifSrcSetToCurrentImageSize = () => {
      const gifSrc = this.gifModel.gif;
      return !!gifSrc && gifSrc.indexOf(this.gifImageSize) > 0;
    };

    if (isGifSrcSetToCurrentImageSize() && !this.gifModel.gifLoading) {
      set(this, 'gifModel.gifOpened', true);
      return;
    }

    const imgSource = lodashGet(this.gifModel, this.gifHrefPath)
      .replace('{imageSize}', this.gifImageSize)
      .split('?static=' + this.gifStaticText)
      .join('');

    const imgSourceOriginal = lodashGet(this.gifModel, this.gifHrefPath)
      .replace('{imageSize}', 'full')
      .split('?static=' + this.gifStaticText)
      .join('');

    this.gifModel.gif = imgSource;
    this.gifModel.gifOriginal = imgSourceOriginal;
    set(this, 'gifModel.gifLoading', true);

    const img = new Image();
    img.src = EnvironmentUtils.getImgHost(true, this.args.model?.isPublicContent) + imgSource;

    img.onload = () => {
      if (!this.isDestroying && !this.isDestroyed && isGifSrcSetToCurrentImageSize()) {
        set(this, 'gifModel.gifOpened', true);
        set(this, 'gifModel.gifLoading', false);
        this.args.setGifModel?.(this.gifModel);
      }
    };
    this.args.setGifModel?.(this.gifModel);
  }

  unloadGif() {
    set(this, 'gifModel.gifOpened', false);
    set(this, 'gifModel.gifLoading', false);
    this.args.setGifModel?.(this.gifModel);
  }

  prepareSrc(type = false) {
    const model = this.gifModel;
    const isOpen = this.gifModel.gifOpened;

    if (isOpen) {
      return (
        EnvironmentUtils.getImgHost(true, this.args.model?.isPublicContent) +
        (type === 'webp' ? model.gif : model.gifOriginal)
      );
    } else {
      const path = lodashGet(this.gifModel, this.gifHrefPath);
      const templateString = (val, options) => {
        if (!val) {
          return;
        }
        for (let property in options) {
          if (options[property] && val.includes(property)) {
            val = val.replace(`{${property}}`, options[property]);
          }
        }
        return val;
      };
      return (
        EnvironmentUtils.getImgHost(true, this.args.model?.isPublicContent) +
        templateString(path, {
          imageSize: type === 'webp' ? model.imageSize : 'full',
          static: '1',
        })
      );
    }
  }

  get gifHrefPath() {
    return this.gifModel?._links?.self?.href ? '_links.self.href' : '_links.img.href';
  }

  get btnExtraClass() {
    return this.args.btnExtraClass || '';
  }

  get btnLoadingClass() {
    return this.args.btnLoadingClass || '';
  }

  get btnMainClass() {
    return this.args.btnMainClass || '';
  }

  @computed('gifModel.{gifOpened,gifLoading}')
  get webpGifUrl() {
    return this.prepareSrc('webp');
  }

  @computed('gifModel.{gifOpened,gifLoading}')
  get gifUrl() {
    return this.prepareSrc();
  }

  get showLoader() {
    if (get(this, 'gifModel.gifOpened')) {
      return;
    }
    return `<span class="${this.args.btnMainClass} ${this.gifModel.gifLoading ? this.args.btnLoadingClass : ''} ${
      this.args.btnExtraClass
    }"></span>`;
  }

  @action
  toggleGifModel() {
    if (this.gifModel) {
      if (this.gifModel.gifOpened) {
        this.gifModel.gifStopped = true;
        this.unloadGif();
      } else {
        this.gifModel.gifStopped = false;
        this.loadGif();
      }
    }
    this.args.setGifModel?.(this.gifModel);
  }
}
