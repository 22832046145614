import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { dateString } from 'mewe/utils/datetime-utils';

export default class ChatMessageTime extends Component {
  @service account;

  @computed('args.message.date')
  get title() {
    return dateString({
      timeZone: this.account.activeUser?.timezone,
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })(this.args.message.date, this.account.activeUser.jsLocale);
  }
}
