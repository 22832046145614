import Image from 'quill/formats/image';

const ATTRIBUTES = ['data-name', 'src'];

class Emoji extends Image {
  static className = 'emoji';

  static formats(domNode) {
    return ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Emoji.tagName = 'img';
Emoji.blotName = 'emoji';
Emoji.className = 'emoji';

export default Emoji;
