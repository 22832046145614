import { createDeferred } from 'mewe/api/api';

/**
 * import { retryHttp500UntilDone } from 'mewe/utils/fetch-utils';
 */

const statusToRetry = {
  500: true,
  502: true,
  503: true,
  504: true,
  505: true,
};

function shouldRetryResponse(resp) {
  return !resp || statusToRetry[resp.status];
}

function createRetryDeferred() {
  var promise = createDeferred();

  promise.connectionIssue = (cb) => {
    promise.onConnectionFailed = cb;
    return promise;
  };

  return promise;
}

function getRetryTime(retryAmount) {
  if (retryAmount == 0) {
    return 0;
  } else if (retryAmount == 1) {
    return 1000;
  } else if (retryAmount < 5) {
    return 5000;
  } else if (retryAmount < 10) {
    return 30000;
  } else if (retryAmount < 30) {
    return 60000;
  } else {
    return 2 * 60000;
  }
}

export function retryHttp500UntilDone(apiCall, timeoutFunc = window.setTimeout) {
  let promise = createRetryDeferred(),
    retryAmount = 0,
    retry = () =>
      timeoutFunc(() => {
        apiCall()
          ?.then(promise.resolve)
          .catch((resp) => {
            if (shouldRetryResponse(resp)) {
              if (retryAmount == 2 && promise.onConnectionFailed) {
                promise.onConnectionFailed(resp);
              }

              retryAmount++;
              retry();
            } else {
              promise.reject(Error(`[Fetch Utils] retryHttp500UntilDone failed`, { cause: resp }));
            }
          });
      }, getRetryTime(retryAmount));

  retry();

  return promise;
}
