/* eslint-disable lines-between-class-members */
/* eslint-disable ember/no-observers */
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';
import Member from 'mewe/stores/models/member-model';

import Scrolling from 'mewe/utils/scrolling-utils';
import GroupApi from 'mewe/api/group-api';
import { begin, end } from '@ember/runloop';
import { each } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

import PS from 'mewe/utils/pubsub';
import { maxResultsMembersList } from 'mewe/constants';

export default class MemberBase extends Component {
  @service router;

  @reads('args.model.group') group;

  scrolling = Scrolling();
  theme = 'member';
  selectedTab = 'members';

  @tracked members = A();
  @tracked searchResults = A();
  @tracked applications = A();
  @tracked isMoreResults = false;
  @tracked isSearching = false;
  @tracked offset = 0;
  @tracked loading = false;

  serializer = JSONSerializer.create();

  constructor() {
    super(...arguments);
    this.bindPs();
    addObserver(this, 'args.model.membersSearch', this, this.membersSearchChange);
    addObserver(this, 'args.model.membersSearchSort', this, this.refreshMembers);
    this.loadMembers && this.loadMembers();
  }

  bindPs() {
    PS.Sub('group.members.refresh', this, this.refreshMembers);
    PS.Sub('group.member.remove', this, this.removeGroupMember);
  }

  unbindPs() {
    PS.Unsub('group.members.refresh', this.refreshMembers);
    PS.Unsub('group.member.remove', this.removeGroupMember);
  }

  // Base getter to show the results
  get filteredMembersOrSearchResults() {
    return this.isSearching ? this.searchResults : this.members;
  }

  @computed('group.isUniversal', 'router.currentRouteName')
  get resultsHidden() {
    // Invited tab is hidden for universal group and this hides results if user typed url manually
    return this.group.isUniversal && this.router.currentRouteName === 'app.group.members.invited';
  }

  removeGroupMember(data = {}) {
    const member = this.members.find((m) => m.id === data.userId);

    if (member) {
      this.members.removeObject(member);
      this.searchResults.removeObject(member);
    }
  }

  @action
  onRoleChange(member) {
    this.isSearching ? this.searchResults.removeObject(member) : this.members.removeObject(member);
  }

  refreshMembers() {
    this.offset = 0;
    this.members.clear();
    this.searchResults.clear();

    if (this.args.model.membersSearch?.length) {
      this.search(this.args.model.membersSearch);
    } else {
      this.loading = false; // there can be pending loading from search, clean it to unblock loading members (SG-41183)
      this.loadMembers();
    }
  }

  loadSearchResults(searchStr) {
    if (!searchStr) {
      return;
    }

    this.scrolling.unbindScrollDown();

    const searchTermChanged = searchStr !== this.lastSearchStr;

    if (searchTermChanged) {
      this.lastSearchStr = searchStr;
    }

    const params = {
      str: searchStr,
      offset: this.searchResults.length,
      maxResults: maxResultsMembersList,
      sort: this.args.model.membersSearchSort,
    };

    GroupApi.membersSearch(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        begin();

        if (searchTermChanged) {
          set(this, 'searchResults', A());
        }

        if (data.members?.length) {
          this.searchResults.pushObjects(this.deserializeMembers(data.members));

          if (data.members.length === maxResultsMembersList) {
            this.scrolling.bindScrollDown(() => this.loadSearchResults(searchStr));
          }
        }

        end();
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }

  deserializeMembers(json) {
    let members = A();
    let data = A();

    each(json, function (m) {
      let user = Object.assign(m, m.user);
      user.isGroupMember = true;
      data.push(user);
    });

    // eslint-disable-next-line ember/require-tagless-components
    let MemberWithGroup = Member.extend({
      group: this.group,
    });
    this.serializer.deserializeMany(members, MemberWithGroup, data);

    return members;
  }

  membersSearchChange() {
    const currentRouteName = this.router.currentRouteName;

    if (this.args.model.membersSearch) {
      this.search(this.args.model.membersSearch);
      this.searchOnRoute = currentRouteName;
    } else if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = null;
    }
    this.isSearching = false;
    this.refreshMembers();
  }

  search(term) {
    const timeoutTime = 500;

    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }

    if (term) {
      this.loading = true;
      this.searchTimeoutId = setTimeout(() => {
        this.isSearching = true;
        this.searchResults = A();
        this.searchTimeoutId = null;
        this.filter = null;
        this.loadSearchResults(term);
      }, timeoutTime);
    }
  }

  @action
  onUserRemove(user) {
    this.members.removeObject(user);
    this.searchResults.removeObject(user);

    if (this.members.length === 0) {
      this.router.transitionTo('app.group.members', this.group.id);
    }
  }

  willDestroy() {
    this.unbindPs();
    this.scrolling.unbindScrollDown();

    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    removeObserver(this, 'args.model.membersSearch', this, this.membersSearchChange);
    super.willDestroy(...arguments);
  }
}
