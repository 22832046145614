import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'mewe/config/environment';
import meweConfig from 'mewe/config';
import { checkForRedirects } from './check-for-redirects';
import { startSentry } from 'mewe/shared/sentry';
import './assets/app.scss';
import Session from 'mewe/shared/session';
import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[Config]', color: 'black', enabled: false }).log;
verbose(config, meweConfig);

const isTesting = config.environment === 'test';

if (!isTesting) {
  checkForRedirects();
  startSentry();
} else {
  window.__ = (p) => p;
}

Session.isAuthenticated();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
