import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import StickerApi from 'mewe/api/sticker-api';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class GenerateSticker extends Component {

  @tracked stickerText = '';
  @tracked sticker = null;
  @tracked isGenerating = false;
  @tracked status = '';

  get isGenerateBtnDisabled() {
    return this.stickerText.length === 0;
  }

  get isDone() {
    return this.status === 'Done' ?? false;
  }

  get isFailed() {
    return this.status === 'Failed' ?? false;
  }

  @action
  setText(text) {
    this.stickerText = text;
  }
  
  @task * generateSticker() {
    const response = yield StickerApi.generateSticker(this.stickerText);
    yield this.checkStickerStatus(response.sticker);
  }

  async checkStickerStatus(sticker) {
    this.isGenerating = true;
    const result = await StickerApi.checkStickerStatus(sticker.id);
    if (result.sticker.status === "InProgress") {
      later(this, this.checkStickerStatus, sticker, 3000);
    } else {
      await StickerApi.downloadGeneratedSticker(sticker.id);
      sticker.url = `${location.origin}/api/v2/stickers/generated/${sticker.id}/file`;
      sticker.status =  result.sticker.status;
      this.sticker = sticker;
      this.status = result.sticker.status;
      this.isGenerating = false;
    }
  }

  @action
  useSticker() {
    this.args.onStickerUse(this.sticker);
  }

  @action
  cancel() {
    this.sticker = null;
  }
}
