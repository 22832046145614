import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';

export default class MwGroupSettingsSidebarLeft extends Component {
  @reads('args.group') group;

  @tracked activeSettingsTab;

  @action
  switchSettingsTab(tabIndex) {
    this.args.switchSettingsTab(tabIndex);
  }
}
