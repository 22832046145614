import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SidebarLeftGroups extends Component {
  @service dynamicDialogs;

  @alias('args.groupState.confirmedGroups.length') confirmedGroupsCount;

  @computed('args.isAllGroups', 'confirmedGroupsCount')
  get showGroupsListFooter() {
    return this.args.isAllGroups || this.confirmedGroupsCount < 2; // has only one grp which is probably Universal
  }

  @action
  openGroupCreationDialog() {
    this.dynamicDialogs.openDialog('group-create');
  }
}
