import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { action, computed } from '@ember/object';
import { getOwner } from '@ember/application';

import { WrapperScope } from 'mewe/constants';
import { ds } from 'mewe/stores/ds';
import { toWrapper } from 'mewe/stores/models/wrapper-model';

export default class MwEventListItemSimple extends Component {
  @service router;
  @service dynamicDialogs;

  @computed('args.rootEventId', 'args.eventData.id')
  get isCurrentEvent() {
    return this.args.rootEventId === this.args.eventData.id;
  }

  @action
  eventClick() {
    if (this.isCurrentEvent && this.args.eventData.unreadPostsCount) {
      later(
        this,
        function () {
          if (this.isDestroying || this.isDestroyed) return;
          let route = getOwner(this).lookup(`route:app.event.feed`);
          if (route) route.send('refreshFeed');
        },
        80
      );
    }

    window.scrollTo(0, 0);
  }

  @action
  openEventPostsDialog() {
    const isWrapperInStore = ds.wrappers.items.some((i) => i.id === this.args.eventData.id);

    if (!isWrapperInStore) {
      ds.wrappers.items.pushObject(
        toWrapper({
          id: this.args.eventData.id,
          postsCount: this.args.eventData.unreadPostsCount,
          eventData: this.args.eventData,
          type: WrapperScope.EVENT,
        })
      );
    }

    this.dynamicDialogs.openDialog('wrappers-dialog', {
      wrapperId: this.args.eventData.id,
      wrappersScope: WrapperScope.EVENT,
    });
  }
}
