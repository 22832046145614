import ApiUtil from './api';

/**
 * import NotificationsApi from 'mewe/api/notifications-api';
 *
 */
class Api extends ApiUtil {
  fetch(params, nextPageUrl) {
    if (nextPageUrl) {
      return this.getNextPageOr(nextPageUrl);
    } else {
      return this.get('/notifications/feed', {
        data: params,
      });
    }
  }

  // alias for backward naming convention
  feed(params) {
    return this.fetch(params);
  }

  markSeen(params) {
    return this.post('/notifications/markSeen', {
      data: params,
    });
  }

  markVisited(params) {
    return this.post('/notifications/markVisited', {
      data: params,
      contentType: 'application/x-www-form-urlencoded',
    });
  }

  unseenNumber() {
    return this.get('/notifications/unseen');
  }

  setMobilePN(params) {
    return this.post('/mobile/push-notifications', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
