import Embed from 'quill/blots/embed';

class TextEditorEmbed extends Embed {
  static create(data) {
    const node = super.create();
    node.classList.add('text-editor_embed');
    node.innerHTML = data.text;
    return TextEditorEmbed.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

TextEditorEmbed.tagName = 'span';

export default TextEditorEmbed;
