import { A } from '@ember/array';
import {
  curry,
  curryRight,
  tap as tapL,
  map as mapL,
  pick as pickL,
  filter as filterL
} from 'lodash';

export const get = curry((name, obj) => (obj[name] ? obj[name] : A()));

export const getOrCreate = curry((obj, _class, name) => {
  if (!obj[name]) obj[name] = _class.create();
  return obj[name];
});

export const emberSet = curry((instance, data) => {
  instance.setProperties(data);
  return instance;
});

export const tap = curryRight(tapL);
export const map = curryRight(mapL);
export const pick = curry((a, b) => pickL(b, a));
export const filter = curryRight(filterL);

export const lift = (items) => (items ? (Array.isArray(items) ? items : [items]) : []);

export const contains = (str, search) => str.toLowerCase().indexOf(search) > -1;
