import {
  isMac,
  isWindows,
  isIE,
  isFirefox,
  isSafari
} from 'mewe/shared/utils';

export default () => {
  const classes = [];

  if (isMac()) classes.push('mac');
  else if (isWindows()) classes.push('win');

  if (isSafari()) classes.push('safari');
  else if (isFirefox()) classes.push('firefox');
  else if (isIE()) classes.push('msie');

  return classes;
};
