import { htmlSafe } from '@ember/template';
import { next, later } from '@ember/runloop';

import './autocomplete-dropdown';
import Editor from './text-editor-quill';
import FunctionalUtils from 'mewe/shared/functional-utils';
import Quill from 'quill/core';
import { makeCodeBlock, insertContent, addCustomRange } from './text-editor-utils';
import { toQuillFormat, wasFormattingChange, parseExistingMentions } from './text-editor-utils';
import { checkOptions } from 'mewe/utils/component-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import Mentions from 'mewe/utils/mentions-utils';
import { insertEmojiFromPicker } from './text-editor-quill-utils';
import { isSafari } from 'mewe/shared/utils';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';

let ENTER_NEW_LINE = false;

export default class TextEditor extends Component {
  options = {
    mandatory: ['placeholder', 'content', 'updateContent'],
    optional: [
      'allowNativeMarkdown',
      'autocompletePlacement',
      'autofocus',
      'beforeStoreRedirection',
      'ctrlEnterAction',
      'canMarkHashtags',
      'classNames',
      'closeOnScroll',
      'confirmExit',
      'disabled',
      'disableTextComplete',
      'setEditor',
      'editorChange',
      'textChange',
      'enterAction',
      'eventId',
      'focusInAction',
      'focusOutAction',
      'group',
      'hashtagsStrategy',
      'hideEmoji',
      'id',
      'keyUpAction',
      'maxlength',
      'maxheight',
      'mentionsStrategy',
      'onclick',
      'page',
      'textPastedAction',
      'selectedTags',
      'sendWithEnter',
      'showFormatting',
      'showUnloadWarning',
      'tabindex',
      'target',
      'testid',
      'data-testid',
    ],
  };

  @tracked content;
  @tracked autocompleteVisible = false;
  @tracked boldFormattingOn = false;
  @tracked italicFormattingOn = false;
  @tracked strikeFormattingOn = false;
  @tracked sendOptionsVisible;

  @service account;

  activeUser = this.account.activeUser;

  elementId = guidFor(this);
  qlEditorClass = '.ql-editor';

  get style() {
    if (this.args.maxheight) {
      return htmlSafe(`max-height: ${this.args.maxheight}px;`);
    }

    return htmlSafe('');
  }

  get enterPressInputId() {
    return `${this.elementId}-enter-press`;
  }

  getEnterNewLine() {
    if (this.args.sendWithEnter && !ENTER_NEW_LINE) this.onEnter();
    return this.args.sendWithEnter ? true : ENTER_NEW_LINE;
  }

  constructor() {
    super(...arguments);
    checkOptions('c-text-editor', this.options || [], this.args);
    this.content = this.args.content;
  }

  @action
  onInsert(element) {
    this.element = element;

    if (this.args.hashtagsStrategyChanged) {
      addObserver(this, 'args.hashtagsStrategy', this.hashtagsStrategyChanged);
    }
    addObserver(this, 'args.mentionsStrategy', this.mentionsStrategyChanged);
    addObserver(this, 'content', this.shouldConfirmExit);
    addObserver(this, 'args.disabled', this.disabledChanged);

    // TODO:FIX: We are getting this "[Deprecation] Listener added for a synchronous 'DOMNodeInserted' DOM Mutation Event."
    this.quill = Editor({
      context: this,
      el: this.element.querySelector('.quill-editor'),
      getEnterNewLine: () => this.getEnterNewLine(),
      placeholder: this.args.placeholder,
      onCtrlEnter: this.args.ctrlEnterAction,
      canMarkHashtags: this.args.canMarkHashtags,
      tabindex: this.args.tabindex,
      editorClassNames: this.editorClassNames,
      showFormatting: this.args.showFormatting,
      hideEmoji: this.args.hideEmoji,
      hashtagsStrategy: this.args.hashtagsStrategy,
      mentionsStrategy: this.args.mentionsStrategy,
      groupName: this.args.group?.name,
    });

    if (this.content) {
      parseExistingMentions(this.args.mentionsStrategy, this.content, Mentions);

      this.content = insertContent(this.content, this.quill, Mentions, this.canMarkHashtags);
      this.args.updateContent(this.content);

      if (typeof this.args.editorChange === 'function') this.args.editorChange();
    }

    this.setEditorApi();

    if (this.args.maxlength) this.quill.maxlength = this.args.maxlength;

    if (this.args.autofocus) {
      this.quill.setSelection(this.quill.getLength());
      this.quill.focus();
    }

    this.quill.keyboard.addBinding(
      {
        key: 'B',
        shortKey: true,
      },
      () => {
        let format = this.quill.getFormat();
        this.quill.format('bold', !format.bold);
        this.onFormat();
      }
    );

    this.quill.keyboard.addBinding(
      {
        key: 'I',
        shortKey: true,
      },
      () => {
        let format = this.quill.getFormat();
        this.quill.format('italic', !format.italic);
        this.onFormat();
      }
    );

    this.quill.keyboard.addBinding(
      {
        key: 'S',
        shortKey: true,
      },
      () => {
        let format = this.quill.getFormat();
        this.quill.format('strike', !format.strike);
        this.onFormat();
      }
    );

    this.quill.on(Quill.events.EDITOR_CHANGE, (type, change) => {
      if (!this.isDestroyed && !this.isDestroying) {
        const _content = makeCodeBlock(this.quill.root.innerHTML);
        this.content = _content;
        if (this.args.updateContent) {
          this.args.updateContent(this.content);
        }
      }

      if (change && wasFormattingChange(this.quill, change)) this.onFormat();

      if (typeof this.args.editorChange === 'function') this.args.editorChange();
    });
    if (typeof this.args.textPastedAction === 'function') {
      this.quill.textPastedAction = this.args.textPastedAction;
    }

    // text change is triggered also when emoji is selected from picker (keyUp is not triggered in such case)
    if (typeof this.args.textChange === 'function') {
      this.quill.on(Quill.events.TEXT_CHANGE, this.args.textChange);
    }

    if (this.args.maxlength) {
      this.quill.on(Quill.events.TEXT_CHANGE, () => {
        if (this.quill.getLength() > this.args.maxlength) {
          this.quill.deleteText(this.args.maxlength, this.quill.getLength());
          FunctionalUtils.showErrorMaxCharacterLength();
        }
      });
    }
  }

  @action
  onDestroy() {
    super.willDestroy(...arguments);

    if (this.args.hashtagsStrategyChanged) {
      removeObserver(this, 'args.hashtagsStrategy', this.hashtagsStrategyChanged);
    }
    removeObserver(this, 'args.mentionsStrategy', this.mentionsStrategyChanged);
    removeObserver(this, 'args.disabled', this.disabledChanged);
    removeObserver(this, 'content', this.shouldConfirmExit);
  }

  // react to mentions strategy change
  mentionsStrategyChanged() {
    if (this.args.mentionsStrategy) {
      const autocomplete = this.quill.getModule('autocomplete');

      autocomplete.groupName = this.args.group?.name;

      const mention = {
        strategy: this.args.mentionsStrategy,
        trigger: /(?:^|\s)@/,
        sign: '@',
      };

      autocomplete.transforms['mention'] = mention;
    }
  }

  // react to hashtag strategy change
  hashtagsStrategyChanged() {
    if (this.args.hashtagsStrategy) {
      const autocomplete = this.quill.getModule('autocomplete');

      const hashtag = {
        strategy: this.args.hashtagsStrategy,
        trigger: /(?:^|\s)#/,
        sign: '#',
      };

      autocomplete.transforms['hashtag'] = hashtag;
    }
  }

  setEditorApi() {
    if (typeof this.args.setEditor === 'function')
      this.args.setEditor({
        focus: () => {
          this.quill.focus();
        },

        update: (text) => {
          let delta = text ? this.quill.clipboard.convert(toQuillFormat(text)) : '';
          this.quill.setContents(delta, 'api');
        },
      });
  }

  onFormat() {
    next(() => {
      if (!this.quill.hasFocus() || !this.quill.getSelection()) return;

      let format = this.quill.getFormat();

      if (format.bold !== this.boldFormattingOn) this.boldFormattingOn = format.bold;
      if (format.italic !== this.italicFormattingOn) this.italicFormattingOn = format.italic;
      if (format.strike !== this.strikeFormattingOn) this.strikeFormattingOn = format.strike;
    });
  }

  disabledChanged() {
    if (!this.quill) return;
    if (this.args.disabled) this.quill.disable();
    else this.quill.enable();
  }

  // SG-30113 - Secures editing a text with a mention after using Ctrl/Cmd + A
  handleBlankEditorInteraction(event) {
    const blankEditor = this.quill.root;
    const delay = 1;
    const resolveEventSource = () => {
      return event.target.classList.contains('h-input_search');
    };

    if (!!blankEditor && !this.quill.getSelection() && !resolveEventSource()) {
      blankEditor.lastChild.textContent = event.key;

      setTimeout(addCustomRange(blankEditor, 1), delay);
    }
  }

  @action
  keyUp(event) {
    this.handleBlankEditorInteraction(event);

    if (typeof this.args.keyUpAction === 'function') this.args.keyUpAction(this.quill.root.innerHTML, event);
  }

  @action
  focusIn(event) {
    if (typeof this.args.focusInAction === 'function') this.args.focusInAction(this, event);
  }

  @action
  focusOut(event) {
    const fn = () => {
      // SG-30386 emoji autocomplete is opened and user clicked on it, prevent focusing out
      if (this.autocompleteVisible && this.quillAutocompleteFocusDisabled) return;

      if (event.relatedTarget && Array.from(event.relatedTarget.classList).includes('c-mw-emoji-button')) return;

      if (typeof this.args.focusOutAction === 'function' && !ENTER_NEW_LINE && !this.emojiPickerOpened) {
        this.args.focusOutAction(this, event);
      }
    };

    // SG-36174 - missing relatedTarget in safari when click on emoji button causes closing editor
    // couldn't find better solution than postponing focusOut after emoji picker will be opened, in such
    // case editor won't be closed because `emojiPickerOpened` will be set to true at the time of this callback
    if (isSafari()) {
      later(this, fn, 500);
    } else {
      fn();
    }
  }

  @action
  onClick(event) {
    if (typeof this.args.onclick === 'function') this.args.onclick(this.content, event);
  }

  onEnter() {
    if (typeof this.args.enterAction === 'function') this.args.enterAction();
  }

  shouldConfirmExit() {
    if (this.args.confirmExit) {
      const content = toServer(this.content, { parseNativeMarkdown: true });
      if (this.args.showUnloadWarning || (content && content.length)) {
        window.onbeforeunload = this.confirmExit;
      } else {
        window.onbeforeunload = null;
      }
    }
  }

  @action
  onFormatSelect(type) {
    let format = this.quill.getFormat();

    switch (type) {
      case 'bold':
        this.quill.format('bold', format ? !format.bold : true);
        break;

      case 'italic':
        this.quill.format('italic', format ? !format.italic : true);
        break;

      case 'strike':
        this.quill.format('strike', format ? !format.strike : true);
        break;
    }

    this.quill.focus();
    this[`${type}FormattingOn`] = !this[`${type}FormattingOn`];
  }

  @action
  onEmojiSelect(emoji) {
    insertEmojiFromPicker(emoji, this.quill);
  }

  @action
  beforeStoreRedirection(callback) {
    if (typeof this.args.beforeStoreRedirection === 'function') this.args.beforeStoreRedirection(callback);
    else callback();
  }

  @action
  onEmojiPickerOpen() {
    if (typeof this.args.onEmojiPickerOpen === 'function') this.args.onEmojiPickerOpen();
  }

  @action
  toggleSendButtonOptions() {
    this.sendOptionsVisible = !this.sendOptionsVisible;
  }

  @action
  toggleWayOfSending() {
    dispatcher.dispatch('client-data', 'setPreferences', { sendDMByEnter: !this.activeUser.sendDMByEnter });
  }

  @action
  setEmojiPicker(isOpen) {
    this.emojiPickerOpened = isOpen;
    this.quill.focus();
  }

  @action
  updateSelectedTags(operation, argument) {
    this.args.selectedTags[operation](argument);
  }
}
