export function isWopiFileTypeSupported(ext) {
  // on the right are old/deprecated versions that need to be converted before editing
  switch (ext) {
    case 'xlsx':
    case 'xls':
    case 'xltx':
    case 'csv':
    case 'xlsb':
    case 'ods':
    case 'xlsm':
    case 'pptx':
    case 'ppt':
    case 'ppsx':
    case 'pps':
    case 'potm':
    case 'pot':
    case 'odp':
    case 'ppsm':
    case 'pptm':
    case 'thmx':
    case 'docx':
    case 'doc':
    case 'docm':
    case 'dot':
    case 'dotm':
    case 'odt':
    case 'dotx':
      return true;

    default:
      return false;
  }
}
