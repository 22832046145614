import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import Scrolling from 'mewe/utils/scrolling-utils';
import MyCloudApi from 'mewe/api/mycloud-api';
import PS from 'mewe/utils/pubsub';
import Group from 'mewe/stores/models/group-model';

import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
const serializer = JSONSerializer.create();

export default class MwPhotoGroup extends Component {
  @tracked loading = true;
  @tracked groups = A();

  offset = 0;
  maxResults = 40;
  groupsInRow = 7;

  constructor() {
    super(...arguments);

    this.scrolling = Scrolling();

    PS.Sub('photoGroups.reload', () => {
      this.reRenderView();
    });

    this.renderView();
  }

  @action onDestroy() {
    this.scrolling.unbindScrollDown();
    PS.Unsub('photoGroups.reload', this.reRenderView);
  }

  @computed('type')
  get placeholderText() {
    switch (this.args.type) {
      case 'group-docs':
        return __(`You don't have any documents in your groups yet`);
        break;
      case 'group-photos':
        return __(`You don't have any photos in your groups yet`);
        break;
    }
  }

  renderView() {
    this.loading = true;

    const params = {
      offset: this.offset,
      maxResults: this.maxResults,
      mediaTypes: this.args.mediaTypes,
    };

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      let groups = serializer.deserializeAll(Group, data.groupItems);

      if (this.offset) {
        this.groups.pushObjects(groups);
      } else {
        this.groups = groups;
      }

      if (groups.length === this.maxResults) {
        this.scrolling.bindScrollDown(() => {
          this.renderView();
        });
      } else {
        this.scrolling.unbindScrollDown();
      }

      this.loading = false;

      this.offset += groups.length;
    };

    if (this.args.type === 'group-photos') {
      MyCloudApi.getActiveGroupsMedias(params).then(callback);
    } else {
      MyCloudApi.getActiveGroupsFiles(params).then(callback);
    }
  }

  reRenderView() {
    if (this.isDestroyed || this.isDestroying) return;

    this.set('offset', 0);
    this.renderView();
  }

  @action openGroupView(group) {
    switch (this.args.type) {
      case 'group-docs':
        this.args.openGroupDocuments(group);
        break;
      case 'group-photos':
        this.args.openGroupStream(group);
        break;
    }
  }
}
