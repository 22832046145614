import highlightParser from 'mewe/stores/text-parsers/highlight-parser';
import htmlParser from 'mewe/stores/text-parsers/html-parser';
import { reduce } from 'lodash';

let parsersDefault = [htmlParser, highlightParser];

export default function (text, parsers = parsersDefault, options = {}) {
  return reduce(
    parsers,
    (value, parser) => {
      return parser.toDisplay(value, options);
    },
    text
  );
}
