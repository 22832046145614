import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwReport extends Component {
  @service dynamicDialogs;

  @action
  click() {
    this.dynamicDialogs.openDialog('report-dialog', {
      post: this.args.post,
      scope: this.args.scope,
    });

    if (this.closePostDropdown) {
      this.closePostDropdown();
    }
  }
}
