/*

 # 4 TYPES OF TEXT

 * textDisplay   - text used to be displayed to user
 * textEdit      - text used in textarea and text editor
 * textServer    - text send to server
 * _textServer   - deprecated text send to server with unicode

 eg.

 * textDisplay  "Hello <br/> <svg class="emoji"><description>😄</description><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#emoji-1f604"></use></svg>"
 * textEdit     "Hello \n <img class="emoji" src="/default/1f604.0d45.svg"/>"
 * textServer    "Hello \n :smile:"
 * _textServer  "Hello \n 😄" DEPRECATED

 Display text and edit text use different emoji markup, because text editor cannot handle <svg> sprites - so we use <img> with svg path

 IMPORTANT
 Before we send plain text to server we change it to unicode text.
 We also get unicode text from server.
 This will go away when we will move all platforms to only use plain text with shortnames!!!

*/

import { computed } from '@ember/object';
import toDisplay from 'mewe/stores/text-parsers/to-display';
import { parsersWithMarkdown } from 'mewe/stores/text-parsers/to-display';
import toEdit from 'mewe/stores/text-parsers/to-edit';
import toServer from 'mewe/stores/text-parsers/to-server';

export let modelText = {
  // you can overrid in model to prepare text eg. deleted message empty text etc.
  preparser(text) {
    return text;
  },

  // you can overrid in model to prepare text eg. generate some event text
  postparser(text) {
    return text;
  },

  // textServer => textDisplay
  textDisplay: computed('textServer', 'emojisReady', function () {
    return this.getTextDisplayWithOptions();
  }),

  getTextDisplayWithOptions(options) {
    options = Object.assign(
      {
        isPrivatePost: this.threadId,
        groupId: this.get('group.id') || this.groupId || this.get('thread.group.id'),
        eventId: this.get('event2.id') || this.get('event.id') || this.get('thread.event.id') || this.eventId,
        textPartsHighlighted: this.textPartsHighlighted,
        pngSpriteSize: this.pngSpriteSize || 22,
      },
      options
    );

    return this.postparser(toDisplay(this.preparser(this.textServer), parsersWithMarkdown, options));
  },

  // textServer => textEdit
  textEdit: computed('textServer', 'emojisReady', {
    get() {
      return this.postparser(toEdit(this.preparser(this.textServer)));
    },
    set(key, value) {
      this.set(
        'textServer',
        toServer(value, {
          gifUrls: this.gifUrls,
        })
      );
      return value;
    },
  }),
};
