import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';

import { daysDiff } from 'mewe/utils/datetime-utils';
import dispatcher from 'mewe/dispatcher';

const FULL_TRIAL = 'fullTrial';
const ENDING_TRIAL = 'endingTrial';
const ENDED_TRIAL = 'endedTrial';
const PAYMENT_EXPIRED = 'paymentExpired';
const PAYMENT_ISSUE = 'paymentIssue';
const TRANSFER_TRIAL = 'transferTrial';

export default class MwPageHeaderStatus extends Component {
  @service router;
  @service purchase;

  @alias('args.model.page') page;

  @computed('headerAlertName')
  get headerAlert() {
    switch (this.headerAlertName) {
      case FULL_TRIAL:
        return {
          colorClass: 'alert-green',
          alertText: __('You have {count}-day left on your trial. <u>Subscribe now!</u>', {
            count: this.activeDaysLeft,
          }),
        };
      case ENDING_TRIAL:
        return {
          colorClass: 'alert-red',
          alertText: __('You have {count}-day left on your trial. <u>Subscribe now!</u>', {
            count: this.activeDaysLeft,
          }),
        };
      case ENDED_TRIAL:
        return {
          colorClass: 'alert-red',
          alertText: __('Your trial period has ended. Your page is now unpublished. <u>Subscribe now!</u>'),
        };
      case PAYMENT_EXPIRED:
        return {
          colorClass: 'alert-red',
          alertText: __(
            'Your payment method has expired. Your Page is now unpublished. <u>Please update payment method.</u>'
          ),
        };
      case PAYMENT_ISSUE:
        return {
          colorClass: 'alert-red',
          alertText: __(
            'There are issues with your payment. Your page will be unpublished in {count}-day. <u>Please update payment method.</u>',
            { count: this.activeDaysLeft }
          ),
        };
      case TRANSFER_TRIAL:
        return {
          colorClass: 'alert-red',
          alertText: __(
            'You as a new owner have {count} day left to update your payment method. <u>Update payment method.</u>',
            { count: this.activeDaysLeft }
          ),
        };
    }
  }

  @computed('page.{subscription,hasActiveSubscription,free.reason}', 'activeDaysLeft')
  get headerAlertName() {
    if (!this.page.free) return;
    if (!this.page.isOwner) return; // showing subscription alerts only to owner
    if (this.page.isFetching) return;
    if (this.page.hasActiveSubscription) return;

    // free.reason:
    // - expired (problems with payment processing),
    // - beta (first 30 days free for existing pages after launching),
    // - transfer (free period for new owner),
    // - cancelled (time until end of subscription after cancelling)

    if (this.page.free.reason === 'transfer') {
      return this.activeDaysLeft > 0 ? TRANSFER_TRIAL : null;
    } else if (this.page.free.reason === 'expired') {
      return this.page.activeTimeLeft > 0 ? PAYMENT_ISSUE : PAYMENT_EXPIRED;
    } else if (this.page.free.reason === 'beta') {
      // trial messages only when user has trial without subscription, otherwise that 'Subscribe now' doesn't make sense for trial period with subscription
      if (this.activeDaysLeft > 7) return FULL_TRIAL;
      else return this.page.activeTimeLeft > 0 ? ENDING_TRIAL : ENDED_TRIAL;
    }
  }

  @computed('page.free.until')
  get activeDaysLeft() {
    if (!this.page.free?.until) return;
    if (this.page.free?.until < Date.now()) return;

    return daysDiff(this.page.free.until) + 1; // +1 to avoid "0 days" when it's less than 24 hours
  }

  @action
  alertAction() {
    const alert = this.headerAlertName;

    if (alert === FULL_TRIAL || alert === ENDING_TRIAL || alert === ENDED_TRIAL || alert === TRANSFER_TRIAL) {
      this.purchase.getItemPromise('page').then((item) => {
        if (this.isDestroyed || this.isDestroying) return;
        dispatcher.dispatch('purchase', 'checkout', null, [item], { pageData: this.page });
      });
    } else {
      this.router.transitionTo('app.settings.purchases');
    }
  }
}
