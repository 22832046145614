import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { isMac } from 'mewe/shared/utils';
import dispatcher from 'mewe/dispatcher';

export default class MwLinksFooter extends Component {
  @service analytics;

  @tracked sentryTestVisible = false;

  constructor() {
    super(...arguments);

    window.showSentryTest = () => {
      this.sentryTestVisible = true;
    };
  }

  @computed('')
  get downloadAppLink() {
    return isMac() ? '/download/mac' : '/download/windows';
  }

  @action
  sentryTest() {
    someFunctionThatDoesntExist();
  }

  @action
  openFeedback() {
    dispatcher.dispatch('feedback', 'openFeedbackDialog');
  }

  @action
  helpCenterClicked() {
    this.analytics.sendEvent('buttonClicked', 'Help Center');
  }
}
