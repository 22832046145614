import EmberObject, { computed, get } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model';
import { attr, hasMany, belongsTo } from 'mewe/utils/store-utils/model/attribute';

import GroupStore from 'mewe/stores/group-store';
import Group from './group-model';

import GroupApi from 'mewe/api/group-api';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import ChatMessageFactory from './chat-message-model';
import Event from './event-model';
import ChatUtils from 'mewe/utils/chat-utils';
import Mentions from 'mewe/utils/mentions-utils';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';
import { modelText } from 'mewe/stores/models/mixins/model-text';
import gifUrlsParser from 'mewe/stores/text-parsers/gif-urls-parser';
import twitterText from 'twitter-text';
import { isUndefined } from 'mewe/utils/miscellaneous-utils';
import { escape } from 'lodash';

export default (CurrentUserStore, ChatStore) => {
  const model = Model.extend(modelEmojisReady, modelText, {
    id: attr('string'),
    chatType: attr('string'),
    closed: attr('boolean'),
    lastMessage: belongsTo(ChatMessageFactory(CurrentUserStore, ChatStore, this)),
    lastNonWsMessage: belongsTo(ChatMessageFactory(CurrentUserStore, ChatStore, this)),
    name: attr('string'),
    typingInfoTimer: attr('array', {
      defaultValue: function () {
        return A();
      },
    }),
    startedBy: attr('string'),
    unread: attr('number'),
    unreadDisappearing: attr('number'),
    unreadMentions: attr('number'),
    arrivedWsMsgsSinceModelCreated: attr('number'),
    lastReadMessageId: attr('string'),
    _links: attr('object'),
    messages: hasMany(ChatMessageFactory(CurrentUserStore, ChatStore, this)),
    hidden: attr('boolean', {
      defaultValue: false,
    }),
    notContact: attr('object', {
      defaultValue: null, // otherwise will throw errors about not using "set"
    }), // for chatrequests
    isNewChat: attr('boolean', {
      // thread displayed during chat creation, changes when participants are changed
      defaultValue: false,
    }),
    wasChatCreationThread: attr('boolean', {
      defaultValue: false,
    }),
    failedToCreate: attr('boolean', {
      defaultValue: false,
    }),
    requestingUser: attr('string'),
    disabledClosed: attr('boolean'), // set to true if fetching thread fails in sidebar group chat, happens for unconfirmed groups and for disabled group chat

    // smart-search <-- highlight of userNames as it's computed and BE doesn't do it
    recordType: attr('string'), // <--- for smart search to distinguish from chatMessage
    query: attr('string'),
    highlightStart: attr('string'),
    highlightEnd: attr('string'),
    isFromSearch: attr('boolean'),
    event: belongsTo(Event),
    replyTo: attr('object'),

    open: attr('boolean', {
      defaultValue: false,
    }),
    minimized: attr('boolean', {
      defaultValue: false,
    }),
    expandedChatSize: attr('boolean', {
      defaultValue: false,
    }),
    typingUsers: attr('array', {
      defaultValue: function () {
        return A();
      },
    }),
    chatFocused: attr('boolean', {
      defaultValue: false,
    }),
    messagesInitied: attr('boolean', {
      defaultValue: false,
    }),
    messagesBeingFetched: attr('boolean', {
      defaultValue: false,
    }),

    selected: attr('boolean', {
      defaultValue: false,
    }),

    deactivated: attr('boolean', {
      defaultValue: false,
    }),

    // false when loaded messages with 'aroundId' and not loaded latest messages yet, true when regular thread loading with latest msgs
    newestMessagesShown: attr('boolean', {
      defaultValue: true,
    }),

    hiddenChat: computed('hidden', function () {
      return this.get('hidden');
    }),

    canUnhideChat: computed('isGroupChat', 'isEventChat', 'hidden', function () {
      return (this.get('isGroupChat') || this.get('isEventChat')) && this.get('hidden');
    }),

    // for chat requests
    // either: a chat request or a chat with non-contact
    isNotAContact: computed('chatType', 'notContact', function () {
      const chatType = this.get('chatType') === 'UserChatRequest';
      let notContact = this.get('notContact');
      notContact = isUndefined(notContact)
        ? false
        : notContact.hasOwnProperty('commonGroupsCount') || notContact.hasOwnProperty('allowsToBeInvited');

      return chatType || notContact;
    }),

    canCall: computed('chatType', 'isNotAContact', 'observableOthers.length', 'closed', function () {
      if (this.get('chatType') === 'GroupChat' || this.get('chatType') === 'EventChat') return false; // no calls in group and event chat for now
      if (
        this.get('closed') ||
        this.get('deactivated') ||
        this.get('isChatRequest') ||
        this.get('observableOthers.length') > 1 ||
        this.get('observableOthers.length') === 0
      )
        return false;
      // no calls in multiuser chat for now
      else return !this.get('isNotAContact'); // can call only contacts in consumer
    }),

    isChatRequest: computed('chatType', function () {
      return this.get('chatType') === 'UserChatRequest';
    }),

    // chat thread is old historic communication between two people which used to be contacts before, but one of them removed other user and now they have chat thread to archive
    // we don't want to show chat request controls for such usecase
    // we want to show chat requests controls only for usecase when users are not contacts , but chat thread is not closed, then it is chat request
    // we cannot rely only on chatType here, because chat request can have both types: UserChatRequest when other user hasn't responded yet and UserChat when it is chat request, users are in communication, but they are not contacts
    notContactNotClosed: computed('closed', 'notContact', 'chatType', function () {
      return this.get('chatType') === 'UserChatRequest' || (this.get('notContact') && !this.get('closed'));
    }),

    chatRequester: computed('startedBy', 'requestingUser', function () {
      if (this.get('isChatRequest')) {
        return this.get('startedBy') || this.get('requestingUser');
      }
    }),

    isReceivedChatRequest: computed('chatType', function () {
      if (this.get('isChatRequest')) {
        const user = CurrentUserStore.getState();
        if (user && user.get('id')) {
          return this.get('chatRequester') !== user.get('id');
        }
      }
    }),

    startedByUser: computed('startedBy', 'observableOthers', function () {
      return this.get('startedBy') ? this.getParticipant(this.get('startedBy')) : null;
    }),

    typingName: computed('typingUsers', 'typingUsers.length', 'isTyping', function () {
      const typingUsers = this.get('typingUsers');
      if (!typingUsers.length) {
        return '';
      }
      const endOne = ' ' + __('is typing...'); // TODO: switch to ICU plural
      const endMany = ' ' + __('people are typing...');
      const firstName = this.getTyperFirstName();

      return typingUsers.length > 1 ? typingUsers.length + endMany : firstName + endOne;
    }),

    getTyperFirstName: function () {
      const typingUsers = this.get('typingUsers');

      if (typeof typingUsers !== 'undefined' && typingUsers !== null && typingUsers.length === 1) {
        if (typingUsers[0].hasOwnProperty('firstName')) {
          return typingUsers[0].firstName;
        } else {
          return typingUsers[0].name;
        }
      } else {
        return '';
      }
    },

    isTyping: computed('typingUsers', 'typingUsers.length', function () {
      return this.get('typingUsers').length > 0;
    }),

    // in case of group/event chat - list of all users that are authors of messages fetched in thread in initial fetch
    // in case of user/multiuser chat - list of all chat participants, including users removed from chat (they have flag status: 'removed')
    // this should be used to get any user from chat, e.g. author of message because he can be removed from chat but still we show author
    // when needed only active chat members then use computed array "observableOthers"
    participants: attr('array', {
      defaultValue: function () {
        return A();
      },
    }),
    // array of users selected to create new chat thread with
    selectedItems: attr('array', {
      defaultValue: function () {
        return A();
      },
    }),

    // get any participant of chat, including users removed from chat but present in 'participants'
    getParticipant: function (participantId) {
      return this.participants.find((p) => p.id === participantId);
    },

    // only other user from 1-1 chat
    getUserChatParticipant: computed('participants', 'isGroupChat', 'isEventChat', function () {
      if (this.isGroupChat || this.isEventChat || this.isMultiUsersChat) return null;

      let participant = this.observableOthers.filter((u) => u.id !== CurrentUserStore.getState().get('id'))[0];

      if (participant) {
        participant.profileId = participant.publicLinkId;
      }

      return participant;
    }),

    // other users in thread that are active (not removed from multiuser chat) and are other than current user
    observableOthers: computed('participants.length', function () {
      const currentUser = CurrentUserStore.getState();
      let others;

      if (currentUser && currentUser.get('id') && this.get('participants.length')) {
        others = A(
          this.get('participants')
            .filter((p) => p.id !== currentUser.get('id') && p.status !== 'removed')
            .map((p) => EmberObject.create(p))
        );
      } else {
        others = A();
      }

      return others;
    }),

    participantsString: computed('participants', function () {
      return this.participants
        .map((p) => p.id)
        .sort()
        .join(',');
    }),

    othersString: computed('observableOthers', function () {
      return this.observableOthers
        .map((o) => o.id)
        .sort()
        .join(',');
    }),

    upHundred: computed('unread', function () {
      return this.unread > 100;
    }),

    showUnreadMessagesBorder: computed(
      'newestMessagesShown',
      'messagesInitied',
      'messages.length',
      'unread',
      function () {
        return (
          this.get('newestMessagesShown') &&
          this.get('messagesInitied') &&
          this.get('messages.length') > this.get('unread')
        );
      }
    ),

    textServer: computed(
      'lastMessage',
      'lastMessage.textServer',
      'lastMessage.editedAt',
      'lastMessage.deleted',
      'emojisReady',
      function () {
        const lastMessage = this.get('lastMessage');

        if (!lastMessage) return '';
        if (lastMessage.get('chatEventMessage')) return '';

        if (lastMessage.get('call') && !lastMessage.get('deleted')) return lastMessage.get('callTextLastMsg'); //SG-15957

        //replace dot with html entity to trick markdown to not parse "number." as ordered list
        //https://sgrouples.atlassian.net/browse/SG-15835
        let text = lastMessage
          ? lastMessage.get('textServer').replace(/\d+\.(\s|$)/g, (t) => t.replace('.', '&#8228;'))
          : '';

        // don't want to display link as last message but text without it or "User sent a link" if no other text
        text = this.skipLinks(text);

        if (text.trim().length === 0 || gifUrlsParser.toEdit(text).trim().length === 0) {
          if (lastMessage) {
            lastMessage.set('thread', {
              participants: this.participants,
            });
            text = ChatUtils.textForEmpty(lastMessage);
          }
        }

        return text;
      }
    ),

    textTitle: computed('textServer', function () {
      return Mentions.stripMentions(this.textServer);
    }),

    skipLinks: function (text) {
      let helperText = text,
        links = twitterText.extractUrlsWithIndices(text, {
          extractUrlsWithoutProtocol: true,
        });

      links.forEach((link) => (helperText = helperText.replace(link.url, '')));

      if (helperText.trim().length === 0) return '';

      return text;
    },

    usersIds: computed('observableOthers', function () {
      return this.observableOthers.map((o) => o.id).join(',');
    }),

    usersCount: computed('observableOthers', function () {
      return this.get('observableOthers.length'); // this doesn't include current user in count
    }),

    threadName: computed('observableOthers', 'chatType', 'group.name', 'name', function () {
      if (this.name) {
        return this.name;
      } else if (this.chatType === 'EventChat') {
        return this.get('event.name');
      } else if (this.chatType === 'GroupChat') {
        return this.get('group.name');
      } else {
        let observableOthers = this.observableOthers;

        if (observableOthers.length === 1) {
          return observableOthers[0].name;
        } else if (observableOthers.length === 2) {
          return __('{userName1} and {userName2}', {
            userName1: MiscellaneousUtils.tillFirstSpace(observableOthers[0].name),
            userName2: MiscellaneousUtils.tillFirstSpace(observableOthers[1].name),
          });
        } else if (observableOthers.length > 2) {
          let separator = __(', '),
            userNames =
              MiscellaneousUtils.tillFirstSpace(observableOthers[0].name) +
              separator +
              MiscellaneousUtils.tillFirstSpace(observableOthers[1].name);

          return __('{userNames} and {count} other', {
            userNames: userNames,
            count: observableOthers.length - 2,
          });
        } else if (observableOthers.length === 0) {
          return __('MeWe member');
        }
      }
    }),

    highlightedUserNames: computed('threadName', 'query', function () {
      const threadName = escape(this.get('threadName'));
      const phrase = this.get('query');

      if (this.get('isFromSearch') && phrase) {
        const beginningOfMatch = threadName.indexOf(phrase);

        if (beginningOfMatch !== -1) {
          return (
            (beginningOfMatch > 0 ? threadName.slice(0, beginningOfMatch - 1) : '') +
            this.get('highlightStart') +
            phrase +
            this.get('highlightEnd') +
            threadName.slice(beginningOfMatch + phrase.length)
          );
        }
      }

      return threadName;
    }),

    multiChatAvatars: computed('observableOthers', '_links', function () {
      if (this.chatType === 'GroupChat' || this.chatType === 'EventChat' || !this.observableOthers.length) {
        return false;
      }

      const firstUser = this.observableOthers[0];
      const template =
        this.get('_links.avatarLinkTemplate.href') ||
        get(firstUser, '_links.avatar.href') ||
        '/api/v2/photo/profile/{imageSize}/{userId}?f={fingerprint}';

      const url =
        EnvironmentUtils.getImgHost(true) +
        __(template, {
          imageSize: '150x150',
          userId: firstUser.id,
          fingerprint: firstUser.fingerprint,
        });

      return {
        othersCount: this.get('observableOthers.length'),
        url: url,
      };
    }),

    isUserChat: computed('observableOthers', function () {
      return this.get('chatType') == 'UserChat' && this.get('observableOthers.length') < 2;
    }),

    isMultiUsersChat: computed('observableOthers', 'isGroupChat', function () {
      return this.get('observableOthers.length') > 1 && !this.get('isGroupChat') && !this.get('isEventChat');
    }),

    isMultiUsersChatOwner: computed('isMultiUsersChat', 'startedBy', function () {
      return this.get('isMultiUsersChat') && this.get('startedBy') === CurrentUserStore.getState().get('id');
    }),

    isGroupChat: computed('chatType', function () {
      return this.get('chatType') == 'GroupChat';
    }),

    isEventChat: computed('chatType', function () {
      return this.get('chatType') == 'EventChat';
    }),

    publicUrl: computed('isUserChat', 'isMultiUsersChat', 'isChatRequest', function () {
      if (this.get('isUserChat') || (this.get('isChatRequest') && !this.get('isMultiUsersChat'))) {
        return this.get('observableOthers')?.[0]?.publicLinkId;
      }
    }),

    noMsgsInThread: computed('messagesInitied', 'messages.length', function () {
      return this.get('messagesInitied') && !this.get('messages.length');
    }),

    group: belongsTo(Group, {
      defaultValue: function () {
        const chatType = this.get('chatType');

        if (chatType === 'GroupChat' || (chatType === 'EventChat' && this.get('event.groupId'))) {
          const groupId = chatType === 'GroupChat' ? this.get('id') : this.get('event.groupId');
          const group = GroupStore.getState({ id: groupId });

          if (group) return group;
          else {
            // TODO: I don't think this branch can be reached
            // need to fetch group, probably it's not loaded on first page of groups or was joined but not fetched yet
            // fetching only if confirmedGroups are already populated which means that intial fetching was done
            if (GroupStore.getState().confirmedGroups.length > 0) {
              GroupApi.fetchGroupData(groupId).then((data) => {
                GroupStore.send('handleOne', data);
              });
            }
            return EmberObject.create({});
          }
        } else return null;
      },
    }),

    userChatFirstName: computed('observableOthers', function () {
      if (this.get('observableOthers.length') === 1) {
        return this.get('observableOthers')?.[0]?.firstName;
      }
    }),

    lastEditTime: computed('lastMessage', function () {
      if (this.get('lastMessage')) {
        return this.get('lastMessage.date') * 1000;
      } else {
        return 0;
      }
    }),

    pngSpriteSize: 14,
  });

  model.reopenClass({
    resourceName: 'thread',
  });

  return model;
};
