import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

import { Theme } from 'mewe/constants';
import { openPostbox } from 'mewe/utils/dialogs-common';

export default class AppMediaTab extends Component {
  @service router;
  @service dynamicDialogs;

  @alias('args.model.app.theme') theme;
  @alias('args.model.group') group;
  @alias('args.model.eventState.rootEvent') rootEvent;
  @alias('args.model.page') page;

  @tracked activeTab = this.args.model.activeTab || 0;
  @tracked albumView = !!this.args.model.albumName;
  @tracked albumName = this.args.model.albumName;
  @tracked order;

  @computed('theme', 'group.canPost', 'rootEvent.canPost', 'page.isOwnerAdmin')
  get canPost() {
    switch (this.theme) {
      case 'group':
        return this.group?.canPost;
      case 'event':
        return this.rootEvent?.canPost;
      case 'page':
        return this.page?.isOwnerAdmin;
      default:
        return false;
    }
  }

  @computed('theme')
  get canShowAlbumTab() {
    return this.theme !== Theme.EVENT;
  }

  @action
  switchTab(tabIndex) {
    if (tabIndex === this.activeTab) return false;

    if (this.theme === Theme.PAGE) {
      if (tabIndex === 0) this.router.transitionTo('app.publicid.media', this.page?.publicLinkId);
      else if (tabIndex === 1) this.router.transitionTo('app.publicid.media.albums', this.page?.publicLinkId);
      else if (tabIndex === 2) this.router.transitionTo('app.publicid.media.tags', this.page?.publicLinkId);
    } else {
      this.activeTab = tabIndex;
      this.albumView = false;
      this.order = 0;
    }
  }

  @action
  openPostBoxDialogWithPhotos() {
    let params = {
      openWithPhotoBrowser: true,
      group: this.group || null,
      groupId: this.args.model.groupId || null,
      preselectedEvent: this.rootEvent,
      page: this.page,
      theme: this.theme,
      target: this.theme,
    };

    openPostbox(params, this.dynamicDialogs);
  }

  @action
  openAlbumStream(album) {
    if (this.theme === Theme.PAGE) {
      this.router.transitionTo('app.publicid.media.album', this.page?.publicLinkId, album.name);
    } else {
      this.albumName = album.name;
      this.albumView = true;
    }
  }

  @action
  resetTabs() {
    if (this.theme === Theme.PAGE) {
      this.router.transitionTo('app.publicid.media.albums', this.page?.publicLinkId);
    } else {
      this.albumName = '';
      this.albumView = false;
    }
  }
}
