import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import Storage from 'mewe/shared/storage';
import { userAvatarHelper } from 'mewe/helpers/user-avatar-helper';
import dispatcher from 'mewe/dispatcher';

export default class UserTile extends Component {
  @tracked user;
  @tracked isEditingPermissions = false;

  @service chat;

  @service router;

  @service abilities;

  @service account;

  @service dynamicPopups;

  @reads('args.theme') theme;

  @reads('args.group') group;

  @reads('args.member') member;

  @reads('args.contact') contact;

  @reads('args.uploaded') uploaded;

  @reads('args.attendee') attendee;

  @reads('args.eventData') eventData;

  @reads('args.add') add;

  @reads('args.disableBlockAction') disableBlockAction;

  constructor() {
    super(...arguments);

    switch (this.theme) {
      case 'user':
        assert('member param missing in user-tile', this.member);
        if (this.user.constructor.resourceName != 'user') {
          throw new Error('member needs to conform to user model');
        }
        break;

      case 'member':
        assert('member param missing in user-tile', this.member);
        if (this.member.constructor.resourceName != 'member') {
          throw new Error('member needs to conform to member model');
        }
        this.user = this.member;
        break;

      case 'uploaded':
        assert('uploaded param missing in user-tile', this.uploaded);
        if (this.uploaded.constructor.resourceName != 'contact') {
          throw new Error('uploaded needs to conform to contact model');
        }
        this.user = this.uploaded;
        break;

      case 'attendee':
        this.user = this.attendee;
        break;
    }
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  get userSlug() {
    return this.canOpenSubProfile || !this.user.profileId ? this.user.id : this.user.profileId;
  }

  get canOpenSubProfile() {
    // prevent opening group/event profile for invited users or unconfirmed users
    if (this.attendee) {
      return this.attendee?.participationType !== 'Invited';
    }
    if (!this.member?.confirmed || this.args.cancel) {
      return false;
    }

    return true;
  }

  get isNsfwBlurred() {
    const isNsfwAllowed = Storage.get(Storage.keys.allowNsfwContentSetting);
    if (this.isCurrentUser || isNsfwAllowed) {
      return false;
    }
    if (this.args.eventData) {
      return this.user?._links.avatar.nsfw;
    }

    // show nsfwBlur for invitations, blocked
    return (this.args.accept || this.args.unblock) && this.user?._links.avatar.nsfw;
  }

  get displayLike4InARow() {
    // I put it like that to be easier to read, rather than long condition after return
    if (this.member && this.abilities.can('remove actor', null, { user: this.user })) {
      if (this.member.canBeBlocked && (this.member.canSendReminder || this.member.canBeInvitedToContacts)) {
        return true;
      } else if (!this.member.canSendReminder && !this.member.canBeInvitedToContacts && this.member.invited) {
        return true;
      }
    }

    return false;
  }

  get isCurrentUser() {
    return this.user.id === this.account.activeUser.id;
  }

  get canBeBlocked() {
    //don't show block option to event SG-9844
    return !this.user.isOwnerAdmin && !this.isCurrentUser && !this.user.isEventAttendee && this.user.registered;
  }

  get canBeBlockedFromAllGroup() {
    return this.canBeBlocked && this.group.isOwnerAdmin && isEmpty(this.member.invitation);
  }

  get canBeInvitedToContacts() {
    return (
      !this.isCurrentUser &&
      !this.user?.followRequestSent &&
      !this.user?.following &&
      !this.user?.nonContactCannotInvite &&
      this.user?.registered !== false &&
      this.user.canBeInvited !== false
    );
  }

  get showInvitationSent() {
    return this.user.followRequestSent;
  }

  get isFollowing() {
    return this.user.following;
  }

  @computed('user.isEventAttendee', 'user.participationType', 'user.role.name')
  get roleNameText() {
    if (this.user.isEventAttendee) {
      if (this.user.participationType === 'Owner') {
        return __('Host');
      } else if (this.user.participationType === 'Invited') {
        return __('Invited');
      }
    } else if (this.user.isGroupMember) {
      if (this.user.invitation) {
        if (this.user.isCurrentUserOwnerOrAdmin) {
          return __('Invited') + ' (' + __(this.user.role.name) + ')';
        } else {
          return __('Invited');
        }
      } else {
        return this.user.role.name ? __(this.user.role.name) : '';
      }
    }

    return '';
  }

  get showUploadedEmailsInvitation() {
    return this.uploaded && !this.uploaded.invited && this.uploaded.emails.length;
  }

  get blockMemberFromYourGroups() {
    return !this.disableBlockAction && this.canBeBlockedFromAllGroup;
  }

  get removeMemberButtonText() {
    const participationType = this.attendee?.participationType;
    if (this.isCurrentUser) {
      if (participationType) {
        return __('Leave Event');
      }
      return __('Leave Group');
    } else if (this.member?.invitation || participationType === 'Invited') {
      return __('Cancel Invitation');
    }

    return __('Remove');
  }

  /** @actions */
  @action
  sendReminderMember() {
    dispatcher.dispatch('group-member', 'sendReminder', this.user, this.group.id);
  }

  @action
  blockUser() {
    if (this.args.removeFromGroup && this.blockMemberFromYourGroups) {
      dispatcher.dispatch('contact', 'blockUserFromAllGroup', this.user, this.group.id);
    } else {
      dispatcher.dispatch(
        'contact',
        'blockUser',
        this.user,
        () => {
          this.args.onUserRemove(this.user);
        },
        this.group.id,
        this.eventData?.id
      );
    }
  }

  @action
  openChat() {
    this.chat.openThreadByParticipants([this.user]);
  }

  @action
  followUser() {
    const followOptions = {
      user: this.user,
      groupId: this.group?.id,
      eventId: this.eventData?.id,
    };

    dispatcher.dispatch('contact', 'followUser', followOptions);
  }

  @action
  openPermissions() {
    this.isEditingPermissions = true;
  }

  @action
  closePermissions() {
    this.isEditingPermissions = false;
  }

  @action
  selectNewOwner() {
    if (this.attendee) {
      dispatcher.dispatch('event-attendee', 'selectNewOwner', this.eventData.id);
    } else {
      dispatcher.dispatch('group', 'selectNewOwner', this.group);
    }
  }

  @action
  remove() {
    dispatcher.dispatch('group-member', 'removeMember', this.member, this.group, false, () => {
      this.args.onUserRemove(this.user);
    });
  }

  @action
  removeMember() {
    if (this.attendee) {
      dispatcher.dispatch('event-attendee', 'removeMember', this.user, this.eventData.id);
    }
  }

  @action
  inviteUploaded() {
    dispatcher.dispatch('contact', 'inviteUploaded', this.uploaded);
  }

  @action
  showProfilePopup(element) {
    // it is blocked user - SG-15174, SG-16309
    // no popup for invited users SG-28853
    if (this.args.unblock || this.isCurrentUser || this.user.invitation) {
      return;
    }

    if (!this.isDestroyed && !this.isDestroying) {
      const params = {
        parent: this.profilePopupSelector ? element.querySelector(this.profilePopupSelector) : element,
        avatar: userAvatarHelper([this.user]),
        group: this.group,
        owner: this.user,
        ownerId: this.user.id,
        onCloseAfterFollow: ({ follow }) => {
          this.user.following = follow?.following;
          this.user.followRequestSent = follow?.followRequestId;
          this.user.canBeInvited = false;
          this.user.invitationSent = true;
          this.user.invited = true;
        },
      };

      if (this.theme === 'contact') {
        params.regularAvatarHeight = 48;
      }

      this.dynamicPopups.openPopup('mw-profile-popup', params);
    }
  }
}
