import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import ChatApi from 'mewe/api/chat-api';
import ChatStore from 'mewe/stores/chat-store';
import dispatcher from 'mewe/dispatcher';

export default class MwEventSettingsChat extends Component {
  @reads('args.eventData') eventData;

  @service dynamicDialogs;
  @service account;

  @tracked loaded = false;
  @tracked chatMode = '';
  @tracked password = '';
  @tracked passwordError = false;
  @tracked isDeleteFormVisible = false;
  @tracked isSavingChatMode = false;

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      this.eventDataReady();
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
    super.willDestroy(...arguments);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      this.chatMode = this.eventData.chatMode;
      this.loaded = true;
    }
  }

  @computed('password.length')
  get showDeleteButton() {
    return this.password.length > 5;
  }

  @computed('chatMode', 'isSavingChatMode')
  get isSaveVisible() {
    const isChatVisibilityChanged = this.chatMode !== this.eventData.chatMode;
    return isChatVisibilityChanged && !this.isSavingChatMode;
  }

  @action
  showDeleteForm() {
    // web3 users dont need to enter password
    if (this.account.activeUser.dsnpHandle) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm Deletion'),
        message: __('Are you sure you want to delete all event chat messages from all members?'),
        cancelButtonText: __('Cancel'),
        okButtonText: __(`Yes, I'm absolutely sure`),
        onConfirm: () => {
          this.submitDeleting();
        },
      });
    } else {
      this.isDeleteFormVisible = true;
    }
  }

  @action
  chatModeChange(chatMode) {
    this.chatMode = chatMode;
  }

  @action
  saveChanges() {
    const chatMode = this.chatMode;
    const eventId = this.eventData.id;

    this.isSavingChatMode = true;

    ChatApi.setEventChatMode(eventId, { chatMode: chatMode })
      .then(() => {
        FunctionalUtils.info(__('Chat Settings have been successfully saved.'));

        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.chatMode', chatMode, eventId);

        if (chatMode === 'off') {
          ChatStore.send('removeThreadById', eventId);
        }
      })
      .catch(() => {
        this.chatMode = this.eventData.chatMode;
      })
      .finally(() => {
        this.isSavingChatMode = false;
      });
  }

  @action
  submitDeleting() {
    let password = this.password;

    this.isDeletingHistory = true;
    this.passwordError = false;

    // delete event chat history form
    ChatApi.deleteEventChatHistory(this.eventData.id, password)
      .then(() => {
        FunctionalUtils.info(__('Event chat history has been successfully deleted.'));
        PS.Pub('chat.remove.all.messages', {
          threadId: this.eventData.id,
        });

        this.password = '';
        this.isDeleteFormVisible = false;
      })
      .catch((data) => {
        if (data.status === 403) this.passwordError = true;
        else FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isDeletingHistory = false;
      });
  }
}
