/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debug } from '@ember/debug';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

import { getDurationTimeFromSeconds } from 'mewe/utils/audio-utils';
import PS from 'mewe/utils/pubsub';
import utils from 'mewe/utils/environment-utils';
import { Theme } from 'mewe/constants';
import { getElWidth } from 'mewe/utils/elements-utils';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class MwAudio extends Component {
  @service dynamicDialogs;

  @tracked volume = true;
  @tracked isInitialized = false;
  @tracked duration = '00:00';
  @tracked audioTracker;
  @tracked audioTime;
  @tracked _isPlaying = false;

  constructor() {
    super(...arguments);

    if (this.audio.duration) {
      this.duration = getDurationTimeFromSeconds(this.audio.duration);
    }

    this.bindPS();
  }

  @action
  onInsert(element) {
    this.element = element;

    this.player = this.element.querySelector('audio');

    this.player.addEventListener('timeupdate', this.timeUpdateBind);

    this.element.querySelector('.audio_tracker').addEventListener('click', this.changeTimeBind);

    this.player.onloadedmetadata = () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.duration = getDurationTimeFromSeconds(this.player.duration);
    };
  }

  @action
  onDestroy() {
    this.element.querySelector('audio').removeEventListener('timeupdate', this.timeUpdateBind);
    this.element.querySelector('.audio_tracker').removeEventListener('click', this.changeTimeBind);
  }

  bindPS() {
    this.timeUpdateBind = this.timeUpdate.bind(this);
    this.changeTimeBind = this.changeTime.bind(this);
  }

  get audioIdentifier() {
    return this.args.voiceId || this.audio.name || this.audio.fileName;
  }

  timeUpdate() {
    this.tracker(this.player.currentTime, this.player.duration);
    this.convertTime(this.player.currentTime);

    if (this.player.ended) {
      this.setIsPlaying(false);
    }
  }

  setIsPlaying(isPlaying) {
    this._isPlaying = isPlaying;
    this.args.setIsPlaying?.(isPlaying);
  }

  changeTime(e) {
    const el = this.element.querySelector('.audio_tracker'),
      rect = el.getBoundingClientRect(),
      width = getElWidth(el);

    let x = e.pageX - (rect.left + document.body.scrollLeft);

    if (width) x = x / width;
    else return;

    if (this.isPlaying && this.player) {
      let curTime = x * this.player.duration;
      if (Number.isFinite(curTime)) this.player.currentTime = curTime;
    }
  }

  tracker(currentTime, duration) {
    const timeValue = (currentTime / duration) * 100;

    if (!Number.isFinite(timeValue)) return;

    this.audioTracker = htmlSafe(`width: ${timeValue}%; height: 100%;`);
  }

  convertTime(sec) {
    const seconds = parseInt(sec % 60, 10),
      minutes = Math.floor(sec / 60);

    this.audioTime = (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
  }

  get audio() {
    return this.args.audio;
  }

  get isPlaying() {
    return this.args.isPlaying ?? this._isPlaying;
  }

  get style() {
    if (this.args.width) {
      return htmlSafe('width: ' + (this.args.width + 'px;'));
    } else {
      return htmlSafe('');
    }
  }

  get isInChat() {
    return this.args.scope === Theme.CHAT;
  }

  get audioSrc() {
    if (this.audio.urlProvided) {
      return this.audio.urlProvided;
    }

    if (!this.audio._links) return null;

    if (this.isInChat) {
      return utils.getMediaHost(true) + this.audio._links.self.href;
    } else {
      return utils.getMediaHost(true) + this.audio._links.url.href;
    }
  }

  @action
  togglePlay() {
    if (this.isPlaying) {
      this.pauseAudio();
    } else {
      this.initOrPlayAudio();
    }
  }

  initOrPlayAudio() {
    if (this.args.post?.isPublicContent && this.args.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.args.post);
      return;
    }

    const player = this.element.querySelector('audio');

    if (!player) return;

    if (!this.isInitialized) {
      this.isInitialized = true;

      player.setAttribute('autoplay', 'autoplay');

      this.setIsPlaying(false); // has to be false, when audio tag is not even in DOM
    }

    if (!this.isPlaying) {
      if (player) {
        try {
          player.play();
          this.setIsPlaying(true);
        } catch (e) {
          // DOMException: the element has no supported resources, caused by 416 "Requested Range is not satisfiable" from Amazon
          debug(e);
        }
      }
    }
  }

  pauseAudio() {
    this.setIsPlaying(false);

    const player = this.element.querySelector('audio');

    if (player) {
      try {
        player.pause();
      } catch (e) {
        // DOMException: the element has no supported resources, caused by 416 "Requested Range is not satisfiable" from Amazon
        debug(e);
      }
    }
  }

  @action
  repeatAudio() {
    this.initOrPlayAudio();
    this.player.currentTime = 0;
  }
}
