import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import MathUtils from 'mewe/utils/math-utils';

export default class MwFile extends Component {
  @service router;

  @tracked showOptionsPopup = false;

  @tracked dropdownId = '';

  @tracked parent = null;

  @tracked theme = null;

  element;

  @action
  onInsert(element) {
    this.element = element;
  }

  get document() {
    return this.args.file.post?.document || this.args.file.document || this.args.file.audio;
  }

  get owner() {
    return this.args.file.owner || this.args.file.post.owner || this.document.owner;
  }

  // hack until backend has its act together
  get fileType() {
    let type = this.document.type;

    if (type) {
      return type;
    } else {
      let name = this.document.name;
      if (name) {
        return name.toLowerCase().slice(name.lastIndexOf('.') + 1);
      }
    }
    return undefined;
  }

  get createdAt() {
    return this.args.file.createdAt || this.args.file.post.createdAt;
  }

  get documentSize() {
    return MathUtils.getDocumentSizeText(this.document.size);
  }

  get documentDownloadUrl() {
    let url = this.document._links.url?.href || this.document._links.self.href;
    return `${url}?download`;
  }

  @action
  openProfile() {
    this.routing.transitionTo('app.publicid', [this.owner.id]);
  }

  @action
  openDropdown() {
    const className = '.dropdown-opener--file-options-dropdown';
    const dropdownId = `file-dropdown-${this.document.id}`;
    const dropdownElement = document.getElementsByClassName('file-options-dropdown');
    let theme = this.args.scope;

    if (dropdownElement.length > 0) {
      if (dropdownId === dropdownElement[0].getAttribute('data-dropdownid')) {
        return;
      }
    }

    if (theme === 'chat') {
      if (this.args.thread.isGroupChat) {
        theme = 'group';
      } else if (this.args.thread.isEventChat) {
        theme = 'event';
      } else {
        theme = 'privacymail';
      }
    }
    this.theme = theme;
    this.parent = this.element.querySelector(className);
    this.dropdownId = dropdownId;
    this.showOptionsPopup = true;
  }

  @action
  closeOptionsPopup() {
    this.showOptionsPopup = false;
    this.parent = null;
    this.theme = null;
  }
}
