import storage from 'mewe/shared/storage';
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { isDefined } from 'mewe/utils/miscellaneous-utils';

export default class IsNsfwBlur extends Helper {
  @service account;

  compute([isNsfw, isNsfwProcessing, authorId]) {
    if (isDefined(authorId) && authorId === this.account.activeUser.id) {
      return false;
    }
    const isNsfwContentSettingDisabled = storage.get(storage.keys.allowNsfwContentSetting) === true ? false : true;
    return (isNsfw && isNsfwContentSettingDisabled) || (isNsfwProcessing && isNsfwContentSettingDisabled);
  }
}
