/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupApi from 'mewe/api/group-api';
import dispatcher from 'mewe/dispatcher';

export default class MwGroupApplication extends Component {
  @service chat;
  @service features;
  @service account;

  @tracked application = this.args.application;

  @tracked isTranslated;

  get displayTranslationFeature() {
    const isLangDifferent = this.account.currentTranslationLanguage !== this.application.language;
    const canTranslate =
      this.application.language &&
      isLangDifferent &&
      this.features.get('translationsGroupsApplication') &&
      this.application.answers &&
      this.hasMinimumRequiredChars;

    return this.isTranslated || canTranslate;
  }

  get hasMinimumRequiredChars() {
    let answersLength = 0;

    this.application.answers?.forEach((answer) => (answersLength += answer.answerDisplay?.length));

    return answersLength > this.account.minimumNumberOfCharacters;
  }

  @action
  handleTranslations(translation) {
    if (translation) {
      if (translation.errorCode) return;

      this.isTranslated = translation.isTranslated;
      set(this, 'application.answers', translation.data.answers);
    }
  }

  @action
  accept() {
    GroupApi.approveApplication(this.application.id).then(() => {
      dispatcher.dispatch('group', 'fetchCounters', this.args.group.id);

      FunctionalUtils.info(
        __("{userName}'s application has been approved.", {
          userName: this.application.user.name,
        })
      );

      this.args.applicationApproved(this.application);
    });
  }

  @action
  decline() {
    GroupApi.rejectApplication(this.application.id).then(() => {
      this.args.applicationRejected(this.application);

      FunctionalUtils.info(
        __("{userName}'s application has been declined", {
          userName: this.application.user.name,
        })
      );

      dispatcher.dispatch('group', 'fetchCounters', this.args.group.id);
    });
  }

  @action
  openChat() {
    this.chat.openThreadByParticipants([this.application.user]);
  }
}
