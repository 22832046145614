import Component from '@glimmer/component';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import dispatcher from 'mewe/dispatcher';
import config from 'mewe/config';

export default class LockoutRoute extends Component {
  @service settings;

  @reads('args.model') model;

  showLogout = config.environment !== 'prod' && !config.isQA;

  @computed('model.jailInfo')
  get isInJail() {
    return this.model.jailInfo;
  }

  @computed('model.jailInfo.sentenceInSec')
  get isH24() {
    return this.model.jailInfo?.sentenceInSec === 86400 || this.model.jailInfo?.sentenceInSec === 3600; // 24 hours or 1 hour on testing envs
  }

  @computed('model.jailInfo.sentenceInSec')
  get isW2() {
    return this.model.jailInfo?.sentenceInSec === 1209600 || this.model.jailInfo?.sentenceInSec === 7200; // 2 weeks or 2 hours on testing envs
  }

  @computed('model.jailInfo.sentenceInSec', 'model.jailInfo.imprisonedAt')
  get releaseDateTimeSeconds() {
    return this.model.jailInfo?.imprisonedAt / 1000 + this.model.jailInfo?.sentenceInSec;
  }

  // ms
  @computed('model.jailInfo.sentenceInSec', 'model.jailInfo.imprisonedAt')
  get releaseDateTime() {
    return this.model.jailInfo?.imprisonedAt + this.model.jailInfo?.sentenceInSec * 1000;
  }

  @computed('releaseDateTime')
  get canCheckout() {
    return this.releaseDateTime < Date.now();
  }

  @action
  logout() {
    dispatcher.dispatch('app', 'logout');
  }

  @action
  checkout() {
    dispatcher.dispatch('app', 'checkoutJail');
  }
}
