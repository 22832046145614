import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { set } from '@ember/object';
import { action } from '@ember/object';

export default class MwEventPicker extends Component {
  @service dynamicDialogs;

  get style() {
    return htmlSafe(this.args.position?.popupPosition);
  }

  @action
  openEventCreation(type) {
    let params = this.args.params || {};

    if (this.group) {
      params.groupId = this.group?.id;
    } else {
      params.eventType = type;
    }

    this.dynamicDialogs.openDialog('event-create-dialog', params);
    set(this, 'open', false); // close event picker after dialog was opened
  }

  @action
  closeDropdown() {
    this.args.close?.();
  }
}
