import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Storage from 'mewe/shared/storage';

export default class AppProfilePosts extends Component {
  @service account;
  @service router;

  @tracked isUnblurred = false;

  get model() {
    return this.args.model;
  }

  get isPostboxVisible() {
    return this.model.contact.isMe && !this.model.tag;
  }

  get isDisplayedPosts() {
    const privateUserHasPosts = !this.model.contact.public && this.model.feed.posts?.length;

    if (this.model.isMyProfile || this.model.contact.public || this.model.contact.following || privateUserHasPosts) {
      return true;
    }
    return false;
  }

  get showFeedPlaceholder() {
    return this.model.isMyProfile && !this.model.feed.posts?.length && !this.model.feed.isFetching;
  }

  get isNsfwBlurred() {
    if (this.isUnblurred) return false;
    if (this.model.contact.following) return false;
    const isNsfwAllowed = Storage.get(Storage.keys.allowNsfwContentSetting);
    if (this.model.contact.id === this.account.activeUser.id || isNsfwAllowed) {
      return false;
    }

    return this.model.contact._links?.avatar?.nsfw;
  }

  @action
  unblur() {
    this.isUnblurred = true;
  }
}
