import { escape, unescape } from 'lodash';

export default {
  // textServer => textDisplay
  toDisplay: (text) => escape(text).replace(/(?:\r\n|\r|\n)/g, '<br />'),

  // textServer => textEdit
  toEdit: (text) => escape(text),

  // textEdit => textServer
  toServer: (text) => unescape(text).replace(/\<br \/\>/g, '\n'),
};
