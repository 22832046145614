import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import EmberObject, { action } from '@ember/object';

import GroupApi from 'mewe/api/group-api';
import { each } from 'lodash';

export default class MwGroupCategorySelector extends Component {
  @tracked allCategories = A();
  @tracked searchedItems;
  @tracked searchValue;

  constructor() {
    super(...arguments);
    this.fetchGroupCategories();
  }

  fetchGroupCategories() {
    // categories could be fetched already
    if (this.allCategories?.length > 1) return;

    GroupApi.categoryNames().then((data) => {
      if (this.isDestroyed || this.isDestroying) return;
      this.prepareCategories(data.categories);
    });
  }

  prepareCategories(categories) {
    let cats = A();

    each(categories, (c) => {
      if (c.id === 'all') return;

      if (c.id === 'Uncategorized') {
        return;
      }

      cats.pushObject(
        EmberObject.create({
          categoryName: c.name,
          name: c.name,
          id: c.id,
        })
      );
    });

    if (!this.args.inSettings) {
      cats.unshiftObject(
        EmberObject.create({
          name: __('Select a category'),
          categoryName: __('Select a category'),
          id: 'Uncategorized',
        })
      );
    }

    this.allCategories = cats;
    this.setGroupCategory();
  }

  setGroupCategory() {
    const currentCatId = this.args.groupCategory.id;

    if (currentCatId) {
      const category = this.allCategories.find((c) => c.id === currentCatId);
      if (category) {
        this.args.updateGroupCategory(category);
      }
    } else if (this.args.inSettings) {
      this.args.updateGroupCategory({
        id: 'Uncategorized',
        name: __('Uncategorized'),
        categoryName: __('Uncategorized'),
      });
    }
  }

  @action
  changeGroupCategory(category) {
    this.args.updateGroupCategory(category);
  }

  @action
  setGroupCategorySearchValue(searchValue) {
    this.searchValue = searchValue;
  }

  @action
  setGroupCategorySearchedItems(searchedItems) {
    this.searchedItems = searchedItems;
  }
}
