import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwPostboxPhoto extends Component {
  get imgClasses() {
    let imgClass = 'postbox_media_photo_img';

    if (!this.args.photo.url) {
      imgClass += ' hidden';
    }

    return imgClass;
  }

  @action
  closePostbox() {
    this.args.close?.();
  }

  @action
  removePhotoPostbox(photo) {
    this.args.removePhoto?.(photo);
  }
}
