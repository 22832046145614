import Route from 'mewe/routes/route';
import { ds } from 'mewe/stores/ds';
import fetchEvents from 'mewe/fetchers/fetch-events';

export let EventsRouteBase = Route.extend({
  theme: 'events',

  getCollectionId() {
    let routeName = this.get('routeName');
    return (
      routeName.slice(routeName.lastIndexOf('.') + 1, routeName.length) +
      '-' +
      (this.get('theme') === 'group' ? this.paramsFor('app.group').group_id : 'events')
    );
  },

  model() {
    const collectionId = this.getCollectionId();

    fetchEvents(this.get('theme') === 'group' ? this.paramsFor('app.group').group_id : 'events', collectionId);

    return {
      collection: ds.events.for(collectionId),
    };
  },
});
