import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';

import PaymentsApi from 'mewe/api/payments-api';
import { emailRegex } from 'mewe/shared/utils';
import twitterText from 'twitter-text';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsContact extends Component {
  @alias('args.model.appPage.page') page;

  @tracked loaded = false;
  @tracked countries = [];
  @tracked searchedItems;
  @tracked searchValue;
  @tracked _country;

  @tracked phone;
  @tracked email;
  @tracked website;
  @tracked street;
  @tracked cityAndState;
  @tracked zipCode;
  @tracked liveValidation;

  constructor() {
    super(...arguments);

    PaymentsApi.getCountries().then((data) => {
      this.countries = data.countries;
    });

    this.setLoaded();

    addObserver(this, 'page.name', this.setLoaded);
  }

  willDestroy() {
    removeObserver(this, 'page.name', this.setLoaded);
  }

  setLoaded() {
    if (this.page.name && !this.loaded) {
      this.loaded = true;
      this.phone = this.page.phone || '';
      this.email = this.page.email || '';
      this.website = this.page.website || '';
      this.street = this.page.address.street || '';
      this.cityAndState = this.page.address.cityAndState || '';
      this.zipCode = this.page.address.zipCode || '';
    }
  }

  @computed('countries.length', 'page.address.country', '_country')
  get country() {
    if (this._country) {
      return this._country;
    }

    if (this.page.address.country && this.countries.length) {
      return this.countries.find((el) => {
        return el.code === this.page.address.country || el.name === this.page.address.country;
      });
    }

    return { name: '', code: '' };
  }

  @computed('{street,cityAndState,zipCode,country,isSaving}', 'page.address.{street,cityAndState,zipCode,country}')
  get isSaveLocationVisible() {
    const streetChanged = this.street !== (this.page.address.street || '');
    const cityChanged = this.cityAndState !== (this.page.address.cityAndState || '');
    const zipChanged = this.zipCode !== (this.page.address.zipCode || '');
    const countryChanged = this.country.code !== (this.page.address.country || '');

    return !this.isSaving && (streetChanged || cityChanged || zipChanged || countryChanged);
  }

  @computed('{phone,email,website,isSaving}', 'page.{phone,email,website}')
  get isSaveContactVisible() {
    const phoneChanged = this.phone !== this.page.phone;
    const emailChanged = this.email !== this.page.email;
    const websiteChanged = this.website !== this.page.website;

    return !this.isSaving && this.isValidated() && (phoneChanged || emailChanged || websiteChanged);
  }

  @computed('isSaving', 'loaded')
  get isPending() {
    return this.isSaving || !this.loaded;
  }

  @computed('email.length', 'liveValidation', 'requestEmailError')
  get isIncorrectEmail() {
    const isIncorrect = this.email.length && (!emailRegex.test(this.email) || this.requestEmailError);

    if (!this.liveValidation) return false;
    if (this.requestEmailError) this.requestEmailError = false;

    return isIncorrect;
  }

  @computed('website.length', 'liveValidation')
  get isIncorrectWebsite() {
    if (!this.liveValidation) return false;
    return this.website && this.website.trim().length && !twitterText.extractUrls(this.website)[0];
  }

  isValidated() {
    return !this.isIncorrectEmail && !this.isIncorrectWebsite;
  }

  @action
  setCountrySearchValue(searchValue) {
    this.searchValue = searchValue;
  }

  @action
  setCountrySearchedItems(searchedItems) {
    this.searchedItems = searchedItems;
  }

  @action
  selectCountry(country) {
    this._country = country;
  }

  @action
  saveContactSettings() {
    this.liveValidation = true;

    if (this.isSaving || !this.isValidated()) return;

    this.isSaving = true;

    const params = {
      phone: this.phone.trim(),
      email: this.email.trim(),
      website: this.website.trim(),
    };

    const callback = () => {
      this.page.setProperties(params);
      this.website = this.website.trim();
      this.phone = this.phone.trim();
      this.isSaving = false;
      this.liveValidation = false;
    };
    const fail = (data) => {
      this.isSaving = false;
      if (data && data.data && data.data.message === 'Invalid parameter email: ') {
        this.requestEmailError = true;
      }
    };

    dispatcher.dispatch('pages', 'saveSettings', this.page, params, callback, fail);
  }

  @action
  saveLocationSettings() {
    if (this.isSaving) return;

    this.isSaving = true;

    const params = {
      street: this.street.trim(),
      cityAndState: this.cityAndState.trim(),
      zipCode: this.zipCode.trim(),
      country: this.country.code.trim(),
    };

    const callback = () => {
      this.isSaving = false;
      this.page.set('address', {
        street: params.street,
        cityAndState: params.cityAndState,
        zipCode: params.zipCode,
        country: params.country,
      });
    };
    const fail = () => {
      this.isSaving = false;
    };

    dispatcher.dispatch('pages', 'saveSettings', this.page, params, callback, fail);
  }
}
