import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import { getDocIconUrl, getDocIconUrlFromFileName } from 'mewe/utils/document-utils';

export default buildHelper(function (docNameOrType) {
  let icon;

  if (!docNameOrType) icon = '';
  else if (docNameOrType.length === 2 && docNameOrType[1]) {
    icon = getDocIconUrl(docNameOrType[1]);

    if (icon.indexOf('default.png') !== -1) {
      //try from name
      icon = getDocIconUrlFromFileName(docNameOrType[0]);
    }
  } else icon = getDocIconUrlFromFileName(docNameOrType[0]);
  return htmlSafe(icon);
});
