import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import Storage from 'mewe/shared/storage';
import Verbose from 'mewe/utils/verbose';
import config from 'mewe/config';

const verbose = Verbose({ prefix: '[Signup/config]', color: 'orange', enabled: true }).log;

export default class MwHomeAuthClass extends Component {
  @tracked authMode = null;
  @tracked authPass = false;
  @tracked configLoaded = false;
  @tracked promisesToWaitFor = [];

  authModes = ['dsnp_siwa', 'dsnp_amplica', 'mewe'];

  constructor() {
    super(...arguments);

    const configPromise = PublicPagesApi.getRegistrationInfo();
    this.promisesToWaitFor.push(configPromise);

    configPromise
      .then((res) => {
        this.setAuthMode(res);
      })
      .catch(() => {
        this.setAuthMode({ defaultRegistrationMode: 'mewe' }); // fallback to mewe just in case
      })
      .finally(() => {
        this.configLoaded = true;
        verbose(`authMode=${this.authMode}`);
      });
  }

  setAuthMode(configFromBE) {
    const hash = window.location.hash;

    // - manually passed config values always have priority over BE config in order
    //   to allow testing the desired state in any situation

    // set "pass=true" or "pass=false" in url or "allowPass" in storage to override BE config
    if (~hash.indexOf('authPass=true')) {
      this.authPass = true;
    } else if (~hash.indexOf('authPass=false')) {
      this.authPass = false;
    } else if (Storage.get('authPass')) {
      this.authPass = Storage.get('authPass');
    } else {
      this.authPass = configFromBE.pass === true;
    }

    // registration mode can be forced by passing hash "authMode" in url
    if (~hash.indexOf('authMode=')) {
      const mode = this.authModes.find((m) => ~hash.indexOf(`authMode=${m}`));

      if (mode) {
        this.authMode = mode;
        this.configLoaded = true;
        return;
      }
    }

    if (config.enviroment !== 'prod') {
      const authMode = Storage.get('authMode');
      if (authMode) {
        this.authMode = authMode;
        this.configLoaded = true;
        return;
      }
    }

    // only if there is no value from parent and no forced mode from url, then use BE value
    this.authMode = configFromBE.defaultRegistrationMode || 'mewe'; // fallback to mewe just in case
  }

}
