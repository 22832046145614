import { assert } from '@ember/debug';

export const withLabels_Contacts = (data) => {
  let contacts = data.filter((el) => {
    return el.user.isContact;
  });

  let all = data.filter((el) => {
    return !el.user.isContact;
  });

  if (contacts[0]) contacts[0].label = __('Contacts');
  if (all[0]) all[0].label = __('All of MeWe');

  return contacts.concat(all);
};

export const withLabels_Group = (data, groupName) => {
  assert('Missing groupName in text-editor withLabels_Group()', groupName);

  let members = data.filter((el) => {
    return el.user.groupMember;
  });

  let others = data.filter((el) => {
    return !el.user.groupMember;
  });

  if (members[0]) members[0].label = groupName;
  if (others[0]) others[0].label = __('MeWe Members');

  return members.concat(others);
};

export const withLabels_GroupOwnerAdmins = (data, groupName) => {
  assert('Missing groupName in text-editor withLabels_GroupOwnerAdmins()', groupName);

  let admins = data.filter((el) => {
    return el.role?.name == 'Admin';
  });

  let owners = data.filter((el) => {
    return el.role?.name == 'Owner';
  });

  const items = admins.concat(owners);

  if (items[0]) items[0].label = __('{groupName} Owner/Admins', { groupName });

  return items;
};
