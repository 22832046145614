import { typeOf } from '@ember/utils';
import EmberArray, { A } from '@ember/array';
import Transform from './base';

export default Transform.extend({
  deserialize: function (serialized) {
    return EmberArray.detect(serialized) ? serialized : A(serialized);
  },

  serialize: function (deserialized) {
    var type = typeOf(deserialized);
    if (type == 'array') {
      return deserialized;
    } else if (type == 'string') {
      return deserialized.split(',').map(function (item) {
        return item.trim();
      });
    } else {
      return A();
    }
  },
});
