/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
/* eslint-disable ember/no-observers */
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import EmberObject, { action, computed } from '@ember/object';
import { scheduleOnce, next } from '@ember/runloop';
import ContactsApi from 'mewe/api/contacts-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import InvitationsUtils from 'mewe/utils/invitations-utils';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { maxEmailInvitationsAtOnce } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import { addObserver, removeObserver } from '@ember/object/observers';
import { guidFor } from '@ember/object/internals';

//TODO: Need to refactor
export default class MwInviteImportCloudsponge extends Component {
  @service router;

  @service dynamicDialogs;

  @tracked element;

  @tracked contacts = A();

  @tracked searchStr = '';

  @tracked totalCount;

  @tracked isSending;

  @tracked allUsersSelected;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);
    this.invitationType = this.args.groupId ? 'group-invitation' : 'contact-invitation';
    if (this.invitationType === 'group-invitation') {
      this.contributorPermissions = 'post,comment,reshare,invite';
    }
    this.openImportWindow();
    this.allUsersSelected = this.args.allUsersSelected;
    addObserver(this, 'searchStr', this, this.searchStrChange);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.scrolling.unbindScrollDown(this.element);
    removeObserver(this, 'searchStr', this, this.searchStrChange);
  }

  @action
  setElement(el) {
    this.element = el;
  }

  @computed('allUsersSelected', 'contacts', 'totalCount', 'contacts.@each.selected')
  get totalInviteCount() {
    return this.allUsersSelected
      ? this.totalCount - this.contacts.filter((c) => !c.selected).length
      : this.contacts.filter((c) => c.selected).length;
  }

  @computed('contacts.{contacts.length,@each.selected}', 'isSending')
  get canSendInvitations() {
    return !this.isSending && this.contacts.filter((c) => c.selected).length > 0;
  }

  get trimmedSearchStr() {
    return (this.searchStr || '').trim().toLowerCase();
  }

  @computed('args.provider')
  get providerIconSrc() {
    switch (this.args.provider) {
      case 'gmail':
        return '/assets/icons/provider-gmail.png';
      case 'yahoo':
        return '/assets/icons/provider-yahoo.png';
      case 'outlook':
        return '/assets/icons/provider-outlook.png';
      case 'windowslive':
      case 'hotmail':
        return '/assets/icons/provider-hotmail.png';
      case 'aol':
        return '/assets/icons/provider-aol.png';
      default:
        return '';
    }
  }

  openImportWindow() {
    this.contactsImportInitiated = true;
    const provider = this.args.provider,
      windowWidth = 600,
      windowHeight = 600,
      valueLeft = window.screenX + (window.innerWidth - windowWidth) / 2,
      valueTop = window.screenY + 50,
      options = 'left=' + valueLeft + ',top=' + valueTop + ',width=' + windowWidth + ',height=' + windowHeight;

    this.wsContactsImportReadyBind = this.wsContactsImportReady.bind(this);
    this.wsContactImportFailureBind = this.wsContactImportFailure.bind(this);

    PS.Unsub('contacts.import.failed', 'clearAll');
    PS.Unsub('contacts.import.ready', 'clearAll');
    PS.Sub('contacts.import.ready', this.wsContactsImportReadyBind);
    PS.Sub('contacts.import.failed', this.wsContactImportFailureBind);

    let win = window.open(`/contacts-import/${provider}`, __('Import Your Addressbook'), options);
    if (!win) {
      FunctionalUtils.error(__('Please disable the Pop-up blocker in your browser.'));
    } else {
      next(this, () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.contacts = A();
        this.args.onImportingContacts?.(true);
        this.args.onCloudspongeResultsView?.(true);
      });

      //PS.Pub('contacts.import.ready', {'msgType':'contact-import-ready','data':{'addressBookName':'gmail(cranekicksoftware@gmail.com)','refImportId':99180095},'msgNo':1326642});
    }
  }

  wsContactImportFailure() {
    // SG-30475 - Delay failure handling in case a successful import is delivered via WS short after failure (1-500ms)
    setTimeout(() => {
      // simple preventing this WS message from other tab
      if (!this.contactsImportInitiated || !this.args.isImportingContacts) {
        return;
      }
      PS.Unsub('contacts.import.failed', this.wsContactImportFailureBind);
      PS.Unsub('contacts.import.ready', this.wsContactsImportReadyBind);
      FunctionalUtils.showDefaultErrorMessage();
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.args.onImportingContacts?.(false);
      this.args.onCloudspongeResultsView?.(false);
    }, 1500);
  }

  wsContactsImportReady(data) {
    // simple preventing this WS message from other tab
    if (!this.contactsImportInitiated) {
      return;
    }
    PS.Unsub('contacts.import.failed', this.wsContactImportFailureBind);
    PS.Unsub('contacts.import.ready', this.wsContactsImportReadyBind);
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    this.addressBookName = data.addressBookName;
    this.refImportId = data.refImportId;
    this.loadContacts();
  }

  searchStrChange() {
    if (!this.searchStr) {
      this.searchContacts();
    } // update list when search string is cleared
  }

  loadContacts() {
    const params = {
      offset: this.contacts.length,
      maxResults: 80,
    };

    if (this.refImportId) {
      params.refId = this.refImportId;
    } else {
      params.addressbook = this.addressBookName;
    }

    ContactsApi.importAddressbookContacts(params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (data.contacts && data.contacts.length) {
          let contacts = A(this.prepareContacts(data.contacts));

          if (this.contacts.length < 1) {
            this.contacts = contacts;
            this.totalCount = data.totalCount;
          } else {
            this.contacts.pushObjects(contacts);
          }

          scheduleOnce('afterRender', this, () => {
            if (!this.isDestroyed && !this.isDestroying) {
              this.scrolling.bindScrollDownElement(this.element, () => this.loadContacts());
            }
          });
          this.args.onCloudspongeResultsView?.(true);
          this.args.updateShowImportResults?.(true);
        } else {
          if (params.offset === 0) {
            FunctionalUtils.error(__('This address book does not contain any contacts.'));
            this.unselectProvider();
          }

          this.scrolling.unbindScrollDown(this.element);
        }
      })
      .catch(() => FunctionalUtils.showDefaultErrorMessage())
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.args.onImportingContacts?.(false);
      });
  }

  prepareContacts(importedContacts = []) {
    let searchStr = this.trimmedSearchStr;
    return importedContacts.map((contact) => {
      contact.id = guidFor(contact);
      contact.name = contact.name || '';
      contact.connections = contact.emails;

      if (!contact.emails.length) {
        contact.disabled = true; // inviting phone numbers is disabled

        if (contact.phoneNumbers.length) {
          contact.selectedConnection = contact.phoneNumbers[0];
        }
      } else {
        contact.selectedConnection = contact.connections[0];
      }

      contact.selected = !contact.disabled && this.allUsersSelected;
      if (searchStr) {
        contact.matchesSearch = this.contactMatchesSearch(contact, searchStr);
      } else {
        contact.matchesSearch = true;
      }
      return EmberObject.create(contact);
    });
  }

  contactMatchesSearch(contact, searchStr) {
    let name = contact.name || contact.fname + ' ' + contact.lname,
      contains = (txt) => {
        return txt && txt.toLowerCase().indexOf(searchStr) !== -1;
      };

    return (
      contains(name) ||
      contains(contact.email) ||
      (contact.connections && !!contact.connections.find((mailOrPno) => contains(mailOrPno)))
    );
  }

  proceedSending(invitees, ref) {
    const params = {
        ref: ref,
        groupId: this.args.groupId,
      },
      callback = () => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.isSending = false;
        }
        if (this.args.groupId) {
          dispatcher.dispatch('group', 'fetchCounters', this.args.groupId);
        }
        if (this.router.currentRouteName == 'app.group.members.invited') {
          PS.Pub('group.members.refresh');
        }

        this.args.closeAction?.();
      };

    // if checkAll is selected then no need to send list of addresses but just 'wholeAddressBook' param
    if (this.allUsersSelected) {
      params.wholeAddressBook = {
        refId: this.refImportId,
        name: this.addressBookName,
      };
    } else {
      params.emailInvitees = invitees.emailInvitees;
      params.smsInvitees = invitees.smsInvitees;
    }

    this.isSending = true;
    if (this.invitationType === 'contact-invitation') {
      InvitationsUtils.sendFollowInvitations(this.dynamicDialogs, params, callback);
    } else {
      InvitationsUtils.sendGroupInvitations(this.dynamicDialogs, params, callback, null, true);
    }
  }

  getSelectedEmails() {
    let selectedContacts = this.contacts.filter((c) => c.selected),
      selectedNotMembers = {
        emailInvitees: A(),
        smsInvitees: A(),
      },
      selectedMembers = {
        emailInvitees: A(),
        smsInvitees: A(),
      };

    selectedContacts.forEach((c) => {
      if (c.isSuggested) {
        selectedMembers = this.addToInvitees(selectedMembers, c.selectedConnection);
      } else {
        selectedNotMembers = this.addToInvitees(selectedNotMembers, c.selectedConnection);
      }
    });

    return {
      members: selectedMembers,
      notMembers: selectedNotMembers,
    };
  }

  addToInvitees(invitees, address) {
    // only emails can be invited
    if (address?.indexOf('@') !== -1) {
      invitees.emailInvitees.push({
        emails: address,
        permissions: this.contributorPermissions,
      });
    }

    return invitees;
  }

  setContactConnection(contact, event) {
    contact.set('selectedConnection', event.target.value);
  }

  @action
  sendInvitations() {
    if (this.isSending) {
      return;
    }

    let addressesToInvite = this.getSelectedEmails(),
      memberInvitees = addressesToInvite.members.emailInvitees.length + addressesToInvite.members.smsInvitees.length,
      nonMemberInvitees =
        addressesToInvite.notMembers.emailInvitees.length + addressesToInvite.notMembers.smsInvitees.length,
      totalNumberOfInvitees = memberInvitees + nonMemberInvitees;

    if (totalNumberOfInvitees > 0) {
      // if check all is selected then there is only one call inviting whole address book
      if (this.allUsersSelected) {
        this.proceedSending(null, 'invite-dialog');
        return;
      }

      // there are two arrays: one with already members to invite, second with not-members emails to invite
      // and two api calls are made with different 'ref' param. We need it for statistics
      if (memberInvitees > 0) {
        this.proceedSending(addressesToInvite.members, 'contact-suggestion');
      }

      if (nonMemberInvitees > 0) {
        this.proceedSending(addressesToInvite.notMembers, 'invite-dialog');
      }
    } else {
      FunctionalUtils.error(__('Please add an invitee first.'));
    }
  }

  @action
  toggleSelected(contact) {
    if (!contact.selected) {
      const selectedCount = this.contacts.filter((c) => c.get('selected')).length;
      if (selectedCount >= maxEmailInvitationsAtOnce) {
        contact.set('selected', false);
        FunctionalUtils.error(
          __('There can be maximum of {count} invitations at once.', {
            count: maxEmailInvitationsAtOnce,
          })
        );
        return;
      }
    }

    contact.toggleProperty('selected');
    //Hacky way to update the template
    let originalContact = this.contacts.find((c) => c === contact);
    originalContact.set('selected', !originalContact.selected);
  }

  @action
  toggleSelectAll() {
    const currentState = this.allUsersSelected;
    this.contacts.forEach((c) => {
      if (!c.disabled) {
        c.set('selected', !currentState);
      }
    });
  }

  @action
  searchContacts() {
    let searchStr = this.trimmedSearchStr,
      contacts = this.contacts;

    if (!searchStr) {
      contacts.forEach((c) => c.set('matchesSearch', true));
    } else {
      contacts.forEach((c) => c.set('matchesSearch', this.contactMatchesSearch(c, searchStr)));
    }
  }

  @action
  clearSearch() {
    this.searchStr = null;
  }
}
