let controllers = {};
let controllerNames = [
  'app',
  'chat',
  'client-data',
  'contact',
  'contacts',
  'event-attendee',
  'events',
  'feed',
  'feedback',
  'group',
  'group-member',
  'public-pages',
  'document',
  'comment',
  'notification',
  'report',
  'poll',
  'pages',
  'purchase',
  'emojify',
];
import config from 'mewe/config';

import { getFirstInsert } from 'mewe/utils/first-insert';
import { SplitRetry } from 'mewe/utils/split-retry';

let ctx = null;

export default {
  setCtx(c) {
    ctx = c;
  },

  dispatch(controller, action, ...options) {
    return new Promise((resolve /*, reject*/) => {
      if (!getFirstInsert() && config.environment != 'prod')
        console.log(
          `dispatch function used before first render! This will slow down first rendering - action controllers should be used only to handle user actions. Action Controller: ${controller} Action: ${action}`
        );

      if (controllerNames.indexOf(controller) == -1 || !controller || !action)
        return console.log('Error in dispatcher - controller:' + controller + ' action: ' + action);

      if (controllers[controller]) {
        controllers[controller].send(action, ...options);

        resolve();
      } else {
        let cname = controller + '-action-controller';

        SplitRetry(() => import(/* webpackChunkName: "[request]" */ `/action-controllers/${cname}`)).then((module) => {
          controllers[controller] = module.default.create({ ctx });

          controllers[controller].send(action, ...options);

          resolve();
        });
      }
    });
  },
};
