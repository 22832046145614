import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';
import groupBy from 'lodash/groupBy';

import Scrolling from 'mewe/utils/scrolling-utils';
import { fetchWrappers } from 'mewe/fetchers/fetch-wrappers';
import { WrapperScope } from 'mewe/constants';

export default class MwSidebarWrappers extends Component {
  @tracked optionsDropdownOpened = false;

  @service settings;
  @service dynamicDialogs;

  scrolling = Scrolling();

  @action
  onInsert(element) {
    this.element = element;
    this.bindLoadMoreScrolling();

    addObserver(this, 'args.wrappers.nextPage', this.bindLoadMoreScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.mw-box_content'));
    removeObserver(this, 'args.wrappers.nextPage', this.bindLoadMoreScrolling);
  }

  get currentWrappersScope() {
    switch (this.args.scope) {
      case WrapperScope.GROUP:
        return this.args.wrappers?.items?.filterBy('scope', WrapperScope.GROUP);

      case WrapperScope.PAGE:
        return this.args.wrappers?.items?.filterBy('scope', WrapperScope.PAGE);

      default:
        return this.args.wrappers?.items;
    }
  }

  get notMutedWrappers() {
    let getWrapperPage = (wrapper) => {
      let inFeatured = this.args.model.featured?.items?.find((i) => i.id === wrapper.id);
      let inFollowed = this.args.model.followed?.items?.find((i) => i.id === wrapper.id);
      let inFeedPages = this.args.model.pages?.items?.find((i) => i.id === wrapper.id);

      return inFeatured || inFollowed || inFeedPages;
    };

    let wrappersData = this.currentWrappersScope.filter((w) => {
      if (w.scope === WrapperScope.PAGE) {
        const wrapperPageData = getWrapperPage(w);

        if (wrapperPageData) {
          w.set('page', wrapperPageData);

          return wrapperPageData;
        } else {
          return false;
        }
      } else if (w.scope === WrapperScope.GROUP) {
        const group = this.getWrapperGroup(w);

        w.set('group', group);

        return group && !group.isMuted;
      }
    });

    return wrappersData;
  }

  get groupsMap() {
    return groupBy(this.args.groups, 'id');
  }

  getWrapperGroup(wrapper) {
    const wrapperId = wrapper.id,
      groupsMap = this.groupsMap;

    return groupsMap[wrapperId] && groupsMap[wrapperId][0];
  }

  bindLoadMoreScrolling() {
    this.scrolling.bindScrollDownElement(
      this.element.querySelector('.mw-box_content'),
      () => {
        this.loadMoreWrappers();
      },
      300
    );
  }

  loadMoreWrappers() {
    // nextPage could be loaded in wrappers-dialog so we check if it's present to avoid unnecessary request
    if (this.args.wrappers.nextPage) {
      fetchWrappers(this.args.scope, { nextPage: this.args.wrappers.nextPage });
    } else {
      this.scrolling.unbindScrollDown(this.element.querySelector('.mw-box_content'));
    }
  }

  @action
  showWrapper(wrapper) {
    this.dynamicDialogs.openDialog('wrappers-dialog', {
      wrapperId: wrapper ? wrapper.id : null,
      wrappersScope: this.args.scope,
    });
  }
}
