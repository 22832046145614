/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { processCloudStorageData } from 'mewe/utils/miscellaneous-utils';
import { isDefined } from 'mewe/utils/miscellaneous-utils';

export default class MwSettingsSidebarRight extends Component {
  @service account;
  @service dynamicDialogs;

  get isLoaded() {
    return isDefined(this.account.activeUser?.settings);
  }

  get cloudStorage() {
    return processCloudStorageData(this.account.activeUser?.settings?.cloudStorage);
  }

  get spaceLeftTextShort() {
    return __(`{spaceLeft} GB Free`, {
      spaceLeft: this.cloudStorage?.leftGB,
    });
  }

  get spaceLeftTextLong() {
    return __(`You have {spaceLeft} GB Free OF {spaceTotal} GB SPACE`, {
      spaceLeft: this.cloudStorage?.leftGB,
      spaceTotal: this.cloudStorage?.totalGB,
    });
  }

  @action
  openStorageDialog() {
    this.dynamicDialogs.openDialog('store/store-item-storage-dialog');
  }

  @action
  openDeleteAccountDialog() {
    this.dynamicDialogs.openDialog('delete-account-dialog');
  }
}
