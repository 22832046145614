import Component from '@glimmer/component';
import { reads } from '@ember/object/computed';
import { action, computed } from '@ember/object';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import dispatcher from 'mewe/dispatcher';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { inject as service } from '@ember/service';

export default class MwGroupBackground extends Component {
  @service dynamicDialogs;

  @reads('args.rootEvent') rootEvent;
  @reads('args.group') group;
  @reads('args.page') page;
  @reads('args.isOpenPage') isOpenPage;

  @computed('group.avatarLink', 'rootEvent._links.imageLinkTemplate.href', 'page.cover')
  get photoUrl() {
    if (this.rootEvent) {
      return (
        EnvironmentUtils.getImgHost(true) +
        this.rootEvent._links?.imageLinkTemplate?.href.replace('{imageSize}', '1600x1600')
      );
    } else if (this.group) {
      return this.group.avatarLink.replace('{imageSize}', '1600x1600');
    } else if (this.page) {
      return this.page.cover.replace('{imageSize}', '1600x1600');
    }
  }

  @action
  coverClick() {
    if (this.isOpenPage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.photoUrl) {
      this.dynamicDialogs.openDialog('media-dialog', {
        photoUrl: this.photoUrl.replace('{imageSize}', '1600x1600'),
        mediaType: 'photoUrl',
        allowMultipleInstances: true,
      });
    }
  }

  @action
  unmuteGroupPosts() {
    dispatcher.dispatch('group', 'unmuteGroupPosts', this.group.id);
  }
}
