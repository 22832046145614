import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DarkThemeGranted extends Component {
  @service settings;

  @action
  activateDarkTheme() {
    this.settings.saveTheme('dark');
  }
}
