import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class ChatMessageAvatar extends Component {
  @service account;
  @service dynamicPopups;

  @reads('args.message') message;

  @action
  onInsert(element) {
    this.element = element;
    this.showProfilePopupBind = this.showProfilePopup.bind(this);
  }

  showProfilePopup() {
    if (this.isDestroyed && !this.isDestroying) return;

    // somehow authorId can be undefined - sentry errors show that.
    // I guess that this might happen when user is deleted/banned
    if (!this.authorId || this.authorId === this.account.activeUser.id) return;

    this.dynamicPopups.openPopup('mw-profile-popup', {
      parent: this.element,
      avatar: this.authorAvatar,
      owner: this.messageAuthor,
      group: this.args.thread.group,
      insideAnotherScrollable: true,
      inChatMessage: true,
      userId: this.authorId,
    });
  }

  @computed('message.{owner,author}')
  get messageAuthor() {
    return this.message.author || this.message.owner;
  }

  @computed('msgOwner', 'message.authorId')
  get authorId() {
    return this.message.authorId || this.messageAuthor?.id || this.messageAuthor?.userId;
  }

  @computed('message.{owner,author}')
  get authorAvatar() {
    let avatarHref = this.messageAuthor?._links?.avatar?.href,
      groupId = this.args.thread.group?.id || this.message.groupId;

    if (avatarHref) {
      // group=& fix for ws msgs
      avatarHref = avatarHref.replace('group=&', groupId ? 'group=' + groupId + '&' : '');
      avatarHref = avatarHref.replace('{imageSize}', '150x150');
    } else {
      avatarHref = `/api/v2/photo/profile/150x150/${this.authorId}`;

      const fprint = this.messageAuthor?.fingerprint || this.messageAuthor?.fprint || '';

      // param order needs to match backend order to avoid img from flashing when prerendered chat msg is replaced with one from response
      if (groupId) {
        avatarHref += `?group=${groupId}&f=${fprint}`;
      } else {
        avatarHref += `?f=${fprint}`;
      }
    }

    return EnvironmentUtils.getImgHost(true) + avatarHref;
  }

  @computed('message.isMyMessage', 'message.author._links.avatar.nsfw', 'args.thread.isChatRequest')
  get isBlurred() {
    return !this.message.isMyMessage && this.args.thread.isChatRequest && this.message.author?._links?.avatar?.nsfw;
  }

  @action
  openProfile() {
    if (this.args.openProfile) {
      this.args.openProfile(this.messageAuthor);
    }
  }
}
