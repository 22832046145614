import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import GroupStore from 'mewe/stores/group-store';

export default class MwDocument extends Component {
  @service router;
  @service dynamicDialogs;

  @tracked openOptionsPopup = false;

  @tracked doc = this.args.doc;

  @tracked group = this.args.group;

  @tracked scope = '';

  constructor() {
    super(...arguments);
    if (this.args.isGroup && this.doc.post.group) {
      this.scope = 'group';
      this.doc.post.group = this.group;
    } else if (this.args.myCloudGroupId) {
      this.scope = 'group';
      let group = GroupStore.getState({ id: this.args.myCloudGroupId });
      if (group) {
        this.doc.post.group = group;
      }
    } else if (this.currentGroupId === 'contacts') {
      this.scope = 'contacts'; // documents in user profile
    } else {
      this.scope = 'mycloud';
    }
  }

  get showDropdown() {
    return this.doc.canRemove || this.doc.canMove;
  }

  get groupId() {
    if (this.args.myCloudGroupId) {
      return this.args.myCloudGroupId;
    } else if (this.group) {
      return this.group.id;
    }
    return '';
  }

  get showDropdownFollow() {
    return !this.doc.post.cannotFollow && !this.args.myCloudGroupId;
  }

  get isFolder() {
    return this.doc.isFolder;
  }

  @action
  moveFile() {
    this.dynamicDialogs.openDialog('move-file-dialog', {
      group: this.group,
      document: this.doc,
    });
  }

  @action
  openProfile() {
    if (this.doc.post && this.doc.post.owner) {
      this.router.transitionTo('app.publicid', this.doc.post.owner.id);
    }
  }

  @action
  openDropdown() {
    const dropdownId = 'document-options-popup';
    const dropdownElement = document.getElementsByClassName('document-options-dropdown');

    if (dropdownElement.length > 0) {
      if (dropdownId === dropdownElement[0].getAttribute('data-dropdownid')) {
        return;
      }
    }

    const elementId = 'btn-' + this.doc.id;
    this.parent = document.getElementById(elementId);
    this.dropdownId = dropdownId;
    this.openOptionsPopup = true;
  }

  @action
  closeOptionsPopup() {
    this.openOptionsPopup = false;
    this.parent = null;
    this.dropdownId = '';
  }
}
