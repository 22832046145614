import Embed from 'quill/blots/embed';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';

class Mention extends Embed {
  static className = 'h-mention';

  static create(data) {
    const node = super.create();
    node.classList.add('text-editor_embed');
    node.innerHTML = EmojisParser.toDisplay(data.text);
    return Mention.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

Mention.tagName = 'span';
Mention.blotName = 'mention';

export default Mention;
