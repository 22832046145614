/* eslint-disable lines-between-class-members */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import CommentApi from 'mewe/api/comments-api';
import Mentions from 'mewe/utils/mentions-utils';
import PostUtils from 'mewe/utils/post-utils';
import { getElHeight } from 'mewe/utils/elements-utils';
import { Theme, highlightTime } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import PS from 'mewe/utils/pubsub';
import { reads, alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { set, computed } from '@ember/object';
import { action } from '@ember/object';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class MwComment extends Component {
  profilePopupSelector = '.comment_text .h-mention';

  @service dynamicDialogs;
  @service dynamicPopups;
  @service account;
  @service features;
  @service account;

  api = CommentApi;

  @tracked truncate = true;
  @tracked didTruncation;
  @tracked isReplying;
  @tracked commentHighlighted;
  @tracked gifModel;
  @tracked commentDropdownOpened;
  @tracked mentionsStrategy;
  @tracked focusReplyInputOnInsert;
  @tracked _showReplies;

  @reads('account.activeUser') currentUser;
  @reads('args.comment') comment;
  @reads('args.group') group;
  @reads('args.post') post;
  @reads('comment.isTranslated') isTranslated;
  @alias('args.commentToHighlightId') commentToHighlightId;

  constructor() {
    super(...arguments);

    if (!this.comment.get('replyTo') && this.comment.get('replies.length')) {
      this._showReplies = true;

      if (this.args.isPostDialog) {
        this.isReplying = this.showReplies;
      }
    }

    if (this.comment.get('replies.length') && this.post.canComment) {
      this.isReplying = true;
    }

    if (this.comment.get('photo.animated')) {
      this.gifModel = this.comment.get('photo');
    }
    this.showProfilePopupBind = this.showProfilePopup.bind(this);
    this.highlightComment();
    addObserver(this, 'comment.id', this.tmpCommentUpdate);
    addObserver(this, 'comment.replies.length', this.replyObserver);
  }

  get scope() {
    return this.args.scope ?? PostUtils.getPostScopeAndId(this.post).scope;
  }

  get showReplies() {
    return this.args.showReplies || this._showReplies;
  }

  @action
  setInputEl(el) {
    this.element = el;
  }

  highlightComment() {
    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.commentToHighlightId && this.comment.id === this.commentToHighlightId) {
        if (this.args.inMediaDialog) {
          const mdPostEl = this.element.closest('.media-dialog_post'),
            postEl = this.element.closest('.c-mw-post'),
            mdPostElHeight = getElHeight(mdPostEl),
            postElHeight = getElHeight(postEl);

          mdPostEl.scrollTop = postElHeight - mdPostElHeight;
        } else if (this.args.isPostDialog) {
          // scroll only when dialog is bigger than screen
          if (document.querySelector('.dialog_inner').offsetHeight > document.body.offsetHeight) {
            document.querySelector('.dialog_wrapper').scrollTop = this.element.closest('.postbar_wrapper').offsetTop;
          }
        }

        this.commentHighlighted = true;
        set(this, 'commentToHighlightId', null);

        setTimeout(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          this.commentHighlighted = false;
        }, highlightTime);
      }
    });
  }

  // newly added comment has temporary id, id update will mean that real comment data was set from WS/response
  tmpCommentUpdate() {
    // set gifModel when temporary comment is replaced with real data
    if (this.comment.get('photo.animated')) {
      this.gifModel = this.comment.get('photo');
    }
  }

  get canAddEmoji() {
    //For group post comments/replies if user is invited to join but not yet jonied
    //adding emoji is blocked
    if (this.scope === 'group' && !this.group.isConfirmed) {
      return false;
    }

    return this.comment.canAddEmoji;
  }

  @computed('post.{groupId,group.isMember}')
  get isAuthorLinkDisabled() {
    if (this.post?.customInteraction) {
      return true;
    }

    // disable group/profile link in group posts if user is not member of group
    return this.post?.groupId && !this.post?.group.isMember;
  }

  get deleteText() {
    return this.comment.get('replyTo') ? __(`Delete reply`) : __(`Delete comment`);
  }

  get editText() {
    return this.comment.get('replyTo') ? __(`Edit reply`) : __(`Edit comment`);
  }

  get deleteBanText() {
    return this.comment.get('replyTo')
      ? __(`Delete reply and ban user from page`)
      : __(`Delete comment and ban user from page`);
  }

  get confirmDeleteText() {
    return this.comment.get('replyTo') ? __(`Confirm reply deletion`) : __(`Confirm comment deletion`);
  }

  @computed('comment.photo', 'args.commentMediaDialog')
  get showCommentPhoto() {
    // commentMediaDialog - if photo-comment was opened in MD,
    // don't show photo in comment because it's already in main part of MD
    return this.comment.get('photo') && !this.args.commentMediaDialog;
  }

  @computed('comment.{canEdit,canRemove,postedByPage,replyTo}')
  get showDropdownFollow() {
    // nothing like 'do not follow comment as a page'
    return (
      !this.comment.get('replyTo') &&
      !this.comment.get('postedByPage') &&
      (this.comment.get('canEdit') || this.comment.get('canRemove'))
    );
  }

  @computed('comment.owner.id', 'currentUser.id')
  get notOwner() {
    return this.currentUser.id !== this.comment.get('owner.id');
  }

  @computed('comment.{canEdit,canRemove}', 'notOwner')
  get showCommentDropdownMenu() {
    return this.comment.get('canEdit') || this.comment.get('canRemove') || this.notOwner;
  }

  @computed('post.{customInteraction,discoverScope,isGroupPreview,isPublicContent}')
  get isProfilePopupDisabled() {
    if (this.post?.discoverScope === 'group' || this.post?.isPublicContent) {
      return true;
    }

    return this.post?.isGroupPreview;
  }

  // eslint-disable-next-line ember/use-brace-expansion
  @computed('comment.owner.id', 'comment.{canRemove,postedByPage}', 'currentUser.id', 'post.page')
  get canRemoveAndBan() {
    return (
      this.comment.get('owner.id') != this.currentUser.id &&
      this.post?.page &&
      this.comment.get('canRemove') &&
      !this.comment.get('postedByPage')
    );
  }

  @computed('comment.{pageId,link,removeLink,postedByPage}', 'scope')
  get showCommentLink() {
    const commentLinkByFollower = this.comment.get('pageId') && !this.comment.get('postedByPage');
    return this.comment.get('link') && !this.comment.get('removeLink') && !commentLinkByFollower;
  }

  @computed()
  get showReplyButton() {
    if (this.comment.replyTo) return false; // can't reply to reply

    return this.post?.canComment || this.comment.repliesCount;
  }

  @computed('isReplying', 'post.canComment')
  get showReplyTextarea() {
    return this.isReplying && this.post.canComment;
  }

  replyObserver() {
    if (this.comment.get('replies.length') === 0 && !this.comment.get('failedToReply')) {
      this.isReplying = false;
      this._showReplies = false;
    }
  }

  showProfilePopup(target) {
    if (!this.isDestroyed && !this.isDestroying) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: target,
        owner: { id: target.getAttribute('data-userid') },
        group: this.group,
        isMention: true,
      });
    }
  }

  get displayTranslationFeature() {
    const isLangDifferent = this.account.currentTranslationLanguage !== this.comment.language;
    const hasMinimumRequiredChars = this.comment.textServer?.length > this.account.minimumNumberOfCharacters;
    const canTranslate =
      this.comment.language &&
      isLangDifferent &&
      this.features.get('translationsComments') &&
      hasMinimumRequiredChars &&
      !this.comment.isCurrentUserCommentOwner;

    return this.comment.isTranslated || canTranslate;
  }

  @action
  handleTranslations(translation) {
    if (translation && !translation.errorCode) {
      set(this, 'comment.isTranslated', translation.isTranslated);
      set(this, 'comment.textServer', translation.data.text);
    }
  }

  @action
  edit() {
    const { scope, scopeId } = PostUtils.getPostScopeAndId(this.post);
    let comment = this.comment;
    let mentionsStrategy,
      mentionsScope = {};

    this.commentDropdownOpened = false;
    comment.set('isEditing', true);

    if (scope === Theme.CONTACTS) {
      mentionsStrategy = Mentions.createTextCompleteStrategy('contacts', comment);
    } else {
      if (scope === Theme.PRIVATEPOSTS) {
        mentionsScope.privacyPost = this.post;
      } else if (scope === Theme.EVENT) {
        mentionsScope.eventId = scopeId;
      } else if (scope === Theme.GROUP) {
        mentionsScope.groupId = scopeId;
      } else if (scope === Theme.PAGE) {
        mentionsScope.pageId = scopeId;
        mentionsScope.postItemId = this.post.postItemId;
      }
      mentionsStrategy = Mentions.createTextCompleteStrategy(mentionsScope, comment);
    }
    this.mentionsStrategy = mentionsStrategy;
  }

  @action
  remove() {
    this.commentDropdownOpened = false;

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: this.confirmDeleteText,
      message: this.comment.get('replyTo')
        ? __('Are you sure you want to delete this reply? <br> You will NOT be able to undo this.')
        : __('Are you sure you want to delete this comment? <br> You will NOT be able to undo this.'),
      cancelButtonText: __('Cancel'),
      okButtonText: this.deleteText,
      noEscaping: true,
      onConfirm: () => {
        CommentApi.removeComment(this.comment.id);
        PS.Pub('comment.remove', this.comment);
      },
    });
  }

  @action
  removeAndBan() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: this.confirmDeleteText,
      message: this.comment.get('replyTo')
        ? __('Are you sure you want to delete this reply? <br> You will NOT be able to undo this.')
        : __('Are you sure you want to delete this comment? <br> You will NOT be able to undo this.'),
      cancelButtonText: __('Cancel'),
      okButtonText: this.deleteText,
      noEscaping: true,
      onConfirm: () => {
        CommentApi.removeComment(this.comment.id);
        PS.Pub('comment.remove', this.comment);
        dispatcher.dispatch('pages', 'banUser', this.post?.page?.id, this.comment.get('owner.id'));
      },
    });
  }

  @action
  openMediaDialog(comment) {
    this.dynamicDialogs.openDialog('media-dialog', {
      mediaType: 'comment',
      theme: this.scope, // for proper dialog color class (e.g. open photo from group post shared to Home)
      isComment: true,
      dataProvided: true,
      groupId: this.group && this.group.id,
      pageId: this.pageId || this.comment.get('pageId') || this.post.pageId,
      comment: comment,
      post: this.post,
      imageSize: comment.get('photo.size'),
      discoverScope: this.post.discoverScope,
      isGroupPreview: this.post.isGroupPreview,
      allowMultipleInstances: true,
    });
  }

  @action
  openCommentOptionsDropdown() {
    this.commentDropdownOpened = !this.commentDropdownOpened;
  }

  @action
  confirmExit() {
    return __(`You haven't finished your reply. Do you really want to leave without finishing?`);
  }

  @action
  toggleReplies() {
    let newVal = !this.showReplies;

    this._showReplies = newVal;

    this.isReplying = newVal;
    this.focusReplyInputOnInsert = true;
  }

  @action
  replyAdded() {
    this._showReplies = true;
  }

  @action
  report() {
    // eslint-disable-next-line object-property-newline
    dispatcher.dispatch('report', 'openReportDialog', { comment: this.comment });
  }

  @action
  setTruncate(value) {
    this.truncate = value;
  }

  @action
  setDidTruncation(value) {
    this.didTruncation = value;
  }

  @action
  setGifModel(gifModel) {
    this.gifModel = gifModel;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    removeObserver(this, 'comment.id', this.tmpCommentUpdate);
    removeObserver(this, 'comment.replies.length', this.replyObserver);
  }
}
