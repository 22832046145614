import { assert } from '@ember/debug';

import Component from '@glimmer/component';

export default class MwInViewport extends Component {
  constructor() {
    super(...arguments);
    assert(
      'didEnter action must be passed to <MwInViewport> component',
      this.args.didEnter && typeof this.args.didEnter === 'function'
    );
  }
}
