/* eslint-disable lines-between-class-members */
import { action } from '@ember/object';
import layout from './template.hbs';
import './styles.scss';
import ChatApi from 'mewe/api/chat-api';
import ChatStore from 'mewe/stores/chat-store';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import PS from 'mewe/utils/pubsub';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';
import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { imgDownloadPath } from 'mewe/helpers/img-download-path';
import { inject as service } from '@ember/service';
export default class ChatOptionsDropdownComponent extends PopupOpener {
  layout = layout;

  @service account;
  @service analytics;
  @service dynamicDialogs;

  popupType = 'dropdown-popup';

  closeOnClickOutside = true;

  closeOnScroll = true;

  @action
  initDropdown() {
    PS.Sub('close.dropdowns.chat-options', () => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.close();
      }
    });
    this.setupPopup();
  }

  willDestroy() {
    PS.Unsub('close.dropdowns.chat-options');
    super.willDestroy(...arguments);
  }

  get dropdownPosition() {
    const isRtl = document.dir;
    // for small chats open popup on the left for right-most chat and on the right for following chats to avoid overflowing page SG-29266
    const isSmallChatOnRight =
      !this.args.isChatWindow && ChatStore.getState().get('openedThreads')[0].id !== this.args.thread.id;
    let position = this.getDefaultDropdownOptions(true);

    // this is an issue in mobile view when message options are wrapped to new line (SG-30190)
    const notEnoughLeftSpace = position.parentOffset.left < 180;

    Object.assign(position, {
      reposition: { elementHeight: 230 },
      placement: {
        top: true,
        left: (isRtl && !isSmallChatOnRight) || (!isRtl && isSmallChatOnRight) || notEnoughLeftSpace,
      },
      translate: { x: -20 },
    });

    return position;
  }

  get message() {
    return this.args.message;
  }

  get showEdit() {
    return this.message.canEdit && !this.attachment && !this.message.sticker;
  }

  get showCopy() {
    return (
      this.message.canCopy &&
      !this.message.attachments.length &&
      !this.message.call &&
      !this.message.isGifUrl &&
      this.message.textServer.indexOf('.gif') === -1
    );
  }

  get attachment() {
    return this.message.attachments[0];
  }

  get attachmentUrl() {
    return this.attachment._links.self.href;
  }

  get isGroupChat() {
    return this.groupId || this.message.thread.group;
  }

  get canDownloadPhoto() {
    return this.isGroupChat && this.message.canDownloadPhoto;
  }

  forwardMessages(users, groups, threads) {
    const messages = [this.message];
    const options = {
      receivers: users.map((i) => i.id),
      groups: groups.map((i) => i.id),
      chatThreads: threads.map((i) => i.id),
      messageIds: messages.map((i) => i.id),
    };

    ChatApi.forward(JSON.stringify(options))
      // eslint-disable-next-line no-undef
      .then(() => FunctionalUtils.info(__('Message sent')))
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }

  get photoDownloadPath() {
    return imgDownloadPath(
      this.attachment._links.self.href,
      { defaultExt: this.attachment.animated ? 'gif' : 'jpg' },
      true
    );
  }

  @action
  forward() {
    this.dynamicDialogs.openDialog('chat-candidates-dialog', {
      // eslint-disable-next-line no-undef
      title: __('Forward Message'),
      // eslint-disable-next-line no-undef
      saveText: __('Forward Message'),
      submitAction: (users, groups, threads) => {
        this.forwardMessages(users, groups, threads);
      },
    });
  }

  @action
  delete() {
    this.args.delete();
  }

  @action
  edit() {
    this.args.message.isInEditMode = true;
  }

  @action
  copy() {
    let textToCopy = toServer(this.message.textEdit);
    textToCopy = MiscellaneousUtils.removeHtmlTagsFromText(textToCopy);

    MiscellaneousUtils.copyToClipboard(textToCopy);
  }

  @action
  report() {
    dispatcher.dispatch('report', 'openReportDialog', {
      message: this.message,
      thread: this.args.thread,
    });
  }

  @action
  reply() {
    dispatcher.dispatch('chat', 'setThreadReply', this.args.thread, this.message);

    if (this.args.focusTextarea) {
      this.args.focusTextarea();
    }
  }

  @action
  onPhotoDownload() {
    const is_owner = this.message.authorId === this.account.activeUser.id;
    this.analytics.sendEvent('photoDownloaded', {
      location: 'group_chat',
      is_owner,
    });
  }
}
