import { isEmpty } from '@ember/utils';
import Transform from './base';

export default Transform.extend({
  deserialize: function (serialized) {
    return isEmpty(serialized) ? null : Number(serialized);
  },
  serialize: function (deserialized) {
    return isEmpty(deserialized) ? null : Number(deserialized);
  },
});
