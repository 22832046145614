import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { A } from '@ember/array';
import { getOwner } from '@ember/application';
import { each } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import EmojiPicker from 'mewe/pods/components/pickers/mw-emoji-picker';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { serverTo_server } from 'mewe/utils/emoji-utils';
import FeedUtils from 'mewe/utils/feed-utils';
import { getElWidth } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';
import { postCustomInteraction } from 'mewe/utils/post-utils';
export default class MwPostbarEmojiButton extends Component {
  @service dynamicDialogs;

  maxEmojis = 4;

  @tracked simplePickerParent;
  @tracked isSimpleEmojiPickerVisible;

  @action
  onInsert(element) {
    this.element = element;
  }

  setEmoji(emoji) {
    if (this.args.isMessage) {
      dispatcher.dispatch('emojify', 'addEmoji', this.args.model, emoji);
    } else {
      // post in model object can be not included in any store (e.g. in SPV) so first update it and then posts from store
      dispatcher.dispatch('emojify', 'addEmoji', this.args.model, emoji);

      let posts = FeedUtils.matchPostsFromAllFeeds(this.args.model.postItemId);

      each(posts, (post) => {
        if (this.args.model.isComment) {
          let commentOrReply = FeedUtils.getCommentOrReplyFromPost(this.args.model, post);
          if (commentOrReply) dispatcher.dispatch('emojify', 'addEmoji', commentOrReply, emoji);
          return true;
        } else {
          dispatcher.dispatch('emojify', 'addEmoji', post, emoji);
        }
      });
    }
  }

  rollback(data, rollbackCopy) {
    set(this, 'args.model.emojis.emojiCounts', rollbackCopy.emojiCounts);
    set(this, 'args.model.emojis.userEmojis', rollbackCopy.userEmojis);

    let text = FunctionalUtils.defaultErrorMessage();

    if (data.status === 409) {
      text = __('You cannot add more than {maxEmoji} emojis to one post/comment.', {
        maxEmoji: this.maxEmojis,
      });
    } else if (data.status === 404) {
      text = __('Post/Comment not found');
    }

    FunctionalUtils.error(text);
  }

  @action
  clickHandler() {
    const post = this.args.commentPost || this.args.model;

    if (post.isGroupPreview) return;

    if (post.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, post);
      return;
    }

    if (EmojiPicker(getOwner(this)).isOpen()) {
      EmojiPicker().send('close');
    } else {
      if (this.args.model.emojisItems?.length >= this.maxEmojis) {
        this.showSimpleEmojiPicker();
      } else {
        // pickerOpenerEl - any element from DOM can be used as picker opener to force positioning
        const openerElementEl =
          this.args.pickerOpenerEl && getElWidth(this.args.pickerOpenerEl) ? this.args.pickerOpenerEl : this.element;
        /**
         * always position picker on a side of opener element
         * in case of chat messages, we use emojis list el as opener
         * to have it always visible after opening picker
         */
        const forceSidePosition = this.args.isMessage;

        EmojiPicker().send('reposition', openerElementEl, forceSidePosition);
        EmojiPicker().send('open', {});

        const options = { scope: this.args.scope, model: this.args.model },
          rollbackCopy = {
            emojiCounts: A([...this.args.model.emojis?.emojiCounts]),
            userEmojis: A([...this.args.model.emojis?.userEmojis]),
          };

        EmojiPicker().send('setCallback', (emoji) => {
          emoji = serverTo_server(emoji.shortname);
          this.setEmoji(emoji);
          this.args.api.postEmoji(options, emoji).catch((data) => this.rollback(data, rollbackCopy));
        });
      }
    }
  }

  @action
  showSimpleEmojiPicker() {
    this.simplePickerParent = this.element.querySelector('.postbar_button_emoji');
    this.isSimpleEmojiPickerVisible = true;
  }

  @action
  closeSimpleEmojiPicker() {
    this.simplePickerParent = null;
    this.isSimpleEmojiPickerVisible = false;
  }
}
