import Component from '@glimmer/component';
import { getOwner } from '@ember/application';
import { computed, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AppPageStatictics extends Component {

  @service account;

  @tracked selectedDateRange;
  @tracked dateRanges;

  routeName = '';

  constructor() {
    super(...arguments);

    const ranges = [
      { range: 7, text: __('{count}-day', { count: 7 }) },
      { range: 30, text: __('{count}-day', { count: 30 }) },
      { range: 90, text: __('{count}-day', { count: 90 }) }, // ,{ range: 365, text: __("{count}-year", { count: 1 }) }
    ];

    this.dateRanges = ranges;
    this.selectDateRange(ranges[0]);
  }

  @computed('args.model.ref')
  get refAsMillis() {
    let ref = parseInt(this.args.model.ref);

    if (isNaN(new Date(ref).getTime())) {
      const now = new Date(),
        sixDays = 6 * 24 * 60 * 60 * 1000;

      ref = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0) - sixDays;
    }

    return ref;
  }

  @action
  selectDateRange(range) {
    const now = new Date(),
      startOfDayMillis = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0),
      startOfDay = new Date(startOfDayMillis),
      daysRange = range.range * 24 * 60 * 60 * 1000,
      lastDay = new Date(startOfDay - daysRange),
      lastDayFormat = { month: 'short', day: 'numeric' },
      dayFormat = { year: 'numeric', month: 'short', day: 'numeric' };

    if (startOfDay.getUTCFullYear() !== lastDay.getUTCFullYear()) {
      lastDayFormat.year = 'numeric';
    }

    const locale = this.account.activeUser.jsLocale;

    set(range, 'fromDateText', lastDay.toLocaleDateString(locale, lastDayFormat));
    set(range, 'toDateText', startOfDay.toLocaleDateString(locale, dayFormat));

    this.selectedDateRange = range;

    if (this.routeName) {
      const route = getOwner(this).lookup(this.routeName);
      if (route) route.send('selectDateRange', range);
    }
  }
}
