import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import { isEmpty } from 'lodash';

import EventsApi from 'mewe/api/events-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwEventSettingsPermissions extends Component {
  @service account;

  @reads('args.eventData') eventData;
  @reads('args.eventSettings') eventSettings;

  @tracked loaded;
  @tracked isSaving;
  @tracked emailNotifyType;
  @tracked commentEmail;
  @tracked postEmail;
  @tracked mentionEmail;
  @tracked postApprovalEmail;

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      this.eventDataReady();
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);
    addObserver(this, 'eventSettings', this, this.eventDataReady);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
    removeObserver(this, 'eventSettings', this, this.eventDataReady);
    super.willDestroy(...arguments);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      if (this.eventSettings) {
        this.emailNotifyType = this.eventSettings.emailNotifyType;
        this.commentEmail = this.eventSettings.commentEmail;
        this.postEmail = this.eventSettings.postEmail;
        this.mentionEmail = this.eventSettings.mentionEmail;
        this.postApprovalEmail = this.eventSettings.postApprovalEmail;
        this.loaded = true;
      } else if (!this.loadingEventSettings) {
        dispatcher.dispatch('events', 'fetchEventSettings', this.eventData.id);
        this.loadingEventSettings = true;
        return;
      }
    }
  }

  @computed('{isSaving,commentEmail,postEmail,mentionEmail,postApprovalEmail,emailNotifyType}')
  get isSaveVisible() {
    return !this.isSaving && !isEmpty(this.getParams());
  }

  getParams() {
    const params = {};

    if (!this.loaded) return params;

    if (this.commentEmail !== this.eventSettings.commentEmail) params.commentEmail = this.commentEmail;
    if (this.postEmail !== this.eventSettings.postEmail) params.postEmail = this.postEmail;
    if (this.mentionEmail !== this.eventSettings.mentionEmail) params.mentionEmail = this.mentionEmail;
    if (this.postApprovalEmail !== this.eventSettings.postApprovalEmail)
      params.postApprovalEmail = this.postApprovalEmail;
    if (this.emailNotifyType !== this.eventSettings.emailNotifyType) params.emailNotifyType = this.emailNotifyType;

    return params;
  }

  @action
  emailNotifyTypeChange(notifyType) {
    this.emailNotifyType = notifyType;
  }

  @action
  saveSettings() {
    const self = this;
    const params = this.getParams();

    this.isSaving = true;

    EventsApi.setEventEmailNotifs(this.eventData.id, params)
      .then(() => {
        FunctionalUtils.info(__('Event Settings have been successfully saved.'));

        for (let p in params) {
          if (params.hasOwnProperty(p)) {
            set(this, `eventSettings.${p}`, params[p]);
          }
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }
}
