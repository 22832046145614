import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import { isDefined } from 'mewe/utils/miscellaneous-utils';
import { map, groupBy } from 'lodash';
import dispatcher from 'mewe/dispatcher';

export default class MwStore extends Component {
  @service router;
  @service purchase;
  @service settings;

  @tracked activeTab;
  @tracked activeSlide;

  slides = ['premium', 'support'];

  constructor() {
    super(...arguments);

    if (this.slides.length > 1) {
      this.activeSlide = 0;
      this.setSlidesInterval();
    }
  }

  willDestroy() {
    window.clearInterval(this.slideTimeout);
  }

  setSlidesInterval() {
    window.clearInterval(this.slideTimeout);
    this.slideTimeout = window.setInterval(() => {
      this.doSlide();
    }, 10000);
  }

  @computed('router.currentRouteName')
  get activeCategory() {
    const routeName = this.router.currentRouteName;

    switch (routeName) {
      case 'app.store.index':
      case 'app.store.product':
        return 'all';
      case 'app.store.themes':
        return 'themes';
      case 'app.store.subscriptions':
        return 'subscriptions';
      case 'app.store.emojis':
        return 'emojis';
      case 'app.store.stickers':
        return 'stickers';
      default:
        return 'all';
    }
  }

  @computed('args.storeState.storeItems.length', 'activeCategory')
  get storeItemsByCategory() {
    if (!this.args.storeState.storeItems.length) return A();

    let productsByCategory = map(
      groupBy(this.args.storeState.storeItems, 'displayInfo.category'),
      (items, category) => {
        return {
          name: category,
          items: items,
          isVisible: !!items.find((i) => i.displayInfo.isHidden !== true), // category is visible when it contains element to display
        };
      }
    );

    const premiumItem = this.args.storeState.storeItems.find((p) => p.id === 'premium');
    const supportCategory = productsByCategory.find((c) => c.name.toLowerCase() === 'support mewe');
    if (premiumItem && supportCategory) {
      // premium item should be first
      supportCategory?.items?.unshift(premiumItem);
      // support category should be first
      const index = productsByCategory.indexOf(supportCategory);
      productsByCategory.splice(index, 1);
      productsByCategory.unshift(supportCategory);
    }

    if (this.activeCategory === 'all') {
      return productsByCategory;
    } else {
      return [productsByCategory.find((c) => c.name.toLowerCase() === this.activeCategory)];
    }
  }

  @action
  selectTab(tabName) {
    this.activeTab = tabName;
  }

  @action
  doSlide(selectedSlide) {
    // selectedSlide present when it's user action
    if (isDefined(selectedSlide)) {
      if (this.activeSlide !== selectedSlide) {
        this.activeSlide = selectedSlide;
        this.setSlidesInterval();
      }
    } else {
      if (this.activeSlide < this.slides.length - 1) {
        this.activeSlide += 1;
      } else {
        this.activeSlide = 0;
      }
    }
  }

  @action
  showPremiumDetails() {
    dispatcher.dispatch('purchase', 'showItemDetails', 'premium', { context: 'store_banner' });
  }
}
