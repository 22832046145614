import Component from '@glimmer/component';

export default class CancelledSubscriptionPromoCode extends Component {
  get subscriptionType() {
    if (this.args.notification?.promoCode?.productId?.indexOf('premiumpromotion.monthly') !== -1) {
      return __('monthly premium subscription');
    } else if (this.args.notification?.promoCode?.productId?.indexOf('premiumpromotion.yearly') !== -1) {
      return __('yearly premium subscription');
    }
  }
}
