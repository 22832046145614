import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import dispatcher from 'mewe/dispatcher';

import FunctionalUtils from 'mewe/shared/functional-utils';

export default class MwSidebarFeedback extends Component {
  @tracked feedbackText = '';
  @tracked reply = 'no';
  @tracked fileIds = A();
  @tracked uploadJobs = A();
  @tracked selectedTitle = '';
  @tracked isUploading = false;

  feedbackTitles = A([
    __('Improvement Idea'),
    __('Compliment'),
    __('Idea for a New Feature'),
    __('Problem Report'),
    __('General Feedback'),
  ]);

  minTextLimit = 8;

  constructor() {
    super(...arguments);
    this.setChildBind = this.setChild.bind(this);
  }

  setChild(child) {
    this.attachmentUpload = child;
  }

  @action
  resetForm() {
    this.selectedTitle = this.feedbackTitles[0];
    this.feedbackText = '';
    this.reply = 'no';
    this.fileIds = A();

    this.attachmentUpload?.cleanAllUploadJobs();
  }

  @action
  addPhotoAttachment() {
    scheduleOnce('afterRender', () => {
      if (this.isDestroying || this.isDestroyed) return;
      this.attachmentUpload.uploadClick();
    });
  }

  @action
  replyChange(reply) {
    this.reply = reply;
  }

  @action
  setFeedbackText(feedbackText) {
    this.feedbackText = feedbackText;
  }

  @action
  sendFeedback() {
    // "send" button should be disabled anyways
    if (this.isUploading) return;

    if (this.feedbackText?.length >= this.minTextLimit) {
      const callback = () => {
        FunctionalUtils.info(__(`Thank you for your Feedback!`));
        this.resetForm();
      };

      const feedback = {
        topic: this.selectedTitle,
        reply: this.reply !== 'no',
        imageIds: this.fileIds,
        text: this.feedbackText,
      };

      dispatcher.dispatch('feedback', 'sendFeedback', JSON.stringify(feedback), callback);
    } else {
      FunctionalUtils.error(
        __(`Feedback text must be at least {number} characters long`, { number: this.minTextLimit })
      );
    }
  }

  @action
  fileUploadUpdate({ fileIds, isUploading }) {
    this.fileIds = fileIds;
    this.isUploading = isUploading;
  }

  @action
  updateUploadJobs(action, job) {
    this.uploadJobs[action](job);
  }
}
