import EmberObject from '@ember/object';
import { unescape } from 'lodash';
import LinkScrappingApi from 'mewe/api/linkscrapping-api';
import GfycatApi from 'mewe/api/gfycat-api';
import twitterText from 'twitter-text';
import { getEmojiUrlCdn } from 'mewe/utils/emoji-utils';
import { getEventSummaryText } from 'mewe/utils/event-utils';
import { isYoutubeLink } from 'mewe/utils/miscellaneous-utils-light';
import { findGifUrl, findPureGfycatId, gifUrlToGif } from 'mewe/utils/gif-utils';

/*
  REQUIRED PROPERTIES of component
    - canDoLinkScrapping, computed permission for component, e.g. can depend on other types of content
    - parseTextValueKey, name of variable used in text input, e.g. newPost in 4

  OPTIONAL PROPERTIES of component
    - showLinkPreview, whether or not show scrapped link preview or loading

  OPTIONAL METHODS/ACTIONS
    - handleLinkData

  CLEAN AFTER - after sharing content and cleaning inputs clean also link fields
    this.setInitialLinkProperties();
*/

export default class Link {
  constructor(parent) {
    this.parent = parent;
    this.setInitialLinkProperties();
  }

  setInitialLinkProperties() {
    this.parent.link = null;
    this.parent.lastFetchedLink = null;
    this.parent.isLoadingLink = false;
    this.parent.isLoadingGfyLinkInfo = false;
  }

  parseGifUrl(url) {
    let selectedGifs = this.parent.selectedGifs;

    if (!this.parent.convertGifUrlFromLinks || selectedGifs.length) {
      return;
    }

    const gfycatId = findPureGfycatId(url);

    const moveToSelectedGifs = (gifUrl) => {
      if (!selectedGifs.length) {
        const textWithNoGif = this.parent[this.parent.parseTextValueKey].replace(url, '');

        this.parent.parseTextValueKey = textWithNoGif; // text has to be removed to not send gif twice, one as gif param and second in text
        this.parent.updateEditor?.(textWithNoGif);

        selectedGifs.pushObject(gifUrlToGif(gifUrl));
      }
      this.parent.updateHeight?.(); // update postbox height
    };

    let found;

    if (gfycatId) {
      found = true;

      this.parent.isLoadingGfyLinkInfo = true;

      GfycatApi.getGfycatInfo(gfycatId)
        .then((data) => {
          if (this.parent.isDestroyed || this.parent.isDestroying) return;

          if (data && data.gfyItem) {
            let wh = '';
            if (data.gfyItem.height && data.gfyItem.width) {
              wh = `#h=${data.gfyItem.height}w=${data.gfyItem.width}`;
            }
            moveToSelectedGifs(`${data.gfyItem.mobileUrl}${wh}`);
          }
        })
        .finally(() => {
          if (this.parent.isDestroyed || this.parent.isDestroying) return;
          this.parent.isLoadingGfyLinkInfo = false;
        });
    } else {
      const gifUrl = findGifUrl(url);

      if (gifUrl) {
        found = true;
        moveToSelectedGifs(gifUrl);
      }
    }

    return found;
  }

  fetchLinkInfo(originalUrl) {
    if (!originalUrl || this.parent.isLoadingLink) return;

    let url = unescape(originalUrl).toString();

    if (url.substring(0, 4) != 'http' && url.substring(0, 3) != 'ftp') {
      url = 'http://' + url;
    }

    this.parent.isLoadingLink = true;

    LinkScrappingApi.info(url)
      .then((data) => {
        // isLoadingLink can be set to false if user sent content without waiting for link preview
        if (this.parent.isDestroyed || this.parent.isDestroying || !this.parent.isLoadingLink) return;

        // no data about link
        if (!data || !data.info) {
          this.parent.link = null;
          return;
        }

        this.parent.link = this.processLinkData(data);
        this.parent.updateHeight?.();
      })
      .catch(() => {
        if (this.parent.isDestroyed || this.parent.isDestroying) return;
        this.parent.link = null;
      })
      .finally(() => {
        if (this.parent.isDestroyed || this.parent.isDestroying) return;
        this.parent.isLoadingLink = false;
      });
  }

  parseLinkFunc(e) {
    if (typeof e === 'undefined' || e === null || this.parent.isDestroyed || this.parent.isDestroying) {
      return;
    }

    const keyPressed = e.keyCode || e.which;

    // 9 tab, 13 enter, 32 space
    if ((!keyPressed && e.type === 'focusout') || keyPressed === 9 || keyPressed === 13 || keyPressed === 32) {
      this.parseLink();
    }
  }

  parseLink() {
    if (!this.parent.canDoLinkScrapping?.()) return;

    const urls = twitterText.extractUrls(this.parent[this.parent.parseTextValueKey]).filter((value) => {
      return !(value.indexOf(getEmojiUrlCdn() + '/emoji/') === 0 || value.indexOf('.svg') > -1); // emoji
    });

    const url = urls[0];

    if (!url) return;

    // do not parse gif link in format that we use in gifs picker, show it as gif instead
    let gifUrl = this.parseGifUrl(url);

    if (!gifUrl) {
      if (url !== this.parent.lastFetchedLink) {
        this.parent.lastFetchedLink = url;

        this.fetchLinkInfo(url);
      }
    }
  }

  processLinkData(data) {
    if (data.info.images) {
      data.info.images = data.info.images.filter(function (img) {
        return img._links.img.href.indexOf('s.ytimg.com/yts/img/pixel') === -1;
      });

      //proxyfying urls
      data.info.images = data.info.images.map(function (img) {
        img.url = img._links.img.href;
        return img;
      });

      data.info.image = data.info.images[0];
      data.info.moreImages = data.info.images.length > 1;
    }

    if (data.info.event) {
      let event = data.info.event.event;
      data.info.title = event.group ? event.group.name + ' | ' + event.name : event.name;
      data.info.description = getEventSummaryText(event);
    }

    if (data.info.description) {
      data.info.description = data.info.description.substr(0, 1000);
    }

    if (!data.info.url) {
      data.info.url = data.info._links.link.href;
    }

    data.info.thumbnailCounter = 0;
    data.info.isYoutubeLink = isYoutubeLink(data.info.url);

    return EmberObject.create(data.info);
  }

  getLinkData() {
    let link = this.parent.link;

    if (link) {
      const linkObj = {
        url: link.url,
        title: link.title,
        description: link.description,
      };

      if (!link.noThumbnail && link.images.length) {
        const selectedImage = link.images[link.thumbnailCounter];
        linkObj.thumbnailUrl = selectedImage.url;

        this.parent.linkThumbUrlWidth = selectedImage.width;
        this.parent.linkThumbUrlHeight = selectedImage.height;
      }

      if (linkObj.url) {
        return linkObj;
      }
    }
  }

  removeLink() {
    // empty the link data but don't empty last 'lastFetchedLink' to avoid scrapping same link (SG-24393)
    this.parent.link = null;
    this.parent.linkPreviewRemoved = true;
    this.parent.updateHeight?.();
  }
}
