import Emoji from 'mewe/utils/emoji-utils';
import PS from 'mewe/utils/pubsub';

export let modelEmojisReady = {
  emojisReady: false,

  init() {
    this._super(...arguments);

    if (Emoji.isHashMapReady()) {
      this.set('emojisReady', true);
    } else {
      const callback = () => {
        if (this.isDestroying || this.isDestroyed) return;

        this.set('emojisReady', true);
        PS.Unsub('emoji.hashmap.ready', callback);
      };

      // on normal group page with chat it creates around 20-30 subs
      // no need to unsub on destroy, seems like in our system models are never destroyed
      // TODO deprecated
      PS.Sub('emoji.hashmap.ready', callback);
    }
  },
};
