import { helper as buildHelper } from '@ember/component/helper';
export default buildHelper(function (value) {
  if (value[0] === 'round') return Math.round(value[1]);

  let firstValue = parseInt(value[0]),
    secondValue = parseFloat(value[2]);

  return {
    '+': firstValue + secondValue,
    '-': firstValue - secondValue,
    '*': firstValue * secondValue,
    '/': firstValue / secondValue,
  }[value[1]];
});
