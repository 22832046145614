import { registerDeprecationHandler } from '@ember/debug';
import * as Sentry from '@sentry/ember';
import config from 'mewe/config';

// Disabling some deprecations
export function initialize() {
  registerDeprecationHandler((message, options, next) => {
    if (config.environment !== 'prod' && !config.isQA && options.id === 'autotracking.mutation-after-consumption') {
      // Capture the deprecation with Sentry
      Sentry.captureException(`[DEPRECATION-PART-2][autotracking.mutation-after-consumption]-${message}`, {
        level: 'warning',
        fingerprint: ['ember-deprecation-3.28.8'],
      });
    }

    if (!window.deprecationsCount) {
      window.deprecationsCount = {};
    }

    if (window.deprecationsCount[options.id]) {
      window.deprecationsCount[options.id] += 1;
    } else {
      window.deprecationsCount[options.id] = 1;
      console.warn(message);
    }

    // missing 'this' context for property in template
    // those should be more visible to be fixed immediately
    if (options.id === 'this-property-fallback') {
      console.error(message);
    }
  });
}

export default { initialize };
