import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import layout from './template.hbs';
import './styles.scss';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getOffset, getWindowScrollTop } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';
import PurchasesStore from 'mewe/stores/purchases-store';

export default class HeaderUserDropdown extends PopupOpener {
  layout = layout;

  popupType = 'dropdown-popup';
  closeOnClickOutside = true;
  dontCloseAt = '.dropdown--dont-close';

  @service router;
  @service purchase;
  @service settings;
  @service account;
  @service analytics;

  @tracked hasDarkTheme;
  @tracked isDarkTheme = this.settings.isDarkThemeEnabled;

  constructor() {
    super(...arguments);

    this.purchase.getPurchased().then(() => {
      this.hasDarkTheme = PurchasesStore.getState().hasDarkTheme;
    });
  }
  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();
    const parentOffset = getOffset(this.parent);

    Object.assign(position, {
      margin: 20,
      reposition: { elementWidth: 140 },
      translate: { x: -15 },
      parentOffset: { top: parentOffset.top - getWindowScrollTop(), left: parentOffset.left },
      placement: { top: false, left: isRtl, positionType: 'fixed' },
    });

    return position;
  }

  @action
  logout() {
    dispatcher.dispatch('app', 'logout');
  }

  @action
  openFeedback() {
    dispatcher.dispatch('feedback', 'openFeedbackDialog');
  }

  @action
  helpCenterClicked() {
    this.analytics.sendEvent('buttonClicked', 'Help Center');
  }

  @action
  toggleDarkTheme() {
    this.settings.saveTheme();
  }
}
