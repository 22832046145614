import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';

import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import ChatApi from 'mewe/api/chat-api';
import GroupStore from 'mewe/stores/group-store';
import ChatStore from 'mewe/stores/chat-store';
import { next } from '@ember/runloop';

export default class MwGroupSettingsChat extends Component {
  @reads('args.group') group;

  @service dynamicDialogs;
  @service account;

  @tracked loaded = false;
  @tracked password = '';
  @tracked passwordError = false;
  @tracked deleteFormVisible = false;
  @tracked chatMode = '';
  @tracked isSavingChatMode = false;
  @tracked isDeletingHistory = false;

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  willDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  groupDataReady() {
    if (!this.loaded && this.group?.id && !this.group.isFetching) {
      next(() => {
        this.loaded = true;
      });
      /**
       * chatMode stored in separate variable
       * so it can be later compared with saved value
       */
      this.chatMode = this.group.chatMode;
    }
  }

  @computed('password.length')
  get showDeleteButton() {
    return this.password.length > 5;
  }

  @computed('chatMode', 'isSavingChatMode')
  get isSaveVisible() {
    const isChatVisibilityChanged = this.chatMode !== this.group.chatMode;
    return isChatVisibilityChanged && !this.isSavingChatMode;
  }

  @action
  showDeleteForm() {
    // web3 users dont need to enter password
    if (this.account.activeUser.dsnpHandle) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm Deletion'),
        message: __('Are you sure you want to delete all group chat messages from all members?'),
        cancelButtonText: __('Cancel'),
        okButtonText: __(`Yes, I'm absolutely sure`),
        onConfirm: () => {
          this.submitDeleting();
        },
      });
    } else {
      this.deleteFormVisible = true;
    }
  }

  @action
  chatModeChange(chatMode) {
    this.chatMode = chatMode;
  }

  @action
  saveChanges() {
    const chatMode = this.chatMode;
    const groupId = this.group.id;

    this.isSavingChatMode = true;

    ChatApi.setGroupChatMode(groupId, { chatMode: chatMode })
      .then(() => {
        FunctionalUtils.info(__('Chat Settings have been successfully saved.'));

        let group = GroupStore.getState({ id: groupId });
        group.set('chatMode', chatMode);
        group.set('hiddenChat', chatMode === 'off');

        if (chatMode === 'off') {
          ChatStore.send('removeThreadById', groupId);
        }
      })
      .catch(() => {
        this.chatMode = this.group.chatMode;
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isSavingChatMode = false;
      });
  }

  @action
  submitDeleting() {
    let password = this.password;

    this.isDeletingHistory = true;
    this.passwordError = false;

    // delete group chat history form
    ChatApi.deleteGroupChatHistory(this.group.id, password)
      .then(() => {
        FunctionalUtils.info(__('Group chat history has been successfully deleted.'));
        PS.Pub('chat.remove.all.messages', {
          threadId: this.group.id,
        });

        this.password = '';
        this.deleteFormVisible = false;
      })
      .catch((data) => {
        if (data.status === 403) this.passwordError = true;
        else FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isDeletingHistory = false;
      });
  }
}
