const cookie = {
  set: function (name, value, exdate, path) {
    exdate && (exdate = new Date(exdate).toUTCString());
    var cValue = encodeURI(value) + (exdate === null || exdate === undefined ? '' : '; expires=' + exdate);
    var result = name + '=' + cValue;

    var host = location.host;
    if (path) {
      result += `; path=${path}`;
    } else if (host.split('.').length === 1) {
      // no "." in a domain - it's localhost or something similar
      result += `; path=/`;
    } else {
      result += `; path=/; domain=.${host}`;
    }

    this.remove(name); //remove old cookie
    document.cookie = result;
  },

  get: function (name) {
    var value = '; ' + decodeURI(document.cookie);
    var parts = value.split('; ' + name + '=');
    return parts.pop().split(';').shift();
  },

  remove: function (name) {
    const pathBits = location.pathname.split('/');
    let pathCurrent = ' path=';

    document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT;domain=${window.location.hostname};`;

    for (var i = 0; i < 2; i++) {
      if (pathBits[i]) {
        pathCurrent += '/';
        document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT;${pathCurrent};domain=${window.location.hostname};`;
      }
    }
  },

  clear: function () {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.remove(name);
    }
  },
};

cookie.setItem = cookie.set;
cookie.getItem = cookie.get;
cookie.removeItem = cookie.remove;

export default cookie;
