import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import toServer from 'mewe/stores/text-parsers/to-server';
import { purifyHtmlString } from 'mewe/utils/miscellaneous-utils';

export default class FieldEditable extends Component {
  original = '';

  pickerOpened = false;

  @tracked selfEditing;

  constructor() {
    super(...arguments);
    this.theme = this.args.theme || 'default';
    this.maxlength = this.args.maxlength || 1000;
    this.original = this.args.valueServer || '';
  }

  @computed('args.editing', 'selfEditing')
  get editing() {
    return this.args.editing || this.selfEditing;
  }

  @computed('_label', 'args.label')
  get labelEdit() {
    return __('Click to add {fieldName}', { fieldName: this._label });
  }

  set labelEdit(value) {
    this._label = value;
    return value;
  }

  @computed('args.{label,placeholder}')
  get placeholderText() {
    return this.args.placeholder || this.args.label;
  }

  @computed('args.{value,canEdit}')
  get canShow() {
    return this.args.canEdit || (!this.args.canEdit && this.args.value);
  }

  @computed('args.value')
  get valueSafe() {
    return htmlSafe(purifyHtmlString(this.args.value));
  }

  @action
  click() {
    if (this.lostFocus === true) {
      return;
    }

    if (this.args.canEdit) {
      // don't set end of editable area when it's already opened
      if (this.editing) {
        return;
      }

      this.selfEditing = true;
    }
  }

  @action
  saveAction() {
    if (this.pickerOpened) {
      return;
    }

    this.selfEditing = false;

    later(
      this,
      function () {
        if (!this.isDestroying && !this.isDestroyed) {
          this.lostFocus = false;
        }
      },
      250
    );

    if (this.original !== this.args.valueEdit) {
      const newValue = toServer(purifyHtmlString(this.args.valueEdit), { parseNativeMarkdown: true });
      const originalStripped = this.original.replace(/<\/?[^>]+(>|$)/g, ''); //removing html from message

      this.args.setValueEdit(newValue);

      let promise = this.args.save(this.args.name, originalStripped, newValue);

      if (promise) {
        promise
          .then(() => {
            this.original = this.args.valueEdit;
          })
          .catch(() => {
            this.original = this.args.valueServer;
          });
      }
    }
  }

  @action
  focusOut() {
    this.lostFocus = true;
    this.saveAction();
  }

  @action
  setValueEdit(valueEdit) {
    this.args.setValueEdit(valueEdit);
  }
}
