import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { sort } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import Scrolling from 'mewe/utils/scrolling-utils';
import { isEdge } from 'mewe/shared/utils';
import { fetchMoreFollowed, fetchMoreInvitations } from 'mewe/fetchers/fetch-pages';

export default class MwFollowedPagesList extends Component {
  pagesSorting = ['lastVisited:desc'];
  @sort('args.collection.items', 'pagesSorting') sortedPages;

  @action
  onInsert(element) {
    this.element = element;
    this.isEdge = isEdge();
    this.scrolling = Scrolling();
    this.bindScrolling();

    addObserver(this, 'args.collection.nextPage', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element);
    removeObserver(this, 'args.collection.nextPage', this.bindScrolling);
  }

  @computed('args.collection.{items.length,nextPage,isFetching}')
  get noPagesInfo() {
    return (
      !this.args.collection.isFetching &&
      this.args.collection.nextPage === null && // compare to null which is set when after fetching, initally it's undefined
      this.args.collection.items.length === 0
    );
  }

  bindScrolling() {
    if (this.args.collection?.nextPage) {
      this.scrolling.bindScrollDownElement(
        this.element,
        () => {
          if (
            this.args.collection.nextPage &&
            !this.args.collection.isFetchingMore &&
            !this.args.collection.isFetching
          ) {
            if (this.args.unconfirmed) {
              fetchMoreInvitations();
            } else {
              fetchMoreFollowed();
            }
          }
        },
        500
      );
    } else {
      this.scrolling.unbindScrollDown(this.element);
    }
  }
}
