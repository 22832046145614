import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class AppEventSettings extends Component {
  @alias('args.model.eventState.rootEvent') eventData;
  @alias('args.model.eventState.eventSettings') eventSettings;

  @service account;

  @tracked loaded;
  @tracked isHost;
  @tracked isGroupOwner;
  @tracked activeSettingsTab;

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      this.eventDataReady();
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      const isOwner = this.eventData?.participationType === 'Owner';
      const isGroupOwner = this.eventData?.group?.ownerId === this.account.activeUser.id;

      this.isHost = isOwner;
      this.isGroupOwner = isGroupOwner;
      this.activeSettingsTab = isOwner || isGroupOwner ? 0 : 1;
      next(() => {
        this.loaded = true;
      });
    }
  }

  @action
  switchSettingsTab(tabIndex) {
    this.activeSettingsTab = tabIndex;
  }
}
