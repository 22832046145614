import Component from '@glimmer/component';
import { action } from '@ember/object';

import dispatcher from 'mewe/dispatcher';

export default class SidebarRightStore extends Component {
  @action
  checkout() {
    dispatcher.dispatch('purchase', 'checkout', null, null);
  }

  @action
  openItemDetails(item) {
    dispatcher.dispatch('purchase', 'showItemDetails', item.id);
  }

  @action
  removeFromCart(item, e) {
    e.stopPropagation(); // prevent propagation to openItemDetails
    dispatcher.dispatch('purchase', 'toggleCartItem', item);
  }
}
