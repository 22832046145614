import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import CurrentUserStore from 'mewe/stores/current-user-store';
import { Locales } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class AccountLanguageSelector extends Component {
  @tracked locale;

  Locales = Locales;

  constructor() {
    super(...arguments);
    this.locale = CurrentUserStore.getState().locale;
  }

  @computed('locale')
  get localeName() {
    const localeString = this.locale ? this.locale.replace('_', '-') : this.locale;
    return Locales[localeString];
  }

  @action
  changeLocale(locale) {
    this.locale = locale;
    dispatcher.dispatch('client-data', 'setLocale', locale);
  }
}
