import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwPostHeader extends Component {
  @service dynamicDialogs;

  @action
  closeSPV() {
    this.dynamicDialogs.closeByName('single-post-dialog');
  }
}
