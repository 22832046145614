import Component from '@glimmer/component';
import { and, or } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

import fetchThreads from 'mewe/fetchers/fetch-threads';
import { ChatFilter } from 'mewe/constants';

export default class MwChatFilters extends Component {
  @service chat;

  ChatFilter = ChatFilter;

  @computed('args.userChatsCount', 'args.groupChatsCount', 'args.eventChatsCount', 'args.threadsSuggestionsCount')
  get showAll() {
    return this.args.userChatsCount + this.args.groupChatsCount + this.args.eventChatsCount > 1;
  }

  @and('args.userChatsCount', 'showAll') showContacts;

  @and('args.groupChatsCount', 'showAll') showGroups;

  @and('args.eventChatsCount', 'showAll') showEvents;

  @or('showContacts', 'showGroups', 'showEvents') hasAnyFilter;

  @action
  changeFilter(filter) {
    this.args.updateChatFilter(filter);
    this.chat.writeChatFilter(filter);
    fetchThreads({ chatType: filter });
  }
}
