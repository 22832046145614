import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import 'mewe/services/tasks';
import { default as fuHelper } from 'mewe/utils/fileupload-utils';
import {
  photoMaxWeightToResizeInBrowser,
  photoMaxWeightToUploadToServer,
  prettyWeight as weight,
} from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { next } from '@ember/runloop';
import ImageUpload from 'mewe/utils/image-upload';

export default class SimpleUpload extends Component {
  @service tasks;
  @service dynamicDialogs;

  element;

  @action
  onInsert(element) {
    this.element = element;
    if (this.args.instantUpload) {
      // some strange execution error appears if it's triggered in did-insert callback so it's postponed (SG-35443)
      next(() => this.filepicker());
    }
  }

  @action
  filepicker() {
    const targetInput = this.element.querySelector('input');

    targetInput.value = null;
    targetInput.click();
  }

  @action
  handleFiles(event) {
    const reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      reader.readAsDataURL(file);
    }

    if (!fuHelper.isImageFileTypeSupported(file.type)) {
      fuHelper.wrongFormatMsg();
      return;
    }

    if (file.size >= photoMaxWeightToResizeInBrowser) {
      FunctionalUtils.error(
        __('Photo should not exceed {limit}', {
          limit: weight(photoMaxWeightToResizeInBrowser),
        })
      );
      return;
    }

    if (this.args.beforeUpload) {
      this.args.beforeUpload(file);
    } else {
      this.upload(file);
    }
  }

  async upload(file) {
    if (!file) {
      return;
    }

    if (file.size >= photoMaxWeightToUploadToServer) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Photo upload error'),
        message: __('Photo should not exceed {limit}', { limit: weight(photoMaxWeightToUploadToServer) }),
        okButtonText: __('OK'),
      });
      return;
    }
    let promise = await ImageUpload.uploadImage(file);
    this.args.done(promise.data);
    if (this.args.task) {
      this.tasks.pushToTask(this.args.task, promise);
    }
  }
}
