import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { reads } from '@ember/object/computed';
import { sortBy, each, reduce, isNumber, compact } from 'lodash';

import { getElWidth, getElHeight } from 'mewe/utils/elements-utils';
import PageAnalyticsBase from '../app-page-statistics';

export default class AppPageStatisticsFollowers extends PageAnalyticsBase {
  routeName = 'route:app.publicid.statistics.followers';

  graphHeight = 100;
  graphWidth = 600;
  graphOriginX = 30;

  @service account;

  @reads('args.model') model;
  @reads('args.model.page') page;
  @reads('args.model.followersCountries.items') countries;

  @tracked selectedSection;
  @tracked infoBoxLeft;
  @tracked infoBoxTop;

  @action
  onInsert(element) {
    this.element = element;
  }

  @computed('infoBoxLeft', 'infoBoxTop', 'selectedSection')
  get infoBoxStyle() {
    if (!this.selectedSection) return htmlSafe('visibility: hidden; display: none;');

    return htmlSafe(`top: ${this.infoBoxTop}px; left: ${this.infoBoxLeft}px; visibility: visible;`);
  }

  @computed('model.followersIntervals.items.length', 'page.followers')
  get newFollowersAmount() {
    if (!this.model.followersIntervals?.items?.length || !isNumber(this.model.page.followers)) return 0;

    return (
      this.model.page.followers -
      this.model.followersIntervals.items[0].count +
      this.model.followersIntervals.items[0].newFollowers
    );
  }

  @computed('selectedDateRange')
  get intervalRange() {
    let type = 'days',
      range = this.selectedDateRange.range;

    switch (range) {
      case 365:
        range = 12;
        type = 'months';
        break;
      case 90:
        range = 3;
        type = 'months';
        break;
    }

    return { type, range };
  }

  // server data shown with GMT 00:00 converted to local TZ, if no server data for some day, then local 00:00 shown with data filled from next day/server data
  @computed('model.followersIntervals.items.length', 'graphDates.length')
  get intervals() {
    if (
      !this.selectedDateRange ||
      !this.refAsMillis ||
      !this.model.followersIntervals?.items?.length ||
      !this.graphDates.length
    )
      return [];

    const intervals = this.model.followersIntervals.items,
      intervalStartTimes = intervals.map((c) => {
        const d = new Date(c.intervalStart);
        return Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0);
      });

    let slots = this.graphDates.map((graphDate) => {
      if (!intervalStartTimes.includes(graphDate.dateMillis)) {
        return {
          intervalStart: graphDate.dateMillis,
        };
      }
    });

    slots = sortBy(compact(slots).concat(intervals), 'intervalStart');

    const today = slots[slots.length - 1];

    if (!today.count) {
      today.count = this.model.page.followers;
      today.newFollowers = 0;
    }

    const reversed = slots.reverse();

    //go from newest to oldest, filling count from newer.count
    each(reversed, (e, i) => {
      if (!e.count && i > 0) {
        const prev = reversed[i - 1];

        if (prev) {
          e.count = prev.count - (prev.newFollowers || 0);
          e.newFollowers = 0;
        }
      }
    });

    return slots;
  }

  @computed('intervals.length')
  get graphIntervals() {
    if (!this.graphDates.length || !this.intervals.length) return [];

    const maxInterval = this.getMaxIntervalCount() || 1,
      oldestDateMillis = this.graphDates[0].dateMillis,
      newestDate = this.graphDates[this.graphDates.length - 1],
      newestDateMillis = newestDate.dateMillis,
      newestDateX = newestDate.textX;

    let intervals = this.intervals.map((interval) => {
      const pct = Math.floor((interval.count * 100) / maxInterval),
        g = {
          dateText: new Date(interval.intervalStart).toLocaleDateString(this.account.activeUser.jsLocale, {
            timeZone: 'UTC', // TODO: why display hours and minutes?
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
          count: interval.count,
          newFollowers: interval.newFollowers,
          pct: pct,
          x: Math.floor(
            (newestDateX * (interval.intervalStart - oldestDateMillis)) / (newestDateMillis - oldestDateMillis)
          ),
          y: this.graphHeight - Math.floor(this.graphHeight * (interval.count / maxInterval)),
        };

      return g;
    });

    for (let i = intervals.length - 1; i > 0; i--) {
      intervals[i].prev = intervals[i - 1];
    }

    return intervals;
  }

  getMaxIntervalCount() {
    if (!this.intervals.length) return 0;

    return sortBy(this.intervals.map((i) => i.count))[this.intervals.length - 1];
  }

  @computed('intervalRange.{range,type}', 'account.activeUser.timezone')
  get graphDates() {
    let now = new Date(),
      dateMillis = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0),
      date;

    const dates = [],
      intervalRange = this.intervalRange,
      textWidth = (this.graphWidth - this.graphOriginX * 2) / intervalRange.range,
      customClass = textWidth > 80 ? '' : 'smaller';

    for (let i = intervalRange.range; i > -1; --i) {
      date = new Date(dateMillis);

      dates.push({
        dateMillis: dateMillis,
        dateText: date.toLocaleDateString(this.account.activeUser.jsLocale, {
          month: 'short',
          day: 'numeric',
          timeZone: this.account.activeUser?.timezone,
        }),
        textX: Math.floor(i * textWidth),
        textY: i % 2 ? 0 : 12,
        customClass: customClass,
      });
      if (intervalRange.type === 'days') {
        dateMillis = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 1, 0, 0, 0);
      } else if (intervalRange.type === 'months') {
        dateMillis = Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - 1, date.getUTCDate(), 0, 0, 0);
      }
    }

    return dates.reverse();
  }

  @computed('intervals.length')
  get graphYAxis() {
    const maxInterval = this.getMaxIntervalCount(),
      roundFunc = maxInterval < 100 ? (count) => Math.round(count * 10) / 10 : Math.round;

    return [0, 1, 2].map((axis) => {
      return {
        count: axis == 0 ? 0 : roundFunc(maxInterval / axis) || '',
        y: axis == 0 ? this.graphHeight : this.graphHeight - Math.round(this.graphHeight / axis),
      };
    });
  }

  @computed('graphIntervals.length')
  get allLinesConnected() {
    if (this.graphIntervals.length < 3) return '';

    const dates = this.graphIntervals,
      first = dates[0],
      last = dates[dates.length - 1];

    const middleLines = reduce(
      this.graphIntervals,
      (text, g) => {
        return text + `L ${g.x},${g.y}`;
      },
      ''
    );

    return 'M ' + first.x + ',' + this.graphHeight + middleLines + ' L ' + last.x + ',' + this.graphHeight + ' Z';
  }

  @action
  selectSection(g) {
    this.selectedSection = g;

    // postpone after box will show up on screen so its size can be measured
    next(this, () => {
      const i = this.graphIntervals.findIndex((i) => i === g);

      const posDot = this.element.querySelectorAll('.graph-amount-dot')[i].getBoundingClientRect();
      const posBox = this.element.querySelector('.page-analytics-followers-graph-container').getBoundingClientRect();

      if (!posDot || !posBox) return;

      const box = this.element.querySelector('.graph-info-box');

      this.infoBoxTop = Math.round(getElHeight(box) || 0);
      this.infoBoxLeft = posDot.left - posBox.left - (getElWidth(box) || 0);
    });
  }

  @action
  unselectSection() {
    if (this.selectedSection) {
      this.selectedSection = null;
    }
  }
}
