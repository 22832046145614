import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ChatInfoHeader extends Component {
  @tracked searchViewActive;

  @computed('args.skin', 'args.thread.{isGroupChat,isEventChat}')
  get showMembersSearch() {
    return this.args.skin === 'small-chat' && (this.args.thread.isGroupChat || this.args.thread.isEventChat);
  }

  @action
  toggleChatInfo() {
    this.args.toggleChatInfo();
  }

  @action
  goBack() {
    this.closeSearch();
    this.args.toggleChatInfo(true);
  }

  @action
  closeCompletely() {
    this.closeSearch();
    this.args.toggleChatInfo();
  }

  @action
  focusInSearch() {
    this.searchViewActive = true;
  }

  @action
  closeSearch() {
    this.searchViewActive = false;
    this.args.resetMemberSearch?.();
  }
}
