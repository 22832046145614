import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';

import Event from 'mewe/stores/models/event-model';
import fetchEvent from 'mewe/fetchers/fetch-event';
import { getPopupPosition } from 'mewe/utils/popup-utils';

import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
const serializer = JSONSerializer.create();

export default class MwCalendarEvent extends Component {
  element;
  timeoutId = null;

  @service router;

  @reads('args.item') item;

  @tracked loading;
  @tracked position;
  @tracked eventData;
  @tracked showEventDetails;

  @action
  onInsert(element) {
    this.element = element;
  }

  // there was a problem when action was attached to element (SG-29971)
  @action
  onClick(e) {
    if (this.item.isPlaceholder) return;

    if (!e.target.closest('.day-event-details')) {
      this.router.transitionTo('app.event', this.item.eventData?.id);
    }
  }

  fetchEventData() {
    this.loading = true;

    const callback = (rawEvent) => {
      if (this.isDestroying || this.isDestroyed) return;
      this.eventData = serializer.deserializeOne(Event, rawEvent);
    };

    const finallyCallback = () => {
      if (this.isDestroying || this.isDestroyed) return;
      this.loading = false;
    };

    fetchEvent(this.item.eventData?.id, callback, null, finallyCallback);
  }

  setPopupPosition() {
    this.position = getPopupPosition(this.element, { popupWidth: 310, popupMargin: 5 });
  }

  @action
  mouseEnterHandler() {
    // don't show details on "+{count} more" list in calendar, it's complicated not worth doing for now (SG-30398)
    if (!this.args.canShowDetails) return;
    if (this.item.isPlaceholder) return;

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      if (this.isDestroyed || this.isDestroying) return;
      if (!this.eventData) this.fetchEventData();
      this.setPopupPosition();
      this.showEventDetails = true;
    }, 200);
  }

  @action
  mouseLeaveHandler() {
    clearTimeout(this.timeoutId);
    this.showEventDetails = false;
  }
}
