import BrowseCommunitiesBase from '../browse-communities-base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { ds } from 'mewe/stores/ds';
import { getOrCreateCollectionFromHash as collection } from 'mewe/stores/models/collection';
import Scrolling from 'mewe/utils/scrolling-utils';

import {
  fetchMorePagesForCategory,
  fetchMorePagesFeatured,
  fetchFeaturedAll,
  fetchCategoriesForBucket,
  fetchTrendingForBucket,
  fetchPagesForCategory,
  fetchBuckets,
  searchPages,
  fetchMoreSearchResults,
  clearSearchResults,
} from 'mewe/fetchers/fetch-pages';

const getCategoriesForBucket = (bucket) => collection(ds.categoriesForBucket, bucket);

const getPages = (bucket, category) => {
  if (category) {
    return collection(ds.pagesForCategory, category);
  } else if (bucket) {
    return collection(ds.trendingForBucket, bucket);
  }

  return ds.featuredAll;
};

const State = {
  BROWSE: 'Browse',
  SEARCH: 'Search',
};

class Model {
  @tracked pages = {};
  @tracked buckets = {};
  @tracked categories = {};
  @tracked followed = {};
  @tracked searchedPages = {};
}

class Params {
  @tracked bucket = null;
  @tracked category = null;
}

export default class MwBrowsePages extends BrowseCommunitiesBase {
  @service settings;

  store = ds;
  states = State;
  defaultState = State.BROWSE;

  scrolling = Scrolling();

  model = new Model();
  params = new Params();

  @tracked searchValue;
  @tracked lastSearchValue;

  @action
  onDestroy() {
    this.clearSearch();

    if (this.args.isDialog) {
      this.scrolling.unbindScrollDown(this.element.querySelector('.browse-communities_rows, .browse-communities_grid'));
    } else {
      this.scrolling.unbindScrollDown();
    }

    this.scrollingObserved.forEach((field) => removeObserver(this, field, this.toggleInfiniteScrollWrapped));
    this.modelObserved.forEach((field) => removeObserver(this, field, this.updateModelWrapped));

    removeObserver(this, 'params.bucket', this.paramsChangedBind);
    removeObserver(this, 'params.category', this.paramsChangedBind);
  }

  get positionClassName() {
    const position = this.params.bucket ? 'next' : 'start';
    return `show-${position}`;
  }

  get bucketsSorted() {
    return this.model.buckets.items?.sortBy('order');
  }

  get title() {
    if (this.selectedCategory) {
      return __(this.selectedCategory.name);
    } else if (this.selectedBucket) {
      return __(this.selectedBucket.name);
    } else if (this.searchValue) {
      return __('Search results for: {query}', {
        query: this.searchValue,
      });
    }

    return __('Featured Pages');
  }

  get sidebarTitle() {
    return this.selectedBucket.name ? __(this.selectedBucket.name) : __('Featured Pages');
  }

  get pages() {
    return this.model.pages.items || [];
  }

  get searchedPages() {
    return this.model.searchedPages.items || [];
  }

  get selectedBucket() {
    return this.model.buckets ? this.model.buckets.items?.find((i) => i.id === this.params.bucket) : null;
  }

  get selectedCategory() {
    return this.model.categories ? this.model.categories.items?.find((i) => i.id === this.params.category) : null;
  }

  get followed() {
    return this.model.followed.items?.map((item) => {
      item.linkTo = 'app.publicid';
      item.linkToId = item.publicLinkId;
      return item;
    });
  }

  get showPagesPlaceholder() {
    return !this.pages.length && !this.model.pages.isFetching;
  }

  get showSearchPlaceholder() {
    return !this.searchedPages?.length && !this.model.searchedPages?.isFetching && this.lastSearchValue;
  }

  setupModel() {
    fetchBuckets();
    fetchFeaturedAll();

    this.modelObserved = [
      'store.featuredAll.items.length',
      'store.pagesForCategory.items.length',
      'store.buckets.items.length',
      'store.trendingForBucket.items.length',
      'store.categoriesForBucket.items.length',
      'params.bucket',
      'params.category',
    ];

    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.updateModelWrapped = () => scheduleOnce('afterRender', this, this.updateModel);
    this.modelObserved.forEach((field) => addObserver(this, field, this.updateModelWrapped));
    this.updateModel();

    this.paramsChangedBind = this.paramsChanged.bind(this);

    addObserver(this, 'params.bucket', this.paramsChangedBind);
    addObserver(this, 'params.category', this.paramsChangedBind);
    this.paramsChanged();
  }

  updateModel() {
    const { bucket, category } = this.params;

    this.model.buckets = ds.buckets;
    this.model.categories = bucket ? getCategoriesForBucket(bucket) : null;
    this.model.pages = getPages(bucket, category);
    this.model.searchedPages = ds.searchedPages;
  }

  paramsChanged() {
    const { bucket, category } = this.params;

    if (bucket) {
      fetchCategoriesForBucket(bucket);
      fetchTrendingForBucket(bucket);
    }

    if (category) {
      fetchPagesForCategory(category);
    }
  }

  setupInfiniteScroll() {
    this.scrollingObserved = [
      'params.bucket',
      'params.category',
      'model.pages.items.length',
      'model.pages.nextPage',
      'store.searchedPages.items.length',
      'model.searchedPages.canShowMore',
    ];
    // scheduleOnce will trigger callback only once even if multiple observser fields are changed at once
    this.toggleInfiniteScrollWrapped = () => scheduleOnce('afterRender', this, this.toggleInfiniteScroll);
    this.scrollingObserved.forEach((property) => addObserver(this, property, this.toggleInfiniteScrollWrapped));
    this.toggleInfiniteScroll();
  }

  doSearch(params) {
    searchPages(params);
    this.lastSearchValue = this.searchValue;
  }

  toggleInfiniteScroll() {
    if (this.args.isDialog) {
      this.scrolling.unbindScrollDown(this.element.querySelector('.browse-communities_rows, .browse-communities_grid'));
    } else {
      this.scrolling.unbindScrollDown();
    }

    if (this.state === State.BROWSE && this.model.pages?.nextPage) {
      if (this.params.bucket && this.params.category) {
        if (this.args.isDialog) {
          this.scrolling.bindScrollDownElement(
            this.element.querySelector('.browse-communities_grid'),
            () => {
              fetchMorePagesForCategory(this.params.category);
            },
            200
          );
        } else {
          this.scrolling.bindScrollDown(() => {
            fetchMorePagesForCategory(this.params.category);
          }, 500);
        }
      }

      if (!this.params.bucket && !this.params.category) {
        if (this.args.isDialog) {
          this.scrolling.bindScrollDownElement(
            this.element.querySelector('.browse-communities_grid'),
            () => {
              fetchMorePagesFeatured();
            },
            200
          );
        } else {
          this.scrolling.bindScrollDown(() => {
            fetchMorePagesFeatured();
          }, 500);
        }
      }
    }

    if (this.state === State.SEARCH && this.model.searchedPages?.canShowMore) {
      if (this.state === State.SEARCH) {
        if (this.args.isDialog) {
          this.scrolling.bindScrollDownElement(
            this.element.querySelector('.browse-communities_rows'),
            () => {
              fetchMoreSearchResults({
                query: this.searchValue,
                pageSize: this.maxSearchResults,
                offset: this.model.searchedPages?.offset,
              });
            },
            200
          );
        } else {
          this.scrolling.bindScrollDown(() => {
            fetchMoreSearchResults({
              query: this.searchValue,
              pageSize: this.maxSearchResults,
              offset: this.model.searchedPages?.offset,
            });
          }, 500);
        }
      }
    }
  }

  @action
  clearSearch() {
    this.searchValue = '';
    this.lastSearchValue = '';
    this.searchInputExpanded = false;
    this._state = this.defaultState;

    clearSearchResults();
  }

  @action
  backToStart() {
    this.params.bucket = null;
    this.params.category = null;
  }

  @action
  fetchBucket(bucket) {
    this.params.category = null;
    this.params.bucket = bucket.id;
    this.clearSearch();
    this.scrollToTop();
  }

  @action
  fetchCategory(category) {
    this.params.category = category.id;
    this.mobileSidebarVisible = false;
    this.clearSearch();
    this.scrollToTop();
  }
}
