import { A } from '@ember/array';
import { allPosts } from 'mewe/stores/models/feed-model';

export default {
  getCommentOrReplyFromPost(model, post) {
    let commentOrReply;

    if (!post.get('comments.feed.length')) return;

    if (model.get('replyTo')) {
      commentOrReply = post.get('comments.feed').find((f) => f.id === model.get('replyTo')); // find parent comment of particular reply

      if (commentOrReply && commentOrReply.get('replies.length')) {
        commentOrReply = commentOrReply.get('replies').find((r) => r.id === model.get('id')); // find particular reply
      }
    } else {
      commentOrReply = post.get('comments.feed').find((f) => f.id === model.get('id'));
    }

    return commentOrReply;
  },

  matchPostsFromAllFeeds(postItemId) {
    let dsposts = allPosts().filter((p) => p.postItemId == postItemId) || A();

    return dsposts;
  },
};
