import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwRadio extends Component {
  get htmlChecked() {
    return this.args.value === this.args.checked;
  }

  @action
  change() {
    this.args.onChange?.(this.args.value);
  }
}
