import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsPermissions extends Component {
  get page() {
    return this.args.model.appPage.page;
  }

  @action
  toggleFollowStories() {
    const newVal = !this.page.followsPageStories;
    dispatcher.dispatch('pages', 'toggleFollowStories', this.page, { followStories: newVal });
  }
}
