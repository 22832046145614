import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MwAboutHeader extends Component {
  @service storyblok;

  get headerText() {
    return this.storyblok.richTextResolver.render(this.args.blok.headerText);
  }
}
