/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable no-undef */
import { set } from '@ember/object';
import { computed, action } from '@ember/object';
import Component from '@glimmer/component';
import { next } from '@ember/runloop';
import ContactsApi from 'mewe/api/contacts-api';
import { tracked } from '@glimmer/tracking';

export default class MwInviteImport extends Component {
  @tracked isImportingContacts;

  @tracked showImportResults;

  @tracked provider = null;

  constructor() {
    super(...arguments);

    // settings not useful in group event invitation usecase
    if (!this.args.eventData?.groupId) {
      ContactsApi.getMyContactsSettings().then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
      });
    }
  }

  get cloudspongeResultsView() {
    return this._cloudspongeResultsView ?? this.args.cloudspongeResultsView;
  }

  @computed('args.cloudspongeResultsView', 'provider')
  get headerText() {
    if (this.cloudspongeResultsView) {
      const provider = this.provider;
      return __('Invite friends using your {providerName} address book', {
        providerName: provider
          ? provider === 'csv'
            ? 'CSV'
            : provider.charAt(0).toUpperCase() + provider.slice(1)
          : '',
      });
    } else {
      return __('Upload your address book to invite friends');
    }
  }

  @computed('isImportingContacts', 'showImportResults')
  get showProvidersList() {
    return !this.isImportingContacts && !this.showImportResults;
  }

  @action
  selectProvider(provider) {
    set(this, 'provider', null);
    // it will cause rerender if same provider was selected
    next(this, () => set(this, 'provider', provider));
  }

  @action
  unselectProvider() {
    set(this, 'provider', null);

    // needed to update values after updating template with 'provider' state
    next(() => {
      set(this, 'showImportResults', false);
      set(this, 'isImportingContacts', false);
      set(this, '_cloudspongeResultsView', false);
    });
  }

  @action
  sendInvitations(users, wholeAddressBook) {
    this.args.sendInvitations?.(users, wholeAddressBook);
  }

  @action
  closeAction() {
    this.args.closeAction?.();
  }
}
