import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class MwTextFormatting extends Component {
  @reads('args.tooltip') tooltip;
  @tracked formattingDropdownOpened;

  constructor() {
    super(...arguments);
    this.formattingDropdownOpened = this.args.formattingDropdownOpened;
  }

  @computed('tooltip', 'formattingDropdownOpened')
  get canShowTooltip() {
    return this.tooltip && !this.formattingDropdownOpened;
  }

  @action
  toggleFormattingDropdown() {
    this.formattingDropdownOpened = !this.formattingDropdownOpened;
  }

  @action
  selectFormatting(type) {
    // https://sentry.qa-groupl.es/mewe/javascript/issues/2075/
    // https://sentry.qa-groupl.es/mewe/javascript/issues/2074/
    this.args.onFormatSelect?.(type);
  }
}
