import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import GroupStore from 'mewe/stores/group-store';
import { openGroupFtueDialog } from 'mewe/utils/dialogs-common';

export default class AppGroup extends Component {
  @service router;
  @service settings;
  @service dynamicDialogs;

  @tracked searchTerm = '';

  constructor() {
    super(...arguments);

    addObserver(this, 'args.model.groupId', this.groupChanged);

    this.groupChanged();
  }

  willDestroy() {
    removeObserver(this, 'args.model.groupId', this.groupChanged);
  }

  get model() {
    return this.args.model;
  }

  groupChanged() {
    next(this, () => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.searchTerm = '';
      }

      if (this.model.isFirstGroupOpening) {
        this.openGroupInitialisationDialog(this.model.groupId);
      }
    });
  }

  get isUnconfirmed() {
    // we don't want to see this blinking when most of groups are confirmed
    if (typeof this.model.group?.isConfirmed === 'undefined') {
      return false;
    } else {
      return !this.model.group?.isConfirmed;
    }
  }

  openGroupInitialisationDialog(groupId) {
    openGroupFtueDialog(GroupStore.getState({ id: groupId }), this.dynamicDialogs);
  }

  @action
  searchTermChanged(term) {
    this.searchTerm = term;
  }
}
