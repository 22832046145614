import twitterText from 'twitter-text';
import { maxHashtags } from 'mewe/constants';
import { each } from 'lodash';

export default {
  prepareDecorators(text) {
    const find = ['*<', '>*', '**<', '>**', '***<', '>***'];
    const replace = ['<em><', '></em>', '<strong><', '></strong>', '<strong><em><', '></em></strong>'];
    let replacedString = text;

    for (let i = 0; i < find.length; i++) {
      if (~text.indexOf(find[i])) {
        replacedString = replacedString.replace(find[i], replace[i]);
      }
    }

    return replacedString;
  },

  toDisplay(text, options = {}) {
    if (options.isPrivatePost) return text; // no ht highlighting in PP

    let richText = '';

    if (text) {
      // https://sgrouples.atlassian.net/browse/SG-28422
      let fixedText = text.replace(/http:\/\/#/g, 'http:// ');

      // hashtags sorted by order of appearance
      let entities = twitterText
          .extractHashtagsWithIndices(fixedText)
          .sort((a, b) => a.indices[0] - b.indices[0])
          .splice(0, maxHashtags),
        beginIndex = 0;

      each(entities, (e) => {
        richText += text.substring(beginIndex, e.indices[0]);
        richText += `<a class="h-hashtag post_text_hashtag">#${e.hashtag}</a>`;
        beginIndex = e.indices[1];
      });

      richText += text.substring(beginIndex, text.length);
    }

    return this.prepareDecorators(richText);
  },

  toEdit(text) {
    return text;
  },

  // textEdit => textServer
  // TODO
  toServer(text) {
    return text;
  },
};
