import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class MwPhoneInput extends Component {
  @action
  onInsert(element) {
    const input = element.querySelector('input');
    if (input) {
      input.classList.add('h-input_text');

      // (SG-39785) - country code is not detected properly if user pastes/autocompletes the phone number
      // but triggering additional update of the number fixes the issue
      const triggerUpdate = () => {
        const inputEvent = new Event('input', {
          bubbles: true,
          cancelable: true,
        });

        later(this, () => input.dispatchEvent(inputEvent), 10);
      };

      input.addEventListener('paste', triggerUpdate);
      input.addEventListener('change', triggerUpdate);
    }
  }
}
