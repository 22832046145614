import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
export default buildHelper(function (value) {
  var hash = {
    recent: __('Recently Used'),
    purchased: __('Purchased'),
    people: __('Smileys & People'),
    nature: __('Animals & Nature'),
    food: __('Food & Drink'),
    activity: __('Activity'),
    travel: __('Travel & Places'),
    objects: __('Objects'),
    symbols: __('Symbols'),
    custom: __('Custom'),
    flags: __('Flags'),
  };

  return htmlSafe(hash[value[0]]);
});
