import { modifier } from 'ember-modifier';
import PurchasesStore from 'mewe/stores/purchases-store';
import { getOwner } from '@ember/application';

let _timeoutValue = 500;
let _timeoutId = null;

// OPTIONS
let allowCustomEmojiNames = false;

export default modifier((element, [ctx, selector], options) => {
  const elements = selector ? element.querySelectorAll(selector) : [element];

  if (!elements || !elements.length) {
    return;
  }

  if (options.allowCustomEmojiNames) {
    allowCustomEmojiNames = true;
  }

  const mouseEnterFunc = (e) => {
    const canShowPopup = !e.target?.closest('.dropdown-menu_opener_wrapper');

    if (canShowPopup) {
      if (_timeoutId) {
        return;
      }

      const tId = setTimeout(() => {
        showEmojiNamePopup(e, ctx);
        _timeoutId = null;
      }, _timeoutValue);

      _timeoutId = tId;
    } else {
      clearTimeout(_timeoutId);
      _timeoutId = null;
    }
  };

  const mouseLeaveFunc = () => {
    if (_timeoutId) {
      clearTimeout(_timeoutId);
      _timeoutId = null;
    }
  };

  const bindEvents = () => {
    elements.forEach((el) => {
      el.addEventListener('mouseenter', mouseEnterFunc);
      el.addEventListener('mouseleave', mouseLeaveFunc);
    });
  };

  const unbindEvents = () => {
    elements.forEach((el) => {
      el.removeEventListener('mouseenter', mouseEnterFunc);
      el.removeEventListener('mouseleave', mouseLeaveFunc);
    });

    if (_timeoutId) {
      clearTimeout(_timeoutId);
      _timeoutId = null;
    }
  };

  bindEvents();

  return unbindEvents;
});

const getNameToDisplay = (name) => {
  // we allow custom emoji names in emoji picker - if user see them then he bought them
  if (allowCustomEmojiNames) {
    return name;
  }

  const customEmojis = PurchasesStore.getState()
    .storeItems.map((i) => {
      if (i.class === 'store-emoji') {
        return i.emojisList;
      }
    })
    .join()
    .split(',');

  // for custom emojis we don't show full emoji name but only pack prefix
  if (customEmojis.indexOf(name) !== -1) {
    name = name.split('_')[0].replace(':', '');
  }

  return name;
};

const showEmojiNamePopup = async (e, ctx) => {
  const emojiEl = e.target,
    emojiName = getNameToDisplay(':' + emojiEl.getAttribute('data-name') + ':'),
    dropdownId = `${emojiEl.getAttribute('data-name')}`;

  const params = {
    parent: emojiEl,
    dropdownId: dropdownId,
    emojiName: emojiName,
  };

  const owner = getOwner(ctx);
  const dynamicPopups = owner.lookup('service:dynamic-popups');
  dynamicPopups.openPopup('mw-emoji-name-popup', params);
};
