import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import { each, escape } from 'lodash';

import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupApi from 'mewe/api/group-api';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import toServer from 'mewe/stores/text-parsers/to-server';
import { next } from '@ember/runloop';

export default class MwGroupSettingsQuestions extends Component {
  @reads('args.group') group;

  @tracked loaded = false;
  @tracked groupQuestions = A();
  @tracked questionsChanged = false;
  @tracked isSavingQuestions = false;
  @tracked isSavingRequire;

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  groupDataReady() {
    if (!this.loaded && this.group?.id && !this.group.isFetching) {
      next(() => {
        this.loaded = true;
      });

      if (this.group.isOwnerAdmin && this.group.groupQuestions) {
        next(() => {
          each(this.group.groupQuestions, (q) => {
            this.groupQuestions.pushObject({
              text: EmojisParser.toEdit(escape(q.question)),
            });
          });
        });
      }
    }
  }

  @action
  requireQuestionsChanged(value) {
    const params = {
      mandatoryQuestions: value,
    };

    if (this.isSavingRequire) return;
    // prevent quick multiple click
    else this.isSavingRequire = true;

    GroupApi.setGroupData(this.group.id, params)
      .then(() => {
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));
        this.group.set('mandatoryQuestions', params.mandatoryQuestions);
      })
      .finally(() => (this.isSavingRequire = false))
      .catch(() => this.group.set('mandatoryQuestions', !params.mandatoryQuestions));
  }

  @action
  hideAnswersChanged(value) {
    const params = {
      hideAnswers: value,
    };

    if (this.isSavingRequire) return;
    // prevent quick multiple click
    else this.isSavingRequire = true;

    GroupApi.setGroupData(this.group.id, params)
      .then(() => {
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));
        this.group.set('hideAnswers', params.hideAnswers);
      })
      .finally(() => (this.isSavingRequire = false))
      .catch(() => this.group.set('hideAnswers', !params.hideAnswers));
  }

  @action
  saveQuestions() {
    const params = {
      applyQuestions: this.groupQuestions
        .map((q) => {
          return toServer(q.text.trim(), {
            parseNativeMarkdown: true,
          });
        })
        .filter((q) => {
          return q !== '';
        }),
    };

    this.isSavingQuestions = true;

    GroupApi.setGroupData(this.group.id, params)
      .then((data) => {
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));

        if (this.isDestroying || this.isDestroyed) return;

        this.group.set('applyQuestions', A(data.applyQuestions));
        this.groupQuestions = A(
          data.applyQuestions.map((q) => {
            return { text: EmojisParser.toEdit(escape(q)) };
          })
        );
        this.questionsChanged = false;
      })
      .finally(() => (this.isSavingQuestions = false))
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }

  @action
  questionsChange() {
    this.questionsChanged = true;
  }

  @action
  onDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
  }
}
