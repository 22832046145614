import Service from '@ember/service';
import { A } from '@ember/array';
import Task from 'mewe/services/Task';

let tasks = A();

export default Service.extend({
  getTask(id) {
    let task = tasks.find((el) => el.id == id);

    if (!task) {
      task = Task.create({ id: id });
      tasks.pushObject(task);
    }

    return task;
  },

  getTasks() {
    return tasks;
  },

  pushToTask(id, promise) {
    const task = this.getTask(id);

    task.push(promise, this);

    if (promise.setProgressHandler && promise.progressId)
      promise.setProgressHandler((e) => task.updateProgress(promise.progressId, e));

    return tasks;
  },
});
