/**
 * This module is reacting to user input and replace his input with recognized blots
 */

import Quill from 'quill/core';
import { getHashMap, getEmojiUrlCdn, asciiRegexp, regAscii, replaceAsciiEmoji } from 'mewe/utils/emoji-utils';

const Module = Quill.import('core/module');
const Delta = Quill.import('delta');

class Autoformat extends Module {
  constructor(quill, options) {
    super(quill, options);

    this.transforms = {
      emoji: options['emoji'],
    };

    if (options.enableHashtags) {
      this.transforms.hashtag = options['hashtag'];
      this.transforms.italicHashtag = options['italicHashtag'];
    }

    this.registerTypeListener();
    this.registerPasteListener();
  }

  registerPasteListener() {
    for (const name in this.transforms) {
      const transform = this.transforms[name];
      this.quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
        if (typeof node.data !== 'string') {
          return;
        }

        delta.ops.forEach((op, index, deltaOps) => {
          if (typeof op.insert === 'string') {
            let changeDelta = makeTransformedDelta(transform, op.insert);
            let composedDelta = new Delta([op]).compose(changeDelta);

            deltaOps.splice(index, 1, ...composedDelta.ops);
          }
        });

        return delta;
      });
    }
  }

  registerTypeListener() {
    this.quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
      let ops = delta.ops;

      if (source !== 'user' || !ops || ops.length < 1) {
        return;
      }

      let lastOpIndex = ops.length - 1;
      let lastOp = ops[lastOpIndex];

      while (!lastOp.insert && lastOpIndex > 0) {
        lastOpIndex--;
        lastOp = ops[lastOpIndex];
      }

      if (!lastOp.insert || typeof lastOp.insert !== 'string') {
        return;
      }

      let isEnter = lastOp.insert === '\n';

      let sel = this.quill.getSelection();

      if (!sel) {
        return;
      }

      let endSelIndex = this.quill.getLength() - sel.index - (isEnter ? 1 : 0);

      let checkIndex = sel.index;
      let [leaf] = this.quill.getLeaf(checkIndex);

      if (!leaf || !leaf.text) {
        return;
      }

      let leafIndex = leaf.offset(leaf.scroll);
      let leafSelIndex = checkIndex - leafIndex;

      let transformed = false;

      for (const name in this.transforms) {
        const transform = this.transforms[name];

        if (lastOp.insert.match(transform.trigger || /./)) {
          let ops = new Delta().retain(leafIndex);

          let transformOps;

          if (leaf.parent.statics.blotName === name) {
            let splitted = leaf.text.split(lastOp.insert);

            // https://sentry.qa-groupl.es/mewe/javascript/issues/2072/
            if (splitted && splitted.length) {
              transformOps = new Delta().retain(splitted[0].length).retain(splitted[1].length + 1, {
                [name]: null,
              });
            }
          } else {
            transformOps = makeTransformedDelta(transform, leaf.text, leafSelIndex);
          }

          if (transformOps.ops.length) {
            if (transformOps) {
              ops = ops.concat(transformOps);
            }

            this.quill.updateContents(ops, 'api');
            transformed = true;
          }
        }
      }

      if (transformed) {
        setTimeout(() => {
          this.quill.setSelection(this.quill.getLength() - endSelIndex, 'api');
        }, 0);
      }
    });
  }
}

function applyExtract(transform, match) {
  if (transform.extract) {
    let extract = new RegExp(transform.extract);
    let extractMatch = extract.exec(match[0]);

    if (!extractMatch || !extractMatch.length) {
      return match;
    }

    extractMatch.index += match.index;
    return extractMatch;
  }

  return match;
}

function makeTransformedDelta(transform, text, atIndex) {
  if (!transform.find.global) {
    transform.find = new RegExp(transform.find, transform.find.flags + 'g');
  }
  transform.find.lastIndex = 0;

  let ops = new Delta();
  let findResult = null;
  let checkAtIndex = atIndex !== undefined && atIndex !== null;

  if (checkAtIndex) {
    findResult = transform.find.exec(text);

    while (findResult && findResult.length && findResult.index < atIndex) {
      if (findResult.index < atIndex && findResult.index + findResult[0].length + 1 >= atIndex) {
        ops = ops.concat(transformedMatchOps(transform, findResult).ops);
        break;
      } else {
        findResult = transform.find.exec(text);
      }
    }
  } else {
    while ((findResult = transform.find.exec(text)) !== null) {
      let transformedMatch = transformedMatchOps(transform, findResult);
      ops = ops.concat(transformedMatch.ops);
      text = text.substr(transformedMatch.rightIndex);
      transform.find.lastIndex = 0;
    }
  }

  return ops;
}

function transformedMatchOps(transform, result) {
  result = applyExtract(transform, result);

  let resultIndex = result.index;

  const ops = new Delta();

  let { insert, format } = transform.getDelta(result[0]);

  ops.retain(resultIndex).delete(result[0].length).insert(insert, format);

  let rightIndex = resultIndex + result[0].length;

  return {
    ops,
    rightIndex,
  };
}

Autoformat.DEFAULTS = {
  hashtag: {
    trigger: /[\s.,;:!#?]/,
    find: /(?:^|\s)#[^\s.,;:!?]+/,
    extract: /#([^\s.,;:!?]+)/,
    transform: '$1',
    insert: 'hashtag',
    getDelta: (text) => {
      return {
        insert: { hashtag: { text: text } },
      };
    },
  },
  italicHashtag: {
    trigger: /[\s_]/,
    find: /(?:^|\s_)_#\w+_+/,
    extract: /_#([^\s.,;:!?]+)_+/,
    transform: '$1',
    insert: 'hashtagItalic',
    getDelta: (text) => {
      return {
        insert: { hashtagItalic: { text: text.replace(/(_#)(\w+)([_])/g, '<em>#$2</em>') } },
      };
    },
  },
  emoji: {
    trigger: /[\s.,;:!?]/,
    find: asciiRegexp,
    extract: regAscii,
    transform: '$1',
    insert: 'emoji',
    getDelta: (text) => {
      const short = replaceAsciiEmoji(text.trim());
      const emoji = getHashMap().shortnameDisplay[short.trim()];

      if (!emoji) return { insert: '' };

      let path = getEmojiUrlCdn() + emoji.png.default;
      return {
        insert: { emoji: 'true' },
        format: { src: path, 'data-name': emoji.shortname.replace(/:/g, '') },
      };
    },
  },
};

export { Autoformat as default };
