/* eslint-disable curly */
/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable lines-between-class-members */
/* eslint-disable getter-return */
import Service from '@ember/service';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AccountService extends Service {
  @service dynamicDialogs;

  @tracked featureFlags = [];
  @tracked temporaryLanguage = this.activeUser.temporaryLanguage;

  @reads('activeUser.isAuthenticated') isAuthenticated;
  @reads('activeUser.defaultLanguage') defaultLanguage;
  @reads('activeUser.locale') settingsLanguage;
  @reads('activeUser.limitReached') limitReached;
  @reads('activeUser.isContactLimit') isContactLimit;
  @reads('activeUser.affiliation') userVariant;
  @reads('activeUser.minimumNumberOfCharacters') minimumNumberOfCharacters = 10;
  @reads('activeUser.favorites') favorites;

  get activeUser() {
    return CurrentUserStore.getState();
  }

  get flagAIStickers() {
    const flag = this.featureFlags.find((f) => f.name === 'web_are_ai_stickers_enabled');
    return flag?.value; // undefined means the flag is not loaded yet
  }

  get flagCommentsFeed() {
    const flag = this.featureFlags.find((f) => f.name === 'web_is_comments_feed_enabled');
    return flag?.value; // undefined means the flag is not loaded yet
  }

  get supportedLanguages() {
    return this.activeUser.supportedLanguages;
  }

  get currentTranslationLanguage() {
    const detectedLanguage =
      this.temporaryLanguage ||
      this.defaultLanguage || //Language saved in translation-dialog
      this.settingsLanguage || //Selected language in account settings
      navigator.language ||
      navigator.userLanguage;

    return this.supportedLanguages?.find((lang) => lang === detectedLanguage) || 'en';
  }

  favoriteLimitPopup() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Favorites limit reached'),
      message: __(
        'Sorry the maximum number of Favorites you can have is 200. Please remove someone from your Favorites list if you want to add this user.'
      ),
    });
  }

  followLimitPopup() {
    this.dynamicDialogs.openDialog('do-you-know-dialog', {
      image: '/assets/images/do-you-know.webp',
      okButtonText: __('Got it!'),
      message: __(
        'Private profile cannot be followed by more than 8000 people. Please change your profile to public to keep your current followers, or create a page or a group to manage your large communities.'
      ),
      noEscaping: true,
    });
  }
}
