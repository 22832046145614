import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { escape, each } from 'lodash';

import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import ChatApi from 'mewe/api/chat-api';
import PS from 'mewe/utils/pubsub';
import Video from 'twilio-video';
import CurrentUserStore from 'mewe/stores/current-user-store';
import dispatcher from 'mewe/dispatcher';
import { isFirefox, getQueryStringParams } from 'mewe/shared/utils';
import { durationTextFromSeconds } from 'mewe/utils/datetime-utils';
import { callingAudio } from 'mewe/utils/miscellaneous-utils';
import { getElHeight, getElWidth, getOffset } from 'mewe/utils/elements-utils';

export default class VideocallRoute extends Component {
  @service settings;
  @service websocket;
  @service dynamicDialogs;

  @tracked activeView = 'loading';
  @tracked isConnected = false; // true when user is in room
  @tracked fullScreenMode = false;
  @tracked isCameraAvailable = true; // at start we assume that device has camera, will try to get access to it
  @tracked myVideoExpanded = true; // small video box with current user video preview, expanded by default
  @tracked audioEnabled = true; // microphone is enabled by default
  @tracked yourVideoTrack;
  @tracked videoEnabled;
  @tracked canMakeCall;
  @tracked thread = window.thread;
  @tracked isFirefox = isFirefox();
  @tracked controlsHidden;
  @tracked controlsHiddenByClick;
  @tracked callNotAnswered;
  @tracked isAnswering;
  @tracked callEnded;
  @tracked isCalling = false; // true when waiting for answering the call

  @tracked callEndedTs;
  @tracked currentTimeTs;
  @tracked callStartedTs;

  currentUserId = window.currentUserId;
  callWindow = window.callWindow; // present when call window opened from app, having this we can close window on user action

  // 1. connect media (ask for mic/cam permissions if needed)
  // 2. get token
  // 3. init twilio

  constructor() {
    super(...arguments);
    this.setInitialAttrs();
  }

  @action
  onInsert() {
    this.myVideoEl = document.querySelector('.my-video-box'); // local video preview element
    this.callVideoEl = document.querySelector('.call-video-box'); // received video stream element

    this.initDragAndDrop();
    this.bindWindowResize();
    this.bindControlsHiding();
    this.initWsHandlers();
  }

  @action
  onDestroy() {
    window.onbeforeunload = null;
  }

  setInitialAttrs() {
    const urlParams = getQueryStringParams();

    this.canMakeCall = urlParams.sub_on; // info if user has calling subscription must be passed from app
    this.videoEnabled = urlParams.v_on === 'true'; // initial state of current user's video stream
    this.isAnswering = urlParams.is_answ === 'true';
    this.threadId = urlParams.t_id;

    // overflow-y: scroll => it's default styling in our app, on this page needs to be overwritten
    let styleEl = document.createElement('style');
    styleEl.type = 'text/css';
    styleEl.innerText = 'html { overflow: hidden }';

    document.querySelector('head').appendChild(styleEl);

    // thread should be passed to opened window when opened call from app
    // if thread not present in window then it could be refreshed or opened by link and we don't support such usecases
    // because we wouldn't be able to store opened window object to close it when needed and to prevent opening more than one call
    if (this.thread && this.currentUserId) {
      this.connectMedia();
    } else {
      this.activeView = 'error';
      return;
    }

    window.onbeforeunload = () => {
      return this.isConnected || this.isCalling ? __('Are you sure you want to end this call?') : null;
    };

    window.addEventListener('click', (e) => {
      if (this.controlsHiddenByClick) {
        this.controlsHidden = false;
        this.controlsHiddenByClick = false;
      } else if (this.yourVideoTrack && e.target.closest('.call-video-box')) {
        this.controlsHidden = true;
        this.controlsHiddenByClick = true;
      }
    });
  }

  initTwilio(token) {
    Video.createLocalTracks({
      audio: true,
      video: this.videoEnabled ? { width: 1280 } : false,
    }).then((localTracks) => {
      return Video.connect(token, {
        name: this.threadId,
        tracks: localTracks,
      }).then(
        (room) => {
          this.room = room;
          this.isConnected = true;

          // new connection pending only when user is caller and there is no other user in room
          if (room.participants.size === 0) {
            // user is answering but there is no participant in the room => caller ended the call while call was being initialized for current user
            if (this.isAnswering) {
              this.endCall();
              return;
            } else {
              this.isCalling = true;
              this.playCallingSound();
            }
          }

          each(localTracks, (t) => {
            switch (t.kind) {
              case 'audio':
                this.myAudioTrack = t;
                break;
              case 'video':
                this.myVideoTrack = t;
            }
          });

          // disable audio after getting audio track according to current settings state
          if (this.myAudioTrack && this.audioEnabled) {
            room.localParticipant.publishTrack(this.myAudioTrack);
          } else {
            room.localParticipant.unpublishTrack(this.myAudioTrack);
          }

          // myVideoTrack could be also set by enablig video while creatingLocalTracks without video enabled
          // so this.myVideoTrack can be present while localTracks[1] is not
          if (this.myVideoTrack && this.videoEnabled) {
            this.setMyVideo(this.myVideoTrack);
          }

          room.participants.forEach(participantConnected); // for now there can be one participant, later with multiuser calls this 'forEach' will be needed

          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);

          room.once('disconnected', (room, error) => {
            if (error) {
              // error: "unable to create Room"
              if (error.code === 53103) {
                this.activeView = 'error';
              }
            }

            if (room.participants.length) room.participants.forEach(participantDisconnected);
            else {
              this.endCall();

              if (this.callVideoEl) {
                const videoEl = this.callVideoEl.querySelector('video');
                if (videoEl) videoEl.remove();
              }
            }
          });
        },
        (error) => {
          // error: "unable to create Room"
          if (error.code === 53103) {
            this.activeView = 'error';
          }
        }
      );
    });

    const participantConnected = (participant) => {
      // it shouldn't happen that someone connected to eneded call - but happens on backend, hack for that
      if (this.callEnded) {
        return;
      }

      this.stopCallingSound();
      this.startDurationClock();
      this.isCalling = false;

      participant.on('trackPublished', trackPublished);
      participant.tracks.forEach((track) => trackPublished(track));
    };

    const participantDisconnected = (participant) => {
      // when we will have multiuser chat then ending call when user disconnected won't be proper solution
      // for now there is only 1-1 call so if user disconnected then it's sure that call should be ended
      this.endCall();

      // endCall should be done first, removing tracks after that
      participant.tracks.forEach(trackUnpublished);

      if (this.callVideoEl) {
        const videoEl = this.callVideoEl.querySelector('video');
        if (videoEl) videoEl.remove();
      }
    };

    const trackPublished = (publication) => {
      publication.on('subscribed', (track) => {
        console.log(`LocalParticipant subscribed to a RemoteTrack: ${track}`);
        this.handleTrack(track, true);
      });

      publication.on('unsubscribed', (track) => {
        console.log(`LocalParticipant unsubscribed from a RemoteTrack: ${track}`);
        this.handleTrack(track, false);
      });
    };

    const trackUnpublished = (publication) => {
      this.handleTrack(publication, false);
    };
  }

  initDragAndDrop() {
    let elToDrag = null,
      x_pos = 0,
      y_pos = 0, // Stores x & y coordinates of the mouse pointer
      x_elem = 0,
      y_elem = 0; // Stores top, left values (edge) of the element

    const drag_start = () => {
      elToDrag = this.myVideoEl;
      x_elem = x_pos - getOffset(elToDrag).left;
      y_elem = y_pos - getOffset(elToDrag).top;
    };
    const drag_move = (e) => {
      x_pos = document.all ? window.event.clientX : e.pageX;
      y_pos = document.all ? window.event.clientY : e.pageY;

      if (elToDrag !== null) {
        const x_new = x_pos - x_elem;
        let y_new = y_pos - y_elem;
        y_new = window.innerHeight - (y_new + getElHeight(elToDrag));

        elToDrag.style.left = `${x_new}px`;
        elToDrag.style.bottom = `${y_new}px`;
        this.checkMyVideoOverflowing();
      }
    };
    const drag_end = () => (elToDrag = null);

    this.myVideoEl.addEventListener('mousedown', () => {
      drag_start();
      return false;
    });

    document.onmousemove = drag_move;
    document.onmouseup = drag_end;
  }

  bindWindowResize() {
    window.addEventListener('resize', () => {
      this.checkMyVideoOverflowing();
    });

    document.addEventListener('fullscreenchange', () => {
      const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
      this.fullScreenMode = isFullScreen;

      this.myVideoEl.style.left = 'auto';
      this.myVideoEl.style.top = 'auto';
    });
  }

  initWsHandlers() {
    CurrentUserStore.send('handle', { id: this.currentUserId });
    this.websocket.open();

    PS.Sub('chat.message.add', (data) => {
      if (data.eventType && data.eventType === 'CallEnded' && data.threadId === this.thread.id) {
        if (this.isConnected || this.isCalling) {
          this.endCall(true, !data.call.duration);
        }
      }
    });
  }

  handleTrack(track, isSubscribed) {
    if (!track) return;

    if (track.kind === 'video') {
      if (isSubscribed) {
        this.yourVideoTrack = track;
        this.callVideoEl.append(track.attach());
      } else {
        if (this.yourVideoTrack) {
          this.yourVideoTrack.detach().forEach((element) => element.remove());
        }
        this.yourVideoTrack = null;
      }
    }

    if (track.kind === 'audio') {
      if (isSubscribed) {
        this.yourAudioTrack = track;
        this.callVideoEl.append(track.attach());
      } else {
        if (this.yourAudioTrack) {
          this.yourAudioTrack.detach().forEach((element) => element.remove());
        }
        this.yourAudioTrack = null;
      }
    }
  }

  // prevents d&d of my-video element into position overflowing window
  checkMyVideoOverflowing() {
    const offset = getOffset(this.myVideoEl);
    const x_max = window.innerWidth - getElWidth(this.myVideoEl);
    const y_max = window.innerHeight - getElHeight(this.myVideoEl);
    let x_new = null,
      y_new = null;

    if (offset) {
      x_new = offset.left < 0 ? 0 : offset.left > x_max ? x_max : null;
      y_new = offset.top < 40 ? window.innerHeight - getElHeight(this.myVideoEl) - 40 : offset.top > y_max ? 0 : null; // 40px is the bottom of top-bar which has fixed position

      if (x_new !== null) this.myVideoEl.style.left = `${x_new}px`;
      if (y_new !== null) this.myVideoEl.style.bottom = `${y_new}px`;
    }
  }

  playCallingSound() {
    if (!this.isCalling) return;

    callingAudio.loop = true;
    callingAudio.play();

    // SG-13669
    // if other user answered the call but didn't give permissions to browser or closed the window without giving permissions
    // then backend thinks that user answered properly because 'answer' request is done on clicking answer button
    // so here we manually end the call if neither there is WS about ended call is received not the other user is connected to room after longer time
    // 40s seems reasonable as ringing time is ~22s and then some time for giving permissions is needed
    this.callingTimeout = window.setTimeout(() => {
      if (this.isCalling) {
        this.endCall();
      }
    }, 40000);
  }

  stopCallingSound() {
    window.clearTimeout(this.callingTimeout);
    callingAudio.pause();
  }

  getToken() {
    ChatApi.openCall(this.threadId, { video: this.videoEnabled }).then((data) => this.initTwilio(data.token));
  }

  connectMedia() {
    const success = () => {
      this.activeView = 'call';

      // fetching token was called immediately after clicking on call answer button
      if (window.tokenPromise) {
        window.tokenPromise
          .then((data) => {
            this.initTwilio(data.token);
          })
          .catch(() => {
            this.getToken();
          });
      } else if (this.thread) {
        this.getToken();
      } else {
        // window was refreshed or opened by url - don't init call automatically in this case but user can start a call manually
        this.callEnded = true;
        window.callEnded = true;
      }
    };
    const failure = (e) => {
      switch (e.name) {
        case 'NotFoundError':
        case 'DevicesNotFoundError':
        case 'TrackStartError':
          if (this.isCameraAvailable) {
            this.isCameraAvailable = false;
            this.videoEnabled = false;
            this.connectMedia(); // retry getting permissions for audio only when camera is nor available
            return;
          }
          break;
        case 'SourceUnavailableError':
          this.showMediaConnectionError();
          break;
        case 'PermissionDeniedError':
        case 'SecurityError':
        case 'NotAllowedError':
          this.activeView = 'gettingPermissions';
          break;
        default:
          this.showMediaConnectionError();
      }
    };

    if (navigator.mediaDevices) {
      let params = { audio: true, video: true };

      // TODO test this usecase when user have only microphone, or there is not even a mic
      if (!this.isCameraAvailable) params.video = false;

      later(
        this,
        () => {
          if (this.isDestroying || this.isDestroyed) return;
          // show gettingPermissions view if getUserMedia didn't return permissions immediately
          // (no saved settings so no success or failure but waiting for user's action)
          if (this.activeView === 'loading') this.activeView = 'gettingPermissions';
        },
        500
      );

      navigator.mediaDevices.getUserMedia(params).then(success).catch(failure);
    } else {
      if (!MiscellaneousUtils.isWebRTCSupported()) {
        dispatcher.dispatch('chat', 'videoCallDisabledDialog');
      } else {
        this.showMediaConnectionError();
      }
    }
  }

  get callTitleHTML() {
    const userName = escape(this.thread.getUserChatParticipant?.name);
    return __('Call with <strong>{userName}</strong>', {
      userName: userName,
    });
  }

  get canConnect() {
    return !this.isConnected && !this.isCalling && this.callEnded;
  }

  get callStatusText() {
    if (!this.thread.getUserChatParticipant.name) return '';

    if (this.callEnded) {
      return this.callNotAnswered ? __('No Answer') : __('This call has ended');
    } else if (this.isConnected) {
      if (this.isCalling) {
        return __('Ringing', { userName: escape(this.thread.getUserChatParticipant.name) });
      } else if (!this.yourVideoTrack) {
        return __(`{userName}'s video is turned off`, { userName: escape(this.thread.getUserChatParticipant.name) });
      }
    } else if (!this.isAnswering) {
      // not connected and not ended => just opened window and not connected to twilio yet
      return __(`You are calling <span class='call-video_status-strong'>{userName}</span>`, {
        userName: escape(this.thread.getUserChatParticipant.name),
      });
    }
  }

  get showLoadingDots() {
    if (this.callEnded) {
      return false;
    }
    if (this.isConnected) {
      return this.isCalling;
    }
    return !this.isAnswering;
  }

  get callDuration() {
    const endMs = this.callEndedTs || this.currentTimeTs;
    return durationTextFromSeconds((endMs - this.callStartedTs) / 1000);
  }

  setMyVideo(localTrack) {
    // room may be not created yet when enabling video before establishing connection
    if (this.room) this.room.localParticipant.publishTrack(localTrack);

    this.myVideoEl.append(localTrack.attach());

    this.myVideoTrack = localTrack;
    this.videoEnabled = true;
  }

  bindControlsHiding() {
    window.onmousemove = this.showControls.bind(this);

    this.hideControlsTimeout = window.setTimeout(() => {
      // hide buttons only if there is reveived video stream
      if (this.yourVideoTrack) this.controlsHidden = true;
    }, 5000);
  }

  showControls() {
    if (this.controlsHiddenByClick) return;

    window.clearTimeout(this.hideControlsTimeout);
    this.controlsHidden = false;
    this.bindControlsHiding();
  }

  showMediaConnectionError() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __(`Can't Make Call`),
      message: __(
        `Connect a camera and a microphone to make a call. If they're already connected, try restarting your browser and closing any other applications that use a camera or microphone.`
      ),
      okButtonText: __('OK'),
    });
  }

  startDurationClock() {
    this.callStartedTs = new Date().getTime();
    this.currentTimeTs = new Date().getTime();
    this.callEndedTs = null;

    this.clockInterval = window.setInterval(() => (this.currentTimeTs = new Date().getTime()), 1000);
  }

  stopDurationClock() {
    this.callEndedTs = new Date().getTime();
    window.clearInterval(this.clockInterval);
  }

  @action
  toggleWindowSize() {
    if (this.fullScreenMode) {
      each(['exitFullscreen', 'webkitExitFullscreen', 'mozCancelFullScreen', 'msExitFullscreen'], (fs) => {
        if (document[fs]) document[fs]();
      });
    } else {
      const wrapperEl = document.getElementsByClassName('videocall-window')[0];

      if (wrapperEl.requestFullscreen) {
        wrapperEl.requestFullscreen();
      } else if (wrapperEl.webkitRequestFullscreen) {
        wrapperEl.webkitRequestFullscreen();
      } else if (wrapperEl.mozRequestFullScreen) {
        wrapperEl.mozRequestFullScreen();
      } else if (wrapperEl.msRequestFullscreen) {
        wrapperEl.msRequestFullscreen();
      }
    }
  }

  @action
  toggleMyVideoSize() {
    this.myVideoExpanded = !this.myVideoExpanded;
    later(
      this,
      () => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.checkMyVideoOverflowing();
        }
      },
      200
    ); // timeout needed for 'my-video-box' css transition
  }

  @action
  toggleVideo() {
    // video track created only when user enables video or starts a video call - if it was already created then enable/disable it
    // unlike audio track, this video optional to share video and video track is not created at all if user is starting voice call

    if (!this.isCameraAvailable) {
      this.videoEnabled = false;
      return;
    }

    if (this.room) {
      if (this.videoEnabled) {
        this.room.localParticipant.unpublishTrack(this.myVideoTrack);
        this.videoEnabled = false;
      } else if (this.myVideoTrack) {
        this.room.localParticipant.publishTrack(this.myVideoTrack);
        this.videoEnabled = true;
      } else {
        Video.createLocalVideoTrack().then((localTrack) => this.setMyVideo(localTrack));
      }
    }
  }

  @action
  toggleAudio() {
    // audio track is a basic of the call so it's just being enabled/disabled
    // no need to create/remove it like video track
    if (this.room) {
      if (this.audioEnabled) {
        this.room.localParticipant.unpublishTrack(this.myAudioTrack);
        this.audioEnabled = false;
      } else {
        this.room.localParticipant.publishTrack(this.myAudioTrack);
        this.audioEnabled = true;
      }
    }
  }

  @action
  endCall(callAlreadyEnded, notAnswered) {
    if (this.isConnected) {
      // if already ended on server side (WS msg about callEnded) then no need to call api
      if (!callAlreadyEnded) ChatApi.dismissCall(this.threadId);

      if (this.myVideoTrack) this.myVideoTrack.detach().forEach((element) => element.remove());
      if (this.myAudioTrack) this.myAudioTrack.detach().forEach((element) => element.remove());

      this.stopDurationClock();
      this.stopCallingSound();

      this.callEnded = true;
      this.isConnected = false;
      this.isCalling = false;
      this.callStartedTs = null;
      this.callEndedTs = null;
      this.callNotAnswered = notAnswered;

      window.callEnded = true;

      // automatically close window 10s after finishing a call
      this.closeWindowTimeout = window.setTimeout(() => {
        if (this.callEnded && !this.isConnected && !this.isCalling) {
          this.closeWindow();
        }
      }, 10000);
    }
  }

  @action
  callAgain() {
    clearTimeout(this.closeWindowTimeout);

    this.getToken();
    this.callEnded = false;
    this.isAnswering = false;
  }

  @action
  closeWindow() {
    let win = this.callWindow;
    if (win) win.close();
  }
}
