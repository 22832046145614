import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import Util from 'mewe/utils/emoji-utils';
import { includes } from 'lodash';
import { getPNGSprite } from 'mewe/utils/emoji-utils';

export default buildHelper(function (value) {
  const emoji = value[0];
  const size = value[1];
  const noTone = value[2]; // in recent emojis we need to show original tone, not current set SG-22664

  if (!emoji) {
    console.log('Emoji passed to emoji helper is not defined ', value);
    return '';
  }

  let tone = Util.get('selectedSkinTone');

  if (
    !noTone &&
    emoji.keywords &&
    (emoji.diversity || includes(emoji.keywords, 'diversity') || emoji.diversities) &&
    tone > 0 &&
    !emoji.shortname.match(/.*?_tone(.)|:tone[0-9]:/g)
  ) {
    let skins = Util.skins()[emoji.shortname];

    if (!skins) {
      return htmlSafe(getPNGSprite(emoji, size));
    } else {
      let skinnedEmoji = Util.skins()[emoji.shortname][tone - 1];

      return htmlSafe(getPNGSprite(skinnedEmoji, size));
    }
  } else {
    return htmlSafe(getPNGSprite(emoji, size));
  }
});
