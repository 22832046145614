import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { escape } from 'lodash';
import { next } from '@ember/runloop';

import { setTheme } from 'mewe/utils/miscellaneous-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import fetchGroups from 'mewe/fetchers/fetch-groups';
import Session from 'mewe/shared/session';
import { Colors } from 'mewe/constants';

export default class ShareToMeweLoggedIn extends Component {
  @service settings;

  @tracked postboxVisible = true;
  @tracked groupsInited = false;
  @tracked currentUser = null;
  @tracked initialLink = null;

  constructor() {
    super(...arguments);

    this.currentUser = CurrentUserStore.getState();
    this.initialLink = escape(this.args.link);

    fetchGroups(() => {
      if (this.isDestroying || this.isDestroyed) return;
      this.groupsInited = true;
    });

    this.settings.promise().then(() => setTheme(this.settings.activeTheme));
  }

  @action
  logout() {
    Session.logout().finally(() => {
      window.location.href = '/';
    });
  }

  @action
  afterShare() {
    try {
      window.close();
    } catch (e) {}

    this.initialLink = null;

    // simple way of postbox reinitialisation in DOM
    // PROBLEM: inside app postbox is destroyed after posting and this way it's cleaned, here we don't destroy it
    // so it'd require more time to do postbox improvements to handle postbox cleanup after posting
    // this /share page isn't worth spending time on it afaik so this is the fastest solution
    this.postboxVisible = false;

    next(() => {
      this.postboxVisible = true;
    });
  }
}
