import api from 'mewe/api/pages-api';
import { ds } from 'mewe/stores/ds';
import { fetchUtil, unsetFlag } from 'mewe/fetchers/utils';

import { get, tap, map, filter } from 'mewe/utils/fp';
import { setNextPage, storeCollectionItems, pushCollectionItems } from 'mewe/stores/models/collection';

import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';

import NotificationModel from 'mewe/stores/models/page-notification-model';

const actingUsersPredicate = (n) => n.actingUsers && n.actingUsers.length > 0;

const toNotification = (pageId) => (notification) =>
  s.deserializeOne(NotificationModel.extend({ pageId: pageId }), notification);

export const fetchPageNotifications = (id) =>
  fetchUtil(api.notifications({ id }), ds.notifications.for(id))
    .then(tap(setNextPage(ds.notifications.for(id))))
    .then(get('notifications'))
    .then(filter(actingUsersPredicate))
    .then(map(toNotification(id)))
    .then(storeCollectionItems(ds.notifications.for(id)))
    .then(tap(unsetFlag(ds.notifications.for(id))));

export const loadMoreNotifications = ({ id, ref }) =>
  fetchUtil(api.notifications({ id, ref }), ds.notifications.for(id), { nextPage: true })
    .then(tap(setNextPage(ds.notifications.for(id))))
    .then(get('notifications'))
    .then(map(toNotification(id)))
    .then(pushCollectionItems(ds.notifications.for(id)))
    .then(unsetFlag(ds.notifications.for(id)));
