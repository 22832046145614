import { A } from '@ember/array';
import EmberObject, { computed } from '@ember/object';
import { ActionHandler } from '@ember/-internals/runtime';
import { each } from 'lodash';
import Storage from 'mewe/shared/storage';
import StoreItem from 'mewe/stores/models/store-item-model';
import StoreSubscription from 'mewe/stores/models/store-subscription-model';
import StorePayment from 'mewe/stores/models/store-payment-model';
import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';
import { storeDisplayInfo, productIdsMap } from 'mewe/pods/components/routable/app-store/config-mewe-store-new';
import { getTotalPriceForItems } from 'mewe/utils/miscellaneous-utils';
import PS from 'mewe/utils/pubsub';

const state = EmberObject.extend({
  cartItems: computed('storeItems.@each.isInCart', () => A(state.storeItems.filterBy('isInCart', true))),
  cartTotalPrice: computed('cartItems.@each.priceComputed', () => getTotalPriceForItems(state.cartItems)),

  // all items that are displayed in store
  storeItems: A(),
  storeItemsLoaded: false,

  subscriptions: A(),
  subscriptionsLoaded: false,

  payments: A(),
  paymentsLoaded: false,

  // array of owned itemIds
  purchasedItems: A(),
  purchasedItemsLoaded: false,
  appliedPromoCodeLabel: '',

  hasCallingSub: computed('purchasedItems.length', () => ~state.get('purchasedItems').indexOf('calls-voicevideo')),

  hasDarkTheme: computed('purchasedItems.length', () => ~state.get('purchasedItems').indexOf('theme-dark')),

  hasPremium: computed('purchasedItems.length', () => ~state.get('purchasedItems').indexOf('premium')),

  hasMonthlyDonation: computed('purchasedItems.length', () => ~state.get('purchasedItems').indexOf('donor')),
}).create();

const getState = () => state;
const getItem = (id) => state.storeItems.find((i) => i.id === id); // returns item only if it's loaded

const self = EmberObject.extend(ActionHandler, {
  getState: getState,

  restoreCart() {
    try {
      const cartFromLS = JSON.parse(Storage.get(Storage.keys.cartItems));
      if (cartFromLS && cartFromLS.length) {
        each(cartFromLS, (i) => {
          const opts = i.split('_');
          const storeItem = getItem(opts[0]);

          if (storeItem) {
            storeItem.products.find((p) => p.productId === opts[1]).isSelected = true;
            storeItem.set('isInCart', true);
          }
        });
      }
    } catch (e) {}
  },

  actions: {
    handleStoreItems(items) {
      each(items, (item) => {
        let displayInfo = storeDisplayInfo.find((d) => d.id === item.id);

        if (displayInfo) {
          item.displayInfo = displayInfo;
          item = s.deserializeOne(StoreItem, item);
        }

        each(item.products, (p) => {
          if (productIdsMap[p.productId]) {
            p.productId = productIdsMap[p.productId];
          }
        });

        // if product is already in storeItems then just update it
        let existing = getItem(item.id);
        if (existing) {
          existing.setProperties({
            products: item.products,
            possibleOperations: item.possibleOperations,
            usedTrial: item.usedTrial,
          });
        } else {
          state.storeItems.pushObject(item);
        }
      });

      // exclude single item fetching from marking a full load
      if (items.length > 1) {
        state.set('storeItemsLoaded', true);
      }

      // restore cart after store items are loaded
      this.restoreCart();

      PS.Pub('store.items.loaded');
    },

    handlePurchased(purchased) {
      state.set('purchasedItems', purchased);
      state.set('purchasedItemsLoaded', true);

      try {
        Storage.set(Storage.keys.purchasedItems, JSON.stringify(purchased));
      } catch (e) {}
    },

    handleSubscriptions(items) {
      state.set('subscriptions', s.deserializeMany(A(), StoreSubscription, items));
      state.set('subscriptionsLoaded', true);
    },

    handlePayments(items) {
      state.set('payments', s.deserializeMany(A(), StorePayment, items));
      state.set('paymentsLoaded', true);
    },

    resetStoreItems() {
      state.set('storeItems', A());
      state.set('storeItemsLoaded', false);
    },
  },
});

export default self.create();
