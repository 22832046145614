import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { getMembershipStatusInfo } from 'mewe/utils/group-utils-light';

const ENTER_KEY = 13;

export default class MwTile extends Component {
  @service('-routing') routing;
  tabindex = 0;
  role = 'button';

  constructor() {
    super(...arguments);
    this.cover = this.args.cover || '';
    this.avatar = this.args.avatar || '';
  }

  @computed('args.model.{alreadyApplied,isMember,isInvited}')
  get membershipStatus() {
    if (this.args.isGroup) return getMembershipStatusInfo(this.args.model);
    else return false;
  }

  @action
  keyDown({ keyCode }) {
    if (keyCode === ENTER_KEY) {
      this.click();
    }
  }

  @action
  click() {
    if (typeof this.args.onClick === 'function') {
      this.args.onClick();
    }

    if (this.args.route) {
      this.routing.transitionTo(this.args.route, [this.args.routeParam]);
    }
  }
}

/*import TileComponent from '../../mw-tile';

export default TileComponent.extend({
  tagName: 'article',
  classNames: ['mw-tile-big'],
  classNameBindings: ['description:has-description'],

  cover: '',
  avatar: '',
});*/
