import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import MiscUtils from 'mewe/utils/miscellaneous-utils';
import { Colors } from 'mewe/constants';

export default class MwInviteShare extends Component {

  @service account;

  constructor() {
    super(...arguments);

    let publicUrl, qrCodeUrl;

    if (this.isEventShare) {
      publicUrl =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') +
        '/e/' +
        this.args.eventData?.publicUrl;
      qrCodeUrl = `${publicUrl}/qr?onColor=.${Colors.APP}`;
    } else if (this.isGroupShare) {
      publicUrl = EnvironmentUtils.correctHostnameAndPort(this.args.group.publicUrl);
      qrCodeUrl = `${this.args.group.publicUrl}/qr`;
    } else if (this.args.page) {
      publicUrl = EnvironmentUtils.correctHostnameAndPort(this.args.page.publicUrl);
      qrCodeUrl = `${this.args.page.publicUrl}/qr`;
    } else {
      const contactInviteId = this.account.activeUser.publicLinkId || '';
      const shareHost = EnvironmentUtils.getHost();
      publicUrl = `${shareHost}/${contactInviteId}`;
      //BE expects to have /i/profile_name/qr
      qrCodeUrl = `${shareHost}/i/${contactInviteId}/qr`;
    }

    this.publicUrl = publicUrl.replace('/p/', '/');
    this.qrCodeUrl = qrCodeUrl;

    this.whatsAppText = __(
      'I want you to join me on MeWe, the Next-Gen Social Network. No Ads. No Spyware. No BS: {publicUrl}',
      {
        publicUrl: encodeURIComponent(this.publicUrl),
      }
    );
  }

  get publicUrlDisplay() {
    return this.publicUrl.replace('https://', 'www.');
  }

  get isEventShare() {
    return this.args.eventData?.eventType === 'open';
  }

  get isGroupShare() {
    return this.args.group?.isPublic;
  }

  get shareText() {
    if (this.isEventShare) return __('Share the event link or QR code');
    else if (this.isGroupShare) return __('Share the group link or QR code');
    else if (this.args.page) return __('Share the page link or QR code');
    else return __('Share your profile link to invite friends to MeWe');
  }

  get shareTextLink() {
    if (this.isEventShare) return __('Event link:');
    else if (this.isGroupShare) return __('Group link:');
    else if (this.args.page) return __('Page link:');
    else return __('Your profile link:');
  }

  getWindowFeatures() {
    return (
      'status=no,height=330,width=700,resizable=yes,left=' +
      (window.screen.width / 2 - 300) +
      ',top=' +
      (window.screen.height / 2 - 165)
    );
  }

  @action
  inviteFromFacebook() {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?mode=message&u=${this.publicUrl}`,
      'sharer',
      this.getWindowFeatures()
    );
  }

  @action
  inviteFromTwitter() {
    let text = encodeURIComponent(
        __("See what I'm really sharing these days. Join me on MeWe - the social network with privacy!")
      ),
      url = encodeURIComponent(this.publicUrl);

    window.open(
      `https://www.twitter.com/intent/tweet?text=${text}&url=${url}`,
      'sharer',
      this.getWindowFeatures()
    );
  }

  @action
  copyToClipboard() {
    MiscUtils.copyToClipboard(this.publicUrl);
  }
}
