import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import dispatcher from 'mewe/dispatcher';

export default class MwRemoveBlock extends Component {
  @action
  click() {
    const groupId = this.args.post.groupId;
    const eventId = this.args.post.event2?.id;

    if (this.args.closePostDropdown) this.args.closePostDropdown();

    const blockCallback = () => {
      if (groupId) {
        dispatcher.dispatch('group', 'fetchCounters', groupId);
      }

      // refetch feed in case of being in app.group.index.feed or app.groups
      let groupsRoute = getOwner(this).lookup(`route:app.groups`);
      if (groupsRoute) groupsRoute.send('refetchFeed');

      let groupFeedPendingRoute = getOwner(this).lookup(`route:app.group.index.feed.pending`);
      if (groupFeedPendingRoute) groupFeedPendingRoute.send('refetchFeed');

      let groupFeedRoute = getOwner(this).lookup(`route:app.group.index.feed`);
      if (groupFeedRoute && !groupFeedPendingRoute) groupFeedRoute.send('refetchFeed');

      // refetch event feed in case of being in app.event.feed
      let eventFeedRoute = getOwner(this).lookup(`route:app.event.feed`);
      if (eventFeedRoute) eventFeedRoute.send('refetchFeed');
    };
    if (this.args.scope === 'group') {
      dispatcher.dispatch('contact', 'blockUserFromAllGroup', this.args.post.owner, groupId, blockCallback);
    } else {
      dispatcher.dispatch('contact', 'blockUser', this.args.post.owner, blockCallback, groupId, eventId);
    }
  }
}
