/* eslint-disable lines-between-class-members */
import Service from '@ember/service';
import { setComponentTemplate } from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import ScrollingUtils from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { computed } from '@ember/object';
import MathUtils from 'mewe/utils/math-utils';

export default class DynamicDialogsService extends Service {
  @tracked components = A(); // used to store actual dialog components
  @tracked _registered = A(); // used to track opened dialogs and prevent multiple instances

  @computed('_registered.[]')
  get hasAnyDialogOpened() {
    return this._registered.length;
  }

  dialogLoadingClass = 'app-routable--dialog-loader';
  blurClass = 'h-blur';

  getWormhole() {
    return document.getElementById('mw-wormhole-dialog');
  }

  constructor() {
    super(...arguments);

    PS.Sub('open.generic.dialog', (params) => {
      if (!params?.message && !params.title) return;
      if (!params.okButtonText) params.okButtonText = __('OK');
      this.openDialog('simple-dialog-new', params);
    });

    this.registerListeners();
  }

  async openDialog(dialogName, args = {}) {
    // in case of some routes outside the app, wormhole is not created in app.js route
    this.setupDialogWormhole();

    if (!args.allowMultipleInstances) {
      const comp = this._registered.find((el) => el.dialogName === dialogName);
      if (comp) {
        return;
      }
    }

    this.loadingDialog();

    if (args.blurApp) {
      this.setBlur(true);
    }

    const wormhole = this.getWormhole();

    if (args.useDarkTheme) {
      wormhole.classList.add('app', 'theme-dark');
    } else {
      wormhole.classList.remove('app', 'theme-dark');
    }

    // register dialog even before importing/injecting it to
    // prevent multiple instances when it's needed. Assign unique id to dialog
    // to be able to filter proper dialog to close
    args.dialogId = MathUtils.generateId();
    this.register({ dialogName, dialogId: args.dialogId });

    const owner = getOwner(this);
    const factory = owner.factoryFor(`component:${dialogName}`);
    if (!factory) {
      let [template, module] = await Promise.all([
        import(`../dialogs/${dialogName}/template.hbs`),
        import(`../dialogs/${dialogName}`),
      ]);
      setComponentTemplate(template.default, module.default);
      owner.register(`component:${dialogName}`, module.default);
    }

    this.components.pushObject({
      componentName: dialogName,
      componentArgs: args,
    });

    this.notLoadingDialog();

    ScrollingUtils().disableWindowScroll();
  }

  close(dialogId, closeConfirmed) {
    if (!dialogId) return;

    const comp = this.getDialogById(dialogId);

    if (comp) {
      // completely prevent closing of dialogs with 'isCloseBlocked' flag
      if (comp.componentArgs.isCloseBlocked) return;

      // if close function is present then actuall closing has to be triggered
      // with 'closeConfirmed' flag, otherwise it will be just closeFunction call without dialog closing
      if (comp.closeFunction && !closeConfirmed) {
        comp.closeFunction();
        return;
      }

      comp.componentArgs.onClose?.();

      // filter out the dialog from the registered list
      this.unregister(dialogId);
      this.components.removeObject(comp);
    }

    const blurDialog = this.components.find((c) => c.componentArgs.blurApp);
    if (!blurDialog) {
      this.setBlur(false);
    }

    if (!this.components.length) {
      ScrollingUtils().enableWindowScroll();
    }
  }

  closeAll() {
    this.components.forEach((comp) => {
      this.close(comp.componentArgs.dialogId, comp.componentArgs.dialogName);
    });

    PS.Pub('close.dropdowns'); // not sure if this is needed here but it was in old dialog-utils
    PS.Pub('close.smart.search'); // not sure if this is needed here but it was in old dialog-utils
  }

  closeByName(dialogName) {
    const dialogs = this.components.filter((c) => c.componentName === dialogName);
    dialogs.forEach((dialog) => {
      this.close(dialog.componentArgs.dialogId);
    });
  }

  setupDialogWormhole() {
    if (!this.getWormhole()) {
      const dialogWormhole = document.createElement('div');
      dialogWormhole.id = 'mw-wormhole-dialog';
      document.body.append(dialogWormhole);
    }
  }

  getDialogById(dialogId) {
    return this.components.find((comp) => comp.componentArgs.dialogId === dialogId);
  }

  // if `closeFunction` is registered then also param `confirmClose: true` has to be passed
  // to the `close` call to do the actual close instead of triggering the registered close function
  // so it should be: dynamicDialogs.close(dialogId, true)
  registerCloseFunction(dialogId, closeFunction) {
    const comp = this.getDialogById(dialogId);
    if (comp) {
      comp.closeFunction = closeFunction;
    }
  }

  register(registerData) {
    const found = this._registered.find((el) => el.dialogName === registerData.dialogName);
    if (!found) this._registered.pushObject(registerData);
  }

  unregister(dialogId) {
    this._registered = A(this._registered.filter((el) => el.dialogId !== dialogId));
  }

  loadingDialog() {
    const bodyEl = document.querySelector('body');
    if (bodyEl?.classList && !bodyEl.classList.contains(this.dialogLoadingClass)) {
      bodyEl.classList.add(this.dialogLoadingClass);
    }
  }

  notLoadingDialog() {
    const viewEl = document.querySelector('body');
    if (viewEl?.classList?.contains(this.dialogLoadingClass)) {
      viewEl.classList.remove(this.dialogLoadingClass);
    }
  }

  setBlur(value) {
    // route element might not be present in DOM when this is called
    // so we need to check for it in a loop
    this.blurInterval = setInterval(() => {
      const routeEl = document.querySelector('body > .c-app-route');
      if (!routeEl) return;

      if (value) {
        routeEl.classList.add(this.blurClass);
      } else {
        routeEl.classList.remove(this.blurClass);
      }
      clearInterval(this.blurInterval);
    }, 200);
  }

  registerListeners() {
    this.generalEscapeHandlerBind = this.generalEscapeHandler.bind(this);
    document.addEventListener('keyup', this.generalEscapeHandlerBind);
  }

  generalEscapeHandler(e) {
    // escape key maps to keycode `27`
    // use registerCloseFunction to bind close function that should be used on escape key
    if (e.keyCode == 27) {
      if (this.components.length) {
        // find last opened dialog that is closable (closing order should be opposite to opening order)
        const closableDialog = this.components.reverse().find((c) => {
          return !c.componentArgs.doNotEscapeClose && !c.componentArgs.isCloseBlocked;
        });

        if (closableDialog) {
          this.close(closableDialog.componentArgs.dialogId);
        }
      }
    }
  }
}
