import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { set } from '@ember/object';

import { each } from 'lodash';

import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupApi from 'mewe/api/group-api';
import GroupPermissions from 'mewe/utils/group-permissions-utils';
import MiscUtils from 'mewe/utils/miscellaneous-utils';
import config from 'mewe/config';
import dispatcher from 'mewe/dispatcher';
import { next } from '@ember/runloop';

export default class MwGroupSettingsMembership extends Component {
  @reads('args.group') group;

  @service router;
  @service account;
  @service dynamicDialogs;

  @tracked loaded;
  @tracked isSaving;
  @tracked discoveryOptIn;
  @tracked isCheckingLink;
  @tracked privacyType;
  @tracked isPublic;
  @tracked isPublicApply;
  @tracked publicRole;
  @tracked proposedHandle;
  @tracked publicUrlId;
  @tracked lastPublicUrlId;

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  willDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  groupDataReady() {
    if (!this.loaded && this.group?.id && !this.group.isFetching) {
      if (this.group.isOwnerAdmin) {
        this.isPublic = this.group.isPublic;
        this.isPublicApply = this.group.isPublicApply;
        this.publicRole = this.group.publicRole;
        this.discoveryOptIn = this.group.discoveryOptIn;
        this.urlStaticPart = `${config.apiHost}/join/`;
        this.publicUrlId = this.group.publicUrlId;
        this.privacyType = this.isPublic ? (this.isPublicApply ? 1 : 2) : 0;

        each(this.group.rolesPermissions, (p) => {
          set(p, 'permissionsNames', GroupPermissions.getPermissionsNames(p.permissions));
        });

        next(() => {
          this.loaded = true;
        });

        this.checkDiscoveryHint();
      }
    }
  }

  @computed('urlStaticPart', 'publicUrlId')
  get publicUrl() {
    return this.urlStaticPart + this.publicUrlId;
  }

  @computed('proposedHandle', 'lastPublicUrlId')
  get proposedLinkText() {
    if (!this.proposedHandle) return;

    return __('The link {linkUrl} is taken. We suggest you use this one: {proposedHandle}', {
      linkUrl: this.urlStaticPart + this.lastPublicUrlId,
      proposedHandle: '<b class="proposed-handle">' + this.urlStaticPart + this.proposedHandle + '</b>',
    });
  }

  @action
  privacyTypeChange(privacyType) {
    this.privacyType = privacyType;
    if (this.group.banned) {
      this.isPublic = false;
      this.isPublicApply = false;

      return false;
    }

    switch (this.privacyType) {
      case 0:
        if (this.group.applicantsCount) {
          this.dynamicDialogs.openDialog('simple-dialog-new', {
            title: __('{count} member has applied to join this group.', {
              count: this.group.applicantsCount,
            }),
            message: __('You must first approve or decline them.'),
            cancelButtonText: __('Cancel'),
            okButtonText: __('Manage Applying Members'),
            onConfirm: () => {
              this.router.transitionTo('app.group.members.pending', this.group.id);
            },
          });
        } else {
          this.isPublic = false;
          this.isPublicApply = false;
        }
        break;

      case 1:
        this.isPublic = true;
        this.isPublicApply = true;
        this.discoveryOptIn = true; // when type is changed to PublicApply then default discoveryOptIn to true
        this.publicUrlId = this.publicUrlId || this.group.name;

        break;

      case 2:
        this.isPublic = true;
        this.isPublicApply = false;
        this.publicUrlId = this.publicUrlId || this.group.name;

        break;
    }
  }

  @action
  publicRoleChange(publicRole) {
    this.publicRole = publicRole;
  }

  @computed('isPublic', 'isPublicApply', 'publicRole', 'isSaving', 'discoveryOptIn', 'publicUrlId')
  get isSaveVisible() {
    const publicChanged = this.isPublic !== this.group.isPublic;
    const publicApplyChanged = this.isPublicApply !== this.group.isPublicApply;
    const publicRoleChanged = this.publicRole !== this.group.publicRole;
    const discoverParticipationChanged = this.isPublic && this.discoveryOptIn !== this.group.discoveryOptIn;
    const publicLinkChanged = this.isPublic && this.publicUrlId && this.publicUrlId !== this.group.publicUrlId;

    return (
      !this.isSaving &&
      (publicChanged || publicApplyChanged || publicRoleChanged || publicLinkChanged || discoverParticipationChanged)
    );
  }

  // dismiss discovery hint banner when:
  // A. user opens membership settings, we assume he noticed new setting
  // B. changed group type - BE sets hint as unseen when group changed to Selective SG-33041)
  // hint is not applicable for PRIVATE/PUBLIC groups, only for SELECTIVE
  checkDiscoveryHint() {
    if (this.isPublicApply && !this.discoveryOptIn) {
      GroupApi.markDiscoverHintAsSeen(this.group.id);
    }
  }

  @action
  getPublicLink() {
    let urlToTest;

    if (!this.publicUrlId) {
      this.publicUrlId = this.group.name;
      urlToTest = this.publicUrlId;
    } else {
      let newId = this.publicUrlId;

      if (newId && newId !== this.group.publicUrlId) urlToTest = newId;
      else {
        this.isEditingLink = false;
        this.lastTestedUrl = '';
        return;
      }
    }

    this.isCheckingLink = true;
    this.proposedHandle = '';

    GroupApi.checkPublicUrl(urlToTest).then((data) => {
      if (data && data.suggestedUrlId) {
        this.proposedHandle = data.suggestedUrlId;
        this.lastPublicUrlId = urlToTest;
        this.publicUrlId = data.suggestedUrlId;
        this.isPendingSave = false; // do not retry saving because link is not available
      }

      this.isCheckingLink = false;
      this.isEditingLink = false;

      if (this.isPendingSave) this.saveSettings();
    });
  }

  @action
  editPublicLink() {
    this.isEditingLink = true;

    const input = document.getElementById('group-public-link');
    input.focus();
    setTimeout(function () {
      input.setSelectionRange(0, 0);
    }, 0);
  }

  @action
  saveSettings() {
    let params = {
      isPublic: this.isPublic,
      isPublicApply: this.isPublicApply,
      publicRole: this.publicRole,
    };

    // save clicked while new group link is being checked, need to check until request will be done
    // and proceed with saving after that if link is available
    if (this.isCheckingLink) {
      this.isPendingSave = true;
      return;
    } else {
      this.isPendingSave = false;
    }

    if (this.isPublic) {
      if (this.publicUrlId) {
        params.publicUrlId = this.publicUrlId;
      }
    }

    if (this.isPublicApply) {
      params.discoveryOptIn = this.discoveryOptIn;
    }

    this.isSaving = true;

    GroupApi.setGroupData(this.group.id, params)
      .then((data) => {
        if (this.isDestroying || this.isDestroyed) return;

        if (data.errorCode === 608) {
          this.lastPublicUrlId = params.publicUrlId;
          this.proposedHandle = data.suggestedUrlId;
          this.publicUrlId = data.suggestedUrlId;
          return;
        }

        FunctionalUtils.info(__('Group Settings have been successfully saved.'));

        this.group.setProperties({
          publicUrlId: data.publicUrlId,
          publicUrl: data.publicUrl,
          isPublic: data.isPublic,
          isPublicApply: data.isPublicApply || false,
          publicRole: data.publicRole,
          discoveryOptIn: data.discoveryOptIn,
        });

        this.proposedHandle = '';

        this.checkDiscoveryHint();
      })
      .catch((data) => {
        if (data && data.status) {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.isSaving = false;
      });
  }

  @action
  confirmGroupLeaving() {
    dispatcher.dispatch(
      'group-member',
      'removeMember',
      { id: this.account.activeUser.id, name: this.account.activeUser.name },
      this.group
    );
  }

  @action
  copyToClipboard() {
    MiscUtils.copyToClipboard(this.publicUrl);
  }
}
