import BookmarksApi from 'mewe/api/bookmarks-api';
import { fetchUtil, unsetFlag, toBookmark } from 'mewe/fetchers/utils';
import { handleOtherStores } from 'mewe/fetchers/fetch-feed';
import { tap } from 'mewe/utils/fp';
import { ds } from 'mewe/stores/ds';
import { storeCollectionItems, pushCollectionItems, setNextPage } from 'mewe/stores/models/collection';
import { toPost } from 'mewe/stores/models/post-model';

export const fetchBookmarks = (params) =>
  fetchUtil(BookmarksApi.getBookmarks(params), ds.bookmarks)
    .then(tap(setNextPage(ds.bookmarks)))
    .then(tap(handleOtherStores))
    .then((data) => {
      const storeOrPush = params?.nextPage ? pushCollectionItems : storeCollectionItems;
      storeOrPush(ds.bookmarks, data.bookmarks.map(toBookmark));
      // if belongsTo relation between post and bookmark is not deserialized automatically, we need to redo it, MW-1498
      ds.bookmarks.items.forEach((item) => {
        if (item.post === undefined) {
          const bookmark = data.bookmarks.find((b) => b.id === item.id);
          const post = toPost(bookmark.post);
          item.post = post;
        }
      });
      return data;
    })
    .then(unsetFlag(ds.bookmarks));