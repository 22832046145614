import Component from '@glimmer/component';

export default class MwButton extends Component {
  constructor() {
    super(...arguments);
  }

  get type() {
    return this.args.type ?? 'button';
  }

  get variant() {
    return this.args.variant ?? 'primary';
  }
}
