import { camelize } from '@ember/string';
import { addObserver } from '@ember/object/observers';
import EmberObject, { computed, get } from '@ember/object';
import Copyable from 'ember-data-copyable';
import ModelStateMixin from './state';
import { attr } from './attribute';
import { next } from '@ember/runloop';
//TODO: this need to be updated with run from the runloop module
import { beginPropertyChanges, endPropertyChanges } from '@ember/-internals/metal';

var Model = EmberObject.extend(ModelStateMixin, Copyable, {
  id: attr('number'),

  __data: null,
  _data: computed(function () {
    if (!this.__data) {
      this.__data = {};
    }
    return this.__data;
  }),

  didDefineProperty: function (proto, key, value) {
    var meta;
    if (value && value.isDescriptor) {
      meta = value.meta();
      if (meta.isRelationship && !meta.readOnly) {
        addObserver(proto, key + '.isDirty', null, '_onRelationshipChange');
      }
    }
  },

  _onPropertyChange: function (key) {
    var isNew = this.get('isNew');

    if (isNew && get(this.constructor, 'primaryKey') === key) {
      this.set('isNew', false);
      isNew = false;
    }

    if (this.get('_isReady') && (isNew || this.get('isLoaded'))) {
      this.set('isDirty', true);
    }
  },

  _onRelationshipChange: function (sender, key) {
    if (sender.get(key)) {
      // if isDirty
      this._onPropertyChange(key);
    }
  },

  copy: function () {
    var clone = this.constructor.create();
    var fields = get(this.constructor, 'fields');
    var field, value;

    beginPropertyChanges(this);
    for (field in fields) {
      if (fields.hasOwnProperty(field)) {
        value = this.get(field);
        if (value !== null) {
          clone.set(field, value);
        }
      }
    }
    endPropertyChanges(this);

    return clone;
  },

  copyWithState: function () {
    return this.copyState(this.copy());
  },
});

Model.reopenClass({
  create: function () {
    var instance = this._super.apply(this, arguments);
    next(() => {
      instance.set('_isReady', true);
    });
    return instance;
  },

  resourceName: computed(function () {
    var classNameParts = this.toString().split('.');
    return classNameParts[classNameParts.length - 1];
  }),

  _configKey: computed('resourceName', function () {
    return camelize(get(this, 'resourceName'));
  }),

  fields: computed(function () {
    var fields = {};
    this.eachComputedProperty(function (name, meta) {
      if (meta.isAttribute || meta.isRelationship) {
        fields[name] = meta;
      }
    });
    return fields;
  }),
});

export default Model;
