import config from 'mewe/config';
import storage from 'mewe/shared/storage';
import cookie from 'mewe/shared/cookie';
import tokenManager from './token-manager';
import { createDeferred } from 'mewe/shared/utils';
import axios from 'axios';

class Session {
  /**
   * clear local cookies and settings, this is done after logout
   * NOTE Chrome (<=76) has an issue which causes Clear-Site-Data to run for very long,
   * so we are calling auth/clear until it gets fixed. We can't wait for the request to finish so
   * timeout is introduced untill chrome gets fixd
   */
  clearLocalData() {
    storage.clear();
    tokenManager.clear();
    this._pendingPromise = null;
    this._authenticated = false;
  }

  /**
   * @private
   */
  _pendingPromise = null;

  _authenticated = false;

  _getHeaders() {
    const headers = {};
    const csrfToken = this.getCsrfToken();

    if (csrfToken) {
      headers['X-CSRF-Token'] = csrfToken;
    }

    return headers;
  }

  getCsrfToken() {
    return cookie.get('csrf-token');
  }

  // fastest way to check if user is authenticated, but it can be false positive sometimes
  hasAuthCookie(){
    return !!cookie.get('session-id');
  }

  isAuthenticated() {
    return this._isAuthenticated();
  }

  /**
   * it will call `/auth/identify` only once
   */
  _isAuthenticated() {

    if(this._pendingPromise != null) return this._pendingPromise;

    let deffered = createDeferred();
    this._pendingPromise = deffered.promise;

    let retryCount = 0;

    const url = `${config.apiRoot3}/auth/identify`;

    axios({
      url: url,
      method: 'GET',
      headers: this._getHeaders(),
    })
      .then(this._isAuthenticatedSuccess.bind(this, deffered))
      .catch((err) => {
        // requests are failing when antiCsfr or other validation is not passed.
        // we also have recently issues with BE stability so we give one more try after first request fails.

        if (err.status >= 500 && err.status < 600) {
          this._isAuthenticatedRetry(url, deffered, err, retryCount);
        } else {
          deffered.resolve(false);
        }
      });

    return deffered.promise;
  }

  /**
   * every request to api refresh authentication token, but there are cases when no api calls are done and app is open, eg. when ws message comes in
   */
  refreshAuthentication() {
    let deffered = createDeferred();
    this._pendingPromise = deffered.promise; // replace previously resolved promise with new one

    const url = `${config.apiRoot3}/auth/identify`;

    axios({
      url: url,
      method: 'GET',
      headers: this._getHeaders(),
    })
      .then(this._isAuthenticatedSuccess.bind(this, deffered))
      .catch((err) => {
        deffered.resolve(false);
      });

    return deffered.promise;
  }

  /**
   * tells if user is properly authenticated and refreshes tokens (as a side effect)
   */
  _isAuthenticated_deprecated() {
    let deffered = createDeferred();
    let retryCount = 0;

    const url = `${config.apiRoot3}/auth/identify`;

    // This part caches promise for time defined at the top of the file OR until it is finished
    if (this._pendingPromise && !this._authenticated) {
      return this._pendingPromise;
    } else {
      this._pendingPromise = deffered.promise;
    }

    axios({
      url: url,
      method: 'GET',
      headers: this._getHeaders(),
    })
      .then(this._isAuthenticatedSuccess.bind(this, deffered))
      .catch((err) => {
        // requests are failing when antiCsfr or other validation is not passed.
        // we also have recently issues with BE stability so we give one more try after first request fails.

        if (err.status >= 500 && err.status < 600) {
          this._isAuthenticatedRetry(url, deffered, err, retryCount);
        } else {
          deffered.resolve(false);
        }
      });

    return deffered.promise;
  }

  _isAuthenticatedRetry(url, deffered, err, retryCount) {
    const me = this;
    const retryFunc = function (timeout) {
      setTimeout(() => {
        axios({
          url: url,
          method: 'GET',
          headers: me._getHeaders(),
        })
          .then(me._isAuthenticatedSuccess.bind(me, deffered))
          .catch(me._isAuthenticatedRetry(url, deffered, err, retryCount));
      }, timeout);
    };

    if (retryCount === 0) {
      retryFunc(1000);
    } else if (retryCount === 1) {
      retryFunc(3000);
    } else if (retryCount === 2) {
      retryFunc(7000);
    } else if (
      !~[
        '/',
        '/not4sale',
        '/press',
        '/privacy',
        '/privacy/',
        '/terms',
        '/terms/',
        '/about',
        '/values',
        '/jobs',
        '/faq',
        '/archive',
        '/archive-privacy',
        '/pricing',
        '/security',
        '/features',
      ].indexOf(window.location.pathname)
    ) {
      // if api is down, redirect to 500 page. But some views are excluded - they do not require api to work
      window.location = '/500';
    } else {
      // if request was done in whitelisted view, treat as unauthorized
      return deffered.resolve(false, false, false, false);
    }

    retryCount++;
  }

  _isAuthenticatedSuccess(deffered, resp) {

    if (resp.tos) {
      storage.set(storage.keys.needTosAccepted, true);
    } else if (storage.get(storage.keys.needTosAccepted)) {
      storage.remove(storage.keys.needTosAccepted);
    }

    this._authenticated = true;

    deffered.resolve({
      isAuthenticated: resp.data.authenticated,
      isConfirmed: resp.data.confirmed,
      isJailed: resp.data.jail,
      needsTos: resp.data.tos,
      isLocked: resp.data.locked,
    });
  }

  logout() {
    let deffered = createDeferred();
    const headers = this._getHeaders();

    axios({
      url: '/api/v3/auth/logout',
      method: 'POST',
      headers: headers,
    })
      .then(() => {
        this.clearLocalData();
        deffered.resolve(true);
      })
      .catch((err) => {
        this.clearLocalData();
        deffered.reject(Error(`[Session] /api/v3/auth/logout failed`, { cause: err }));
      });

    return deffered.promise;
  }

}

export default new Session();
