import Component from '@glimmer/component';
import { computed } from '@ember/object';

import MathUtils from 'mewe/utils/math-utils';

export default class MwPostDocument extends Component {
  @computed('document.size')
  get documentSize() {
    const document = this.args.document || {};

    if (typeof document.size === 'string') return document.size;
    // post to repost has already string value
    else return MathUtils.getDocumentSizeText(document.size);
  }
}
