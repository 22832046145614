import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'User Autocomplete',
  component: 'MwUserAutocomplete',
  excludeStories: /.*Data$/,
};

const Template = (args) => ({
  template: hbs`<Others::MwUserAutocomplete
  @chatWindow={{true}}
  @allowEmptySearch={{true}}
  @selectedItems={{this.selectedItems}}
  @skin='new-chat'
/>`,
  context: args,
});

export const onNoSelectedItems = Template.bind({});
onNoSelectedItems.args = {
  selectedItems: [],
};

export const onSelectedItems = Template.bind({});
onSelectedItems.args = {
  selectedItems: [
    {
      user: {
        name: 'ABC',
      },
    },
    {
      user: {
        name: 'DEF',
      },
    },
  ],
};
