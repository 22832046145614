import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { A } from '@ember/array';
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';

import Scrolling from 'mewe/utils/scrolling-utils';
import PostApi from 'mewe/api/post-api';
import PS from 'mewe/utils/pubsub';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';
import { maxResultsTagsList } from 'mewe/constants';
import { next } from '@ember/runloop';

export default class MwGroupWhatsNew extends Component {
  @service dynamicDialogs;

  @tracked tags = A();
  @tracked isLoadingTags = false;
  @tracked hashtagsFetched = false;
  @tracked tagsSearchPhrase = '';
  @tracked showTagSearch;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    next(this, function () {
      this.loadTags();
    });

    this.postAddBind = this.postAdd.bind(this);
    PS.Sub('post.add', this.postAddBind);

    addObserver(this, 'args.selectedTag', this.selectTag);
    addObserver(this, 'args.group.id', this.groupIdChanged);
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.whats-new_content-tags'));
    PS.Unsub('post.add', this.postAddBind);
    removeObserver(this, 'args.selectedTag', this.selectTag);
    removeObserver(this, 'args.group.id', this.groupIdChanged);
  }

  get showNoResultsTags() {
    return this.tags.length === 0 && !this.isLoadingTags;
  }

  groupIdChanged() {
    // observer is triggered when group.id is set, not only if it changes,
    // and selecting any hashtag triggers group.id update at some level
    if (this.args.group?.id === this.lastGroupId) {
      return;
    }

    this.tags = A();
    this.tagsSearchPhrase = '';
    this.hashtagsFetched = false;

    this.loadTags(true);
  }

  loadTags(reload) {
    if (this.isLoadingTags || !this.args.group) return;

    const options = {
      maxResults: maxResultsTagsList,
      offset: reload ? 0 : this.tags.length,
    };

    this.isLoadingTags = true;
    this.lastGroupId = this.args.group.id;

    if (this.tagsSearchPhrase) {
      options.term = this.tagsSearchPhrase;
    }

    PostApi.getHashTags(options, 'group', this.args.group.id)
      .then((d) => {
        if (this.isDestroyed || this.isDestroying) return;

        if (d.hashtags && (options.term === this.tagsSearchPhrase || (!options.term && this.tagsSearchPhrase === ''))) {
          if (options.offset === 0) {
            this.tags = A(d.hashtags);
          } else {
            this.tags.pushObjects(d.hashtags);
          }

          if (!this.hashtagsFetched && d.hashtags) {
            this.showTagSearch = this.tags.length;
          }

          this.hashtagsFetched = true;
          if (d.hashtags.length >= maxResultsTagsList) {
            this.scrolling.bindScrollDownElement(this.element.querySelector('.tags-content'), () => this.loadTags());
          } else {
            this.scrolling.unbindScrollDown(this.element.querySelector('.tags-content'));
          }

          if (this.args.selectedTag) {
            this.selectTag();
          }
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.isLoadingTags = false;
      });
  }

  postAdd(data) {
    if (data.post.groupId === this.args.group?.id) {
      if (this.tabActive === 'tags' && data.post.hashTags) {
        this.loadTags(true);
      }
    }
  }

  selectTag() {
    const tag = this.tag;
    let currentTag = this.tags.filter((t) => t.name === tag);
    let selected = this.tags.filter((t) => t.selected);

    if (selected !== currentTag) {
      set(selected, 'selected', false);
    }

    if (!currentTag.selected) {
      set(currentTag, 'selected', true);
    }
  }

  @action
  clearSearchTags() {
    this.searchTags('');
  }

  @action
  searchTags(tagsSearchPhrase) {
    clearTimeout(this.searchTimeout);

    let timeout = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;

      this.tagsSearchPhrase = tagsSearchPhrase;
      this.tags = A();
      this.loadTags();
    }, 500);

    this.searchTimeout = timeout;
  }

  @action
  openPostBoxDialog() {
    const params = {};

    if (this.args.group) {
      params.theme = Theme.GROUP;
      params.target = Theme.GROUP;
      params.groupId = this.args.group?.id;
      params.group = this.args.group;
    } else {
      params.theme = Theme.CONTACTS;
      params.target = Theme.CONTACTS;
    }

    openPostbox(params, this.dynamicDialogs);
  }
}
