/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwDialog extends Component {
  zIndex = 21;

  get style() {
    return `z-index:${this.zIndex};${this.args.style ?? ''}`;
  }

  @action
  checkClickOutside(e) {
    if (this.args.isCloseBlocked) return;

    // make sure that click is exactly on this element and isn't just propagated from a child
    if (e.target.classList.contains('dialog_inner-wrapper')) {
      this.args.onClickOutside?.();
    }
  }
}
