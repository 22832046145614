import Route from 'mewe/routes/route';
import EmberObject from '@ember/object';

import { setTheme } from 'mewe/utils/miscellaneous-utils';
import Session from 'mewe/shared/session';
import { Theme } from 'mewe/constants';
import PostApi from 'mewe/api/post-api';
import { handleOtherStores, getFeed } from 'mewe/fetchers/fetch-feed';
import { toPost } from 'mewe/stores/models/post-model';
import { objectToQueryString } from 'mewe/shared/utils';

// ## Link Format
// Link to post after copying should have format:
// `https://mewe.com/post/show/{post_id}?{queryParams}`

// ## Query params
// `ppId` - id of a page where the post is located
// `eventId` - id of an event where the post is located
// `groupId` - id of a group where the post is located
//  - lack of a query param means that the post is located in the timeline feed

// ## Routing
// - Initially the link leads to `/post/show/:post_id` route. This is intercepted by BE
//   in order to detect if the request is made by link scrapping bot or an user.
// - If request is made by user then BE redirects that request to `/to-front?uri={encoded_post_link}`
//   which FE decodes and redirects again to `/post/show/:post_id`
// - `/post/show/:post_id` route checks if user is logged in or not and either redirects to to
//   `/myworld/single-post/:post_id` to show the post inside the application (logged in user) or
//   stays on `/post/show/:post_id` and fetches the post data in order to show it if it's a
//   publicly available post (not logged in user)

export default class PublicPostRoute extends Route {

  async beforeModel(transition) {
    const params = transition.to.params;
    const queryParams = transition.to.queryParams;

    // in case user navigated to login/signup page and then back to public post
    setTheme('mewe', false);

    await Session.isAuthenticated().then(({ isAuthenticated }) => {
      // for logged in user open the post in application
      if (isAuthenticated) {
        this.transitionTo('app.myworld.single-post', params.post_id, { queryParams: queryParams });
      } else {

        // currently Public Post view supports only page posts and user posts
        const scope = queryParams.ppId ? Theme.PAGE_LINK_ID : Theme.CONTACTS;
        const postItemId = params.post_id;
        const pageId = queryParams.ppId;
        const groupId = queryParams.groupId;
        const eventId = queryParams.eventId;
        const feed = getFeed('single-posts', 'single-posts');

        const postUnavailable = () => {
          window.location.href = `/login?next=/post/show/${params.post_id}&${objectToQueryString(queryParams)}`;
        };

        // group/event post link is not supported in public post view
        if (groupId || eventId) {
          return postUnavailable();
        }

        PostApi.getPostDetails({
          scope: scope,
          pageId: pageId,
          postItemId: postItemId,
        })
          .then((data) => {
            if (!data?.post) {
              return postUnavailable();
            }

            //those flags are needed to set img-public-proxy for avatars
            data.pages?.forEach((page) => {
              page.isOpenPage = true;
            });
            data.groups?.forEach((group) => {
              group.isUnauthView = true;
            });

            handleOtherStores(data);

            data.post.isPublicViewPost = true;
            if (data.post.refPost) {
              data.post.refPost.isPublicViewPost = true;
            }

            if (scope === Theme.CONTACTS) {
              data.post.group = {
                id: Theme.CONTACTS,
                name: __('Home'),
              };
            };

            if (data.post.pageId) {
              data.post.postedByPage = true;
            }

            feed.get('posts').pushObject(toPost(data.post));
          })
          .catch(() => {
            postUnavailable();
          });
      }
    });

  }

  model(params) {
    return EmberObject.create({
      postId: params.post_id,
      singlePosts: getFeed('single-posts', 'single-posts'),
    });
  }
}
