import { helper as buildHelper } from '@ember/component/helper';
function indicatorify(params) {
  let initialUnseenValue = parseInt(params[0], 10);

  if (initialUnseenValue > 0 && initialUnseenValue <= 99) {
    return initialUnseenValue;
  } else if (isNaN(initialUnseenValue) || initialUnseenValue <= 0) {
    return '';
  } else if (initialUnseenValue > 99) {
    return '99+';
  }
}

export default buildHelper(indicatorify);
