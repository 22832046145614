import { computed } from '@ember/object';
import { attr, hasMany } from 'mewe/utils/store-utils/model/attribute';

import Comment from './comment-model';
import Collection from './collection';
import { newCommentsVisible } from 'mewe/constants';

var model = Collection.extend({
  _links: attr('object'),

  total: attr('number', {
    defaultValue: 0,
  }),

  feed: hasMany(Comment),

  // end of properties from backend

  canBeMoreBefore: attr('boolean', {
    defaultValue: function () {
      return this.get('nextPage.length') > 0 && this.get('feed.length') < this.get('total');
    },
  }),

  canBeMoreAfter: attr('boolean', {
    defaultValue: false,
  }),

  showBeforeCounter: attr('boolean', {
    defaultValue: function () {
      return this.get('canBeMoreBefore') && (!this.get('canBeMoreAfter') || this.get('newCommentsCount') > 0);
    },
  }),

  nextPage: attr('string', {
    defaultValue: function () {
      return this.get('_links.nextPage.href') ? decodeURI(this.get('_links.nextPage.href')) : null;
    },
  }),

  isLoadingMoreAfter: attr('boolean', {
    defaultValue: false,
  }),

  isLoadingMoreBefore: attr('boolean', {
    defaultValue: false,
  }),

  newCommentsCount: attr('number', {
    defaultValue: 0,
  }),

  newCommentsToShow: computed('newCommentsCount', function () {
    return this.get('newCommentsCount') - newCommentsVisible;
  }),

  showNewButton: computed('newCommentsCount', function () {
    return this.get('newCommentsCount') > newCommentsVisible;
  }),

  contextCommentId: attr('string', {
    defaultValue: null,
  }),
});

model.reopenClass({
  resourceName: 'comments',
});

export default model;
