import ApiUtil from './api';

class Api extends ApiUtil {

  generateSticker(prompt) {
    return this.post('/stickers/generate', {
      data: JSON.stringify({prompt})
    });
  }

  downloadGeneratedSticker(stickerId) {
    return this.get(`/stickers/generated/${stickerId}/file`);
  }

  checkStickerStatus(stickerId) {
    return this.get(`/stickers/generated/${stickerId}`);
  }

  loadGeneratedSticker() {
    return this.get('/stickers/generated');
  }
}

export default new Api();