import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend({
  permission: attr('string', {
    defaultValue: function () {
      if (!window.Notification) {
        //notifications not supported
        return 'denied';
      }
      return Notification.permission;
    },
  }),
  hideInfoUntil: attr('number', {
    defaultValue: 0,
  }),

  canShowInfo: computed('hideInfoUntil', 'permission', function () {
    return this.get('permission') === 'default' && this.get('hideInfoUntil') < new Date().getTime();
  }),

  disabled: computed('permission', function () {
    return this.get('permission') === 'default';
  }),

  granted: computed('permission', function () {
    return this.get('permission') === 'granted';
  }),

  denied: computed('permission', function () {
    return this.get('permission') === 'denied';
  }),

  getToStorageModel() {
    return {
      hideInfoUntil: this.get('hideInfoUntil'),
    };
  },
});

model.reopenClass({
  resourceName: 'notification',
});

export default model;
