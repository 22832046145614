import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MwContent extends Component {
  @service storyblok;

  get htmlContent() {
    return this.storyblok.richTextResolver.render(this.args.blok.text);
  }
}
