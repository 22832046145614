import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupPermissions from 'mewe/utils/group-permissions-utils';
import { next } from '@ember/runloop';

export default class MwGroupSettingsPermissions extends Component {
  @reads('args.group') group;
  @reads('args.settings') settings;

  @tracked loaded = false;
  @tracked isSavingGroup = false;
  @tracked isSavingPostPermissions = false;
  @tracked isGroupSaveVisible = false;

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
    addObserver(this, 'settings', this, this.groupDataReady);
  }

  willDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
    removeObserver(this, 'settings', this, this.groupDataReady);
  }

  groupDataReady() {
    if (this.settings && !this.loaded && this.group?.id && !this.group.isFetching) {
      this.setRoles();

      this.canReshare = this.group.canReshare;
      next(() => {
        this.loaded = true;
      });
    }
  }

  @computed('group.permissions')
  get permissions() {
    return GroupPermissions.getPermissionsNames(this.group?.permissions);
  }

  @computed('group.{isPublicApply,discoveryOptIn}')
  get showPostSharingPermissions() {
    return this.group.isPublicApply && !this.group.discoveryOptIn;
  }

  @computed('canReshare', 'isSavingPostPermissions')
  get isPostPermissionsSaveVisible() {
    return !this.isSavingPostPermissions && this.canReshare !== this.group.canReshare;
  }

  getRolesArray(roleName) {
    let rolesArray = A();

    for (var role in this[roleName]) {
      if (this[roleName][role]) rolesArray.push(role);
    }

    return rolesArray;
  }

  setRoles() {
    const roles = [];
    this.group.rolesPermissions.forEach((r) => (roles[r.name] = r));

    this.contributor = {
      post: roles.Contributor.permissions.indexOf('post') !== -1,
      comment: roles.Contributor.permissions.indexOf('comment') !== -1,
      invite: roles.Contributor.permissions.indexOf('invite') !== -1,
    };
    this.limited = {
      post: roles.Limited.permissions.indexOf('post') !== -1,
      comment: roles.Limited.permissions.indexOf('comment') !== -1,
      invite: roles.Limited.permissions.indexOf('invite') !== -1,
    };
    this.viewer = {
      post: roles.Viewer.permissions.indexOf('post') !== -1,
      comment: roles.Viewer.permissions.indexOf('comment') !== -1,
      invite: roles.Viewer.permissions.indexOf('invite') !== -1,
    };
  }

  @action
  permissionsChange(role, permission) {
    if (this.loaded) {
      this.isGroupSaveVisible = true;
    }

    this[role][permission] = !this[role][permission];
  }

  @action
  savePostPermissionsSettings() {
    const params = {
      canReshare: this.canReshare,
    };

    this.isSavingPostPermissions = true;

    GroupApi.setGroupData(this.group.id, params)
      .then(() => {
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));
        this.group.set('canReshare', params.canReshare);
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.canReshare = this.group.canReshare;
      })
      .finally(() => {
        this.isSavingPostPermissions = false;
      });
  }

  @action
  saveGroupSettings() {
    const params = {
      permissions: {
        members: { roles: [] }, // required by api even thought empty
        group: {
          contributor: this.getRolesArray('contributor'),
          limited: this.getRolesArray('limited'),
          viewer: this.getRolesArray('viewer'),
        },
      },
    };

    this.isSavingGroup = true;
    this.isGroupSaveVisible = false;

    GroupApi.setGroupData(this.group.id, params)
      .then((data) => {
        this.group.setProperties({
          rolesPermissions: A(data.rolesPermissions),
        });
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();

        // set as not loaded and set settings in groupDataReady function again to rollback unsaved changes
        this.loaded = false;
        this.groupSettingsReady();
      })
      .finally(() => {
        this.isSavingGroup = false;
      });
  }
}
