import Session from 'mewe/shared/session';
import { taskAxios } from 'mewe/services/Task';

export default {
  async uploadImage(file) {
    let formData = new FormData();
    formData.append('file', file);

    let promise = await taskAxios({
      url: '/api/v2/photo/uc',
      method: 'POST',
      data: formData,
      headers: {
        'X-CSRF-Token': Session.getCsrfToken(),
        'content-type': 'application/json; charset=UTF-8',
      },
    });
    return promise.data;
  },
};
