export const isElementInViewport = (el, widthTolerance = 0, heightTolerance = 0) => {
  if (!el) return false;

  var rect = el.getBoundingClientRect();

  return (
    rect.top + heightTolerance >= 0 &&
    rect.left + widthTolerance >= 0 &&
    rect.bottom - heightTolerance <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right - widthTolerance <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isYoutubeLink = (link) => {
  return (
    link.indexOf('://youtube.com') !== -1 ||
    link.indexOf('://www.youtube.com') !== -1 ||
    link.indexOf('m.youtube.com') !== -1 ||
    link.indexOf('://youtu.be') !== -1 ||
    link.indexOf('://www.youtu.be') !== -1
  );
};
