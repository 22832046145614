import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';
import PurchasesStore from 'mewe/stores/purchases-store';
import StoreApi from 'mewe/api/store-api';

export default class MwSettingsPurchases extends Component {
  @service dynamicDialogs;

  @tracked promoCode = '';
  @tracked promoCodeError = false;
  @tracked promoCodeInProgress = false;

  constructor() {
    super(...arguments);
    this.storeState = PurchasesStore.getState();
  }

  @action
  managePayment() {
    this.dynamicDialogs.openDialog('credit-card-info-dialog');
  }

  @action
  cancelSubscription(subscription) {
    dispatcher.dispatch('purchase', 'cancelSubscription', { subscription: subscription });
  }

  @action
  changeStoragePlan() {
    this.dynamicDialogs.openDialog('store/store-item-storage-dialog');
  }

  @action
  promoCodeUpdated() {
    this.promoCodeError = false;
  }

  @action
  applyPromoCode(item) {
    const subscriptionId = item.subscriptionId;

    this.promoCodeInProgress = true;

    StoreApi.applyPromoCodeToSubscription(subscriptionId, this.promoCode)
      .then((data) => {
        const res = data.items[0];

        item.discountDetails = {
          discountPercentage: res.discountPercentage,
          discountDurationMonths: res.discountDurationMonths,
        };

        this.promoCode = '';
      })
      .catch(() => {
        this.promoCodeError = true;
      })
      .finally(() => {
        this.promoCodeInProgress = false;
      });
  }
}
