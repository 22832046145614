import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = createDisplayEditServerModel(['textPlain']).extend({
  text: attr('string', { defaultValue: '' }),
  textPlain: attr('string', { defaultValue: '' }),
  answers: attr('array'),
});

model.reopenClass({
  resourceName: 'group-description',
});

export default model;
