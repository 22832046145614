import Service from '@ember/service';
import StoryblokClient from 'storyblok-js-client';
import i18n from 'i18next';
import config from 'mewe/config';

const TOKENS = {
  public: {
    accessToken: 'nqQ8BUrORXGyI8eTRvU9nAtt',
    version: 'published',
  },
  draft: {
    accessToken: 'WvpMvnI0MDS4VQc3ag1jfgtt',
    version: 'draft',
  },
};

export default class StoryblokService extends Service {
  setup = {
    region: 'us',
    accessToken: this.storyblokVersion.accessToken,
  };

  storyblok = new StoryblokClient(this.setup);

  richTextResolver = this.storyblok.richTextResolver;

  get storyblokVersion() {
    if (config.environment === 'prod' && !config.isQA) {
      return TOKENS.public;
    }

    return TOKENS.draft;
  }

  async loadStory(path) {
    try {
      const response = await this.storyblok.get(`cdn/stories/${path}`, {
        version: this.storyblokVersion.version,
        language: i18n.language,
      });
      return response.data.story;
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  }
}
