/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { alias, sort } from '@ember/object/computed';
import { each } from 'lodash';
import { A } from '@ember/array';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

import AccountApi from 'mewe/api/account-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import Session from 'mewe/shared/session';
import axios from 'axios';
import { getMonthNames } from 'mewe/utils/event-utils';
import isUndefined from 'mewe/utils/isUndefined';
import { isDefined, trimAndLower } from 'mewe/utils/miscellaneous-utils';
import { meweHandleRegex, dsnpHandleRegex } from 'mewe/shared/utils';
import { TaskStates } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import PS from 'mewe/utils/pubsub';

class Errors {
  @tracked missingName = false;
  @tracked missingSurname = false;
  @tracked invalidDate = false;
  @tracked handleError = false;
  @tracked emailTaken = false;
  @tracked emailInvalid = false;
  // password errors
  @tracked invalidPassword = false;
  @tracked invalidSmsCode = false;
  @tracked newPasswordLength = false;
  @tracked passwordMatch = false;
  @tracked invalidAuthPassword = false;
}

export default class AppSettingsAccount extends Component {
  @service dynamicDialogs;
  @service account;
  @service tasks;

  @alias('account.activeUser.settings.account') accountSettings;

  // general //
  @tracked errors;
  @tracked attrsInitialised;

  // avatar
  @tracked task;
  @tracked avatarBlob;
  @tracked dropdownMenuOpenedAvatar;
  photoDialogTitles = {
    choosePhoto: __('Choose Profile Photo'),
    cropPhoto: __('Edit Profile Photo'),
  };

  // name
  @tracked firstName;
  @tracked lastName;
  @tracked savingName;
  @tracked allowNameChange;

  // emails
  @tracked emails;
  @tracked emailToAdd;
  @tracked isEmailsChangeToSave;
  @tracked savingEmails;
  @tracked passwordToAuth;

  // password
  @tracked currentPassword;
  @tracked newPassword;
  @tracked repeatPassword;
  @tracked savingPassword;
  @tracked showForgotPassword;
  @tracked revokeSessions;
  @tracked smsCodeRequired;
  @tracked smsCode;
  @tracked otpButtonDisabled;
  @tracked otpAddress;

  // handle
  @tracked savingHandle;
  @tracked handleHint;
  @tracked handleEditValue;

  // birthday
  @tracked savingBirthDate;
  @tracked allowSeeBirthday;

  // other
  @tracked includeCurrentSession = false;
  @tracked editedSetting;

  minPasswordLength = 6;
  minNewPasswordLength = 8;
  maxEmailsCount = 5;

  constructor() {
    super(...arguments);
    this.task = this.tasks.getTask('photo.avatar.contacts');
    this.selectPhotoBind = this.selectPhoto.bind(this);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    // to prevent SG-9700
    if (this.primaryEmailChanged) {
      this.resetEmailSettings();
    }
  }

  @computed('accountSettings', 'attrsInitialised', 'account.activeUser')
  get isLoaded() {
    if (this.accountSettings && this.account.activeUser && !this.attrsInitialised) {
      this.setInitialAttrs();
    }
    return isDefined(this.accountSettings);
  }

  get confirmEmailChangePlaceholder() {
    if (this.isDsnpUser) {
      return __(`Confirmation code`);
    } else {
      return __(`Enter current password to confirm changes`);
    }
  }

  get confirmEmailChangeInfo() {
    if (this.isDsnpUser) {
      return __(`We sent you a confirmation code to <b>{otpAddress}</b>`, { otpAddress: this.otpAddress });
    } else {
      return __(`Enter current password to confirm changes and hit Save`);
    }
  }

  get isDsnpUser() {
    return this.account.activeUser?.dsnpHandle;
  }

  setInitialAttrs() {
    this.errors = new Errors();

    // name
    this.firstName = this.account.activeUser.firstName;
    this.lastName = this.account.activeUser.lastName;
    // emails
    this.emails = this.accountSettings.emailAddresses ? A(this.accountSettings.emailAddresses) : A();
    this.emailsToAdd = A();
    this.emailsToRemove = A();
    this.emailToAdd = '';
    this.passwordToAuth = '';
    this.primaryEmailChanged = false;
    this.isEmailsChangeToSave = false;
    // password
    this.currentPassword = '';
    this.newPassword = '';
    this.repeatPassword = '';
    this.smsCode = '';
    this.revokeSessions = false;
    // birthday
    this.days = A();
    for (let i = 1; i <= 31; i++) this.days.push(i);
    this.months = getMonthNames(this.account.activeUser.jsLocale);
    this.birth = this.accountSettings.birth;
    this.allowSeeBirthday = this.accountSettings.birth.allowSeeBirthday;
    // handle
    this.handleEditValue = this.account.activeUser.handleDisplay.core;

    set(this, 'attrsInitialised', true);
  }

  @computed('account.activeUser._links.avatar.href')
  get avatarHref() {
    return (
      EnvironmentUtils.getImgHost(true) +
      this.account.activeUser?._links?.avatar?.href?.replace('{imageSize}', '150x150')
    );
  }

  @computed('args.model.sessions')
  get sessions() {
    return this.args.model?.sessions
      ?.filter((session) => session.expires)
      .map((session) => {
        session.isMobile = session.name ? !!~session.name.indexOf('MeWe App') : false;
        return session;
      });
  }

  sessionsSorting = ['expires:desc'];
  @sort('sessions', 'sessionsSorting') sessionsSorted;

  @computed('primaryEmail', 'account.activeUser.primaryPhoneNumber')
  get showForgotPassword() {
    return false; //SG31005 this.primaryEmail || this.account.activeUser.primaryPhoneNumber;
  }

  @computed('accountSettings.birth.{month,day}')
  get birthdayText() {
    const month = this.accountSettings?.birth?.month,
      day = this.accountSettings?.birth?.day;

    if (month && day) {
      const d = new Date(Date.UTC(new Date().getUTCFullYear(), month - 1, day, 0, 0, 0));

      return d.toLocaleDateString(this.account.activeUser.jsLocale, {
        day: 'numeric',
        month: 'long',
        timeZone: 'UTC',
      });
    } else {
      return '';
    }
  }

  @computed('accountSettings.birth.month', 'changedMonthId')
  get monthId() {
    if (!this.changedMonthId) {
      const monthId = this.accountSettings?.birth?.month;
      return monthId ? monthId - 1 : null;
    } else {
      return this.changedMonthId - 1;
    }
  }

  @computed('accountSettings.birth.month', 'changedMonthId')
  get monthName() {
    if (this.changedMonthId) {
      return this.months[this.changedMonthId - 1];
    } else {
      const monthId = this.accountSettings?.birth?.month;
      return monthId ? this.months[monthId - 1] : __('Select one');
    }
  }

  @computed('accountSettings.birth.day', 'changedDayNumber')
  get dayNumber() {
    const dayText = this.accountSettings?.birth?.day;
    if (!this.changedDayNumber) {
      return dayText ? dayText : __('Select one');
    } else {
      return this.changedDayNumber;
    }
  }

  @computed('accountSettings.emailAddresses')
  get primaryEmail() {
    return A(this.accountSettings?.emailAddresses).find((e) => e.primary);
  }

  @computed(
    'account.birth.{allowSeeBirthday,day,month}',
    'accountSettings.birth.{allowSeeBirthday,day,month}',
    'allowSeeBirthday',
    'changedDayNumber',
    'changedMonthId',
    'errors.{invalidDate,missingFullDate}'
  )
  get isSaveBirthdayDisabled() {
    if (!this.accountSettings?.birth) {
      return true;
    }

    const monthNotChanged = !this.changedMonthId || this.changedMonthId === this.accountSettings.birth.month;
    const dayNotChanged = !this.changedDayNumber || this.changedDayNumber === this.accountSettings.birth.day;
    const birthdayVisibilityNotChanged = this.allowSeeBirthday === this.accountSettings.birth.allowSeeBirthday;

    return (
      this.errors.invalidDate ||
      this.errors.missingFullDate ||
      (monthNotChanged && dayNotChanged && birthdayVisibilityNotChanged)
    );
  }

  @computed('task.state')
  get isUploadingAvatar() {
    return this.task.state === TaskStates.LOADING;
  }

  @computed('accountSettings.publicLinkIdUpdateWaitPeriod')
  get editHandleTimoutDays() {
    const secondsLeft = this.accountSettings.publicLinkIdUpdateWaitPeriod;
    const daysLeft = Math.ceil(secondsLeft / (24 * 60 * 60)); // ceil to avoid 0 days left, 1 day is minimum
    return secondsLeft ? daysLeft : 0;
  }

  changeEditedSetting(setting) {
    if (setting === 'email' && this.isDsnpUser) {
      return;
    }

    this.editedSetting === setting ? (this.editedSetting = null) : (this.editedSetting = setting);

    if (setting === 'birthday') {
      next(() => {
        this.validateBirthDate();
      });
    }

    this.setInitialAttrs();
  }

  validateAuthPassword() {
    if (this.passwordToAuth.length > 5) {
      this.errors.invalidAuthPassword = false;
      return true;
    } else {
      this.errors.invalidAuthPassword = true;
      return false;
    }
  }

  validatePassword() {
    let isOk = true;

    if (this.smsCodeRequired) {
      if (!this.smsCode.length) {
        this.errors.invalidSmsCode = true;
        isOk = false;
      }
    } else if (!this.currentPassword || this.currentPassword.length < this.minPasswordLength) {
      this.errors.invalidPassword = true;
      isOk = false;
    } else {
      this.errors.invalidPassword = false;
    }

    if (!this.newPassword || this.newPassword.length < this.minNewPasswordLength) {
      this.errors.newPasswordLength = true;
      isOk = false;
    } else {
      this.errors.newPasswordLength = false;
    }

    if (this.newPassword !== this.repeatPassword) {
      this.errors.passwordMatch = true;
      isOk = false;
    } else {
      this.errors.passwordMatch = false;
    }

    return isOk;
  }

  @action
  opendEditDropdownAvatar() {
    this.dropdownMenuOpenedAvatar = true;
  }

  @action
  closeEditDropdownAvatar() {
    this.dropdownMenuOpenedAvatar = false;
  }

  @action
  submitAvatar() {
    if (this.avatarBlob && this.avatarParams) {
      dispatcher.dispatch('contact', 'setAvatar', this.avatarBlob, this.avatarParams, this.task);
    }

    this.changeEditedSetting('photo');

    this.avatarBlob = null;
  }

  selectPhoto(blob, params) {
    this.avatarBlob = blob;
    this.avatarParams = params;
  }

  @action
  validateNameLive() {
    if (this.errors.missingName || this.errors.missingSurname) {
      this.validateName();
    }
  }

  validateName() {
    let isOk = true;

    if (this.firstName.length === 0) {
      this.errors.missingName = true;
      isOk = false;
    } else {
      this.errors.missingName = false;
    }

    if (this.lastName.length === 0) {
      this.errors.missingSurname = true;
      isOk = false;
    } else {
      this.errors.missingSurname = false;
    }

    return isOk;
  }

  @action
  validateSmsCodeLive() {
    if (this.smsCode.length) {
      this.invalidSmsCode = false;
    }
  }

  @action
  validatePasswordLive() {
    if (this.errors.newPasswordLength || this.errors.passwordMatch || this.errors.invalidPassword) {
      this.validatePassword();
    }
  }

  @action
  settingClick(setting) {
    if (this.isDsnpUser && setting === 'email') {
      // we don't allow editing email for DSNP users
      return false;
    }

    // don't allow opening handle edit if it's pending update (still allow closing it)
    if (setting === 'handle' && this.editedSetting !== 'handle' && this.isPendingHandleUpdate) {
      return;
    }

    if (this.primaryEmailChanged) {
      this.resetEmailSettings();
    }

    if (setting === 'password') {
      this.smsCode = null;
      this.currentPassword = null;
      this.newPassword = null;
      this.repeatPassword = null;
    }

    this.changeEditedSetting(setting);
  }

  @action
  submitEmails() {
    if (this.isEmailsChangeToSave) {
      if (this.validateAuthPassword()) {
        this.savingEmails = true;
        this.saveEmails();
      } else {
        this.errors.invalidAuthPassword = true;
        document.querySelector('.settings-account_email_new .new-email-password-confirmation')?.focus();
        FunctionalUtils.error(__('Enter current password to confirm changes'));
      }
    } else {
      this.changeEditedSetting('email');
      this.savingEmails = false;
    }
  }

  saveEmails() {
    const emailsToSave = {
      toRemove: this.emailsToRemove,
      toAdd: this.emailsToAdd,
      primary: this.primaryEmail?.email,
    };

    if (this.otpAddress) {
      emailsToSave.otp = this.passwordToAuth;
    } else {
      emailsToSave.password = this.passwordToAuth;
    }

    AccountApi.setEmails(emailsToSave)
      .then(() => {
        FunctionalUtils.info(__('Changes in your account have been saved'));

        each(this.emails, (e) => {
          if (e.pwdNeeded) {
            set(e, 'pwdNeeded', false);
          }
        });

        dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.account.emailAddresses', this.emails);
        this.changeEditedSetting('email');

        this.account.activeUser.primaryEmail = emailsToSave.primary;
        this.primaryEmailChanged = false;
      })
      .catch((data) => {
        let msg;
        if (data.taken) {
          if (emailsToSave.toAdd.length === 1) {
            msg = __('Sorry - the email address {email} is already taken', {
              email: emailsToSave.toAdd[0],
            });
          } else {
            msg = __('Sorry - the email addresses are already taken');
          }
          FunctionalUtils.error(msg);
        } else if (data.data && data.data.message === 'Wrong password') {
          this.errors.invalidAuthPassword = true;
        } else {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        next(() => {
          this.savingEmails = false;
        });
      });
  }

  checkEmailsChangeToSave() {
    this.otpButtonDisabled = true;

    const value = this.emailsToRemove.length || this.emailsToAdd.length || this.primaryEmailChanged;
    this.isEmailsChangeToSave = value;

    // web3 users don't have password, we send OTP instead
    if (this.isDsnpUser) {
      AccountApi.sendOtpCode()
        .then((data) => {
          this.otpAddress = data.email || data.phoneNumber;

          /* 10 seconds timeout to prevent abusing OTP */
          this.otpTimeout = setTimeout(() => (this.otpButtonDisabled = false), 10000);
        })
        .catch(() => {
          FunctionalUtils.error(__(`Something went wrong while sending code. Please try again later.`));
          this.otpButtonDisabled = false;
        });
    }
  }

  resetEmailSettings() {
    each(this.emails, (e) => {
      set(e, 'primary', e.email === this.account.activeUser.primaryEmail);
    });

    this.primaryEmailChanged = false;
    this.checkEmailsChangeToSave();
  }

  @action
  submitName() {
    if (this.validateName()) {
      this.savingName = true;

      if (this.firstName !== this.account.activeUser.firstName || this.lastName !== this.account.activeUser.lastName) {
        this.saveName();
      } else {
        this.changeEditedSetting('name');
        this.savingName = false;
      }
    }
  }

  saveName() {
    AccountApi.setData({
      firstName: this.firstName,
      lastName: this.lastName,
    })
      .then(() => {
        FunctionalUtils.info(__('Changes in your account have been saved'));
        location.reload();
      })
      .catch(() => {
        FunctionalUtils.error(__('An error occurred'));
      })
      .finally(() => {
        next(() => {
          this.savingName = false;
        });
      });
  }

  @action
  savePassword() {
    if (!this.validatePassword()) {
      return;
    }

    this.savingPassword = true;

    const revokeAllSessions = this.revokeSessions;
    const params = {
      newPassword: this.newPassword,
    };

    if (this.smsCodeRequired) {
      params.code = this.smsCode;
    } else {
      params.oldPassword = this.currentPassword;
    }

    AccountApi.setPassword(params, revokeAllSessions)
      .then(() => {
        FunctionalUtils.info(__('Password set successfully'));
        this.changeEditedSetting('password');
      })
      .then(() => {
        if (revokeAllSessions) {
          window.location.reload();
        }

        this.smsCodeRequired = false;
      })
      .catch((data) => {
        if (this.smsCodeRequired) {
          const resp = data.data;
          if (resp && resp.message === 'Wrong validation code') {
            this.errors.invalidSmsCode = true;
          }
        } else {
          this.errors.invalidPassword = true;
        }
      })
      .finally(() => {
        this.smsCode = null;
        this.currentPassword = null;
        this.newPassword = null;
        this.repeatPassword = null;

        next(this, function () {
          this.savingPassword = false;
        });
      });
  }

  // vvhandle related stuff vv //
  get handleTitle() {
    return this.isDsnpUser ? __('Universal Handle') : __('Username');
  }

  get handleRequirementsText() {
    return this.isDsnpUser
      ? __(
          `Your handle must be between 3-20 characters and can only consist of Latin-based lower case letters, numbers, and underscores.`
        )
      : __(`Username must be between 4-16 characters, can only consist letters, numbers and underscores.`);
  }

  get saveHandleDisabled() {
    const minLength = this.isDsnpUser ? 3 : 4;
    return this.savingHandle || !this.accountSettings.canUpdatePublicLinkId || this.handleEditValue.length < minLength;
  }

  get isPendingHandleUpdate() {
    return this.account.activeUser.dsnpHandle?.length && !this.account.activeUser.handleDisplay.suffix?.length;
  }

  @action
  handleChanged() {
    this.handleEditValue = trimAndLower(this.handleEditValue);
    this.errors.handleError = false;
  }

  @action
  submitHandle() {
    const newHandle = trimAndLower(this.handleEditValue);
    const handleRegex = this.isDsnpUser ? dsnpHandleRegex : meweHandleRegex;

    if (!handleRegex.test(newHandle)) {
      this.errors.handleError = true;
      this.handleHint = this.handleRequirementsText;
      return false;
    }

    // don't save non-DSNP handle if it didn't change. DSNP handle still can be changed in order to get a new suffix
    if (!this.isDsnpUser && newHandle === this.account.activeUser.handleDisplay.core) {
      this.changeEditedSetting('handle');
      this.savingHandle = false;
      return;
    }

    if (isUndefined(this.account.activeUser.badges.verified)) {
      this.saveHandle();
    } else {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        okButtonText: this.isDsnpUser ? __('Update Handle') : __('Change Username'),
        cancelButtonText: __('Cancel'),
        title: __('Verification Status'),
        message: this.isDsnpUser
          ? __('Changing your Universal Handle will remove your verification badge.')
          : __('If you change your username your account will no longer be verified.'),
        imagePath: '/assets/icons/badge_verified.png',
        classImg: 'mt-24 mb-8',
        imgHeight: 24,
        imgWidth: 24,
        onConfirm: () => {
          this.saveHandle();
        },
      });
    }
  }

  saveHandle() {
    this.savingHandle = true;

    const newHandle = trimAndLower(this.handleEditValue);
    const oldHandle = this.account.activeUser.publicLinkId;

    const failCallback = (errorCode = '') => {
      // 120 - taken,
      // 122 - unavailable,
      // 123 - "same handle" (not sure what it means but BE knows such usecase and it's not about saving the same string)
      if (errorCode === 120 || errorCode === 122 || errorCode === 123) {
        this.errors.handleError = true;
        this.handleHint = __('Please use a different handle. This one is forbidden or taken.');
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    };

    const successCallback = () => {
      dispatcher.dispatch('client-data', 'saveClientPreference', 'publicLinkId', newHandle);
      dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.account.publicLinkId', newHandle);
      dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.account.canUpdatePublicLinkId', false);
      dispatcher.dispatch(
        'client-data',
        'saveClientPreference',
        'settings.account.publicLinkIdUpdateWaitPeriod',
        30 * 24 * 60 * 60
      ); // 30 days

      if (this.isDsnpUser) {
        dispatcher.dispatch('client-data', 'saveClientPreference', 'dsnpHandle', newHandle);
      }

      this.changeEditedSetting('handle');
    };

    const finallyCallback = () => {
      this.savingHandle = false;
    };

    if (this.isDsnpUser) {
      AccountApi.setDsnpHandle({ inviteLinkId: newHandle })
        .then((res = {}) => {
          if (res.errorCode) {
            failCallback(res.errorCode);
          } else {
            successCallback();
          }
        })
        .catch(() => {
          FunctionalUtils.showDefaultErrorMessage();
        })
        .finally(finallyCallback);

      // prepare for intercepting handle change result
      PS.Sub('msa.handle.change.result', (data = {}) => {
        // if no handle in response (change has failed) then set back the old one
        const finalHandle = data.handle || oldHandle;

        dispatcher.dispatch('client-data', 'saveClientPreference', 'dsnpHandle', finalHandle);
        dispatcher.dispatch('client-data', 'saveClientPreference', 'publicLinkId', finalHandle);
        dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.account.publicLinkId', finalHandle);

        if (!data.handle?.length) {
          failCallback(data.errorCode);
        }
      });
    } else {
      AccountApi.setData({ publicLinkId: newHandle })
        .then(() => {
          successCallback();

          if (!isUndefined(this.account.activeUser.badges.verified)) {
            location.reload();
          }
        })
        .catch((error) => {
          if (error?.status === 409) {
            const available = error.data?.available;
            this.handleHint = __('The username is taken or invalid. We suggest you use: {available}', {
              available: available,
            });
            this.errors.handleError = true;
          } else if (error?.status === 400) {
            if (error.data?.errorCode === 121) {
              this.handleHint = __(
                'You can not change your username right now because you’ve already changed it within the last 60 days.'
              );
            } else {
              this.handleHint = __('The username is taken or invalid.');
            }
            this.errors.handleError = true;
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        })
        .finally(finallyCallback);
    }
  }

  validateBirthDate() {
    let year = 2012;
    let month = this.monthId;
    let day = this.dayNumber;
    let date = new Date(year, month, day);

    // without error, because one of fields was not set yet at all
    if (month === null || day === __('Select one')) {
      set(this, 'errors.missingFullDate', true);
      return false;
    } else {
      set(this, 'errors.missingFullDate', false);
    }

    if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
      set(this, 'errors.invalidDate', false);
      return true;
    } else {
      set(this, 'errors.invalidDate', true);
      return false;
    }
  }

  @action
  submitBirthDate() {
    if (this.validateBirthDate()) {
      this.savingBirthDate = true;

      if (
        this.changedDayNumber !== this.accountSettings.birth.day ||
        this.changedMonthId !== this.accountSettings.birth.month ||
        this.allowSeeBirthday !== this.accountSettings.birth.allowSeeBirthday
      ) {
        this.saveBirthDate();
      } else {
        this.changeEditedSetting('birthday');
        this.savingBirthDate = false;
      }
    } else {
      this.changeEditedSetting('birthday');
      this.savingBirthDate = false;
    }
  }

  @action
  saveBirthDate() {
    const params = {
      birthDay: this.changedDayNumber || this.accountSettings.birth.day,
      birthMonth: this.changedMonthId || this.accountSettings.birth.month,
      allowSeeBirthday: this.allowSeeBirthday,
    };

    AccountApi.setData(params)
      .then(() => {
        FunctionalUtils.info(__('Changes in your account have been saved'));

        dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.account.birth', {
          day: params.birthDay,
          month: params.birthMonth,
          allowSeeBirthday: params.allowSeeBirthday,
        });
        this.changeEditedSetting('birthday');
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        next(() => {
          this.savingBirthDate = false;
        });
      });
  }

  @action
  changeBirth(type, index) {
    set(this, type, parseInt(index, 10));
    this.validateBirthDate();
  }

  @action
  changePrimaryEmail(email) {
    each(this.emails, function (e) {
      set(e, 'primary', false);
    });

    set(email, 'primary', true);

    this.primaryEmailChanged = true;
    this.checkEmailsChangeToSave();
  }

  @action
  addEmail() {
    let emailToAdd = this.emailToAdd;
    let emailsToRemove = this.emailsToRemove;
    let oldeEmailIndex = emailsToRemove.indexOf(emailToAdd);

    if (this.testingEmailToAdd) {
      this.addEmailPending = true; // call this function after api request for email validation
      return;
    }

    if (!emailToAdd || !emailToAdd.length || this.errors.emailInvalid || this.errors.emailTaken) {
      return;
    }

    if (oldeEmailIndex !== -1) {
      emailsToRemove.splice(oldeEmailIndex, 1);
      this.emailsToRemove = emailsToRemove;
    } else {
      this.emails.pushObject({
        email: emailToAdd,
        validationSent: false,
        primary: false,
        pwdNeeded: true,
      });
    }

    if (oldeEmailIndex === -1) {
      this.emailsToAdd.push(emailToAdd);
    }

    this.emailToAdd = '';

    this.checkEmailsChangeToSave();
  }

  @action
  removeEmail(email) {
    let emailsToAdd = this.emailsToAdd;
    let newEmailIndex = emailsToAdd.indexOf(email.email);

    if (newEmailIndex !== -1) {
      emailsToAdd.splice(newEmailIndex, 1);
      this.emailsToAdd = emailsToAdd;
    } else {
      this.emailsToRemove.push(email.email);
    }

    this.emails.removeObject(email);

    this.checkEmailsChangeToSave();
  }

  @action
  resendValidation(email) {
    AccountApi.sendValidationEmail(email.email).finally(() => {
      FunctionalUtils.info(__('Validation Email has been sent to {email}', { email: email.email }));
      set(email, 'validationSent', true);
    });
  }

  @action
  checkEmail() {
    let emailToAdd = this.emailToAdd;

    if (!emailToAdd.length) {
      this.errors.emailInvalid = false;
      this.errors.emailTaken = false;
      return;
    }

    // basic checking to avoid calling api if it's clear that address is wrong
    if (emailToAdd.length < 6 || emailToAdd.indexOf('@') === -1 || emailToAdd.indexOf('.') === -1) {
      this.errors.emailInvalid = true;
      return;
    }

    this.testingEmailToAdd = true;

    AccountApi.checkEmailAvailability(emailToAdd).then((data) => {
      this.errors.emailInvalid = false;
      this.errors.emailTaken = false;

      if (data.errorCode) {
        if (data.errorCode === 100) {
          this.errors.emailInvalid = true;
        } else if (data.errorCode === 109) {
          this.errors.emailTaken = true;
          FunctionalUtils.error(__('Email address is already taken.'));
        }
      }

      this.testingEmailToAdd = false;
      if (this.addEmailPending && !data.errorCode) {
        this.addEmail();
      }

      this.addEmailPending = false;
    });
  }

  @action
  revokeSession(id) {
    AccountApi.revokeSession(id)
      .then(() => {
        const route = getOwner(this).lookup('route:app.settings');
        if (route) {
          route.send('reloadModel');
        }
      })
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }

  @action
  revokeAllSessions() {
    AccountApi.revokeAllSessions(this.includeCurrentSession)
      .then(() => {
        const route = getOwner(this).lookup('route:app.settings');
        if (route) {
          route.send('reloadModel');
        }
      })
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }

  @action
  forgotPassword() {
    let params;
    let url = EnvironmentUtils.getApiHost() + '/api/v2/auth/';

    if (this.primaryEmail) {
      params = {
        email: this.primaryEmail.email,
      };

      url += 'send-reset-email';

      axios({
        url: url,
        method: 'POST',
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
          'content-type': 'application/json; charset=UTF-8',
        },
        data: JSON.stringify(params),
      })
        .then(() => {
          FunctionalUtils.info(
            __('An email with a reset password link has been sent to {email}', { email: params.email })
          );
        })
        .catch((error) => {
          let response = error.response;
          if (response.data && response.data.blocked) {
            FunctionalUtils.info(__(`You've exceeded the number of attempts. Please try again later`));
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        });
    } else if (this.account.activeUser.primaryPhoneNumber) {
      url += 'reset-pass';

      params = {
        phoneNumber: this.account.activeUser.primaryPhoneNumber,
      };

      axios({
        url: url,
        method: 'POST',
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
          'content-type': 'application/json; charset=UTF-8',
        },
        data: JSON.stringify(params),
      })
        .then((res) => {
          let data = res.data;
          if (data && data.limitReached === true) {
            FunctionalUtils.error(__(`You've exceeded the number of attempts. Please try again later`));
          } else {
            this.smsCodeRequired = true;
            FunctionalUtils.info(
              __(
                `Thanks! If an account exists with this phone number, we’ll send you a text message with a code to reset your password`
              )
            );
          }
        })
        .catch((error) => {
          let data = error.response.data;
          if (data && data.blocked) {
            FunctionalUtils.error(__(`You've exceeded the number of attempts. Please try again later`));
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        });
    }
  }

  @action
  authPasswordChanged() {
    if (this.passwordToAuth.length > 5) {
      this.errors.invalidAuthPassword = false;
    }
  }
}
