/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import AccountApi from 'mewe/api/account-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import storage from 'mewe/shared/storage';
import { inject as service } from '@ember/service';

export default class MwSettingsPreferences extends Component {
  @service analytics;

  @tracked isSaving = false;
  @tracked autoPlayVideo = storage.get(storage.keys.videoFeedAutoPlaySetting) === true ?? false;
  @tracked allowNsfwContent = storage.get(storage.keys.allowNsfwContentSetting) === true ?? false;

  @action
  async changeAutoPlaySetting() {
    this.isSaving = true;
    try {
      this.autoPlayVideo = !this.autoPlayVideo;
      const config = {
        autoPlayVideos: this.autoPlayVideo,
        allowNsfwContent: this.allowNsfwContent,
      };
      await AccountApi.setPreferences(config);
      storage.set(storage.keys.videoFeedAutoPlaySetting, this.autoPlayVideo);
      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
      FunctionalUtils.defaultErrorMessage();
    }
  }

  @action
  async updateNSFWSetting() {
    this.isSaving = true;
    try {
      this.allowNsfwContent = !this.allowNsfwContent;
      const config = {
        autoPlayVideos: this.autoPlayVideo,
        allowNsfwContent: this.allowNsfwContent,
      };
      await AccountApi.setPreferences(config);
      storage.set(storage.keys.allowNsfwContentSetting, this.allowNsfwContent);
      this.analytics.sendEvent('buttonClicked', 'NSFW Content Setting Toggled');
      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
      FunctionalUtils.defaultErrorMessage();
    }
  }
}
