import { assert } from '@ember/debug';
import { isNumber } from 'lodash';
/**
 * Makes it possible to page through a scrollable list using up and down arrow keys, enter will trigger the action set to 'keyboardListElementActionName'
 * Paging starts from the currently hovered over element or the first element in the list
 *
 * Usage: {{# each yourItems as |item index| }} {{ if (compare index keyboardListActiveIndex) 'your-active-class' }}
 * and in extending component:
 * keyboardListItems: function() { return this.get('yourItems'); }.property('yourItems')
 */

export let KeyboardSelectListActions = {
  keyboardListItems() {
    assert('KeyboardSelectListActions.keyboardSelectListItems: override this function with a .property().');
  },

  resetKeyboardList() {
    this.set('keyboardListActiveIndex', -1);
  },

  init() {
    this._super(...arguments);

    if (!this.keyboardListElementSelector) {
      this.set('keyboardListElementSelector', 'li');
    }
  },

  keyDown(e) {
    if (e.keyCode === 38) {
      // arrow up
      this.onArrowUp();

      e.preventDefault();
      return false;
    } else if (e.keyCode === 40) {
      // arrow down
      this.onArrowDown();

      e.preventDefault();
      return false;
    } else if (e.keyCode === 13) {
      // enter
      let action = this.keyboardListElementActionName;

      if (action) {
        let selected = this.keyboardListItems[this.keyboardListActiveIndex];
        if (selected) this.send(action, selected, this.keyboardListActiveIndex);

        e.preventDefault();
        return false;
      }
    } else {
      this.resetKeyboardList();
    }

    this._super(...arguments);
  },

  scrollIndexIntoView(index) {
    if (!index) return;
    const elem = this.element.querySelectorAll(this.keyboardListElementSelector)[index];

    if (!elem) return;

    elem.scrollIntoView({
      block: 'center',
    });
  },

  getActiveListItemIndex() {
    let index = this.keyboardListActiveIndex;

    if (!isNumber(index)) return -1;

    return index;
  },

  onArrowUp() {
    this.setNeighborAsActiveListElement(false);
  },

  onArrowDown() {
    this.setNeighborAsActiveListElement(true);
  },

  setNeighborAsActiveListElement(setToNext) {
    let index = this.getActiveListItemIndex();

    if (setToNext) {
      index += 1;
      if (index >= this.get('keyboardListItems.length')) index = this.get('keyboardListItems.length') - 1;
    } else {
      index -= 1;
      if (index < 0) index = 0;
    }

    this.set('keyboardListActiveIndex', index);
    this.scrollIndexIntoView(index);
  },
};
