import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Home/MwPhoneInput',
  component: 'MwPhoneInput',
};

const Template = (params, context) => ({
  template: hbs`<Home::MwPhoneInput />`
});

export const Default = Template.bind({});
