import Api from 'mewe/api/home-api';
import { toWrapper } from 'mewe/stores/models/wrapper-model';
import { storeCollectionItems as store, pushCollectionItems as push, setNextPage } from 'mewe/stores/models/collection';
import { ds } from 'mewe/stores/ds';
import { fetchUtil, unsetFlag } from 'mewe/fetchers/utils';
import { tap, emberSet } from 'mewe/utils/fp';
import { storePages } from 'mewe/fetchers/fetch-pages';

const isLoadingMore = (params) => params && params.nextPage;

export const fetchWrappers = (scope, params) =>
  fetchUtil(Api.getWrappers(scope, params), ds.wrappers)
    .then(tap(setNextPage(ds.wrappers)))
    .then(tap(handleOtherStores))
    .then((data) => (isLoadingMore(params) ? push : store)(ds.wrappers, data.wrappers.map(toWrapper)))
    .then(unsetFlag(ds.wrappers))
    .then(emberSet(ds.wrappers, { scope: scope }));

const handleOtherStores = (data) => {
  if (data.pages) storePages(data.pages);
  return data;
};
