import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

import { animateScrollTo } from 'mewe/utils/elements-utils';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';

const State = {
  BROWSE: 'Browse',
  SEARCH: 'Search',
  CATEGORIES: 'Categories',
};

export default class BrowseCommunitiesBase extends Component {
  @service dynamicDialogs;

  maxSearchResults = 10;
  defaultState = State.CATEGORIES;

  @tracked searchInputExpanded = false;
  @tracked searchValue = '';

  constructor() {
    super(...arguments);
    this.options = this.args.options;
  }

  @action
  onInsert(element) {
    this.element = element;
    animateScrollTo(document.querySelector('html'));

    this.setupInfiniteScroll();
    this.setupModel();
  }

  @tracked _state;
  get state() {
    if (this.searchValue) {
      return State.SEARCH;
    }

    if (this._state) {
      return this._state;
    }

    return this.defaultState;
  }

  @action
  searchChanged() {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;

      if (this.searchValue) {
        this.doSearch({
          query: this.searchValue,
          pageSize: this.maxSearchResults,
        });
      } else {
        this.clearSearch();
      }
    }, 500);
  }

  scrollToTop() {
    if (this.args.isDialog) {
      this.element.querySelector('.browse-communities_rows, .browse-communities_grid').scrollTop = 0;
    } else {
      window.scrollTo(0, 0);
    }
  }

  @action
  onSearchFocus() {
    this.searchInputExpanded = true;
  }

  @action
  onSearchBlur() {
    if (!this.searchValue) {
      next(() => {
        this.searchInputExpanded = false;
      });
    }
  }

  @action
  openInterestsPicker() {
    openFtueRecommendations(this.dynamicDialogs, 'public-directory');
    this.close();
  }

  @action
  toggleMobileSidebar() {
    this.mobileSidebarVisible = !this.mobileSidebarVisible;
  }

  @action
  close() {
    this.args.close?.();
  }
}
