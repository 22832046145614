import Component from '@glimmer/component';
import { action } from '@ember/object';

import dispatcher from 'mewe/dispatcher';
import { inject as service } from '@ember/service';

export default class SidebarLeftGroup extends Component {
  @service dynamicDialogs;

  @action
  openReportGroupDialog() {
    dispatcher.dispatch('group', 'reportGroup', { group: this.args.group });
  }

  @action
  openGroupCreationDialog() {
    this.dynamicDialogs.openDialog('group-create');
  }
}
