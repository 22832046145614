import EmberObject, { observer, computed } from '@ember/object';
import { emailRegex } from 'mewe/shared/utils';

export default EmberObject.extend({
  value: '',

  errors: {},

  validate: function () {},

  validateSimpleInput: function (validator) {
    validator.set(`errors.${validator.name}.value`, ('' || validator.value).trim().length < 1);
    validator.notifyPropertyChange('errors');
  },

  validateMonth: function (validator) {
    const value = validator.value;
    const isInvalid = value > 12 || value < 1;

    validator.set(`errors.${validator.name}.value`, isInvalid);
    validator.notifyPropertyChange('errors');
  },

  validateYear: function (validator) {
    const value = validator.value;
    const currentYear = new Date().getFullYear();
    const isInvalid = value > currentYear + 11 || value < currentYear;

    validator.set(`errors.${validator.name}.value`, isInvalid);
    validator.notifyPropertyChange('errors');
  },

  validateEmail: function (validator) {
    validator.set(`errors.${validator.name}.value`, !emailRegex.test(validator.value));
    validator.notifyPropertyChange('errors');
  },

  valueChanged: observer('value', function () {
    if (this.hasErrors) this.validate();
  }),

  hasErrors: computed('errors', function () {
    return Object.values(this.errors).find((el) => el.value) ? true : false;
  }),

  bindErrors: computed('errors', function () {
    if (this.hasErrors) {
      return true;
    }

    return '';
  }),
});
