import EventsApi from 'mewe/api/events-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';
import { isLoading } from 'mewe/stores/models/event-page-model';

export default function (eventId, callback, failCallback, finallyCallback) {
  isLoading(eventId);

  EventsApi.getOrJoinEvent(eventId)
    .then((data) => {
      if (data.event && data.owner) {
        data.event.owner = data.owner;
      }
      if (data.event && data.participation) {
        data.event.participationType = data.participation.participationType;
      }

      dispatcher.dispatch('events', 'handleRootEvent', data.event, callback);
    })
    .catch((data) => {
      if (data.data) {
        if (data.data.message === 'User is not participating in event') {
          FunctionalUtils.error(__('This event is private.'));
        } else if (data.data.message === 'Event not found') {
          FunctionalUtils.error(__('This event was deleted.'));
          dispatcher.dispatch('events', 'removeEventFromUpcoming', eventId);
          dispatcher.dispatch('events', 'getCounters', 'events');
        } else {
          FunctionalUtils.showDefaultErrorMessage();
        }
      }

      failCallback?.();
    })
    .finally(() => {
      finallyCallback?.();
    });
}
