export const getThemeFromHtmlClass = () => {
  let theme = document.querySelector('html').className.match(/theme-\w+/);

  if (theme) {
    theme = theme[0].replace('theme-', '');
  }
  return theme ? theme : 'mewe';
};

export const hasAnyThemeClass = () => {
  return document.querySelector('html').className.match(/theme-\w+/);
};
