import { scheduleOnce } from '@ember/runloop';
import { filterBy } from '@ember/object/computed';
import { each, compact, isEmpty, differenceBy, reduce, sortBy } from 'lodash';

import Store from './store';
import ThreadFactory from './models/thread-model';
import ChatMessageFactory from './models/chat-message-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import CurrentUserStore from 'mewe/stores/current-user-store';
import GroupStore from 'mewe/stores/group-store';
import Utils from 'mewe/utils/miscellaneous-utils';
import ChatUtils, { readChatFilter } from 'mewe/utils/chat-utils';
import Storage from 'mewe/shared/storage';
import { setTitleCount } from 'mewe/shared/storage';
import { createDeferred } from 'mewe/shared/utils';
import { isUndefined, isDefined, areMessagesConsecutive } from 'mewe/utils/miscellaneous-utils';
import fetchThreads from 'mewe/fetchers/fetch-threads';
import PS from 'mewe/utils/pubsub';
import dispatcher from 'mewe/dispatcher';
import { chatMsgsPerPage, ChatFilter } from 'mewe/constants';
//TODO: this need to be updated with run from the runloop module
import { beginPropertyChanges, endPropertyChanges } from '@ember/-internals/metal';
import EmberObject, { computed, get, set } from '@ember/object';
import { A } from '@ember/array';
import { debug } from '@ember/debug';

/**
 * import ChatStore from 'mewe/stores/chat-store';
 */
let state = EmberObject.extend({
  // there are 3 states a thread could be in:
  // - normal chat request
  // - a chat request that a user has answered -> it's located in threads and has chatType set to UserChatRequest, also, it has notContact fields
  // - a normal thread :)
  threads: A(),
  threadsToDisplay: computed('threads.@each.hiddenChat', 'threads.@each.disabledClosed', function () {
    return this.get('threads')?.filter((t) => !t.hiddenChat && !t.disabledClosed);
  }),

  userChats: filterBy('threadsToDisplay', 'chatType', 'UserChat'),
  groupChats: filterBy('threadsToDisplay', 'chatType', 'GroupChat'),
  eventChats: filterBy('threadsToDisplay', 'chatType', 'EventChat'),

  unreadIndicator: EmberObject.extend({
    EventChat: false,
    GroupChat: false,
    UserChat: false,
    UserChatRequest: false,
  }).create(),

  chatRequests: A(),
  isFetchingRequests: false,
  chatRequestsInited: false,
  allChatRequestsLoaded: false,

  allowRequests: null, // are CRs turned ON or OFF?
  unreadRequestsCount: 0,
  requestsCount: 0, // <--- /threads doesn't return requests themselves, only stats
  chatRequestsThread: EmberObject.create({}),

  uploadsInProgress: A(),
  isRecordingVoice: false,
  callWindow: null,

  isChatPage: false,
  isChatRequestsPage: false,
  isChatPageCreationMode: false, // is chat creation on chat page displayed (mw-chat-window)
  chatCreationThread: null, // existing chat matched during new chat creation

  openedThreads: A(),
  maxAvailableChats: 4, // default value
  threadsInited: false,
  unreadThreadsCount: 0,
  failedMessages: A(),
  chatMsgsPerFirstLoadAmount: chatMsgsPerPage,

  notClosedRequestsCountComputed: computed(
    'chatRequests.@each.closed',
    'requestsCount',
    'chatRequests.length',
    'chatRequestsInited',
    function () {
      let chatRequests = this.get('receivedChatRequests');

      if (chatRequests.length === 0 && !this.get('chatRequestsInited')) {
        return this.get('requestsCount');
      }

      return chatRequests.filter((c) => !c.closed).length;
    }
  ),

  hasNoThreads: computed(
    'threads.length',
    'threadsInited',
    'threads.@each.closed',
    'threads.@each.hiddenChat',
    'unreadRequestsCountComputed',
    function () {
      return (
        this.get('threadsInited') &&
        !this.get('unreadRequestsCountComputed') &&
        !this.get('threads').any((t) => !t.hiddenChat && !t.closed)
      );
    }
  ),

  unreadRequestsCountComputed: computed(
    'chatRequests.@each.unread',
    'chatRequests.@each.deleted',
    'unreadRequestsCount',
    function () {
      let chatRequests = A(this.get('receivedChatRequests'));

      if (!this.get('chatRequestsInited') && !chatRequests.length) {
        return this.get('unreadRequestsCount');
      } else {
        return chatRequests.filterBy('unread').length;
      }
    }
  ),

  selectedThreadId: computed('selectedThread', function () {
    let thread = this.get('selectedThread');

    if (thread) return thread.get('id');
  }),

  selectedThread: computed(
    'threads.length',
    'threads.@each.selected',
    'chatRequests.@each.selected',
    'chatRequests.length',
    'chatCreationThread',
    'isChatPageCreationMode',
    function () {
      // during new chat creation, current thread == thread for currently selected users or null, and it's stored as 'chatCreationThread'
      if (this.get('isChatPageCreationMode')) return this.get('chatCreationThread');

      return this.get('threads').find((t) => t.selected) || this.get('chatRequests').find((t) => t.selected);
    }
  ),

  canOpenChatCreation: computed('openedThreads.@each.isNewChat', 'isChatPageCreationMode', function () {
    // new chat creation can be opened if there is no small chat creator opened and chat page is not in chat creation mode
    return !this.get('isChatPageCreationMode') && isUndefined(this.get('openedThreads').find((t) => t.isNewChat));
  }),

  receivedChatRequests: computed('chatRequests.length', function () {
    return A(this.get('chatRequests').filter((r) => r.get('isReceivedChatRequest')));
  }),

  hasChatFilesToSend: computed('uploadsInProgress.length', function () {
    return this.get('uploadsInProgress.length') > 0;
  }),
}).create();

let serializer = JSONSerializer.create();

var store = Store.extend({
  init() {
    this._super();

    this.setProperties({
      fetchingThreadsDeffered: createDeferred(),
      lastPlayed: 0,
      soundDelay: 5,
    });

    this.wsDocEditBind = this.wsDocEdit.bind(this);
    PS.Sub('doc.edit', this.wsDocEditBind);
    this.unfocusThreadsOnBlurBind = this.unfocusThreadsOnBlur.bind(this);
    window.addEventListener('blur', this.unfocusThreadsOnBlurBind);
  },

  willDestroy() {
    this._super(...arguments);
    PS.Unsub('doc.edit', this.wsDocEditBind);
    window.removeEventListener('blur', this.unfocusThreadsOnBlurBind);
  },

  wsDocEdit(docData) {
    let process = (thread) => {
      thread.get('messages').forEach((msg) => {
        let att = get(msg, 'attachments')[0];
        if (!att) return;

        if (get(att, 'fileObjectId') === docData.id) {
          beginPropertyChanges();
          if (!isUndefined(docData.name)) {
            set(att, 'fileName', docData.name);
          }
          if (!isUndefined(docData.length)) {
            set(att, 'fileSize', docData.length);
          }
          if (!isUndefined(docData.type)) {
            set(att, 'type', docData.type);
          }
          endPropertyChanges();
        }
      });
    };

    state.get('chatRequests').forEach(process);
    state.get('threads').forEach(process);
  },

  unfocusThreadsOnBlur() {
    beginPropertyChanges();
    state.get('threads').forEach((t) => {
      if (t.get('chatFocused')) t.set('chatFocused', false);
    });
    endPropertyChanges();
  },

  deserializeMany(json) {
    const Thread = ThreadFactory(CurrentUserStore, this);

    var threads = A();
    serializer.deserializeMany(threads, Thread, json);
    return threads;
  },

  deserializeOne(record) {
    const Thread = ThreadFactory(CurrentUserStore, this);

    return serializer.deserialize(
      Thread.create({
        isNew: false,
      }),
      record
    );
  },

  setUnreadIndicators(data) {
    state.set('unreadIndicator', {
      EventChat: data?.unreadIndicator?.EventChat
        ? data.unreadIndicator.EventChat
        : state.get('unreadIndicator.EventChat'),
      GroupChat: data?.unreadIndicator?.GroupChat
        ? data.unreadIndicator.GroupChat
        : state.get('unreadIndicator.GroupChat'),
      UserChat: data?.unreadIndicator?.UserChat ? data.unreadIndicator.UserChat : state.get('unreadIndicator.UserChat'),
      UserChatRequest: data?.unreadIndicator?.UserChatRequest
        ? data.unreadIndicator.UserChatRequest
        : state.get('unreadIndicator.UserChatRequest'),
    });
  },

  openSavedChatThreads() {
    let chats;
    try {
      chats = JSON.parse(Storage.get(Storage.keys.chats));

      if (chats && chats.length) {
        this.fetchingThreadsDeffered.promise.then(() => {
          this.deserializeOpenedThreads(chats);
        });
      }
    } catch (error) {
      debug(error.message);
    }
  },

  deserializeOpenedThreads: function (chats) {
    let requests = state.chatRequests,
      threads = state.threads,
      noChatInStore = false;

    // already opened
    if (chats.length == state.get('openedThreads.length')) return;

    each(chats, (item) => {
      // there was case when item was not defined or null: Unable to get property 'threadId' of undefined or null reference
      if (item) {
        var thread = requests.find((r) => r.id === item.threadId);

        if (isUndefined(thread)) {
          thread = threads.find((t) => t.id === item.threadId);
        } else {
          thread.set('noFocusAfterOpen', true);
        }

        // thread is not on the list of chats (probably somewhere further in pagination)
        if (isUndefined(thread) && item.threadId) {
          noChatInStore = true;
        }

        if (thread || noChatInStore) {
          dispatcher.dispatch('chat', 'openThread', {
            thread: thread,
            threadId: noChatInStore ? item.threadId : null,
            minimized: item.minimized,
            expandedChatSize: item.expandedChatSize,
            addTemporarilyToStore: noChatInStore,
            openedFromLs: true, // openedFromLs indicates opening chat that was stored in opened chats (reopening it after refresh or reconnection)
          });
        }
      }
    });
  },

  serializeOpenedThreads: function () {
    var arr = compact(
      state.openedThreads.map((item) => {
        let storeThread = {};

        if (item.get('id')) {
          storeThread.threadId = item.get('id');
        }
        if (item.get('minimized')) storeThread.minimized = item.get('minimized');
        if (item.get('expandedChatSize')) storeThread.expandedChatSize = item.get('expandedChatSize');

        if (!isEmpty(storeThread)) return storeThread;
      })
    );

    Storage.set(Storage.keys.chats, JSON.stringify(arr));
  },

  getState() {
    return state;
  },

  // users, threadId, isGroupChat, minimize
  openThread: function (chat, minimize, options = {}) {
    if (chat.get('disabledClosed') || chat.get('isNewChat') || state.get('threadSelectionInProgress')) {
      return;
    }

    let doNotClose = false;
    if (typeof options !== 'undefined' && options !== null) {
      doNotClose = options.doNotClose;
    }

    beginPropertyChanges();

    if (options.noFocusAfterOpen) chat.set('noFocusAfterOpen', true);

    if (chat.get('open')) {
      if (!doNotClose) {
        chat.set('open', false);
        state.get('openedThreads').removeObject(chat);
      } else {
        // mini chat opened from dedicated chat page
        chat.set('minimized', false);
        chat.set('chatFocused', true);
      }
    } else {
      let openedThreads = state.openedThreads.length;
      let expandedOpenedThreads = state.openedThreads.filter((c) => c.expandedChatSize);
      let computedOpenedThreads = openedThreads + expandedOpenedThreads.length * 0.8; // expanded chat is 535px wide so ~1.8 the small chat

      if (computedOpenedThreads >= state.maxAvailableChats) {
        // <----- value is set in mw-chat
        const lastChat = state.openedThreads[state.openedThreads.length - 1];
        if (lastChat) {
          state.openedThreads.removeObject(lastChat);
          lastChat.set('open', false);
        }

        chat.set('open', true);
      }

      chat.setProperties({
        minimizes: minimize || false,
        expandedChatSize: options.expandedChatSize || false,
      });

      // new (empty) chats don't have id at all and still need to be added to openedThreads
      if (!chat.id || !state.openedThreads.find((t) => t.id === chat.id)) state.openedThreads.pushObject(chat);
    }

    if (options.aroundId) {
      if (!chat.get('scrollTo') || chat.get('scrollTo') !== options.aroundId) chat.set('scrollTo', options.aroundId);
    }

    endPropertyChanges();
  },

  getThreadById: function (threadId) {
    if (threadId) {
      return state.threads.find((thread) => thread.id === threadId);
    }

    return null;
  },

  preprocessMessages(messages, thread) {
    each(messages, (message, index) => {
      if (index + 1 < messages.length) {
        this.setMessagesConsecutive(message, messages[index + 1]);
      }

      message.set('thread', thread);
    });
  },

  sortMessages(messages) {
    if (messages && messages.length) {
      return messages.uniqBy('id').sortBy('date', 'id').reverse();
    } else {
      return A();
    }
  },

  restoreNewMsgFromLS(thread) {
    // checking if there was a message being componsed and not sent
    const storedMessages = ChatUtils.getComposedMsgs();
    let newMessage = '';
    let mentions = [];
    if (storedMessages && storedMessages[thread.id]) {
      newMessage = storedMessages[thread.id].text || '';
      mentions = storedMessages[thread.id].mentions || A(); // array of mentioned users in newMessage
    }

    if (!thread.newMessage && !thread.wasChatCreationThread) {
      // SG-13488
      thread.set('newMessage', newMessage); // set newMessage if some unsent text was saved in storage before
    }

    thread.set('mentions', mentions); // mentions of users in newMessage
  },

  preRenderMyMessage: function (thread, msgText, preId) {
    const date = Math.floor(new Date().getTime() / 1000);
    const me = CurrentUserStore.getState();

    const Thread = ThreadFactory(CurrentUserStore, this);
    const ChatMessage = ChatMessageFactory(CurrentUserStore, this, Thread);

    let message = serializer.deserializeOne(
      ChatMessage,
      {
        id: `pre:${date}`,
        isPre: true,
        preId: preId,
        text: msgText,
        authorId: me.id || me.userId,
        date: date,
        isMyMessage: true,
        thread: thread,
        threadId: thread.id,
        groupId: thread.isGroupChat ? thread.id : undefined,
      },
      { thread: thread }
    );

    beginPropertyChanges();

    let currentMessages = A(thread.get('messages'));
    if (currentMessages.length >= 1) this.setMessagesConsecutive(message, currentMessages[0]);
    if (currentMessages.length >= 2) this.setMessagesConsecutive(message, currentMessages[1]);

    currentMessages.unshiftObject(message);

    endPropertyChanges();
  },

  addMessage: function (thread, message, preId, options) {
    let currentMessages = A(thread.messages);
    let existingMessage = currentMessages.find((m) => m.id === message.id);

    const Thread = ThreadFactory(CurrentUserStore, this);
    const ChatMessage = ChatMessageFactory(CurrentUserStore, this, Thread);

    let newMessage = serializer.deserializeOne(ChatMessage, message, { thread: thread });

    beginPropertyChanges();

    if (existingMessage) {
      // already exists in collection, return
      // link scrapping is async, it we don't have link info in response when adding msg but we have in following WS msg
      if (newMessage.get('links.length') && !existingMessage.get('links.length')) {
        set(existingMessage, 'links', newMessage.links);
      }
      endPropertyChanges();
      return;
    }

    /* 1. Adding message to collection, cleaning preRendered message if needed */

    let tempMessage;

    // if no preId then it's msg from WS. On slow networks ws msg can arrive before api call response, try to find & replace tempMsg based on content
    if (!preId) {
      tempMessage = currentMessages.find((item) => {
        return (
          item.preId &&
          item.computedAuthorId === newMessage.computedAuthorId &&
          item.textDisplay === newMessage.textDisplay
        );
      });
    }
    // msg from api call response
    else {
      tempMessage = currentMessages.find((item) => item.preId == preId); // using === doesn't work here for some reason
    }

    if (thread.newestMessagesShown) {
      if (tempMessage) {
        // if failed to send before then push message to the top of collection and remove temporary message
        if (tempMessage.failedToSend) {
          currentMessages.removeObject(tempMessage);
          currentMessages.unshiftObject(newMessage);
        } else {
          currentMessages.replace(currentMessages.indexOf(tempMessage), 1, [newMessage]);
        }
      } else {
        currentMessages.unshiftObject(newMessage);
      }

      let sorted = A(this.sortMessages(currentMessages));

      if (differenceBy(sorted, currentMessages, 'id').length) {
        thread.set('messages', sorted);
      }

      if (!newMessage.isMyMessage) {
        thread.incrementProperty('arrivedWsMsgsSinceModelCreated');
      }
    }

    if (thread.newestMessagesShown) {
      if (newMessage.id === currentMessages[0].id || !thread.lastMessage) {
        /**
         * moving thread to the top of list depends on lastMessage's date
         * we cannot use newMessage because of reference - SG-29330
         */
        thread.set('lastMessage', serializer.deserializeOne(ChatMessage, message, { thread: thread }));
      }

      if (currentMessages.length > 1) {
        this.setMessagesConsecutive(newMessage, currentMessages[1]);
      }
    }

    /* 2. Chat opening logic */

    let shouldSmallChatBeOpened = ChatUtils.canOpenSmallChat(
      CurrentUserStore,
      newMessage.computedAuthorId,
      state.isChatPage,
      options?.doNotOpenSmallChatIfIAmTheAuthor,
      newMessage
    );

    if (!thread.open) {
      if (!state.isChatPage) {
        // don't open chat thread if we are on chat page
        let openedThread = state.openedThreads.find((t) => t.id === thread.id);
        if (shouldSmallChatBeOpened && !openedThread && !options?.blockOpenThread) {
          this.send('openThread', thread, null, options);
        }
      }
    }

    /* 3. Counters and notifications */

    this.send('doNotification', {
      thread: thread,
      shouldSmallChatBeOpened: shouldSmallChatBeOpened,
      newMessage: newMessage,
    });

    if (get(state, 'chatCreationThread') === thread) {
      thread.set('wasChatCreationThread', true); // mw-chat-window will handle transition, since can't call services directly from here
    }

    /* 4. Threads operations */

    let sortedThreads = A(state.threads.sortBy('lastEditTime').reverse());

    if (sortedThreads[0] !== state.get('threads')[0]) {
      state.set('threads', sortedThreads);
    }

    if (thread.isNewChat) {
      thread.set('isNewChat', false);
      if (!state.threads.find((t) => t.id === thread.id)) {
        state.threads.unshiftObject(thread);
      }
    }

    endPropertyChanges();
  },

  playNotifSound: function () {
    let time = new Date().getTime() / 1000;
    if (typeof this.get('lastPlayed') === 'undefined' || time - this.get('lastPlayed') >= this.get('soundDelay')) {
      //avoiding constant sound beeping
      if (CurrentUserStore.getState().get('allowChatMsgSound')) {
        Utils.playNotificationSound();
      }
      this.set('lastPlayed', time);
    }
  },

  decreaseUnreadCount(decreaseAmount) {
    let newCount = state.unreadThreadsCount - decreaseAmount;
    if (newCount < 0) {
      newCount = 0;
    }

    state.set('unreadThreadsCount', newCount);
    setTitleCount('messages', newCount);
    Utils.updateDocumentTitle('message');
  },

  maximizeNewChatIfMinimized: function () {
    // if there already is new small chat but minimized then expand it
    if (!state.get('isChatPageCreationMode')) {
      let newSmallChat = A(state.get('openedThreads')).find((t) => t.isNewChat);
      if (newSmallChat && newSmallChat.get('minimized')) this.send('toggleMinimize', newSmallChat);
    }
  },

  matchEventsToChats(threads, events) {
    each(threads, (t) => {
      if (t.chatType === 'EventChat') {
        let ev = events.find((e) => e.id === t.id);
        if (ev) t.event = ev;
      }
    });

    return threads;
  },

  findNotHiddenAndNotDisabledClosedThread(isChatRequestsPage, filteredChatName) {
    let threads = isChatRequestsPage
      ? state.get('receivedChatRequests')
      : state.get(filteredChatName || 'threadsToDisplay');

    if (!threads?.length) return null; // just in case threads are empty, we had such errors in sentry

    threads = threads.filter((t) => !t.get('hidden') && !t.get('disabledClosed'));

    let firstWithMsgs = threads.find((t) => t.get('messagesInitied') && t.get('messages.length'));

    return firstWithMsgs || threads[0];
  },

  setMessagesConsecutive(msgA, msgB) {
    if (!msgA || !msgB) return;

    if (areMessagesConsecutive(msgA, msgB)) {
      msgA.set('isConsecutiveReverse', true);
      msgB.set('isConsecutive', true);
    }
  },

  getChatMsgsPerFirstLoadAmount() {
    return state.get('chatMsgsPerFirstLoadAmount');
  },

  doubleChatMsgsPerFirstLoadAmount() {
    state.incrementProperty('chatMsgsPerFirstLoadAmount', state.get('chatMsgsPerFirstLoadAmount'));
  },

  storeFailedMessages() {
    // there might be some previous data that needs to be cleaned
    state.set('failedMessages', A());

    let currentThreads = state.get('threads');

    each(currentThreads, (thread) => {
      if (thread.get('messages.length')) {
        let failedMessages = A(thread.get('messages')).filterBy('failedToSend', true);
        if (failedMessages && failedMessages.length) {
          state.failedMessages.pushObject({ id: thread.get('id'), messages: failedMessages });
        }
      }
    });
  },

  actions: {
    deleteAllChatRequests: function () {
      beginPropertyChanges();
      state.set('chatRequests', A());
      state.set('unreadRequestsCount', 0);
      state.set('requestsCount', 0);
      endPropertyChanges();
    },

    setAllChatRequestsAsRead: function () {
      beginPropertyChanges();
      state.get('chatRequests').forEach((r) => r.set('unread', 0));
      state.set('unreadRequestsCount', 0);
      endPropertyChanges();
    },

    doNotification(options = {}) {
      let thread = options.thread;
      let newMessage = options.newMessage;
      let showNotification = true;

      const Thread = ThreadFactory(CurrentUserStore, this);
      const ChatMessage = ChatMessageFactory(CurrentUserStore, this, Thread);

      if (!newMessage && thread) {
        newMessage = serializer.deserializeOne(ChatMessage, thread.get('messages')[0], { thread: thread });
      }

      // no notification for chat msg about answered call - SG-13784 or if is users own msg
      if (newMessage.isAnsweredCall || newMessage.isMyMessage) return;
      // no notification about chat special events (rename, adding users, ..)
      if (newMessage.chatEventMessage) showNotification = false;
      // no notification for chat messages if group is muted
      if (newMessage.group?.id) {
        const group = GroupStore.getState({ id: newMessage.group.id });
        if (group && group.groupMuted) showNotification = false;
      }

      const threadNotFocusedAndNotSelected =
        document.hidden ||
        (!thread.get('chatFocused') &&
          !(state.get('isChatPage') && state.get('selectedThread.id') === thread.get('id')));

      const incrementCounters = () => {
        state.incrementProperty('unreadThreadsCount');
        setTitleCount('messages', state.unreadThreadsCount);
        thread.incrementProperty('unread');
        state.set(`unreadIndicator.${thread.chatType}`, true);
      };

      // increase unread counter - unless textarea have focus, then mark as read
      // also increase unread counter if chat just pop up with new message
      if (options.shouldSmallChatBeOpened || threadNotFocusedAndNotSelected) {
        if (!options.newThreadLoaded) {
          incrementCounters();
        }

        if (showNotification) {
          dispatcher.dispatch('notification', 'notifyFromChatMsg', newMessage);
          Utils.updateDocumentTitle('message', newMessage.get('computedAuthor.name'), true);
        }
      } else if (!threadNotFocusedAndNotSelected) {
        // unread needs to be set to fire markAsRead api call
        incrementCounters();
      }

      if (threadNotFocusedAndNotSelected && showNotification) {
        this.playNotifSound();
      }
    },

    removeChatRequestById: function (threadId) {
      let requests = A(state.get('chatRequests')),
        foundRequest = requests.find((r) => r.id === threadId);

      if (foundRequest) {
        if (foundRequest.get('isReceivedChatRequest')) {
          this.send('decreaseRequestCount');
        }
        requests.removeObject(foundRequest);
      }

      this.send('resetChatRequestById', threadId);
      this.send('selectFirstAvailableChatRequest');
    },

    resetChatRequestById: function (threadId) {
      const openedThread = state.openedThreads.find((t) => t.id === threadId);

      if (openedThread) {
        openedThread.set('waitingForCreation', true);
        openedThread.messages.clear();
      }
    },

    selectFirstAvailableChatRequest() {
      let threads = state.get('receivedChatRequests');

      if (threads.length) {
        this.send('selectThread', threads[0]);
      } else if (state.get('isChatPage')) {
        dispatcher.dispatch('chat', 'transitionToFirstThreadOrIndex');
      }
    },

    decreaseRequestCount: function () {
      let count = Number(state.get('requestsCount'));
      if (isUndefined(count) || count <= 0) {
        count = 1;
      }
      state.set('requestsCount', count - 1);
    },

    decreaseUnreadRequestCount: function () {
      let count = Number(state.get('unreadRequestsCount'));
      if (isUndefined(count) || count <= 0) {
        count = 1;
      }
      state.set('unreadRequestsCount', count - 1);
    },

    increaseUnreadRequestCount: function () {
      let count = Number(state.get('unreadRequestsCount'));
      if (isUndefined(count) || count <= 0) {
        count = 0;
      }
      state.set('unreadRequestsCount', count + 1);
    },

    handleChatRequestsData(chatRequestsData) {
      let loadedRequests = this.deserializeMany(chatRequestsData);

      beginPropertyChanges();
      state.setProperties({
        chatRequests: loadedRequests,
        chatRequestsInited: true,
        isFetchingRequests: false,
        allChatRequestsLoaded: !loadedRequests.length,
      });
      endPropertyChanges();
    },

    handleMoreChatRequestsData(data) {
      const loadedRequests = this.deserializeMany(data);
      const currentThreads = state.chatRequests;

      if (loadedRequests.length) {
        each(loadedRequests, function (thread) {
          let isAlreadyInStore = currentThreads.find((t) => t.id === thread.id);
          if (!isAlreadyInStore) currentThreads.pushObject(thread);
        });
      } else {
        state.set('allChatRequestsLoaded', true);
      }

      state.set('isFetchingRequests', false);
    },

    handleChatRequests: function (data) {
      if (isDefined(data)) {
        if (isDefined(data.requests)) {
          state.get('chatRequestsThread').setProperties({
            threadName: __('New chat requests'),
            lastAuthor: data.requests.lastAuthor,
            publicUrl: data.requests.lastAuthor.publicLinkId,
            textDisplay: __('From {senderName}', { senderName: data.requests.lastAuthor.name }),
            lastMessage: {
              date: data.requests.lastMessageAt,
            },
          });

          state.setProperties({
            unreadRequestsCount: data.requests.unreadCount,
            requestsCount: data.requests.count,
          });
        }

        state.set('allowRequests', data.allowRequests);
      }
    },

    resetChatRequestsThread: function () {
      state.get('chatRequestsThread').setProperties({
        lastAuthor: null,
        lastMessage: null,
      });
    },

    updateLastRequestAuthorFromWs: function (authorObj, lastAt) {
      state.get('chatRequestsThread').setProperties({
        'lastMessage.date': lastAt,
        lastAuthor: authorObj,
      });
    },

    updateChatRequestsFromWs: function (message) {
      if (message.firstMessage) {
        state.incrementProperty('requestsCount');
        state.incrementProperty('unreadRequestsCount');
      } else {
        const request = A(state.get('receivedChatRequests')).find((r) => r.id === message.threadId);
        if (request) {
          request.incrementProperty('unread');
        }
      }

      this.send('updateLastRequestAuthorFromWs', message.author, message.date);
    },

    // thread moved from requests to threads + last request info updated
    moveReceivedChatRequestToThreads(threadId, newMsgAuthorId) {
      let chatRequest = A(state.get('chatRequests')).find((r) => r.id === threadId);
      let existingThread = A(state.get('threads')).find((r) => r.id === threadId);
      if (!chatRequest) return;

      let currentUserId = CurrentUserStore.getState().get('id');
      let currentUserAcceptedRequest = chatRequest.get('isReceivedChatRequest') && newMsgAuthorId === currentUserId;
      let otherUserAcceptedRequest = !chatRequest.get('isReceivedChatRequest') && newMsgAuthorId !== currentUserId;

      if (currentUserAcceptedRequest || otherUserAcceptedRequest) {
        beginPropertyChanges();

        let requests = state.get('chatRequests');

        requests.removeObject(chatRequest);

        chatRequest.set('chatType', 'UserChat');

        endPropertyChanges(); // so computer properties get a chance to update

        state.set('requestsCount', state.get('receivedChatRequests.length'));

        if (!existingThread) state.get('threads').unshiftObject(chatRequest);

        beginPropertyChanges();

        if (state.get('receivedChatRequests.length') === 1) {
          let newLast = state.get('receivedChatRequests')[0],
            newRequestTime = newLast.get('lastEditTime'),
            author = newLast.get('observableOthers')[0];

          if (newRequestTime) newRequestTime = newRequestTime / 1000;

          if (author) {
            this.send('updateLastRequestAuthorFromWs', { name: get(author, 'name') }, newRequestTime);
          } else {
            this.send('resetChatRequestsThread');
          }
        } else {
          this.send('resetChatRequestsThread');
        }

        endPropertyChanges();
      }
    },

    allowAllChatRequests: function () {
      state.set('allowRequests', true);
    },

    denyAllChatRequests: function () {
      beginPropertyChanges();
      this.send('resetChatRequestsThread');
      state.setProperties({
        allowRequests: false,
        unreadRequestsCount: 0,
        requestsCount: 0,
      });
      state.get('chatRequests').clear();
      endPropertyChanges();
    },

    handleRawThreads: function (data, options, chatSource) {
      let currentThreads = state.threads;
      let selectedThreadId = state.selectedThreadId;

      // storing failed messages before chats will be overwriten when refetching them after reconnection
      if (options && options.reconnectRefresh && currentThreads.length) {
        this.storeFailedMessages();

        let smallChatInCreation = A(state.openedThreads).find((r) => r.waitingForCreation);

        currentThreads.clear();
        state.openedThreads.clear();

        if (smallChatInCreation) {
          state.openedThreads.pushObject(smallChatInCreation);
        }
      }

      // set event fields before deserializing because events are in separate array
      let loadedThreads = data && data.events ? this.matchEventsToChats(data.threads, A(data.events)) : data.threads;

      loadedThreads.map((t) => Object.assign(t, { chatSource }));

      let threads = [];

      switch (chatSource) {
        case ChatFilter.ALL:
          threads = state.threads;
          break;

        case ChatFilter.USER:
          threads = state.userChats;
          break;

        case ChatFilter.GROUP:
          threads = state.groupChats;
          break;

        case ChatFilter.EVENT:
          threads = state.eventChats;
          break;
      }

      let ids = threads.map((t) => t.id);

      loadedThreads = loadedThreads.filter((t) => ids.indexOf(t.id) == -1);

      loadedThreads = this.deserializeMany(loadedThreads);

      beginPropertyChanges();
      currentThreads.pushObjects(loadedThreads);
      state.set('threadsInited', true);
      state.set('connectionIssue', false);
      endPropertyChanges();

      this.fetchingThreadsDeffered.resolve();

      setTitleCount('messages', state.unreadThreadsCount);
      Utils.updateDocumentTitle('message');

      this.openSavedChatThreads();

      this.setUnreadIndicators(data);

      // restoring last selected thread and fetching its messages
      // this should go AFTER handling threads data so threads won't override this thread selection and fetched messages
      if (options && options.selectLastThread && state.isChatPage && selectedThreadId) {
        const lastSelectedThread = state.threads.find((t) => t.id === selectedThreadId);
        if (lastSelectedThread) {
          this.send('selectThread', lastSelectedThread);
          this.dispatch('chat', 'getChatThreadMessages', { threadId: selectedThreadId });
        }
      }
    },

    connectionIssue() {
      state.set('connectionIssue', true);
    },

    handleMoreRawThreads: function (data) {
      let currentThreads = A(state.threads);

      // set event fields before deserializing because events are in separate array
      let loadedThreads = data && data.events ? this.matchEventsToChats(data.threads, A(data.events)) : data.threads;

      loadedThreads = this.deserializeMany(loadedThreads);

      // if a single thread was fetched because it was not in threads list
      // then it was added to store and we need to avoid doubling it when more threads is loaded
      // TODO still there can be missing thread because of badly counted offset (additional thread added to list not from regular fetching)
      each(loadedThreads, function (thread) {
        let isAlreadyInStore = currentThreads.find((t) => t.id === thread.id);
        if (!isAlreadyInStore) currentThreads.pushObject(thread);
      });
    },

    //options: offset, open, doNotChangeThread, addTemporarilyToStore
    handleThread: function (jsonThread, options = {}) {
      let existingThread =
          state.threads.find((t) => t.id === jsonThread.id) || state.chatRequests.find((r) => r.id === jsonThread.id),
        opened = A(state.get('openedThreads')).find((t) => t.id === jsonThread.id),
        failedMessages;

      // a thread can be waiting for creation (no msgs sent yet) and a new msg arrives from one of the thread participants or current user sends a private post on profile page
      if (!existingThread) {
        let participants = jsonThread.participants || A();

        if (participants.length) {
          existingThread = state.threads.find((t) => {
            if (t.get('waitingForCreation') && t.get('participants.length') === participants.length) {
              let differentParticipantExists = t.get('participants').find((p) => {
                let id = get(p, 'id');

                return !participants.find((p) => p.id === id);
              });

              return !differentParticipantExists;
            }
          });
        }
      }

      beginPropertyChanges();

      if (existingThread) {
        //if chat exist in openedThreads open it
        //this is used in new chat creation to avoid double chat open
        if (opened) {
          existingThread.set('open', true);
        }

        let messages = A();

        const Thread = ThreadFactory(CurrentUserStore, this);
        const ChatMessage = ChatMessageFactory(CurrentUserStore, this, Thread);

        if (jsonThread.messages) {
          serializer.deserializeMany(messages, ChatMessage, jsonThread.messages);
          messages = this.sortMessages(messages);
        }

        // if there was offset, we're loading more messages, so unshift them
        if (options.offset && options.offset > 0) {
          existingThread.messages.pushObjects(messages);
        } else {
          existingThread.set('messages', messages);

          // append failed messages
          failedMessages = state.failedMessages.find((m) => m.id === existingThread.id);
          if (failedMessages) {
            existingThread.messages.unshiftObjects(failedMessages.messages);
            state.failedMessages.removeObject(failedMessages);
          }

          existingThread.set(
            'newestMessagesShown',
            !options.aroundId || existingThread.messages.length <= this.getChatMsgsPerFirstLoadAmount()
          );

          // uncomment after SG-18008 will be fixed, backend inconsistency issue with unread counters
          //existingThread.set('unread', jsonThread.unread);
        }

        if (!existingThread.lastMessage && messages.length > 0) {
          existingThread.setProperties({
            lastMessage: messages[0],
          });
        }

        existingThread.setProperties({
          id: jsonThread.id,
          chatType: jsonThread.chatType,
          closed: jsonThread.closed,
          hidden: jsonThread.hidden,
          participants: jsonThread.participants,
          lastReadMessageId: jsonThread.lastReadMessageId,
          messagesInitied: true,
          waitingForCreation: false,
          lastNonWsMessage: existingThread.get('lastMessage'),
        });

        existingThread.set('notContact', jsonThread.notContact);

        this.preprocessMessages(messages, existingThread);

        if (existingThread.get('messages.length') > 0) {
          if (options.aroundId) {
            if (existingThread.get('scrollTo') !== options.aroundId) {
              existingThread.set('scrollTo', options.aroundId);
            }
          }
        }

        if (jsonThread.isNewChat) {
          existingThread.set('expandedChatSize', options.expandedChatSize);
          set(existingThread, 'isNewChat', true);
          set(existingThread, 'selectedItems', state.get('chatCreationThread.selectedItems'));

          state.set('chatCreationThread', existingThread);

          endPropertyChanges();

          return;
        }

        if (
          options.open &&
          !existingThread.get('open') &&
          (existingThread !== state.get('selectedThread') || !state.get('isChatPage'))
        ) {
          this.send('openThread', existingThread, null, options);
        }
      } else {
        let newThread = this.deserializeMany([jsonThread])[0];

        // new chat message comes from WS but thread doesn't exist in store yet ->
        // fetching thread from server returns it without 'lastMessage' and only messages array
        let messages = this.sortMessages(newThread.messages);
        newThread.set('messages', messages);

        if (newThread.get('lastMessage') && messages.length === 0) {
          dispatcher.dispatch('chat', 'getChatThreadMessages', { threadId: newThread.id, offset: 0 });
        } else if (!newThread.get('lastMessage') && messages.length > 0) {
          newThread.setProperties({
            lastMessage: messages[0],
            messagesInitied: true,
            waitingForCreation: false,
          });
        } else if (messages.length === 0) {
          // there are no messages for new thread so it should be true as empty messages is real state of thread
          // also there cen be empty group chat
          newThread.setProperties({
            messagesInitied: true,
            waitingForCreation: false,
          });
        }

        this.preprocessMessages(messages, newThread);

        if (newThread.get('messages.length') > 0) {
          if (options.aroundId) newThread.set('scrollTo', options.aroundId);
        }

        // append failed messages
        failedMessages = state.failedMessages.find((m) => m.id === newThread.id);
        if (failedMessages) {
          newThread.get('messages').unshiftObjects(failedMessages.messages);
          state.failedMessages.removeObject(failedMessages);
        }

        newThread.set('lastNonWsMessage', newThread.get('lastMessage'));
        newThread.set(
          'newestMessagesShown',
          !options.aroundId || newThread.get('messages').length <= this.getChatMsgsPerFirstLoadAmount()
        );

        this.restoreNewMsgFromLS(newThread);

        if (newThread.get('isNewChat')) {
          newThread.set('selectedItems', state.get('chatCreationThread.selectedItems'));
          newThread.set('expandedChatSize', options.expandedChatSize);
          state.set('chatCreationThread', newThread);

          endPropertyChanges();

          return;
        }

        if (newThread.chatType === 'UserChatRequest') {
          state.chatRequests.unshiftObject(newThread);
        } else {
          state.threads.unshiftObject(newThread);
          if (!newThread.hidden) {
            state.set('unreadThreadsCount', state.unreadThreadsCount + newThread.unread);
            setTitleCount('messages', state.unreadThreadsCount + newThread.unread);
          }
        }

        if (state.isChatPage && state.isChatPageCreationMode) {
          let newThreadParticipants, createdThreadParticipants;

          if (newThread.get('chatType') !== 'GroupChat') {
            newThreadParticipants = reduce(
              sortBy(newThread.get('participants').map((u) => u.id)),
              (a, b) => a + ',' + b
            );
            createdThreadParticipants = reduce(
              sortBy(state.chatCreationThread.participants.map((u) => u.id)),
              (a, b) => a + ',' + b
            );
          }

          // fetched thread is the same one that is being created, happens e.g. when added PP to newly created chat
          if (newThreadParticipants === createdThreadParticipants || newThread.get('chatType') === 'GroupChat') {
            this.send('closeNewChatMode');
            this.send('selectThread', newThread);
          }
        }

        if (options.open) {
          this.send('openThread', newThread, null, options);
        }
      }

      endPropertyChanges();
    },

    handleThreadMessages: function (threadId, jsonMessages, options) {
      let existingThread =
        state.threads.find((t) => t.id === threadId) || state.chatRequests.find((r) => r.id === threadId);

      if (!existingThread) {
        if (state.isChatPageCreationMode && state.chatCreationThread) {
          existingThread = state.chatCreationThread;
        }
      }

      if (!existingThread) return;

      beginPropertyChanges();

      let messages = A();

      const Thread = ThreadFactory(CurrentUserStore, this);
      const ChatMessage = ChatMessageFactory(CurrentUserStore, this, Thread);

      serializer.deserializeMany(messages, ChatMessage, jsonMessages.messages);
      messages = this.sortMessages(messages);

      this.preprocessMessages(messages, existingThread);

      if (options.afterId) {
        if (messages.length > 0) {
          A(existingThread.messages).unshiftObjects(messages);
          existingThread.set('messages', A(this.sortMessages(existingThread.messages)));
        }
        if (
          !existingThread.lastMessage ||
          existingThread.get('lastMessage.date') < existingThread.get('messages')[0].date
        ) {
          existingThread.set('lastMessage', existingThread.get('messages')[0]);
          existingThread.set('lastMessageAt', existingThread.get('messages')[0].date);
          existingThread.set('lastNonWsMessage', existingThread.get('lastMessage'));
        }
        // load until empty array
        existingThread.set('newestMessagesShown', !messages.length);
      } else if (options.beforeId) {
        if (messages.length > 0) {
          A(existingThread.messages).pushObjects(messages);
          existingThread.set('messages', A(this.sortMessages(existingThread.messages)));
        }
      } else if (options.aroundId) {
        messages = A(this.sortMessages(messages));
        existingThread.set('messages', messages);
        if (existingThread.get('messages.length') > 0) {
          existingThread.set('scrollTo', options.aroundId);
        }
        existingThread.set('newestMessagesShown', messages.length <= this.getChatMsgsPerFirstLoadAmount());
      } else {
        existingThread.set('messages', A(this.sortMessages(messages)));
        existingThread.set('newestMessagesShown', true);
      }

      // TODO: have something in between of messagesInitied and only partially loaded
      if (!existingThread.get('messagesInitied')) existingThread.set('messagesInitied', true);

      endPropertyChanges();
    },

    openThread: function (threadInstance, minimize, options = {}) {
      // doNotChangeThread is sent on chats page if a thread is clicked from the top menu (we want to always open a mini chat in this case)
      if (state.isChatPage && !options.doNotChangeThread) {
        this.send('selectThread', threadInstance, options.aroundId);
      } else {
        this.openThread(threadInstance, minimize, options);
        this.serializeOpenedThreads();
      }
    },

    closeSmallChat: function (threadInstance) {
      beginPropertyChanges();

      let removeFromThreads = () => {
        state.get('threads').removeObject(threadInstance);
        state.get('chatRequests').removeObject(threadInstance);
      };

      if (isDefined(threadInstance)) {
        dispatcher.dispatch('chat', 'cleanupThread', threadInstance);

        threadInstance.setProperties({
          open: false,
          expandedChatSize: false,
          chatFocused: false,
        });

        if (threadInstance.get('isNewChat')) {
          if (!threadInstance.get('id')) {
            removeFromThreads();
          }
        }

        if (threadInstance.get('waitingForCreation')) {
          removeFromThreads();
          if (state.get('chatCreationThread') === threadInstance) {
            if (!state.get('isChatPageCreationMode')) {
              state.set('chatCreationThread', null);
            }
          }
        }
      }
      state.get('openedThreads').removeObject(threadInstance);
      this.serializeOpenedThreads();

      PS.Pub('close.dropdowns');

      endPropertyChanges();
    },

    hideGroupChat: function (threadInstance) {
      this.send('closeSmallChat', threadInstance);
      threadInstance.setProperties({
        expandedChatSize: false,
        selected: false,
        hidden: true,
      });
    },

    unhideGroupChat: function (threadInstance) {
      if (threadInstance) {
        threadInstance.setProperties({
          hidden: false,
        });
      } else {
        const filter = readChatFilter();
        fetchThreads({ chatType: filter });
      }
    },

    removeThread: function (thread) {
      beginPropertyChanges();

      const isOnThreadPage =
        state.get('isChatPage') && (thread.get('selected') || state.get('chatCreationThread') == thread);

      this.send('closeSmallChat', thread);
      state.get('threads').removeObject(thread);
      state.get('openedThreads').removeObject(thread);
      state.get('chatRequests').removeObject(thread);
      if (state.get('chatCreationThread') === thread) {
        set(state, 'chatCreationThread', null);
      }
      this.serializeOpenedThreads();
      thread.setProperties({
        messages: A(),
        unread: 0,
        lastMessage: null,
      });
      if (thread.get('chatType') === 'UserChat') {
        // the thread will be docked if the thread was a mini chat and it was removed while on the other participant's profile page
        thread.setProperties({
          id: null,
          waitingForCreation: true,
        });
      }

      endPropertyChanges();

      if (isOnThreadPage) {
        scheduleOnce('actions', this, () => dispatcher.dispatch('chat', 'transitionToFirstThreadOrIndex'));
      }
    },

    removeThreadById: function (id) {
      let thread = A(state.get('threads')).find((t) => t.id === id);
      if (!thread) thread = A(state.get('chatRequests')).find((r) => r.id === id);

      if (thread) this.send('removeThread', thread);
    },

    isSending: function (thread) {
      thread.set('isSending', true);
    },

    createTempMessage: function (thread, msg, preId) {
      this.preRenderMyMessage(thread, msg, preId);
    },

    addMessage: function (thread, message, preId, options) {
      this.addMessage(thread, message, preId, options);
    },

    isTyping: function (thread, user) {
      if (typeof user === 'undefined' || user === null) {
        return;
      }
      if (typeof thread === 'undefined' || thread === null) {
        return;
      }

      let userId = user.id;
      if (typeof userId === 'undefined' || userId === null) {
        userId = user.userId;
      }

      if (userId === CurrentUserStore.getState().get('id')) {
        return;
      }

      var alreadyTyping = thread.get('typingUsers').find(function (item) {
        return item.id == userId;
      });

      if (!alreadyTyping) {
        thread.get('typingUsers').pushObject(user);
      }

      let timeout = thread.get('typingInfoTimer');

      clearTimeout(timeout[userId]);

      set(
        timeout,
        userId,
        setTimeout(function () {
          var u = thread.get('typingUsers').find(function (item) {
            return item.id == userId;
          });

          thread.get('typingUsers').removeObject(u);
        }, 4000)
      );
    },

    setUnreadCount(count) {
      state.set('unreadThreadsCount', count);
      setTitleCount('messages', count);
    },

    allRead: function (thread) {
      beginPropertyChanges();

      this.decreaseUnreadCount(thread.get('unread'));
      thread.set('unread', 0);
      thread.set('unreadMentions', 0);

      // toLowerCase first character of ChatType to match indicator name
      const threadsInCategory = state.get(`${thread.chatType.charAt(0).toLowerCase() + thread.chatType.slice(1)}s`);
      if (threadsInCategory) {
        state.set(
          `unreadIndicator.${thread.chatType}`,
          threadsInCategory.find((t) => t.unread > 0)
        );
      }

      endPropertyChanges();
    },

    maxAvailableChats: function (maxAvailableChats) {
      state.set('maxAvailableChats', maxAvailableChats);
    },

    toggleMinimize: function (thread) {
      let wasMinimized = !thread.get('minimized');

      if (wasMinimized && thread.get('chatFocused')) {
        thread.setProperties({
          minimized: wasMinimized,
          chatFocused: false,
        });
      } else {
        thread.set('minimized', wasMinimized);
      }
      this.serializeOpenedThreads();
    },

    minimizeAll: function () {
      state.get('openedThreads').forEach((thread) => {
        if (thread.get('chatFocused')) {
          thread.setProperties({
            minimized: true,
            chatFocused: false,
          });
        } else {
          thread.set('minimized', true);
        }
      });
      this.serializeOpenedThreads();
    },

    expandChatSize: function (thread) {
      thread.set('expandedChatSize', true);
      this.serializeOpenedThreads();
    },

    collapseChatSize: function (thread) {
      thread.set('expandedChatSize', false);
      this.serializeOpenedThreads();
    },

    deselectCurrentThread: function () {
      if (state.get('selectedThread')) {
        state.get('selectedThread').set('selected', false);
      }
    },

    deselectCurrentThreadIfNotChatRequest() {
      let thread = state.get('selectedThread');
      if (thread && !thread.get('isChatRequest')) {
        thread.set('selected', false);
      }
    },

    deselectCurrentChatRequest() {
      let thread = state.get('selectedThread');
      if (thread && thread.get('isChatRequest')) {
        thread.set('selected', false);
      }
    },

    selectThread: function (thread, messageId) {
      let selected = state.get('selectedThread');

      beginPropertyChanges(); // to not fire callbacks until thread has been set as selected

      if (selected) {
        // 'selected' and 'thread' may be different instances of the same thread so need to compare ids
        if (selected.get('id') === thread.get('id')) {
          if (!selected.get('selected')) selected.set('selected', true);
        } else {
          if (selected.get('selected')) selected.set('selected', false);
          if (!thread.get('selected')) thread.set('selected', true);
        }
      } else {
        thread.set('selected', true);
      }

      if (!thread.get('scrollTo') || thread.get('scrollTo') !== messageId) {
        thread.set('scrollTo', messageId);
      }

      endPropertyChanges();
    },

    markAsDeleted: function (threadId, messageId) {
      let thread = this.getThreadById(threadId);
      if (!thread) thread = A(state.get('chatRequests')).find((r) => r.id === threadId);
      if (!thread) return;

      const message = A(thread.get('messages')).find((m) => m.id === messageId);

      if (message && !message.get('deleted')) {
        let newestMessage = thread.get('messages')[0];

        if (message.get('id') === newestMessage.get('id')) {
          thread.set('lastMessage.textServer', __('This message was deleted'));
          thread.set('lastMessage.text', __('This message was deleted'));
        }

        message.set('deleted', true);

        if (message.aType && message.aType === 'photo') {
          PS.Pub('photoStream.update', { message: message, type: 'remove' });
        }

        if (message.attachments && message.attachments.length) {
          PS.Pub('chat.attachment.del', message);
        }
      }

      if (thread.get('replyTo.id') === messageId) {
        thread.set('replyTo', null);
      }
    },

    fixAdjucentMessagesAfterRemoval: function (thread, message) {
      // needed for #SG-15647
      if (isDefined(thread)) {
        const messages = thread.messages;

        if (!messages || !messages.length) return;

        const idx = messages.findIndex((msg) => {
          return msg.id === message.id;
        });

        if (idx >= 0) {
          if (idx - 1 < messages.length) {
            const nextMessage = messages[idx - 1];

            if (message.get('isConsecutive')) {
              if (areMessagesConsecutive(message, nextMessage)) {
                nextMessage.set('isConsecutiveReverse', false);
              }
            }
          }

          if (idx + 1 >= 0) {
            const previousMessage = messages[idx + 1];

            if (message.get('isConsecutiveReverse')) {
              if (areMessagesConsecutive(message, previousMessage)) {
                previousMessage.set('isConsecutive', false);
              }
            }
          }
        }
      }
    },

    markAllAsRead: function () {
      beginPropertyChanges();

      each(state.get('threads'), (t) => t.set('unread', 0));

      state.set('unreadIndicator.EventChat', false);
      state.set('unreadIndicator.GroupChat', false);
      state.set('unreadIndicator.UserChat', false);

      this.send('setUnreadCount', 0);

      endPropertyChanges();

      setTitleCount('messages', 0);
      Utils.updateDocumentTitle('message');
    },

    deleteMessage: function (threadId, messageId) {
      const thread = this.getThreadById(threadId);
      if (!thread) return;

      var message = A(thread.get('messages')).find((m) => m.id === messageId);

      if (message) {
        this.send('fixAdjucentMessagesAfterRemoval', thread, message);
        thread.get('messages').removeObject(message);

        if (thread.get('unread')) {
          thread.set('unread', thread.get('unread') - 1);
          this.decreaseUnreadCount(1);
        }
      }
    },

    handleFailedMessage: function (options) {
      let message = A(options.thread.get('messages')).find((m) => m.get('preId') === options.sendOptions.preId + '');

      if (message) {
        message.setProperties({
          failedToSend: true,
          sendOptions: options.sendOptions,
        });
      }
    },

    deleteAllChatMessages: function (thread) {
      if (thread) {
        thread.set('messages', A());
        thread.set('lastMessage', null);

        if (thread.get('unread')) {
          this.send('allRead', thread);
        }
      }
    },

    deleteAllUserChatMessages: function (thread, userId) {
      if (thread) {
        each(thread.get('messages'), (msg) => {
          if (msg.computedAuthorId === userId) {
            this.send('markAsDeleted', thread.id, msg.id);
          }
        });
      }
    },

    openNewChatMode: function (userToStartChatWith, newMessage) {
      if (userToStartChatWith || state.get('isChatPage')) {
        beginPropertyChanges();
        state.set('isChatPageCreationMode', true);

        each(state.get('threads'), (t) => {
          set(t, 'selected', false);
        });

        // set new empty thread when userToChat is passed or there is no chat being created already
        // otherwise leave currently existing (new thread could be expanded to pull chat page)
        if (userToStartChatWith || !state.get('chatCreationThread')) {
          this.send('setEmptyThreadToNewChat', { userToStartChatWith: userToStartChatWith, newMessage: newMessage });
        }

        endPropertyChanges();
      } else {
        this.maximizeNewChatIfMinimized();
      }
    },

    closeNewChatMode: function () {
      beginPropertyChanges();

      const isNewWaitingForCreation = (t) => t.isNewChat && t.waitingForCreation;

      set(state, 'openedThreads', A(get(state, 'openedThreads').filter((t) => !isNewWaitingForCreation(t))));

      let newChats = get(state, 'threads').filter(isNewWaitingForCreation),
        newChatsReqs = get(state, 'chatRequests').filter(isNewWaitingForCreation);

      get(state, 'threads').removeObjects(newChats);
      get(state, 'chatRequests').removeObjects(newChatsReqs);

      let oldThreadsMarkedAsNew = get(state, 'threads').filter((t) => t.isNewChat);

      if (oldThreadsMarkedAsNew.length) {
        each(oldThreadsMarkedAsNew, (chat) => chat.set('isNewChat', false));
      }

      set(state, 'isChatPageCreationMode', false);
      set(state, 'chatCreationThread', null);

      endPropertyChanges();
    },

    openNewSmallChat: function () {
      if (state.canOpenChatCreation) {
        this.send('setEmptyThreadToNewChat');
      } else {
        this.maximizeNewChatIfMinimized();
      }
    },

    setEmptyThreadToNewChat: function (options = {}) {
      const Thread = ThreadFactory(CurrentUserStore, this);

      let newChat,
        chatProperties = {
          isNewChat: true,
          waitingForCreation: true,
          messagesInitied: true,
          selectedUsers: A(),
          selectedItems: A(),
          expandedChatSize: options.expandedChatSize, // preserve size
          newMessage: options.newMessage,
        };

      beginPropertyChanges();

      if (options.userToStartChatWith) {
        let participants = ChatUtils.participantsToObj([options.userToStartChatWith, CurrentUserStore.getState()]);

        newChat = state.threads.find((t) => {
          return (
            !t.id &&
            t.get('observableOthers.length') === 1 &&
            t.get('observableOthers')[0].id === options.userToStartChatWith.id
          );
        });

        if (newChat) {
          newChat.setProperties(chatProperties);
        } else {
          newChat = Thread.create(chatProperties);
        }

        newChat.setProperties({
          selectedItems: A([{ user: options.userToStartChatWith, isSuggestion: true }]),
          participants: A(participants),
          selected: true,
          chatType: options.userToStartChatWith.isContact ? 'UserChat' : 'UserChatRequest',
        });
      } else {
        newChat = Thread.create(chatProperties);
      }

      set(state, 'chatCreationThread', newChat);

      // empty thread was set to chatCreationThread but push it to openedThreads only if it's not there yet
      if (state.get('canOpenChatCreation') && !state.get('openedThreads').includes(newChat)) {
        state.get('openedThreads').pushObject(newChat);
      }

      endPropertyChanges();
    },

    // creates a temporary thread that is replaced by response from api call, call is made when first msg is added into the thread
    createThreadForParticipants(users, options = {}) {
      if (!users || !users.length) return;

      const currentUser = CurrentUserStore.getState();
      const Thread = ThreadFactory(CurrentUserStore, this);

      let participants = ChatUtils.participantsToObj(users);

      // add current user to participants - it's important that current user is present in participants,
      // because later when checking if thread for given participants exists we would match some other thread
      // where userB is the only participant (so it's look like we're creating thread that already exists)
      if (!participants.find((p) => currentUser.get('id') === (p.id || p.userId))) {
        let currentUserAsParticipant = ChatUtils.chatParticipantObj(currentUser);
        participants = participants.concat(currentUserAsParticipant);
      }

      let participantsString = participants
        .map((p) => p.id || p.userId)
        .sort()
        .join(',');
      let participantsAreEqual = (thread) => thread.get('participantsString') === participantsString;

      // dont proceed if a thread is already being created for the same participants
      if (options.isNewChat) {
        let creationThread = state.get('chatCreationThread');
        if (creationThread && participantsAreEqual(creationThread)) {
          return;
        }
      } else {
        // non-existing thread can be opened already (SG-38464, SG-42110)
        let openedThread = state.get('openedThreads').find(participantsAreEqual);
        if (openedThread) return;

        let foundThread =
          state.get('threads').find(participantsAreEqual) || state.get('chatRequests').find(participantsAreEqual);

        if (foundThread) {
          if (!state.get('isChatPageCreationMode')) {
            if (state.get('isChatPage') || !foundThread.get('open')) {
              this.send('openThread', foundThread, false); // display already existing mini chat that was waiting for creation in chats list, instead of creating new
            }
          }
          return;
        }
      }

      // when chat opening comes from notification then users have no data if they are contacts
      // and there wouldn't be an option to chat if there were not so 'isContact' needs to be added to don't open ChatRequest but UserChat
      if (options.userIsContact) {
        each(participants, (p) => {
          if (p.id !== CurrentUserStore.getState().get('id')) set(p, 'isContact', true);
        });
      }

      let newThread = Thread.create({
        isNewChat: !!options.isNewChat,
        messagesInitied: true,
        startedBy: currentUser.get('id'),
        participants: participants,
        waitingForCreation: true,
        expandedChatSize: options.expandedChatSize, // preserve size
      });

      if (options.isNewChat) {
        newThread.set('selectedItems', state.get('chatCreationThread.selectedItems'));

        let oldChatCreationThread = state.get('chatCreationThread'),
          chatCreationThreadIndex = state.get('openedThreads').indexOf(oldChatCreationThread);

        this.send('closeSmallChat', oldChatCreationThread);
        state.set('chatCreationThread', newThread);
        // empty thread was set to chatCreationThread but push it to openedThreads only if it's not there yet
        if (state.get('canOpenChatCreation')) {
          let openedThreads = state.get('openedThreads');

          if (chatCreationThreadIndex !== -1) {
            // keep old chat creation thread index
            let newThreads = A(openedThreads.slice(0, chatCreationThreadIndex));
            newThreads.pushObject(newThread);
            newThreads.pushObjects(openedThreads.slice(chatCreationThreadIndex));
            state.set('openedThreads', newThreads);
          } else {
            state.get('openedThreads').pushObject(newThread);
          }
        }
      } else if (newThread.get('observableOthers.length') === 1 && !newThread.get('observableOthers')[0].isContact) {
        newThread.set('chatType', 'UserChatRequest');
        state.get('chatRequests').unshiftObject(newThread);
      } else {
        state.get('threads').unshiftObject(newThread);
      }

      if (!state.isChatPageCreationMode) {
        this.send('openThread', newThread, false, { doNotChangeThread: true });
      }
    },

    focusChat(thread) {
      state.openedThreads.forEach((t) => {
        if (t !== thread && t.chatFocused) {
          t.set('chatFocused', false);
        }
      });
      if (!thread.chatFocused) {
        thread.set('chatFocused', true);
      }
    },

    storeCallWindow(callWindow) {
      state.set('callWindow', callWindow);
    },
  },
});

export default store.create();
