import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class MwChatAvatar extends Component {
  @service chat;
  @service dynamicPopups;

  @reads('args.thread') thread;

  constructor() {
    super(...arguments);
    this.showProfilePopupBind = this.showProfilePopup.bind(this);
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  showProfilePopup() {
    if (!this.showMiniProfile) return;
    if (this.args.preventProfilePopup) return;

    if (this.isDestroyed && !this.isDestroying) return;

    this.dynamicPopups.openPopup('mw-profile-popup', {
      parent: this.element,
      avatar: this.miniProfileAvatar,
      owner: this.miniProfileOwner,
    });
  }

  @computed('thread.{isGroupChat,isEventChat}')
  get showGroupAvatar() {
    return this.thread?.isGroupChat || this.thread?.isEventChat;
  }

  @computed('thread.isUserChat', 'args.{withMiniProfile,isInTopMenu}')
  get showMiniProfile() {
    return (this.thread?.isUserChat || this.args.withMiniProfile) && !this.args.isInTopMenu;
  }

  @computed('thread.{isUserChat,getUserChatParticipant}', 'args.contact')
  get miniProfileOwner() {
    if (this.thread?.isUserChat) return this.thread?.getUserChatParticipant;
    else if (this.args.contact) return this.args.contact;
  }

  @computed('thread.isUserChat', 'thread.multiChatAvatars.urls')
  get miniProfileAvatar() {
    if (this.thread?.isUserChat && this.thread?.multiChatAvatars?.url) {
      return this.thread?.multiChatAvatars.url;
    }
  }

  @action
  showUserProfile() {
    if (this.thread?.multiChatAvatars?.othersCount > 1) {
      return false;
    }

    if (this.args.location === 'topMenuChatPage') {
      // don't open profile when clicking in chat thread lists, we need to open chat thread in that case
      this.chat.showUserProfile(this.thread);

      if (this.profilePopup) {
        this.profilePopup.send('close');
      }
    }
  }

  @action
  showUnreadMessages() {
    this.chat.selectThreadById(this.thread?.id);
  }
}
