import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

import { getQueryStringParams, extractParamsFromPostLink } from 'mewe/shared/utils';
import { decodeBase64 } from 'mewe/utils/miscellaneous-utils';

export default class ToFrontRoute extends Route {
  @service router;

  beforeModel() {
    const queryParams = getQueryStringParams();
    const pathname = decodeBase64(queryParams.uri);

    if (!pathname?.length) {
      this.router.transitionTo('error-404');
    }

    // ** Formats of current post links:
    // public link to post: /post/show/{postId}?queryParams |
    // in app link to post: /myworld/show/{postId}?queryParams |
    // optional queryParams: eventId, groupId, ppId.
    //
    // ** Format of legacy links:
    // /{scope}/{scopeId}/show/{postId} (scope: event, group) - for group/event links
    // /{publicLinkId}/show/{postId} - for page links

    // ** What happens in this route?
    // all links in format */show/* are encoded by BE to base64 format, and redirected
    // to this route in such format: /to-front?uri={base64encodedLink}
    // then we decode the URI param and display the post

    // NOTE: in local development BE doesn't do this redirection to this route.
    // If you want to test this then first try opening public post on master
    // and copy the redirection url from browser, then use the /to-front?uri=...
    // part and manually add it to localhost address. Or encode the url manually

    if (~pathname.indexOf('/show/')) {
      const postLinkParams = extractParamsFromPostLink(pathname);
      const scope = postLinkParams.scope;
      const isLegacyPageLink = scope && scope !== 'post' && scope !== 'myworld' && scope !== 'group' && scope !== 'event';

      if (!postLinkParams.postId) {
        return this.router.transitionTo('error-404');
      }

      if (isLegacyPageLink) {
        postLinkParams.queryParams.ppId = scope;
      }

      // handling legacy links, to be removed after grace period
      if (scope === 'group' || scope === 'event') {
        this.router.transitionTo('app.myworld.single-post', postLinkParams.postId, { queryParams: postLinkParams.queryParams });
      } else {
        this.router.transitionTo('public-post', postLinkParams.postId, { queryParams: postLinkParams.queryParams });
      }
    }
  }

}
