import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { set } from '@ember/object';

import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwSmartSearchPage extends Component {
  @service router;

  @action
  openDetails() {
    this.fullDescriptionOpened = !this.fullDescriptionOpened;
  }

  @action
  goToPage() {
    this.router.transitionTo('app.publicid', this.args.page.publicLinkId);
  }

  @action
  follow() {
    dispatcher.dispatch('pages', 'toggleFollow', this.args.page, true);
    set(this, 'args.page.isFollower', true);
    FunctionalUtils.info(__('You are following {pageName}', { pageName: this.args.page.name }));
  }
}
