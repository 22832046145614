import GroupApi from 'mewe/api/group-api';
import GroupStore from 'mewe/stores/group-store';
import dispatcher from 'mewe/dispatcher';

export default function (groupId, options = {}) {
  GroupApi.fetchGroupData(groupId)
    .then((data) => {
      // public groups that user is not a member of and not invited to
      // should redirect to group public page
      if (!data.isConfirmed && !data.invitedBy && data.isPublic) {
        dispatcher.dispatch('public-pages', 'fetchPublicGroupById', groupId, options.postId);
      } else {
        GroupStore.send('handleOne', data);
        options.doneCallback?.();
      }
    })
    .catch((data = {}) => {
      // data defaults to {}, sometimes it's undefined according to sentry
      data.id = groupId;
      GroupStore.send('failToFetchData', data);

      let group = GroupStore.getState({ id: groupId });
      if (group && group.isPublic === false) {
        // this can only be fired when group is not public, for public group redirect to join is fired by "fetchPublicGroupById"
        options.notFoundCallback?.();
      } else {
        dispatcher.dispatch('public-pages', 'fetchPublicGroupById', groupId);
      }
    });
}
