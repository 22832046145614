import EmberObject from '@ember/object';
import CurrentUserStore from 'mewe/stores/current-user-store';
import EventsApi from 'mewe/api/events-api';
import { getOrCreate } from 'mewe/utils/fp';
import { ds } from 'mewe/stores/ds';

let Model = EmberObject.extend({
  owned: EmberObject.create({
    past: 0,
    upcoming: 0,
  }),
  all: 0,
  past: 0,
  upcoming: 0,
  birthdays: 0,
  invitations: 0,
});

export function getCounters(id) {
  return getOrCreate(ds.eventsCounters, Model, id);
}

export function fetchEventsCounters(scope = 'events', callback = null) {
  let counters = getCounters(scope);

  CurrentUserStore.getState().deferred.promise.then(function () {
    EventsApi.getCounters(scope).then((data) => {
      counters.setProperties(data);

      if (typeof callback === 'function') callback();
    });
  });
}
