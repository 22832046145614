import Component from '@glimmer/component';
import { action, set } from '@ember/object';
export default class MwFollow extends Component {
  @action
  startEditing() {
    this.args.closePostDropdown();

    set(this.args.post, 'isEditingSchedule', true);
  }
}
