import { htmlSafe } from '@ember/template';
import { imgHost } from './img-host';
import { templatedString } from './templated-string';
import { isBoolean } from 'lodash';

// e.g. /api/v2/poll/{id}/photo/full/0?static={static} -> https://mewe.com/api/v2/poll/{id}/photo/full/0?static=0&download
//      /api/v2/photo/{id}/full/{name} defaultExt="jpg" -> https://mewe.com/api/v2/photo/{id}/full/{name}.jpg/download
// https://sgrouples.atlassian.net/browse/SG-24338

export function imgDownloadPath(value, options, useValue) {
  const templatedStringOpts = {
      imageSize: 'full',
      static: '0',
    },
    imgHostOpts = {
      noApiPrefix: true,
    };

  if (options) {
    if (options.imageSize) templatedStringOpts.imageSize = options.imageSize;
    if (isBoolean(options.noApiPrefix)) imgHostOpts.noApiPrefix = options.noApiPrefix;

    if (options.mime) {
      const ext = options.mime.split('/').pop();
      if (ext) imgHostOpts.defaultExt = ext;
    } else if (options.defaultExt) {
      imgHostOpts.defaultExt = options.defaultExt;
    }
  }

  let imgLink = imgHost([templatedString(value, templatedStringOpts, useValue)], imgHostOpts);

  const imgLinkParamIndex = imgLink?.indexOf('?');
  const addDownloadPath = (imgLink) => imgLink + '/download';

  if (imgLinkParamIndex !== -1 && imgLink) {
    const imgLinkParams = imgLink.slice(imgLinkParamIndex);

    imgLink = addDownloadPath(imgLink.slice(0, imgLinkParamIndex));

    imgLink += imgLinkParams;
  } else {
    imgLink = addDownloadPath(imgLink);
  }

  if (options.fileName) {
    // chat images have /image/ part, in other cases in media dialog there is /img/
    imgLink = imgLink.replace(/(?:\/img\/|\/image\/)/, `/${options.fileName}.jpeg/`);
  }

  return htmlSafe(imgLink).toString();
}
