/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { Theme } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';

export default class SidebarLeftMyworld extends Component {
  @service account;
  @service dynamicDialogs;
  @service router;

  @action
  createNewChat() {
    dispatcher.dispatch('chat', 'createNewChat');
  }

  @action
  openPublicDirectoryDialog(isBrowserPages) {
    this.dynamicDialogs.openDialog('browse-communities-dialog', {
      theme: isBrowserPages ? Theme.PAGE : Theme.GROUP,
    });
  }

  @action
  openInvitationsDialog() {
    dispatcher.dispatch('contact', 'openContactInvitationDialog');
  }

  @action
  openMyProfile() {
    this.router.transitionTo('app.publicid', this.account.activeUser.publicLinkId);
  }

  @action
  openInterestsPicker() {
    openFtueRecommendations(this.dynamicDialogs, 'myworld-sidebar');
  }
}
