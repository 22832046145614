import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';

import toDisplay from 'mewe/stores/text-parsers/to-display';
import htmlParser from 'mewe/stores/text-parsers/html-parser';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
let { isDefined } = MiscellaneousUtils;
import { imgFormats } from 'mewe/constants';
import { openSinglePost } from 'mewe/utils/open-post-details';

export default class MwPostLink extends Component {
  @reads('args.post') post;
  @reads('args.post.link') link;

  maxDescriptionHeight = 30;

  @computed('link.thumbnailSize', 'post.isComment', 'isImageLink', 'args.isSharedPost', 'isYouTubeLink')
  get verticalVersion() {
    if (this.isImageLink && this.post.isComment) return '';
    if (this.isYouTubeLink) return 'post-link--horizontal';

    if (this.post.isComment || (this.args.isSharedPost && !this.isImageLink)) {
      return 'post-link--vertical post-link--vertical-small';
    }

    const width = this.link?.thumbnailSize?.width;
    const height = this.link?.thumbnailSize?.height;
    // if image is less than 450, then we need to display it as vertical photo, otherwise it will be upscaled and blurred
    if (isDefined(height) && isDefined(width) && (height > width || height === width || width < 450)) {
      return 'post-link--vertical';
    }

    return 'post-link--horizontal';
  }

  @computed('link._links.thumbnail.href')
  get thumbnailHref() {
    const href = this.link?._links?.thumbnail?.href;

    if (href.indexOf('http') === 0) return href; // external thumbnails url

    return EnvironmentUtils.addImgHost(href);
  }

  @computed('link._links.thumbnail.href')
  get hasImage() {
    const href = this.link?._links?.thumbnail?.href;
    // if there is no preview image then link has format "/api/v2/proxy/img?target=" and shouldn't be displayed
    return href && href !== '/api/v2/proxy/img?enc=b64&target=';
  }

  @computed('thumbnailHref')
  get backgroundImageStyle() {
    return htmlSafe(`background-image: url(${this.thumbnailHref})`);
  }

  @computed('link._links.urlHost.href', 'link._links.url.href')
  get isYouTubeLink() {
    const href = this.link?._links?.url?.href;
    const hostHref = this.link?._links?.urlHost?.href;

    // hack for SG-16149
    if (String.prototype.endsWith) {
      if (href) {
        if (href.endsWith('youtube.com') || href.endsWith('youtube.com/')) return false;
      }
    }

    // if link is youtube.com/shared, generate image - #SG-9473
    return (
      (href.indexOf('youtube.com/shared') === -1 && hostHref === 'youtube.com') ||
      hostHref === 'www.youtube.com' ||
      hostHref === 'm.youtube.com' ||
      hostHref === 'youtu.be' ||
      href.indexOf('youtu.be') !== -1
    );
  }

  @computed('link._links.url.href')
  get isImageLink() {
    const linkUrl = this.link._links.url.href;
    const linkUrlExtension = linkUrl.toLowerCase().slice(linkUrl.lastIndexOf('.') + 1);

    return linkUrlExtension && new RegExp(imgFormats.join('|')).test(linkUrlExtension);
  }

  @computed('link.title')
  get linkTitle() {
    if (this.link.title) {
      return this.link.title.substring(0, 1000);
    }
  }

  @computed('link.description')
  get descriptionDisplay() {
    if (this.link.description) {
      const linkDescription = this.link.description.substring(0, 2000);
      return toDisplay(linkDescription, [htmlParser]); // \n parsed into <br>
    }
  }

  @action
  onClick(e) {
    // don't open link on click when it's edited
    const isMewePost = openSinglePost(this.link._links.url.href);
    if (isMewePost) {
      e.preventDefault();
    }

    if (this.args.isInEditState) {
      e.preventDefault();
      return false;
    }
  }

  @action
  removeLink(e) {
    e.preventDefault();
    this.post.set('removeLink', true);
  }
}
