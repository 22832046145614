import Component from '@glimmer/component';
import { computed, set, action } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';

import Scrolling from 'mewe/utils/scrolling-utils';
import PostApi from 'mewe/api/post-api';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import PS from 'mewe/utils/pubsub';
import { maxResultsTagsList } from 'mewe/constants';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';

export default class MwPageWhatsNew extends Component {
  @service dynamicDialogs;

  @tracked isLoadingTags;
  @tracked tagsSearch;
  @tracked tags = A();

  @action
  onInsert(element) {
    this.element = element;

    this.scrolling = Scrolling();

    // publicLinkId indicates that page was already loaded
    if (this.args.page.publicLinkId) {
      this.initialise();
    }

    // observe for switching page to re-initialise
    addObserver(this, 'args.page.publicLinkId', this.initialise);
    addObserver(this, 'args.selectedTag', this.selectTag);

    this.postAddBind = this.postAdd.bind(this);
    PS.Sub('post.add', this.postAddBind);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.whats-new_content-tags'));

    removeObserver(this, 'args.selectedTag', this.selectTag);
    removeObserver(this, 'args.page.publicLinkId', this.initialise);

    PS.Unsub('post.add', this.postAddBind);
  }

  initialise() {
    this.resetTags();
    this.loadTags();
  }

  resetTags() {
    this.tagsSearch = '';
    this.tags = A();
  }

  @computed('tags.length', 'isLoadingTags')
  get showNoResultsTags() {
    return this.tags.length === 0 && !this.isLoadingTags;
  }

  loadTags(reload) {
    if (this.isLoadingTags || !this.args.page.id) return;

    const options = {
      maxResults: maxResultsTagsList,
      offset: reload ? 0 : this.tags.length,
    };

    this.isLoadingTags = true;

    if (this.tagsSearch) {
      options.term = this.tagsSearch;
    }

    if (this.args.page.isOpenPage) {
      PublicPagesApi.getPageHashTags(options, this.args.page.id)
        .then((d) => {
          if (this.isDestroyed || this.isDestroying) return;

          if (d.hashtags && (options.term === this.tagsSearch || (!options.term && this.tagsSearch === ''))) {
            if (options.offset === 0) {
              this.tags = A(d.hashtags);
            } else {
              this.tags.pushObjects(d.hashtags);
            }

            if (d.hashtags.length >= maxResultsTagsList) {
              this.scrolling.bindScrollDownElement(this.element.querySelector('.tags-content'), () => this.loadTags());
            } else {
              this.scrolling.unbindScrollDown(this.element.querySelector('.tags-content'));
            }

            if (this.args.selectedTag) {
              this.selectTag();
            }
          }
        })
        .finally(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.isLoadingTags = false;
        });
    } else {
      PostApi.getHashTags(options, 'page', this.args.page.id)
        .then((d) => {
          if (this.isDestroyed || this.isDestroying) return;

          if (d.hashtags && (options.term === this.tagsSearch || (!options.term && this.tagsSearch === ''))) {
            if (options.offset === 0) {
              this.tags = A(d.hashtags);
            } else {
              this.tags.pushObjects(d.hashtags);
            }

            if (d.hashtags.length >= maxResultsTagsList) {
              this.scrolling.bindScrollDownElement(this.element.querySelector('.tags-content'), () => this.loadTags());
            } else {
              this.scrolling.unbindScrollDown(this.element.querySelector('.tags-content'));
            }

            if (this.args.selectedTag) {
              this.selectTag();
            }
          }
        })
        .finally(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.isLoadingTags = false;
        });
    }
  }

  postAdd(data) {
    if (this.args.page.isOpenPage) return;
    if (data.post.pageId === this.args.page.id) {
      if (this.tabActive === 'tags' && data.post.hashTags) this.loadTags(true);
    }
  }

  selectTag() {
    if (this.args.page.isOpenPage) return;
    const tag = this.tag;
    let currentTag = this.tags.filter((t) => t.name === tag);
    let selected = this.tags.filter((t) => t.selected);

    if (selected !== currentTag) {
      set(selected, 'selected', false);
    }

    if (!currentTag.selected) {
      set(currentTag, 'selected', true);
    }
  }

  @action
  clearSearch() {
    this.resetTags();
    this.loadTags();
  }

  @action
  searchTags(tagsSearch) {
    if (this.args.page.isOpenPage) return;
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;

      this.tagsSearch = tagsSearch;
      this.tags = A();
      this.loadTags();
    }, 500);
  }

  @action
  openPostBoxDialog() {
    if (this.args.page.isOpenPage) return;
    if (this.args.page) {
      const params = {
        theme: Theme.PAGE,
        target: Theme.PAGE,
        page: this.args.page,
      };
      openPostbox(params, this.dynamicDialogs);
    } else {
      return console.warn(`Page is undefined - can't open a postbox!`);
    }
  }

  @action
  clickHandler(e) {
    if (this.args.page?.isOpenPage && this.args.page?.customInteraction) {
      e.preventDefault();
      this.args.page?.customInteraction(this.dynamicDialogs, this.args.page);
      return;
    }
  }
}
