import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class MwCommentsButton extends Component {
  @action
  onClick() {
    const post = this.args.post;

    if (this.args.forSnippet) return;
    if (post.isPublicContent) return;
    if (post.isGroupPreview) return;

    if (!post.initialCommentsSet) {
      const doWithPost = (p) => {
        if (p.get('comments.feed.length')) {
          post.set('comments', p.get('comments'));
          this.args.toggleComments();
        } else {
          this.args.toggleComments();
        }

        post.set('initialCommentsSet', true);
      };

      dispatcher.dispatch('feed', 'fetchPostFromMemoryOrServer', {
        postItemId: post.postItemId,
        groupId: post.group.id,
        threadId: post.threadId,
        pageId: post.pageId,
        scope: this.args.scope,
        fetchingPostForDialog: true,
        doWithPost: doWithPost,
      });
    } else {
      this.args.toggleComments();
    }
  }
}
