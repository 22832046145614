import i18n from 'mewe/shared/i18n/Translation';
import fallbackToMoment from 'mewe/shared/fallback-to-moment';

import { getStorageCurrentUser } from 'mewe/shared/storage';
import cookie from 'mewe/shared/cookie';

var QueryString = (function () {
  // This function is anonymous, is executed immediately and
  // the return value is assigned to QueryString!
  var query_string = {};
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    // If first entry with this name
    if (typeof query_string[pair[0]] === 'undefined') {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof query_string[pair[0]] === 'string') {
      var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
      query_string[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return query_string;
})();

export const initialize = (application) => {
  application.deferReadiness();

  if (location.pathname.indexOf('/500') === 0) {
    return startAppWithLocale(QueryString.locale || cookie.get('locale'), application); // if no query param and no cookies, leave it for language detection in Translation, init
  }

  let cacheUser = getStorageCurrentUser();

  if (cacheUser) {
    startAppWithLocale(cacheUser.locale, application);
  } else {
    startAppWithLocale(QueryString.locale || cookie.get('locale') || 'en', application);
  }
};

const startAppWithLocale = (locale, application) => {
  const boot = (moment) => {
    i18n(moment)(locale, {}, function (initedI18n) {
      window.moment = moment;

      let existingLang = document.documentElement.getAttribute('lang');
      if (!existingLang || existingLang !== initedI18n.language) {
        document.documentElement.setAttribute('lang', initedI18n.language); // it sets "lang" attribute on "html" tag
      }
      if (initedI18n.language === 'ar' || initedI18n.language === 'he') {
        document.documentElement.setAttribute('dir', 'rtl');
      } else {
        document.documentElement.removeAttribute('dir');
      }
      window.__ = initedI18n.getFixedT();

      if (!application._booted) application.advanceReadiness();
    });
  };

  if (fallbackToMoment(locale)) {
    import('mewe/moment').then((moment) => import('mewe/moment-timezone').then(() => boot(moment.default)));
  } else boot();
};

export default {
  name: 'translation',

  initialize,
};
