import { decamelize } from '@ember/string';
import { isArray, A } from '@ember/array';
import EmberObject, { get } from '@ember/object';
import JSONTransforms from '../transforms/json';
import Model from '../model/model';
//TODO: this need to be updated with run from the runloop module
import { beginPropertyChanges, endPropertyChanges } from '@ember/-internals/metal';

var JSONSerializer = EmberObject.extend({
  updateInstance(resource, data) {
    var prop;

    beginPropertyChanges(resource);

    for (prop in data) {
      if (data.hasOwnProperty(prop)) {
        var attrName = this.attributeNameForKey(resource.constructor, prop);
        if (get(resource, attrName) != data[prop]) this.deserializeProperty(resource, prop, data[prop]);
      }
    }

    endPropertyChanges(resource);
  },

  deserialize: function (resource, data) {
    var key, prop, meta;

    if (!data) {
      return resource;
    }

    key = this._keyForResource(resource);
    if (data[key]) {
      data = data[key];
    }

    meta = this.extractMeta(data);
    if (meta) {
      resource.set('meta', meta);
    }

    beginPropertyChanges(resource);
    for (prop in data) {
      if (data.hasOwnProperty(prop)) {
        this.deserializeProperty(resource, prop, data[prop]);
      }
    }
    resource.setProperties({
      // isLoaded: true, // TODO are we using it anyway?
      isDirty: false,
    });
    endPropertyChanges(resource);

    return resource;
  },

  deserializeProperty: function (resource, prop, value) {
    var attrName = this.attributeNameForKey(resource.constructor, prop);

    var fields = get(resource.constructor, 'fields');

    if (!fields) {
      return;
    }

    var field = fields[attrName];

    var type, klass, belongsToModel, hasManyArr;

    if (!field) {
      return;
    }

    type = field.type;

    if (field.hasMany) {
      hasManyArr = this.deserializeMany(resource.get(attrName), type, value, attrName);
      resource.set(attrName, hasManyArr);
    } else if (field.belongsTo && value) {
      klass = type;

      if (klass) {
        belongsToModel = this.deserialize(klass.create(), value);
        resource.set(attrName, belongsToModel);
      }
    } else {
      // Check for a custom transform
      if (type && JSONTransforms[type]) {
        value = JSONTransforms[type].deserialize(value);
      }
      resource.set(attrName, value);
    }
  },

  deserializeMany: function (recordArray, klass, data, keyPlural, extendData) {
    var arrayData, meta, i, len, item, content;

    if (!data) {
      return recordArray;
    }

    if (!keyPlural) {
      keyPlural = klass.resourceName + 's';
    }

    if (isArray(data)) {
      arrayData = data;
    } else {
      arrayData = data[keyPlural];
    }

    if (!arrayData) {
      return recordArray;
    }

    if (recordArray) {
      recordArray.set('isLoaded', false);
      recordArray.clear();
    } else {
      recordArray = A();
    }

    len = arrayData.length;
    if (len) {
      content = A();
      for (i = 0; i < len; i++) {
        item = arrayData[i];
        var resource;
        if (klass && typeof item === 'object') {
          resource = klass.create({
            isNew: false,
          });
          if (extendData) resource.setProperties(extendData);
          this.deserialize(resource, item);
        }
        content.push(resource);
      }
      recordArray.pushObjects(content);
    }

    // extract any meta info
    meta = this.extractMeta(data);
    if (meta) {
      recordArray.set('meta', meta);
    }

    recordArray.setProperties({
      isLoaded: true,
      isDirty: false,
    });
    return recordArray;
  },

  deserializeAll: function (klass, data, extendData, keyPlural) {
    return this.deserializeMany(A(), klass, data, keyPlural, extendData);
  },

  deserializeOne: function (klass, data, extendData, keyPlural) {
    return this.deserializeMany(A(), klass, [data], keyPlural, extendData).pop();
  },

  serialize: function (resource, options) {
    var fields, json, field, fieldMeta, value, wrapped;

    if (!resource) {
      return null;
    }

    fields = get(resource.constructor, 'fields');
    json = {};

    for (field in fields) {
      if (fields.hasOwnProperty(field)) {
        fieldMeta = fields[field];

        if (
          !fieldMeta.readOnly &&
          (!fieldMeta.belongsTo || (fieldMeta.belongsTo && options && options.includeRelationships))
        ) {
          value = this.serializeProperty(resource, field, fieldMeta);
          if (value !== null) {
            json[this.keyForAttributeName(field)] = value;
          }
        }
      }
    }

    if (options && options.nonEmbedded) {
      return json;
    }
    wrapped = {};
    wrapped[this._keyForResource(resource)] = json;
    return wrapped;
  },

  serializeProperty: function (resource, prop, opts) {
    var type, value, transform;

    opts = opts || resource.constructor.metaForProperty(prop);
    type = opts.type;
    value = resource.get(prop);

    if (opts.hasMany) {
      return this.serializeMany(value, type);
    } else if (opts.belongsTo) {
      return this.serialize(value, {
        nonEmbedded: true,
      });
    }

    //Check for a custom transform
    transform = JSONTransforms[type];
    if (opts.type && transform) {
      value = transform.serialize(value);
    }
    return value;
  },

  serializeMany: function (recordArray, type) {
    var key = this._keyForResourceType(type);
    var array = recordArray.get('content');
    var len = array.length;
    var result = A(),
      i,
      item;

    for (i = 0; i < len; i++) {
      item = array[i];
      if (Model.detectInstance(item)) {
        item = item.serialize();
      }
      result.push(item[key]);
    }
    return result;
  },

  keyForResourceName: function (name) {
    return name ? decamelize(name) : null;
  },

  keyForAttributeName: function (name) {
    return name ? decamelize(name) : null;
  },

  attributeNameForKey: function (klass, key) {
    // check if a custom key was configured for this property
    var keys = get(klass, 'aliases');

    if (keys && keys[key]) {
      return keys[key];
    }

    // caused problems with _links String.camelize('_links') => 'links'
    //return String.camelize(key);
    return key;
  },

  prepareData: function (data) {
    return JSON.stringify(data);
  },

  parseError: function (error) {
    var errorData = null;
    try {
      errorData = JSON.parse(error);
    } catch (e) {}
    return errorData;
  },

  extractMeta: function (json) {
    return json && json.meta;
  },

  registerTransform: function (type, transform) {
    JSONTransforms[type] = transform;
  },

  _keyForResource: function (resource) {
    return this.keyForResourceName(get(resource.constructor, 'resourceName'));
  },

  _keyForResourceType: function (type) {
    var klass = this.modelFor(type);
    return klass ? this.keyForResourceName(get(klass, 'resourceName')) : 'model';
  },
});

export default JSONSerializer;

export const serializer = JSONSerializer.create();
