import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Home/MwHomeDsnpFaq',
  component: 'MwHomeDsnpFaq',
};

const Template = () => ({
  template: hbs`<Home::MwHomeDsnpFaq />`,
  context: {},
});

export const Default = Template.bind({});
