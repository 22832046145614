import Helper from '@ember/component/helper';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import LinksParser from 'mewe/stores/text-parsers/links-parser';
import HtmlParser from 'mewe/stores/text-parsers/html-parser';
import Mentions from 'mewe/utils/mentions-utils';
import MarkdownParser from 'mewe/stores/text-parsers/markdown-parser';

import PS from 'mewe/utils/pubsub';
import { escape } from 'lodash';

export default Helper.extend({
  init() {
    this._super(...arguments);

    let callback = () => {
      this.recompute();
      PS.Unsub('emoji.hashmap.ready', callback);
    };

    PS.Sub('emoji.hashmap.ready', callback);
  },

  compute(value, options) {
    let text = value[0];

    if (!text?.length) return '';

    if (!options.notEscape) text = escape(text);

    if (options.displayMarkdown) text = MarkdownParser.toDisplay(text, options);

    if (options.allowHtml) text = HtmlParser.toDisplay(text);

    if (options.allowLink) text = LinksParser.toDisplay(text);

    if (options.parseEmoji !== false) text = EmojisParser.toDisplay(text, options);

    // show @name instead of {@{userId}}
    if (options.stripMentions) text = Mentions.stripMentions(text);

    if (options.singleLine) {
      text = text.replace(/<br>/g, ' ');
    }

    return text;
  },
});
