import ApiUtil from './api';
import { TellerType } from 'mewe/constants';

class Api extends ApiUtil {
  tellers() {
    return this.get('/stories/feed', {}, 'apiRoot3');
  }

  entriesByTeller({ id, tellerType }) {
    switch (tellerType) {
      case TellerType.PAGE:
        return this.get(`/page/${id}/stories`, {}, 'apiRoot3');
        break;
      default:
        return this.get(`/stories/${id}`, {}, 'apiRoot3');
        break;
    }
  }

  entriesByJournal({ tellerId, journalId, nextPage }) {
    if (nextPage) return this.get(nextPage);
    else return this.get(`/journals/${tellerId}/show/${journalId}`, {}, 'apiRoot3');
  }

  journalsBy({ id }) {
    return this.get(`/journals/${id}`, {}, 'apiRoot3');
  }

  markEntrySeen({ views }) {
    return this.post(
      '/stories/mark-seen',
      {
        data: JSON.stringify({
          views: views,
        }),
      },
      'apiRoot3'
    );
  }

  createNewStory(scope, params) {
    return this.post(`/story/${scope}`, { data: JSON.stringify(params) }, 'apiRoot3');
  }

  deleteEntry({ id, scope, pageId }) {
    if (pageId) {
      return this.del(`/page/${pageId}/story/${id}`, {}, 'apiRoot3');
    } else if (scope) {
      return this.del(`/story/${scope.toLowerCase()}/${id}`, {}, 'apiRoot3');
    }
  }

  removeEntryFromJournal({ journalId, params }) {
    return this.post(
      `/api/v3/journal/${journalId}/remove-story`,
      {
        data: JSON.stringify(params),
      },
      'apiRoot3'
    );
  }

  commentEntry({ tellerId, entryId, journalId = '', params }) {
    const journalParam = journalId ? `?journalId=${journalId}` : '';

    return this.post(
      `/stories/${tellerId}/${entryId}/reply${journalParam}`,
      {
        data: JSON.stringify(params),
      },
      'apiRoot3'
    );
  }
}

export default new Api();
