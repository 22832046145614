import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Scrolling from 'mewe/utils/scrolling-utils';
import { fetchBookmarks } from 'mewe/fetchers/fetch-bookmarks';
import { addObserver, removeObserver } from '@ember/object/observers';
import BookmarksApi from 'mewe/api/bookmarks-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class AppProfileBookmarks extends Component {
  @service account;
  @service router;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    this.bindScrolling();
    addObserver(this, 'args.model.bookmarks.nextPage', this.bindScrolling);
  }

  get bookmarks() {
    return this.args.model.bookmarks.items;
  }

  willDestroy() {
    removeObserver(this, 'args.model.bookmarks.nextPage', this.bindScrolling);
    this.scrolling.unbindScrollDown();
  }

  bindScrolling() {
    this.scrolling.unbindScrollDown();

    if (this.args.model.bookmarks.nextPage) {
      this.scrolling.bindScrollDown(() => this.loadMore(), 1200);
    }
  }

  loadMore() {
    fetchBookmarks({
      nextPage: this.args.model.bookmarks.nextPage,
    });
  }

  @action
  removeBookmark(bookmark) {
    BookmarksApi.removeBookmark(bookmark.id)
      .then(() => {
        this.args.model.bookmarks.items.removeObject(bookmark);
        FunctionalUtils.info('Post removed from bookmarks.');
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      });

  }
}
