import { and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { scheduleOnce, next, later } from '@ember/runloop';
import { action, computed, set } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';

import ChatStore from 'mewe/stores/chat-store';
import Scrolling from 'mewe/utils/scrolling-utils';
import ChatPhotoupload from 'mewe/utils/chat-photoupload';
import MwChatBase from 'mewe/pods/components/chats/mw-chat-base';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import toServer from 'mewe/stores/text-parsers/to-server';
import { chatWindowHeightToSubtratct, iosAppUrl, androidAppUrl } from 'mewe/constants';
import config from 'mewe/config';
import { isUndefined, isDefined } from 'mewe/utils/miscellaneous-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwChatWindow extends MwChatBase {
  @service chat;
  @service account;
  @service router;

  @tracked commonGroups;
  @tracked maxHeightTextEditor;
  @tracked sendFormTight;
  @tracked uploadInProgress;

  scrolling = Scrolling();
  scope = 'privacymail';
  processedImages = A();
  lastThreadId = null;
  isChatWindow = true;
  filesCount = 0;

  loadNewerMessagesOffset = 1200;

  iosAppUrlConst = iosAppUrl;
  androidAppUrlConst = androidAppUrl;

  constructor() {
    super(...arguments);

    ChatStore.send('minimizeAll');

    // set autocomplete if msgs were already initied and there are no messages
    // otherwise it will be done after: 'didRenderAllMessages' => if there are msgs OR 'didInitMessages' => if empty thread
    if (this.thread?.messagesInitied && !this.thread?.messages?.length) {
      this.setMentionsAndAutocomplete();
    } else {
      this.storeObserver(this, 'thread.messagesInitied', this.didInitMessages);
    }
    this.storeObserver(this, 'thread.messagesBeingFetched', this.didRenderAllMessages);
  }

  @action
  onInsert() {
    super.onInsert(...arguments);

    this.focusAndMarkAsRead();
    this.initUploads();

    this.markAsReadBind = this.markAsRead.bind(this);
    document.addEventListener('mousemove', this.markAsReadBind);

    // observer threadsLengthChanged
    if (this.state.threadsInited && this.lastThreadId && !this.state.isChatPageCreationMode) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.chat.selectFirstThreadIfNeeded(this.state.isChatRequestsPage);
      });
    }

    this.storeObserver(this, 'state.chatCreationThread.selectedItems.length', this.newChatParticipantsChanged);
    this.storeObserver(this, 'state.isChatPageCreationMode', this.newChatModeChanged);
    this.storeObserver(this, 'thread.id', this.threadChanged);
    this.storeObserver(this, 'thread.wasChatCreationThread', this.transitionToNewlyCreatedThread);
    this.storeObserver(this, 'audioRecordingBlob', this.audioRecordingBlobChanged);
    this.storeObserver(this, 'filesInProgress.length', this.updateFilesInStore);

    // these observer callbacks were called also right after first insert
    this.threadChanged();
    this.newChatModeChanged();
    this.newChatParticipantsChanged();
  }

  @action
  onDestroy() {
    super.onDestroy(...arguments);

    this.cleanupThread();

    document.removeEventListener('mousemove', this.markAsReadBind);

    if (this.uploadInProgress) {
      this.cancelAllUploads();
    }
  }

  bindPubSub() {
    super.bindPubSub(...arguments);

    this.cancelAllUploadsBind = this.cancelAllUploads.bind(this);
    PS.Sub('chat.cancel.all.uploads', this.cancelAllUploadsBind);
  }

  unbindPubSub() {
    super.unbindPubSub(...arguments);

    PS.Unsub('chat.cancel.all.uploads', this.cancelAllUploadsBind);
  }

  setMentionsAndAutocomplete() {
    this.setMentionsStrategy();
    this.bindScrollAndTextarea();
  }

  // called after each rendering of WS msg - check messageRendered in chat-mixin
  didRenderAllMessages() {
    super.didRenderAllMessages(...arguments);
    this.setMentionsAndAutocomplete();
  }

  // called from observer after `messagesInitied` is set
  didInitMessages() {
    this.allMessagesLoaded = false;
    if (this.thread?.messagesInitied && !this.thread?.messages?.length && !this.mentionsStrategy) {
      this.setMentionsAndAutocomplete();
    }
  }

  get showBadges() {
    const userChatOrRequest = this.thread.chatType == 'UserChat' || this.thread.chatType == 'UserChatRequest';

    return userChatOrRequest && this.thread.observableOthers.length < 2;
  }

  get threadUserPublicLinkId() {
    return this.thread?.getUserChatParticipant?.publicLinkId;
  }

  @computed('filesInProgress.length')
  get filesInProgressFiltered() {
    return this.filesInProgress.filter((f) => !f.isVoice);
  }

  @computed('state.isChatPageCreationMode', 'thread.selectedItems.length')
  get shouldDisplayMessageCreator() {
    return !this.state.isChatPageCreationMode || this.thread?.selectedItems?.length;
  }

  @computed(
    'state.{threadsToDisplay,threadsInited,chatRequestsInited,selectedThread,isChatRequestsPage,receivedChatRequests.length}'
  )
  get noThreads() {
    if (this.state.isChatRequestsPage)
      return this.state.chatRequestsInited && !this.state.selectedThread && !this.state.receivedChatRequests?.length;
    else {
      let threadsToDisplay = this.state.threadsToDisplay;
      return this.state.threadsInited && !threadsToDisplay?.length && !this.state.selectedThread;
    }
  }

  @computed('thread.{messagesInitied,messages.length}')
  get noMessages() {
    return this.thread?.messagesInitied && !this.thread?.messages?.length;
  }

  @computed('thread.{isNewChat,selectedItems.length}')
  get newChatContainsOnlyContacts() {
    return this.thread?.isNewChat && this.thread?.selectedItems?.length;
  }

  @computed('filesInProgress.length', 'chatInfoActive')
  get hasManyFiles() {
    // two rows of files if there is more than 6 in expanded view or more that 4 in narrow view
    return this.filesInProgress.length > 6 || (this.filesInProgress.length > 4 && this.chatInfoActive);
  }

  @computed('thread.isNewChat', 'thread.selectedItems.[]', 'thread.selectedItems.@each.isSuggestion')
  get isThreadCreatedFromSuggestion() {
    return (
      this.thread?.isNewChat &&
      this.thread?.selectedItems?.length === 1 &&
      this.thread?.selectedItems?.[0]?.isSuggestion
    );
  }

  @computed('state.isChatRequestsPage', 'thread.chatType')
  get isChatRequest() {
    return this.state.isChatRequestsPage || this.thread?.chatType === 'UserChatRequest';
  }

  // only if `isReceivedChatRequest` because own chat requests are placed in regular chats list (SG-32193)
  @and('state.isChatRequestsPage', 'thread.isReceivedChatRequest') showCommonGroupsInfo;

  @computed('isAudioConverting', 'isAudioRecording', 'uploadInProgress', 'isSendingIntervalActive')
  get isSendingDisabled() {
    return this.isAudioRecording || this.isAudioConverting || this.uploadInProgress || this.isSendingIntervalActive;
  }

  newChatParticipantsChanged() {
    // it's not chat creation page so participants change was in small chat
    if (!this.state.isChatPageCreationMode) return;

    /**
     * prevent infinite loop when chat creation thread is replaced with a freshly
     * fetched thread by checking if particpants have changed since last call
     */
    const participantsIds = this.state.chatCreationThread?.selectedItems
      ?.map((i) => i.id)
      .sort()
      .join(',');
    if (participantsIds === this.lastParticipantsIds) return;
    else this.lastParticipantsIds = participantsIds;

    if (this.state.chatCreationThread.selectedItems.length) {
      const firstSelectedItem = this.state.chatCreationThread.selectedItems[0];
      let addTemporarilyToStore;

      // if it is group, open group chat and hide chat creation
      if (firstSelectedItem.group) {
        addTemporarilyToStore = true;
        this.chat.transitionToThreadByIdOrOpenParticipants(firstSelectedItem.group.id, null, addTemporarilyToStore);
      } else {
        const participants = this.state.chatCreationThread.selectedItems.map((i) => i.user);

        const options = {
          isNewChat: true,
        };

        /**
         * open chat with user if it exists
         * for multiuser chats we don't do this -
         * we allow creating multiple chats with same participants
         */
        if (this.state.chatCreationThread.selectedItems.length === 1) {
          this.chat.openThreadByParticipants(participants, options);
          this.setMentionsStrategy();
          this.bindScrollAndTextarea();
        } else {
          ChatStore.send('createThreadForParticipants', participants, options);
        }
      }
    } else if (this.state.chatCreationThread.participants.length) {
      ChatStore.send('setEmptyThreadToNewChat');
    }
  }

  updateFilesInStore() {
    set(this, 'state.uploadsInProgress', this.filesInProgress);
  }

  newChatModeChanged() {
    if (this.state.isChatPageCreationMode) {
      this.chatInfoActive = false;
      next(this, () => {
        if (!this.isDestroyed && !this.isDestroying) {
          const searchEl = this.element.querySelector('.h-input_search');
          if (searchEl) searchEl.focus();
        }
      });
    }
  }

  threadChanged() {
    if (!this.lastThreadId && !this.thread?.id) return;
    if (this.lastThreadId === this.thread?.id) return;

    // cleanup in last thread, do before reseting properties and setting new thread
    this.cleanupThread();

    this.lastThreadId = this.thread?.id;

    this.hasScrolledToLastReadMessage = false;
    this.firstMessagesRendered = false;
    this.lastFetchTryTime = null;
    this.newWsMsgsCount = 0; // reset counter in order to reset "new messages" button

    this.voiceRecordingVisible = false;

    this.toggleChatInfo(false);
    this.tearDownScrollObservable(true);

    this.state.threads.forEach((t) => {
      if (!t.selected) {
        /**
         * TODO: need to setup / remove all other thread.observers like this,
         * otherwise they won't be properly removed when thread changes
         */
        t.removeObserver('arrivedWsMsgsSinceModelCreated', this, 'arrivedMessagesObserver');
      }
    });

    if (this.state.chatCreationThread && isUndefined(this.state.chatCreationThread.selectedItems)) {
      set(this, 'state.chatCreationThread.selectedItems', A());
    }

    this.scrollDown(true, true); // if changed to thread with already loaded messages then replacing selected thread will keep scroll pos in chat window

    if (this.thread) {
      this.thread?.addObserver('arrivedWsMsgsSinceModelCreated', this, 'arrivedMessagesObserver');
    }

    next(this, () => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.focusAndMarkAsRead();
      }
    });

    // not sure if I understand it correctly but it should be here probably
    this.fetchChatRequestMessagesIfNotFetched();

    if (this.editor) {
      this.editor.update(this.thread?.newMessage);
      this.editor.focus();
    }
  }

  transitionToNewlyCreatedThread() {
    if (this.thread?.wasChatCreationThread) {
      set(this, 'thread.wasChatCreationThread', false);
      this.chat.transitionToThread(this.thread);
      set(this, 'state.chatCreationThread', null);
    }
  }

  audioRecordingBlobChanged() {
    const blob = this.audioRecordingBlob;

    if (!blob) {
      const voiceFile = this.filesInProgress.find((f) => f.isVoice);
      if (voiceFile) this.removeFile(voiceFile);
      return;
    }

    this.isAudioVoice = true;

    next(() => {
      if (this.voiceRecordingVisible) {
        blob.isVoice = true;
        this.chatPhotouploadFile.add([blob]);
      }
      this.isAudioVoice = false;
    });
  }

  cleanupThread() {
    if (this.lastThreadId) {
      const lastThread = ChatStore.getThreadById(this.lastThreadId);
      if (lastThread) dispatcher.dispatch('chat', 'cleanupThread', lastThread);
    }
  }

  // couldn't handle it in css: new message textarea should take maximum of half of the chat screen when long text inside
  // this height is also updated on widnow resize in routes/app-chat.js
  @action
  resizeTextarea() {
    if (!this.canChat) return;

    let maxHeight = Math.max((window.innerHeight - chatWindowHeightToSubtratct) / 2);
    let openedPanels = 0;

    if (this.voiceRecordingVisible) openedPanels++;
    if (this.thread?.replyTo) openedPanels++;
    if (this.filesInProgressFiltered.length) openedPanels++;

    // slightly decrease height if additional components are visible
    if ((window.innerHeight < 750 && openedPanels > 1) || (window.innerHeight < 650 && openedPanels > 0)) {
      maxHeight = 80;
      this.sendFormTight = true;
    } else {
      this.sendFormTight = false;
    }

    this.maxHeightTextEditor = maxHeight;
  }

  @action
  storeAudioRecordingState() {
    ChatStore.getState().set(
      'isRecordingVoice',
      this.voiceRecordingVisible && (this.isAudioRecording || this.isAudioConverting)
    );
  }

  arrivedMessagesObserver() {
    if (this.isDestroyed || this.isDestroying) return;

    if (
      this.firstMessagesRendered &&
      isDefined(this.unreadAmountOnInit) &&
      this.thread?.arrivedWsMsgsSinceModelCreated
    ) {
      this.newWsMsgsCount += 1;
    }
  } // observer that needs to be explicitly added & removed, as ember doesn't realize thread has changed and can keep observing the old thread

  setUploadInProgress(value, tempId) {
    if (tempId) {
      let file = this.filesInProgress.find((f) => f.tempId === tempId);
      if (file) {
        set(file, 'loading', value);
        set(file, 'progress', value);
        // check for false because it can be falsy value 0 when upload starts
        set(file, 'loaded', value === false);

        if (file.isVoice) this.audioUploaded = !value;
      }
    }

    this.uploadInProgress = this.filesInProgress.filter((f) => !f.loaded).length;
  }

  onFileAdded(file) {
    const maxFileLimit = config.maxFileLimit;

    if (this.filesCount < maxFileLimit) {
      this.filesCount += 1;
      if (!file.isImage) {
        this.filesInProgress.pushObject(file);
      }

      next(this, () => {
        this.focusAndMarkAsRead();
      });

      return true;
    }

    FunctionalUtils.error(
      __('Sorry, you can only upload up to {maxFileLimit} files at a time.', { maxFileLimit: maxFileLimit })
    );

    return false;
  }

  onFileLoaded(tempId, id) {
    const obj = this.filesInProgress.find((f) => f.tempId === tempId);

    if (isDefined(obj)) {
      set(obj, 'id', id);
      set(obj, 'tempId', null);
      set(obj, 'loaded', true);
      set(obj, 'loading', false);

      if (obj.isVoice) {
        this.audioUploaded = true;
      }
    }
  }

  initUploads() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;

      if (this.canChat && !this.uploadInitiated) {
        this.uploadInitiated = true;

        this.chatPhotouploadFile = ChatPhotoupload.create({
          chatEl: this.element,
          formEl: this.element.querySelector('#chat-window_file-upload'),
          dropZoneEl: this.element.querySelector('.chat-window_send-form'),
          pasteZoneEl: null,
          autoUpload: false,
          isFileUpload: true,
          storeUploadRequest: this.storeUploadRequest.bind(this),
          setUploadInProgress: this.setUploadInProgress.bind(this),
          storeEventListener: this.storeEventListener.bind(this),
          onFileAdded: this.onFileAdded.bind(this),
          onFileLoaded: this.onFileLoaded.bind(this),
          renderPhoto: this.renderPhoto.bind(this),
          checkAddFileReturn: true,
        });

        this.chatPhotoupload = ChatPhotoupload.create({
          chatEl: this.element,
          formEl: this.element.querySelector('#chat-window_photo-upload'),
          dropZoneEl: this.element.querySelector('.chat-window_send-form'),
          pasteZoneEl: this.element.querySelector('.chat-window_send-form'),
          autoUpload: false,
          isImageUpload: true,
          storeUploadRequest: this.storeUploadRequest.bind(this),
          setUploadInProgress: this.setUploadInProgress.bind(this),
          storeEventListener: this.storeEventListener.bind(this),
          onFileAdded: this.onFileAdded.bind(this),
          onFileLoaded: this.onFileLoaded.bind(this),
          renderPhoto: this.renderPhoto.bind(this),
          cancelUpload: this.cancelUpload.bind(this),
          checkAddFileReturn: true,
        });
      }
    });
  }

  renderPhoto(blob, tempId) {
    let img = new Image();
    img.src = blob;
    img.className = 'photo';

    if (tempId && this.processedImages.indexOf(tempId) == -1) {
      this.filesInProgress.pushObject({
        tempId: tempId,
        img: img,
        loading: false,
        loaded: false,
      });

      this.processedImages.push(tempId);
    }
  }

  fetchChatRequestMessagesIfNotFetched() {
    if (this.thread?.isChatRequest && this.thread?.messages?.length === 0 && !this.thread?.messagesInitied) {
      dispatcher.dispatch('chat', 'getChatThread', {
        setAsRead: false,
        open: true,
        threadId: this.thread?.id,
      });
    }
  }

  bindScrollAndTextarea() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;

      const scrollEl = this.getScrollElement();

      if (scrollEl && !this.bindScrollAfterMsgsRendered) {
        this.bindScroll();
      } else {
        this.bindScrollAfterMsgsRendered = true;
      }
    });
  }

  @action
  sendMessage() {
    let message = (this.thread?.newMessage || '').trim();
    let attachmentIds = this.filesInProgress.map((f) => f.id);

    if (this.isSendingDisabled || (!message.length && !attachmentIds)) return;

    // prevent double send/enter press by blocking send action for a while
    this.isSendingIntervalActive = true;
    later(() => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.isSendingIntervalActive = false;
      }
    }, 200);

    message = toServer(message, {
      mentionsStrategy: this.mentionsStrategy,
      parseNativeMarkdown: true,
    });

    dispatcher.dispatch('chat', 'sendMessagesAtOnce', this.thread, {
      message: message,
      replyTo: this.thread?.replyTo?.id,
      attachmentIds: attachmentIds,
    });

    set(this, 'thread.newMessage', '');
    set(this, 'thread.replyTo', null);

    this.lastNewMsg = '';
    this.typingTimer = false;
    this.filesInProgress = A();
    this.filesCount = 0;
    this.unreadAmountOnInit = 0;
    this.audioUploaded = false;
    this.audioRecordingUrl = null;
    this.audioRecordingBlob = null;
    this.voiceRecordingVisible = false;

    if (this.editor) {
      this.editor.update('');
    }

    this.linkController.setInitialLinkProperties();

    ChatStore.send('moveReceivedChatRequestToThreads', this.thread?.id, this.account.activeUser.id);

    if (this.state.isChatRequestsPage) {
      set(this, 'state.isChatRequestsPage', false); // otherwise empty chat requests page can flash annoyingly before transition is made back to chats page
      this.router.transitionTo('app.chat');
    }

    PS.Pub('chat.message.sent', this.thread?.id);
  }

  @action
  removeFile(file) {
    if (file) {
      if (this.fileUploadRequests.length) {
        let xhrToAbort = this.fileUploadRequests.find((r) => r.tempId === file.tempId);
        if (xhrToAbort) {
          xhrToAbort.cancel();
        }
      }

      if (file.isVoice) {
        this.audioRecordingUrl = null;
        this.audioRecordingBlob = null;
        this.voiceRecordingVisible = false;
        this.audioUploaded = false;
      }

      this.filesInProgress.removeObject(file);
      this.filesCount -= 1;
      this.uploadInProgress = this.filesInProgress.filter((f) => !f.loaded).length;
    }
  }

  @action
  threadNameClick() {
    if (this.thread?.isEventChat) {
      this.router.transitionTo('app.event', this.thread?.event.id);
    } else if (this.thread?.isGroupChat) {
      this.router.transitionTo('app.group', this.thread?.group.id);
    } else if (this.thread?.isMultiUsersChat) {
      this.showChatParticipants();
    } else {
      this.chat.showUserProfile(this.thread);
    }
  }

  @action
  setEditor(editor) {
    this.editor = editor;
  }
}
