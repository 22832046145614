import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { getWindowHeight, getWindowScrollTop, getOffset } from 'mewe/utils/elements-utils';

export default class MwSelectedContacts extends Component {
  @tracked selectedDropdownOpened = false;
  @tracked topDropdown;
  @tracked leftTranslateMenu;

  @service router;
  @service dynamicDialogs;

  @action
  onInsert(element) {
    this.element = element;
  }

  openProfile(contact) {
    if (this.args.groupId) {
      this.router.transitionTo('app.group.members.profile', this.args.groupId, contact.id);
    } else if (this.args.eventId) {
      this.router.transitionTo('app.event.attendees.profile', this.args.eventId, contact.id);
    } else if (contact.publicLinkId) {
      this.router.transitionTo('app.publicid', contact.publicLinkId);
    }
  }

  @action
  openSelectedDropdown() {
    // in case of opening full list in dialog we ignore dropdown opening
    // the idea is that we open group/event/page contacts in a new dialog,
    // and only for list of selected contacts (selected for chat, sharing post etc.) we show dropdown
    if (this.args.showAllInDialog) {
      return;
    }

    // checking if entire box will be displayed below, if not, display above
    const offset = getOffset(this.element),
      popupSelectorWidth = 300 + 10, // 300 is the width and 10 is an extra side space
      popupSelectorHeight = 235 + 30; // 235 is the height of popup and 30 is margin

    offset.top = offset.top - getWindowScrollTop();

    const canDisplayBelow = getWindowHeight() - offset.top >= popupSelectorHeight;
    this.topDropdown = !canDisplayBelow;

    const spaceOnRight =
      window.innerWidth - this.element.querySelector('.dropdown-menu_opener').getBoundingClientRect().left;

    if (spaceOnRight > popupSelectorWidth) {
      this.leftTranslateMenu = 0;
    } else {
      this.leftTranslateMenu = `${spaceOnRight - popupSelectorWidth}px`;
    }

    this.selectedDropdownOpened = true;
  }

  @action
  showAllContacts() {
    if (this.args.showAllInDialog) {
      const params = {
        contacts: this.args.contacts,
        totalCount: this.args.totalCount,
        theme: this.args.theme,
        groupId: this.args.groupId,
        eventId: this.args.eventId,
        pageId: this.args.pageId,
      };

      this.dynamicDialogs.openDialog('mutual-contacts-dialog', params);
    }
  }

  @action
  selectContact(contact) {
    if (this.args.unSelectContact) {
      this.args.unSelectContact(contact);
    } else if (!this.args.disableAvatarClick) {
      this.openProfile(contact);
    }
  }

  @action
  closeDropdown() {
    this.selectedDropdownOpened = false;
  }
}
