import { computed } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend({
  emojiCounts: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  userEmojis: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  total: computed('emojiCounts.length', function () {
    return this.get('emojiCounts').reduce((total, emo) => total + Object.values(emo)[0], 0);
  }),
});

model.reopenClass({
  resourceName: 'emojis',
});

export default model;
