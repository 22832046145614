export function getUserAgent() {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export function isIos() {
  return /iPhone|iPod|iPad/i.test(getUserAgent());
}

export function isAndroid() {
  return /Android/i.test(getUserAgent());
}
