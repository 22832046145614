import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class DotsProgress extends Component {
  @tracked dots = [];

  get currentStepInt() {
    return parseInt(this.args.currentStep);
  }

  constructor() {
    super(...arguments);

    if (this.args.dots) {
      for (let i = 0; i < this.args.dots; i++) {
        this.dots.push(i + 1);
      }
    }
  }
}
