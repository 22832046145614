import ApiUtil from './api';

class Api extends ApiUtil {

  /**
   * params
   * message
   * userInvitees
   * emailInvitees
   * smsInvitees
   * ref
   */
  sendInvitations(params) {
    if (params && (params.emailInvitees || params.wholeAddressBook)) {
      return this.post('/mycontacts/invite', {
        data: JSON.stringify(params),
      });
    }
  }

  inviteByPublicId(inviteId) {
    return this.post(`/mycontacts/public-invite?inviteId=${inviteId}`);
  }

  invited(params) {
    return this.get('/mycontacts/invited', {
      data: params,
    });
  }

  blocked(params) {
    return this.get('/mycontacts/blocked', {
      data: params,
    });
  }

  pending(params) {
    return this.getNextPageOr(`/following/requests/received`, params);
  }

  uploaded(params) {
    return this.get('/mycontacts/addressbook/contacts', {
      data: params,
    });
  }

  search(params) {
    return this.get('/following/followers?' + this.toUrlParams(params));
  }

  blockUser(userId, reportSpam) {
    const url = `/mycontacts/contact/block/user?userId=${userId}${reportSpam ? '&reportSpam=true' : ''}`;
    return this.post(url);
  }

  unblockUser(userId) {
    return this.post('/mycontacts/contact/unblock?userId=' + userId);
  }

  getMyContactsSettings() {
    return this.get('/mycontacts/settings');
  }

  setMyContactsSettings(params) {
    return this.put('/mycontacts/settings', {
      data: JSON.stringify(params),
    });
  }

  getGroupsToInvite(userId) {
    return this.get('/mycontacts/groups-invite/' + userId);
  }

  // details params - used to get profile details (bio, description)
  getUserDetails({ userId, groupId, eventId, details = false }) {
    if (groupId) {
      return this.get(`/group/${groupId}/member/${userId}/profile`);
    } else if (eventId) {
      return this.get(`/event2/${eventId}/participant/${userId}/profile`);
    } else {
      return this.get(`/following/${userId}${details ? '?details=true' : ''}`);
    }
  }

  profile(handle) {
    return this.get(`/following/profile/${handle}`);
  }

  getUserFeed(userId, params) {
    if (userId) {
      return this.getNextPageOr(`/home/user/${userId}/postsfeed`, params /*, 'apiRoot3'*/);
    }
  }

  uploadAddressbook(name, autoAddContacts) {
    return this.post(`/mycontacts/addressbook/import/initiate?service=${name}&autoAddContacts=${autoAddContacts}`);
  }

  autovalidateBeforeImport() {
    return this.post(`/api/v3/mycontacts/addressbook/user/autovalidate`);
  }

  importAddressbookContacts(params) {
    // if no addressBookName and no importId then all imported contacts will be returned
    return this.get('/mycontacts/addressbook/contacts', {
      non401: true,
      data: params,
    });
  }
}

export default new Api();
