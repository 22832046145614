import Component from '@glimmer/component';
import { action } from '@ember/object';

import { chatWindowHeightToSubtratct } from 'mewe/constants';
import PS from 'mewe/utils/pubsub';

export default class AppChat extends Component {

  @action
  onInsert(el) {
    this.element = el;

    this.resizeFunc();
    window.onresize = this.resizeFunc.bind(this);
  }

  @action
  onDestroy() {
    this.element.style.height = 'auto';
    window.onresize = null;
  }

  resizeFunc() {
    this.element.style.height = `${window.innerHeight}px`;
    const textareaEl = this.element.querySelector('.quill-editor');

    if (textareaEl) {
      textareaEl.style.maxHeight = Math.max((window.innerHeight - chatWindowHeightToSubtratct) / 2, 0) + 'px';
    }

    PS.Pub('chat.resize');
  }
}