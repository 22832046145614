import Component from '@glimmer/component';
import { isNumber } from 'lodash';
import { assert } from '@ember/debug';

export default class KeyboardSelectList extends Component {
  keyboardListElementSelector = 'li';

  keyboardListItems() {
    assert('KeyboardSelectListActions.keyboardSelectListItems: override this function with a .property().');
  }

  resetKeyboardList() {
    this.keyboardListActiveIndex = -1;
  }

  keyDown(e) {
    if (e.keyCode === 38) {
      // arrow up
      this.onArrowUp();

      e.preventDefault();
      return false;
    } else if (e.keyCode === 40) {
      // arrow down
      this.onArrowDown();

      e.preventDefault();
      return false;
    } else if (e.keyCode === 13) {
      // enter
      let action = this.keyboardListElementActionName;

      if (action) {
        let selected = this.keyboardListItems[this.keyboardListActiveIndex];
        if (selected) this[action].call(action, selected, this.keyboardListActiveIndex);

        e.preventDefault();
        return false;
      }
    } else {
      this.resetKeyboardList();
    }
  }

  scrollIndexIntoView(index) {
    if (!index) return;
    const elem = this.memberSearchResults.querySelectorAll(this.keyboardListElementSelector)[index];

    if (!elem) return;

    elem.scrollIntoView({
      block: 'center',
    });
  }

  getActiveListItemIndex() {
    let index = this.keyboardListActiveIndex;

    if (!isNumber(index)) return -1;

    return index;
  }

  onArrowUp() {
    this.setNeighborAsActiveListElement(false);
  }

  onArrowDown() {
    this.setNeighborAsActiveListElement(true);
  }

  setNeighborAsActiveListElement(setToNext) {
    let index = this.getActiveListItemIndex();

    if (setToNext) {
      index += 1;
      if (index >= this.keyboardListItems.length) index = this.keyboardListItems.length - 1;
    } else {
      index -= 1;
      if (index < 0) index = 0;
    }

    this.keyboardListActiveIndex = index;
    this.scrollIndexIntoView(index);
  }
}
