import Route from 'mewe/routes/route';
import AccountApi from 'mewe/api/account-api';
import Storage from 'mewe/shared/storage';
import { later } from '@ember/runloop';
import { getQueryStringParams } from 'mewe/shared/utils';
import { useFeatureHint } from 'mewe/utils/feature-hint';
import tokenManager from 'mewe/shared/token-manager';

// this page is used to confirm the account after MeWe registration (non-DSNP)
export default class ConfirmAccountRoute extends Route {

  model(params) {
    this.confirmAccount(params.id);
  }

  confirmAccount(confirmationId) {
    AccountApi.confirmAccount(confirmationId)
      .then((data) => {
        tokenManager.set(data);

        this.user = data.user;
        this.params = getQueryStringParams();

        later(
          this,
          () => {
            const redirectionType = Object.keys(this.params)[0]?.toLowerCase() || 'default';
            useFeatureHint('new-registration');
            AccountApi.checkHint({ hint: 'new-registration' });
            this.redirectToApp(redirectionType);
          },
          1000
        );
      })
      .catch(() => {
        Storage.remove(Storage.keys.ftueUnconfirmedUser);
        window.location = '/myworld';
      });
  }

  redirectToApp(type) {
    Storage.remove(Storage.keys.ftueUnconfirmedUser);

    switch (type) {
      case 'pageurlid':
        window.location = `/${pageUrlId}`;
        break;

      case 'groupid':
        const groupType = this.params.groupType.toLowerCase();
        const groupId = this.params.groupId;
        if (groupType === 'public') {
          window.location = `/group/${groupId}?isFirstGroupOpening=true`;
        } else if (groupType === 'publicapply') {
          window.location = '/myworld#application';
        } else {
          window.location = '/myworld';
        }
        break;

      case 'eventid':
        window.location = `/event/${eventUrlId}`;
        break;

      default:
        window.location = '/myworld';
        break;
    }
  }
}
