import { helper as buildHelper } from '@ember/component/helper';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export function userAvatarHelper(value, options) {
  if (!value) return null;
  let user = value[0];
  if (!user) return null;
  let size = (options && options.size) || '150x150';

  if (!user._links || !user._links.avatar) {
    if (user.id) {
      return EnvironmentUtils.getImgHost(true) + '/api/v2/photo/profile/' + size + '/' + user.id;
    }
    return null;
  }

  if (user?._links?.avatar?.href.includes('https://')) {
    return user._links.avatar.href;
  }

  return (
    EnvironmentUtils.getImgHost(true) + user._links.avatar.href.replace('{imageSize}', size).replace('{static}', '')
  );
}

/**
 * Returns full avatar url for provided user
 *
 * USAGE
 * {{ user-avatar-helper user size="150x150"}}
 *
 * default size is 150x150 if not provided
 */
export default buildHelper(userAvatarHelper);
