import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'MwButton',
  component: 'MwButton',
  excludeStories: /.*Data$/,
};

const TemplatePrimary = (args) => ({
  template: hbs`
    <div class='px-20 py-20' style='background: #FFFFFF;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>

    <div class='theme-dark px-20 py-20' style='background: #000000;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary' @disabled='true'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='primary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='primary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='primary' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>
  `,
  context: args,
});

export const buttonPrimary = TemplatePrimary.bind({});


const TemplateSecondary = (args) => ({
  template: hbs`
    <div class='px-20 py-20' style='background: #FFFFFF;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>

    <div class='theme-dark px-20 py-20' style='background: #000000;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary' @disabled='true'>Text</BasicUi::MwButton>
      </div>

      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @size='s' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='m' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @size='l' @variant='secondary' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>
  `,
  context: args,
});

export const buttonSecondary = TemplateSecondary.bind({});


const TemplateOther = (args) => ({
  template: hbs`
    <div class='px-20 py-20' style='background: #FFFFFF;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @variant='category' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @variant='category'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @variant='category' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>

    <div class='theme-dark px-20 py-20' style='background: #000000;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-32' @variant='category' @disabled='true'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @variant='category'>Text</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-32' @variant='category' @active='true'>Text</BasicUi::MwButton>
      </div>
    </div>

    <div class='px-20 py-20' style='background: #FFFFFF;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-16 btn-fill btn-black' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-fill btn-app' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-outline btn-black' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-outline btn-app' @variant='circle'>+</BasicUi::MwButton>
      </div>
    </div>

    <div class='theme-dark px-20 py-20' style='background: #000000;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @classNames='mr-16 btn-fill btn-black' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-fill btn-app' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-outline btn-black' @variant='circle'>+</BasicUi::MwButton>
        <BasicUi::MwButton @classNames='mr-16 btn-outline btn-app' @variant='circle'>+</BasicUi::MwButton>
      </div>
    </div>

    <div class='px-20 py-20' style='background: #FFFFFF;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @variant='float'>+</BasicUi::MwButton>
      </div>
    </div>

    <div class='theme-dark px-20 py-20' style='background: #000000;'>
      <div class="items-start my-10">
        <BasicUi::MwButton @variant='float'>+</BasicUi::MwButton>
      </div>
    </div>
  `,
  context: args,
});

export const buttonOther = TemplateOther.bind({});
