/* eslint-disable lines-between-class-members */
/* eslint-disable no-undef */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { reads } from '@ember/object/computed';

import TranslationApi from 'mewe/api/translation-api';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';

import { translationFields } from 'mewe/constants';
import config from 'mewe/config';

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { unescape } from 'lodash';

export default class MwTranslation extends Component {
  @service account;
  @service features;
  @service analytics;
  @service dynamicDialogs;

  @tracked loading = false;
  @tracked original = null;
  @tracked translated = null;
  @tracked translatedLocale;
  @tracked allowGeneralAnalytics;
  @tracked allowErrorAnalytics = true;
  @tracked supportedTranslation = translationFields.find((field) => field.type === this.contentType);

  fullString = '';
  isLocal = config.environment === 'local';

  @reads('args.type') contentType;
  @reads('args.translationModel') translationModel;
  @reads('args.applicationId') applicationId;
  @reads('account.currentTranslationLanguage') currentTranslationLanguage;
  @reads('args.translationModel.poll') isPoll;
  @reads('translationModel.isTranslated') isTranslated;

  dataHelper = {
    prepareStructureForTranslations: {
      group: () => {
        const mappedDisplayFields = {};
        const fieldToTranslate = 'questionDisplay';
        const parentContainerKey = this.supportedTranslation.questionsParentContainer;
        mappedDisplayFields[parentContainerKey] = [];

        this.translationModel[parentContainerKey]?.forEach((originalField) => {
          mappedDisplayFields[parentContainerKey].push({
            [fieldToTranslate]: originalField[fieldToTranslate],
          });

          this.addToFullString(originalField[fieldToTranslate]);
        });

        mappedDisplayFields.description = this.translationModel?.description || this.translationModel?.descriptionPlain;

        this.addToFullString(mappedDisplayFields.description);

        return mappedDisplayFields;
      },
      groupApplication: () => {
        const mappedDisplayFields = {
          answers: [],
        };

        this.translationModel.answers?.forEach((answer) => {
          mappedDisplayFields.answers.push({
            questionDisplay: answer.questionDisplay,
            answerDisplay: answer.answerDisplay,
          });

          this.addToFullString(answer.answerDisplay);
        });

        return mappedDisplayFields;
      },
      profile: () => {
        let mappedDisplayFields = {};

        this.supportedTranslation?.translations.forEach((fieldToTranslate) => {
          const target = this.translationModel?.[fieldToTranslate];

          if (target) {
            mappedDisplayFields = {
              ...mappedDisplayFields,
              [fieldToTranslate]: target,
            };

            this.addToFullString(target);
          }
        });

        return mappedDisplayFields;
      },
      post: () => {
        this.addToFullString(this.translationModel.textServer);
        return {
          text: this.translationModel.textServer,
        };
      },
      comment: () => {
        this.addToFullString(this.translationModel.textServer);
        return {
          text: this.translationModel.textServer,
        };
      },
      poll: () => {
        const mappedDisplayFields = {};
        mappedDisplayFields.question = this.translationModel.poll?.question;
        mappedDisplayFields.options = [];

        this.translationModel.poll.options?.forEach((originalField) => {
          mappedDisplayFields.options.push(originalField);

          this.addToFullString(originalField.text);
        });

        this.addToFullString(mappedDisplayFields.question);

        mappedDisplayFields.text = this.translationModel.textServer;
        this.addToFullString(this.translationModel.textServer);

        return mappedDisplayFields;
      },
      chat: () => {
        this.addToFullString(this.translationModel.text);
        return {
          text: this.translationModel.text,
        };
      },
    },
    getTranslated: {
      group: () => {
        const transaltedQuestions = this.translated.publicApplyQuestions;
        const translatedFields = {
          groupQuestions: [],
        };

        translatedFields.description = this.translated?.description;

        transaltedQuestions?.forEach((translatedQuestion, index) => {
          const oldQuestion = this.translationModel?.groupQuestions[index]?.questionDisplay;
          const questionWithValue = translatedQuestion?.length > 1 ? translatedQuestion : oldQuestion;

          translatedFields.groupQuestions.push({
            questionDisplay: EmojisParser.toDisplay(questionWithValue),
          });
        });

        return translatedFields;
      },
      groupApplication: () => {
        const translatedAnswers = this.translated.answers;
        const translatedFields = {
          answers: [],
        };

        translatedAnswers?.forEach((translatedAnswer, index) => {
          const originalQuestion = this.translationModel.answers[index]?.questionDisplay;
          const originalAnswer = this.translationModel.answers[index]?.answerDisplay;
          const answerWithValue = translatedAnswer?.length > 1 ? translatedAnswer : originalAnswer;

          translatedFields.answers.push({
            questionDisplay: EmojisParser.toDisplay(originalQuestion),
            answerDisplay: EmojisParser.toDisplay(answerWithValue),
          });
        });

        return translatedFields;
      },
      profile: () => {
        const translatedFields = {};

        Object.entries(this.translated).forEach((field) => {
          const key = field[0];
          const value = field[1];

          translatedFields[key] = value;
        });

        return translatedFields;
      },
      post: () => {
        return {
          text: this.translated.text,
        };
      },
      comment: () => {
        return {
          text: this.translated.text,
        };
      },
      chat: () => {
        return {
          text: this.translated.text,
        };
      },
      poll: () => {
        const translatedData = {};
        const pollText = this.translated.text?.length === 0 ? this.translationModel.textServer : this.translated.text;
        const pollQuestion =
          this.translated.poll?.question.length === 0
            ? this.translationModel.poll.question
            : this.translated.poll.question;

        translatedData.options = [];
        translatedData.text = EmojisParser.toDisplay(pollText);
        translatedData.question = EmojisParser.toDisplay(pollQuestion);

        this.translated.poll.options?.forEach((translatedOption) => {
          translatedData.options.push({
            textTranslated: EmojisParser.toDisplay(translatedOption),
          });
        });

        return translatedData;
      },
    },
    getInitialPostText: () => {
      return {
        text: EmojisParser.toDisplay(this.translationModel.textEdit),
      };
    },
  };

  @action
  onInsert() {
    if (this.applicationId) {
      this.id = this.translationModel.groupId;
    }
  }

  addToFullString(string) {
    //replacing unwanted characters, &#39; is an apostrophe
    this.fullString += string ? `${string.replace(/<\/?[^>]+(>|$)|\\n /g, '').replace('&#39;', "'")} ` : '';
  }

  async getTranslation(params, scope) {
    //Sending request
    const queryParams = {
      destinationLanguage: this.currentTranslationLanguage,
      applicationId: this.applicationId,
      ...params,
    };

    //if its group pending post
    if (this.translationModel.pending) {
      Object.assign(queryParams, {
        pending: this.translationModel.pending,
        groupId: this.translationModel.groupId,
      });
    }

    try {
      //avoid duplicated requests
      if (this.blockRequest) {
        return this.translated;
      }

      const data = await TranslationApi.translate(queryParams, scope);
      this.allowGeneralAnalytics = true;

      return await data;
    } catch (e) {
      if (this.isLocal) {
        console.log(e);
      }

      if (e.data.errorCode === 429) {
        this.sendAnalyticsLimit();
      }

      if (e.data.errorCode) {
        this.dynamicDialogs.openDialog('premium-feature-dialog', {
          message: __('Enjoy unlimited translations on MeWe. Consider subscribing and enhancing your overall experience on our social network.'),
        });
        return e.data;
      }
    }
  }

  get buttonText() {
    //After the translation is done, switch text
    if (this.isTranslated) {
      return __('See original');
    }
    return __('See translations');
  }

  get isLanguageSupported() {
    //find if needed language is supported by us
    return this.account.supportedLanguages?.find((lang) => lang === this.translatedLocale) || 'en';
  }

  get blockRequest() {
    //blocking sending multiple translation requests

    const translationLanguage = this.translated?.destinationLanguage;
    const isTranslatedLangTheSame = translationLanguage?.toLowerCase() === this.currentTranslationLanguage;
    const shouldBlock = this.isTranslated ? this.isTranslated : this.translated && isTranslatedLangTheSame;

    if (shouldBlock) {
      return !!this.translated;
    }

    return false;
  }

  get setIdField() {
    if (this.contentType === 'chat') {
      if (this.translationModel.thread.event) {
        return { [`${this.supportedTranslation?.idFieldEventMessage}`]: this.translationModel.id };
      }

      if (this.translationModel.thread.group) {
        return { [`${this.supportedTranslation?.idFieldGroupMessage}`]: this.translationModel.id };
      }

      return { [`${this.supportedTranslation?.idFieldUserMessage}`]: this.translationModel.id };
    }

    //set id field by supported translation
    const scope = this.translationModel;

    return { [`${this.supportedTranslation?.idField}`]: scope.id || this.args.id };
  }

  get isGroup() {
    return this.contentType === 'group' && !this.isGroupApplication;
  }

  get isProfile() {
    return this.contentType === 'profile';
  }

  get isComment() {
    return this.contentType === 'comment';
  }

  get isGroupApplication() {
    return !!this.applicationId;
  }

  get isPost() {
    return this.contentType === 'post' && !this.isPoll;
  }

  get isChat() {
    return this.contentType === 'chat';
  }

  prepareDisplayFields() {
    //Avoid duplicated iteration
    if (this.isTranslated) {
      return this.original;
    }

    //preparing structure to read data to be translated
    if (this.isProfile) {
      return this.dataHelper.prepareStructureForTranslations.profile();
    }

    if (this.isGroup) {
      return this.dataHelper.prepareStructureForTranslations.group();
    }

    if (this.isGroupApplication) {
      return this.dataHelper.prepareStructureForTranslations.groupApplication();
    }

    if (this.isComment) {
      return this.dataHelper.prepareStructureForTranslations.comment();
    }

    if (this.isPoll) {
      return this.dataHelper.prepareStructureForTranslations.poll();
    }

    if (this.isPost) {
      return this.dataHelper.prepareStructureForTranslations.post();
    }

    if (this.isChat) {
      return this.dataHelper.prepareStructureForTranslations.chat();
    }
  }

  async getTranslationText() {
    try {
      //prepare translation structure to match parent component
      if (this.isProfile) {
        return this.dataHelper.getTranslated.profile();
      }

      if (this.isGroup) {
        return this.dataHelper.getTranslated.group();
      }

      if (this.isGroupApplication) {
        return this.dataHelper.getTranslated.groupApplication();
      }

      if (this.isComment) {
        return this.dataHelper.getTranslated.comment();
      }

      if (this.isPoll) {
        return this.dataHelper.getTranslated.poll();
      }

      if (this.isPost) {
        return this.dataHelper.getTranslated.post();
      }

      if (this.isChat) {
        return this.dataHelper.getTranslated.chat();
      }
    } catch (e) {
      if (this.isLocal) {
        console.log(e);
      }
    }
  }

  async getDisplayData() {
    //what data has to be send to parrent component
    try {
      if (this.isTranslated && this.translated) {
        return await this.getTranslationText();
      }

      //In case of switching between scopes (ex sharing) in posts original can be null
      if (this.isPost) {
        return this.original || this.dataHelper.getInitialPostText();
      }

      return this.original;
    } catch (e) {
      if (this.isLocal) {
        console.log(e);
      }
    }
  }

  @action
  async translate() {
    if (this.loading || this.translationModel?.isPublicContent) {
      return;
    }

    this.allowGeneralAnalytics = false;
    this.loading = true;

    try {
      this.translatedLocale = this.translated?.destinationLanguage;
      //when switching from translated to original, show current or originaly passed fields
      this.original = this.prepareDisplayFields();
      //should we send to translation or do we already have it translated ?
      this.translated = await this.getTranslation(this.setIdField, this.contentType);

      //passed value to switch between translated and original fields
      set(this.args.translationModel, 'isTranslated', !this.isTranslated && !this.translated?.errorCode ? true : false);

      //handling the data in parrent component
      this.args.handleTranslations({
        data: await this.getDisplayData(),
        isTranslated: this.isTranslated,
        errorCode: this.translated?.errorCode || false,
      });

      this.loading = false;

      this.sendAnalytics();
    } catch (e) {
      if (this.isLocal) {
        console.log(e);
      }

      this.loading = false;
    }
  }

  sendAnalytics() {
    if (!this.features.get('translationsAnalytics')) {
      return;
    }
    //Send analytics only first time when translation request is done
    if (!this.allowGeneralAnalytics) {
      return;
    }

    let contentType;
    if (this.translationModel?.replyTo) {
      contentType = 'reply';
    } else if (this.contentType === 'chat') {
      contentType = 'chat_message';
    } else if (this.contentType === 'group') {
      contentType = 'group_description';
    } else if (this.contentType === 'profile') {
      contentType = 'profile_description';
    } else {
      contentType = this.contentType;
    }

    const word_count = unescape(this.fullString)?.trim().split(' ').length;
    const character_count = unescape(this.fullString)?.trim().length;
    let totalEmojis = 0;

    this.translationModel?.emojisItems?.forEach((emoji) => {
      totalEmojis = totalEmojis + emoji.counter;
    });

    const translatedContext = this.translated?.context === 'privacymail' ? 'private_posts' : this.translated.context;

    const values = {
      emoji_count: totalEmojis,
      comments_count: this.translationModel.comments?.total,
      word_count,
      character_count,
      context: this.args.context || translatedContext,
      content_type: contentType,
      source_language: this.translated.sourceLanguage,
      destination_language: this.translated.destinationLanguage,
      applicationId: this.applicationId,
      id: this.translationModel?.id || this.args.id,
    };

    this.analytics.sendEvent('contentTranslated', values);
  }

  sendAnalyticsLimit() {
    if (!this.features.get('translationsAnalytics')) {
      return;
    }
    if (!this.allowErrorAnalytics) {
      return;
    }

    //Currently this event doesnt need any params but {} has to be passed
    //otherwise Sentry error will be triggered due to empty params
    this.analytics.sendEvent('contentTranslationLimit', {});

    this.allowErrorAnalytics = false;
  }

  @action
  openSettings() {
    if (this.translationModel?.isPublicContent && this.translationModel?.customInteraction) {
      this.translationModel?.customInteraction(this.dynamicDialogs, this.args.translationModel);
    } else {
      this.dynamicDialogs.openDialog('translation-dialog', {
        onApply: () => this.translate(),
        isTranslated: this.isTranslated,
      });
    }
  }
}
