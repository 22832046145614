/* eslint-disable lines-between-class-members */
import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

export default class MoveToWeb3 extends Route {
  @service storyblok;
  @service settings;

  get isDarkThemeEnabled() {
    return this.settings.isDarkThemeEnabled;
  }

  model() {
    return this.storyblok.loadStory(`cms/move-to-web3`);
  }
}
