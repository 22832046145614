/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Session from 'mewe/shared/session';
import { getQueryStringParams } from 'mewe/shared/utils';

export default class MwContentPage extends Component {
  @tracked content = this.args.content;
  @tracked isMobileSocialWeb;
  @tracked isLoggedIn = false;
  @tracked isAuthFetched = false;
  @tracked styles = this.content.backgroundColor ? `background-color: ${this.content.backgroundColor};` : '';

  constructor() {
    super(...arguments);
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      this.isAuthFetched = true;
      this.isLoggedIn = isAuthenticated;

      this.adjustContentOnClient();

      this.storyblokBridge = new window.StoryblokBridge();

      this.storyblokBridge.on(['change', 'input'], (e) => {
        this.content = e?.story.content;
        this.adjustContentOnClient();
      });
    });
  }

  adjustContentOnClient() {
    const urlParams = getQueryStringParams();
    const hash = window.location.hash;
    const isMobileSocialWeb = hash === '#info';

    // https://sgrouples.atlassian.net/browse/UL-433
    if (urlParams?.mobileApp === 'true' || isMobileSocialWeb) {
      this.content.hideLogin = true;
      this.content.hideRegistration = true;
    }

    // https://sgrouples.atlassian.net/browse/UL-432
    this.isMobileSocialWeb = isMobileSocialWeb;
  }
}
