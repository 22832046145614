/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class MwBadge extends Component {
  @service account;
  @service dynamicPopups;

  @reads('args.owner') owner;
  @reads('args.page') page;
  @reads('args.postedByPage') postedByPage;

  @tracked showDropdown = false;
  @tracked dropdownId = '';
  @tracked parent = null;
  @tracked popupBadge;

  get isVerified() {
    if (this.args.isVerified) {
      return true;
    }

    if (this.page && !this.owner) {
      return this.page.isVerified && this.postedByPage;
    }

    return this.owner?.badges?.verified;
  }

  get isPremium() {
    if (this.args.isPremium) {
      return true;
    } else {
      return this.owner?.badges?.premium;
    }
  }

  get isDonor() {
    if (this.args.isDonor) {
      return true;
    } else {
      return this.owner?.badges?.donor;
    }
  }

  get isDsnp() {
    if (this.args.isDsnp) {
      return true;
    } else {
      return this.owner?.badges?.dsnp;
    }
  }

  get isFavorite() {
    if (this.postedByPage) {
      return false;
    }

    const isFavorite = this.args.isFavorite || this.isUserFavorite(this.owner?.id);
    return !this.args.ignoreFavorite && isFavorite;
  }

  isUserFavorite(userId) {
    return this.account.favorites?.includes(userId);
  }

  @action
  openDropdown(badge, event) {
    if (this.args.disableHint) return;

    this.parent = event.target;
    this.dropdownId = `${badge}-hint-popup`;
    this.popupBadge = badge;
    this.showDropdown = true;
  }

  @action
  closeDropdown() {
    this.parent = null;
    this.dropdownId = null;
    this.popupBadge = null;
    this.showDropdown = false;
  }
}
