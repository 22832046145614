import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getPublicProfileParams } from 'mewe/utils/miscellaneous-utils';

export default class ViewMore extends Component {
  @service router;

  @action
  goToSignUp() {
    this.router.transitionTo('register', { queryParams: getPublicProfileParams(this.args.model) });
  }

  @action
  goToLogin() {
    this.router.transitionTo('login', { queryParams: getPublicProfileParams(this.args.model) });
  }
}
