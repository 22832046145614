import PostCommons from 'mewe/utils/posting-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import { trim, isEmpty } from 'lodash';

class Status {
  constructor(postbox) {
    this.postbox = postbox;
  }

  showValidation() {
    // TODO plug status validation
  }

  isEmpty() {
    const text = trim(
      toServer(this.postbox.newPost, {
        parseNativeMarkdown: true,
      })
    );

    return isEmpty((text || '').trim()) && this.postbox.selectedGifs.length === 0 && !this.postbox.audioFileId;
  }

  shareController(params) {
    if (this.postbox.isSharing) return;

    let text = this.postbox.newPostServer;

    if (
      !params.edit &&
      !text &&
      !this.postbox.link?.url &&
      !this.postbox.audioFileId &&
      !this.postbox.preselectedVideo &&
      !this.postbox.preselectedFiles &&
      !this.postbox.sticker
    ) {
      this.showValidation();
      return;
    }

    const options = PostCommons.getStandardPostingOptions(
      Object.assign(
        {},
        {
          text: text,
          mediaIds: this.postbox.preselectedVideo ? [this.postbox.preselectedVideo] : null,
        },
        params
      ),
      this.postbox
    );

    if (this.postbox.selectedAlbumName?.length) {
      options.album = this.postbox.selectedAlbumName;
    }

    PostCommons.share(options, this.postbox);
  }
}

export default Status;
