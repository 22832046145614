import Model from 'mewe/utils/store-utils/model/read-only-model.js';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { Post } from './post-model';
import User from './user-model';

var model = Model.extend({
  _links: attr('object'),
  id: attr('string'),
  name: attr('string'),
  type: attr('string', {
    defaultValue() {
      let typeInPost = this.get('post.document.type');
      if (typeInPost && typeInPost.length) return typeInPost;
      else {
        let name = this.get('name');
        if (name) return name.toLowerCase().slice(name.lastIndexOf('.') + 1);
      }
    },
  }),
  createdAt: attr('string'),
  nodeIcon: attr('string'),
  canRemove: attr('boolean'),
  canMove: attr('boolean'),
  size: attr('number'),
  owner: belongsTo(User),
  post: belongsTo(Post),
  postItemId: attr('string'),
});

model.reopenClass({
  resourceName: 'document',
});

export default model;
