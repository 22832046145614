export const sortMediaOptions = () => {
  return {
    subOptions: [
      {
        label: __('Newest'),
        icon: 'icn_arrow_up',
        action: 'orderMedia',
        selected: true,
        default: true,
        order: 0,
      },
      {
        label: __('Oldest'),
        icon: 'icn_arrow_down',
        action: 'orderMedia',
        selected: false,
        order: 1,
      },
    ],
  }
};

export const sortMembersOptions = () => {
  return {
    subOptions: [
      {
        label: __('Recently Added'),
        action: 'sortMembers',
        selected: true,
        default: true,
        order: 'recency',
      },
      {
        label: __('First Name'),
        action: 'sortMembers',
        selected: false,
        order: 'firstname',
      },
      {
        label: __('Last Name'),
        action: 'sortMembers',
        selected: false,
        order: 'lastname',
      },
    ],
  }
};
