import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';
import i18next from 'i18next';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { iosAppUrl, androidAppUrl } from 'mewe/constants';

export default class MwHomeFooter extends Component {
  @service storyblok;

  languages = i18next.languages;

  iosAppUrlConst = iosAppUrl;
  androidAppUrlConst = androidAppUrl;

  @tracked storyblokLinks = [];

  @tracked showBottomLinks;

  constructor() {
    super(...arguments);
    this.showBottomLinks = this.args.showBottomLinks ?? true;
    this.storyblok.loadStory('home-wip').then((story) => {
      this.storyblokLinks = story.content.links;
    });
  }

  @action
  changeLocale(locale) {
    dispatcher.dispatch('client-data', 'setLocale', locale, true);
  }

  get links() {
    return this.storyblokLinks.map((link) => ({
      href: link.link.cached_url.replace(`/${i18next.language}`, ''),
      title: link.title,
    }));
  }
}
