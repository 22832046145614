import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import { escape, isEmpty, unescape } from 'lodash';
import { getEmojisPromise } from 'mewe/utils/emoji-utils';

import 'mewe/services/tasks';
import GroupStore from 'mewe/stores/group-store';
import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import ChatStore from 'mewe/stores/chat-store';
import toServer from 'mewe/stores/text-parsers/to-server';
import isUndefined from 'mewe/utils/isUndefined';
import dispatcher from 'mewe/dispatcher';

export default class MwGroupSettingsGeneral extends Component {
  @reads('args.group') group;

  @tracked task;
  @tracked loaded;
  @tracked isSaving;
  @tracked groupName;

  @tracked groupDescription;
  @tracked dropdownMenuOpened;

  @tracked showDeleteButton;
  @tracked showDeleteForm;
  @tracked passwordError;

  @service dynamicDialogs;
  @service account;
  @service settings;
  @service tasks;

  @tracked groupCategory = {
    id: this.group?.groupThematicType || 'Uncategorized',
    name: '', // needs to be translated, so set after available group categories fetched
    categoryName: '',
  };

  photoDialogTitles = {
    choosePhoto: __('Choose Group Background Photo'),
    cropPhoto: __('Edit Group Background Photo'),
  };

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
    this.updateGroupCategoryBind = this.updateGroupCategory.bind(this);
  }

  willDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
  }

  groupDataReady() {
    if (!this.loaded && this.group?.id && !this.group.isFetching) {
      if (this.group.isOwnerAdmin) {
        // each setting is stored in separate field so it can be compared with fetched value later
        this.groupName = this.group.name;
        this.groupCategory.id = this.group.groupThematicType;

        getEmojisPromise().promise.then(() => {
          this.groupDescription = EmojisParser.toEdit(escape(this.group.descriptionPlain)).replace(
            /(?:\r\n|\r|\n)/g,
            '<br />'
          );
          this.loaded = true;
        });
      }

      this.task = this.tasks.getTask(`photo.cover.group.${this.group.id}`);
    }
  }

  @computed('loaded', 'isSaving', 'groupName', 'groupDescription', 'groupCategory.id')
  get isSaveVisible() {
    return this.loaded && !this.isSaving && !isEmpty(this.getParams());
  }

  @computed('groupName.length', 'loaded')
  get isNameMissing() {
    return this.loaded && (!this.groupName || this.groupName.trim().length === 0);
  }

  getParams() {
    // regular member can't change anything in general settings
    if (!this.group?.isOwnerAdmin) return;

    let params = {};

    let toServerText = toServer(this.groupDescription, {
      parseNativeMarkdown: true,
    });

    let isNameChanged = this.groupName && this.groupName !== this.group?.name;
    let isGroupCategoryChanged = this.groupCategory.id && this.groupCategory.id !== this.group.groupThematicType;
    let isDescriptionChanged = toServerText !== this.group.descriptionPlain;

    if (isNameChanged) params.name = this.groupName;
    if (isGroupCategoryChanged) params.groupThematicType = this.groupCategory.id;
    if (isDescriptionChanged) params.description = toServerText;

    return params;
  }

  @action
  selectPhoto(blob, params) {
    dispatcher.dispatch('group', 'sendGroupPhoto', this.group?.id, blob, params, this.task);
  }

  @action
  passwordChanged() {
    this.passwordError = false;
    this.showDeleteButton = this.password.length > 5;
  }

  @action
  saveSettings() {
    let params = this.getParams();

    this.isSaving = true;

    if (this.group.isOwnerAdmin) {
      GroupApi.setGroupData(this.group.id, params)
        .then(() => {
          if (!this.isDestroyed && !this.isDestroying) {
            if (params.groupThematicType) {
              this.group.set('groupThematicType', params.groupThematicType);
              this.groupCategory.id = params.groupThematicType;
            }

            if (!isUndefined(params.description)) {
              this.group.set('descriptionPlain', params.description);
            }

            if (params.name) this.group.set('name', params.name);
          }

          FunctionalUtils.info(__('Group Settings have been successfully saved.'));
        })
        .catch((resp) => {
          if (this.isDestroyed || this.isDestroying) return;

          if (resp && resp.status) {
            FunctionalUtils.showDefaultErrorMessage();
          }
        })
        .finally(() => {
          if (this.isDestroyed || this.isDestroying) return;

          this.isSaving = false;
        });
    } else {
      GroupApi.putSettings(this.group?.id, params)
        .then(() => {
          FunctionalUtils.info(__('Group Settings have been successfully saved.'));
        })
        .catch((resp) => {
          if (resp && resp.status) {
            FunctionalUtils.showDefaultErrorMessage();
          }
        })
        .finally(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.isSaving = false;
        });
    }
  }

  @action
  showDeleteGroupForm() {
    // web3 users dont need to enter password
    if (this.account.activeUser.dsnpHandle) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm Deletion'),
        message: __(
          'Are you ABSOLUTELY sure you want to delete this group? <br><br> You will NOT be able to undo this.'
        ),
        noEscaping: true,
        cancelButtonText: __('Cancel'),
        okButtonText: __(`Yes, I'm absolutely sure`),
        onConfirm: () => {
          this.confirmGroupDeleting();
        },
      });
    } else {
      this.showDeleteForm = true;
    }
  }

  @action
  confirmGroupDeleting() {
    let group = this.group;

    GroupApi.deleteGroup(group.id, this.password)
      .then(() => {
        FunctionalUtils.info(
          __('Group {groupName} has been successfully deleted.', {
            groupName: group.name,
          })
        );
        GroupStore.send('removeGroup', group.id);
        ChatStore.send('removeThreadById', group.id);
        location.href = '/myworld';
      })
      .catch((data) => {
        if (data && data.status) {
          if (data.status === 403) {
            if (data.data && data.data.errorCode === 622) {
              FunctionalUtils.error(__('Group owner can delete a group by first removing all members.'));
            } else {
              if (!this.isDestroyed && !this.isDestroying) {
                this.passwordError = true;
              }
            }
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        }
      });
  }

  @action
  selectNewOwner() {
    dispatcher.dispatch('group', 'selectNewOwner', this.group);
  }

  @action
  choosePhotoDialog() {
    this.dropdownMenuOpened = !this.dropdownMenuOpened;
  }

  @action
  updateGroupCategory(category) {
    this.groupCategory = category;
  }
}
