import Delta from 'quill-delta';
import Clipboard from 'quill/modules/clipboard';

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();

    const clipboardData = e.clipboardData;

    if (!clipboardData) return;

    // SG-28980, SG-12406 - pasting image so don't paste text as well
    if (clipboardData.files && clipboardData.files.length) return;

    const range = this.quill.getSelection();
    let text = clipboardData.getData('text/plain') + ' ';

    if (this.quill.maxlength) text = text.substr(0, this.quill.maxlength);

    const delta = new Delta().retain(range.index).delete(range.length).insert(text);

    const index = text.length + range.index;
    const length = 0;

    this.quill.updateContents(delta, 'api');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();

    if (typeof this.quill.textPastedAction === 'function') this.quill.textPastedAction();
  }
}

export default PlainClipboard;
