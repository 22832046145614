import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import FunctionalUtils from 'mewe/shared/functional-utils';
import { inject as service } from '@ember/service';
import isUndefined from 'mewe/utils/isUndefined';
import AccountApi from 'mewe/api/account-api';

export default class MwSettingsBadges extends Component {
  @service account;
  @service analytics;

  @tracked badges = {};
  @tracked selectedBadges = [];

  constructor() {
    super(...arguments);

    // get latest state of badges from server instead of relying on current user data,
    // user could gain some badges in the meantime
    AccountApi.getBadges().then((data) => {
      set(this, `account.activeUser.badges`, data);
      this.badges = this.account.activeUser.badges;
      this.updateBadges();
    });
  }

  @computed('badges.verified', 'selectedBadges.length')
  get isVerifiedBadgeDisabled() {
    return isUndefined(this.badges.verified) || (this.selectedBadges.length >= 2 && !this.badges.verified);
  }

  @computed('badges.premium', 'selectedBadges.length')
  get isPremiumBadgeDisabled() {
    return isUndefined(this.badges?.premium) || (this.selectedBadges.length >= 2 && !this.badges.premium);
  }

  @computed('badges.donor', 'selectedBadges.length')
  get isDonorBadgeDisabled() {
    return isUndefined(this.badges?.donor) || (this.selectedBadges.length >= 2 && !this.badges.donor);
  }

  @computed('badges.dsnp', 'selectedBadges.length')
  get isDsnpBadgeDisabled() {
    return isUndefined(this.badges?.dsnp) || (this.selectedBadges.length >= 2 && !this.badges.dsnp);
  }

  updateBadges(name, value) {
    if (name) {
      set(this, `badges.${name}`, value);
    }

    let selected = [];
    for (const key in this.badges) {
      if (this.badges[key]) {
        selected.push(key);
      }
    }

    this.selectedBadges = selected;
  }

  @action
  setBadge(name) {
    const newValue = !this.badges[name];

    this.updateBadges(name, newValue);

    AccountApi.setBadges({ badges: this.selectedBadges })
      .then(() => {
        FunctionalUtils.info(__('Changes in your account have been saved'));
        set(this, `account.activeUser.badges.${name}`, newValue);
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.updateBadges(name, !newValue);
      });
  }

  @action
  dsnpBadgeClicked() {
    this.analytics.sendEvent('learnMoreClicked', { location: 'badge settings', element: 'social web badge' });
  }
}
