import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import ChatApi from 'mewe/api/chat-api';
import ChatStore from 'mewe/stores/chat-store';
import dispatcher from 'mewe/dispatcher';

export default class MwChatRequestSettings extends Component {
  @tracked chatSettingsDropdownOpened = false;

  @action
  toggleChatSettingsDropdown(value) {
    this.chatSettingsDropdownOpened = value;
  }

  @action
  ignoreAllRequests() {
    ChatApi.ignoreAllChatRequests();
    ChatStore.send('setAllChatRequestsAsRead');
    this.toggleChatSettingsDropdown(false);
  }

  @action
  deleteAllRequests() {
    dispatcher.dispatch('chat', 'closeSmallChat', this.args.thread);
    ChatApi.deleteAllChatRequests();
    ChatStore.send('deleteAllChatRequests');
    this.toggleChatSettingsDropdown(false);
  }

  @action
  turnOnChatRequests() {
    dispatcher.dispatch('chat', 'showTurnOnChatRequestsPrompt');
    this.toggleChatSettingsDropdown(false);
  }

  @action
  turnOffChatRequests() {
    dispatcher.dispatch('chat', 'showTurnOffChatRequestsPrompt');
    this.toggleChatSettingsDropdown(false);
  }
}
