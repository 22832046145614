import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr, hasMany } from 'mewe/utils/store-utils/model/attribute';
import EmberObject from '@ember/object';
import { capitalize } from '@ember/string';

const periodNames = {
  month: __('month'),
  year: __('year'),
};

const ProductModel = Model.extend({
  productId: attr('string'),
  price: attr('string'),
  type: attr('string'), // consumable/nonconsumable/subscription
  grantedBy: attr('string'),
  isPurchased: attr('boolean'),
  isCancelled: attr('boolean'),
  subscriptionId: attr('string'),
  provider: attr('string'),
  period: attr('string'),
  tierValue: attr('number'),
  // end of fields from BE

  // FE custom fields
  isSelected: attr('boolean'), // in case of items with multiple products this is set to mark selected one
  periodName: computed('period', function () {
    return periodNames[this.period] || '';
  }),
});

ProductModel.reopenClass({
  resourceName: 'store-product',
});

const ItemDisplayInfoModel = Model.extend({
  id: attr('string'),
  category: attr('string'),
  name: attr('string'),
  description: attr('string'),
  class: attr('string'),
  coverImage: attr('string'),
  packLogo: attr('string'),
  packLogoDark: attr('string'),
  packLogoWidth: attr('number'),
  packLogoSticker: attr('object'),
  iconSticker: attr('object'),
  iconEmoji: attr('string'),
  previewEmojisFive: attr('array'),
  previewStickers: attr('array'),
  previewStickersTwo: attr('array'),
  emojisList: attr('array'),
  stickers: attr('array'),
});

ItemDisplayInfoModel.reopenClass({
  resourceName: 'item-display-info',
});

const ItemModel = Model.extend({
  id: attr('string'),

  displayInfo: attr('object'), // vvv this comes from our config, contains data specific for UI presentation

  // vvv this comes from BE /products, contains data specific for purchase operations
  products: hasMany(ProductModel),
  possibleOperations: attr('array'), // Purchase/Cancel/ChangeBillingCycle/ChangePlan
  usedTrial: attr('boolean'),

  // vvv additional properties
  isInCart: attr('boolean'),
  isPendingAction: attr('boolean'), // flag set when some action is in progress (reactivation, plan change)

  discount: attr('object', {
    defaultValue: () => EmberObject.create({}),
  }),
  discountText: computed('discount.label', 'discount.value', function () {
    return this.discount.label
      ? __('{discountLabel} {discountValue}% off', {
          discountLabel: this.discount.label,
          discountValue: this.discount.value,
        })
      : '';
  }),

  // in case of multiple products in an item this is set to mark selected one for the purchase
  selectedProduct: computed('products.@each.isSelected', function () {
    // return empty object as fallback to avoid errors
    return this.products.find((p) => p.isSelected) || this.products[0] || EmberObject.create({});
  }),

  purchasedProduct: computed('products.@each.isPurchased', function () {
    return this.products?.find((p) => p.isPurchased);
  }),

  priceComputed: computed('selectedProduct.price', 'discount.newPrice', function () {
    // donations have this format "10.0", we skip the decimal part
    if (this.discount?.newPrice) {
      return this.discount.newPrice;
    } else if (this.id === 'donation') {
      return Math.round(this.selectedProduct.price);
    } else {
      return this.selectedProduct.price;
    }
  }),

  isSubscription: computed('selectedProduct', function () {
    return this.selectedProduct.type === 'subscription';
  }),

  isPurchased: computed('products.@each.isPurchased', function () {
    return this.products.find((p) => p.isPurchased);
  }),

  isCancelled: computed('products.@each.isCancelled', function () {
    return this.products.find((p) => p.isCancelled);
  }),

  isGranted: computed('products.@each.grantedBy', function () {
    return this.products?.find((p) => p.grantedBy === 'premium');
  }),

  isWebPurchase: computed('purchasedProduct.provider', function () {
    return ~['stripe', 'paypal'].indexOf(this.purchasedProduct?.provider);
  }),

  canBuy: computed('possibleOperations', 'id', function () {
    return this.possibleOperations?.includes('Purchase') || this.id === 'page';
  }),

  canChangePlan: computed('possibleOperations', 'isWebPurchase', 'purchasedProduct.provider', function () {
    // temporarily disabled for paypal, this needs specyfying flow for paypal where
    // transactionId for plan change will be created firstly because we want to support this flow
    if (this.purchasedProduct?.provider === 'paypal') return false;

    return this.isWebPurchase && this.possibleOperations?.includes('ChangePlan');
  }),

  canReactivate: computed('possibleOperations', 'isWebPurchase', function () {
    return this.isWebPurchase && this.possibleOperations?.includes('Reactivate');
  }),

  subscriptionText: computed('isSubscription', 'selectedProduct.period', function () {
    return this.isSubscription
      ? __('1-{period} Subscription', { period: capitalize(this.selectedProduct.period) })
      : '';
  }),
});

ItemModel.reopenClass({
  resourceName: 'store-item',
});

export default ItemModel;
