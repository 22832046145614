import Component from '@glimmer/component';
import { A } from '@ember/array';
import EmberObject, { observer, computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isNull } from 'lodash';
import { scheduleOnce, later, cancel } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import SmartSearchApi from 'mewe/api/smart-search-api';
import GroupApi from 'mewe/api/group-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import { minSmartSearchPhraseLength } from 'mewe/constants';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { Post } from 'mewe/stores/models/post-model';
import Group from 'mewe/stores/models/group-model';
import MessageFactory from 'mewe/stores/models/chat-message-model';
import Page from 'mewe/stores/models/page-model';
import toDisplaySimpleHighlight from 'mewe/stores/text-parsers/to-display-simple-highlight';
import CurrentUserStore from 'mewe/stores/current-user-store';
import ChatStore from 'mewe/stores/chat-store';
import ThreadFactory from 'mewe/stores/models/thread-model';
import { handleOtherStores, getFeed } from 'mewe/fetchers/fetch-feed';
import isUndefined from 'mewe/utils/isUndefined';
import { processMyContactsInGroups } from 'mewe/utils/group-utils-light';
import PS from 'mewe/utils/pubsub';
import Verbose from 'mewe/utils/verbose';

const verbose = Verbose({ prefix: '[Search]', color: 'teal', enabled: true }).log;

const Thread = ThreadFactory(CurrentUserStore, ChatStore);
const Message = MessageFactory(CurrentUserStore, ChatStore, Thread);

const Serializer = JSONSerializer.create();

export default class MwSmartSearch extends Component {
  @service analytics;

  @tracked phrase = '';
  @tracked lastPhraseValue = '';
  @tracked smartSearchActive;
  @tracked isFetchingMore;
  @tracked resultPages = A();
  @tracked isRequestPending = false;
  @tracked gotResponse = true;
  @tracked category = 'top';

  scrolling = Scrolling();
  isLoading = false;
  currentTask = null;
  pageCount = 0;
  pageSize = 20;

  defaultParams = {
    highlightStart: `<span class='word-highlight'>`,
    highlightEnd: `</span>`,
  };

  @action
  onInsertSearch(element) {
    this.elementSearch = element;

    this.focusSmartSearchBind = this.focusSmartSearch.bind(this);
    this.closeSmartSearchBind = this.closeSmartSearch.bind(this);
    this.openSmartSearchBind = this.openSmartSearch.bind(this);
    PS.Sub('focus.smart.search', this.focusSmartSearchBind);
    PS.Sub('close.smart.search', this.closeSmartSearchBind);
    PS.Sub('open.smart.search', this.openSmartSearchBind);

    // intentionally used observer instead of handling actions from input (keyUp or other)
    // to keep track of any type of change in one place (including closing search by button or by clicking outside)
    addObserver(this, 'phrase', this.phraseChanged);
  }

  @action
  onDestroySearch() {
    PS.Unsub('focus.smart.search', this.focusSmartSearchBind);
    PS.Unsub('close.smart.search', this.closeSmartSearchBind);
    PS.Unsub('open.smart.search', this.openSmartSearchBind);

    removeObserver(this, 'phrase', this.phraseChanged);
  }

  @action
  onInsertResults(element) {
    this.elementResults = element;

    document.documentElement.style.overflow = 'hidden';

    this.dragFuncBind = this.dragFunc.bind(this);
    document.addEventListener('dragover', this.dragFuncBind);

    const responsiveInput = this.elementResults.querySelector('.h-input_search');
    if (responsiveInput) responsiveInput.focus();
  }

  @action
  onDestroyResults() {
    document.documentElement.style.overflow = '';
    document.removeEventListener('dragover', this.dragFuncBind);

    if (this.elementToBind) {
      this.scrolling.unbindScrollDown(this.elementToBind);
    }
  }

  get phraseValue() {
    return this.phrase.trim();
  }

  @action
  phraseChanged() {
    if (this.lastPhraseValue === this.phraseValue) return;
    this.lastPhraseValue = this.phraseValue;

    this.clearArrays();

    if (this.phraseValue.length >= minSmartSearchPhraseLength) {
      this.isRequestPending = true;
      this.throttleSearch();
      this.openSmartSearch();
    } else {
      this.isRequestPending = false;

      // deleting input characters should close search only on desktop (not on mobile version of search)
      if (window.innerWidth >= 980) {
        // just hide results, don't call closeSmartSearch which clears state completely
        this.smartSearchActive = false;
      }
    }

    this.trackTyping();
  }

  throttleSearch() {
    const scheduleId = this.scheduleId;

    if (scheduleId) cancel(scheduleId);

    this.scheduleId = later(
      this,
      () => {
        if (!this.isDestroyed && !this.isDestroying) this.search();
      },
      350
    );
  }

  trackTyping() {
    // track typing once per "search session", when at least one character is typed in.
    // "session" ends when input is CLEARED AND UNFOCUSED
    if (!this.wasTypingTracked && this.phraseValue.length) {
      const theme = this.args.model.theme;
      let context = 'others';

      if (theme === 'contacts') context = 'home';
      else if (theme === 'store') context = 'store';
      else if (theme === 'profile') context = 'profile';
      else if (theme === 'privacymail') context = 'chats';
      else if (~theme.indexOf('page')) context = 'pages'; // indexOf to catch both pages/page
      else if (~theme.indexOf('group')) context = 'groups'; // indexOf to catch both groups/group
      else if (~theme.indexOf('event')) context = 'events'; // indexOf to catch both events/event

      this.analytics.sendEvent('typingInSearchStarted', {
        context: context,
      });

      this.wasTypingTracked = true;

      verbose(`Typing in Search started in context: ${context}`);
    }

    if (!this.phraseValue.length && !this.hasFocus && this.wasTypingTracked) {
      this.wasTypingTracked = false;
      verbose(`Typing session cleared`);
    }
  }

  dragFunc(e) {
    const files = e && e.dataTransfer;
    e.stopPropagation();
    e.preventDefault();

    if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
      e.preventDefault();
      return;
    }

    if (!this.isDestroyed && !this.isDestroying) {
      this.initDropZone();
    }
  }

  initDropZone() {
    let timeout = this.dropZoneTimeout;

    if (!timeout) {
      this.elementResults.parentNode.classList.add('dropping');
    } else {
      clearTimeout(timeout);
    }

    let dropZoneTimeout = setTimeout(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.dropZoneTimeout = null;
        this.elementResults.parentNode.classList.remove('dropping');
      }
    }, 100);

    this.dropZoneTimeout = dropZoneTimeout;
  }

  @computed('resultPages.@each.noResults', 'phraseValue')
  get noMatches() {
    if (!this.phraseValue) return false;

    return this.resultPages.every((page) => {
      return page.noResults;
    });
  }

  @computed('gotResponse')
  get showMenu() {
    return this.gotResponse;
  }

  @computed('phrase', 'defaultParams')
  get searchData() {
    return {
      searchTerm: this.phraseValue,
      highlightStart: this.defaultParams.highlightStart,
      highlightEnd: this.defaultParams.highlightEnd,
    };
  }

  search(isFetchingMore) {
    // TODO - performance boost solution so that request is not send after every key stroke
    let phrase = this.phraseValue;
    const doRequest = this.getEndpoint();
    let params = {
      query: phrase,
      highlighting: true,
    };

    if (!isFetchingMore) {
      this.pageCount = 0;
    }

    // jshint ignore: start
    params = {
      ...params,
      ...this.getPaginationParams(),
    };
    // jshint ignore: end

    if (phrase.length >= minSmartSearchPhraseLength) {
      this.currentTask = doRequest(params);

      this.currentTask
        .then((response) => {
          if (!this.smartSearchActive) return;
          if (this.isDestroyed) return;

          this.parseResponse(response, isFetchingMore);

          this.gotResponse = true;
          this.response = response; // TODO <---- only temporary
        })
        .finally(() => {
          this.currentTask = null;
        });
    }
  }

  getEndpoint() {
    const request = SmartSearchApi[this.category];

    if (typeof request === 'function' && request !== null) {
      return request.bind(SmartSearchApi);
    }

    return SmartSearchApi.top.bind(SmartSearchApi);
  }

  handleNoResultsInResponse(isFetchMore) {
    if (typeof isFetchMore !== 'undefined' && isFetchMore !== null) {
      if (isFetchMore) {
        this.hasMoreResults = false;
      } else {
        this.clearArrays();
        this.hasMoreResults = false;
      }
    } else {
      this.clearArrays();
      this.hasMoreResults = false;
    }
  }

  parseResponse({ hasMoreResults, results, relatedPosts = A(), postsData = {} }, isFetchMore) {
    if (this.isDestroyed || this.isDestroying) return;

    handleOtherStores(postsData);
    if (results.length === 0 && this.pageCount === 0) {
      this.handleNoResultsInResponse(isFetchMore);
    } else {
      const currentCategory = this.category;

      let resultPages = this.resultPages;

      let posts = A();
      let users = A();
      let groups = A();
      let chats = A(); // chatMessage + chatThread
      let comments = A();
      let pages = A();

      let i;
      for (let i = 0; i < results.length; i++) {
        let current = results[i];

        if (current.hasOwnProperty('post')) {
          posts.pushObject(current.post);
        } else if (current.hasOwnProperty('comment')) {
          comments.pushObject(current.comment);
        } else if (current.hasOwnProperty('user')) {
          const user = this.parseTextToHighlight(current.user);
          users.pushObject(user);
        } else if (current.hasOwnProperty('group')) {
          let groupModel = Serializer.deserializeOne(Group, this.parseTextToHighlight(current.group));
          groups.pushObject(groupModel);
        } else if (current.hasOwnProperty('chatMessage')) {
          if (current.chatMessage.textPlain !== '') {
            current.chatMessage.text = current.chatMessage.textPlain;
            current.chatMessage.recordType = 'chatMessage';

            chats.pushObject(Serializer.deserializeOne(Message, current.chatMessage));
          }
        } else if (current.hasOwnProperty('chatThread')) {
          chats.pushObject(this.chatThreadEnrichment(current.chatThread));
        } else if (current.hasOwnProperty('page')) {
          pages.pushObject(Serializer.deserializeOne(Page, this.parseTextToHighlight(current.page)));
        }
      }

      for (i = 0; i < comments.length; i++) {
        var current = comments[i];

        let matchIdx;
        if ((matchIdx = this.matchIndexIn(posts, current.postItemId)) !== -1) {
          typeof posts[matchIdx].comments === 'undefined' // TODO - extract into a separate function
            ? (posts[matchIdx].comments = { feed: [current] })
            : posts[matchIdx].comments.feed.push(current);

          posts[matchIdx].initialCommentsSet = true; //need for comments button to not load comments on click
        } else if ((matchIdx = this.matchIndexInRelated(relatedPosts, current.postItemId)) !== -1) {
          typeof relatedPosts[matchIdx].post.comments === 'undefined' // .post. because of a structure of relatedPosts from BE
            ? (relatedPosts[matchIdx].post.comments = { feed: [current], canBeMoreAfter: true, canBeMoreBefore: true })
            : relatedPosts[matchIdx].post.comments.feed.push(current);

          relatedPosts[matchIdx].post.initialCommentsSet = true; //need for comments button to not load comments on click
        } else {
          // TODO - this case should be logged, as normally it should never happen.
          // that means we got an comment without a related post data
        }
      }

      let filteredPosts = relatedPosts.filter((postRecord) => {
        return posts.findIndex(({ postItemId }) => postItemId === postRecord.post.postItemId) === -1;
      });

      posts.pushObjects(filteredPosts);

      posts = Serializer.deserializeAll(Post, posts);

      if (currentCategory === 'top') {
        if (posts && posts.length > 3) {
          posts = posts.slice(0, 3);
        }
        if (users.length > 3) {
          users = users.slice(0, 3);
        }
        if (groups.length > 3) {
          groups = groups.slice(0, 3);
        }
        if (chats.length > 3) {
          chats = chats.slice(0, 3);
        }
        if (pages.length > 3) {
          pages = pages.slice(0, 3);
        }
      }

      let postsFeed = getFeed('smart-search', 'smart-search');

      if (!isFetchMore) {
        postsFeed.posts = posts;
      } else {
        postsFeed.posts.pushObjects(posts);
      }
      const privacymailPosts = A(posts.filter((post) => post.scope === 'privacymail'));
      const notPrivacymailPosts = A(posts.filter((post) => post.scope !== 'privacymail'));
      let page = EmberObject.extend({
        posts: notPrivacymailPosts,
        members: users,
        groups: groups,
        chats: chats,
        chatPosts: privacymailPosts,
        pages: pages,
        noResultsObserver: observer('posts.length', function () {
          // only posts can be removed from collection
          const allIsEmpty = () => {
            return !this.posts?.length && !this.members?.length && !this.groups?.length && !this.chats?.length;
          };

          this.noResults = allIsEmpty();
        }),
      }).create();

      if (!isFetchMore) {
        resultPages.clear();
        resultPages.pushObject(page);
        this.resultPages = resultPages;
      }
      // posts use ds store and Feed model in order to be updated in a general way via feed action controller
      else if (currentCategory !== 'posts') {
        if (resultPages[0] && resultPages[0][currentCategory]) {
          resultPages[0][currentCategory].pushObjects(page[currentCategory]);
        }
        if (currentCategory === 'chats') {
          resultPages[0]['chatPosts'].pushObjects(page['chatPosts']);
        }
      }

      if (hasMoreResults) {
        if (currentCategory !== 'top') {
          scheduleOnce('afterRender', this, () => {
            if (this.isDestroying || this.isDestroyed) return;

            const elementToBind =
              currentCategory === 'posts' || currentCategory === 'pages'
                ? this.elementResults
                : this.elementResults.querySelector(`.smart-search_result--${currentCategory}`);

            this.scrolling.bindScrollDownElement(elementToBind, () => {
              this.elementToBind = elementToBind;
              this.fetchMoreResults();
            });
          });
        }
      } else {
        this.scrolling.unbindScrollDown(this.elementToBind);
      }

      // TODO - correction - empty chat messages - unless BE is ready:
      if (posts.length === 0 && users.length === 0 && groups.length === 0 && chats.length === 0 && pages.length === 0) {
        this.handleNoResultsInResponse(isFetchMore);
      }

      this.fetchGroupContactsForResults(page);
    }

    this.hasMoreResults = hasMoreResults;
    this.pageCount = this.pageCount + 1;
    this.isRequestPending = false;
    this.isFetchingMore = false;
  }

  parseTextToHighlight(object) {
    const nameParts = A(object.nameParts).filterBy('isHighlighted');
    object.nameDisplay = toDisplaySimpleHighlight(object.name, undefined, { textPartsHighlighted: nameParts });
    return object;
  }

  matchIndexIn(posts, expectedPostItemId) {
    return posts.findIndex(({ postItemId }) => {
      return postItemId === expectedPostItemId;
    });
  }

  matchIndexInRelated(posts, expectedPostItemId) {
    return posts.findIndex(({ post }) => {
      return post.postItemId === expectedPostItemId;
    });
  }

  clearArrays() {
    if (this.resultPages) {
      this.resultPages.clear();
    }
  }

  getPaginationParams() {
    const category = this.category;
    const pageSize = this.pageSize;
    const pageCount = this.pageCount;
    const newPaginationApis = ['top', 'groups', 'members'];

    if (newPaginationApis.indexOf(category) !== -1) {
      if (this.pageCount > 0) {
        return {
          // NEW pagination style
          page: pageCount + 1,
          pageSize,
        };
      }
    } else {
      let resultPages = this.resultPages;
      let resultsLength = 0;
      if (resultPages && resultPages[0] && category === 'chats') {
        resultsLength = A([...resultPages[0]['chatPosts'], ...resultPages[0]['chats']]).length;
      } else {
        if (resultPages && resultPages[0] && resultPages[0][category]) resultsLength = resultPages[0][category].length;
      }

      return {
        // OLD pagination style
        limit: pageSize,
        offset: resultsLength // I see that number of results is sometimes random so count offset basing on results.length
      };
    }
  }

  chatThreadEnrichment(chatThread) {
    // jshint ignore: start
    return {
      ...chatThread,

      id: chatThread.threadId,
      recordType: 'chatThread',
      query: this.phraseValue,
      highlightStart: this.defaultParams.highlightStart,
      highlightEnd: this.defaultParams.highlightEnd,
      isFromSearch: true,
    };
    // jshint ignore: end
  }

  fetchGroupContactsForResults(results) {
    // if there are any "group" results in search then do additional
    // request to get number of contacts in those groups
    if (results?.groups?.length) {
      const groupIds = results.groups.map((g) => g.id).join(',');

      GroupApi.myContactsInGroups({ groupIds: groupIds }).then((data) => {
        if (this.isDestroyed || this.isDestroying) return;

        processMyContactsInGroups(results.groups, data);
      });
    }
  }

  focusSmartSearch() {
    const input = this.elementSearch.querySelector('.h-input_search');
    input.focus();
    // nice blinking indication where the cursor was focused - used when click on Search members in FTUE dialog
    setTimeout(() => {
      input.classList.add('highlight');
    }, 200);
    setTimeout(() => {
      input.classList.remove('highlight');
    }, 600);
  }

  doSearch(selected) {
    this.isRequestPending = true;
    this.clearArrays();

    this.category = selected;
    this.search();
  }

  @action
  openSmartSearch() {
    this.smartSearchActive = true;
    this.args.setSmartSearchState(true);
  }

  @action
  closeSmartSearch() {
    this.phrase = '';
    this.smartSearchActive = false;
    this.args.setSmartSearchState(false);
  }

  @action
  outsideClick(e) {
    // make sure that click is exactly on this element and isn't just propagated from a child
    if (e.target.classList.contains('dialog_inner-wrapper')) {
      this.closeSmartSearch();
    }
  }

  @action
  focusIn() {
    this.hasFocus = true;
  }

  @action
  focusOut() {
    this.hasFocus = false;

    // focusOut is one of two triggers for tracking typing session
    this.trackTyping();
  }

  @action
  fetchMoreResults() {
    this.isFetchingMore = true;
    this.search(true); // <----- true means it's actually fetching more results
  }

  @action
  select(selected) {
    if (isUndefined(selected) || isNull(selected)) return;

    if (this.currentTask) {
      this.currentTask.catch(() => this.doSearch(selected));

      return this.currentTask.reject();
    }

    this.doSearch(selected);
  }

  @action
  removePost(page, post) {
    const posts = page.posts.filter((p) => p.postItemId !== post.postItemId);
    page.set('posts', posts);
  }

  @action
  removeChatPost(page, post) {
    const posts = page.chatPosts.filter((p) => p.postItemId !== post.postItemId);
    page.set('chatPosts', posts);
  }
}
