import { inject as service } from '@ember/service';
import Route from 'mewe/routes/route';

export default class Error404Route extends Route {
  @service router;

  redirect(redir) {
    if (redir && redir.path !== '404') {
      this.router.transitionTo('error-404');
    }

    document.body.classList.add('error-page');
  }
}
