import Component from '@glimmer/component';
import { action } from '@ember/object';

import PS from 'mewe/utils/pubsub';
import { removeFeatureHint } from 'mewe/utils/feature-hint';
import { fetchFeedGroups } from 'mewe/fetchers/fetch-feed';

export default class AppGroups extends Component {

  constructor() {
    super(...arguments);

    this.groupJoinHandlerBind = this.groupJoinHandler.bind(this);
    PS.Sub('group.join', this.groupJoinHandlerBind);

    //make sure that after changing from myworld feed is at the top
    window.scrollTo(0, 0);
  }

  groupJoinHandler() {
    fetchFeedGroups({ hashtag: this.args.model.hashTag });
  }

  @action
  onDestroy() {
    PS.Unsub('group.join', this.groupJoinHandlerBind);
    removeFeatureHint('ftue-tour'); // first visit of groups should show feature hints - #SG-9477
  }
}