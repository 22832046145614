import { computed } from '@ember/object';
import { A } from '@ember/array';
import { assign } from '@ember/polyfills';

function attr(type, opts) {
  var meta = assign(
    {
      type: type,
      isAttribute: true,
    },
    opts
  );
  return makeComputedAttribute(meta);
}

function belongsTo(type, opts) {
  var meta = assign(
    {
      type: type,
      isRelationship: true,
      belongsTo: true,
    },
    opts
  );
  return makeComputedAttribute(meta);
}

function hasMany(type, opts) {
  var defaultArray = function () {
      return A();
    },
    meta = assign(
      {
        type: type,
        isRelationship: true,
        hasMany: true,
        defaultValue: defaultArray,
      },
      opts
    );
  return makeComputedAttribute(meta);
}

function makeComputedAttribute(meta) {
  return computed('_data', {
    get(key) {
      let value;

      if (this._data) {
        value = this._data[key];
      }

      if (value === undefined) {
        if (typeof meta.defaultValue === 'function') {
          value = meta.defaultValue.call(this);
        } else {
          value = meta.defaultValue;
        }

        if (this._data) {
          this._data[key] = value;
        }
      }

      return value;
    },
    set(key, value) {
      if (this._data && this._data[key] !== value) {
        this._data[key] = value;

        if (!meta.readOnly) {
          this._onPropertyChange(key);
        }
      }

      return value;
    },
  }).meta(meta);
}

function aliasTo(name, attribute) {
  return attribute;
}

export { attr, belongsTo, hasMany, aliasTo };
