import Component from '@glimmer/component';
import { A } from '@ember/array';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { Theme, Target } from 'mewe/constants';
import CurrentUserStore from 'mewe/stores/current-user-store';
import PurchasesStore from 'mewe/stores/purchases-store';
import GroupStore from 'mewe/stores/group-store';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PermissionsUtils from 'mewe/utils/group-permissions-utils';
import PostApi from 'mewe/api/post-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import { tzName } from 'mewe/utils/datetime-utils';
import { openPostbox } from 'mewe/utils/dialogs-common';
import dispatcher from 'mewe/dispatcher';
import { uniqBy } from 'lodash';

export default class AppScheduledFeed extends Component {
  @service purchase;
  @service account;
  @service dynamicDialogs;

  @tracked timezone;
  @tracked purchasesLoaded = false;
  @tracked postsToDisplay = A();

  scrolling = Scrolling();

  premiumPreviewTrackingContext = 'post_scheduling';

  get model() {
    return this.args.model;
  }

  get page() {
    return this.args.model.page;
  }

  get group() {
    return this.args.model.groupModel?.group;
  }

  constructor() {
    super(...arguments);

    this.purchase.getPurchased().then(() => {
      // group/profile scheduled posts require premium, show Premium dialog if user doesn't have it
      if (this.premiumRequired) {
        this.dynamicDialogs.openDialog('premium-feature-dialog', { context: this.premiumPreviewTrackingContext });
      }

      this.purchasesLoaded = true;
    });

    CurrentUserStore.getState().deferred.promise.then(() => {
      this.timezone = tzName(this.account.activeUser?.timezone, this.account.activeUser?.jsLocale);
    });

    this.storeState = PurchasesStore.getState();

    this.updatePostsBinded = this.updatePosts.bind(this);
    addObserver(this, 'model.feed.sortedPosts.length', this.updatePostsBinded);

    this.updatePosts();
  }

  @action
  onInsert() {
    this.feedFieldsToObserve = [
      'model.feed.posts.length',
      'model.feed.canShowMore',
      'model.feed.connectionFail',
      'model.feed.nextPage',
    ];

    // scheduleOnce used to trigger only one callback when multiple observers are triggered
    this.loadMoreWrapped = () => {
      scheduleOnce('afterRender', this, this.bindInfiniteScroll);
    };

    this.feedFieldsToObserve.forEach((field) => {
      addObserver(this, field, this.loadMoreWrapped);
    });
  }

  willDestroy() {
    this.scrolling.unbindScrollDown();

    this.feedFieldsToObserve.forEach((field) => {
      removeObserver(this, field, this.loadMoreWrapped);
    });

    // if there are no posts left, set hasScheduledPosts to false when user leaves the page
    // (we decided not to do it immediately after deleting/releasing posts)
    if (this.args.theme === Theme.GROUP && !this.postsToDisplay.length) {
      GroupStore.getState({ id: this.group?.id }).set('hasScheduledPosts', false);
    }
  }

  bindInfiniteScroll() {
    this.scrolling.bindScrollDown(() => this.loadMorePosts(), 1200);
  }

  get hasPremium() {
    return this.storeState.hasPremium;
  }

  get premiumRequired() {
    return !this.hasPremium && (this.args.theme === Theme.CONTACTS || this.args.theme === Theme.GROUP);
  }

  get displayPremiumInfo() {
    const noPosts = !this.postsToDisplay.length && !this.model.feed.isFetching;
    return this.premiumRequired && noPosts;
  }

  get displayPremiumError() {
    return this.premiumRequired && this.postsToDisplay.length;
  }

  get isPremiumOrPage() {
    return this.hasPremium || this.args.theme === Theme.PAGE;
  }

  get publishDisabled() {
    if (!this.selectedPosts.length) {
      return true;
    }

    const cantReleaseSelectedPost = this.selectedPosts.find((p) => p.permissions.release !== true);

    return cantReleaseSelectedPost;
  }

  get deleteDisabled() {
    return !this.selectedPosts.length;
  }

  get newPostDisabled() {
    if (this.args.theme === Theme.PAGE) return false;
    if (this.args.theme === Theme.CONTACTS) return !this.hasPremium;
    if (this.args.theme === Theme.GROUP) {
      const canPostWithoutModeration = PermissionsUtils.canPostWithoutModeration(this.group.permissions);
      return !this.hasPremium || !canPostWithoutModeration;
    }
    return true;
  }

  updatePosts() {
    let arrayOfPosts = this.model.feed.sortedPosts
      .filter((post) => typeof post?.scheduled !== 'undefined')
      .sort((post1, post2) => {
        if (post1.scheduled === post2.scheduled) return post1.createdAt - post2.createdAt;
        return post1.scheduled - post2.scheduled;
      });
    arrayOfPosts = uniqBy(arrayOfPosts, 'id');

    if (this.args.theme === Theme.CONTACTS) {
      arrayOfPosts = arrayOfPosts.filter((post) => {
        return post.owner?.id === this.account.activeUser.id;
      });
    }

    this.postsToDisplay = arrayOfPosts;
  }

  @computed('postsToDisplay.@each.selected')
  get selectedPosts() {
    return this.postsToDisplay.filter((post) => post.selected);
  }

  @action
  select(post) {
    set(post, 'selected', !post.selected);
  }

  @action
  loadMorePosts() {
    if (this.model.feed.nextPage && !this.model.feed.isFetching) {
      dispatcher.dispatch('feed', 'loadMore', this.model.feed);
    }
  }

  @action
  edit() {
    const selected = this.selectedPosts;
    const target = this.args.theme === Theme.PAGE ? Target.PAGE : Target.CONTACTS;

    if (selected.length === 1) {
      const [post] = this.selectedPosts;
      openPostbox(
        {
          postToRepost: post,
          theme: this.args.theme,
          target: target,
          page: this.page,
        },
        this.dynamicDialogs
      );
    }
  }

  @action
  publish() {
    if (this.publishDisabled) return;

    const selected = this.selectedPosts;
    const promises = selected.map((post) => {
      let scopePath;

      if (post.groupId) {
        scopePath = `group/${post.groupId}`;
      } else if (post.page?.id) {
        scopePath = `pages/page/${post.page.id}`;
      } else {
        scopePath = `home`;
      }

      return PostApi.releasePost(post.id, scopePath);
    });

    Promise.all(promises)
      .then(() => {
        this.model.feed.posts.removeObjects(selected);
        FunctionalUtils.info(__('Your posts have been published.'));
      })
      .catch(() => {
        FunctionalUtils.error(__(`Couldn't publish posts. Please try again.`));
      });
  }

  @action
  delete() {
    if (this.deleteDisabled) return;

    dispatcher.dispatch('feed', 'deletePosts', {
      posts: this.selectedPosts,
      message: __(`You're about to delete {count} scheduled posts`, {
        count: this.selectedPosts.length,
      }),
      scheduled: true,
    });
  }

  @action
  new() {
    if (this.newPostDisabled) return;

    openPostbox(
      {
        theme: this.args.theme,
        target: this.args.theme,
        scheduleVisible: true,
        groupId: this.group?.id,
        page: this.page,
        scheduled: null,
      },
      this.dynamicDialogs
    );
  }

  @action
  optionClick(option) {
    if (option.enabled) {
      this[option.action]();
    }
  }

  @action
  purchasePremium() {
    dispatcher.dispatch('purchase', 'showItemDetails', 'premium', { context: this.premiumPreviewTrackingContext });
  }
}
