/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { each } from 'lodash';
import Scrolling from 'mewe/utils/scrolling-utils';
import PagesApi from 'mewe/api/pages-api';

export default class MwUserSelectDropdown extends Component {
  @service account;

  maxResults = 10;
  scrolling = Scrolling();
  inputFocused = false;

  @tracked loading = false;
  @tracked opened = false;
  @tracked searchText = '';
  @tracked results = A();

  constructor() {
    super(...arguments);
    this.setDefaultValues();
  }

  @action
  onInsert(element) {
    this.element = element;

    this.clickHandlerBind = this.clickHandler.bind(this);
    document.addEventListener('click', this.clickHandlerBind);
  }

  willDestroy() {
    document.removeEventListener('click', this.clickHandlerBind);
    this.scrolling.unbindScrollDown(this.element.querySelector('.dropdown-menu-results'));
  }

  clickHandler(e) {
    if (!e.target.closest('.user-select-dropdown')) {
      this.closeSearchResults();
    }
  }

  setDefaultValues() {
    this.loading = false;
    this.opened = false;
    this.searchText = '';
    this.results = A();
    this.inputFocused = false;
  }

  get selectedIds() {
    return this.args.selectedItems.map((i) => i.user.id);
  }

  @action
  doSearch() {
    this.search();
  }

  closeSearchResults() {
    if (!this.isDestroying && !this.isDestroyed) {
      this.setDefaultValues();
    }
  }

  @action
  focusInSearch() {
    this.inputFocused = true;
    this.search();
  }

  @action
  search(nextPage) {
    if (!this.inputFocused) return;

    const params = {
      'with-admins': false,
      sort: 'name',
      str: this.searchText.trim(),
      nextPage: nextPage || null,
    };

    clearTimeout(this.searchTimeoutId);

    if (!params.str && !this.args.allowEmptySearch) {
      return this.closeSearchResults();
    }

    this.searchTimeoutId = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;

      this.opened = true;
      this.loading = true;

      const thenCb = (data) => {
        if (this.isDestroyed || this.isDestroying) return;

        const selectedIds = this.selectedIds;
        const items = A(data.followers);

        each(items, (i) => {
          if (this.selectedIds.length) {
            i.isSelected = selectedIds.indexOf(i.user.id) !== -1;
          }
        });

        if (params.nextPage) {
          this.results.pushObjects(items);
        } else {
          this.results = items;
        }

        if (data._links.nextPage) {
          this.scrolling.bindScrollDownElement(this.element.querySelector('.dropdown-menu-results'), () =>
            this.search(data._links.nextPage.href)
          );
        } else {
          this.scrolling.unbindScrollDown(this.element.querySelector('.dropdown-menu-results'));
        }
      };

      PagesApi.followers(this.args.pageId, params)
        .then(thenCb)
        .finally(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.loading = false;
        });
    }, 500);
  }

  @action
  select(item) {
    if (item.isSelected) return false;

    this.args.select(item.user);
    this.closeSearchResults();
  }

  @action
  clearSearch() {
    this.searchText = null;
  }
}
