import TwitterText from 'twitter-text';
import { isEmpty, each } from 'lodash';
import EmberObject from '@ember/object';

const toP = (el) => (el ? `<div>${el}</div>` : `<div><br></div>`);
const isInsertNewLine = (content) => {
  content?.ops?.length && content.ops[0].insert && content.ops[0].insert.match && content.ops[0].insert.match(/\n/);
};

export const toQuillFormat = (txt) => {
  let htmlToInsert = txt.split('\n').map(toP).join('');
  return htmlToInsert;
};

export const wasFormattingChange = (quill, b) => {
  let content;

  if (b.index > 0) {
    content = quill.getContents(b.index - 1, 1);

    if (isInsertNewLine(content)) {
      content = quill.getContents(b.index, 1);

      if (isInsertNewLine(content)) {
        return false;
      }
    }
  }

  return true;
};

/**
 * Quill operates on html, but we want to have markdown there
 * This function changes markdown code to html, so quill output is consitent in html
 */

export const makeCodeBlock = (txt) => {
  const codeRegex = /```(.*?)```/gim;

  let matches = [];
  let newDoc = txt;

  while ((matches = codeRegex.exec(txt))) {
    if (matches && matches[1]) {
      const lines = matches[1].split('</div><div>');

      const lang = lines.length > 1 ? lines.shift() : null;

      const before = isEmpty(lang) ? `<pre><code>` : `<pre><code class="language-${lang}">`;

      const after = `</code></pre>`;

      const replaceString = before + lines.join('\n') + after;

      newDoc = newDoc.replace(matches[0], replaceString);
    }
  }

  return newDoc;
};

const replaceHashtags = (text) => {
  let richText = '';

  if (text) {
    // hashtags sorted by order of appearance
    let entities = TwitterText.extractHashtagsWithIndices(text)
        .sort((a, b) => a.indices[0] - b.indices[0])
        .splice(0, 50),
      beginIndex = 0;

    each(entities, (e) => {
      richText += text.substring(beginIndex, e.indices[0]);
      richText += `<span class="text-editor_embed" data-text="#${e.hashtag}"><span contenteditable="false">#${e.hashtag}</span></span>`;
      beginIndex = e.indices[1];
    });

    if (text) richText += text.substring(beginIndex, text.length);
  }

  return richText;
};

export const insertContent = (text, quill, Mentions, enableHashtags) => {
  const contentWithHtmlMentions = enableHashtags ? replaceHashtags(text) : text;

  const delta = quill.clipboard.convert(toQuillFormat(contentWithHtmlMentions));

  quill.setContents(delta, 'api');

  const content = makeCodeBlock(quill.root.innerHTML);

  return content;
};

export const parseExistingMentions = (mentionsStrategy, content, Mentions) => {
  let existingMentions = Mentions.parseMentions(EmberObject.create({ textServer: content }));

  if (mentionsStrategy) {
    mentionsStrategy.mentions = mentionsStrategy.mentions.concat(existingMentions);

    return mentionsStrategy.mentions;
  }
};

export const addCustomRange = (node, offset) => {
  const range = document.createRange();
  const selection = window.getSelection();

  range.setStart(node, offset);
  range.collapse(true);

  selection.removeAllRanges();
  selection.addRange(range);
};
