import { inject as service } from '@ember/service';
import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { getOffset } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';
import { action } from '@ember/object';

export default class MwChatInfoMemberDropdown extends PopupOpener {
  @service('chat') chatService;

  layout = layout;

  popupType = 'dropdown-popup';

  closeOnClickOutside = true;

  closeOnHoverOutside = true;

  closeOnScroll = true;

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();
    const parentOffset = getOffset(this.parent);

    Object.assign(position, {
      reposition: { elementHeight: 230 },
      parentOffset: {
        top: parentOffset.top,
        left: parentOffset.left,
      },
      placement: {
        top: false,
        left: isRtl,
      },
      translate: {
        x: -20,
        altY: -18,
      },
    });
    return position;
  }

  get canRemoveFromGroup() {
    return this.args.thread.isGroupChat && this.args.user.canRemove;
  }

  get canRemoveFromChat() {
    return this.args.thread.isMultiUsersChatOwner;
  }

  get canChat() {
    return this.args.currentUserId !== this.args.user.id && this.args.user.canSendMessage;
  }

  @action
  chat() {
    this.chatService.openThreadByParticipants([this.args.user], { isChatOptions: true });
  }

  @action
  removeFromGroup() {
    dispatcher.dispatch('group-member', 'removeMember', this.args.user, this.args.thread.group);
  }

  @action
  removeFromChat() {
    dispatcher.dispatch('chat', 'removeChatParticipant', this.args.thread, this.args.user);
  }
}
