import { getOwner } from '@ember/application';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import Scrolling from 'mewe/utils/scrolling-utils';
import PageAnalyticsBase from '../app-page-statistics';

export default class AppPageStatisticsPosts extends PageAnalyticsBase {
  routeName = 'route:app.publicid.statistics.posts';

  @reads('args.model.page') page;
  @reads('args.model') model;

  @action
  onInsert() {
    this.scrolling = Scrolling();

    if (this.args.model.feed.nextPage) {
      this.bindScrolling();
    }

    addObserver(this, 'model.feed.nextPage', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'model.feed.nextPage', this.bindScrolling);
  }

  bindScrolling() {
    this.scrolling.bindScrollDown(() => this.loadMorePosts(), 1200);
  }

  @computed('model.overview.engagement.total.count')
  get subsummaryItems() {
    return [
      {
        title: __('Shares'),
        count: this.model.overview.engagement?.shares?.count || 0,
        change: this.model.overview.engagement?.shares?.change || 0,
      },
      {
        title: __('Emojis'),
        count: this.model.overview.engagement?.emojis?.count || 0,
        change: this.model.overview.engagement?.emojis?.change || 0,
      },
      {
        title: __('Comments'),
        count: this.model.overview.engagement?.comments?.count || 0,
        change: this.model.overview.engagement?.comments?.change || 0,
      },
    ];
  }

  @action
  loadMorePosts() {
    const route = getOwner(this).lookup(this.routeName);
    if (route) route.send('loadMorePosts');
  }
}
