import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class AppGroupSettings extends Component {
  @tracked activeSettingsTab = null;

  constructor() {
    super(...arguments);

    if (this.args.model?.initialTabNumber) {
      this.activeSettingsTab = this.args.model.initialTabNumber;
    } else {
      if (this.args.model?.group?.isFetching) {
        addObserver(this, 'args.model.group.isFetching', this, this.setInitialTab);
        this.isObserverSet = true;
      } else {
        this.setInitialTab();
      }
    }
  }

  willDestroy() {
    if (this.isObserverSet) {
      removeObserver(this, 'args.model.group.isFetching', this, this.setInitialTab);
    }
  }

  setInitialTab() {
    if (!this.args.model?.group?.isFetching) {
      this.activeSettingsTab = this.showGeneralSettings ? 0 : 1;
    }
  }

  @computed('args.model.group.isOwnerAdmin')
  get showGeneralSettings() {
    return this.args.model?.group?.isOwnerAdmin;
  }

  @action
  switchSettingsTab(tabIndex) {
    this.activeSettingsTab = tabIndex;
  }
}
