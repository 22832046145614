import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import AccountApi from 'mewe/api/account-api';
import { processCloudStorageData } from 'mewe/utils/miscellaneous-utils';

export default class MwCloudStorageUsage extends Component {
  @service dynamicDialogs;

  @tracked cloudStorage;

  constructor() {
    super(...arguments);
    this.fetchAndSetUsedSpaceDetails();
  }

  fetchAndSetUsedSpaceDetails() {
    AccountApi.getSpaceUsedStatus(false).then((data) => {
      if (this.isDestroyed || this.isDestroying) return;
      this.cloudStorage = processCloudStorageData(data);
    });
  }

  @action
  openGetSpaceDialog() {
    this.dynamicDialogs.openDialog('store/store-item-storage-dialog');
  }

  @action
  openDownloadContentDialog() {
    this.dynamicDialogs.openDialog('download-content-dialog');
  }
}
