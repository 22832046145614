import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce, later } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';
import { htmlSafe } from '@ember/template';

import { each } from 'lodash';
import { getElWidth } from 'mewe/utils/elements-utils';
import GroupApi from 'mewe/api/group-api';
import config from 'mewe/config';

export default class MwPhotoCarousel extends Component {
  @tracked predefinedImages;
  @tracked currentImageNo;
  @tracked carouselMargin;
  @tracked imgWidth;
  @tracked loaded;

  constructor() {
    super(...arguments);

    GroupApi.getPredefinedPhotos({ system: this.args.theme }).then((data) => {
      if (this.isDestroying || this.isDestroyed) return;

      each(data.images, (img) => {
        img._links.self.href = img._links.self.href.replace('/img/', config.cdnHost + '/app/');
      });

      this.predefinedImages = data.images;
      this.currentImageNo = Math.floor(Math.random() * 12);

      scheduleOnce('afterRender', this, () => {
        if (this.isDestroying || this.isDestroyed) return;
        this.setImgSize();
        this.updateCarousel();

        this.updateCarouselBinded = this.updateCarousel.bind(this);
        addObserver(this, 'currentImageNo', this.updateCarouselBinded);
      });
    });
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    removeObserver(this, 'currentImageNo', this.updateCarouselBinded);
  }

  setImgSize() {
    const imgEl = this.element.querySelector('.predefined-images-wrapper');
    if (imgEl) {
      this.imgWidth = htmlSafe(`width: ${getElWidth(imgEl)}px;`);
    }
  }

  initialPhotoLoaded() {
    if (this.isDestroying || this.isDestroyed) return;

    this.loaded = true;
    this.element
      .querySelector(`[data-picid='${this.args.picId}']`)
      .removeEventListener('load', this.initialPhotoLoadedBind);
  }

  updateCarousel() {
    later(
      this,
      () => {
        if (this.isDestroying || this.isDestroyed) return;

        this.args.resetUploadedImage(); // needed if image was uploaded and then carousel navigation clicked
        this.args.setPictureId(this.predefinedImages[this.currentImageNo].picId);

        if (!this.loaded) {
          const imgEl = this.element.querySelector(`[data-picid='${this.args.picId}']`);
          const isLoaded = imgEl.complete && imgEl.naturalHeight !== 0;

          if (isLoaded) {
            this.loaded = true;
          } else {
            this.initialPhotoLoadedBind = this.initialPhotoLoaded.bind(this);
            imgEl.addEventListener('load', this.initialPhotoLoadedBind);
          }
        }

        const imgEl = this.element.querySelector('.predefined-images-list img');
        if (!imgEl) return 0;

        const isRtl = document.dir,
          margin = this.currentImageNo * (getElWidth(imgEl) + 4);

        if (isRtl) {
          this.carouselMargin = htmlSafe(`margin-right: -${margin}px;`);
        } else {
          this.carouselMargin = htmlSafe(`margin-left: -${margin}px;`);
        }
      },
      300
    );
  }

  @action
  nextPhoto() {
    const no = this.currentImageNo;

    if (no === this.predefinedImages.length - 1) {
      this.currentImageNo = 0;
    } else {
      this.currentImageNo = no + 1;
    }
  }

  @action
  previousPhoto() {
    const no = this.currentImageNo;

    if (no === 0) {
      this.currentImageNo = this.predefinedImages.length - 1;
    } else {
      this.currentImageNo = no - 1;
    }
  }
}
