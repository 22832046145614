import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { getMembershipStatusInfo } from 'mewe/utils/group-utils-light';
import { getGroupContactsText } from 'mewe/utils/group-utils-light';

const ENTER_KEY = 13;

export default class MwTileWide extends Component {
  @service('-routing') routing;
  tabindex = 0;
  role = 'button';

  constructor() {
    super(...arguments);
    this.thumb = this.args.thumb || '';
  }

  @computed('args.model.{alreadyApplied,isMember,isInvited}')
  get membershipStatus() {
    if (this.args.isGroup) return getMembershipStatusInfo(this.args.model);
    else return false;
  }

  @computed('args.model.groupContacts.length')
  get groupContactsText() {
    return getGroupContactsText(this.args.model);
  }

  @action
  keyDown({ keyCode }) {
    if (keyCode === ENTER_KEY) {
      this.click();
    }
  }

  @action
  click() {
    if (typeof this.args.onClick === 'function') {
      this.args.onClick();
    }

    if (this.args.route) {
      this.routing.transitionTo(this.args.route, [this.args.routeParam]);
    }
  }
}
