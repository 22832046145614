import Helper from '@ember/component/helper';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default Helper.extend({
  compute(value, options) {
    let { url, imageSize } = options;

    // host only for own stories and excluding usecase when they are in chat message (treated as non-private there)
    let host = ~url.indexOf('photo/own-story') ? EnvironmentUtils.getImgHost(true) : '';

    return host + url.replace('{imageSize}', imageSize);
  },
});
