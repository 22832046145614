import { action } from '@ember/object';

import Component from '@glimmer/component';

export default class ClListItem extends Component {
  @action
  didClick() {
    if (this.args.onClick && !this.args.disabled) {
      this.args.onClick(...arguments);
    }
  }
}
