import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import { WrapperScope } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class MwWrappersOptions extends Component {
  @tracked wrappersOptionsDropdownOpened;

  @action
  toggleOptionsDropdown() {
    this.wrappersOptionsDropdownOpened = !this.wrappersOptionsDropdownOpened;
  }

  @action
  dismissAll() {
    dispatcher.dispatch('feed', 'dismissWrappers', {
      all: this.args.scope === WrapperScope.ALL,
      allGroups: this.args.scope === WrapperScope.GROUP,
      allPages: this.args.scope === WrapperScope.PAGE,
    });
    this.toggleOptionsDropdown();
  }
}
