/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
let { isDefined } = MiscellaneousUtils;

export default class MwEventListItem extends Component {
  @service account;
  @service router;
  @service chat;

  @reads('args.eventData') eventData;

  @computed('args.collection')
  get showDateLabel() {
    if (this.eventData.dateMonth === this.args.collection.monthName) {
      return false;
    } else {
      set(this, 'args.collection.monthName', this.eventData.dateMonth);
      return true;
    }
  }

  @computed('eventData.id')
  get disabled() {
    return this.eventData.id ? false : true;
  }

  @computed('eventData.user.firstName')
  get birthdayText() {
    if (!this.eventData.user?.firstName) return '';

    const trimmedFirstName =
      this.eventData.user.firstName.length > 15
        ? this.eventData.user.firstName.substring(0, 12) + '...'
        : this.eventData.user.firstName;
    return __('Send {userName} a Birthday Message', {
      userName: trimmedFirstName,
    });
  }

  @computed('router.currentRouteName')
  get type() {
    let routeName = this.router.currentRouteName;
    return routeName.slice(routeName.lastIndexOf('.') + 1, routeName.length);
  }

  @computed('eventData.{startDate,isBday,month,day}')
  get eventSimpleDate() {
    let d;

    if (this.eventData.isBday) {
      d = new Date(Date.UTC(new Date().getUTCFullYear(), this.eventData.month - 1, this.eventData.day, 0, 0));
    } else {
      d = new Date(this.eventData.startDate);
    }

    return d.toLocaleDateString(this.account.activeUser.jsLocale, {
      month: 'short',
      day: 'numeric',
      timeZone: this.eventData.timezone, // TODO: remove
    });
  }

  @computed('eventData.{startDate,isBday,month,day}')
  get eventDayOfWeek() {
    let d;

    if (this.eventData.isBday) {
      const now = new Date(),
        eventDay = this.eventData.day,
        eventMonth = this.eventData.month - 1;

      let year = now.getUTCFullYear();

      if (now.getUTCMonth() > eventMonth || (now.getUTCMonth() === eventMonth && now.getUTCDate() > eventDay)) {
        year += 1;
      }

      d = new Date(Date.UTC(year, eventMonth, eventDay, 0, 0));
    } else {
      d = new Date(this.eventData.startDate);
    }

    return d.toLocaleDateString(this.account.activeUser.jsLocale, {
      weekday: 'long',
      timeZone: this.eventData.timezone, // TODO: remove
    });
  }

  @action
  startChat(user) {
    this.chat.openThreadByParticipants([user], { userIsContact: true });
  }

  @action
  openUserProfile() {
    let groupId = this.eventData.groupId;
    let userId = this.eventData.user?.id;
    let profileId = this.eventData.user?.publicLinkId;

    if (isDefined(groupId)) this.router.transitionTo('app.group.members.profile', groupId, userId);
    else this.router.transitionTo('app.publicid', profileId);
  }
}
