import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isSafari } from 'mewe/shared/utils';

export default class MwTextarea extends Component {
  element;

  @action
  onInsert(element) {
    this.element = element;
    this.pasteFuncBind = this.pasteFunc.bind(this);
    this.element.querySelector('textarea').addEventListener('paste', this.pasteFuncBind);
    addObserver(this, 'args.value', this.shouldConfirmExit);
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.value', this.shouldConfirmExit);
    this.element.querySelector('textarea').removeEventListener('paste', this.pasteFuncBind);
  }

  // added to component as inline styles and also to <pre> element inside component
  @computed('args.maxheight')
  get style() {
    return htmlSafe('max-height: ' + (this.args.maxheight ? this.args.maxheight + 'px;' : 'auto;'));
  }

  @computed('args.minheight')
  get minheight() {
    return htmlSafe('min-height: ' + (this.args.minheight ? this.args.minheight + 'px;' : '16px;'));
  }

  @computed('minheight', 'style')
  get maxMinHeightInline() {
    return htmlSafe(`${this.minheight}${this.style}`);
  }

  focusOutAction() {}

  pasteFunc(event) {
    const max = this.element.querySelector('textarea').getAttribute('maxlength'),
      clipboardData = event.clipboardData || window.clipboardData;

    clipboardData.getData('text/plain').slice(0, max);
  }

  shouldConfirmExit() {
    if (this.confirmExit) {
      if (this.args.value && this.args.value.length) {
        window.onbeforeunload = this.confirmExit;
      } else {
        window.onbeforeunload = null;
      }
    }
  }

  @action
  focusOut() {
    this.args.focusOutAction?.(arguments);
  }

  @action
  keyUp(event) {
    if (event.which === 13) {
      // shiftKey works in textarea by default, alt and ctrl has to be handled separately
      if ((event.altKey || event.ctrlKey) && !isSafari()) {
        this.args.setText(event.target.value + '\n');
      } else if (this.args.sendWithEnter && !event.shiftKey && !event.altKey && !event.ctrlKey) {
        event.preventDefault(); // Don't insert newlines when submitting with enter
        this.args.enter(arguments);
      } else {
        this.args.setText(event.target.value);
      }
    } else {
      this.args.setText(event.target.value);
    }
  }
}

/*import { observer, computed } from '@ember/object';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';

import { isSafari } from 'mewe/shared/utils';

export default Component.extend({
  classNames: ['sg-textarea-container', 'sg-textarea-container-expandable'],
  classNameBindings: ['classParent'],
  attributeBindings: ['style', 'data-testid'],

  focusOutAction: () => {},

  didInsertElement: function () {
    this.pasteFuncBind = this.pasteFunc.bind(this);
    this.element.querySelector('textarea').addEventListener('paste', this.pasteFuncBind);
  },

  willDestroyElement() {
    this.element.querySelector('textarea').removeEventListener('paste', this.pasteFuncBind);
  },

  pasteFunc(event) {
    const max = this.element.querySelector('textarea').getAttribute('maxlength'),
      clipboardData = event.clipboardData || window.clipboardData;

    clipboardData.getData('text/plain').slice(0, max);
  },

  shouldConfirmExit: observer('value', function () {
    if (this.confirmExit)
      if (this.value && this.value.length) {
        window.onbeforeunload = this.confirmExit;
      } else {
        window.onbeforeunload = null;
      }
  }),

  getminheight: computed('minheight', function () {
    return htmlSafe('min-height: ' + (this.minheight ? this.minheight + 'px;' : '16px;'));
  }),

  // added to component as inline styles and also to <pre> element inside component
  style: computed('maxheight', function () {
    return htmlSafe('max-height: ' + (this.maxheight ? this.maxheight + 'px;' : 'auto;'));
  }),

  maxMinHeightInline: computed('getminheight', 'style', function () {
    return htmlSafe(`${this.getminheight}${this.style}`);
  }),

  actions: {
    focusOut: function () {
      this.focusOutAction(arguments);
    },

    keyDown: function (event) {
      if (event.which === 13) {
        // shiftKey works in textarea by default, alt and ctrl has to be handled separately
        if ((event.altKey || event.ctrlKey) && !isSafari()) {
          this.set('value', this.value + '\n');
        } else if (this.sendWithEnter && !event.shiftKey && !event.altKey && !event.ctrlKey) {
          event.preventDefault(); // Don't insert newlines when submitting with enter
          this.enter(arguments);
        }
      }
    },
  },
});*/
