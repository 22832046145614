import { assert } from '@ember/debug';
import ApiUtil from './api';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import { Theme } from 'mewe/constants';
let { isDefined } = MiscellaneousUtils;

/**
 * USAGE EXAMPLE:
 *
 * import CommentApi from 'mewe/api/comments-api';
 */
class Api extends ApiUtil {
  getComments(postItemId, scope, scopeId, params, nextPageUrl) {
    if (nextPageUrl) {
      return this.getNextPageOr(nextPageUrl);
    }

    const apiRoot = scope === Theme.GROUP ? 'apiRoot3' : 'apiRoot2';

    if (scope === Theme.CONTACTS) {
      return this.get(`/home/post/${postItemId}/comments`, {
        data: params,
      });
    } else {
      if (scope === Theme.PRIVATEPOSTS) scope = 'privateposts';
      if (scope === Theme.PAGE) scope = 'pages/page';

      return this.get(
        `/${scope}/${scopeId}/post/${postItemId}/comments`,
        {
          data: params,
        },
        apiRoot
      );
    }
  }

  addComment(postItemId, scope, scopeId, params) {
    assert('addComment-api postItemId is missing', isDefined(postItemId));
    assert(
      'addComment-api scope is missing or incorrect',
      scope === Theme.GROUP ||
        scope === Theme.CONTACTS ||
        scope === Theme.EVENT ||
        scope === Theme.PRIVATEPOSTS ||
        scope === Theme.PAGE
    );
    assert('addComment-api scopeId is missing or incorrect', isDefined(scopeId) || scope === Theme.CONTACTS);
    assert('addComment-api params is missing', isDefined(params));

    const apiRoot = scope === Theme.GROUP ? 'apiRoot3' : 'apiRoot2';

    if (scope === Theme.CONTACTS) {
      return this.post(`/home/post/${postItemId}/comments`, {
        data: JSON.stringify(params),
      });
    } else {
      if (scope === Theme.PRIVATEPOSTS) scope = 'privateposts';
      if (scope === Theme.PAGE) scope = 'pages/page';

      return this.post(
        `/${scope}/${scopeId}/post/${postItemId}/comments`,
        {
          data: JSON.stringify(params),
        },
        apiRoot
      );
    }
  }

  addReply(commentId, params) {
    return this.post('/comments/' + commentId + '/reply', {
      data: JSON.stringify(params),
    });
  }

  getReplies(commentId, params) {
    return this.get('/comments/' + commentId + '/replies', {
      data: params,
    });
  }

  getCommentInfo(commentId) {
    return this.get('/comments/' + commentId, {});
  }

  postEmoji(options, emoji) {
    if (!options || !options.model) return;

    return this.post(`/comments/${options.model.id}/emojis`, {
      data: JSON.stringify([emoji]),
    });
  }

  deleteEmoji(options, params) {
    if (!options || !options.model) return;

    params.emojis = encodeURIComponent(params.emojis);
    return this.del(`/comments/${options.model.id}/emojis?emojis=${params.emojis}`, {});
  }

  getEmoji(options, params) {
    if (!options || !options.model) return;

    return this.getNextPageOr(`/comments/${options.model.id}/emoji`, params);
  }

  getAllEmojis(options, params) {
    if (!options || !options.model) return;

    return this.getNextPageOr(`/comments/${options.model.id}/emojis`, params);
  }

  editComment(params, commentId) {
    return this.put('/comments/' + commentId, {
      data: JSON.stringify(params),
    });
  }

  removeComment(commentId) {
    return this.del(`/comments/${commentId}`, {});
  }

  followComment(commentId) {
    return this.put(`/comments/${commentId}/follow`);
  }

  unfollowComment(commentId) {
    return this.del(`/comments/${commentId}/follow`);
  }

  getCommentContext(options = {}) {
    switch (options.scope) {
      case Theme.PRIVATEPOSTS:
        return this.get(`/privateposts/${options.threadId}/comment/${options.commentId}/context`);
      case Theme.GROUP:
        return this.get(`/group/${options.groupId}/comment/${options.commentId}/context`, {}, 'apiRoot3');
      case Theme.EVENT:
        return this.get(`/event/${options.eventId}/comment/${options.commentId}/context`);
      case Theme.PAGE:
        return this.get(`/pages/page/${options.pageId}/comment/${options.commentId}/context`);
      default:
        return this.get(`/home/comment/${options.commentId}/context`);
    }
  }
}

export default new Api();
