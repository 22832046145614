import Store from './store';
import NotificationsApi from 'mewe/api/notifications-api';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import MiscUtils from 'mewe/utils/miscellaneous-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import ChatStore from 'mewe/stores/chat-store';
import { setTitleCount } from 'mewe/shared/storage';
import { daysDiff, getLocalizedBirthDateString } from 'mewe/utils/datetime-utils';
import Emoji from 'mewe/utils/emoji-utils';
import PS from 'mewe/utils/pubsub';
import { emojisList } from 'mewe/stores/models/mixins/model-emojis-list';
import { hasGifUrlOnly, gifToSmallStill, gifUrlRegExp } from 'mewe/utils/gif-utils';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import { each, compact, escape } from 'lodash';
import { fetchInvitations } from 'mewe/fetchers/fetch-pages';
import EmberObject, { set } from '@ember/object';
import { A } from '@ember/array';

import fetchGroup from 'mewe/fetchers/fetch-group';

import {
  getActingUserHTML,
  getActingUserHandle,
  getActingUsersHTML,
  getCommentText,
  getCommentReplyText,
} from 'mewe/utils/notifications-text-utils';
import { pageNotificationsCenter, Theme } from 'mewe/constants';

let state = EmberObject.create({
  init() {
    this.setProperties({
      unseenCount: 0,
      unseenCountDisplay: 0,
      unseenIds: {},
      unVisitedCount: 0,
      feed: A(),
      nextPageLink: null,
      loading: false,
      markSeenLoading: false,
      desktopNotifRef: null,
    });
  },
});

/**
 * import NotificationsStore from 'mewe/stores/notifications-store';
 */
var self = Store.extend({
  init() {
    this._super();
    PS.Sub('notification.add', (data) => this.wsNotificationAdd(data));
    PS.Sub('desktop.notification.add', (data) => this.wsDesktopNotificationAdd(data));
    PS.Sub('group.application.remove', (data) => this.removeGroupApplications(data));
  },

  getState() {
    return state;
  },

  getById(notificationId) {
    return state.feed.find((f) => f.id === notificationId);
  },

  willDestroy() {
    PS.Unsub('notification.add', 'clearAll');
    this._super();
  },

  wsNotificationAdd(data) {
    if (data && data.newCount && data.ids) {
      let unseen = state.get('unseenCount'),
        newUnseen = unseen;

      // we want to avoid situation to increase counter for same notification - e.g. user likes/unlikes and again likes same post/comment
      each(data.ids, (id) => {
        if (!state.get('unseenIds.' + id) || state.get('unseenIds.' + id) === 0) {
          state.set('unseenIds.' + id, data.newCount);
          newUnseen += data.newCount;
        }

        // play sound only when newCount is more than 0, e.g. don't play sound for -1, when user removed last emoji
        let userPrefs = CurrentUserStore.getState();
        if (userPrefs && userPrefs.get('allowNotifSound') && data.newCount > 0) {
          MiscUtils.playNotificationSound();
        }
      });

      let notification = data.data;
      if (notification && notification.notificationType === 'mention' && !notification.threadId) {
        let iconUrl =
          EnvironmentUtils.getImgHost(true) +
          notification.actingUsers[0]._links.avatar.href.replace('{imageSize}', '150x150');
        this.dispatch('notification', 'notify', {
          type: 'mentions',
          title: this.getNotificationText(notification, true),
          body: this.getPostText(notification),
          icon: iconUrl,
          tag: 'mentions' + (notification.postData ? notification.postData.postItemId : notification.commentData.id),
          onClick: () => {
            this.dispatch('notification', 'openPostCallback', notification);
          },
        });
      } else if (notification && notification.notificationType === 'mention' && notification.threadId) {
        let thread = ChatStore.getThreadById(notification.threadId);
        if (thread) {
          thread.incrementProperty('unreadMentions', 1);
        }
      }

      // SG-15770
      if (notification && notification.notificationType === 'group_invitation' && notification.group) {
        fetchGroup(notification.group.id);
      }

      if (notification && notification.notificationType === 'poll_ended') {
        this.dispatch('feed', 'closePostPollOnNotification', notification.pollData.sharedPostId);
      }

      if (newUnseen > unseen) {
        state.set('unseenCount', newUnseen);
        setTitleCount('notifications', newUnseen);
        MiscUtils.updateDocumentTitle('notification', false, true);
        if (newUnseen > 0) state.set('unseenNotifsArrived', true);
      }

      if (notification && notification.notificationType === 'event_deleted') {
        // SG-13528
        this.dispatch('events', 'cleanDeletedEvent', notification.event.id);
      }

      if (notification && notification.notificationType === 'group_application_approved') {
        this.dispatch('group', 'groupNewHandler', notification.group.id);
      }

      if (notification && notification.notificationType === 'page_invitation') {
        // SG-24771
        if (window.location.pathname === '/pages') {
          fetchInvitations();
        }
      }
    }
  },

  wsDesktopNotificationAdd(data = {}) {
    if (data.notificationType === 'generic') {
      let onClick = () => {};

      if (data.notificationSubtype === 'dsnp_migration') {
        // don't show migration notification to dsnp users
        if (CurrentUserStore.getState().dsnpHandle) return;
        else onClick = () => (window.location.href = `/web3/migrate`);
      }

      this.dispatch('notification', 'notify', {
        type: 'generic',
        title: data.title,
        body: data.body,
        sticky: true,
        ignoreFocus: true,
        tag: `generic-${data.notificationSubtype}`,
        onClick: onClick,
      });
    }
  },

  removeGroupApplications(data) {
    if (!data.groupId || !data.userIds || !data.userIds.length) return;

    let applicationNotification = state.feed.find((n) => {
      return n.notificationType === 'group_application_request' && n.group && n.group.id === data.groupId;
    });

    if (applicationNotification) {
      each(data.userIds, (userId) => {
        const user = applicationNotification.actingUsers.find((u) => u.id === userId);
        if (user) {
          A(applicationNotification.actingUsers).removeObject(user);
          applicationNotification.actingUsersCount -= 1;
        }
      });

      if (applicationNotification.actingUsersCount === 0) {
        state.feed.removeObject(applicationNotification);
        if (state.unseenCount > 0) state.set('unseenCount', state.unseenCount - 1);
      }
    }
  },

  processNotificationEmojis(notification) {
    if (notification.commentData) {
      set(notification, 'commentData.emojis', emojisList(notification.commentData.emojiCounts));
    } else if (notification.postData) {
      set(notification, 'postData.emojis', emojisList(notification.postData.emojiCounts));
    } else if (notification.chatMessageData) {
      set(notification, 'chatMessageData.emojis', emojisList(notification.chatMessageData.emojiCounts));
    }

    set(notification, 'emojisHtml', this.getEmojisHTML(notification));
  },

  actions: {
    handleNotifications(feed = []) {
      state.feed.pushObjects(
        compact(
          feed.map((notification) => {
            // prevent any further errors caused by non existing array
            if (!notification.actingUsers) {
              notification.actingUsers = [];
            }

            if (notification.notificationType === 'group_public_directory_removed') {
              notification.actingUsers = [{ system: true }];
            }

            if (notification.notificationType === 'scheduled_posts_failed') {
              notification.system = 'MeWe';
              notification.actingUsers = [{ system: true }];
            }

            if (notification.notificationType === 'event_reminder') {
              //in this notification event acts as a user
              notification.actingUsers = [notification.event];
            }

            if (
              notification.notificationType === 'page_activity' ||
              (notification.notificationType === 'poll_ended' && notification.page)
            ) {
              //in this notification page acts as a user
              if (notification.page) {
                notification.actingUsers = [Object.assign({ isPage: true }, notification.page)];
              }
            }

            if (
              notification.notificationType === 'comment' &&
              notification.commentData &&
              notification.commentData.postedByPage
            ) {
              //in this notification page acts as a user
              // but also parentAuthor is needed because "PageName replied to UserName's comment" (SG-31017)
              if (notification.actingUsers?.length) {
                notification.actingUsers = notification.actingUsers.filter(
                  (u) => u.id === notification.commentData.parentAuthor
                );
              }

              if (notification.page) {
                notification.actingUsers.unshift(Object.assign({ isPage: true }, notification.page));
              }
            }

            if (
              notification.notificationType === 'storage_exceeded' ||
              notification.notificationType === 'cancelled_subscription_promo_code'
            ) {
              notification.actingUsers = [{ system: true }];
            }

            if (notification.notificationType === 'discover_feature_info') {
              notification.system = 'MeWe';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/hearts.png',
                },
              ];
            }

            if (
              notification.notificationType === 'discover_group_post_published_owner' ||
              notification.notificationType === 'discover_group_post_published_admin'
            ) {
              notification.system = 'group';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/hearts.png',
                },
              ];
            }

            if (
              notification.notificationType === 'discover_page_post_published_owner' ||
              notification.notificationType === 'discover_page_post_published_admin'
            ) {
              notification.system = 'page';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/hearts.png',
                },
              ];
            }

            if (notification.notificationType === 'group_admin_rights_granted') {
              notification.system = 'group';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/crown.png',
                },
              ];
            }

            if (notification.notificationType === 'contacts_limit_warning') {
              notification.system = 'MeWe';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/group.png',
                },
              ];
            }

            if (notification.notificationType === 'generic') {
              notification.system = 'MeWe';
              notification.actingUsers = [
                {
                  system: true,
                  customIcon: '/assets/icons/group.png',
                },
              ];
            }

            if (notification.notificationType === 'subscription_trial_ends') {
              notification.actingUsers = [
                {
                  system: true,
                },
              ];
            }

            if (!notification.seenRecent) {
              state.set('unseenIds.' + notification.id, 1);
            }

            if (notification.actingUsers?.length > 0) {
              return this.processNotification(notification); // corrupted notifications with broken data filtered out.
            }
          })
        )
      );
    },

    refreshStore(keepFeed) {
      if (keepFeed) {
        state.setProperties({
          loading: true,
        });
      } else {
        state.setProperties({
          loading: true,
          feed: A(),
          nextPageLink: null,
        });
      }

      NotificationsApi.fetch({
        maxResults: pageNotificationsCenter,
      })
        .then((data) => {
          state.setProperties({
            nextPageLink: data._links?.nextPage?.href,
            feed: A(),
          });

          this.send('handleNotifications', data.feed);
          this.send('fetchUnVisited', data.feed);
          this.send('handleUnseenCount', data);
          state.set('unseenNotifsArrived', false);
        })
        .finally(() => {
          state.set('loading', false);
        });
    },

    removeNotification: function (notificationId) {
      var notification = this.getById(notificationId);

      if (notification) {
        state.feed.removeObject(notification);
      }
    },

    loadMore() {
      if (!state.get('loading')) {
        NotificationsApi.fetch(
          {
            maxResults: pageNotificationsCenter,
          },
          state.nextPageLink
        )
          .then((data) => {
            state.set('nextPageLink', data._links?.nextPage?.href);
            this.send('handleNotifications', data.feed);
            this.send('fetchUnVisited', data.feed);
            this.send('handleUnseenCount', data);
          })
          .finally(() => {
            state.set('loading', false);
          });
      }
    },

    markSeen(refreshAfter, keepFeed) {
      state.set('markSeenLoading', true);

      NotificationsApi.markSeen()
        .then(() => {
          state.set('unseenCount', 0);
          state.set('unseenIds', {});
          setTitleCount('notifications', 0);
          MiscUtils.updateDocumentTitle('notification');
        })
        .finally(() => {
          state.set('markSeenLoading', false);
          if (refreshAfter) {
            this.send('refreshStore', keepFeed);
          }
        });
    },

    markAllVisited() {
      NotificationsApi.markVisited({ all: true }).then(() => {
        state.set('unVisitedCount', 0);
        each(
          state.feed.filter((f) => f.visited === false),
          (notification) => {
            set(notification, 'visited', true);
          }
        );
      });
    },

    handleUnseenCount(data) {
      const newCount = data.unseenCount || 0;
      state.set('unseenCount', newCount);
      setTitleCount('notifications', newCount);
      MiscUtils.updateDocumentTitle('notification');
    },

    fetchUnVisited(feed) {
      const unVisitedNotifications = feed.filter((f) => f.visited === false);
      const unVisitedCount = unVisitedNotifications ? unVisitedNotifications.length : 0;

      state.set('unVisitedCount', unVisitedCount);
    },

    storeDesktopNotif(notification) {
      state.set('desktopNotifRef', notification);
    },
  },

  // setting detailed text about post
  getPostText: function (notification) {
    var postText = false;

    if (notification.commentData) {
      postText = notification.commentData.snippet;
      if (!postText && notification.commentData.doc) postText = notification.commentData.doc.name;
    } else if (notification.chatMessageData) {
      postText = notification.chatMessageData.snippet;
    } else if (notification.postData) {
      switch (notification.postData.postType) {
        case 'event2':
          postText = notification.eventData && notification.eventData.name;
          break;
        case 'link':
          postText = notification.postData.link && notification.postData.link._links.url.href;
          break;
        case 'doc':
          postText = notification.docData.name;
          break;
        default:
          postText = notification.postData.snippet;
          break;
      }
    }

    // if text contains gif url only then don't return it, gif url will be displayed as img
    return hasGifUrlOnly(postText) ? '' : postText;
  },

  getEmojisHTML: function (notification) {
    var emojisHtml = '';

    let emojis = [];

    if (notification.chatMessageData && notification.chatMessageData.emojis) {
      emojis = notification.chatMessageData.emojis;
    } else if (notification.postData && notification.postData.emojis) {
      emojis = notification.postData.emojis;
    } else if (notification.commentData && notification.commentData.emojis) {
      emojis = notification.commentData.emojis;
    }

    each(emojis, (emoji) => {
      emojisHtml += `<li class="emojis">${emoji.html} ${emoji.counter}</li>`;
    });

    return `<ul class="notification_content_emojis h-list-reset">${emojisHtml}</ul>`;
  },

  processNotification: function (notification) {
    var self = this;

    if (notification.actingUsers && notification.actingUsers[0]) {
      notification.actingUser = notification.actingUsers[0];

      if (
        notification.notificationType === 'comment' &&
        notification.commentData &&
        notification.commentData.postedByPage
      ) {
        if (notification.page && notification.page._links && notification.page._links.profilePhoto) {
          notification.actingUser.avatar =
            EnvironmentUtils.getImgHost(true) +
            notification.page._links.profilePhoto.href.replace('{imageSize}', '150x150');
        } else {
          notification.actingUser.avatar = EnvironmentUtils.getCdnHost() + '/assets/predefined/cover-picture.jpg';
        }
      } else if (
        notification.notificationType === 'page_activity' ||
        (notification.notificationType === 'poll_ended' && notification.page)
      ) {
        if (notification.actingUser._links && notification.actingUser._links.profilePhoto) {
          // page - page_activity
          notification.actingUser.avatar =
            EnvironmentUtils.getImgHost(true) +
            notification.actingUser._links.profilePhoto.href.replace('{imageSize}', '150x150');
        } else {
          notification.actingUser.avatar = EnvironmentUtils.getCdnHost() + '/assets/predefined/cover-picture.jpg';
        }
      } else if (notification.actingUser._links && notification.actingUser._links.avatar) {
        notification.actingUser.avatar =
          EnvironmentUtils.getImgHost(true) +
          notification.actingUser._links.avatar.href.replace('{imageSize}', '150x150');
        notification.actingUser.isNsfw = notification.actingUser._links.avatar.nsfw;
      } else {
        notification.actingUser.avatar =
          EnvironmentUtils.getImgHost() +
          '/photo/profile/150x150/' +
          notification.actingUser.id +
          '.jpg?fp=' +
          notification.actingUser.fprint;
      }
    }

    if (notification.postData && notification.postData.postType === '') {
      notification.postData.postType = 'comment';
    }

    if (notification.notificationType === 'group_invitation') {
      notification.isGroupInvitation = true;
      notification.accept = self.accept;
      notification.decline = self.decline;
      notification.removeNotification = self.removeNotification;
    } else if (notification.notificationType === 'group_application_invitation') {
      notification.isSelectiveGroupInvitation = true;
      notification.redirectToJoinPage = this.redirectToJoinPage;
    } else if (notification.notificationType === 'event_invitation') {
      notification.isEventInvitation = true;
      notification.isOnlyAction = true;
    } else if (
      notification.notificationType === 'invitation_accepted' ||
      notification.notificationType === 'contact_auto_added'
    ) {
      notification.isInvitationAccepted = true;
      notification.openProfile = true;
    } else if (notification.notificationType.indexOf('limited_') !== -1 && notification.system === 'pending') {
      notification.isLimited = true;
      notification.approve = self.approve;
      notification.reject = self.reject;
      notification.removeNotification = self.removeNotification;
    } else if (notification.notificationType === 'contactSuggestion') {
      notification.isContactSuggestion = true;
      notification.openProfile = true;
    } else if (notification.notificationType === 'emojis') {
      if (Emoji.isHashMapReady()) {
        this.processNotificationEmojis(notification);
      } else {
        let callback = () => {
          this.processNotificationEmojis(notification);
          PS.Unsub('emoji.hashmap.ready', callback);
        };
        PS.Sub('emoji.hashmap.ready', callback);
      }

      notification.isEmojiNotification = true;
    } else if (notification.notificationType === 'added_to_group') {
      notification.showGroupLink = true;
      notification.isOnlyAction = true;
    } else if (notification.notificationType === 'group_ownership_transferred') {
      notification.ownershipTransferred = true;
      notification.showGroupLink = true;
      notification.isOnlyAction = true;
    } else if (notification.notificationType === 'generic') {
      notification.isOnlyAction = true;
    } else if (notification.notificationType === 'web3_dark_theme_granted') {
      notification.isOnlyAction = true;
    } else if (notification.notificationType === 'cancelled_subscription_promo_code') {
      notification.isOnlyAction = true;
    } else if (notification.notificationType === 'subscription_trial_ends') {
      notification.isOnlyAction = true;
    }

    if (notification.group && (notification.system === 'group' || notification.system === 'pending')) {
      notification.place = notification.group.name;
      notification.placeId = notification.group.id;
    } else if (notification.system === 'privacymail') {
      notification.place = __('Private Post');
      notification.placeId = 'privacymail';
    } else if (notification.notificationType === 'contact_birthday') {
      notification.place = __('Birthday');
      notification.placeId = 'contacts';
    } else if (notification.notificationType === 'secret_chat_request') {
      notification.isOnlyAction = true;
      notification.place = __('Chat');
      notification.placeId = 'contacts';
    } else if (notification.notificationType === 'event_invitation') {
      if (notification.group && notification.group.id) {
        notification.place = notification.group.name;
        notification.placeId = notification.group.id;
      } else {
        notification.place = __('Events');
        notification.placeId = 'events';
      }
    } else if (notification.event || notification.system === 'event') {
      notification.place = notification.event ? notification.event.name : __('Events');
      notification.isOnlyAction = notification.notificationType === 'event_deleted';
      notification.placeId = 'events';
    } else if (notification.page || notification.system === 'page') {
      notification.place = notification.page ? notification.page.name : __('Pages');
      notification.placeId = 'pages';
    } else if (notification.notificationType === 'mention' && notification.threadId) {
      // mention in a multiuser chat
      notification.place = __('Chat');
      notification.placeId = 'contacts';
    } else if (notification.chatMessageData) {
      notification.place = __('Chat');
      notification.placeId = 'chat';
    } else if (notification.system === 'MeWe') {
      notification.place = 'MeWe';
      notification.placeId = Theme.CONTACTS;
    } else if (
      notification.notificationType === 'new_follower' ||
      notification.notificationType === 'new_follow_request' ||
      notification.notificationType === 'follow_request_accepted' ||
      notification.notificationType === 'group_invitee_registered'
    ) {
      notification.openProfile = true;
    } else {
      notification.place = __('Timeline');
      notification.placeId = 'contacts';
    }

    // commentData with photo/gif in comment can occur for multiple types of notification (SG-29630)
    if (notification.commentData) {
      if (notification.commentData.photo) {
        notification.photoCommentUrl = notification.commentData.photo._links.img.href.replace('{imageSize}', '400x400');
      } else if (notification.commentData.snippet) {
        const foundGif = notification.commentData.snippet.match(gifUrlRegExp);

        if (foundGif && foundGif.length) {
          notification.photoCommentUrl = gifToSmallStill(foundGif[0]);
          notification.photoCommentUrlIsGif = true;
          if (hasGifUrlOnly(notification.commentData.snippet)) {
            notification.commentData.snippet = '';
          } else {
            notification.commentData.snippet = notification.commentData.snippet.replace(foundGif, '').trim();
          }
        }
      }
    }

    if (notification.postData && notification.postData.snippet) {
      const foundGif = notification.postData.snippet.match(gifUrlRegExp);

      if (foundGif && foundGif.length) {
        notification.postGifUrl = gifToSmallStill(foundGif[0]);
        if (hasGifUrlOnly(notification.postData.snippet)) {
          notification.postData.snippet = '';
        } else {
          notification.postData.snippet = notification.postData.snippet.replace(foundGif, '').trim();
        }
      }
    }

    if (notification.notificationType === 'group_public_directory_removed') {
      if (notification.group._links.groupAvatar) {
        notification.actingUser.avatar = __(notification.group._links.groupAvatar.href, {
          imageSize: '400x400',
        });
      }
    }

    if (
      notification.notificationType === 'event_updated' ||
      notification.notificationType === 'event_reminder' ||
      notification.notificationType === 'event_deleted'
    ) {
      if (notification.event._links && notification.event._links.imageLinkTemplate) {
        notification.actingUser.avatar = __(notification.event._links.imageLinkTemplate.href, {
          imageSize: '400x400',
        });
        notification.actingUser.isNsfw = false;
      }
    }

    if (notification.group && notification.actingUser?.avatar) {
      const avatar = notification.actingUser.avatar;

      if (avatar.indexOf('group=') === -1) {
        notification.actingUser.avatar += `&group=${notification.group.id}`;
      } else if (avatar.indexOf('group=&') !== -1) {
        notification.actingUser.avatar = notification.actingUser.avatar.replace(
          'group=',
          `group=${notification.group.id}`
        );
      }
    }

    notification.post = self.getPostText(notification);
    notification.notificationTextHTML = self.getNotificationText(notification);

    return notification;
  },

  currentUserIsActing: function (notification) {
    return CurrentUserStore.getState().get('id') === notification.actingUsers[0].id;
  },

  getLocalizedBirthDateString: function (notification) {
    return getLocalizedBirthDateString(
      new Date().getFullYear(),
      notification.birthDayData.month - 1,
      notification.birthDayData.day,
      CurrentUserStore.getState().get('locale')
    );
  },

  getNotificationText: function (notification, onlyText) {
    var self = this,
      text = '',
      postType;

    switch (notification.notificationType) {
      case 'group_invitation':
        if (!notification.group) {
          return text;
        }

        if (notification.actingUsers.length > 1) {
          text = __('{userNames} invited you to join group {groupName}', {
            userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            groupName: `<span class='color-app'> ${escape(notification.group.name)} </span>`,
          });
        } else {
          text = __('{userName} ({userHandle}) invited you to join group {groupName}', {
            userName: getActingUserHTML(notification, true),
            groupName: `<span class='color-app'> ${escape(notification.group.name)} </span>`,
            userHandle: getActingUserHandle(notification),
          });
        }
        break;

      case 'group_application_invitation':
        if (!notification.group) {
          return text;
        }

        if (notification.actingUsers.length > 1) {
          text = __('{userNames} invited you to apply to join group {groupName}', {
            userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            groupName: `<a href='/group/${notification.group.id}' class='color-app'> ${escape(
              notification.group.name
            )} </a>`,
          });
        } else {
          text = __('{userName} ({userHandle}) invited you to apply to join group {groupName}', {
            userName: getActingUserHTML(notification, true),
            groupName: `<a href='/group/${notification.group.id}' class='color-app'> ${escape(
              notification.group.name
            )} </a>`,
            userHandle: getActingUserHandle(notification),
          });
        }
        break;

      case 'group_ownership_transferred':
        if (!notification.group) {
          return text;
        }

        text = __('{userNames} has transferred ownership of {groupName} to you.', {
          userNames: getActingUserHTML(notification),
          groupName: `<a href='/group/${notification.group.id}' class='color-app'> ${escape(
            notification.group.name
          )} </a>`,
        });
        break;

      case 'page_activity':
        if (!notification.page) {
          return text;
        }

        text = __('You have {count} new notification in {pageName}', {
          count: notification.count,
          pageName: `<span class='color-app'> ${escape(notification.page.name)} </span>`,
        });
        break;

      case 'event_invitation':
        if (notification.event) {
          text = __('{userName} invited you to the event {eventName}', {
            userName: getActingUserHTML(notification),
            eventName: `<a href='/event/${notification.event.id}' class='notification_text_user'> ${escape(
              notification.event.name
            )} </a>`,
          });
        }
        break;

      case 'event_deleted':
        text = __('{eventName} was cancelled', {
          eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
        });
        break;

      case 'event_reminder':
        let eventName = '<span class="notification_text_user">' + escape(notification.event.name) + '</span>';
        if (notification.eventReminderEta === 0) {
          text = __('The event "{eventName}" is starting now', {
            eventName: eventName,
          });
        } else if (notification.eventReminderEta < 60) {
          text = __('The event "{eventName}" is starting in {count} minute', {
            eventName: eventName,
            count: notification.eventReminderEta,
          });
        } else if (notification.eventReminderEta === 60) {
          text = __('The event "{eventName}" is starting in 1 hour', {
            eventName: eventName,
          });
        } else if (notification.eventReminderEta === 1440) {
          text = __('The event "{eventName}" is starting in 1 day', {
            eventName: eventName,
          });
        }

        break;

      case 'event_updated':
        text = __('{eventName} was updated', {
          eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
        });
        break;

      case 'event_attendance':
        if (notification.actingUsers.length > 2) {
          let userCount = notification.actingUsersCount - 1;
          text = __('{userName} and {count} others are going to your event {eventName}', {
            userName: getActingUserHTML(notification),
            count: userCount,
            eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
          });
        } else if (notification.actingUsers.length === 2) {
          text = __('{userNames} are going to your event {eventName}', {
            userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
          });
        } else {
          text = __('{userName} is going to your event {eventName}', {
            userName: getActingUserHTML(notification),
            eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
          });
        }
        break;

      case 'group_public_directory_removed':
        text = __(
          'The group {groupName} has been removed from our directory due to lack of activity. Post something in the group to add it back.',
          {
            groupName: '<span class="notification_text_user">' + escape(notification.group.name) + '</span>',
          }
        );
        break;

      case 'invitation_accepted':
        text = __('{userName} has accepted your contact request', {
          userName: getActingUserHTML(notification),
        });
        break;

      case 'secret_chat_request':
        text = __(
          '{userName} would like to send you a secret encrypted chat message. Currently secret chat is only supported on the mobile apps. Go download the MeWe app for free to start a secret chat with {userName}.',
          {
            userName: getActingUserHTML(notification),
          }
        );
        break;

      case 'contact_birthday':
        if (notification.birthDayData) {
          text = __("{userName}'s birthday is on {localizedDate}! Send a birthday message.", {
            userName: getActingUserHTML(notification),
            localizedDate: self.getLocalizedBirthDateString(notification),
          });
        } else {
          text = __("{userName}'s birthday is today! Send a birthday message.", {
            userName: getActingUserHTML(notification),
          });
        }
        break;

      case 'group_application_approved':
        text = __('{userName} has approved your application to join this group.', {
          userName: getActingUserHTML(notification),
        });
        break;

      case 'scheduled_posts_failed':
        text = __('Sorry, your scheduled posts could not be published', {
          userName: getActingUserHTML(notification),
        });
        break;

      case 'contactSuggestion':
        text = __('{userName} is now on MeWe.', {
          userName: getActingUserHTML(notification),
        });
        break;

      case 'contact_auto_added':
        text = __('{userName} is now your contact', {
          userName: getActingUserHTML(notification),
        });
        break;

      case 'group_application_request':
        if (notification.actingUsers.length > 2) {
          let userCount = notification.actingUsersCount - 1;
          text = __('{userName} and {count} others have applied to join your group {groupName}', {
            userName: getActingUserHTML(notification),
            count: userCount,
            groupName: escape(notification.group.name),
          });
        } else if (notification.actingUsers.length === 2) {
          text = __('{userNames} have applied to join your group {groupName}', {
            userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            groupName: escape(notification.group.name),
          });
        } else {
          text = __('{userName} has applied to join your group {groupName}', {
            userName: getActingUserHTML(notification),
            groupName: escape(notification.group.name),
          });
        }
        break;

      case 'page_invitation':
        if (notification.page) {
          text = __('{userName} invited you to follow the page {pageName}', {
            userName: getActingUserHTML(notification),
            pageName: `<span class='notification_text_user'> ${escape(notification.page.name)} </span>`,
          });
        }
        break;

      case 'limited_comment':
        if (notification.system === 'pending') {
          if (notification.actingUsers.length > 1) {
            text = __('{userNames} added comments that require approval: ', {
              userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            });
          } else {
            text = __('{userName} added a comment that requires approval: ', {
              userName: getActingUserHTML(notification),
            });
          }
        } else {
          // !! TODO -
          if (self.currentUserIsActing(notification)) {
            text = __('You approved a comment by {authorName}: ', {
              authorName: escape(notification.commentData.author.name),
            });
          } else {
            text = __('{userName} approved a comment by {authorName}: ', {
              userName: getActingUserHTML(notification),
              authorName: escape(notification.commentData.author.name),
            });
          }
        }
        break;
      case 'comment':
        if (notification.commentData.replyTo) {
          text = getCommentReplyText(CurrentUserStore.getState().get('id'), notification);
        } else {
          text = getCommentText(CurrentUserStore.getState().get('id'), notification);
        }
        break;

      case 'limited_post_edit':
        if (notification.system === 'pending') {
          text = __("{userName}'s edit requires approval: ", {
            userName: getActingUserHTML(notification),
          });
        } else {
          if (self.currentUserIsActing(notification)) {
            text = __('You approved an edit by {authorName}: ', {
              authorName: escape(notification.postData.author.name),
            });
          } else {
            text = __('{userName} approved an edit by {authorName}: ', {
              userName: getActingUserHTML(notification),
              authorName: escape(notification.postData.author.name),
            });
          }
        }
        break;
      case 'limited_post':
        if (notification.system === 'pending') {
          if (notification.event) {
            text = __('{userName} added a post in event {eventName} that requires approval: ', {
              userName: getActingUserHTML(notification),
              eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
            });
          } else {
            text = __('{userName} added a post that requires approval: ', {
              userName: getActingUserHTML(notification),
            });
          }
        } else {
          if (self.currentUserIsActing(notification)) {
            text = __('You approved a post by {authorName}: ', {
              authorName: escape(notification.postData.author.name),
            });
          } else {
            text = __('{userName} approved a post by {authorName}: ', {
              userName: getActingUserHTML(notification),
              authorName: escape(notification.postData.author.name),
            });
          }
        }
        break;
      case 'emojis':
        if (notification.commentData) {
          if (notification.commentData.replyTo) {
            text = __('{userName} emojied your reply: ', {
              userName: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            });
          } else {
            text = __('{userName} emojied your comment: ', {
              userName: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
            });
          }

          if (notification.commentData.photo) {
            notification.imageData = notification.commentData.photo;
          }

          if (notification.commentData.snippet) {
            if (hasGifUrlOnly(notification.commentData.snippet)) {
              notification.postGifUrl = gifToSmallStill(notification.commentData.snippet);
              delete notification.snippet;
            }
          }
        } else if (notification.postData) {
          text = __('{userName} emojied your post: ', {
            userName: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
          });

          if (!notification.postData || !notification.postData.postType) {
            return text;
          } else {
            postType = notification.postData.postType;
          }

          const params = {
            userName: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
          };

          switch (postType) {
            case 'photo':
              text = __('{userName} emojied your photo: ', params);
              break;
            case 'link':
              if (notification.postGifUrl) {
                text = __('{userName} emojied your GIF: ', params);
              } else {
                text = __('{userName} emojied your link: ', params);
              }
              break;
            case 'doc':
              text = __('{userName} emojied your document: ', params);
              break;
            case 'video':
              text = __('{userName} emojied your video: ', params);
              break;
            case 'event2':
            case 'recurring_event':
              text = __('{userName} emojied your event {eventName}', {
                userName: params.userName,
                eventName: notification.event
                  ? '<span class="notification_text_user">' + escape(notification.event.name) + '</span>'
                  : '',
              });
              break;
            case 'photos':
            case 'multi_photo':
            case 'album':
              text = __('{userName} emojied your photos: ', params);
              break;
            case 'multi_doc':
              text = __('{userName} emojied your documents: ', params);
              break;
            default:
              text = __('{userName} emojied your post: ', params);
              break;
          }
        } else if (notification.chatMessageData) {
          text = __('{userName} emojied your chat message: ', {
            userName: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
          });

          if (notification.chatMessageData.photo) {
            notification.imageData = notification.chatMessageData.photo;
          }

          if (notification.chatMessageData.snippet) {
            if (hasGifUrlOnly(notification.chatMessageData.snippet)) {
              notification.postGifUrl = gifToSmallStill(notification.chatMessageData.snippet);
              delete notification.snippet;
            }
          }
        }
        break;

      case 'mention':
        if (notification.commentData) {
          postType = 'comment';
        } else if (notification.threadId) {
          switch (notification.system) {
            case 'group':
              postType = 'groupchat';
              break;
            case 'privacymail':
              break;
            default:
              postType = 'chat';
              break;
          }
        } else if (notification.postData) {
          postType = notification.postData.postType;
        }

        let isEveryone = notification.mentionType === 'everyone',
          mentionParams = {
            userName: getActingUserHTML(notification, onlyText),
          };

        switch (postType) {
          case 'comment':
            if (isEveryone) text = __('{userName} mentioned @everyone in a comment: ', mentionParams);
            else text = __('{userName} mentioned you in a comment: ', mentionParams);
            break;
          case 'groupchat':
            mentionParams.groupName = escape(notification.group.name);
            if (isEveryone) text = __('{userName} mentioned @everyone in {groupName} chat.', mentionParams);
            else text = __('{userName} mentioned you in {groupName} chat.', mentionParams);
            break;
          case 'chat':
            if (isEveryone) text = __('{userName} mentioned @everyone in a chat.', mentionParams);
            else text = __('{userName} mentioned you in a chat.', mentionParams);
            break;
          default:
            // post
            if (isEveryone) text = __('{userName} mentioned @everyone in a post: ', mentionParams);
            else text = __('{userName} mentioned you in a post: ', mentionParams);
            break;
        }

        break;
      case 'post':
        if (notification.event) {
          text = __('{userName} posted in {eventName}', {
            userName: getActingUserHTML(notification),
            eventName: '<span class="notification_text_user">' + escape(notification.event.name) + '</span>',
          });
        }

        break;
      case 'poll_vote':
        text = __('{userNames} responded to your poll: {pollQuestion}', {
          userNames: getActingUsersHTML(CurrentUserStore.getState().get('id'), notification),
          pollQuestion: EmojisParser.toDisplay(escape(notification.pollData.question)),
        });
        break;
      case 'poll_ended':
        if (notification.page) {
          text = __("{userName}'s poll has ended. Check out the results", {
            userName: notification.page.name,
          });
        } else {
          if (
            notification?.actingUsers.length &&
            notification.actingUsers[0].id === CurrentUserStore.getState().get('id')
          ) {
            text = __('Your poll has ended. Check out the results');
          } else {
            text = __("{userName}'s poll has ended. Check out the results", {
              userName: getActingUserHTML(notification),
            });
          }
        }
        break;

      case 'storage_exceeded':
        let daysLeft = 30 + daysDiff(notification.occuredAt); // 30 days - time already passed
        if (daysLeft < 0) daysLeft = 0;

        text = __(
          "You are currently using more storage than is included in your plan. If you don't renew your subscription within {count} day, any data you have stored on MeWe in excess of your current plan (starting with the oldest content first) will be removed.",
          {
            count: daysLeft,
          }
        );
        break;

      case 'discover_feature_info':
        text = __(
          `You now have the option to allow some of your group’s most interesting posts to be featured in MeWe Discover - a way to help other members find high quality groups. Visit your group’s Settings page, “How members join” to learn more and allow Group Discovery.`
        );
        break;

      case 'discover_group_post_published_owner':
      case 'discover_page_post_published_owner':
        text = __(
          `Congratulations! Your post in <b>{originName}</b> was chosen to be shared with other MeWe members to help them discover the great communities at MeWe. Thanks for sharing interesting, high-quality content.`,
          {
            originName: notification.group?.name || notification.page?.name,
          }
        );
        break;

      case 'group_admin_rights_granted':
        text = __(
          `Congratulations! You’ve been made an Admin in the group <b>{originName}</b>. Now you can help manage and moderate the group. Thanks for helping to keep the community thriving!`,
          {
            originName: notification.group?.name,
          }
        );
        break;

      case 'discover_group_post_published_admin':
      case 'discover_page_post_published_admin':
        text = __(
          `Congratulations! A post in <b>{originName}</b> was chosen to be shared with other MeWe members to help them discover great communities like yours at MeWe.`,
          {
            originName: notification.group?.name || notification.page?.name,
          }
        );
        break;

      case 'contacts_limit_warning':
        text = __(
          `You have reached the limit of 8,000 follows. To follow a new profile you must either make your profile public or remove an existing follow..`
        );
        break;

      case 'new_follower':
        text = __(`{userName} ({userHandle}) started following you`, {
          userName: getActingUserHTML(notification, true),
          userHandle: getActingUserHandle(notification),
        });
        break;

      case 'new_follow_request':
        text = __(`{userName} ({userHandle}) wants to follow you`, {
          userName: getActingUserHTML(notification, true),
          userHandle: getActingUserHandle(notification),
        });
        break;

      case 'follow_request_accepted':
        text = __(`{userName} ({userHandle}) has accepted your follow request`, {
          userName: getActingUserHTML(notification, true),
          userHandle: getActingUserHandle(notification),
        });
        break;

      case 'group_invitee_registered':
        text = __(`{userName} ({userHandle}) is on MeWe!`, {
          userName: getActingUserHTML(notification, true),
          userHandle: getActingUserHandle(notification),
        });
        break;

      default:
        break;
    }

    return text;
  },
});

export default self.create();
