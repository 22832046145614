var TIMEOUT = 4000;
var lastTime = new Date().getTime();
var _timeout;

function onlineChecker() {
  if (navigator && navigator.onLine) {
    var currentTime = new Date().getTime();
    if (currentTime > lastTime + 4 * 60 * 1000) {
      // 4 minutes
      self.postMessage('wakeup');
    }
    lastTime = currentTime;
  }

  clearTimeout(_timeout);
  _timeout = setTimeout(onlineChecker, TIMEOUT);
}

onlineChecker();
