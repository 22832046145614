import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class AppLayout extends Component {
  @service account;

  @tracked showSidebarLeft;
  @tracked showSidebarRight;
  @tracked showSidebarSettings;
  @tracked showHeaderInfoBar;
  @tracked showHeaderBottomPanel;

  @action
  toggleMirrorLayout() {
    document.querySelector('html').setAttribute('dir', this.mirrorLayout ? 'rtl' : '');
  }

  @action
  toggleHeaderInfoBar() {
    set(this, 'account.activeUser.notifications.canShowInfo', this.showHeaderInfoBar);
  }

  @action
  toggleHeaderBottomPanel() {
    if (this.showHeaderBottomPanel) {
      document.querySelector('.mewe-app-wrapper').classList.add('has-header-bottom-panel');
    } else {
      document.querySelector('.mewe-app-wrapper').classList.remove('has-header-bottom-panel');
    }
  }
}