import { computed } from '@ember/object';
import Service from '@ember/service';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import Verbose from 'mewe/utils/verbose';
import AccountApi from 'mewe/api/account-api';
import Storage from 'mewe/shared/storage';
import FunctionalUtils from 'mewe/shared/functional-utils';
import Session from 'mewe/shared/session';
import { setTheme } from 'mewe/utils/miscellaneous-utils';
import { isInJailOrLocked } from 'mewe/utils/jail-utils';
import { inject as service } from '@ember/service';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

const verbose = Verbose({ prefix: '[Settings Service]', color: 'lightblue', enabled: false }).log;

let promise = null;

const settings = [
  'activeTheme',
  'notificationsHideInfo',
  'notificationsIsChatOn',
  'notificationsIsMentionsOn',
  'homeFeedScope',
];

const fetchSettings = () => {
  promise = new Promise((resolve) => {
    const fromStorage = Storage.get(Storage.keys.desktopSettings);

    if (fromStorage) return resolve(fromStorage);

    if (isInJailOrLocked()) return resolve({});

    Session.isAuthenticated().then(({ isAuthenticated, isConfirmed }) => {
      // dont make request if user is not logged in to prevent 401 resp
      // and redirection to login page, needed for public pages
      if (isAuthenticated && isConfirmed) {
        AccountApi.getCustomData('desktopSettings')
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            resolve({}); // api will return error when key is not set
          });
      } else {
        return resolve({});
      }
    });
  });

  return promise;
};

const store = (data, options = {}) => {
  Storage.set(Storage.keys.desktopSettings, data);
  AccountApi.setCustomData('desktopSettings', data)
    .then(() => {
      if (!options.skipSuccessMessage) {
        FunctionalUtils.info(__('Changes in your account have been saved'));
      }
    })
    .catch(() => {
      FunctionalUtils.showDefaultErrorMessage();
    });
};

export default Service.extend({
  analytics: service(),

  init: function () {
    this._super(...arguments);

    fetchSettings().then((response) => {
      verbose('restored desktop settings');

      if (response) {
        try {
          this.setProperties(JSON.parse(response));
          Storage.set(Storage.keys.desktopSettings, response);
        } catch (e) {}
      }
    });
  },

  promise() {
    return promise;
  },

  loaded: computed(function () {
    return ObjectPromiseProxy.create({ promise });
  }),

  save(key, value, options) {
    this.set(key, value);

    store(JSON.stringify(this.getProperties(settings)), options);
  },

  saveTheme(theme = '') {
    if (!theme) {
      theme = this.activeTheme === 'dark' ? 'mewe' : 'dark';
    }

    this.save('activeTheme', theme, { skipSuccessMessage: true });
    setTheme(theme, true);

    this.analytics.sendEvent('isUsingDarkTheme', { isEnabled: this.isDarkThemeEnabled });
  },

  isDarkThemeEnabled: computed('activeTheme', function() {
    return this.activeTheme === 'dark';
  }),

  notificationsHideInfo: false,

  notificationsIsChatOn: true,

  notificationsIsMentionsOn: true,
});
