import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { next } from '@ember/runloop';
import { reads } from '@ember/object/computed';
import { trim } from 'lodash';

import { phoneNumberRegex, extractPhoneNumberCharacters, emailRegex } from 'mewe/shared/utils';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import CurrentUserStore from 'mewe/stores/current-user-store';
import FunctionalUtils from 'mewe/shared/functional-utils';
import AccountApi from 'mewe/api/account-api';
import config from 'mewe/config';
import dispatcher from 'mewe/dispatcher';

class Errors {
  @tracked firstName = false;
  @tracked lastName = false;
  @tracked password = false;
  @tracked ageType = false;
  @tracked tooYoung = false;
  @tracked missingAge = false;
  @tracked invalidEmail = false;
  @tracked invalidPhone = false;
  @tracked emailTaken = false;
  @tracked emailOrPhoneRepeat = false;
  @tracked validationCode = false;
  @tracked validationLimitReached = false;
}

export default class VerifyRoute extends Component {
  // login key is currently being reused but just in case we have own key at some point
  arkoseKey = config.arkoseKeyLock || config.arkoseKeyLog;
  hcaptchaSiteKey = config.hcaptcha.lock;
  hcaptchaElemClass = 'h-captcha_lock';
  challengeOrigin = 'lock';

  showLogout = config.environment !== 'prod' && !config.isQA;

  @reads('args.model') model;

  @tracked challengeReady;
  @tracked emailWasSent;
  @tracked afterChallengeCallback;
  @tracked phoneRequestInProgress;
  @tracked _isVerified;
  @tracked errors = new Errors();
  @tracked tmpPhoneId;

  constructor() {
    super(...arguments);

    CurrentUserStore.getState().deferred.promise.then(() => {
      this.goToMyWorldIfNotLocked();
    });

    this.challengeReadyCallback = () => {
      this.challengeReady = true;
    };
  }

  goToMyWorldIfNotLocked() {
    if (!this.model?.lockInfo && (this.hasPhone || this.hasEmail)) {
      window.location.href = '/myworld';
    }
  }

  @computed('model.lockInfo.isVerified', '_isVerified')
  get isVerified() {
    return this._isVerified ?? this.model?.lockInfo?.isVerified;
  }

  @computed('model.primaryPhoneNumber')
  get hasPhone() {
    return this.model?.primaryPhoneNumber;
  }

  @computed('model.primaryEmail')
  get hasEmail() {
    return this.model?.primaryEmail;
  }

  @computed('hasPhone', 'hasEmail')
  get hasPhoneAndEmail() {
    return this.hasPhone && this.hasEmail;
  }

  @computed('hasPhone', 'emailValue', 'errors.{invalidEmail,emailTaken}')
  get sendEmailDisabled() {
    return (this.emailValue || '').indexOf('@') === -1 || this.errors.invalidEmail || this.errors.emailTaken;
  }

  @computed('phoneRequestInProgress', 'hasPhone', 'phoneValue')
  get sendPhoneDisabled() {
    return this.phoneRequestInProgress || (!this.hasPhone && !this.phoneValue);
  }

  validatePhoneRegex() {
    const val = this.phoneValue;

    if (!val) return true; // so error is cleared

    return phoneNumberRegex.test(extractPhoneNumberCharacters(val));
  }

  verifyLockedAccount(challengeToken, provider, onChallengeFail) {
    AccountApi.verifyLockedAccount(challengeToken, provider)
      .then(() => {
        if (this.isDestroyed || this.isDestroying) return;

        if (this.model?.lockInfo) {
          this._isVerified = true;
        } else {
          window.location.href = '/myworld';
        }
      })
      .catch((data) => {
        if (this.isDestroyed || this.isDestroying) return;

        if (data && data.status) {
          if (data.status === 400 && data.data.errorCode == 117) {
            onChallengeFail();
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        }
      });
  }

  sendEmail() {
    const emailsToSave = {
      primary: null,
      toAdd: [this.emailValue],
      toRemove: [],
    };

    AccountApi.setEmailToVerify(emailsToSave)
      .then(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.emailWasSent = true;
      })
      .catch((data) => {
        if (this.isDestroyed || this.isDestroying) return;

        if (data && data.taken) {
          this.errors.emailTaken = emailsToSave.primary;
        } else {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        next(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.savingEmails = false;
        });
      });
  }

  validateEmail(callback) {
    const emailOrPhone = this.emailValue.trim().toLowerCase();

    if (emailOrPhone && emailOrPhone.length) {
      let isValid = emailRegex.test(emailOrPhone);

      this.errors.invalidEmail = !isValid;

      if (isValid) {
        this.checkEmailRequestInProgress = true;

        PublicPagesApi.checkEmailAvailability(emailOrPhone)
          .then((data) => {
            if (this.isDestroying || this.isDestroyed) return;

            if (data && data.errorCode) {
              if (data.errorCode === 100) {
                this.errors.invalidPhone = false;
                this.errors.emailTaken = false;
                this.errors.invalidEmail = true;
              } else {
                this.errors.invalidPhone = false;
                this.errors.emailTaken = data.errorCode === 109;
              }
            } else {
              this.errors.invalidPhone = false;
              this.errors.emailTaken = false;

              callback?.();
            }
          })
          .finally(() => {
            this.checkEmailRequestInProgress = false;
          });
      }
    }
  }

  validatePhone(challengeToken, provider, onChallengeFailed) {
    let num = this.phoneValue;
    num = trim(num);
    num = extractPhoneNumberCharacters(num);

    const countryCode = this.getUsCountryCode(num),
      params = {
        phone: num,
        countryCode: countryCode,
      };

    if (challengeToken) params.session_token = challengeToken;
    if (provider) params.challenge_provider = provider;

    this.errors.validationCode = false;
    this.phoneRequestInProgress = true;

    return PublicPagesApi.validatePhone(params)
      .then((data) => {
        // for automated tests, on production there is no code
        if (data.code) {
          window.setTimeout(() => {
            const phoneEl = document.getElementById('reg-phone-v-code');
            if (phoneEl) {
              phoneEl.value = data.code;
              this.userTypedValidationCode = data.code;
            }
          }, 500);
        }

        if (data.phoneNumber) {
          this.tmpPhoneId = data.tmpPhoneId;
        } else if (data.limitReached) {
          this.errors.validationLimitReached = true;
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          if (error.data?.errorCode === 117) {
            onChallengeFailed();
          }
        }

        this.errors.invalidPhone = true;
      })
      .finally(() => {
        const codeElem = document.getElementById('reg-phone-v-code');
        if (codeElem) codeElem.focus();
        this.phoneRequestInProgress = false;
      });
  }

  // watch out, temporary hack if 10 digits +1, make US number TODO: select country codes from list
  getUsCountryCode(phoneNumber) {
    const digits10 = /^\d{10}$/i;

    if (digits10.test(phoneNumber)) {
      if (phoneNumber.charAt(0) !== '0') {
        return '+1';
      }
    }

    return '';
  }

  // unverfied account - submit newly passed phone number
  @action
  startVerification() {
    this.afterChallengeCallback = this.verifyLockedAccount.bind(this);
  }

  @action
  clearEmailErros() {
    this.errors.invalidEmail = false;
    this.errors.emailTaken = false;
  }

  @action
  submitEmail() {
    this.errors.invalidEmail = false;
    this.errors.invalidPhone = false;
    this.errors.emailTaken = false;

    this.validateEmail(this.sendEmail.bind(this));
  }

  // verified account - submit phone number
  @action
  submitPhone() {
    if (!this.phoneRequestInProgress && this.phoneValue) {
      this.afterChallengeCallback = this.validatePhone.bind(this);
    }
    this.errors.invalidPhoneRegexp = !this.validatePhoneRegex();
  }

  @action
  resendSMSCode() {
    if (!this.phoneRequestInProgress) {
      this.afterChallengeCallback = this.validatePhone.bind(this);
    }
  }

  // submit current phone number
  @action
  validateCurrentPhone() {
    this.phoneValue = this.model?.primaryPhoneNumber;
    this.afterChallengeCallback = this.validatePhone.bind(this);
  }

  @action
  checkSMSCode() {
    if (!this.tmpPhoneId || !this.userTypedValidationCode) {
      return;
    }

    AccountApi.reconfirmPhone({
      tmpPhoneId: this.tmpPhoneId,
      code: this.userTypedValidationCode,
    })
      .then(() => {
        window.location.href = '/myworld';
      })
      .catch((data) => {
        if (this.isDestroyed || this.isDestroying) return;

        if (!data) return;

        if (data.data && data.data.message === 'wrong validation code') {
          this.errors.validationCode = true;
        }
        if (data.limitReached) {
          this.errors.validationLimitReached = true;
        }
      });
  }

  @action
  logout() {
    dispatcher.dispatch('app', 'logout');
  }
}
