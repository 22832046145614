import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import EmojiPicker from 'mewe/pods/components/pickers/mw-emoji-picker';
import PS from 'mewe/utils/pubsub';
import { isDefined } from 'mewe/utils/miscellaneous-utils';

export default class MwEmojiButton extends Component {
  element;

  @action
  onInsert(element) {
    this.element = element;
  }

  setEmoji(emoji) {
    // case for emoji preset in postbox
    if (isDefined(this.args.emojis) && this.args.emojis.length < 4 && !this.args.emojis.includes(emoji)) {
      this.args.addEmoji(emoji);
      return;
    }

    // in text-editor
    if (typeof this.args.onEmojiSelect === 'function') {
      this.args.onEmojiSelect(emoji);
      return;
    }

    this.args.setPicker(false);
  }

  @action
  mouseDown() {
    // needed for properly closing another popups
    PS.Pub('close.dropdowns');

    return false;
  }

  @action
  click() {
    if (EmojiPicker(getOwner(this)).isOpen()) {
      EmojiPicker().send('close');
    } else {
      if (typeof this.args.popupDidOpen === 'function') this.args.popupDidOpen();

      this.args.setPicker(true);

      EmojiPicker().send('reposition', this.element);

      EmojiPicker().send('setCallback', (emoji) => {
        this.setEmoji(emoji);
      });

      EmojiPicker().send('setAfterClose', () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.args.setPicker(false);
      });

      EmojiPicker().send('open', {
        beforeStoreRedirection: this.args.beforeStoreRedirection,
      });
    }
  }
}
