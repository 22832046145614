import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwGifUrls extends Component {
  @action
  removeSelectedGif(data, index) {
    if (Array.isArray(this.args.value)) {
      this.args.value.removeAt(index);
    } else {
      this.args.setSelectedGifs(this.args.value.replace(data, ''));
    }

    this.args.gifRemoved?.();
  }
}
