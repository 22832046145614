import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwChatCallDropdown extends Component {
  @action
  openChatCallDropdown() {
    this.args.toggleCallDropdown();
  }

  @action
  startCall(videoEnabled) {
    this.args.startCall?.(videoEnabled);
    this.args.toggleCallDropdown();
  }
}
