import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';

import dispatcher from 'mewe/dispatcher';

export default class AppEventFeed extends Component {
  element;

  @service router;
  @service dynamicDialogs;

  constructor() {
    super(...arguments);

    if (!this.args.model.preventScroll) {
      window.scrollTo(0, 0);
    }

    this.checkPendingFeed();
    addObserver(this, 'args.model.eventState.rootEvent.id', this.checkPendingFeed);
  }

  @action
  didInsert(element) {
    this.element = element;
  }

  @action
  willDestroy() {
    removeObserver(this, 'args.model.eventState.rootEvent.id', this.checkPendingFeed);
  }

  checkPendingFeed() {
    // check pendig feed after event is fetched to avoid possible race condition (SG-29925)
    const eventId = this.args.model.eventState.rootEvent?.id;
    if (eventId) dispatcher.dispatch('events', 'checkEventPendingFeed', eventId);
  }

  @action
  openInviteToEvent() {
    this.dynamicDialogs.openDialog('invitations-dialog', {
      theme: 'event',
      eventData: this.args.model.eventState.rootEvent,
      isGroupMembersPicker: !!this.args.model.eventState.rootEvent.groupId,
    });
  }

  @action
  openPostbox() {
    this.element.querySelector('.postbox-placeholder').click();
  }

  @action
  openPostBoxDialog() {
    const params = {
      theme: Theme.EVENT,
      target: Theme.EVENT,
      eventId: this.args.model.eventState.rootEvent.id,
      preselectedEvent: this.args.model.eventState.rootEvent,
    };

    openPostbox(params, this.dynamicDialogs);
  }

  @action
  openEventHashTagFeed(hash) {
    // passing route and eventId is needed here in case of click on hashtag in
    // "/feed-pending" route because we need to rediredt to "/feed" in that case
    this.router.transitionTo('app.event.feed', this.args.model.eventState.rootEvent.id, { queryParams: { tag: hash } });
  }
}
