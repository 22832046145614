import AccountApi from 'mewe/api/account-api';
import PS from 'mewe/utils/pubsub';
import Storage from 'mewe/shared/storage';

export const isFeatureHintValid = (featureName, groupId) => {
  return new Promise((resolve, reject) => {
    let hintCache = null;
    try {
      hintCache = JSON.parse(Storage.get(`hint-${featureName}-cache`));
    } catch (ign) {}

    // check if hint did not expire
    if (hintCache && hintCache.expires < new Date().getTime()) {
      hintCache = null;
      Storage.remove(`hint-${featureName}-cache`);
    }

    if (!hintCache) {
      AccountApi.hint(featureName, groupId)
        .then((data) => {
          if (hintCache) {
            resolve(hintCache);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const removeFeatureHint = (featureName) => {
  const currentDate = new Date().getTime();
  let hintCache = null;
  try {
    hintCache = JSON.parse(Storage.get(`hint-${featureName}-cache`));
  } catch (ign) {}

  PS.Pub(`hint-dismissed-${featureName}`);

  if (hintCache) {
    hintCache.timesViewed += 1;
    // we check only if lastViewedAt was more than one week ago so it does not need to be precise
    // so it is set to currentDate
    hintCache.lastViewedAt = currentDate;
    hintCache.usedAt = currentDate;
    hintCache.expires = currentDate + 1000 * 60 * 60;
    Storage.set(`hint-${featureName}-cache`, JSON.stringify(hintCache));
  } else {
    Storage.set(
      `hint-${featureName}-cache`,
      JSON.stringify({
        timesViewed: 1,
        usedAt: currentDate,
        lastViewedAt: currentDate,
        expires: currentDate + 1000 * 60 * 60,
      })
    );
  }

  AccountApi.checkHint({ hint: featureName }); //dismiss
};

export const useFeatureHint = (featureName) => {
  AccountApi.useHint({ hint: featureName });
};
