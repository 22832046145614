import { Post } from './post-model';
import Feed from './feed-model';
import { attr, hasMany } from 'mewe/utils/store-utils/model/attribute';
import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';

const AnalyticsPost = Post.extend({
  engagement: attr('object', {
    defaultValue: function () {
      return {
        emojis: 0,
        comments: 0,
        shares: 0,
        total: 0,
      };
    },
  }),
});

export const AnalyticsFeed = Feed.reopen({
  posts: hasMany(AnalyticsPost),
});

export const toAnalyticsPost = (post) => s.deserializeOne(AnalyticsPost, post);
