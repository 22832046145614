/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import GroupApi from 'mewe/api/group-api';
import { reads } from '@ember/object/computed';
import dispatcher from 'mewe/dispatcher';

export default class MwGroupBlockedUser extends Component {
  @reads('args.user')
  user;

  @reads('args.group')
  group;

  constructor() {
    super(...arguments);
    dispatcher.setCtx(this);
  }

  @action
  unblock() {
    GroupApi.unBlockUserFromAllGroup(this.user.id).then(() => {
      dispatcher.dispatch('group', 'fetchCounters', this.group.id);
      this.args.userUnblocked(this.user);
    });
  }
}
