import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce, bind } from '@ember/runloop';
import { trimAndLower } from 'mewe/utils/miscellaneous-utils';

export default class MwSelectMenu extends Component {
  @tracked selectMenuOpened;
  element;
  escapeText = true;
  _clickEventListener = null;

  @action
  onInsert(element) {
    this.element = element;
    addObserver(this, 'args.searchValue', this.searchItems);
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.searchValue', this.searchItems);
    this.destroySelectMenu();
  }

  searchItems() {
    if (this.args.searchValue && this.args.searchable && this.args.searchable.length) {
      this.args.setSearchedItems(
        this.args.searchable.filter((item) => {
          return ~trimAndLower(item.name).indexOf(trimAndLower(this.args.searchValue));
        })
      );
    } else {
      this.args.setSearchedItems(this.args.searchable);
    }
  }

  destroySelectMenu() {
    if (this.isDestroying || this.isDestroyed) return;

    if (this._clickEventListener) {
      document.body.removeEventListener('click', this._clickEventListener);
    }

    this.selectMenuInited = false;
  }

  initSelectMenu() {
    if (this.isDestroying || this.isDestroyed || this.selectMenuInited) return;

    this._clickEventListener = bind(this, this.clickEvent);
    document.body.addEventListener('click', this._clickEventListener);

    this.selectMenuInited = true;

    if (this.args.searchable) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroying || this.isDestroyed) return;

        this.element.querySelector('.select-menu_search').focus();
      });
    }
  }

  clickEvent(event) {
    if (!this.element) return;

    const searchEl = this.element.querySelector('.select-menu_search'),
      targetIsInput = event.target === searchEl || event.target.classList.contains('btn-close-search'),
      targetIsOutsideOfOpener = !event.target.closest(`.select-menu_opener`);

    // don't close when clicked on search input or opener element
    // (options list is outside, selecting by click will close properly)
    if (targetIsOutsideOfOpener && !targetIsInput) {
      // postpone closing so possible item selection can be handled first
      this.close();
    } else {
      this.open();

      if (searchEl) searchEl.focus();
    }
  }

  @computed('args.default', 'args.value')
  get isDefault() {
    return this.args.default && this.args.default() === this.args.value;
  }

  @action
  setSearchValue(event) {
    this.args.setSearchValue(event.target.value);
  }

  @action
  clearSearch() {
    this.args.setSearchValue('');
  }

  @action
  open() {
    if (!this.selectMenuOpened) {
      this.selectMenuOpened = true;
      this.initSelectMenu();
    }
  }

  @action
  close() {
    if (this.selectMenuOpened) {
      this.destroySelectMenu();
      this.selectMenuOpened = false;
    }
  }
}
