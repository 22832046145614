import { getOwner, setOwner } from '@ember/application';
import { assert } from '@ember/debug';
import EmberObject from '@ember/object';
import dispatcher from 'mewe/dispatcher';
import config from 'mewe/config/environment';
import { ActionHandler } from '@ember/-internals/runtime';

export default EmberObject.extend(ActionHandler, {
  init() {
    if (config.environment !== 'test') {
      assert('Missing ctx in action-controller', this.ctx);

      let owner = getOwner(this.ctx);

      assert('Missing owner in action-controller', owner);

      setOwner(this, owner);

      // TODO fix globals injection in controllers (MW)
      this.globals = owner.lookup('globals:main');
    }

    this._super(...arguments);
  },

  // TODO in some cases, globals are not set properly in init, this function is hack (MW)
  getGlobals() {
    return getOwner(this).lookup('globals:main');
  },

  dispatch: dispatcher.dispatch,
});
