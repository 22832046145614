import ApiUtil from './api';

class Api extends ApiUtil {
  getStripeApiKey() {
    return this.get('/store/stripe/api-key');
  }

  getCountries() {
    return this.get('/store/stripe/countries');
  }

  getStates() {
    const countryCode = 'us';
    return this.get(`/store/stripe/states?countryCode=${countryCode}`);
  }

  updateCard(params) {
    return this.post('/store/stripe/card', { data: JSON.stringify(params) });
  }

  cancelSubscription(params) {
    return this.post('/payments/subscription/cancel', {
      data: JSON.stringify(params),
    });
  }

  storeGetProducts() {
    return this.get('/store/desktop/products');
  }

  storeGetSavedCard() {
    return this.get('/store/stripe/card');
  }

  storeDoPurchase(params) {
    return this.post('/store/desktop/purchase', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
