import { action, computed } from '@ember/object';
import Component from '@glimmer/component';

import fetchEvents from 'mewe/fetchers/fetch-events';
import Scrolling from 'mewe/utils/scrolling-utils';
import { isEdge } from 'mewe/shared/utils';

export default class MwEventInvitations extends Component {

  @action
  onInsert(element) {
    this.element = element;
    this.scrolling = Scrolling();
    this.scrolling.bindScrollDownElement(this.element, () => fetchEvents('events', 'invited-events'), 500);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element);
  }

  @computed()
  get isEdge() {
    return isEdge();
  }
}