import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';

import Scrolling from 'mewe/utils/scrolling-utils';

export default class MwChatSearchResults extends Component {

  @action
  onInsert(element) {
    this.element = element;
    this.scrolling = Scrolling();

    this.bindScrolling();

    addObserver(this, 'args.searchResults.length', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.getScrollElement());
    removeObserver(this, 'args.searchResults.length', this.bindScrolling);
  }

  getScrollElement() {
    return this.element.querySelector('.search_list');
  }

  bindScrolling() {
    if (this.args.searchResults?.length && this.args.hasMoreSearchResults) {
      this.scrolling.bindScrollDownElement(this.getScrollElement(), () => this.args.loadMoreChatSearch());
    } else {
      this.scrolling.unbindScrollDown(this.getScrollElement());
    }
  }

}