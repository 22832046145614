import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { escape, isEmpty, isNaN, isNumber } from 'lodash';

import EventsApi from 'mewe/api/events-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import EventUtils from 'mewe/utils/event-utils';
import isUndefined from 'mewe/utils/isUndefined';
import dispatcher from 'mewe/dispatcher';

export default class MwEventSettingsGeneral extends Component {
  @service tasks;
  @service account;
  @service dynamicDialogs;

  @reads('args.eventData') eventData;

  @tracked dropdownMenuOpened;
  @tracked loaded;

  @tracked eventName;
  @tracked eventDescription;
  @tracked eventLocation;
  @tracked endDateTS;
  @tracked startDateTS;
  @tracked timezoneName;
  @tracked isAllDay;
  @tracked task;
  @tracked reminder;
  @tracked repeatObj;
  @tracked isRecurrenceChanged;

  photoDialogTitles = {
    choosePhoto: __('Choose Event Background Photo'),
    cropPhoto: __('Edit Event Background Photo'),
  };

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      next(() => {
        this.eventDataReady();
      });
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);

    this.selectPhotoBind = this.selectPhoto.bind(this);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
    super.willDestroy(...arguments);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      this.eventName = this.eventData.name;
      this.eventDescription = EmojisParser.toEdit(escape(this.eventData.description));
      this.eventLocation = this.eventData.location;
      this.endDateTS = this.eventData.endDate;
      this.startDateTS = this.eventData.startDate;
      this.timezoneName = this.eventData.timeZone;
      this.isAllDay = this.eventData.allDay;
      this.reminder = this.eventData.reminder;
      this.repeatObj = this.eventData.recurrence
        ? EventUtils.getRepeatObjFromRecurrence(this.eventData.recurrence)
        : null;
      this.task = this.tasks.getTask(`photo.cover.event.${this.eventData.id}`);
      this.loaded = true;
    }
  }

  @computed(
    'isSaving',
    'eventName',
    'isNameMissing',
    'eventDescription',
    'eventLocation',
    'startDateTS',
    'endDateTS',
    'isAllDay',
    'reminder',
    'repeatObj',
    'timezoneName'
  )
  get isSaveVisible() {
    return !isEmpty(this.checkParams()) && !this.isSaving && !this.isNameMissing;
  }

  @computed('eventData._links.imageLinkTemplate.href')
  get profileImage() {
    if (this.eventData?._links?.imageLinkTemplate) {
      return this.eventData._links.imageLinkTemplate.href;
    } else {
      return '/assets/images/group-photo-placeholder-full.png';
    }
  }

  @computed('eventName.length')
  get isNameMissing() {
    return this.eventName?.trim().length === 0;
  }

  checkParams() {
    let isNameChanged = this.eventName && this.eventName !== this.eventData.name;
    let isLocationChanged = this.eventLocation !== this.eventData.location;
    let isStartDateChanged = this.startDateTS !== this.eventData.startDate;
    let isEndDateChanged = this.endDateTS !== this.eventData.endDate;
    let isAllDayChanged = this.isAllDay !== this.eventData.allDay;
    let isReminderChanged = this.reminder !== this.eventData.reminder;
    let isTimezoneChanged = this.timezoneName !== this.eventData.timeZone;

    let descriptionToServer = toServer(this.eventDescription, {
      parseNativeMarkdown: true,
    });
    let isDescriptionChanged = descriptionToServer !== this.eventData.description;

    const params = {};

    if (isNameChanged) params.name = this.eventName;
    if (isDescriptionChanged) params.description = this.descriptionToServer;
    if (isLocationChanged) params.location = this.eventLocation;
    if (isStartDateChanged) params.startDate = this.eventStartTS;
    if (isEndDateChanged) params.endDate = this.eventEndTS;
    if (isAllDayChanged) params.allDay = this.isAllDay;
    if (isReminderChanged) params.reminder = this.reminder;
    if (isTimezoneChanged) params.timeZone = this.timezoneName;
    if (this.isRecurrenceChanged) {
      params.recurrence = !this.repeatObj ? null : EventUtils.getRecurrenceFromRepeatObj(this.repeatObj);
    }

    return params;
  }

  validateDate() {
    const start = this.startDateTS;
    const end = this.endDateTS;
    const minDate = 0; // 1.1.1970
    const maxDate = 4133890800000; // 31.12.2100

    if (
      isNaN(start) ||
      isNaN(end) ||
      !isNumber(start) ||
      !isNumber(end) ||
      start > end ||
      start < minDate ||
      end > maxDate
    ) {
      FunctionalUtils.error(__('Wrong date format or range'));
      return false;
    } else {
      return true;
    }
  }

  selectPhoto(blob, params) {
    dispatcher.dispatch('events', 'sendEventPhoto', this.eventData.id, blob, params, this.task);
  }

  @action
  updateDate(fieldName, value) {
    this[fieldName] = value;
  }

  @action
  updateRepeat(repeatObj) {
    this.repeatObj = repeatObj;
    this.isRecurrenceChanged = true;
  }

  @action
  updateReminder(reminder) {
    this.reminder = reminder;
  }

  @action
  saveSettings() {
    if (!this.validateDate()) return;

    const params = EventUtils.getEventObjectFromEventData(this.eventData);
    params.name = this.eventName;
    params.description = toServer(this.eventDescription, {
      parseNativeMarkdown: true,
    });
    params.location = toServer(escape(this.eventLocation), {
      parseNativeMarkdown: true,
    });
    params.endDate = this.endDateTS;
    params.startDate = this.startDateTS;
    params.allDay = this.isAllDay;
    params.timeZone = this.timezoneName;

    if (this.repeatObj) {
      params.recurrence = EventUtils.getRecurrenceFromRepeatObj(this.repeatObj);
    } else {
      delete params.recurrence;
    }

    if (isUndefined(this.reminder) || this.reminder == 'none') {
      params.reminder = null; // deletiion, removal of object field
    } else {
      params.reminder = parseInt(this.reminder, 10);
    }

    this.isSaving = true;

    const startDate = this.eventData.startDate;
    const endDate = this.eventData.endDate;

    EventsApi.setEventDetails(this.eventData.id, params)
      .then((data) => {
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.description', data.description, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.name', data.name, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.location', data.location, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.startDate', data.startDate, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.endDate', data.endDate, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.timeZone', data.timeZone, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.allDay', data.allDay, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.reminder', data.reminder, data.id);
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.recurrence', data.recurrence, data.id);
        dispatcher.dispatch(
          'events',
          'saveEventState',
          'rootEvent.nextOccurrenceDate',
          data.nextOccurrenceDate,
          data.id
        );

        this.reminder = data.reminder;

        if (data.recurrence) {
          this.repeatObj = EventUtils.getRepeatObjFromRecurrence(data.recurrence);
          this.isRecurrenceChanged = false;
        }

        if (data.name !== this.eventData.name) {
          dispatcher.dispatch('events', 'saveEventState', 'rootEvent.name', data.name, data.id);
        }

        this.eventDescription = EmojisParser.toEdit(escape(this.eventData.description));
        this.eventLocation = EmojisParser.toEdit(this.eventData.location); // this will be safe only used in input

        FunctionalUtils.info(__('Event Settings have been successfully saved.'));

        const now = Date.now();

        if ((endDate > now && this.endDateTS < now) || (endDate < now && this.endDateTS > now)) {
          //event was upcoming and now is in past, need to refresh lists
          //or event was past and now is upcoming, need to refresh lists
          dispatcher.dispatch('events', 'getCounters', 'events');
          dispatcher.dispatch('events', 'refreshUpcomingEvents', 'events');
        } else if (endDate > now && (endDate !== this.endDateTS || startDate !== this.startDateTS)) {
          // if event is in upcoming and user change date refresh list to show changes
          dispatcher.dispatch('events', 'refreshUpcomingEvents', 'events');
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  @action
  confirmDeleting() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Confirm Deletion'),
      message: __('Are you ABSOLUTELY sure you want to delete this event? <br><br> You will NOT be able to undo this.'),
      noEscaping: true,
      cancelButtonText: __('Cancel'),
      okButtonText: __(`Yes, I'm absolutely sure`),
      onConfirm: () => {
        dispatcher.dispatch('events', 'deleteEvent', this.eventData);
      },
    });
  }

  @action
  selectNewOwner() {
    dispatcher.dispatch('event-attendee', 'selectNewOwner', this.eventData.id);
  }

  @action
  choosePhotoDialog() {
    this.dropdownMenuOpened = !this.dropdownMenuOpened;
  }
}
