import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import Storage from 'mewe/shared/storage';
import PS from 'mewe/utils/pubsub';
import { Locales } from 'mewe/constants';

export default class MwLanguageSelector extends Component {
  @tracked languageDropdownOpened;

  constructor() {
    super(...arguments);
    this.setLocales();
  }

  setLocales() {
    let locales = [];
    for (let key in Locales) {
      locales.push({
        name: Locales[key],
        shortName: key.replace('-', '_'),
      });
    }
    this.Locales = locales;
  }

  @computed('args.locale')
  get currentLocale() {
    return A(this.Locales).find((i) => i.shortName === this.args.locale);
  }

  @action
  toggleLanguageDropdown() {
    this.languageDropdownOpened = !this.languageDropdownOpened;
  }

  @action
  changeLocale(locale) {
    if (this.args.locale === locale) return;

    Storage.set(Storage.keys.publiGroupsLocale, JSON.stringify(locale));

    this.languageDropdownOpened = false;
    this.args.setLocale(locale);

    // Publish this only after setting new value to locale
    PS.Pub('public.groups.locale', locale);
  }
}
