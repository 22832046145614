import { A } from '@ember/array';
import { each } from 'lodash';

import Member from 'mewe/stores/models/member-model';
import GroupApi from 'mewe/api/group-api';
import { maxResultsMembersList } from 'mewe/constants';
import MemberBase from '../app-group/member-base';
import { next } from '@ember/runloop';

//TODO: Need to refactor the code
export default class AppGroupMembersInvited extends MemberBase {
  disableBlockAction = true;

  deserializeMembers(list, isFromSearch) {
    if (isFromSearch) {
      return undefined;
    }

    let members = A();
    let data = A();

    each(list, (item) => {
      let user = Object.assign(item, item.user);
      user.isCurrentUser = false;
      user.isGroupMember = true;
      delete user.user;

      data.push(user);
    });

    // eslint-disable-next-line ember/require-tagless-components
    let MemberWithGroup = Member.extend({
      group: this.group,
    });
    this.serializer.deserializeMany(members, MemberWithGroup, data);

    return members;
  }

  loadMembers() {
    if (this.loading) {
      return;
    }

    const params = {
      offset: this.members.length || 0,
      maxResults: maxResultsMembersList,
      sort: this.args.model.membersSearchSort,
      // role: {admin|user} - new arguments added on BE, might be useful one day
    };

    next(() => {
      this.loading = true;
    });

    GroupApi.invited(this.group.id, params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.members.pushObjects(this.deserializeMembers(data.members));

        if (data.members.length >= maxResultsMembersList) {
          this.scrolling.bindScrollDown(() => this.loadMembers());
        } else {
          this.scrolling.unbindScrollDown();
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.loading = false;
      });
  }
}
