import Route from 'mewe/routes/route';
import PublicApi from 'mewe/api/public-pages-api-unauth';

export let UnsubscribeRouteBase = Route.extend({
  theme: 'unsubscribe',

  unsubId: false,

  model(params) {
    document.body.classList.add('error-page');

    this.unsubId = params.id;

    this.unsubscribe(this.unsubId);

    return {
      id: this.unsubId,
    };
  },

  unsubscribe(id) {
    PublicApi.unsubscribe[this.unsubFuncName](id)
      .then(() => {
        this.set('currentModel.serviceToUnsubscribeText', this.getUnsubText());
      })
      .catch((resp) => {
        if (resp && resp.status === 404) {
          this.set('currentModel.alreadyUnsubscribed', true);
        }
      });
  },
});

export default UnsubscribeRouteBase;
