import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';

import Scrolling from 'mewe/utils/scrolling-utils';
import NotificationsStore from 'mewe/stores/notifications-store';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';

export default class MwNotificationsWrapper extends Component {
  @service dynamicDialogs;

  @tracked groups = A();

  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    NotificationsStore.send('refreshStore', true);

    if (this.notifications.unseenCount > 0) {
      NotificationsStore.send('markSeen', false);
    }
  }

  get notifications() {
    return this.args.model.notifications;
  }

  get groups() {
    return this.args.model.groupState.groups;
  }

  @action
  onInsert(element) {
    this.element = element;

    addObserver(this, 'args.notificationsDropdownOpened', this.wrapperOpened);
    addObserver(this, 'notifications.feed.length', this.feedChanged);
  }

  willDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.notifications-wrapper_content'));
    removeObserver(this, 'args.notificationsDropdownOpened', this.wrapperOpened);
    removeObserver(this, 'notifications.feed.length', this.feedChanged);
  }

  get newNotifsArrived() {
    return (
      !get(this, 'notifications.markSeenLoading') &&
      get(this, 'notifications.unseenNotifsArrived') &&
      get(this, 'notifications.unseenCount') > 0
    );
  }

  get isEmpty() {
    return !get(this, 'notifications.feed.length') && !get(this, 'notifications.loading');
  }

  wrapperOpened() {
    if (this.args.notificationsDropdownOpened && this.notifications.unseenCount) {
      this.element.querySelector('.notifications-wrapper_content').scrollTop = 0;
    }

    if (this.args.notificationsDropdownOpened) {
      document.body.classList.add('nc-open');
    } else {
      document.body.classList.remove('nc-open');
    }

    this.bindScrolling();
  }

  feedChanged() {
    this.bindScrolling();
  }

  bindScrolling() {
    const elem = this.element.querySelector('.notifications-wrapper_content');

    if (!this.args.notificationsDropdownOpened || !this.notifications.feed.length || !this.notifications.nextPageLink) {
      this.scrolling.unbindScrollDown(elem);
    } else {
      this.scrolling.bindScrollDownElement(elem, () => NotificationsStore.send('loadMore'));
    }
  }

  @action
  refreshStore() {
    this.element.querySelector('.notifications-wrapper_content').scrollTop = 0;
    NotificationsStore.send('markSeen', true, true);
  }

  @action
  markAllVisited() {
    NotificationsStore.send('markAllVisited');
  }

  @action
  openPostboxDialog() {
    const params = {
      theme: Theme.CONTACTS,
      target: Theme.CONTACTS,
    };

    openPostbox(params, this.dynamicDialogs);
  }
}
