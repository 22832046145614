import Component from '@glimmer/component';

import { commentsMaxResults } from 'mewe/constants';
import CommentsApi from 'mewe/api/comments-api';
import Comment from 'mewe/stores/models/comment-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PostUtils from 'mewe/utils/post-utils';
import { Theme } from 'mewe/constants';
import isUndefined from 'mewe/utils/isUndefined';
import dispatcher from 'mewe/dispatcher';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { set } from '@ember/object';
import { run } from '@ember/runloop';

export default class MwCommentsTable extends Component {
  @alias('args.post') post;

  @computed('args.commentsOpened')
  get commentsOpened() {
    return isUndefined(this.args.commentsOpened) ? true : this.args.commentsOpened;
  }

  @action
  insertElement() {
    // has to be done once after insertElement event
    if (this.commentToHighlight) {
      // copy of this param is done to prevent propagation of its change up to the post component
      // this is small hack but otherwise post component closes comments table for unknown reason
      this.commentToHighlightId = this.commentToHighlight;
    }
  }

  fetchComments(loadType) {
    const { scope, scopeId } = PostUtils.getPostScopeAndId(this.post);
    let postComments = this.post.comments;
    let options, nextPageUrl;

    if (loadType === 'before') {
      // nextPageUrl in new pagination system, not available in 'comment/context' / smart search / group search - use beforeId then
      nextPageUrl = postComments.get('nextPage');

      if (nextPageUrl) {
        nextPageUrl = nextPageUrl.replace('{limit}', commentsMaxResults);
      } else {
        options = {
          maxResults: commentsMaxResults,
          beforeId: postComments.get('feed')[0].id || postComments.contextCommentId, // if fetched comment in context was deleted then its id is stored in contextCommentId
        };
      }
    } else if (postComments.get('feed.length') || postComments.contextCommentId) {
      options = {
        maxResults: commentsMaxResults,
        afterId: postComments.get('feed')[postComments.get('feed').length - 1]?.id || postComments.contextCommentId, // if fetched comment in context was deleted then its id is stored in contextCommentId
      };
    } else {
      return;
    }

    CommentsApi.getComments(this.post.postItemId, scope, scopeId, options, nextPageUrl)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        run(() => {
          if (data.feed.length) {
            const serializer = JSONSerializer.create();
            let comments = serializer.deserializeAll(Comment, data.feed);

            if (loadType === 'before') {
              postComments.get('feed').unshiftObjects(comments.reverse());
            } else {
              postComments.get('feed').pushObjects(comments);
            }
          }

          if (postComments.get('feed.length') === postComments.get('total')) {
            postComments.setProperties({
              canBeMoreAfter: false,
              canBeMoreBefore: false,
            });
          } else if (loadType === 'after') {
            postComments.set('canBeMoreAfter', data.feed.length === options.maxResults);
          } else if (data._links && data._links.nextPage && data._links.nextPage.href) {
            postComments.setProperties({
              canBeMoreBefore: true,
              nextPage: data._links.nextPage.href,
            });
          } else {
            postComments.set('canBeMoreBefore', false);
          }

          postComments.set(loadType === 'before' ? 'isLoadingMoreBefore' : 'isLoadingMoreAfter', false);
        });
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      });
  }

  showMore(loadType) {
    let postComments = this.post.comments;

    if (postComments.get('isLoadingMoreBefore') || postComments.get('isLoadingMoreAfter')) {
      return;
    }

    postComments.set(loadType === 'before' ? 'isLoadingMoreBefore' : 'isLoadingMoreAfter', true);

    // special behavior for comments from smart-search (posts behaves normally)
    if (
      this.args.isSmartSearch &&
      postComments.get('feed.length') > 0 &&
      postComments.get('feed.length') < postComments.get('total') &&
      !this.post.initialCommentsSet
    ) {
      const { scope, scopeId } = PostUtils.getPostScopeAndId(this.post);
      const groupId = scope === Theme.GROUP ? scopeId : null;
      const doWithPost = ({ comments }) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        postComments.set('feed', comments);
        this.fetchComments(loadType);

        set(this, 'post.initialCommentsSet', true);
      };

      dispatcher.dispatch('feed', 'fetchPostFromMemoryOrServer', {
        postId: this.post.postItemId,
        scope: scope,
        groupId: groupId,
        doWithPost: doWithPost,
      });
    } else {
      this.fetchComments(loadType);
    }
  }

  @action
  showNext() {
    // first show hidden new posts, then fetch more
    this.showNew();
    this.showMore('after');
  }

  @action
  showPrevious() {
    this.showMore('before');
  }

  @action
  showNew() {
    run(() => {
      this.post.comments.feed.forEach((c) => c.set('hideNew', false));
      set(this, 'post.comments.newCommentsCount', 0);
    });
  }
}
