import config from 'mewe/config';
import { each } from 'lodash';

export default function (onLoadFunctionName) {
  return new Promise((resolve, reject) => {
    const useFakeHcaptcha =
      window.location.host.indexOf('localhost:') === 0 ||
      (navigator.webdriver && (config.isQA || config.environment !== 'prod'));

    if (useFakeHcaptcha) {
      window.hcaptcha = {
        execute: () => {
          const captchas = document.getElementsByClassName('h-captcha');

          each(captchas, (c) => {
            const cb = c.getAttribute('data-callback');
            if (cb) window[cb]('FAKE_TOKEN_FOR_LOCAL_USE_ONLY');
          });
        },
        render: () => {},
        reset: () => {},
      };

      resolve(window[onLoadFunctionName]());
    } else {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = `https://hcaptcha.com/1/api.js?onload=${onLoadFunctionName}`;
      script.addEventListener('load', resolve);
      script.addEventListener('error', () => reject('Error loading script.'));
      script.addEventListener('abort', () => reject('Script loading aborted.'));
      document.head.appendChild(script);
    }
  });
}
