import { htmlSafe } from '@ember/template';
import { A } from '@ember/array';
import { map, flow, reduce } from 'lodash';
import api from 'mewe/api/pages-api';

import PageFollowersCountries from 'mewe/stores/models/page-followers-countries-model';
import PageFollowersIntervals from 'mewe/stores/models/page-followers-intervals-model';
import PageAnalyticsOverview from 'mewe/stores/models/page-analytics-overview-model';
import { storePosts, pushPosts } from 'mewe/stores/models/feed-model';
import { setNextPage, storeCollectionItems, pushCollectionItems } from 'mewe/stores/models/collection';
import { FeedTypes } from 'mewe/constants';
import { deserializeAndStoreAuthors } from 'mewe/fetchers/fetch-feed';
import { AnalyticsFeed, toAnalyticsPost } from 'mewe/stores/models/analytics-feed-model';
import { ds } from 'mewe/stores/ds';
import { intoHash, toPage, toSimpleUser, fetchUtil, unsetFlag, isMore } from 'mewe/fetchers/utils';
import { get, emberSet, tap, getOrCreate } from 'mewe/utils/fp';

export const getAnalyticsFeed = (id) =>
  getOrCreate(
    ds.feeds,
    AnalyticsFeed.extend({ id: `analytics-${id}`, type: FeedTypes.PAGE_ANALYTICS }),
    `analytics-${id}`
  );

export const getFollowersCountries = (pageId) =>
  getOrCreate(ds.pageFollowersCountries, PageFollowersCountries.extend({ id: pageId }), pageId);
export const getFollowersIntervals = (pageId, params = {}) =>
  getOrCreate(ds.pageFollowersIntervals, PageFollowersIntervals.extend({ id: pageId }), pageId + '-' + params.ref);
export const getAnalyticsOverview = (pageId, params = {}) =>
  getOrCreate(ds.pageAnalyticsOverviews, PageAnalyticsOverview.extend({ id: pageId }), pageId + '-' + params.interval);

const storePages = (pages) => pushCollectionItems(ds.pages, pages.map(toPage));

export const fetchAnalyticsFeed = (id, params = {}) =>
  fetchUtil(api.fetchAnalyticsFeed(id, params), getAnalyticsFeed(id), params)
    .then(tap(flow(get('users'), map(toSimpleUser), intoHash(ds.authors))))
    .then((data) => {
      deserializeAndStoreAuthors(data.users);
      if (data.pages) storePages(data.pages);

      // filter to only posts that are newer than ref
      if (params.ref) {
        data.feed = data.feed || [];

        const oldLength = data.feed.length;

        data.feed = data.feed.filter((f) => f.createdAt * 1000 > params.ref || f.featured);

        if (oldLength > data.feed.length) {
          if (data._links) delete data._links.nextPage;
        }
      }

      const feed = getAnalyticsFeed(id);

      setNextPage(feed)(data);
      feed.set('ref', params.ref);

      const fn = isMore(params) ? pushPosts : storePosts;
      fn(feed, A(data.feed.map(toAnalyticsPost)));
      return data;
    })
    .then(unsetFlag(getAnalyticsFeed(id)));

export const fetchMoreAnalyticsFeed = (id) => {
  const feed = getAnalyticsFeed(id);
  if (feed.nextPage) return fetchAnalyticsFeed(id, { nextPage: feed.nextPage, ref: feed.ref });
};

export const fetchFollowersCountries = (pageId) =>
  fetchUtil(api.followersCountries(pageId), ds.pageFollowersCountries.for(pageId))
    .then((data) => {
      const allCountriesSum = reduce(data.countries, (sum, country) => sum + country.count, 0);

      const countries = data.countries.map((country) => {
        country.pct = (country.count / allCountriesSum) * 100;
        country.pctStyle = htmlSafe('width: ' + country.pct + '%;');
        country.countryName = __('country:::' + country.country);

        return country;
      });

      storeCollectionItems(ds.pageFollowersCountries.for(pageId), countries);
      return data;
    })
    .then(unsetFlag(ds.pageFollowersCountries.for(pageId)));

export const fetchFollowersIntervals = (pageId, params = {}) =>
  fetchUtil(api.followersIntervals(pageId, params), ds.pageFollowersIntervals.for(pageId + '-' + params.ref))
    .then((data) => {
      return storeCollectionItems(ds.pageFollowersIntervals.for(pageId + '-' + params.ref), data.intervals);
    })
    .then(unsetFlag(ds.pageFollowersIntervals.for(pageId + '-' + params.ref)));

export const fetchAnalyticsOverview = (pageId, params = {}) =>
  fetchUtil(api.analyticsOverview(pageId, params), ds.pageAnalyticsOverviews.for(pageId + '-' + params.interval))
    .then((data) => {
      data.interval = params.interval;
      return emberSet(ds.pageAnalyticsOverviews.for(pageId + '-' + params.interval))(data);
    })
    .then(unsetFlag(ds.pageAnalyticsOverviews.for(pageId + '-' + params.interval)));
