import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { tracked } from '@glimmer/tracking';

export default class EmojiNamePopup extends PopupOpener {
  @tracked showPopup = true;

  layout = layout;

  popupType = 'dropdown-popup';

  closeOnClickOutside = true;

  closeOnScroll = true;

  closeOnHoverOutside = true;

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();

    Object.assign(position, {
      margin: 6,
      placement: {
        top: true,
        left: isRtl,
        positionType: 'fixed',
      },
      translate: {
        x: -5,
        altY: -10,
      },
    });

    return position;
  }
}
