/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import PostApi from 'mewe/api/post-api';

export default class MwPostSnippet extends Component {
  @service purchase;
  @service account;
  @service dynamicDialogs;
  @service dynamicPopups;
  @service router;

  @reads('args.feedTheme') feedTheme;
  @reads('args.scope') scope;
  @reads('args.post') post;
  @reads('args.post.isTranslated') isTranslated;

  @tracked truncate;
  @tracked postDropdownOpened = false;

  get showGroupHeader() {
    return this.post.isGroupPost || this.post.isEventPost;
  }

  get commentedOn() {
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');
    if (postedByPage) {
      return this.post.page;
    }
    return this.post.comments.feed.get('firstObject.owner');
  }

  get postedIn() {
    return this.post.page || this.post.owner;
  }

  @action
  openProfileCommentedOn() {
    let commentedProfile = this.post.comments.feed.get('firstObject.owner');
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');

    if (this.post.groupId) {
      return this.router.transitionTo('app.group.members.profile', this.post.groupId, commentedProfile.id);
    } else if (postedByPage) {
      return this.router.transitionTo('app.publicid', this.post.page.publicLinkId);
    } else {
      return this.router.transitionTo('app.publicid', commentedProfile.profileId);
    }
  }

  @action
  openProfilePostedIn() {
    let postedByPage = this.post.comments.feed.get('firstObject.postedByPage');
    if (this.post.owner.id && this.post.groupId) {
      return this.router.transitionTo('app.group.members.profile', this.post.groupId, this.post.owner.id);
    } else if (postedByPage || this.postedIn?.urlId) {
      return this.router.transitionTo('app.publicid', this.post.page.publicLinkId);
    } else {
      return this.router.transitionTo('app.publicid', this.post.owner.publicLinkId);
    }
  }

  @action
  togglePostDropdown() {
    this.postDropdownOpened = !this.postDropdownOpened;
  }

  @action
  closePostDropdown() {
    this.postDropdownOpened = false;
  }

  @action
  hidePost() {
    PostApi.hidePostThread(this.post.postItemId).then(() => {
      this.args.collection.posts.removeObject(this.post);
      FunctionalUtils.info(__('Thread hidden'));
    })
    .catch(() => {
      FunctionalUtils.showDefaultErrorMessage();
    })
    .finally(() => {
      this.closePostDropdown();
    });
  }

  @computed('post.textDisplay', 'post.album')
  get showPostText() {
    return this.post.textDisplay || this.post.album;
  }

  @computed('post.album', 'args.inMediaDialog', 'args.threadId')
  get showPostAlbumName() {
    return this.post.album && !this.args.inMediaDialog && !this.args.threadId;
  }

  @action
  setTruncate(value) {
    this.truncate = value;
  }
}
