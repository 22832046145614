import ApiUtil from './api';

class Api extends ApiUtil {
  info(url) {
    return this.get('/link/info', {
      data: {
        url: url,
      },
    });
  }
}

export default new Api();
