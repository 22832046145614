import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

export default class Avalanche extends Route {
  @service storyblok;
  @service settings;

  model() {
    return this.storyblok.loadStory(`cms/avalanche`);
  }
}
