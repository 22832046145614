import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import layout from './template.hbs';
import './styles.scss';
import { openPostbox } from 'mewe/utils/dialogs-common';

export default class MwFileOptionsDropdown extends PopupOpener {
  @service account;
  @service dynamicDialogs;

  layout = layout;

  popupType = 'dropdown-popup';

  dontCloseAt = '.dropdown--dont-close';

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions(true);

    Object.assign(position, {
      reposition: { elementHeight: 180 },
      placement: {
        top: false,
        left: isRtl,
      },
      translate: { x: -20 },
    });

    return position;
  }

  @action
  repostTo(target) {
    const file = this.args.file;
    if (file && file.audio) {
      file.audio.id = file.id;
    }
    let document = file.document || file.audio;
    openPostbox(
      {
        target: target,
        preselectedFiles: [document],
        theme: 'privacymail',
      },
      this.dynamicDialogs
    );
  }
}
