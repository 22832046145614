import Component from '@glimmer/component';
import { getOwner } from '@ember/application';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import dispatcher from 'mewe/dispatcher';
import PS from 'mewe/utils/pubsub';

export default class MwGroupTabs extends Component {
  @reads('args.model.group') group;
  @reads('args.model.group.id') groupId;

  @tracked searchViewActive;
  @tracked searchTerm = '';

  @service router;
  @service account;

  constructor() {
    super(...arguments);

    this.globals = getOwner(this).lookup('globals:main');

    addObserver(this, 'searchTerm', this, this.searchTermChanged);
    addObserver(this, 'args.searchTerm', this, this.checkIfClosedSearch);

    this.groupApplicationChangeWsHandlerBind = this.groupApplicationChangeWsHandler.bind(this);
    PS.Sub('group.application.new', this.groupApplicationChangeWsHandlerBind);
    PS.Sub('group.application.remove', this.groupApplicationChangeWsHandlerBind);
  }

  willDestroy() {
    removeObserver(this, 'searchTerm', this, this.searchTermChanged);
    removeObserver(this, 'args.searchTerm', this, this.checkIfClosedSearch);

    PS.Unsub('group.application.new', this.groupApplicationNewWsHandlerBind);
    PS.Unsub('group.application.remove', this.groupApplicationNewWsHandlerBind);
  }

  get showScheduledTab() {
    // only for owner/admin in universal group
    if (this.group.isUniversalComputed) {
      return this.group.canPost;
    }

    // visible to anyone in group, just wait for loading group by checking if name exists
    return this.group.name;
  }

  groupApplicationChangeWsHandler(data) {
    const groupId = this.args.model.group.id;

    if (data.groupId === groupId) {
      dispatcher.dispatch('group', 'fetchCounters', groupId);
    }
  }

  @computed('group._links', 'account.activeUser.fprint')
  get groupAvatar() {
    let url = this.group?._links?.userAvatar?.href || '';
    if (url) {
      return url.replace('{imageSize}', '400x400') + '&f=' + this.account.activeUser?.fprint;
    }
  }

  searchTermChanged() {
    this.args.searchTermChanged(this.searchTerm);
  }

  // reset input value when args.searchTerm is cleaned in parent
  checkIfClosedSearch() {
    if (!this.args.searchTerm && this.searchTerm) {
      this.searchTerm = '';
    }
  }

  // reset both in component and in parent component
  closeGroupSearchResults() {
    this.searchTerm = '';
    this.args.searchTermChanged('');
  }

  @action
  focusInSearch() {
    this.searchViewActive = true;
  }

  @action
  focusOutSearch() {
    setTimeout(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.searchViewActive = false;
      }
    }, 500);
  }

  @action
  refreshFeed() {
    this.closeGroupSearchResults();

    //only refresh when on feed page
    if (this.router?.currentRouteName !== 'app.group.index.feed') return;
    //only refresh when there are new posts
    if (this.group.newPosts === 0) return;

    const route = getOwner(this).lookup(`route:app.group.index.feed`);
    if (route) route.send('refreshFeed');
  }
}
