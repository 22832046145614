import ApiUtil from './api';
import { WrapperScope } from 'mewe/constants';

/**
 * import HomeApi from 'mewe/api/home-api';
 */
class Api extends ApiUtil {
  getWrappers(scope, params) {
    if (scope === WrapperScope.GROUP) {
      return this.getNextPageOr('/communities/wrappers/groups', params);
    } else if (scope === WrapperScope.PAGE) {
      return this.getNextPageOr('/communities/wrappers/pages', params);
    } else {
      return this.getNextPageOr('/communities/wrappers', params);
    }
  }

  getWrapperPosts(scope, scopeId, params) {
    if (scope === WrapperScope.GROUP) return this.getNextPageOr(`/group/${scopeId}/wrapperfeed`, params, 'apiRoot3');
    else if (scope === WrapperScope.PAGE) return this.getNextPageOr(`/pages/page/${scopeId}/wrapperfeed`, params);
  }

  dismissWrappers(params) {
    if (params.allGroups) {
      return this.post('/myworld/wrappers/dismiss', {
        data: JSON.stringify({ all: true }), // { groupIds: () } || {{ all: true }} => used for all groups only
      });
    } else if (params.allPages) {
      return this.post('/pages/wrappers/dismiss', {
        data: JSON.stringify({ all: true }), // { pagesIds: () } || {{ all: true }} => used for all pages only
      });
    } else {
      return this.post('/communities/wrappers/dismiss', {
        data: JSON.stringify(params), // params: { groupIds: (), pageIds: () } || { all: true } for all communities
      });
    }
  }

  muteGroupWrapper(groupId) {
    return this.put(`/myworld/wrappers/mute/${groupId}`);
  }

  unmuteGroupWrapper(groupId) {
    return this.del(`/myworld/wrappers/mute/${groupId}`);
  }

  mutePageWrapper(pageId) {
    return this.put(`/pages/page/${pageId}/wrapper/mute`);
  }

  unmutePageWrapper(pageId) {
    return this.del(`/pages/page/${pageId}/wrapper/mute`);
  }
}

export default new Api();
