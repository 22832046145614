import { observer, computed } from '@ember/object';
import { A } from '@ember/array';
import Component from '@ember/component';
import { bind } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import EnvUtils from 'mewe/utils/environment-utils';
import layout from './template.hbs';
import './styles.scss';
import { withLabels_Contacts, withLabels_Group, withLabels_GroupOwnerAdmins } from './utils';
import { KeyboardSelectListActions } from 'mewe/utils/keyboard-select-list-actions';
import PopupOpener from 'mewe/utils/popup-opener-base';
import { scheduleOnce } from '@ember/runloop';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

const MINIMUM_AUTOCOMPLETE_WIDTH = 200;

export default Component.extend(KeyboardSelectListActions, PopupOpener, {
  layout,

  account: service(),

  classNames: ['autocomplete-dropdown'],

  _clickOutsideListener: null,

  attributeBindings: ['style'],

  init: function () {
    this.setDefaultValues();
    this._super();
  },

  didInsertElement() {
    this.setProperties({
      keyboardListActiveIndex: 0,
      elementOffsetHeight: 0,
      elementOffsetWidth: 0,
    });
    this._clickOutsideListener = bind(this, this.clickOutside, this.element);
    document.body.addEventListener('click', this._clickOutsideListener);
    this.search(this.term);
  },

  _strategy: computed('strategy', function () {
    return this.strategy.strategy || this.strategy;
  }),

  // we show information when user enter @ - but only for contacts
  showContactsEmptyInfo: computed('strategy', 'term', function () {
    const strategy = this._strategy;
    return strategy.scope == 'contacts' && (this.term == '@' || this.term == '');
  }),

  search(term, offset) {
    const strategy = this._strategy;

    strategy.search(
      term,
      (data) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        next(() => {
          if (!offset) {
            this.setProperties({
              keyboardListItems: A(),
              keyboardListActiveIndex: 0,
            });
          }

          this.keyboardListItems.pushObjects(data);
        });
      },
      offset
    );
  },

  itemsMapped: computed('keyboardListItems.length', 'term', function () {
    let items = this.keyboardListItems;
    const strategy = this._strategy;

    if (strategy.id === 'emoji') {
      const mapped = items.map((el) => this._strategy.template(el));
      return mapped;
    }

    if (strategy.id === 'hashtag') {
      const mapped = items.map((el) => this._strategy.template(el));
      return mapped;
    }

    if (strategy.scope == 'contacts') {
      items = withLabels_Contacts(items);
    }

    if (strategy?.scope?.groupId) {
      if (this.term == '' || this.term == '@') items = withLabels_GroupOwnerAdmins(items, this.groupName);
      else items = withLabels_Group(items, this.groupName);
    }

    const mapped = items.map((mention) => {
      let links = mention._links || mention.user._links;
      let avatarUrl = '';

      if (links) {
        avatarUrl = EnvUtils.getImgHost(true) + links.avatar.href.replace('{imageSize}', '150x150');
      } else {
        avatarUrl = EnvUtils.getImgHost(true) + '/api/v2/photo/profile/150x150/' + mention.user.id;
      }

      mention.avatarUrl = avatarUrl;

      return mention;
    });

    return mapped;
  }),

  track: observer('term', function () {
    this.search(this.term.length ? this.term.substr(1) : '');
  }),

  setDefaultValues() {
    this.setProperties({
      loading: false,
      offset: 0,
      keyboardListItems: A([]),
      keyboardListElementActionName: 'insert',
    });

    this.resetKeyboardList();
  },

  style: computed(
    'autocompletePlacement',
    'dropdownOpened',
    'position.{left,top}',
    'keyboardListItems.length',
    'elementOffsetHeight',
    'elementOffsetWidth',
    function () {
      const placeOnLeft = window.innerWidth - this.position.left - MINIMUM_AUTOCOMPLETE_WIDTH < 0;

      const styleHorizontal = placeOnLeft
        ? `left: ${Math.floor(this.position.left - this.elementOffsetWidth)}px;`
        : `left: ${Math.floor(this.position.left)}px;`;

      const offset = this.get('elementOffsetHeight');
      let styleVertical = '';

      styleVertical =
        this.autocompletePlacement === 'top'
          ? `top: ${Math.floor(this.position.top - offset)}px;`
          : `top: ${Math.floor(this.position.top + 30)}px;`;

      const display = this.dropdownOpened ? `display: block;` : `display: hidden;`;

      return htmlSafe(styleVertical + styleHorizontal + display);
    }
  ),

  clickOutside(parentElement, event) {
    if (!parentElement.contains(event.target)) {
      this.closeAndDestroyDropdown();
      this.onClose();
    }
  },

  closeAndDestroyDropdown() {
    document.body.removeEventListener('click', this._clickOutsideListener);
  },

  visibilityObserver: observer('keyboardListItems.length', function () {
    scheduleOnce('afterRender', this, () => {
      this.set('elementOffsetHeight', this.element.offsetHeight);
      this.set('elementOffsetWidth', this.element.offsetWidth);
    });

    this.visibilityChange(!!this.keyboardListItems.length);
  }),

  actions: {
    insert(selected, i) {
      const list =
        this._strategy.id === 'hashtag' || this._strategy.id === 'emoji' ? this.keyboardListItems : this.itemsMapped;
      this.onSelect(list[i], this.strategy, this.term);
      this.closeAndDestroyDropdown();
    },
  },
});
