import EmberObject from '@ember/object';

export default EmberObject.extend({
  deserialize: function (serialized) {
    return serialized;
  },

  serialize: function (deserialized) {
    return deserialized;
  },
});
