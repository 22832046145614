/**
 * usage: (compare value1 value2 operator="operator")
 */

import { helper as buildHelper } from '@ember/component/helper';

export function compare(values, options) {
  if (values.length < 2) {
    //throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
  }

  var lvalue = values[0];
  var rvalue = values[1];

  var operator = options.operator || '==';

  var operators = {
    '==': function (l, r) {
      return l == r;
    },
    '===': function (l, r) {
      return l === r;
    },
    '!=': function (l, r) {
      return l != r;
    },
    '<': function (l, r) {
      return l < r;
    },
    '>': function (l, r) {
      return l > r;
    },
    '<=': function (l, r) {
      return l <= r;
    },
    '>=': function (l, r) {
      return l >= r;
    },
    typeof: function (l, r) {
      return typeof l == r;
    },
    '&&': function (l, r) {
      return l && r;
    },
    '&&!': function (l, r) {
      return l && !r;
    },
    '||': function (l, r) {
      return l || r;
    },
    '||!': function (l, r) {
      return l || !r;
    },
    '+': function (l, r) {
      return l + r;
    },
  };

  if (!operators[operator]) {
    throw new Error("Helper 'compare' doesn't know the operator " + operator);
  }

  const result = operators[operator](lvalue, rvalue);

  if (result) {
    return true;
  } else {
    return false;
  }
}

export default buildHelper(compare);
