import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import { escape } from 'lodash';

export const highlightText = (text, hlText, tagStart = '<span>', tagEnd = '</span>') => {
  if (!text || !hlText) return text || '';

  text = escape(text);
  hlText = escape(hlText);

  hlText = hlText.replace(/[`~!@#$%^*()_|+\-=?:'",.<>\{\}\[\]\\\/]/gi, ''); // SG-22509 in ticket's comment, parenthesis break RegExp and looks like are even skipped in search engine

  return text.replace(new RegExp('(' + hlText + ')', 'ig'), tagStart + '$1' + tagEnd);
};

/**
 * usage: {{ text-highlight textToHighlightFrom highlightText highlightHtmlTagStart highlightHtmlTagEnd }}
 */
export default buildHelper(function (values) {
  return htmlSafe(highlightText(values[0], values[1], values[2], values[3]));
});
