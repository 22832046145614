import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';

export default class SidebarJournals extends Component {
  @service dynamicDialogs;

  sorting = ['lastStoryAddedAt:desc'];
  @sort('args.journals.items', 'sorting') journalsSorted;

  @action
  open(journal) {
    let params = {
      journalsList: this.args.journals.items,
      journalId: journal ? journal.id : null,
      tellerId: this.args.contact.id,
      tellerName: this.args.contact.name,
      tellerType: 'User', // for now only User type exist
      avatarHref: this.args.contact._links?.avatar?.href,
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }
}
