import ApiUtil from './api';
import { Theme } from 'mewe/constants';

class Api extends ApiUtil {
  // photosets => list tags with thumbnails and photos count
  getPhotosets(scope, scopeId, params) {
    if (scope === Theme.EVENT) {
      return this.get(`/event/${scopeId}/mediasets`, {
        data: params,
      });
    } else if (scope === Theme.GROUP) {
      return this.get(
        `/group/${scopeId}/mediasets`,
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.PROFILE) {
      return this.get(`/home/user/${scopeId}/mediasets`, {
        data: params,
      });
    } else if (scope === Theme.MYCLOUD) {
      return this.get(
        '/mycloud/mediasets',
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.PAGE) {
      return this.get(`/pages/page/${scopeId}/mediasets`, {
        data: params,
      });
    }
  }

  // list of albums with thumbnails and photos count
  getAlbums(scope, scopeId, params) {
    if (params.nextPage) return this.get(params.nextPage);

    if (scope === Theme.GROUP) {
      return this.get(
        `/${scope}/${scopeId}/albums`,
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.EVENT) {
      return this.get(`/${scope}/${scopeId}/albums`, {
        data: params,
      });
    } else if (scope === Theme.MYCLOUD) {
      return this.get(
        `/mycloud/albums`,
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.PAGE) {
      return this.get(`/pages/page/${scopeId}/albums`, {
        data: params,
      });
    } else {
      return this.get(`/home/user/${scopeId}/albums`, {
        data: params,
      });
    }
  }

  getAlbumNames(scope, scopeId, params) {
    if (scope === Theme.GROUP) {
      return this.get(
        `/${scope}/${scopeId}/albums/find`,
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.EVENT) {
      return this.get(`/${scope}/${scopeId}/albums/names`, {
        data: params,
      });
    } else if (scope === Theme.MYCLOUD) {
      return this.get(
        `/mycloud/albums/find`,
        {
          data: params,
        },
        'apiRoot3'
      );
    } else if (scope === Theme.PAGE) {
      return this.get(`/pages/page/${scopeId}/albums/find`, {
        data: params,
      });
    } else {
      return this.get(`/home/albums/find`, {
        data: params,
      });
    }
  }

  deleteAlbum(albumName) {
    return this.put('/mycloud/album/' + encodeURIComponent(albumName) + '/remove', {}, 'apiRoot3');
  }

  editAlbumName(scope, scopeId, albumName, newAlbumName) {
    if (scope === Theme.GROUP) {
      return this.put(
        `/group/${scopeId}/album/${encodeURIComponent(albumName)}/rename`,
        {
          data: JSON.stringify({ newName: newAlbumName }),
        },
        'apiRoot3'
      );
    } else if (scope === Theme.EVENT) {
      return this.put(`/event/${scopeId}/album/${encodeURIComponent(albumName)}/rename`, {
        data: JSON.stringify({ newName: newAlbumName }),
      });
    } else if (scope === Theme.PAGE) {
      return this.put(`/pages/page/${scopeId}/album/${encodeURIComponent(albumName)}/rename`, {
        data: JSON.stringify({ newName: newAlbumName }),
      });
    } else if (scope === Theme.MYCLOUD) {
      return this.put(
        `/mycloud/album/${encodeURIComponent(albumName)}/rename`,
        {
          data: JSON.stringify({ newName: newAlbumName }),
        },
        'apiRoot3'
      );
    } else {
      return this.put(`/home/album/${encodeURIComponent(albumName)}/rename`, {
        data: JSON.stringify({ newName: newAlbumName }),
      });
    }
  }
}

export default new Api();
