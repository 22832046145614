import { htmlSafe } from '@ember/template';
import Helper from '@ember/component/helper';
import { getEmojiUrlCdn } from 'mewe/utils/emoji-utils';

export default Helper.extend({
  compute(value, options) {
    let position = value[0].pngSpritePosition.default;
    let sprite = value[1];

    let scale = position[3];

    let positionX = position[0];
    let positionY = position[1];

    let spriteSizeX = sprite.size.x;
    let spriteSizeY = sprite.size.y;

    if (options.size) {
      let s = options.size;
      let sc = (n) => n / scale;

      spriteSizeX = sc(spriteSizeX) * s;
      spriteSizeY = sc(spriteSizeY) * s;
      positionX = sc(positionX) * s;
      positionY = sc(positionY) * s;
    }

    return htmlSafe(
      `<div class="sticker" data-testid="sticker-test-attr" style="background-image: url(${
        getEmojiUrlCdn() + sprite.url
      }); background-position: -${positionX}px -${positionY}px; background-size: ${spriteSizeX}px ${spriteSizeY}px;"></div>`
    );
  },
});
