import i18n from 'i18next';
import ICU from 'i18next-icu';

import LngDetector from 'i18next-browser-languagedetector';
import storage from 'mewe/shared/storage';
import cookie from 'mewe/shared/cookie';
import assign from 'object-assign';
import XHR from 'i18next-xhr-backend';

export const findBestMatchingLocale = (supportedLocs = [], locs = []) => {
  let found;

  locs.find((l) => {
    found = supportedLocs.find((b) => b === l);

    if (found) {
      return found;
    } else {
      if (l.indexOf('-') !== -1) {
        l = l.slice(0, l.indexOf('-'));
      }

      found = supportedLocs.find((b) => {
        return b === l || (b.indexOf('-') !== -1 && b.slice(0, b.indexOf('-')) === l);
      });

      return found;
    }
  });

  return found;
};

export default function (moment) {
  const baseSettings = {
    ns: ['translation', 'block', 'country', 'community'],
    // load: 'languageOnly', // prevents loading en-US, ja-JA, to avoid unnecessary resource look ups
    whitelist: [
      'en',
      'ar',
      'cs',
      'da',
      'de',
      'el',
      'es',
      'fi',
      'fr',
      'he',
      'it',
      'ja',
      'ko',
      'nb',
      'nl',
      'pl',
      'pt-BR',
      'sv',
      'zh-HK',
      'zh-CN',
      'test',
    ],
    keySeparator: '::', // doesn't work as before for some reason, but shouldn't really be needed
    nsSeparator: ':::',
    contextSeparator: '###',
    fallbackLng: 'en',
    returnNull: false,
    returnEmptyString: false,
    setJqueryExt: false,
    debug: false, //config.environment === 'local',
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false,
    },
    backend: {
      ajax: loadLocales,
      loadPath: '/app/locales/{lng}/{ns}.json',
      parse: (data) => data,
    },
    detection: {
      order: ['querystring', 'meweStorage', 'cookie', 'meweNavigator', 'navigator'],
      lookupQuerystring: 'locale',
      lookupCookie: 'locale',
      lookupLocalStorage: null, // meweStorage used instead
    },
  };

  function loadLocales(url, options, callback) {
    let cb = (locale) => callback(locale, { status: '200' }),
      // helpers so matched url doesn't get replaced by build:revall
      trans = (locale) => '/app/locales/' + locale + '/translation.json',
      block = (locale) => '/app/locales/' + locale + '/block.json',
      country = (locale) => '/app/locales/' + locale + '/country.json',
      community = (locale) => '/app/locales/' + locale + '/community.json';

    if (url.indexOf('/country.json') !== -1) {
      // english translations for countries for now
      return import(/* webpackChunkName: 'lang-country.en' */ 'mewe/assets/locales/en/country.json').then(cb);
    }

    // ./../../ is needed for desktop-app jenkins build, ../../ doesn't work for some reason
    switch (url) {
      case trans('en'):
        import(/* webpackChunkName: 'lang.en' */ 'mewe/assets/locales/en/translation.json').then(cb);
        break;

      case country('en'):
        import(/* webpackChunkName: 'lang-country.en' */ 'mewe/assets/locales/en/country.json').then(cb);
        break;

      case trans('ar'):
        import(/* webpackChunkName: 'lang.ar' */ 'mewe/assets/locales/ar/translation.json').then(cb);
        break;

      case trans('cs'):
        import(/* webpackChunkName: 'lang.cs' */ 'mewe/assets/locales/cs/translation.json').then(cb);
        break;

      case trans('da'):
        import(/* webpackChunkName: 'lang.da' */ 'mewe/assets/locales/da/translation.json').then(cb);
        break;

      case trans('de'):
        import(/* webpackChunkName: 'lang.de' */ 'mewe/assets/locales/de/translation.json').then(cb);
        break;

      case trans('el'):
        import(/* webpackChunkName: 'lang.el' */ 'mewe/assets/locales/el/translation.json').then(cb);
        break;

      case trans('es'):
        import(/* webpackChunkName: 'lang.es' */ 'mewe/assets/locales/es/translation.json').then(cb);
        break;

      case trans('fi'):
        import(/* webpackChunkName: 'lang.fi' */ 'mewe/assets/locales/fi/translation.json').then(cb);
        break;

      case trans('fr'):
        import(/* webpackChunkName: 'lang.fr' */ 'mewe/assets/locales/fr/translation.json').then(cb);
        break;

      case trans('he'):
        import(/* webpackChunkName: 'lang.he' */ 'mewe/assets/locales/he/translation.json').then(cb);
        break;

      case trans('it'):
        import(/* webpackChunkName: 'lang.it' */ 'mewe/assets/locales/it/translation.json').then(cb);
        break;

      case trans('ja'):
        import(/* webpackChunkName: 'lang.ja' */ 'mewe/assets/locales/ja/translation.json').then(cb);
        break;

      case trans('ko'):
        import(/* webpackChunkName: 'lang.ko' */ 'mewe/assets/locales/ko/translation.json').then(cb);
        break;

      case trans('nb'):
        import(/* webpackChunkName: 'lang.nb' */ 'mewe/assets/locales/nb/translation.json').then(cb);
        break;

      case trans('nl'):
        import(/* webpackChunkName: 'lang.nl' */ 'mewe/assets/locales/nl/translation.json').then(cb);
        break;

      case trans('pl'):
        import(/* webpackChunkName: 'lang.pl' */ 'mewe/assets/locales/pl/translation.json').then(cb);
        break;

      case trans('pt-BR'):
        import(/* webpackChunkName: 'lang.ptbr' */ 'mewe/assets/locales/pt_BR/translation.json').then(cb);
        break;

      case trans('sv'):
        import(/* webpackChunkName: 'lang.sv' */ 'mewe/assets/locales/sv/translation.json').then(cb);
        break;

      case trans('zh-CN'):
        import(/* webpackChunkName: 'lang.zhcn' */ 'mewe/assets/locales/zh_CN/translation.json').then(cb);
        break;

      case trans('zh-HK'):
        import(/* webpackChunkName: 'lang.zhhk' */ 'mewe/assets/locales/zh_HK/translation.json').then(cb);
        break;
      default:
        callback(null, { status: '404' });
    }
  }

  function init(lang, settingsArg, callback) {
    const settings = assign({}, baseSettings, settingsArg);
    let i18nInited = false;
    const languageChanged = () => {
      if (!i18nInited) {
        i18nInited = true;
      } else {
        if (i18n.language) {
          cookie.set('locale', i18n.language); // user switched language, cookie for outer pages when logged out
        } else {
          cookie.remove('locale');
        }
      }
      loadLocalizedLibs(i18n.language, callback);
    };

    i18n.on('languageChanged', languageChanged);
    i18n.use(XHR);

    // support both i18n plurals (count-param) and ICU https://formatjs.io/docs/core-concepts/icu-syntax/
    class ICUWithI18nPlurals {
      constructor(options) {
        this.ICU = new ICU(options);
      }
      init(i18next, options) {
        return this.ICU.init(i18next, options);
      }
      addLocaleData(data) {
        return this.ICU.addLocaleData(data);
      }
      addUserDefinedFormats(formats) {
        return this.ICU.addUserDefinedFormats(formats);
      }
      parse(res, options, lng, ns, key, info) {
        if (options.count !== undefined && typeof options.count !== 'string') {
          key += i18n.translator.pluralResolver.getSuffix(lng, options.count);
        }
        return this.ICU.parse(res, options, lng, ns, key, info);
      }
      clearCache() {
        return this.ICU.clearCache();
      }
    }

    ICUWithI18nPlurals.type = 'i18nFormat';
    i18n.use(ICUWithI18nPlurals);

    if (lang) {
      settings.lng = lang.replace('_', '-'); // java format to i18next
    } else {
      const lngDetector = new LngDetector();

      lngDetector.addDetector({
        name: 'meweNavigator',
        lookup() {
          if (window.navigator && window.navigator.languages && window.navigator.languages.length) {
            return findBestMatchingLocale(baseSettings.whitelist, window.navigator.languages);
          }
        },
      });

      lngDetector.addDetector({
        name: 'meweStorage',
        lookup() {
          try {
            const user = JSON.parse(storage.get(storage.keys.currentUser));
            if (user) {
              return user.locale; // if null i18n tries to detect
            }
          } catch (e) {
            // hmm ... there's currentUser set in storage, but it's not a valid obj -> rm it.
            storage.remove(storage.keys.currentUser);
          }
        },
      });

      i18n.use(lngDetector);
    }

    i18n.init(settings);
  }

  function loadLocalizedLibs(locale, callback) {
    if (moment) moment.locale(locale);
    if (callback) callback(i18n);
  }

  return init;
}
