import ContactsApi from 'mewe/api/contacts-api';
import EventsApi from 'mewe/api/events-api';
import GroupApi from 'mewe/api/group-api';
import PagesApi from 'mewe/api/pages-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import { each, escape } from 'lodash';
/**
 * import InvitationUtils from 'mewe/utils/invitations-utils';
 */
export default {
  invitationsSentTitle: function () {
    return __('Invitations were sent!');
  },
  invitationsErrTitle: function () {
    return __('Something went wrong, please try again later!');
  },
  noInviteesMsg: function () {
    return __('Please add an invitee first.');
  },
  invitationNotAllowedErrTitle: function () {
    return __('Invitation');
  },
  invitationNotAllowedErrText: function () {
    return __('Sorry, this member does not accept follow requests from strangers.');
  },
  userHasContactsLimitErrText: function () {
    return __(
      'You have reached the limit of 8,000 follows. To follow a new profile you must either make your profile public or remove an existing follow.'
    );
  },
  userHasLimitErrText: function () {
    return __('The user you are trying to invite have reached the limit of 8000 follows.');
  },
  chatRequestsLimitMsg: function (hoursLeft) {
    return __(
      `<b>Woah. You must be really social!</b> To limit spam, you’re only allowed to send 50 chat requests every 24 hours. You’ve reached that limit and you’ll need to wait {count} hour before you can send any more. There is no limit to how many chat requests you can receive.`,
      {
        count: hoursLeft,
      }
    );
  },

  contactRequestsLimitMsg: function (hoursLeft) {
    return __(
      `<b>Woah. You must be really social!</b> To limit spam, you’re only allowed to send 50 follow requests every 24 hours. You’ve reached that limit and you’ll need to wait {count} hour before you can send any more. There is no limit to how many follow requests you can receive.`,
      {
        count: hoursLeft,
      }
    );
  },

  // was used to get emails from string with multiple emails: "xx@email.com,yy@email.com"
  validateEmail: function (email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  // params: emailInvitees, ref
  sendFollowInvitations: function (dynamicDialogs, params, callback, failure) {
    if (
      (params && params.wholeAddressBook) ||
      (params.emailInvitees && params.emailInvitees.length) ||
      (params.smsInvitees && params.smsInvitees.length)
    ) {
      if (Array.isArray(params.userInvitees)) {
        let userInviteesStr = '';
        each(params.userInvitees, (userOrId) => {
          if (userOrId.userId) userInviteesStr += userOrId.userId + ',';
          else userInviteesStr += userOrId + ',';
        });
        if (userInviteesStr.length) {
          params.userInvitees = [
            {
              users: userInviteesStr.slice(0, userInviteesStr.length - 1), // api expects string of ids separated by ','
            },
          ];
        } else {
          delete params.userInvitees;
        }
      }

      ContactsApi.sendInvitations(params)
        .then(() => {
          PS.Pub('contact-invitees.new');
          if (typeof callback === 'function') callback();
          FunctionalUtils.info(this.invitationsSentTitle());
        })
        .catch((resp) => {
          if (typeof failure === 'function') failure(resp);

          if (resp?.data?.errorCode === 505) {
            this.frozenInvitationsDialog(dynamicDialogs, resp.data.frozenTo, 'contact'); // contact requests
          } else if (resp?.data?.errorCode === 705) {
            this.showInfoDialog(dynamicDialogs, null, this.userHasContactsLimitErrText());
          } else if (resp?.data?.errorCode === 706) {
            this.showInfoDialog(dynamicDialogs, null, this.userHasLimitErrText());
          } else if (resp?.status === 403) {
            this.showAlertDialog(
              dynamicDialogs,
              this.invitationNotAllowedErrTitle(),
              this.invitationNotAllowedErrText()
            );
          } else {
            this.showAlertDialog(dynamicDialogs, this.invitationsErrTitle());
          }
        });
    } else {
      FunctionalUtils.error(this.noInviteesMsg());
    }
  },

  sendGroupInvitations: function (dynamicDialogs, params, callback, failure) {
    const self = this;

    if (!params.groupId && params.group) {
      params.groupId = params.group.id;
    }

    if (
      (params.emailInvitees && params.emailInvitees.length) ||
      (params.userInvitees && params.userInvitees.length) ||
      params.wholeAddressBook
    ) {
      GroupApi.inviteUsers(params)
        .then(function () {
          if (callback && typeof callback === 'function') {
            callback();
          }
          FunctionalUtils.info(self.invitationsSentTitle());
        })
        .catch(function () {
          if (failure && typeof failure === 'function') {
            failure();
          }
          self.showAlertDialog(dynamicDialogs, self.invitationsErrTitle());
        });
    } else {
      FunctionalUtils.error(self.noInviteesMsg());
    }
  },

  sendEventInvitations: function (dynamicDialogs, eventId, params, callback, failure) {
    if (!eventId) return;

    const self = this;
    let requestParams = {};

    if (params.userInvitees && params.userInvitees.length) {
      let userIds = params.userInvitees.map((u) => u.userId);

      requestParams.userIds = userIds;
    }

    if (params.emailInvitees && params.emailInvitees.length) {
      let userEmails = params.emailInvitees.map((e) => e.emails);

      requestParams.emailInvitees = userEmails;
    }

    if (requestParams.userIds || requestParams.emailInvitees) {
      EventsApi.inviteUsers(eventId, requestParams)
        .then(function () {
          if (callback && typeof callback === 'function') {
            callback();
          }
          FunctionalUtils.info(self.invitationsSentTitle());
        })
        .catch(function () {
          if (failure && typeof failure === 'function') {
            failure();
          }
          self.showAlertDialog(dynamicDialogs, self.invitationsErrTitle());
        });
    } else {
      FunctionalUtils.error(self.noInviteesMsg());
    }
  },

  sendPageInvitations: function (dynamicDialogs, pageId, params, callback, failure) {
    if (params.userIds && params.userIds.length) {
      PagesApi.inviteUsers(pageId, params)
        .then(() => {
          if (typeof callback === 'function') callback();
          FunctionalUtils.info(this.invitationsSentTitle());
        })
        .catch(() => {
          if (typeof failure === 'function') failure();
          this.showAlertDialog(dynamicDialogs, this.invitationsErrTitle());
        });
    } else {
      FunctionalUtils.error(this.noInviteesMsg());
    }
  },

  showAlertDialog(dynamicDialogs, title, message) {
    dynamicDialogs.openDialog('simple-dialog-new', {
      title: title,
      message: message,
      noEscaping: true,
      okButtonText: __('OK'),
    });
  },

  showInfoDialog(dynamicDialogs, title, message) {
    dynamicDialogs.openDialog('do-you-know-dialog', {
      image: '/assets/images/do-you-know.webp',
      okButtonText: __('Got it!'),
      title,
      message,
      noEscaping: true,
    });
  },

  frozenInvitationsDialog: function (dynamicDialogs, expiresAt, whichInvitation) {
    /**
     * expiresAt should be in seconds
     * seconds: 1584696246
     * ms: 1584696246000
     * we want to support also ms in just in case
     */
    let expiresAtMs = 0;

    if (expiresAt >= 1e11 || expiresAt <= -3e10) {
      // time is in ms already, no converting
      expiresAtMs = expiresAt;
    } else {
      // time is in s, we need to convert to ms
      expiresAtMs = expiresAt * 1000;
    }

    const ms = expiresAtMs - new Date().getTime();
    const hoursLeft = Math.ceil(ms / (60 * 60 * 1000));

    let message = '';

    if (whichInvitation === 'chat') {
      // chat requests
      message = this.chatRequestsLimitMsg(hoursLeft);
    } else {
      message = this.contactRequestsLimitMsg(hoursLeft);
    }

    dynamicDialogs.openDialog('simple-dialog-new', {
      message: message,
      noEscaping: true,
      okButtonText: __('OK'),
    });
  },

  showBlockUserOwnerConflictAlert(dynamicDialogs, params) {
    let escapedUserName = escape(params.userName);

    const closeCallback = () => {
      dynamicDialogs.closeByName('report-dialog');
    };

    dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Block {userName}', {
        userName: escapedUserName,
      }),
      message: __(
        'You cannot block {userName} because they are the owner or admin of {groupNames}. In order to block this person, you must remove yourself from their groups',
        {
          userName: escapedUserName,
          groupNames: this.getGroupNamesHTML(params.groups),
        }
      ),
      noEscaping: true,
      okButtonText: __('OK'),
      onClose: closeCallback,
      onConfirm: closeCallback,
    });
  },

  showBlockMeOwnerConflictConfirm(dynamicDialogs, params) {
    const escapedUserName = escape(params.userName),
      title = __('Block {userName}', {
        userName: escapedUserName,
      });

    dynamicDialogs.openDialog('simple-dialog-new', {
      title: title,
      message: __(
        'You are trying to block {userName}, but you are the group owner or admin of {groupNames}. Group members may not block the owner, but this also means that you, as the owner, may not block them. Would you like to remove this member from your groups and then block them?',
        {
          userName: escapedUserName,
          groupNames: this.getGroupNamesHTML(params.groups),
        }
      ),
      noEscaping: true,
      cancelButtonText: __('Cancel'),
      okButtonText: __('Remove and Block'),
      onClose: params.callback,
      onConfirm: () => {
        GroupApi.removeUserFromMyGroups(params.userId)
          .then(() => {
            ContactsApi.blockUser(params.userId)
              .then((resp) => {
                if (resp.user_admin || resp.user_owner) {
                  FunctionalUtils.error(__("Couldn't block User."));
                } else {
                  PS.Pub('user.blocked', {
                    userId: params.userId,
                    userName: params.userName,
                    groupId: params.groupId,
                    eventId: params.eventId,
                  });
                  FunctionalUtils.info(__('User blocked'));
                  if (params.callback) params.callback(true);
                }
              })
              .catch(() => {
                FunctionalUtils.error(__("Couldn't block User."));
              });
          })
          .catch(() => {
            FunctionalUtils.error(__("Couldn't remove user from groups"));
          });
      },
    });
  },

  showBlockUserConfirm(dynamicDialogs, params) {
    dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('You are about to block {userName}', {
        userName: params.userName,
      }),
      message: __(
        'When you block someone, you will not see their posts in any groups you are together, and they will not see your posts. They will also not be able to send you chat messages, and you will not be able to message them either. Their name will appear in a special "Blocked" list in your profile in case you change your mind.'
      ),
      noEscaping: true,
      cancelButtonText: __('Cancel'),
      okButtonText: __('Block'),
      onConfirm: () => {
        params.failCallback = (params, resp) => {
          this.couldntblockUserFail(dynamicDialogs, params, resp);
        };
        this.blockUser(params);
      },
    });
  },

  showBlockUserGroupsConfirm(params) {
    params.dynamicDialogs.openDialog('group-blocking-confirm-dialog', {
      type: 'confirm-simple',
      userId: params.userId,
      currentGroupId: params.currentGroupId,
      title: __('You are about to block {userName} from this group', {
        userName: params.userName,
      }),
      dialogClass: 'myworld',
      okButtonStr: __('Block'),
      width: 500,
      onConfirm: () => {
        params.failCallback = (params, resp) => {
          this.couldntblockUserFail(params.dynamicDialogs, params, resp);
        };
        this.blockUserFromAllGroup(params);
      },
    });
  },

  blockUserFromAllGroup(params) {
    GroupApi.blockUserFromAllGroup(params.userId)
      .then((resp) => {
        if (resp.user_owner || resp.user_admin || resp.me_owner || resp.me_admin) {
          if (params.failCallback) {
            params.failCallback(params, resp);
          }
        } else {
          PS.Pub('user.blocked', {
            userId: params.userId,
            userName: params.userName,
          });

          if (params.callback) {
            params.callback();
          }
          FunctionalUtils.info(__('This member has been removed from the group and prevented from rejoining.'));
        }
      })
      .catch(() => {
        FunctionalUtils.error(__(`Couldn't block member.`));
      });
  },

  blockUser(params) {
    ContactsApi.blockUser(params.userId)
      .then((resp) => {
        if (resp.user_owner || resp.user_admin || resp.me_owner || resp.me_admin) {
          if (params.failCallback) {
            params.failCallback(params, resp);
          }
        } else {
          // case when we don't want to publish 'user.blocked' event - after report+block action we display
          // report dialog with success message and blocking is done afterwards - we don't want blocking
          // callback to redirect or refresh page because that would close success dialog. Redirect will be done on dialog close
          if (!params.blockPS) {
            PS.Pub('user.blocked', {
              userId: params.userId,
              userName: params.userName,
            });
          }
          if (params.callback) {
            params.callback();
          }
          FunctionalUtils.info(__('User blocked'));
        }
      })
      .catch(() => {
        FunctionalUtils.error(__("Couldn't block User."));
      });
  },

  couldntblockUserFail(dynamicDialogs, params, data) {
    if (!data) {
      return FunctionalUtils.error(__("Couldn't block user"));
    }

    if (data.user_owner || data.user_admin) {
      // user is owner of groups I belong to
      this.showBlockUserOwnerConflictAlert(dynamicDialogs, {
        userName: params.userName,
        groups: data.groups,
      });
    } else if (data.me_owner || data.me_admin) {
      // I am owner of groups user belongs to
      this.showBlockMeOwnerConflictConfirm(dynamicDialogs, {
        userName: params.userName,
        userId: params.userId,
        groups: data.groups,
        groupId: params.groupId,
        eventId: params.eventId,
        callback: params.callback,
      });
    } else {
      FunctionalUtils.error(__("Couldn't block user"));
    }
  },

  getGroupNamesHTML(groups = []) {
    return groups.map((g) => '<strong class="color-app-' + g.id + '">' + escape(g.name) + '</strong>').join(', ');
  },
};
