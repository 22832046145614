import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MwProfileDropdown extends Component {
  @service account;
  @service purchase;

  get darkThemeAvailable() {
    return this.args.hasDarkTheme || this.purchase.darkTheme.get('purchased');
  }
}
