import twitterText from 'twitter-text';
import { gifUrlRegExp } from 'mewe/utils/gif-utils';
import isUndefined from 'mewe/utils/isUndefined';
import { isEmpty, unescape } from 'lodash';

export default {
  urlsToSkip: [gifUrlRegExp],

  toDisplay(text) {
    var richText = '';

    if (text) {
      let entities = twitterText.extractUrlsWithIndices(text, {
        extractUrlsWithoutProtocol: true,
      });

      if (!isEmpty(this.urlsToSkip)) {
        let doesNotMatchUrlToSkip = (e) => isUndefined(this.urlsToSkip.find((reg) => !isEmpty(e.url.match(reg))));
        entities = entities.filter(doesNotMatchUrlToSkip);
      }

      entities = entities.map((url) => {
        url.url = unescape(url.url);
        return url;
      });

      richText = twitterText.autoLinkEntities(text, entities, {
        targetBlank: true,
        suppressNoFollow: true, // added two lines later manually
        invisibleTagAttrs: 'img',
        rel: 'nofollow noopener noreferrer',
      });
    }

    return richText;
  },

  toEdit(text) {
    return text;
  },

  // textEdit => textServer
  // TODO
  toServer(text) {
    return text;
  },
};
