import { later } from '@ember/runloop';
import config from 'mewe/config/environment';

export const getWindowHeight = () => {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

export const getWindowScrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
};

export const getElWidth = (el) => {
  return el ? parseFloat(getComputedStyle(el, null).width.replace('px', '')) : 0;
};

export const getElHeight = (el) => {
  return el ? parseFloat(getComputedStyle(el, null).height.replace('px', '')) : 0;
};

export const animateScrollTo = (element, to = 0, duration = 300, scrollToDone = null) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;

    const val = easeInOutQuad(currentTime, start, change, duration);

    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      if (scrollToDone) scrollToDone();
    }
  };

  animateScroll();
};

export const setSwipeListener = (el, dir, callback) => {
  const swipeTreshold = 30;

  let touchStartX = 0;
  let touchEndX = 0;

  el.addEventListener('touchstart', (event) => {
    touchStartX = event.changedTouches[0].screenX;
  });

  el.addEventListener('touchend', (event) => {
    touchEndX = event.changedTouches[0].screenX;

    if (Math.abs(touchEndX - touchStartX) < swipeTreshold) return;

    if (dir === 'left' && touchEndX > touchStartX) callback();
    if (dir === 'right' && touchEndX < touchStartX) callback();
  });
};

export const setSticky = (opts = {}) => {
  let lastElementHeight, lastViewHeight;

  const element = opts.element;
  const appTopPadding = 80; // should be equal to $app-top-padding from _constants.scss
  const sidebarOffset = opts.sidebarOffset || appTopPadding;
  const margin = opts.margin || 20;
  const isSecondary = opts.isSecondary || false;

  if (!element) return;

  const position = () => {
    // positioning interval is needed because sidebar/viewport heights can change
    if (config.environment !== 'test') {
      // in tests this part causes timeouts of <AppMainFrame::SidebarRight> component
      later(this, position, 1000);
    }

    // mobile view doesn't have sticky sidebars.
    // secondary sidebars (e.g. group settings sidebar) have different media-query break point
    if (window.innerWidth <= 620 || (isSecondary && window.innerWidth <= 900)) {
      element.style.top = '';
      return;
    }

    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const elementHeight = element.clientHeight;

    // don't do anything if sidebar height and window height didn't change
    if (lastElementHeight === elementHeight && lastViewHeight === viewHeight) {
      return;
    }

    lastElementHeight = elementHeight;
    lastViewHeight = viewHeight;

    if (elementHeight + sidebarOffset > viewHeight) {
      element.style.top = `${viewHeight - elementHeight - margin}px`;
    } else {
      element.style.top = '';
    }
  };

  position();
};
