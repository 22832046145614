import Controller from '@ember/controller';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';

export default class PaypalSuccessController extends Controller {
  queryParams = [
    'transactionId',
    'token',
    'entityId',
    {
      subscriptionId: 'subscription_id',
    },
  ];

  @reads('model.makeSuccessRequest.value') response;
}
