import layout from './template.hbs';
import './styles.scss';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getElWidth, getElHeight, getOffset, getWindowScrollTop } from 'mewe/utils/elements-utils';
import { tracked } from '@glimmer/tracking';

export default class MwSimpleEmojiPicker extends PopupOpener {
  layout = layout;

  popupType = 'simple-emoji-picker';

  @tracked popupOnLeft;
  @tracked popupOnRight;

  closeOnScroll = true;

  closeOnHoverOutside = true;

  insideAnotherScrollable = false;

  popupSize = {
    width: 140,
    height: 44,
  };

  getPlacement() {
    const margin = 20;
    const parentOffset = getOffset(this.args.parent);
    const parentWidthHalf = getElWidth(this.args.parent) / 2;
    const spaceTop = parentOffset.top - getWindowScrollTop();
    const spaceLeft = parentOffset.left + parentWidthHalf;
    const spaceRight = window.innerWidth - parentOffset.left - parentWidthHalf;
    const sideWidthRequired = this.popupSize.width / 2 + margin;

    // default placement: top and centered
    let placement = {
      top: parentOffset.top - this.popupSize.height,
      left: parentOffset.left + parentWidthHalf - this.popupSize.width / 2,
    };

    // not enough space on top, position on bottom
    if (spaceTop - margin < this.popupSize.height) {
      placement.top = parentOffset.top + getElHeight(this.args.parent);
      this.popupOnTop = false;
    } else {
      this.popupOnTop = true;
    }

    // not enough space on right or left, side offset needed
    if (spaceRight < sideWidthRequired) {
      placement.left -= 30;
      this.popupOnLeft = true;
    } else if (spaceLeft < sideWidthRequired) {
      placement.left += 30;
      this.popupOnRight = true;
    }

    return placement;
  }
}
