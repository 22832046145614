/**
 * import DocumentUtils from 'mewe/utils/document-utils';
 */

import EnvironmentUtils from 'mewe/utils/environment-utils';

/*
 * best way to set document icon in template is: src={{ doc-icon file.name }}
 */

export function getDocIconUrlFromFileName(name) {
  if (name) return getDocIconUrl(name.toLowerCase().slice(name.lastIndexOf('.') + 1));
}

export function getDocIconUrl(ext) {
  let type = ext && ext.toLowerCase();
  let icon;

  // mobile apps also need access to the doc icons so they are not currently hashed, so simplifying is ok here
  switch (type) {
    case 'acc':
    case 'ai':
    case 'aiff':
    case 'asp':
    case 'avi':
    case 'bmp':
    case 'c':
    case 'cpp':
    case 'css':
    case 'data':
    case 'dmg':
    case 'doc':
    case 'docx':
    case 'dot':
    case 'dotx':
    case 'dwg':
    case 'dxf':
    case 'eps':
    case 'exe':
    case 'flv':
    case 'gif':
    case 'h':
    case 'html':
    case 'ics':
    case 'iso':
    case 'java':
    case 'jpg':
    case 'key':
    case 'm4v':
    case 'mid':
    case 'mov':
    case 'mp3':
    case 'mp4':
    case 'mpg':
    case 'odp':
    case 'ods':
    case 'odt':
    case 'otp':
    case 'ots':
    case 'ott':
    case 'pdf':
    case 'php':
    case 'png':
    case 'pps':
    case 'ppt':
    case 'pptx':
    case 'psd':
    case 'py':
    case 'qt':
    case 'rar':
    case 'rb':
    case 'rtf':
    case 'sql':
    case 'tga':
    case 'tgz':
    case 'tiff':
    case 'txt':
    case 'wav':
    case 'xls':
    case 'xlsx':
    case 'xml':
    case 'yml':
    case 'zip':
    case 'document':
    case 'presentation':
    case 'spreadsheet':
      icon = '/assets/icons/file-type/' + type + '.png'; // appended to prevent hashing
      break;
    default:
      icon = '/assets/icons/file-type/' + 'default.' + 'png'; // appended to prevent hashing
      break;
  }

  return EnvironmentUtils.getCdnHost() + icon;
}

export const fileFormatIcons = [
  'acc',
  'ai',
  'aiff',
  'asp',
  'avi',
  'bmp',
  'c',
  'cpp',
  'css',
  'data',
  'dmg',
  'doc',
  'docx',
  'dot',
  'dotx',
  'dwg',
  'dxf',
  'eps',
  'exe',
  'flv',
  'gif',
  'h',
  'html',
  'ics',
  'iso',
  'java',
  'jpg',
  'key',
  'm4v',
  'mid',
  'mov',
  'mp3',
  'mp4',
  'mpg',
  'odp',
  'ods',
  'odt',
  'otp',
  'ots',
  'ott',
  'pdf',
  'php',
  'png',
  'pps',
  'ppt',
  'pptx',
  'psd',
  'py',
  'qt',
  'rar',
  'rb',
  'rtf',
  'sql',
  'tga',
  'tgz',
  'tiff',
  'txt',
  'wav',
  'xls',
  'xlsx',
  'xml',
  'yml',
  'zip',
];
