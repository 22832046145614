import emojisParser from 'mewe/stores/text-parsers/emojis-parser';
import gifUrlsParser from 'mewe/stores/text-parsers/gif-urls-parser';
import hashtagsParser from 'mewe/stores/text-parsers/hashtags-parser';
import htmlParser from 'mewe/stores/text-parsers/html-parser';
import linksParser from 'mewe/stores/text-parsers/links-parser';
import mentionsParser from 'mewe/stores/text-parsers/mentions-parser';
import markdownParser from 'mewe/stores/text-parsers/markdown-parser';
import highlightParser from 'mewe/stores/text-parsers/highlight-parser';
import { reduce } from 'lodash';

export let parsersDefault = [
  htmlParser,
  linksParser,
  hashtagsParser,
  mentionsParser,
  emojisParser,
  gifUrlsParser,
  highlightParser,
];

export let parsersDefaultBasic = [htmlParser, linksParser, emojisParser];

export let parsersDefaultNoHashtags = [
  htmlParser,
  linksParser,
  mentionsParser,
  emojisParser,
  gifUrlsParser,
  highlightParser,
];

export let parsersWithMarkdown = [
  markdownParser,
  hashtagsParser,
  mentionsParser,
  emojisParser,
  gifUrlsParser,
  highlightParser,
];

export default function (text, parsers = parsersDefault, options = {}) {
  return reduce(
    parsers,
    (value, parser) => {
      return parser.toDisplay(value, options);
    },
    text
  );
}
