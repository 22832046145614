import { A } from '@ember/array';
import EmberObject, { set } from '@ember/object';
import { next } from '@ember/runloop';

import MathUtils from 'mewe/utils/math-utils';
import fuHelper from 'mewe/utils/fileupload-utils';
import { maxPollOptions, fileUploadLimit } from 'mewe/constants';
import toServer from 'mewe/stores/text-parsers/to-server';
import PostCommons from 'mewe/utils/posting-utils';
import baseController from './baseController';
import {
  photoMaxWidthHeight,
  photoMaxWeightToUploadToServer,
  photoMaxWeightToResizeInBrowser,
  prettyWeight as weight,
} from 'mewe/constants';
import Session from 'mewe/shared/session';
import { resizeImage } from 'mewe/utils/fileupload-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import axios from 'axios';
import PS from 'mewe/utils/pubsub';

const CancelToken = axios.CancelToken;

class Poll extends baseController {
  constructor(postbox) {
    super(...arguments);
    this.postbox = postbox;
    this.setInitialAttributes();
  }

  setInitialAttributes() {
    this.photoBoxIdPhotoIdPairs = {};

    this.retries = {};
    this.maxRetries = 8;
    this.retryTimeouts = {};
    this.retryTimeoutValues = [100, 200, 500, 750, 1000, 2000, 5000, 10000];
    this.jqXHRs = {};

    let selectedDays = 0,
      selectedHours = 0,
      selectedMinutes = 0,
      pollQuestion = '',
      pollOptions;

    if (this.postbox.postToRepost && this.postbox.postToRepost.poll) {
      const poll = this.postbox.postToRepost.poll;

      pollOptions = A(
        poll.options.map((opt) => {
          if (opt.photo) {
            return EmberObject.create({
              text: opt.text,
              photo: { id: opt.photo.id, url: opt.photo.url },
              hasPhoto: true,
            });
          } else
            return EmberObject.create({
              text: opt.text,
              hasPhoto: false,
            });
        })
      );

      pollQuestion = poll.question;

      const timeLeft = poll.getDaysHoursMinutesLeft(
        this.postbox.postToRepost.scheduled && this.postbox.postToRepost.createdAt * 1000 - 1000
      );

      selectedDays = timeLeft.days;
      selectedHours = timeLeft.hours;
      selectedMinutes = timeLeft.minutes;
    } else {
      pollOptions = A([
        EmberObject.create({ text: '', hasPhoto: false }),
        EmberObject.create({ text: '', hasPhoto: false }),
      ]);
    }

    this.postbox.showPoll = !!this.postbox.postToRepost?.poll;
    this.postbox.pollQuestion = pollQuestion;
    this.postbox.pollOptions = pollOptions;

    const pollSettings = this.postbox.pollSettings;

    pollSettings.maxOptions = maxPollOptions;
    pollSettings.selectedDays = selectedDays;
    pollSettings.selectedHours = selectedHours;
    pollSettings.selectedMinutes = selectedMinutes;

    for (let i = 0; i <= 30; i++) pollSettings.days.push(i);
    for (let i = 0; i <= 23; i++) pollSettings.hours.push(i);
    for (let i = 0; i <= 59; i++) pollSettings.minutes.push(i);
  }

  addPollOption() {
    if (this.postbox.pollOptions.length < maxPollOptions) {
      this.postbox.pollOptions.pushObject(EmberObject.create({ text: '', hasPhoto: false }));

      next(this, () => {
        const el = document.querySelector('.postbox_poll_options');
        el.scrollTo(0, el.scrollHeight);
      });
    }

    this.validatePoll();
  }

  handlePhoto({ file, index }) {
    this.currentUploadIndex = index;

    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    // handling non-image files
    if (!fuHelper.isImageFileTypeSupported(file.type)) {
      return;
    }

    // checking file size
    if (file && file.size >= fileUploadLimit) {
      fuHelper.showDefaultFileErrorMessage();
      return;
    }

    // we need some id to identify image before and after upload
    file.uploadId = MathUtils.generateId();
    file.loading = true;

    // don't process - resize gifs, because of bug #SG-8352
    if (file.type.indexOf('image/gif') !== -1 || file.size > photoMaxWeightToResizeInBrowser) {
      this.uploadPhoto(file, file);

      this.previewSubmitData(file);
    } else {
      this.previewSubmitData(file);

      resizeImage(file, {
        maxWidth: photoMaxWidthHeight,
        maxHeight: photoMaxWidthHeight,
      }).then((resizedFile) => {
        // photo after resizing it still too big to upload to server
        if (file.size >= photoMaxWeightToUploadToServer) {
          PS.Pub('open.generic.dialog', {
            title: __('Photo upload error'),
            message: __('Photo should not exceed {limit}', { limit: weight(photoMaxWeightToUploadToServer) }),
          });
          return;
        }

        this.uploadPhoto(file, resizedFile);
      });
    }
  }

  uploadPhoto(originalFile, file) {
    let formData = new FormData();
    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url: EnvironmentUtils.getApiHost() + '/api/v2/photo/po',
      method: 'POST',
      cancelToken: source.token,
      headers: { 'X-CSRF-Token': Session.getCsrfToken() },
      data: formData,
    })
      .then((res) => {
        this.done(originalFile.uploadId, res.data);
      })
      .catch((e) => {
        this.fail(e.response);
      });

    this.jqXHRs[originalFile.uploadId] = source;
  }

  done(uploadId, data) {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    let tempPhotoOption = this.postbox.pollOptions.find((option) => option.photo?.tempId == uploadId);

    // the real id is assigned here
    if (data.id && tempPhotoOption) {
      // always keep track of information about photoBoxId->id pairings
      this.photoBoxIdPhotoIdPairs[uploadId] = data.id;

      if (tempPhotoOption) {
        let photo = tempPhotoOption.photo;
        let options = {
          id: data.id,
          loading: false,
        };

        photo.setProperties(options);
        tempPhotoOption.set('hasPhoto', true);
      }

      delete this.jqXHRs[tempPhotoOption.get('photo.tempId')];

      this.resetFileInput();
      this.validatePoll();
    }
  }

  fail(uploadId, response = {}) {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    if (response.status == 401) return;

    if (response.data && response.data.errorCode === 700) {
      this.postbox.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
      this.abortPendingUploads();
      return;
    }

    let retries = this.retries[uploadId] || 0;

    if (response.data && retries < this.maxRetries) {
      clearTimeout(this.retryTimeouts[uploadId]);
      this.retryTimeouts[uploadId] = setTimeout(() => {
        response.data.submit();
      }, this.retryTimeoutValues[retries]); // progressive timeout
      this.retries[uploadId] = retries + 1;
    } else {
      let photoOption = this.postbox.pollOptions.find((option) => option.photo?.tempId == uploadId);

      if (photoOption) {
        photoOption.photo.setProperties({
          failedToLoad: true,
          requestToRetry: response.data,
        });
      }
    }

    this.resetFileInput();
    this.validatePoll();
  }

  resetFileInput() {
    const pollFileInput = this.postbox.element.querySelector('#fake-upload-photo-poll');
    if (pollFileInput) pollFileInput.value = ''; // reset value so that selecting same file will be noticed by onChange listener
  }

  afterRenderPhoto(photo) {
    const option = this.postbox.pollOptions.objectAt(this.currentUploadIndex);
    option.set('photo', EmberObject.create(photo));
  }

  validatePoll(onSend) {
    const questionText = toServer(this.postbox.pollQuestion, { parseNativeMarkdown: true }).trim();
    const isQuestionValid = !!questionText.length;
    const isOptionsCountValid = this.getFilledOptions().length >= 2;
    const hasPendingUpload = this.postbox.pollOptions.filter((o) => (o.photo ? o.photo.loading : false)).length;

    if (onSend) {
      this.postbox.pollQuestionError = !isQuestionValid;
    }

    //clear or mark option as error
    this.postbox.pollOptions.forEach((option) => {
      //if no text and photo mark as error
      const text = toServer(option.text, { parseNativeMarkdown: true }) || '';

      if (!text.trim() && !option.get('photo.id')) {
        //only when trying to send and there is less than 2 options filled error is true
        option.set('error', onSend ? !isOptionsCountValid : false);
        option.set('isValid', false);
      } else {
        option.set('error', false);
        option.set('isValid', true);
      }
    });

    this.postbox.isPollValid = isQuestionValid && isOptionsCountValid && !hasPendingUpload;
  }

  getFilledOptions() {
    if (!this.postbox.pollOptions || !this.postbox.pollOptions.length) return;

    //get all filled options
    return this.postbox.pollOptions.filter((option) => {
      return (toServer(option.text, { parseNativeMarkdown: true }) || '').trim().length || option.get('photo.id');
    });
  }

  shareController(params) {
    if (this.postbox.isSharing) return;

    this.validatePoll(true);

    if (!this.postbox.isPollValid) return;

    let options = PostCommons.getStandardPostingOptions(
      Object.assign(
        {},
        {
          text: this.postbox.newPostServer,
          poll: {
            question: toServer(this.postbox.pollQuestion, { parseNativeMarkdown: true }),
            options: this.getFilledOptions().map((option) => {
              return {
                text: toServer(option.text, { parseNativeMarkdown: true }),
                imageId: option.get('photo.id'),
              };
            }),
          },
        },
        params
      ),
      this.postbox
    );

    if (
      this.postbox.pollSettings.selectedDays ||
      this.postbox.pollSettings.selectedHours ||
      this.postbox.pollSettings.selectedMinutes
    ) {
      let ends = 0; // in seconds
      if (this.postbox.pollSettings.selectedDays) {
        ends += parseInt(this.postbox.pollSettings.selectedDays, 10) * 60 * 60 * 24;
      }
      if (this.postbox.pollSettings.selectedHours) {
        ends += parseInt(this.postbox.pollSettings.selectedHours, 10) * 60 * 60;
      }
      if (this.postbox.pollSettings.selectedMinutes) {
        ends += parseInt(this.postbox.pollSettings.selectedMinutes, 10) * 60;
      }

      options.poll.duration = ends;
    }

    PostCommons.share(options, this.postbox);
  }

  clear() {
    this.setInitialAttributes();
  }

  abortPendingUploads() {
    Object.keys(this.jqXHRs).forEach((key) => {
      this.jqXHRs[key].cancel();

      if (this.postbox.pollOptions.length) {
        const option = this.postbox.pollOptions.find((o) => o.get('photo.tempId') == key);

        option.setProperties({
          photo: EmberObject.create({ loading: false }),
          hasPhoto: false,
        });
      }
    });

    this.jqXHRs = {};
  }

  removePhoto(index) {
    const option = this.postbox.pollOptions.objectAt(index);
    if (option.get('photo.tempId') && this.jqXHRs[option.get('photo.tempId')]) {
      this.jqXHRs[option.get('photo.tempId')].cancel();
    }
    set(option, 'photo', EmberObject.create({ loading: false }));
    option.set('hasPhoto', false);
  }

  isEmpty() {
    return (this.postbox.pollQuestion || '').trim().length === 0 && this.getFilledOptions().length === 0;
  }
}

export default Poll;
