import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class ChatMessageOptions extends Component {
  @tracked showOptionsPopup = false;

  @tracked dropdownId = '';

  @tracked parent = null;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  retrySending() {
    if (!this.args.message.isSending) {
      this.args.message.isSending = true;
      dispatcher.dispatch('chat', 'retrySending', this.args.thread, this.args.message.preId);
    }
  }

  @action
  replyToMsg() {
    dispatcher.dispatch('chat', 'setThreadReply', this.args.thread, this.args.message);
    this.args.focusTextarea();
  }

  @action
  openOptionsPopup() {
    const className = '.dropdown-opener--chat-options-dropdown';
    const dropdownId = 'chat-options-popup';
    const dropdownElement = document.getElementsByClassName('chat-options-dropdown');

    if (dropdownElement.length > 0) {
      if (dropdownId === dropdownElement[0].getAttribute('data-dropdownid')) {
        return;
      }
    }

    this.showOptionsPopup = true;
    this.parent = this.element.querySelector(className);
    this.dropdownId = dropdownId;
  }

  @action
  closeOptionsPopup() {
    this.dropdownId = '';
    this.parent = null;
    this.showOptionsPopup = false;
  }
}
