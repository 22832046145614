import { debug } from '@ember/debug';
import { capitalize } from '@ember/string';
import CurrentUserStore from 'mewe/stores/current-user-store';
import EventsApi from 'mewe/api/events-api';
import Event from 'mewe/stores/models/event-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { ds } from 'mewe/stores/ds';
import { map } from 'lodash';

const serializer = JSONSerializer.create();

import { maxResultsEventsList } from 'mewe/constants';

let mapBirthdays = (data) => data.events.map((el) => Object.assign({}, el, { name: el.user.name, isBday: true }));
let mapEvents = (data) =>
  data.events.map((el) =>
    el.participation
      ? Object.assign({}, el.event, {
          participationType: el.participation.participationType,
          unreadPostsCount: el.unreadPostsCount || 0,
        })
      : Object.assign({}, el.event)
  );

export const handle = (id, data) => ds.events.for(id).items.pushObjects(serializer.deserializeAll(Event, data));
export const update = (id, data) => ds.events.for(id).setProperties(data);
export const clear = (id) => ds.events.for(id).items.clear();
export const remove = (event) => map(ds.events, (collection) => collection.items.removeObject(event));

export default function (scope, id, since) {
  let fetchType = since ? 'isFetchingMore' : 'isFetching';
  let type = id.split('-')[0];
  let name = 'get' + capitalize(type) + 'Events';
  let request = EventsApi[name];

  const params = { maxResults: maxResultsEventsList };

  // since may be collection.length (for birthdays) or collection.lastItem.startDate (for other collections of events)
  //start date is no longer supported, now we have nextPage param with full url to call
  if (since) {
    if (id === 'birthdays-events' || type === 'birthdays') {
      params.offset = since;
    } else {
      let nextPage = ds.events.for(id).get('nextPage');

      if (nextPage) {
        request = EventsApi.loadNextPage;
        params.nextPage = nextPage.href;
      } else {
        //no next page so loading more is pointless
        console.log(`fetching more events - no nextPage | type: ${type}`);
        return;
      }
    }
  }

  if (typeof request !== 'function') {
    debug('Api for fetching events is undefined: ' + name);
  }

  update(id, { [fetchType]: true, id: id });

  CurrentUserStore.getState().deferred.promise.then(() => {
    request.call(EventsApi, scope, params).then((data) => {
      if (!since) clear(id);

      handle(id, type == 'birthdays' ? mapBirthdays(data) : mapEvents(data));

      update(id, { [fetchType]: false });

      if (data._links && data._links.nextPage) {
        update(id, { nextPage: data._links.nextPage });
      } else {
        update(id, { nextPage: null });
      }

      update(id, { canShowMore: data.events.length !== 0 });
    });
  });
}
