import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default class MwDropdownMenu extends Component {
  @action
  onMouseLeave() {
    if (this.args.trigger === 'mouseEnter') {
      this.args.closeDropdown?.(true);
    }
  }

  @computed(
    'args.rightTranslateMenu',
    'args.leftTranslateMenu',
    'args.topTranslateMenu',
    'args.bottomTranslateMenu',
    'args.ignoreRTL'
  )
  get translateMenu() {
    let isRtl = document.dir,
      rightText,
      leftText,
      topText = '';

    if (isRtl && !this.args.ignoreRTL) {
      rightText = 'left';
      leftText = 'right';
    } else {
      rightText = 'right';
      leftText = 'left';
    }

    if (this.args.topTranslateMenu) {
      topText = `top: ${this.args.topTranslateMenu};`;
    } else if (this.args.bottomTranslateMenu) {
      topText = `bottom: ${this.args.bottomTranslateMenu};`;
    }

    if (this.args.rightTranslateMenu) {
      return htmlSafe(`${topText} ${rightText}: ${this.args.rightTranslateMenu};`);
    } else if (this.args.leftTranslateMenu) {
      return htmlSafe(`${topText} ${leftText}: ${this.args.leftTranslateMenu};`);
    }

    return htmlSafe(`${topText}`);
  }

  @action
  onDestroy() {
    super.willDestroy(...arguments);
    if (this.args.dropdownMenuOpened) {
      this.args.closeDropdown?.();
    }
  }
}
