import Model from 'mewe/utils/store-utils/model/model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import Contact from 'mewe/stores/models/contact-model';

export const Media = Model.extend({
  mediaId: attr('string'),
  mediaType: attr('string'),
  thumbnailId: attr('string'),
  _links: attr('object'),
});

Media.reopenClass({
  resourceName: 'entry-media',
});

var model = Model.extend({
  id: attr('string'),
  scope: attr('string'),
  media: belongsTo(Media),
  isNew: attr('boolean'),
  createdAt: attr('number'),
  totalUniqueViews: attr('number'),
  byUser: belongsTo(Contact),
});

model.reopenClass({
  resourceName: 'entry',
  aliases: {
    storyId: 'id',
  },
});

export default model;
