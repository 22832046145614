import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import { each } from 'lodash';
import PostApi from 'mewe/api/post-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { FeedTypes } from 'mewe/constants';
import { getFeed } from 'mewe/fetchers/fetch-feed';
import { getAnalyticsFeed } from 'mewe/fetchers/fetch-page-analytics';

export default class MwFeature extends Component {
  @service dynamicDialogs;

  @computed('args.post.eventId', 'args.post.eventInfo', 'args.post.group.id', 'args.post.pageId')
  get feedType() {
    if (this.args.post.eventId && !this.args.post.eventInfo) return FeedTypes.EVENT;
    else if (this.args.post.group && this.args.post.group.id !== 'contacts') return FeedTypes.GROUP;
    else if (this.args.post.pageId) return FeedTypes.PAGE;
    else return FeedTypes.CONTACT;
  }

  @computed('feedType', 'args.post.featured')
  get title() {
    if (this.args.post.featured) return __('Unpin a post');

    switch (this.feedType) {
      case FeedTypes.EVENT:
        return __(
          "Pin a post to the top of the event feed."
        );
      case FeedTypes.GROUP:
        return __(
          "Pin a post to the top of the group feed."
        );
      case FeedTypes.PAGE:
        return __(
          "Pin a post to the top of the page feed."
        );
      case FeedTypes.CONTACT:
        return __('Pin a post to the top of your news feed.');
    }
  }

  getPostsInCurrentScope() {
    const feedType = this.feedType;
    const post = this.args.post;
    let posts = A();

    switch (feedType) {
      case FeedTypes.PAGE:
        posts.pushObjects(getFeed(post.pageId, feedType).posts);
        posts.pushObjects(getFeed(`wrapper-${post.pageId}`, FeedTypes.WRAPPER).posts);
        posts.pushObjects(getAnalyticsFeed(post.pageId).posts);
        break;

      case FeedTypes.EVENT:
        posts.pushObjects(getFeed(post.eventId, feedType).posts);
        posts.pushObjects(getFeed(`wrapper-${post.eventId}`, FeedTypes.WRAPPER).posts);
        break;

      case FeedTypes.GROUP:
        posts.pushObjects(getFeed(post.groupId, feedType).posts);
        posts.pushObjects(getFeed(`wrapper-${post.groupId}`, FeedTypes.WRAPPER).posts);
        break;

      case FeedTypes.CONTACT: // only in consumer we have featured profile post
        posts.pushObjects(getFeed(post.userId, feedType).posts);
        break;
    }

    return posts;
  }

  getExistingFeatured() {
    return this.getPostsInCurrentScope().filterBy('featured', true);
  }

  confirmToFeature() {
    const featureInfo = this.getExistingFeatured().length
      ? __('Do you want to replace the current pinned post?')
      : this.title;

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Pin Post'),
      message: featureInfo,
      cancelButtonText: __('Cancel'),
      okButtonText: __('Pin It!'),
      onConfirm: () => this.featureToggle(),
    });
  }

  featureToggle() {
    const post = this.args.post;
    const callback = () => this.updateIsFeatured();
    const failure = () => FunctionalUtils.showDefaultErrorMessage();
    const feedType = this.feedType;
    const params = {
      postId: post.postItemId,
      contacts: feedType === FeedTypes.CONTACT || feedType === FeedTypes.CONTACTS,
      groupId: feedType === FeedTypes.GROUP ? post.group.id : null,
      pageId: feedType === FeedTypes.PAGE ? post.pageId : null,
      eventId: feedType === FeedTypes.EVENT ? post.eventId : null,
    };
    if (!post.featured) {
      PostApi.featurePost(params).then(callback).catch(failure);
    } else {
      PostApi.unfeaturePost(params).then(callback).catch(failure);
    }
  }

  updateIsFeatured() {
    const doFeature = !this.args.post.featured;
    let post = this.args.post;

    if (doFeature) {
      // only one pinned post per group/event/profile/timeline, so update the posts that were pinned before this one
      // needs to be null, false won't get updated in feed for some reason when switching between groups and group
      each(this.getExistingFeatured(), (p) => (p.featured = null));
    }

    this.args.setFeatured(doFeature);

    // same post (but as a different object) can live in both group & groups feed, page & pages feed
    const samePosts = this.getPostsInCurrentScope().filterBy('postItemId', post.postItemId);
    each(samePosts, (p) => (p.featured = doFeature));
  }

  @action
  click() {
    if (this.args.post.featured) {
      this.featureToggle();
    } else {
      this.confirmToFeature();
    }

    if (this.args.closePostDropdown) {
      this.args.closePostDropdown();
    }
  }
}
