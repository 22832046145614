import Component from '@glimmer/component';
import dispatcher from 'mewe/dispatcher';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AppStoreProduct extends Component {
  @service purchase;

  @service dynamicDialogs;

  @service router;

  constructor() {
    super(...arguments);

    const previewItemId = this.args.model.previewItemId;

    if (previewItemId?.length) {
      if (this.args.duration && this.args.promocode && previewItemId === 'premium') {
        this.extractPremiumProduct.perform();
      } else {
        dispatcher.dispatch('purchase', 'showItemDetails', previewItemId, { context: 'deep_link' });
      }
    }
  }

  @action
  onClose() {
    this.router.transitionTo({ queryParams: { duration: null, promocode: null } });
  }

  @task
  *extractPremiumProduct() {
    const productBase = yield this.purchase.getItemPromise('premium');
    const key = this.args.duration === 'month' ? 'com.mewe.store.premium.monthly' : 'com.mewe.store.premium.yearly';
    const product = productBase.products.find((product) => product.productId === key);
    const alreadyPurchased = productBase.products.find((product) => product.isPurchased);
    if (alreadyPurchased) {
      this.dynamicDialogs.openDialog('store/store-item-premium-dialog');
    } else {
      dispatcher.dispatch('purchase', 'changeSelectedProduct', productBase, product);
      dispatcher.dispatch('purchase', 'checkout', null, [productBase], {
        promocode: this.args.promocode,
        onClose: this.onClose,
      });
    }
  }
}
