import { assert } from '@ember/debug';
import { A } from '@ember/array';
import Service from '@ember/service';
import api from 'mewe/api/journals-api';
import Storage from 'mewe/shared/storage';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { isSafari } from 'mewe/shared/utils';
import Verbose from 'mewe/utils/verbose';
const log = Verbose({ prefix: '[Journals Service]', color: 'navy', enabled: false }).log;

export default Service.extend({
  seenQueue: A(),

  markSeen({ entryId, tellerId, tellerType }) {
    assert('entryId missing', entryId);
    assert('tellerId missing', tellerId);
    assert('tellerType missing', tellerType);

    this.seenQueue.pushObject({
      storyId: entryId,
      storytellerId: tellerId,
      storytellerType: tellerType,
      viewedAt: Date.now(),
    });

    this.write();
  },

  write() {
    Storage.set(Storage.keys.entriesMarkSeen, JSON.stringify(this.seenQueue.toArray()));
  },

  read() {
    let ret = null;
    try {
      ret = JSON.parse(Storage.get(Storage.keys.entriesMarkSeen));
    } catch (e) {}
    return ret;
  },

  sendSeen() {
    const seen = this.read();

    if (seen && seen.length) {
      api.markEntrySeen({ views: this.read() }).then(this.seenQueue.removeObjects(this.seenQueue)).then(this.write());
    }
  },

  _isPreloadingVideo: {},

  _preloadedVideoBlobs: {},

  _preloadedImages: {},

  getPreloadedVideo(url) {
    return this._preloadedVideoBlobs[url];
  },

  preload(entry, isCurrentUser) {
    if (!entry || !entry.media) return;

    if (!entry || entry.media.mediaType == 'Video') {
      let vidUrl = entry.media._links.media.href.replace('{resolution}', 'auto');

      // for testing locally
      // let vidUrlTest = 'http://localhost:8001/assets/video-test';

      if (this.getPreloadedVideo(vidUrl)) return;

      if (this._isPreloadingVideo[vidUrl]) return;

      this._isPreloadingVideo[vidUrl] = true;

      let req = new XMLHttpRequest();
      req.open('GET', vidUrl, true);
      req.responseType = 'blob';

      req.onload = () => {
        if (req.status === 200) {
          let blob = isSafari() ? new Blob([req.response], { type: 'video/mp4' }) : req.response;

          this._preloadedVideoBlobs[vidUrl] = URL.createObjectURL(blob);
          delete this._isPreloadingVideo[vidUrl];
          log('preload: ' + vidUrl);
          entry.set('entryLoaded', true);
        }
      };

      req.onerror = (error) => {
        log('preload error', vidUrl, error);
        delete this._isPreloadingVideo[vidUrl];
      };

      req.send();

      return;
    } else {
      let host = isCurrentUser ? EnvironmentUtils.getImgHost(true) : '';

      let url = host + entry.get('media._links.media.href').replace('{imageSize}', '1200x1200');

      if (!this._preloadedImages[url]) {
        log('preload: ' + url);

        new Image().src = url;

        this._preloadedImages[url] = true;
      }
    }
  },
});
