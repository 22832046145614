import { A } from '@ember/array';
import { computed } from '@ember/object';
import { gifUrlRegExp, findGifUrl } from 'mewe/utils/gif-utils';

export let modelGifUrls = {
  gifUrls: computed('textServer', function () {
    if (this.textServer) {
      return this.textServer.match(gifUrlRegExp);
    }

    return A();
  }),

  isGif: computed('textServer', function () {
    return !!findGifUrl(this.textServer);
  }),
};
