import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

import PagesApi from 'mewe/api/pages-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { isDefined } from 'mewe/utils/miscellaneous-utils';

export default class AppPageSettingsNotifications extends Component {

  @alias('args.model.appPage.pageId') pageId;

  @tracked notificationType;
  @tracked notificationInterval;
  @tracked isSaving;
  @tracked loaded;

  constructor() {
    super(...arguments);

    PagesApi.getNotificationsInterval(this.pageId).then((data) => {
      if (isDefined(data.period)) {
        if (data.period) {
          this.notificationType = 'some';
          this.notificationInterval = data.period;
        } else {
          this.notificationType = 'all';
        }
      } else {
        this.notificationType = 'off';
      }

      next(this, () => {
        this.loaded = true;
      });
    });
  }

  @computed('isSaving', 'loaded')
  get isPending() {
    return this.isSaving || !this.loaded;
  }

  @computed('isPending', 'notificationType')
  get isPendingOrInactive() {
    return this.isPending || this.notificationType !== 'some';
  }

  saveSetting() {
    /**
     * interval in ms, 0 for 'ALL' and no interval param if notifications 'OFF'
     * 4h: 14400000, 12h: 43200000, 24h: 83400000
     */
     if (!this.loaded) return;

     if (this.notificationType === 'some' && !this.notificationInterval) {
       this.notificationInterval = 14400000;
     }

     const params = {};

     if (this.notificationType !== 'off') {
       params.period = this.notificationType === 'some' ? this.notificationInterval : 0;
     }

     this.isSaving = true;

     PagesApi.setNotificationsInterval(this.pageId, params).then(() => {
       this.isSaving = false;
       FunctionalUtils.info(__('Page Settings have been successfully saved.'));
     });
  }

  @action
  notificationTypeChange(value) {
    this.notificationType = value;
    this.saveSetting();
  }

  @action
  notificationIntervalChange(value) {
    this.notificationInterval = value;
    this.saveSetting();
  }
}