import GroupStore from 'mewe/stores/group-store';
import { ds } from 'mewe/stores/ds';
import { Theme } from 'mewe/constants';
import { isFeatureHintValid } from 'mewe/utils/feature-hint';
import { later } from '@ember/runloop';
import { loadDarkTheme } from 'mewe/utils/miscellaneous-utils';

export const openGroupFtueDialog = (group, dynamicDialogs) => {
  dynamicDialogs.openDialog('group-initialisation-dialog', { group: group, doNotEscapeClose: true });
};

export const openFtueRecommendations = (dynamicDialogs, origin, callback) => {
  dynamicDialogs.openDialog('discover-interests-dialog', {
    origin: origin,
    useDarkTheme: origin === 'ftue',
    doNotEscapeClose: origin === 'ftue',
    callback,
  });
};

export const openPostbox = (params, dynamicDialogs) => {
  // we used to open postbox with preselected group by just passing groupId
  if (params.groupId && !params.preselectedGroup) {
    params.preselectedGroup = GroupStore.getState({ id: params.groupId });
  }

  if (params.eventId && !params.preselectedEvent) {
    params.preselectedEvent = ds.events.for('all-events').items.find((i) => i.id === params.eventId);
  }

  dynamicDialogs.openDialog('postbox-dialog', params);
};

export const showGroupJoinedDialog = (dynamicDialogs, group) => {
  dynamicDialogs.openDialog('group-joined-dialog', {
    group,
  });
};

export const showNewToMeWePopup = (dynamicDialogs) => {
  dynamicDialogs.openDialog('new-to-mewe-dialog');
};

// FTUE should be started on first Home feed visit after registration,
// but it's postponed if case there is Group Preview dialog displayed on first opening.
// In such case FTUE is started after Group Preview dialog is closed or until second visit
// to Home feed (if user joined group in the dialog and was redirected to group feed).
export const startFtue = (dynamicDialogs) => {
  // this load is run also during app initialisation so probably it's already loaded
  loadDarkTheme().then(() => {
    // timeout is needed because ftue is triggered during route transitions and we
    // need to wait at least fot the location to be changed because it's checked here
    later(
      this,
      () => {
        // show Premium Trial and whole FTUE only on Myworld page
        if (!~window.location.pathname.indexOf('/myworld')) {
          return;
        }

        isFeatureHintValid('premium-new-user-ad').then(({ timesViewed }) => {
          if (timesViewed === 0) {
            // isFtueFlow needs to be present to open recommendations dialog after premium promotion
            dynamicDialogs.openDialog('store/store-item-premium-dialog', {
              context: 'onboarding',
              isFtueFlow: true,
              useDarkTheme: true,
            });
          }
        });
      },
      500
    );
  });
};

export const showGroupPreviewDialog = (dynamicDialogs, group, options = {}) => {
  dynamicDialogs.openDialog('group-preview-dialog', {
    groupFromParent: group,
    closeBtnText: __(`Got it!`),
    options,
    dontHideLoader: true,
    theme: Theme.CONTACTS,
  });
};
