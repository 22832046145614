import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { each, pick } from 'lodash';
import './crop-box';
import cropBoxAction from './event';
import { getElHeight, getElWidth } from 'mewe/utils/elements-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';

// :: Event -> Touch
let touch = (e) => (e.touches ? e.touches[0] : null);

// :: Number 0.340000000 -> Number 0.34
let round = (num) => +(Math.round(num + 'e+2') + 'e-2');

export default class MwCropper extends Component {
  @tracked cropBox = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    ratio: this.ratio,
    wrapper: null,
  };
  @tracked ratio;
  @tracked width;
  @tracked height;
  @tracked action;
  @tracked startX;
  @tracked startY;
  @tracked endX;
  @tracked endY;
  element;

  constructor() {
    super(...arguments);
    this.setSizesBind = this.setSizes.bind(this);
    this.ratio = this.args.ratio;
    window.addEventListener('resize', this.setSizesBind);
  }

  @computed('args.src', 'width', 'height')
  get style() {
    let src = this.args.src;
    let width = parseInt(this.width, 10);
    let height = parseInt(this.height, 10);

    if (!width || !height) return htmlSafe('');

    return htmlSafe(`
      background-image: url(${src});
      width: ${width}px;
      height: ${height}px;
    `);
  }

  @action
  initializeCropper(element) {
    this.element = element;
    let image = new Image();

    image.onload = () => {
      if (this.isDestroying || this.isDestroyed) return;

      this.imageWidth = this.args.canvasWidth || image.width;
      this.imageHeight = this.args.canvasHeight || image.height;

      this.setSizes();

      this.args.setLoading(false);

      this.onload = null;
      image = null;
      this.getCropperData();
    };

    image.src = this.args.src;

    // mouseMove and mouseUp build into component work only on root component element
    this.mouseMoveBind = this._mouseMove.bind(this);
    this.mouseUpBind = this._mouseUp.bind(this);
    this.mouseDownBind = this._mouseDown.bind(this);

    this.element.addEventListener('mousemove', this.mouseMoveBind);
    this.element.addEventListener('mouseup', this.mouseUpBind);
    this.element.addEventListener('mousedown', this.mouseDownBind);

    // for mobile view, operate on touches
    this.element.addEventListener('touchmove', this.mouseMoveBind);
    this.element.addEventListener('touchend', this.mouseUpBind);
    this.element.addEventListener('touchstart', this.mouseDownBind);

    // create all events by defining their behaviour - how box should change when user move the mouse?
    this.all = {
      width: 'dontChange',
      height: 'dontChange',
      left: 'increase',
      top: 'increase',
      wrapper: this,
    };
    this.w = {
      width: 'decrease',
      height: 'auto',
      left: 'increase',
      top: 'auto',
      wrapper: this,
    };
    this.n = {
      width: 'auto',
      height: 'decrease',
      left: 'auto',
      top: 'increase',
      wrapper: this,
    };
    this.e = {
      width: 'increase',
      height: 'auto',
      left: 'dontChange',
      top: 'auto',
      wrapper: this,
    };
    this.s = {
      width: 'auto',
      height: 'increase',
      left: 'auto',
      top: 'dontChange',
      wrapper: this,
    };
    this.nw = {
      width: 'decrease',
      height: 'decrease',
      left: 'increase',
      top: 'increase',
      wrapper: this,
    };
    this.ne = {
      width: 'increase',
      height: 'decrease',
      left: 'dontChange',
      top: 'increase',
      wrapper: this,
    };
    this.se = {
      width: 'increase',
      height: 'increase',
      left: 'dontChange',
      top: 'dontChange',
      wrapper: this,
    };
    this.sw = {
      width: 'decrease',
      height: 'increase',
      left: 'increase',
      top: 'dontChange',
      wrapper: this,
    };
  }

  setSizes() {
    let compW = getElWidth(this.element),
      compH = getElHeight(this.element),
      cropWrapW,
      cropWrapH,
      r = this.ratio;

    // only if one size of image is higher than cropper size
    if (this.imageWidth > compW || this.imageHeight > compH) {
      // calculate cropper size so whole image will fit
      if (this.imageWidth / this.imageHeight > compW / compH) {
        cropWrapW = compW;
        cropWrapH = this.imageHeight / (this.imageWidth / compW);
      } else {
        cropWrapH = compH;
        cropWrapW = this.imageWidth / (this.imageHeight / compH);
      }
    } else {
      cropWrapW = this.imageWidth;
      cropWrapH = this.imageHeight;
    }

    this.width = cropWrapW;
    this.height = cropWrapH;
    this.originalImageResizeRatio = this.imageWidth / cropWrapW;

    // set the cropBox size - it should be maximum width but keeping the ratio

    let cropBoxWidth = cropWrapH / cropWrapW > r ? cropWrapW : cropWrapH / r;
    let cropBoxHeight = cropWrapH / cropWrapW > r ? cropWrapW * r : cropWrapH;
    this.cropBox = {
      ...this.cropBox,
      width: cropBoxWidth,
      height: cropBoxHeight,
      left: (cropWrapW - cropBoxWidth) / 2,
      top: (cropWrapH - cropBoxHeight) / 2,
      ratio: r,
      wrapper: this,
    };
  }

  getVectorRange(range, ratio) {
    var directionX = range.x > 0 ? 1 : -1;
    var directionY = range.y > 0 ? 1 : -1;

    var rX = (Math.abs(range.x) + Math.abs(range.y)) / 2;
    var rY = range.y === 0 ? 0 : rX * ratio;

    return {
      x: directionX * rX,
      y: directionY * rY,
    };
  }

  _mouseDown(e) {
    this.action = e.target.getAttribute('data-action');

    const t = touch(e);

    this.startX = t ? t.pageX : e.pageX;
    this.startY = t ? t.pageY : e.pageY;
  }

  _mouseMove(e) {
    const action = this.action;

    if (action) {
      let t = touch(e);

      this.endX = t ? t.pageX : e.pageX;
      this.endY = t ? t.pageY : e.pageY;

      var range = {
        x: round(this.endX - this.startX),
        y: round(this.endY - this.startY),
      };

      if (!range.x && !range.y) return;

      switch (action) {
        case 'all':
        case 'e':
        case 'n':
        case 'w':
        case 's':
          this.cropBox = { ...this.cropBox, ...cropBoxAction.move(this[action], range) };
          break;

        case 'ne':
        case 'nw':
        case 'sw':
        case 'se':
          this.cropBox = {
            ...this.cropBox,
            ...cropBoxAction.move(this[action], this.getVectorRange(range, this.ratio)),
          };
          break;
      }

      this.startX = this.endX;
      this.startY = this.endY;
    }
  }

  _mouseUp(e) {
    this.action = null;
    e.preventDefault();
    this.getCropperData();
  }

  getCropperData() {
    let properties = pick(this.cropBox, ['width', 'height', 'top', 'left']);
    this.args.onCrop(
      each(properties, (v, k) => (properties[k] = parseInt(v * this.originalImageResizeRatio, 10))),
      this.getBlob()
    );
  }

  getBlob (callback) {
    var canvas = this.element.querySelector('#canvas');
    var ctx = canvas.getContext('2d');

    var img = this.element.querySelector('.img-wrapper img');

    let properties = pick(this.cropBox, ['width', 'height', 'top', 'left']);
    let crop = each(properties, (v, k) => (properties[k] = parseInt(v * this.originalImageResizeRatio, 10)));

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(img, crop.left, crop.top, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return canvas.toDataURL();
  }

  @action
  destroyCropper() {
    this.element.removeEventListener('mousemove', this.mouseMoveBind);
    this.element.removeEventListener('mouseup', this.mouseUpBind);
    this.element.removeEventListener('mousedown', this.mouseDownBind);

    this.element.removeEventListener('touchmove', this.mouseMoveBind);
    this.element.removeEventListener('touchend', this.mouseUpBind);
    this.element.removeEventListener('touchstart', this.mouseDownBind);

    window.removeEventListener('resize', this.setSizesBind);
  }
}
