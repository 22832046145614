import { htmlSafe } from '@ember/template';
import { each } from 'lodash';
/*
  Pinterest like grid

  options:
    columnsCount
    columnWidth
    gapSize
*/

export default class MasonryLayout {
  constructor(items, options) {
    this.options = options;
    this.items = this.makeColumnsPhotoGrid(items);
  }

  makeColumnsPhotoGrid(items) {
    let columnsInRow = 0,
      firstRowColumns = 0;

    each(items, (item, i) => {
      item.position = {};

      if (firstRowColumns < this.options.columnsCount) {
        item.position.top = 0;
        item.position.left = this.setLeftPosition(item, firstRowColumns);
        item.position.bottom = this.getItemHeightFromRatio(item);
        this.setItemPositionStyle(item);
        firstRowColumns += 1;
      } else {
        if (columnsInRow < this.options.columnsCount) {
          item.position.number = columnsInRow;
          columnsInRow += 1;
        } else if (columnsInRow === this.options.columnsCount) {
          item.position.number = 0;
          columnsInRow = 1;
        }

        this.setItemPosition(item, items, i);
      }
    });

    return items;
  }

  processNewItems(newItems) {
    const lastOldItem = this.items[this.items.length - 1];
    let columnsInRow;

    each(newItems, (item, i) => {
      item.position = {};

      if (i === 0) {
        item.position.number =
          lastOldItem.position.number === this.options.columnsCount - 1 ? 0 : lastOldItem.position.number + 1;

        if (item.position.number === this.options.columnsCount) columnsInRow = 0;
        else columnsInRow = item.position.number + 1;
      } else {
        if (columnsInRow < this.options.columnsCount) {
          item.position.number = columnsInRow;
          columnsInRow += 1;
        } else if (columnsInRow === this.options.columnsCount) {
          item.position.number = 0;
          columnsInRow = 1;
        }
      }

      this.setItemPosition(item, this.items, this.items.length);
      this.items.push(item);
    });

    return newItems;
  }

  setItemPosition(item, items, topNumber) {
    item.position.top = this.setTopPosition(items, topNumber);
    item.position.left = this.setLeftPosition(item);
    item.position.bottom = item.position.top + this.getItemHeightFromRatio(item);
    this.setItemPositionStyle(item);
  }

  setItemPositionStyle(item) {
    item.positionStyle = htmlSafe(
      'top: ' +
        (item.position.top || 0) +
        'px; left: ' +
        (item.position.left || 0) +
        'px; height: ' +
        Math.round(this.getItemHeightFromRatio(item)) +
        'px;'
    );
  }

  setLeftPosition(item, number) {
    item.position.left = (this.options.columnWidth + this.options.gapSize) * (number ? number : item.position.number);
    return item.position.left;
  }

  setTopPosition(items, oldItemCounter) {
    return (
      items[oldItemCounter - this.options.columnsCount].position.top +
      this.getItemHeightFromRatio(items[oldItemCounter - this.options.columnsCount]) +
      this.options.gapSize
    );
  }

  getItemHeightFromRatio(image) {
    const ratio = parseInt(image.height, 10) / parseInt(image.width, 10);
    return this.options.columnWidth * ratio;
  }
}
