/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MwAboutHeader extends Component {
  @service storyblok;
  @service settings;

  get isDarkThemeEnabled() {
    return this.settings.isDarkThemeEnabled;
  }

  get headerText() {
    return this.storyblok.richTextResolver.render(this.args.blok.headerText);
  }
}
