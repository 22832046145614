import layout from './template.hbs';
import './styles.scss';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getOffset } from 'mewe/utils/elements-utils';

export default class HeaderEventsDropdown extends PopupOpener {
  @tracked isEventPickerOpened;

  layout = layout;

  popupType = 'dropdown-popup';

  closeOnHoverOutside = true;

  delayUntilOpen = 200;

  get dropdownPosition() {
    let position = this.getDefaultDropdownOptions();
    const parentOffset = getOffset(this.parent);

    Object.assign(position, {
      margin: 10,
      reposition: { elementWidth: 300 },
      parentOffset: { top: this.parent.offsetTop, left: parentOffset.left },
      placement: { top: false, center: true, positionType: 'fixed' },
    });

    return position;
  }

  @action
  toggleEventPicker() {
    this.isEventPickerOpened = !this.isEventPickerOpened;
  }
}
