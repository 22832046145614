import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { computed, action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { next } from '@ember/runloop';

import ChatApi from 'mewe/api/chat-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { chatMaxUsers } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class MwChatInfo extends Component {
  @tracked blockReportDropdownOpened;
  @tracked chatInfoMembersActive;
  @tracked isSavingChatSettings;
  @tracked mediaOpened;
  @tracked allowAddParticipants;
  @tracked allowSeeHistory;

  @reads('args.thread') thread;

  @service chat;
  @service account;
  @service dynamicDialogs;

  constructor() {
    super(...arguments);

    addObserver(this, 'thread.id', this.threadChanged);
    addObserver(this, 'args.chatInfoActive', this.chatInfoToggled);
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  willDestroy() {
    removeObserver(this, 'thread.id', this.threadChanged);
    removeObserver(this, 'args.chatInfoActive', this.chatInfoToggled);
  }

  threadChanged() {
    if (this.args.chatInfoActive) {
      this.chatSettingsLoaded = false;
      this.fetchMultiuserChatSettings();
    }
  }

  chatInfoToggled() {
    if (this.args.chatInfoActive) {
      this.fetchMultiuserChatSettings();
    }

    this.mediaOpened = '';
  }

  @computed(
    'thread.isGroupChat',
    'thread.group.canInvite',
    'thread.isEventChat',
    'thread.event.canInvite',
    'thread.isMultiUsersChat',
    'thread.isMultiUsersChatOwner',
    'allowAddParticipants'
  )
  get optionsButtonsDisabled() {
    if (this.thread?.isGroupChat) return !this.thread?.group?.canInvite;
    if (this.thread?.isEventChat) return !this.thread?.event?.canInvite;
    if (this.thread?.isMultiUsersChat) return !this.thread?.isMultiUsersChatOwner && !this.allowAddParticipants;
  }

  @computed('thread.{isGroupChat,isEventChat,isMultiUsersChat}')
  get chatInviteText() {
    if (this.thread?.isGroupChat) return __('Invite Group Members');
    else if (this.thread?.isEventChat) return __('Invite to Event');
    else if (this.thread?.isMultiUsersChat) return __('Add to Chat');
  }

  @computed('args.{chatInfoMembersActive,chatInfoMediaActive}')
  get cssHiddenOverflow() {
    return this.args.chatInfoMembersActive || this.args.chatInfoMediaActive;
  }

  fetchMultiuserChatSettings() {
    if (!this.thread?.isMultiUsersChat || this.chatSettingsLoaded) return;

    ChatApi.getMultiuserChatSettings(this.thread?.id).then((data) => {
      this.allowAddParticipants = data.allowAddParticipants;
      this.allowSeeHistory = data.allowSeeHistory;
      next(() => (this.chatSettingsLoaded = true));
    });
  }

  @action
  setMultiuserChatSettings() {
    if (!this.chatSettingsLoaded || !this.thread?.isMultiUsersChatOwner || this.isSavingChatSettings) return;

    this.isSavingChatSettings = true;

    // postponed in order to have input value updated first
    next(() => {
      ChatApi.setMultiuserChatSettings(this.thread?.id, {
        allowSeeHistory: this.allowSeeHistory,
        allowAddParticipants: this.allowAddParticipants,
      })
        .then(() => {
          FunctionalUtils.info(__('Chat Settings have been successfully saved.'));
        })
        .catch(() => {
          FunctionalUtils.showDefaultErrorMessage();
        })
        .finally(() => {
          this.isSavingChatSettings = false;
        });
    });
  }

  addUsersToChat(users) {
    ChatApi.addUsersToChat(this.thread?.id, { receivers: users.map((u) => u.id) }).catch((data) => {
      if (data && data.data && data.data.errorCode === 800) {
        FunctionalUtils.error(
          __(`Can't create thread for more than {chatMaxUsers} participants.`, { chatMaxUsers: chatMaxUsers })
        );
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    });
  }

  @action
  openChatInfoMembers() {
    this.element.scrollTop = 0;
    this.args.openChatInfoMembers();
  }

  @action
  openChatInfoMedia() {
    this.element.scrollTop = 0;
    this.mediaOpened = 'photos';
    this.args.openChatInfoMedia();
  }

  @action
  openMediaTab(tab) {
    this.mediaOpened = tab;
  }

  @action
  openInvite() {
    const self = this;

    if (this.optionsButtonsDisabled) return;

    if (this.thread?.isGroupChat) {
      this.dynamicDialogs.openDialog('invitations-dialog', { group: this.thread?.group });
    } else if (this.thread?.isEventChat) {
      dispatcher.dispatch('events', 'openInvitationDialog', this.thread?.event);
    } else {
      this.dynamicDialogs.openDialog('invitations-dialog', {
        isContactsPicker: true,
        isInvitationToChat: true,
        preSelectedUsers: this.thread?.observableOthers,
        title: __('Add to Chat'),
        saveText: __('Done'),
        submitAction: function (users) {
          if (users.length) self.addUsersToChat(users);
          this.close();
        },
      });
    }
  }

  @action
  hideChat() {
    this.chat.hideChatConfirm({
      thread: this.thread,
      isChatWindow: this.args.skin === 'chat-window',
      isChatRequestsPage: this.args.isChatRequestsPage,
    });
  }

  @action
  showBlockReportDropdown() {
    this.blockReportDropdownOpened = true;
  }

  @action
  blockUser() {
    this.chat.blockUser(this.thread);
  }

  @action
  reportUser() {
    dispatcher.dispatch('contact', 'reportUser', EmberObject.create(this.thread?.getUserChatParticipant));
  }

  @action
  reportGroup() {
    dispatcher.dispatch('group', 'reportGroup', { group: this.thread?.group });
  }

  @action
  leaveGroup() {
    dispatcher.dispatch('group-member', 'removeMember', this.account.activeUser, this.thread?.group);
  }

  @action
  leaveEvent() {
    dispatcher.dispatch('events', 'confirmLeavingEvent', {
      eventId: this.thread?.event?.id,
      isChatWindow: this.args.skin === 'chat-window',
    });
  }

  @action
  deleteChat() {
    this.chat.deleteChatConfirm({
      thread: this.thread,
      isChatWindow: this.args.skin === 'chat-window',
      isChatRequestsPage: this.args.isChatRequestsPage,
    });
  }

  @action
  leaveChat() {
    this.chat.leaveChatConfirm({
      thread: this.thread,
      isChatWindow: this.args.skin === 'chat-window',
      isChatRequestsPage: this.args.isChatRequestsPage,
    });
  }

  @action
  doFollow() {
    let other = this.thread.notContact;

    if (!other) return;

    this.chat.doFollow(other, () => {
      let notContact = this.thread.notContact;

      if (notContact) {
        let reminder = get(notContact, 'reminder');

        if (reminder) {
          set(reminder, 'remindDelay', true);
        } else {
          set(notContact, 'reminder', {
            remindSubscr: true,
            remindDelay: true,
          });
        }
      }
    });
  }
}
