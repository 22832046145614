import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { each } from 'lodash';
import { A } from '@ember/array';
import EmberObject, { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

import PhotosApi from 'mewe/api/photos-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { Theme } from 'mewe/constants';
import { openPostbox } from 'mewe/utils/dialogs-common';

// scopes: Theme.PROFILE/GROUP/EVENT/MYCLOUD -> group.id, event.id, userId

export default class MwPhotoAlbums extends Component {
  @service dynamicDialogs;
  @service account;

  @tracked albums = A();
  @tracked isLoading = false;
  @tracked isMyAlbums;

  maxResults = 30;

  constructor() {
    super(...arguments);

    this.globals = getOwner(this).lookup('globals:main');
    this.scrolling = Scrolling();

    const scope = this.args.scope;
    const isMyAlbums =
      scope === Theme.MYCLOUD ||
      ((scope === Theme.CONTACTS || scope === Theme.PROFILE) && this.args.userId === this.account.activeUser.id);

    this.isMyAlbums = isMyAlbums;
    // in event or group there might be user's photos in the album, check is made only after album photos are fetched with ownPicsOnly=1
    this.canShowOptions = isMyAlbums || scope === Theme.EVENT || scope === Theme.GROUP || this.args.page?.isOwnerAdmin;

    this.reRenderViewBind = this.reRenderView.bind(this);

    addObserver(this, 'args.order', this, this.reRenderViewBind);

    PS.Sub('photoAlbums.reload', this.reRenderViewBind);
    PS.Sub('album.shared', this.reRenderViewBind);

    this.renderView();
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.unbindScroll();
    removeObserver(this, 'args.order', this, this.reRenderViewBind);
    PS.Unsub('photoAlbums.reload', this.reRenderViewBind);
    PS.Unsub('album.shared', this.reRenderViewBind);
  }

  renderView(nextPageLink) {
    const params = {
      nextPage: nextPageLink,
      limit: this.maxResults,
      order: this.args.order || 0,
    };

    this.isLoading = true;

    const thenCb = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      let photoAlbums = A();

      each(data.feed, (a) => {
        // count is 0 if album contains only photos from blocked user that can't be viewed, don't show such album
        if (a.count === 0) return;

        let album = EmberObject.create(a); // fields from backend: name, count, mediaId, image
        album.isAlbum = true;
        album.canEdit = this.args.scope !== Theme.PROFILE || this.isMyAlbums;
        album.canRemove = this.args.scope === Theme.MYCLOUD;
        album.scope = this.args.scope;

        photoAlbums.push(album);
      });

      this.albums.pushObjects(photoAlbums);

      if (data._links?.nextPage) {
        this.nextPageLink = data._links.nextPage.href;
        this.bindScroll();
      } else {
        this.unbindScroll();
      }
    };

    const finallyCb = () => {
      if (this.isDestroyed || this.isDestroying) return;
      this.isLoading = false;
    };

    PhotosApi.getAlbums(
      this.args.scope,
      this.args.event?.id || this.args.group?.id || this.args.page?.id || this.args.userId,
      params
    )
      .then(thenCb)
      .finally(finallyCb);
  }

  reRenderView() {
    if (this.isLoading) return;

    this.albums = A();
    this.renderView();
  }

  bindScroll() {
    const containerEl = this.element.closest(this.args.containerSelector);

    if (containerEl) {
      this.scrolling.bindScrollDownElement(containerEl, () => this.renderView(this.nextPageLink));
    } else {
      this.scrolling.bindScrollDown(() => this.renderView(this.nextPageLink));
    }
  }

  unbindScroll() {
    this.scrolling.unbindScrollDown(this.element.closest(this.args.containerSelector));
    this.scrolling.unbindScrollDown();
  }

  @action
  openAlbumStream(album) {
    this.args.openAlbumStream(album);
  }

  @action
  createNewAlbum() {
    openPostbox(
      {
        openWithPhotoBrowser: true,
        openWithAlbumDropdown: true,
        myCloudUploading: true,
        theme: this.args.theme,
        target: this.args.scope === Theme.PROFILE ? Theme.CONTACTS : this.args.scope,
      },
      this.dynamicDialogs
    );
  }

  @action
  removeFromCollection(album) {
    this.albums.removeObject(album);
  }
}
