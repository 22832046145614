import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import { htmlSafe } from '@ember/template';
import { bind } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import CalendarUtils from 'mewe/utils/calendar-utils';
import { calendarDateFormatInUtc } from 'mewe/utils/datetime-utils';
import { getElWidth, getOffset } from 'mewe/utils/elements-utils';

export default class MwDatePicker extends Component {

  @service account;

  @tracked date;
  @tracked isOpened;
  @tracked calendarDetails = EmberObject.create();

  @action
  onInsert(element) {
    this.element = element;
    this._clickOutsideListener = bind(this, this.clickOutside);
    this._keyPressedListener = bind(this, this.keyPressed);

    addObserver(this, 'args.utcMillis', this.updateDateDisplay);

    if (!this.date && this.args.utcMillis) {
      this.updateDateDisplay();
    }

    this.account.activeUser.deferred.promise.then(() => this.renderCalendar(null, null, this.args.utcMillis));
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.utcMillis', this.updateDateDisplay);
    this.close();
  }

  get jsLocale() {
    return this.account.activeUser.jsLocale;
  }

  get datePlaceholder() {
    return (
      this.calendarDetails.dateFormat || new Date().toLocaleDateString(this.jsLocale, calendarDateFormatInUtc)
    ).toLowerCase();
  }

  get datePickerPosition() {
    if (this.isOpened) {
      const spaceLeft = window.innerWidth - getOffset(this.element).left;
      const spaceNeeded = getElWidth(this.element.querySelector('.date-picker_box'));

      return htmlSafe(`margin-left: -${spaceNeeded > spaceLeft ? spaceNeeded - spaceLeft + 20 : 0}px`);
    }
  }

  // year/month passed when switching months, date passed for initial calendar render on particular date
  renderCalendar(year, month, utcMillis) {
    this.calendarDetails = CalendarUtils.getMonthDetails({
      year: year,
      month: month,
      utcMillis: utcMillis,
    });
  }

  setDate(date) {
    this.calendarDetails.setProperties({
      year: date.year,
      month: date.month,
    });

    // send action up to update utcMillis
    this.args.dateChanged(date.utcDayStart);

    // use `utcMillis` from parent, `utcDayStart` passed to parent in the `dateChanged` above
    // could be changed if the date was set incorrectly, so here those values can be different already
    this.date = new Date(this.args.utcMillis).toLocaleDateString(this.jsLocale, calendarDateFormatInUtc);
  }

  updateDateDisplay() {
    if (this.args.utcMillis) {
      this.date = new Date(this.args.utcMillis).toLocaleDateString(this.jsLocale, calendarDateFormatInUtc);
    }
  }

  @action
  clickOutside(e) {
    if (!this.element.contains(e.target)) {
      this.close();
    }
  }

  keyPressed(e) {
    // close on Esc or Tab
    if (e.keyCode === 27 || e.keyCode === 9) {
      this.close();
    }
  }

  @action
  close() {
    this.isOpened = false;
    this.args.datePickerOpenedUpdate?.(this.isOpened);
    document.body.removeEventListener('click', this._clickOutsideListener);
    document.body.removeEventListener('keydown', this._keyPressedListener);
  }

  @action
  open() {
    if (this.isOpened) return;
    if (this.args.disabled) return;

    this.isOpened = true;
    this.args.datePickerOpenedUpdate?.(this.isOpened);

    document.body.addEventListener('click', this._clickOutsideListener);
    document.body.addEventListener('keydown', this._keyPressedListener);
  }

  @action
  onFocus(e) {
    this.open(); // focus can come from TAB key, opening datepicker
    e.target.blur(); // but we don't really want to allow real focus in input, only opening picker
  }

  @action
  onDateClick(day) {
    this.setDate(day);
    this.close();
  }

  @action
  setMonth(offset) {
    let newMonth = this.calendarDetails.month + offset;
    let newYear = this.calendarDetails.year;

    if (newMonth === -1) {
      newYear--;
      newMonth = 11;
    } else if (newMonth === 12) {
      newYear++;
      newMonth = 0;
    }

    this.renderCalendar(newYear, newMonth);
  }
}
