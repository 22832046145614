import { action } from '@ember/object';

import layout from './template.hbs';
import './styles.scss';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getWindowHeight, getWindowScrollTop, getOffset } from 'mewe/utils/elements-utils';

export default class TargetsDropdown extends PopupOpener {
  layout = layout;
  popupType = 'dropdown-popup';
  closeOnClickOutside = true;

  get dropdownPosition() {
    let isRtl = document.dir;
    if (this.args.alignRight) {
      isRtl = !isRtl;
    }

    let position = this.getDefaultDropdownOptions(true);

    this.placementTop = !this.canBeBelow();

    Object.assign(position, {
      margin: 5,
      placement: { top: this.placementTop, left: !isRtl },
      translate: { x: this.translateX || -30, y: 8 },
    });

    return position;
  }

  canBeBelow() {
    const dropdownHeight = 200;
    return getOffset(this.args.parent).top + dropdownHeight < getWindowScrollTop() + getWindowHeight();
  }

  @action
  choose(target) {
    this.args.onTargetSelected(target);
  }
}
