import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';

import Scrolling from 'mewe/utils/scrolling-utils';
import dispatcher from 'mewe/dispatcher';

export default class AppEventAttendees extends Component {

  @service router;

  @tracked membersSearch = '';

  @reads('args.model.eventState') state;

  constructor() {
    super(...arguments);

    addObserver(this, 'state.eventAttendees.length', this.bindScrolling);
    addObserver(this, 'router.currentRouteName', this.clearSearch);
  }

  @action
  onInsert(element) {
    this.element = element;
    this.scrolling = Scrolling();
  }

  @action
  onDestroy() {
    dispatcher.dispatch('events', 'resetEventAttendeesData', this.args.model.eventId); // reset attendees data in store
    this.scrolling.unbindScrollDown();

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    removeObserver(this, 'state.eventAttendees.length', this.bindScrolling);
    removeObserver(this, 'router.currentRouteName', this.clearSearch);
  }

  bindScrolling() {
    if (this.state.isFetchingAttendees) {
      this.scrolling.unbindScrollDown();
    } else if (!this.state.noMoreAttendeesResult) {
      this.scrolling.bindScrollDown(() => {
        if (this.membersSearch) {
          this.loadSearchResults(true);
        }

        this.loadUsers(true);
      });
    }
  }

  @computed('state.{isFetchingAttendees,eventAttendees.length}')
  get noResults() {
    return !this.state.isFetchingAttendees && !this.state.eventAttendees.length;
  }

  loadUsers(isLoadMore) {
    dispatcher.dispatch('events', 'loadAttendees', null, isLoadMore, this.args.model.eventId);
  }

  loadSearchResults(isLoadMore) {
    const searchStr = this.membersSearch;

    if (!searchStr || searchStr !== this.lastSearchStr) {
      dispatcher.dispatch('events', 'searchAttendees', searchStr, isLoadMore, this.args.model.eventId);
      this.lastSearchStr = searchStr;
    }
  }

  @action
  openInvitationDialog() {
    dispatcher.dispatch('events', 'openInvitationDialog', this.state.rootEvent);
  }

  @action
  search(term) {
    this.membersSearch = term;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      if (this.membersSearch) {
        this.loadSearchResults();
      } else {
        this.lastSearchStr = '';
        this.loadUsers();
      }

      this.searchTimeout = null;
    }, 500);
  }

  @action
  clearSearch() {
    this.membersSearch = '';
    this.lastSearchStr = '';
    this.loadUsers();
  }
}