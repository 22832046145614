import { A } from '@ember/array';

// http://blog.vjeux.com/2014/image/google-plus-layout-find-best-breaks.html
var dijkstra = {
  single_source_shortest_paths: function (graph, s, d) {
    var predecessors = {};

    var costs = {};
    costs[s] = 0;

    var open = new BinaryHeap(function (x) {
      return x.cost;
    });
    open.push({ value: s, cost: 0 });

    var closest,
      u,
      cost_of_s_to_u,
      adjacent_nodes,
      cost_of_e,
      cost_of_s_to_u_plus_cost_of_e,
      cost_of_s_to_v,
      first_visit;
    while (open.size()) {
      closest = open.pop();
      u = closest.value;
      cost_of_s_to_u = closest.cost;

      adjacent_nodes = graph(u) || {};

      for (var v in adjacent_nodes) {
        cost_of_e = adjacent_nodes[v];

        cost_of_s_to_u_plus_cost_of_e = cost_of_s_to_u + cost_of_e;

        cost_of_s_to_v = costs[v];
        first_visit = typeof costs[v] === 'undefined';
        if (first_visit || cost_of_s_to_v > cost_of_s_to_u_plus_cost_of_e) {
          costs[v] = cost_of_s_to_u_plus_cost_of_e;
          open.push({ value: v, cost: cost_of_s_to_u_plus_cost_of_e });
          predecessors[v] = u;
        }
      }
    }

    if (typeof costs[d] === 'undefined') {
      var msg = ['Could not find a path from ', s, ' to ', d, '.'].join('');
      throw new Error(msg);
    }

    return predecessors;
  },

  extract_shortest_path_from_predecessor_list: function (predecessors, d) {
    var nodes = A();
    var u = d;
    while (u) {
      nodes.push(u);
      u = predecessors[u];
    }
    nodes.reverse();
    return nodes;
  },

  find_path: function (graph, s, d) {
    var predecessors = dijkstra.single_source_shortest_paths(graph, s, d);
    return dijkstra.extract_shortest_path_from_predecessor_list(predecessors, d);
  },
};

function BinaryHeap(scoreFunction) {
  this.content = A();
  this.scoreFunction = scoreFunction;
}

BinaryHeap.prototype = {
  push: function (element) {
    this.content.push(element);
    this.bubbleUp(this.content.length - 1);
  },

  pop: function () {
    var result = this.content[0];
    var end = this.content.pop();

    if (this.content.length > 0) {
      this.content[0] = end;
      this.sinkDown(0);
    }
    return result;
  },

  remove: function (node) {
    var len = this.content.length;
    for (var i = 0; i < len; i++) {
      if (this.content[i] == node) {
        var end = this.content.pop();
        if (i != len - 1) {
          this.content[i] = end;
          if (this.scoreFunction(end) < this.scoreFunction(node)) this.bubbleUp(i);
          else this.sinkDown(i);
        }
        return;
      }
    }
    throw new Error('Node not found.');
  },

  size: function () {
    return this.content.length;
  },

  bubbleUp: function (n) {
    var element = this.content[n];
    while (n > 0) {
      var parentN = Math.floor((n + 1) / 2) - 1,
        parent = this.content[parentN];
      if (this.scoreFunction(element) < this.scoreFunction(parent)) {
        this.content[parentN] = element;
        this.content[n] = parent;
        n = parentN;
      } else {
        break;
      }
    }
  },

  sinkDown: function (n) {
    var length = this.content.length,
      element = this.content[n],
      elemScore = this.scoreFunction(element),
      child1Score;

    while (true) {
      var child2N = (n + 1) * 2,
        child1N = child2N - 1;
      var swap = null;
      if (child1N < length) {
        var child1 = this.content[child1N];
        child1Score = this.scoreFunction(child1);
        if (child1Score < elemScore) swap = child1N;
      }
      if (child2N < length) {
        var child2 = this.content[child2N],
          child2Score = this.scoreFunction(child2);
        if (child2Score < (swap == null ? elemScore : child1Score)) swap = child2N;
      }

      if (swap != null) {
        this.content[n] = this.content[swap];
        this.content[swap] = element;
        n = swap;
      } else {
        break;
      }
    }
  },
};

export default dijkstra.find_path;
