/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class MwTeaser extends Component {
  @tracked asset = this.args.blok.image.filename || '';
  @tracked isVideo = false;
  @tracked videoExt = 'mp4';

  supportedVideos = ['mp4', 'webm', 'avi', 'mov'];

  constructor() {
    super(...arguments);

    this.videoExt = this.asset.split('.').pop();
    this.isVideo = this.supportedVideos.includes(this.videoExt);

    later(() => {
      if (this.isVideo) {
        this.setupAutoplay();
      }
    });
  }

  setupAutoplay() {
    const video = document.getElementById('cmsVideo');

    if (video) {
      video.oncanplaythrough = function () {
        video.muted = true;
        video.play();
      };
    }
  }
}
