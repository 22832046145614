import findPath from './../dijkstra';

const MAXIMUM_IMAGES_IN_ROW = 8;
const SPACING = 4;
const MINIMUM_HEIGHT = 50;

let percentage = (x, y) => (x * 100) / y;
let resizeToHeight = (h, s) => (h * s.width) / s.height;
let minRange = (start, length) => Math.min(start + MAXIMUM_IMAGES_IN_ROW + 1, length);
let toChunks = (ends) => (end, i, starts) => ends.slice(starts[i - 1], end);
let makeKey = (a, b) => [a, a + b + 1].join(',');

export let photosToGrid = (photos, maxHeight, containerWidth) => {
  let cache = {};

  let cost = (slice, makeKey, max) => cache[makeKey] || Math.pow(Math.abs(getRowHeight(slice) - max), 2);

  let getRowHeight = (images) => {
    let height = (containerWidth - (images.length - 1) * SPACING) / reduceRatios(images);
    return height < MINIMUM_HEIGHT ? MINIMUM_HEIGHT : height;
  };

  let reduceRatios = (images) => images.reduce((sum, el) => sum + el.width / el.height, 0);

  let getResizedRows = (arr) => arr.map(getResizedImage(getRowHeight(arr)));

  let getResizedImage = (height) => (image) =>
    Object.assign(image, { resized: { width: inPercent(image, height), height: height } });

  let inPercent = (image, height) => percentage(resizeToHeight(height, image), containerWidth);

  let graph = (photos) => (start) =>
    photos
      .slice(+start, minRange(+start, photos.length))
      .map((image, i, slice) => ({ [+start + i + 1]: cost(slice.slice(0, i + 1), makeKey(+start, i), maxHeight) }))
      .reduce((sum, obj) => Object.assign(sum, obj), { [+start]: 0 });

  return findPath(graph(photos), 0, photos.length).map(toChunks(photos)).map(getResizedRows);
};
