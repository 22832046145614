/* eslint-disable lines-between-class-members */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { A } from '@ember/array';
import { remove } from 'lodash';
import Component from '@glimmer/component';

import CommentsApi from 'mewe/api/comments-api';
import Comment from 'mewe/stores/models/comment-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import FunctionalUtils from 'mewe/shared/functional-utils';
import isUndefined from 'mewe/utils/isUndefined';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { computed } from '@ember/object';

export default class MwComment extends Component {
  @tracked showPagination;
  @tracked canBeMoreAfter;
  @tracked canBeMoreBefore;
  @tracked loadingMoreBefore;
  @tracked loadingMoreAfter;

  @reads('args.comment') comment;

  constructor() {
    super(...arguments);
    this.canBeMoreBefore = true;

    if (this.comment.get('replies.length') === this.comment.repliesCount) {
      this.showPagination = false;
      this.canBeMoreAfter = false;
      this.canBeMoreBefore = false;
    } else if (this.comment.get('replies.length') < this.comment.get('repliesCount')) {
      // fetch replies on init only when there are none yet, otherwise there can be one
      // when opened from notification and no need to fetch without user's action
      if (!this.comment.get('replies.length')) {
        this.getReplies(true);
      }

      if (this.args.isPostDialog || this.args.inMediaDialog) {
        this.canBeMoreAfter = true;
        this.canBeMoreBefore = true;
      } else {
        this.showPagination = true;
        this.canBeMoreAfter = false;
      }
    } else {
      this.canBeMoreAfter = true;
    }
  }

  @computed('args.commentsOpened')
  get commentsOpened() {
    return isUndefined(this.args.commentsOpened) ? true : this.args.commentsOpened;
  }

  getReplies(before) {
    let offset = this.comment.get('replies.length') || 0;
    let options = {
      offset: offset,
      maxResults: 10,
      order: 0,
    };

    if (before && offset) {
      options.beforeId = this.comment.get('replies')[0].id;
      delete options.offset;
    } else if (offset) {
      options.afterId = this.comment.get('replies')[this.comment.get('replies.length') - 1].get('id');
      delete options.offset;
    }

    if (before) {
      this.loadingMoreBefore = true;
    } else {
      this.loadingMoreAfter = true;
    }

    CommentsApi.getReplies(this.comment?.id, options)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        let repliesSerialized = JSONSerializer.create().deserializeMany(A(), Comment, data.comments);
        remove(repliesSerialized, (r) => {
          return this.comment.get('replies').find((cr) => r.id === cr.id);
        });

        if (before) {
          this.comment.get('replies').unshiftObjects(repliesSerialized.reverseObjects());
        } else {
          this.comment.get('replies').pushObjects(repliesSerialized);
        }

        if (this.comment.get('repliesCount') === this.comment.get('replies.length')) {
          this.canBeMoreBefore = false;
          this.canBeMoreAfter = false;
        } else if (data.comments.length < options.maxResults) {
          if (before) {
            this.canBeMoreBefore = false;
          } else {
            this.canBeMoreAfter = false;
          }
        }
      })
      .catch((resp) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (resp && resp.status) {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.loadingMoreBefore = false;
        this.loadingMoreAfter = false;
      });
  }

  @action
  showNext() {
    if (!this.canBeMoreAfter) {
      return;
    }

    this.getReplies(false);
  }

  @action
  showPrevious() {
    if (!this.canBeMoreBefore) {
      return;
    }

    this.getReplies(true);
  }
}
