import Component from '@glimmer/component';
import { localCopy } from 'tracked-toolbox';

export default class MwList extends Component {
  @localCopy('args.bordered', true)
  bordered;

  @localCopy('args.dense', true)
  dense;
}
