import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { each } from 'lodash';

import UserSearch from 'mewe/stores/models/user-search-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import dispatcher from 'mewe/dispatcher';
import { isSimpleClick } from '@ember/-internals/views';
import Storage from 'mewe/shared/storage';
export default class MwSmartSearchUser extends Component {
  element;

  @service chat;
  @service router;
  @service account;
  @service dynamicPopups;

  @tracked user;
  @tracked isEmptyTable;
  @tracked justInvited;
  @tracked isAlreadyInvited;

  constructor() {
    super(...arguments);
    this.setInitialParams();
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  get isNsfwBlurred() {
    const isNsfwAllowed = Storage.get(Storage.keys.allowNsfwContentSetting);
    if (this.user.id === this.account.activeUser.id || isNsfwAllowed) {
      return false;
    }
    return this.user._links.avatar.nsfw;
  }

  setInitialParams() {
    const serializer = JSONSerializer.create();
    const user = this.args.userData;
    let isEmptyTable = true;

    each(
      ['description', 'currentCity', 'job', 'company', 'college', 'highSchool', 'relationshipStatus', 'interests'],
      (element) => {
        if (typeof user[element] !== 'undefined' && user[element].length !== 0) {
          user[element] = user[element].replace(/\r?\n|\r/g, ' ');
          isEmptyTable = false;
        }
      }
    );

    if (isEmptyTable) this.isEmptyTable = true;

    this.user = serializer.deserializeOne(UserSearch, user);

    this.showProfilePopupBind = this.showProfilePopup.bind(this);
  }

  showProfilePopup() {
    if (this.isDestroyed && !this.isDestroying) return;
    if (this.user?.id === this.account.activeUser.id) return;

    const avatar = this.user?._links?.avatar?.href || '';

    this.dynamicPopups.openPopup('mw-profile-popup', {
      parent: this.element.querySelector('.smart-search_user-img'),
      avatar: avatar.replace('{imageSize}', '150x150'),
      owner: this.user,
    });
  }

  @action
  closeSmartSearch(e) {
    if (!isSimpleClick(e)) return;
    e.preventDefault();
    this.args.closeSmartSearch();
    this.router.transitionTo('app.publicid.posts', this.user.profileId);
  }

  @action
  openProfile() {
    this.router.transitionTo('app.publicid.posts', this.user.profileId);
    this.args.closeSmartSearch();
  }

  @action
  chatWith() {
    this.chat.openThreadByParticipants([this.user]);
    this.args.closeSmartSearch();
  }

  @action
  doFollow() {
    // user model is different in SmartSearch - need to do separate update
    const callback = (data) => {
      this.user.setProperties({
        isInvited: !!data.follow?.followRequestId
      });
    };

    dispatcher.dispatch('contact', 'followUser', { user: this.user }, callback);
  }
}
