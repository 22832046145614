import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DocumentOptionsComponent extends PopupOpener {
  @service dynamicDialogs;

  layout = layout;

  popupType = 'dropdown-popup';

  dontCloseAt = '.dropdown--dont-close';

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions(true);

    Object.assign(position, {
      reposition: { elementHeight: 180 },
      placement: {
        top: false,
        left: isRtl,
      },
      translate: { x: -20 },
    });

    return position;
  }

  get canMove() {
    return this.args.doc.canMove && (!this.args.group || this.args.group.isConfirmed);
  }

  @action
  moveFile() {
    this.dynamicDialogs.openDialog('move-file-dialog', {
      group: this.args.group,
      document: this.args.doc,
    });
  }

  @action
  openPostboxWithTarget(target) {
    openPostbox(
      {
        target: target,
        theme: target,
        preselectedFiles: [this.args.doc.post.document],
      },
      this.dynamicDialogs
    );
  }
}
