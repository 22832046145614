import EmberObject from '@ember/object';
import { ActionHandler } from '@ember/-internals/runtime';
//TODO: this need to be updated with run from the runloop module
import { beginPropertyChanges, endPropertyChanges } from '@ember/-internals/metal';
import { A } from '@ember/array';
import Contact from 'mewe/stores/models/contact-model';
import Collection from 'mewe/stores/models/collection';
import { cond, isObject, identity, stubTrue, flip, conforms, isString, stubFalse } from 'lodash';

import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { map } from 'lodash';

const serializer = JSONSerializer.create();

// { k: v }
const state = {};

// :: { id: String } -> [ Contacts ]
const getContacts = (params) => returnOrCreate(state[params.id], params.id);

// :: id: String -> [ Contacts ]
const create = (id) =>
  (state[id] = Collection.create({
    id: id,
    items: A(),
    isFetching: false,
    isFetchingMore: false,
    isInited: false,
  }));

// :: [ Contacts ] -> id: String -> [ Contacts ]
// :: nil -> id: String -> [ Contacts ]
const returnOrCreate = cond([
  [isObject, identity],
  [stubTrue, flip(create)],
]);

// :: { id: String, groupId: String } -> Contact
// :: { id: String } -> Contact
const getState = cond([
  [conforms({ id: isString }), getContacts],
  [stubTrue, stubFalse],
]);

const self = EmberObject.extend(ActionHandler, {
  getState: getState,

  actions: {
    handle: (id, data) => {
      let contacts = getState({ id: id }).get('items'),
        newContacts = serializer.deserializeMany(A(), Contact, data);

      beginPropertyChanges();

      if (id && id.indexOf('-uploaded') > 0) {
        contacts.pushObjects(newContacts);
      } else {
        newContacts.forEach((c) => {
          if (!contacts.find((u) => u.id === c.get('id'))) {
            contacts.pushObject(c);
          }
        });
      }

      endPropertyChanges();
    },

    update: (id, data) => getState({ id: id }).setProperties(data),

    clear: (id) => getState({ id: id }).get('items').clear(),

    remove: (contact) => map(state, (collection) => collection.get('items').removeObject(contact)),

    removeByUserId: (userId) =>
      map(state, (collection) => {
        const contact = collection.get('items').find((i) => i.id === userId);

        if (contact) {
          collection.get('items').removeObject(contact);
        }
      }),
  },
});

export default self.create();
