import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

import 'mewe/services/chat';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { Colors } from 'mewe/constants';

class DownloadingStatus {
  @tracked attempts = 0;
  @tracked intervalMs = 3 * 1000;
  @tracked timeoutId = null;
  @tracked maxAttempts = 4;
}

export default class MwMewePlaybox extends Component {
  @service chat;

  @tracked progressBarStyle;

  constructor() {
    super(...arguments);

    this.downloading = new DownloadingStatus();
    this.getState();
  }

  @action
  onInsert(element) {
    this.element = element;

    this.mediaLoadErrorBind = this.mediaLoadError.bind(this);
    this.element.querySelector('.dc-content').addEventListener('onerror', this.mediaLoadErrorBind);

    if (this.args.message.aType === 'photo') {
      this.imageLoadDoneBind = this.imageLoadDone.bind(this);
      this.element.querySelector('.dc-image').addEventListener('load', this.imageLoadDoneBind);
    } else {
      this.videoLoadDoneBind = this.videoLoadDone.bind(this);
      this.element.querySelector('.dc-video').addEventListener('loadedmetadata', this.videoLoadDoneBind);
    }

    this.args.message.addObserver('playerOpened', this, 'openMessage');
  }

  @action
  onDestroy() {
    this.cancelRetrying();
    this.args.message.removeObserver('playerOpened', this, 'openMessage');

    if (this.args.message.playerOpened) {
      this.finishPlaying();
    }
  }

  @action
  onClick() {
    this.finishPlaying();
  }

  openMessage() {
    if (!this.args.message.playerOpened) return false;

    set(this.args.message, 'openedFrom', this.args.isChat ? 'chatWindow' : 'smallChat');

    if (this.args.message.isLoaded) {
      this.initPlaying();
    } else {
      this.args.message.addObserver('isLoaded', this, 'dcLoadedHandler');
    }
  }

  get isVideo() {
    return this.args.message.aType === 'video';
  }

  get playerOpened() {
    return this.args.message.playerOpened && this.args.message.isLoaded && !this.args.message.loadingError;
  }

  get fullCircuit() {
    const chatSizeType = this.args.chatSizeType;
    if (chatSizeType === 'smallChat') {
      return 640.88; // Math.PI * 2 * radius(102px)
    } else {
      return 992.74; // Math.PI * 2 * radius(158px);
    }
  }

  mediaLoadError() {
    if (this.args.message.playerOpened && !this.errorShown) {
      this.errorShown = true;
      FunctionalUtils.error(__('Something went wrong, please try again later!'));
    }

    this.downloading.timeoutId = window.setTimeout(() => {
      this.retry();
    }, this.downloading.intervalMs);
  }

  imageLoadDone() {
    set(this.args.message, 'isLoaded', true);
    this.cancelRetrying();
  }

  videoLoadDone() {
    if (this.args.message) {
      set(this.args.message, 'isLoaded', true);
    }
    this.cancelRetrying();
  }

  retry() {
    if (this.isDestroying || this.isDestroyed) return;

    if (!this.args.message || this.args.message.isLoaded) return;

    const dcContent = this.element.querySelector('.dc-content');

    let src = dcContent.src;
    if (!src) return;
    let indexOfQueryString = src.indexOf('?');

    if (indexOfQueryString < 0) {
      indexOfQueryString = src.length;
    }

    if (this.downloading.attempts < this.downloading.maxAttempts) {
      dcContent.src = src.substr(0, indexOfQueryString) + '?t=' + new Date().getTime();
      this.downloading.attempts = this.downloading.attempts + 1;
    } else {
      this.cancelRetrying();

      if (this.args.message) {
        set(this.args.message, 'isLoaded', true);
        set(this.args.message, 'loadingError', true);
      }
    }
  }

  cancelRetrying() {
    if (this.isDestroying || this.isDestroyed) return;

    const dcContentEl = this.element.querySelector('.dc-content');

    if (dcContentEl) {
      if (this.args.message.aType === 'photo') {
        dcContentEl.removeEventListener('load', this.imageLoadDoneBind);
      } else {
        dcContentEl.removeEventListener('loadedmetadata', this.videoLoadDoneBind);
        dcContentEl.removeEventListener('ended', this.finishPlayingBind);
        dcContentEl.removeEventListener('timeupdate', this.progressVideoHanderBind);
      }

      dcContentEl.removeEventListener('onerror', this.mediaLoadErrorBind);
    }

    window.clearTimeout(this.downloading.timeoutId);
    this.downloading.timeoutId = null;
  }

  getState() {
    if (this.args.message?.playerOpened) {
      if (
        (this.args.message.openedFrom === 'chatWindow' && !this.isChat) ||
        (this.args.message.openedFrom === 'smallChat' && this.args.isChat)
      ) {
        this.finishPlaying();
      }

      this.cancelRetrying();
      set(this.args.message, 'isLoaded', true);
    }
  }

  dcLoadedHandler() {
    if (this.args.message.isLoaded) {
      this.initPlaying();
      this.args.message.removeObserver('isLoaded', this, 'dcLoadedHandler');
    }
  }

  initPlaying() {
    if (!this.args.message) return false;

    if (this.args.message.loadingError) {
      set(this.args.message, 'playerOpened', false);
      FunctionalUtils.error(__('Something went wrong, please try again later!'));
      return;
    }

    set(this.args.message, 'opened', true);

    if (this.isVideo) {
      const videoEl = this.element?.querySelector('.dc-video');
      try {
        videoEl.play();
      } catch (e) {
        debug(e);
      }

      this.finishPlayingBind = this.finishPlaying.bind(this);
      videoEl.addEventListener('ended', this.finishPlayingBind);

      this.progressVideo(videoEl);
    } else {
      this.progressGif();

      window.setTimeout(() => {
        if (this.args.message.playerOpened) {
          this.finishPlaying();
        }
      }, 6000);
    }
  }

  progressGif() {
    let i = 2;

    this.progressBarStyle = htmlSafe(
      `stroke-dashoffset: ${this.fullCircuit - this.fullCircuit / 5}px; stroke: '${Colors.APP}';`
    );

    const setProgress = window.setInterval(() => {
      const fullCircuit = this.fullCircuit;
      const currentCircuit = fullCircuit - i * (fullCircuit / 5);

      if (this.isDestroying || this.isDestroyed) return;

      if (this.args.message.opened) {
        this.progressBarStyle = htmlSafe(`stroke-dashoffset: ${currentCircuit}px; stroke: '${Colors.APP}';`);
      } else {
        window.clearInterval(setProgress);
      }

      if (i >= 5) {
        window.clearInterval(setProgress);
      }

      i += 1;
    }, 1000);
  }

  progressVideo(videoEl) {
    const duration = videoEl.duration;

    this.progressVideoHander = () => {
      const fullCircuit = this.fullCircuit;
      const currentCircuit = fullCircuit - fullCircuit / (duration / videoEl.currentTime);
      this.progressBarStyle = htmlSafe(`stroke-dashoffset: ${currentCircuit}px; stroke: '${Colors.APP}';`);
    };

    this.progressVideoHanderBind = this.progressVideoHander.bind(this);
    videoEl.addEventListener('timeupdate', this.progressVideoHanderBind);
  }

  finishPlaying() {
    set(this.args.message, 'playing', false);
    set(this.args.message, 'playerOpened', false);
    set(this.args.message, 'played', true);

    later(
      this,
      () => {
        if (this.isDestroying || this.isDestroyed) return;
        //this.chat.markMessageAsSeen(this.args.message.id, this.args.message.threadId);
      },
      305
    );
  }
}
