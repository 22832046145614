import Component from '@glimmer/component';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';

export default class ChatMessageReplyTo extends Component {
  @service router;

  @action
  openOriginalMessage() {
    // check if linked message is already loaded in current thread, if yes then just scroll to it without redirecting
    const targetMsg = this.args.thread.messages.find((m) => m.id === this.args.message.replyTo?.id);

    if (targetMsg) {
      targetMsg.set('highlight', true);
    } else {
      this.router.transitionTo('app.chat.thread.message', this.args.thread.id, this.args.message.replyTo?.id);
    }
  }
}
