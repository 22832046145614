import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import dispatcher from 'mewe/dispatcher';

export default class MwFollowPage extends Component {
  @tracked dropdownOpened;

  @action
  toggleFollow() {
    if (this.args.page.isOpenPage) return;
    dispatcher.dispatch('pages', 'toggleFollow', this.args.page, !this.args.page.isFollower);
    this.dropdownOpened = false;
  }

  @action
  mainAction() {
    if (this.args.page.isOpenPage) {
      this.args.followAction();
      return;
    }
    if (!this.args.page.isFollower) {
      this.toggleFollow();
      FunctionalUtils.info(__('You are now following this page'));
      if (this.args.followAction) this.args.followAction();
    } else {
      this.setDropdown();
    }
  }

  @action
  setDropdown() {
    if (this.args.page.isOpenPage) return;
    this.dropdownOpened = !this.dropdownOpened;
  }
}
