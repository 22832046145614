import { assert } from '@ember/debug';
import EmberObject from '@ember/object';
import { ActionHandler } from '@ember/-internals/runtime';
import dispatcher from 'mewe/dispatcher';

// @deprecated
export default EmberObject.extend(ActionHandler, {
  getState() {
    assert('Store: function getState needs to be overwritten.');
  },

  dispatch: dispatcher.dispatch,
});
