import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';

var model = createDisplayEditServerModel([
  'description',
  'currentCity',
  'job',
  'company',
  'college',
  'highSchool',
  'relationshipStatus',
  'interests',
  'phone',
  'email',
]).extend(modelProfileId, {
  id: attr('string'),
  publicLinkId: attr('string'),
  firstName: attr('string'),
  favorite: attr('boolean'),
  lastName: attr('string'),
  name: attr('string'),
  nameDisplay: attr('string'), // needed for SS
  isContact: attr('boolean'),
  isFollowed: attr('boolean'),
  isInvited: attr('boolean'),
  searchGroupId: attr('string'),
  fingerprint: attr('string'),
  verified: attr('boolean'), // deprecated, use badges
  donor: attr('boolean'), // deprecated, use badges
  badges: attr('object'),
  _links: attr('object'),
});

model.reopenClass({
  resourceName: 'user-search',
});

export default model;
