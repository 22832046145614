import { getStorageCurrentUser } from 'mewe/shared/storage';

const userCheck =
  (checkUser) =>
  (user = getStorageCurrentUser()) => {
    if (user) return checkUser(user);

    return false;
  };

export const isInJail = userCheck((u) => u.jailInfo || u.jail);
export const isLocked = userCheck((u) => u.lockInfo || u.lock);
export const isInJailOrLocked = userCheck((u) => u.jailInfo || u.lockInfo || u.jail || u.lock);
export const isDsnpUserWithoutName = userCheck((u = {}) => {
  const hasName = u.firstName?.length && u.lastName?.length;
  return u.id && u.distinctId && !hasName; // checking 'id' only might be not reliable so checking presence of 'distinctId' as well
});
