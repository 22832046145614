import { htmlSafe } from '@ember/template';
import { assert } from '@ember/debug';
import { escape } from 'lodash';
import ExternalApi from 'mewe/api/external-api';
import config from 'mewe/config';
import isUndefined from 'mewe/utils/isUndefined';
import { giphyContentRating } from 'mewe/constants';

assert('giphyApiKey missing from config', config.giphyApiKey);

class GiphyApi extends ExternalApi {
  constructor() {
    super();
    this.apiRoot = 'https://api.giphy.com/v1/gifs/';
    this.baseUrlOptions = {
      api_key: config.giphyApiKey,
    };
  }

  // maxResults -> limit,
  optionsToGiphyFormat(options) {
    if (isUndefined(options.limit) && !isUndefined(options.maxResults)) {
      options.limit = options.maxResults;
      delete options.maxResults;
    }
  }

  // q, offset, limit, rating
  search(options) {
    options = options || {};
    if (isUndefined(options.q) && !isUndefined(options.searchTerm)) {
      options.q = options.searchTerm;
      options.rating = giphyContentRating;
      delete options.searchTerm;
    }
    this.optionsToGiphyFormat(options);

    return this.get('search', null, options);
  }

  // words or phrases -> 1 gif
  translate(options) {
    options = options || {};
    if (isUndefined(options.s) && !isUndefined(options.term)) {
      options.s = options.term;
      options.rating = giphyContentRating;
      delete options.term;
    }
    this.optionsToGiphyFormat(options);

    return this.get('translate', null, options);
  }

  transformData(resp) {
    let data = resp.data;
    if (typeof data === 'object' && !Array.isArray(data)) data = [data];

    let transformed = {
        data: data.map((gifData) => {
          let safeData;
          if (gifData.images) {
            safeData = gifData.images.fixed_width;
            safeData.downSampledUrl = gifData.images.fixed_width_downsampled.url;
          } else {
            safeData = gifData;
            safeData.downSampledUrl = gifData.url;
          }

          safeData.url = htmlSafe(escape(safeData.url));
          safeData.downSampledUrl = htmlSafe(escape(safeData.downSampledUrl));

          return safeData;
        }),
      },
      pagination = resp.pagination;

    if (pagination) {
      transformed.offset = pagination.offset;
      transformed.totalCount = pagination.total_count;
      transformed.count = pagination.count;
    }

    return transformed;
  }
}

export default new GiphyApi();
