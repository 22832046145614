export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const nonFalsyEmpty = (opt) => {
  const obj = {};
  for (const key in opt) {
    if (opt[key] && !isEmpty(opt[key])) {
      obj[key] = opt[key];
    }
  }
  return obj;
};

export const objFilter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

export default {
  windowRedirect(url, queryParam) {
    window.location = queryParam ? url + queryParam : url;
  },
};
