import Component from '@glimmer/component';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { FeedTypes } from 'mewe/constants';

export default class MwAddToAlbum extends Component {
  @service dynamicDialogs;

  @reads('args.post') post;

  @reads('args.feedTheme') feedTheme;

  get title() {
    return this.post.album ? __('Remove from Album') : __('Add to Album');
  }

  @action
  click() {
    this.closePostDropdown?.();

    const groupId = this.post.group.id;
    const pageId = this.post.pageId;

    let feedTheme = this.feedTheme && this.feedTheme !== FeedTypes.ALL ? this.feedTheme : null;

    if (!feedTheme) {
      if (pageId) feedTheme = FeedTypes.PAGE;
      else if (groupId && groupId !== FeedTypes.CONTACTS) feedTheme = FeedTypes.GROUP;
      else feedTheme = FeedTypes.CONTACTS;
    }

    const params = {
      post: this.post,
      eventId: this.post.eventId || this.post?.event2?.id,
      groupId: groupId,
      pageId: pageId,
      page: this.post?.page,
      theme: feedTheme,
    };

    this.dynamicDialogs.openDialog('add-to-album-dialog', params);
  }
}
