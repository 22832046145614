import Component from '@ember/component';
import { observer, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import EmberObject from '@ember/object';

import { Theme, Target } from 'mewe/constants';
import CurrentUserStore from 'mewe/stores/current-user-store';
import GroupStore from 'mewe/stores/group-store';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PermissionsUtils from 'mewe/utils/group-permissions-utils';
import PostApi from 'mewe/api/post-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import { tzName } from 'mewe/utils/datetime-utils';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';
import { uniqBy } from 'lodash';

export default Component.extend({
  classNames: ['mewe-app-content flex-column'],

  routing: service('-routing'),

  purchase: service(),

  account: service(),

  scrolling: Scrolling(),

  page: alias('model.page'),
  group: alias('model.groupModel.group'),

  purchasesLoaded: false,

  init() {
    this._super(...arguments);

    this.purchase.getPurchased().then(() => this.set('purchasesLoaded', true));

    CurrentUserStore.getState().deferred.promise.then(() => {
      this.set('timezone', tzName(this.account.activeUser?.timezone, this.account.activeUser?.jsLocale));
    });
  },

  willDestroyElement() {
    this._super();
    this.scrolling.unbindScrollDown();

    // if there are no posts left, set hasScheduledPosts to false when user leaves the page
    // (we decided not to do it immediately after deleting/releasing posts)
    if (this.theme === Theme.GROUP && !this.items.length) {
      GroupStore.getState({ id: this.group?.id }).set('hasScheduledPosts', false);
    }
  },

  hasPremium: computed('purchase.premium.purchased', function () {
    return this.get('purchase.premium.purchased');
  }),

  premiumRequired: computed('theme', 'hasPremium', function () {
    return !this.hasPremium && (this.theme === Theme.CONTACTS || this.theme === Theme.GROUP);
  }),

  displayPremiumInfo: computed('items.length', 'model.feed.isFetching', 'premiumRequired', function () {
    const noPosts = !this.items.length && !this.model.feed.isFetching;
    return this.premiumRequired && noPosts;
  }),

  displayPremiumError: computed('items.length', 'premiumRequired', function () {
    return this.premiumRequired && this.items.length;
  }),

  items: computed('account.activeUser.id', 'model.feed.sortedPosts.length', 'theme', function () {
    let arrayOfPosts = this.model.feed.sortedPosts
      .filter((post) => typeof post?.scheduled !== 'undefined')
      .sort((post1, post2) => {
        if (post1.scheduled === post2.scheduled) return post1.createdAt - post2.createdAt;
        return post1.scheduled - post2.scheduled;
      })
      .map((post) => EmberObject.create({ post, selected: false }));
    arrayOfPosts = uniqBy(arrayOfPosts, 'post.id');

    if (this.theme === Theme.CONTACTS) {
      arrayOfPosts = arrayOfPosts.filter((post) => post.owner?.id !== this.account.activeUser?.id);
    }

    return arrayOfPosts;
  }),

  infiniteScrollDown: on(
    'didInsertElement',
    observer('model.feed.{posts.length,canShowMore,connectionFail,nextPage}', function () {
      this.scrolling.bindScrollDown(() => this.send('loadMorePosts'), 1200);
    })
  ),

  selectedPosts: computed('items.@each.selected', function () {
    return this.get('items')
      .filter((item) => item.get('selected'))
      .map((item) => item.get('post'));
  }),

  isPremiumOrPage: computed('{hasPremium,theme}', function () {
    return this.hasPremium || this.theme === Theme.PAGE;
  }),

  publishDisabled: computed('selectedPosts.length', function () {
    if (!this.selectedPosts.length) return true;

    const cantReleaseSelectedPost = this.selectedPosts.find((p) => p.permissions.release !== true);

    return cantReleaseSelectedPost;
  }),

  deleteDisabled: computed('selectedPosts.length', function () {
    return !this.selectedPosts.length;
  }),

  newPostDisabled: computed('hasPremium', 'theme', 'group.permissions', function () {
    if (this.theme === Theme.PAGE) return false;
    if (this.theme === Theme.CONTACTS) return !this.hasPremium;
    if (this.theme === Theme.GROUP) {
      const canPostWithoutModeration = PermissionsUtils.canPostWithoutModeration(this.group.permissions);
      return !this.hasPremium || !canPostWithoutModeration;
    }
    return true;
  }),

  select(item, e) {
    // don't select item if when clicked on post options dropdown
    if (e?.target?.closest('dropdown-menu_opener_wrapper')) return;
    // prevent default checkbox state change, otherwise it'd be duplicated
    e.preventDefault();

    item.toggleProperty('selected');
  },

  actions: {
    loadMorePosts() {
      if (this.get('model.feed.nextPage') && !this.get('model.feed.isFetching')) {
        dispatcher.dispatch('feed', 'loadMore', this.get('model.feed'));
      }
    },

    edit() {
      const selected = this.get('selectedPosts');
      const target = this.theme === Theme.PAGE ? Target.PAGE : Target.CONTACTS;

      if (selected.length === 1) {
        const [post] = this.get('selectedPosts');
        openPostbox({
          postToRepost: post,
          theme: this.theme,
          target: target,
          page: this.page,
        });
      }
    },

    publish() {
      if (this.publishDisabled) return;

      const selected = this.selectedPosts;
      const promises = selected.map((post) => {
        let scopePath;

        if (post.groupId) {
          scopePath = `group/${post.groupId}`;
        } else if (post.page?.id) {
          scopePath = `pages/page/${post.page.id}`;
        } else {
          scopePath = `home`;
        }

        return PostApi.releasePost(post.id, scopePath);
      });

      Promise.all(promises)
        .then(() => {
          this.model.feed.posts.removeObjects(selected);
          FunctionalUtils.info(__('Your posts have been published.'));
        })
        .catch(() => {
          FunctionalUtils.error(__(`Couldn't publish posts. Please try again.`));
        });
    },

    delete() {
      if (this.deleteDisabled) return;

      dispatcher.dispatch('feed', 'deletePosts', {
        posts: this.get('selectedPosts'),
        message: __(`You're about to delete {count} scheduled posts`, {
          count: this.get('selectedPosts.length'),
        }),
        scheduled: true,
      });
    },

    new() {
      if (this.newPostDisabled) return;

      openPostbox({
        theme: this.theme,
        target: this.theme,
        scheduleVisible: true,
        groupId: this.group?.id,
        page: this.page,
        scheduled: null,
      });
    },

    optionClick(option) {
      if (option.enabled) {
        this.send(option.action);
      }
    },

    purchasePremium() {
      dispatcher.dispatch('purchase', 'checkout', 'premium-promotion', []);
      this.send('close');
    },
  },
});
