import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import { formatBigNumber } from 'mewe/utils/math-utils';

export default buildHelper(function ([value], { safe = true }) {
  if (value === 0) return '0';
  if (!value) return safe ? htmlSafe('') : '';

  let formattedNumber = formatBigNumber(value);
  return safe ? htmlSafe(formattedNumber) : formattedNumber;
});
