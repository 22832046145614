import Model from 'mewe/utils/store-utils/model/model';
import { attr } from 'mewe/utils/store-utils/model/attribute';

const UsersCounters = Model.extend({
  blocked: attr('number'),
  favoriteFollowers: attr('number'),
  favorites: attr('number'),
  followers: attr('number'),
  following: attr('number'),
  invited: attr('number'),
  requestsReceived: attr('number'),
  requestsSent: attr('number'),
  isFetching: attr('boolean'),
  isLoaded: attr('boolean'),
});

export default UsersCounters;
