import { A } from '@ember/array';
import clientPermissions from 'mewe/utils/permissions-utils';

export default {
  getPermissionsNames: function (permissionsArr) {
    if (!permissionsArr || !permissionsArr.length) return A();

    let permissionsNames = A();

    if (permissionsArr.indexOf(clientPermissions.POST) !== -1) {
      if (permissionsArr.indexOf(clientPermissions.REQMODERATION) !== -1)
        permissionsNames.push(__('Post with approval'));
      else permissionsNames.push(__('Post'));
    }

    if (permissionsArr.indexOf(clientPermissions.COMMENT) !== -1) permissionsNames.push(__('Comment / Chat'));
    if (permissionsArr.indexOf(clientPermissions.RESHARE) !== -1) permissionsNames.push(__('Reshare'));
    if (permissionsArr.indexOf(clientPermissions.INVITE) !== -1) permissionsNames.push(__('Invite'));

    return permissionsNames;
  },

  canPost: function (permissions) {
    return this.containPermission(clientPermissions.POST, permissions);
  },

  canComment: function (permissions) {
    return this.containPermission(clientPermissions.COMMENT, permissions);
  },

  requireModeration: function (permissions) {
    return this.containPermission(clientPermissions.REQMODERATION, permissions);
  },

  canInvite: function (permissions) {
    return this.containPermission(clientPermissions.INVITE, permissions);
  },

  containPermission: function (permission, groupPermissions = []) {
    return groupPermissions.indexOf(permission) >= 0;
  },

  canPostWithoutModeration: function (permissions) {
    return this.canPost(permissions) && !this.requireModeration(permissions);
  },
};
