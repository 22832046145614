/* eslint-disable curly */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getPublicProfileParams } from 'mewe/utils/miscellaneous-utils';

export default class MwPostBlocked extends Component {
  @service account;
  @service router;

  @action
  goToSignUp() {
    if (!this.account.activeUser.id) return;
    this.router.transitionTo('register', { queryParams: getPublicProfileParams(this.args.model) });
  }

  @action
  goToLogin() {
    if (!this.account.activeUser.id) return;
    this.router.transitionTo('login', { queryParams: getPublicProfileParams(this.args.model) });
  }
}
