import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { utcOffsetMillis, shiftToUtc, utcTimeOfDayMillis, utcMidnightMillis, tzName } from 'mewe/utils/datetime-utils';

export default class MwPostboxSchedule extends Component {
  @service account;

  @tracked scheduledDateUtcMillis;
  @tracked timeMillisSuggestion;
  @tracked timeMillis;
  @tracked timezoneLong;
  @tracked timezone;

  constructor() {
    super(...arguments);

    this.timezone = this.account.activeUser?.timezone;
    this.timezoneLong = tzName(this.timezone, this.account.activeUser?.jsLocale);

    let date = new Date();
    const offsetMillis = utcOffsetMillis(this.timezone, date.getTime());

    if (this.args.post?.isScheduled) {
      const schedule =
        this.args.post.scheduled.toString().length >= 13 ? this.args.post.scheduled : this.args.post.scheduled * 1000;
      const dateScheduled = new Date(schedule);
      date = new Date(
        Date.UTC(
          dateScheduled.getUTCFullYear(),
          dateScheduled.getUTCMonth(),
          dateScheduled.getUTCDate(),
          date.getUTCHours() + 1,
          0,
          0,
          offsetMillis
        )
      );
    } else {
      date = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours() + 1,
          0,
          0,
          offsetMillis
        )
      );
    }

    this.scheduledDateUtcMillis = utcMidnightMillis(date);
    this.timeMillisSuggestion = utcTimeOfDayMillis(date);
  }

  get timestamp() {
    return shiftToUtc(this.timezone, this.scheduledDateUtcMillis + this.timeMillis);
  }

  get scheduledInPast() {
    return !isNaN(new Date(this.timestamp).getTime()) && Date.now() > this.timestamp;
  }

  get showDynamicButton() {
    return this.args.post?.scheduled ? !this.args.post?.isEditingSchedule : true;
  }

  @action
  schedule() {
    this.args.scheduleAction?.(this.timestamp);
  }

  @action
  postAction(cb) {
    cb(this.args.postAction());
  }

  @action
  dateChanged(utcMillis) {
    this.scheduledDateUtcMillis = utcMillis;
  }

  @action
  timeChanged(utcMillis) {
    this.timeMillis = utcMillis;
  }
}
