import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/read-only-model.js';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import User from './user-model';

// this model differs from document-model only with 'post' field which here is pure object
// and in document-model it is instance of post-model
// we can have "document" object with field "post" and this is circular dependency which probably causes problems

var model = Model.extend({
  _links: attr('object'),
  id: attr('string', {
    defaultValue() {
      // hack for missing document.id until backend adds it back
      const m = (this.get('_links.url.href') || '').match(/\/api\/v2\/doc\/([a-z0-9]*)\//);

      if (m) return m[1];
    },
  }),
  fileName: attr('string'),
  name: attr('string', {
    defaultValue() {
      return this.get('post.document.name') || this.get('fileName');
    },
  }),
  fileType: attr('string'),
  type: attr('string', {
    defaultValue() {
      let typeInPost = this.get('post.document.type') || this.get('fileType');
      if (typeInPost && typeInPost.length) return typeInPost;
      else {
        let name = this.get('name');
        if (name) return name.toLowerCase().slice(name.lastIndexOf('.') + 1);
      }
    },
  }),
  createdAt: attr('string'),
  mime: attr('string', {
    defaultValue() {
      return '';
    },
  }),
  nodeIcon: attr('string'),
  canRemove: attr('boolean'),
  canMove: attr('boolean'),
  audio: attr('boolean'),
  duration: attr('number'),
  length: attr('number'),
  size: attr('number', {
    defaultValue() {
      return this.get('post.document.size') || this.get('length');
    },
  }),
  owner: belongsTo(User),
  post: attr('object'),
  google: attr('object'),

  isAudio: computed('mime', function () {
    return this.get('mime').indexOf('audio/') === 0;
  }),
});

model.reopenClass({
  resourceName: 'post-document',
});

export default model;
