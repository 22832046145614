import Component from '@glimmer/component';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { ds } from 'mewe/stores/ds';
import Scrolling from 'mewe/utils/scrolling-utils';
import PagesApi from 'mewe/api/pages-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsUsersFollowers extends Component {
  @service account;

  @reads('args.model') model;

  @tracked searchValue = '';

  constructor() {
    super(...arguments);
    this.scrolling = Scrolling();

    this.bindScrollDown();

    addObserver(this, 'args.model.followers.nextPage', this.bindScrollDown);
  }

  willDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'args.model.followers.nextPage', this.bindScrollDown);
  }

  @computed('model.{followers.items,page.isOwner,page.isAdmin}', 'model.followers.items.@each.roleName')
  get followersWithPermissions() {
    const isAdmin = this.model.page.isAdmin;
    const isOwner = this.model.page.isOwner;

    return this.model.followers.items.map((f) => {
      set(f, 'canToggleAdmin', (isOwner || isAdmin) && f.roleName !== 'Owner');
      set(f, 'canBeBanned', (isOwner || isAdmin) && f.roleName === 'Follower');
      set(f, 'hasOptions', f.canToggleAdmin || f.canBeBanned);
      return f;
    });
  }

  loadMore() {
    const pageId = this.model.page.id;
    const followers = ds.followers.for(pageId);

    dispatcher.dispatch('pages', 'fetchFollowers', pageId, {
      nextPage: followers.nextPage,
      str: this.searchValue,
      'with-admins': true,
    });
  }

  bindScrollDown() {
    if (this.args.model.followers.nextPage) {
      this.scrolling.bindScrollDown(() => {
        this.loadMore();
      }, 500);
    }
  }

  @action
  ban(user) {
    dispatcher.dispatch('pages', 'banUser', this.model.page.id, user.id);
  }

  @action
  addAdmin(user) {
    PagesApi.addAdmin(this.model.page.id, user.id).then(() => {
      FunctionalUtils.info(__('Admin successfully added'));
      set(user, 'roleName', 'Admin');
    });
  }

  @action
  removeAdmin(user) {
    PagesApi.removeAdmin(this.model.page.id, user.id).then(() => {
      if (user.id === this.account.activeUser.id) {
        window.location = `/page/${this.model.page.id}`;
      } else {
        FunctionalUtils.info(__('Admin successfully removed'));
        set(user, 'roleName', 'Follower');
      }
    });
  }

  @action
  searchFollowers() {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;
      dispatcher.dispatch('pages', 'fetchFollowers', this.model.page.id, {
        str: this.searchValue,
        'with-admins': true,
      });
    }, 500);
  }

  @action
  resetSearch() {
    this.searchValue = '';
    this.searchFollowers();
  }

  @action
  closeDropdown(user) {
    set(user, 'open', false);
  }
}
