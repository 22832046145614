/* eslint-disable lines-between-class-members */
/* eslint-disable no-case-declarations */
import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { NotificationTypes } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class PageNotification extends Component {
  @reads('args.notification') notification;
  @reads('args.page') page;

  @service router;
  @service account;

  @computed(
    'account.activeUser.id',
    'account.favorites',
    'notification.comment.postedByPage',
    'notification.{actingUsers,notificationType}',
    'page.owner'
  )
  get owner() {
    const owner = this.notification?.actingUsers?.[0];

    switch (this.notification.notificationType) {
      case 'poll_ended':
        return null; // page owner shown in hbs
      case 'poll_vote':
      case 'newPageFollower':
        return owner;
      default:
        let actingUsers = [];

        if (this.notification.actingUsers) {
          actingUsers = this.notification.actingUsers.filter((u) => {
            return this.account.activeUser?.id !== u.id;
          });
        }

        if (!actingUsers.length) {
          if (this.notification.notificationType === 'comment' && this.notification.comment) {
            return this.notification.comment.postedByPage ? null : owner;
          } else if (this.notification.notificationType === 'emojis') {
            return owner; // emoji always done as user, not page
          } else {
            return null; // page owner shown in hbs
          }
        } else {
          return actingUsers?.[0];
        }
    }
  }

  @action
  click() {
    if (this.notification.post || this.notification.comment) {
      dispatcher.dispatch('feed', 'openSinglePost', {
        postItemId: this.notification.post ? this.notification.post.postItemId : null,
        scope: 'page',
        pageId: this.notification.pageId,
        commentId: this.notification.comment ? this.notification.comment.id : null,
        spvSource: 'notification',
      });
    } else {
      if (this.notification.notificationType === NotificationTypes.newPageFollower) {
        this.router.transitionTo('app.publicid', this.notification.actingUsers[0].publicLinkId);
      } else {
        /*
         * we can't distinguish between deleted post or comment right now,
         * notificationType is 'emoji' but no data if emoji was under post/comment
         */
        FunctionalUtils.error(__('Sorry, this post has been deleted by the owner.'));
      }
    }

    if (!this.notification.visited) {
      dispatcher.dispatch('pages', 'notificationMarkVisited', {
        id: this.notification.pageId,
        notificationId: this.notification.id,
      });
      this.notification.visited = true;
    }
  }
}
