import ApiUtil from './api';
import { Theme } from 'mewe/constants';

/**
 * import mediaFeedApi from 'mewe/api/mediafeed-api';
 */
class Api extends ApiUtil {
  getMedia(params, groupId, eventId, mediaSourceUrl = false) {
    if (params.nextPage) {
      if (params.nextPage.indexOf('/api/v2/contacts/') !== -1) {
        // backend sends old endpoint
        params.nextPage = params.nextPage.replace(/\&user=([A-z0-9]+)/, '&userId=$1');
        params.nextPage = params.nextPage.replace(
          /\/api\/v2\/contacts\/(?:[A-z0-9]+)\/media\?([^\b]*)/,
          '/api/v2/media?$1'
        );
      }

      return this.get(params.nextPage);
    } else if (params.isScheduled && mediaSourceUrl) {
      return this.get(mediaSourceUrl, { data: params });
    } else if (params.pendingPostId) {
      // first check for event because there can be both eventId/groupId in case of group event
      if (eventId) {
        return this.get(`/api/v2/event/${eventId}/pending/post/${params.pendingPostId}/media`, { data: params });
      } else if (groupId && groupId !== 'contacts') {
        return this.get(`/api/v3/group/${groupId}/pending/post/${params.pendingPostId}/media`, { data: params });
      }
      return;
    }

    let url, apiRoot;

    if (eventId) {
      url = '/event/' + eventId + '/media';
    } else if (groupId === 'privacymail' || params.threadId) {
      url = '/privateposts/' + params.threadId + '/media';
      delete params.threadId;
    } else if (params.pageId) {
      url = '/pages/page/' + params.pageId + '/media';
      params.pageId = null;
    } else if (!groupId || groupId === 'contacts') {
      if (params.userId) {
        url = '/home/user/' + params.userId + '/media';
        delete params.userId;
      } else url = '/home/media';
    } else if (groupId === 'mycloud') {
      apiRoot = 'apiRoot3';
      params.refId = params.postItemId;
      params.postItemId = null;
      url = '/mycloud/media';
    } else {
      apiRoot = 'apiRoot3';
      url = '/group/' + groupId + '/media';
    }

    if (params.multiPostId) {
      params.postItemId = params.postItemId || params.imgId;
      delete params.imgId;
    }

    if (params.postItemId || params.imgId || params.refId) {
      url += 'feed';
      delete params.userId;
    } else {
      url += 'stream';
    }

    return this.get(url, { data: params }, apiRoot);
  }

  getGalleryDataForChatImg(params) {
    if (params.nextPage) return this.get(params.nextPage);

    if (!params.mediaTypes) params.mediaTypes = 'photo,video';

    return this.get('/messages/media', {
      data: params,
    });
  }

  getAlbumMedia(params, groupId, userId, albumName, scope, eventId) {
    if (params.nextPage) {
      if (params.nextPage.indexOf('/api/v2/events2/event/') !== -1) {
        // backend sends old endpoint
        params.nextPage = params.nextPage.replace(
          /\/api\/v2\/events2\/event\/([A-z0-9]+)\/album\/([A-z0-9]+)\?([^\b]*)/,
          '/api/v2/event2/$1/album/$2/media?$3'
        );
      } else if (params.nextPage.indexOf('/media') === -1) {
        const queryIndex = params.nextPage.indexOf('?'); // backend is missing /media from their nextPage
        params.nextPage = params.nextPage.slice(0, queryIndex) + '/media' + params.nextPage.slice(queryIndex);
      }
      return this.get(params.nextPage);
    }

    let url = '',
      apiRoot;

    switch (scope) {
      case 'event':
        url = '/event/' + eventId;
        break;
      case 'myworld':
      case 'contacts':
      case 'profile':
        url = '/home/user/' + userId;
        params.userId = null;
        break;
      case 'group':
      case 'groups':
        apiRoot = 'apiRoot3';
        url = '/group/' + groupId;
        break;
      case Theme.PAGE:
      case Theme.PAGES:
        url = '/pages/page/' + params.pageId;
        params.pageId = null;
        break;
      case 'mycloud':
        apiRoot = 'apiRoot3';
        url = '/mycloud';
        break;
    }

    url += '/album/' + encodeURIComponent(albumName) + '/media';

    if (!params.postItemId && !params.multiPostId) {
      url += 'stream';
    } else {
      url += 'feed';
    }

    if (params.postItemId) params.refId = params.postItemId;

    return this.get(
      url,
      {
        data: params,
      },
      apiRoot
    );
  }

  getMediaByScopeWithoutPosts(params, groupId, scope, albumName) {
    if (params.nextPage) return this.get(params.nextPage);

    let url, apiRoot;

    switch (scope) {
      case 'event':
        if (albumName) {
          url = '/event/' + groupId + '/album/' + encodeURIComponent(albumName) + '/mediastream';
        } else {
          url = `/event/${groupId}/mediastream`;
        }
        break;
      case 'group':
        apiRoot = 'apiRoot3';

        if (albumName) {
          url = '/group/' + groupId + '/album/' + encodeURIComponent(albumName) + '/mediastream';
        } else {
          if (params.userId) url = '/group/' + groupId + '/member/' + params.userId + '/mediastream';
          else url = `/group/${groupId}/mediastream`;
          params.userId = null;
        }
        break;
      case 'mycloud':
        apiRoot = 'apiRoot3';
        if (groupId && groupId !== 'mycloud') {
          if (params.userId) url = '/group/' + groupId + '/member/' + params.userId + '/mediastream';
          else url = `/group/${groupId}/mediastream`;
          params.userId = null;
        } else {
          if (albumName) {
            url = '/mycloud/album/' + encodeURIComponent(albumName) + '/mediastream';
          } else {
            url = '/mycloud/mediastream';
          }
        }
        break;
      case 'contacts':
      case 'profile':
        if (params.userId) {
          if (albumName) {
            url = '/home/user/' + params.userId + '/album/' + encodeURIComponent(albumName) + '/mediastream';
          } else {
            url = '/home/user/' + params.userId + '/mediastream';
          }

          params.userId = null;
        } else {
          url = '/home/mediastream';
        }
        break;
      case Theme.PAGE:
      case Theme.PAGES:
        if (albumName) {
          url = `/pages/page/${groupId}/album/${encodeURIComponent(albumName)}/mediastream`;
        } else {
          url = `/pages/page/${groupId}/mediastream`;
        }
        break;
    }

    return this.get(url, { data: params }, apiRoot);
  }
}

export default new Api();
