import EmberObject from '@ember/object';
import PS from 'mewe/utils/pubsub';
import PostUtils from 'mewe/utils/post-utils';
import { ActionHandler } from '@ember/-internals/runtime';

const state = EmberObject.extend({
  mwPhotoStreamData: null,
}).create();

const self = EmberObject.extend(ActionHandler, {
  init() {
    this.checkUpdateBind = this.checkUpdate.bind(this);
    PS.Sub('photoStream.update', this.checkUpdateBind);

    this._super();
  },

  willDestroy() {
    this._super();
    PS.Unsub('photoStream.update', this.wsDocEditBind);
  },

  checkUpdate(data) {
    // data.post.cameFromWs => if true then it's WS chat message
    if (data && data.post && data.scope !== 'mycloud' && !data.post.cameFromWs) {
      let { scopeId } = PostUtils.getPostScopeAndId(data.post);
      if (scopeId === 'contacts' && state.get('mwPhotoStreamData.photoStreamRows.length'))
        state.setProperties({
          'mwPhotoStreamData.updateInBackground': true,
          'mwPhotoStreamData.offset': 0,
          'mwPhotoStreamData.nextPageUrl': null,
          'mwPhotoStreamData.showReload': false,
        });
    }
  },

  getState: () => state,

  actions: {
    storeMwPhotoStream(data) {
      state.set('mwPhotoStreamData', data);
    },

    cleanMwPhotoStream() {
      state.set('mwPhotoStreamData', null);
    },
  },
});

export default self.create();
