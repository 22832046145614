import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import ChatStore from 'mewe/stores/chat-store';

export default class MwSmartSearchChat extends Component {
  @service chat;

  @tracked thread;

  constructor() {
    super(...arguments);
    this.setInitialParams();
  }

  setInitialParams() {
    let chat = this.args.chat;

    let threadFromStore = ChatStore.getThreadById(chat.threadId);
    if (typeof threadFromStore !== 'undefined' && threadFromStore !== null) {
      const currentParticipants = threadFromStore.get('participants');

      if (!currentParticipants?.length && chat.participants?.length) {
        next(() => {
          threadFromStore.set('participants', chat.participants);
        });
      }

      this.thread = threadFromStore;
      this.thread.set('threadId', chat.threadId);
    }

    if (this.isThreadChatType || this.isGroupChatType) {
      // chatThread (+ message of group type)

      if (!(typeof threadFromStore !== 'undefined' && threadFromStore !== null)) {
        this.thread = ChatStore.deserializeOne(this.args.chat);
      }

      let textServer = this.thread?.lastMessage?.textServer;
      const originalLastMsg = chat.lastMessage;
      if (typeof originalLastMsg !== 'undefined' && originalLastMsg !== null) {
        const originalText = originalLastMsg.text;

        if (typeof textServer === 'undefined' || textServer === null || textServer === '') {
          if (typeof originalText !== 'undefined' && originalText !== null && originalText !== '') {
            textServer = chat.lastMessage.textServer;
          }
        }
      }
      this.textServer = textServer;

      this.setDataForHighlight(chat);
    }
  }

  get threadNameDisplay() {
    // don't show thread name if it's the same as the user name (happens for 1-1 chats SG-43239)
    if (this.thread.threadName && this.args.chat.userName) {
      return this.thread.threadName !== this.args.chat.userName ? this.thread.threadName : '';
    }
  }

  @computed('chat.recordType')
  get isMessageChatType() {
    return this.args.chat.recordType === 'chatMessage';
  }

  @computed('chat.recordType')
  get isThreadChatType() {
    return this.args.chat.recordType === 'chatThread';
  }

  @computed('chat.messageType')
  get isGroupChatType() {
    return this.args.chat.messageType === 'group';
  }

  setDataForHighlight(chat) {
    this.thread.query = chat.query;
    this.thread.highlightStart = chat.highlightStart;
    this.thread.highlightEnd = chat.highlightEnd;
    this.thread.isFromSearch = true;
  }

  @action
  openChat(event) {
    if (event && event.target) {
      if (event.target.classList.contains('text-truncate_button')) return;
    }

    let msgId = this.args.chat.threadId === this.args.chat.id ? null : this.args.chat.id;
    this.chat.transitionToThreadByIdOrOpenParticipants(this.args.chat.threadId, msgId);
    this.args.closeSmartSearch();
  }
}
