import Component from '@glimmer/component';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class MwAppFooter extends Component {
  @action
  changeLocale(locale) {
    dispatcher.dispatch('client-data', 'setLocale', locale, true);
  }
}
