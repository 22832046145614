import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import isUndefined from 'mewe/utils/isUndefined';
import { isEmpty, isNaN, isNull } from 'lodash';

export default buildHelper(function (value, options) {
  if (!value || isEmpty(value) || isNull(value[0]) || isUndefined(value[0])) {
    return htmlSafe('');
  }

  const size = value[0];
  let ratio = Math.min(options.maxWidth / size.width, options.maxHeight / size.height),
    width = size.width * ratio,
    height = size.height * ratio;

  if (isNaN(width) || isNaN(height) || width === Infinity || height === Infinity) {
    return htmlSafe('');
  } else {
    // keep original size if both dimensions smaller than scaled ones
    if (height > size.height && width > size.width) {
      height = size.height;
      width = size.width;
    }

    return htmlSafe('height: ' + height + 'px; width: ' + width + 'px');
  }
});
