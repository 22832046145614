import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import MiscUtils from 'mewe/utils/miscellaneous-utils';

export default class ShareLink extends Component {
  @tracked showLinksDropdown = false;

  @action
  toggleDropdown() {
    this.showLinksDropdown = !this.showLinksDropdown;
  }

  @action
  copyToClipboard() {
    MiscUtils.copyToClipboard(this.args.link);
  }

  @action
  inviteFromFacebook() {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?mode=message&u=' + this.args.link,
      'sharer',
      this.getWindowFeatures()
    );
  }

  @action
  inviteFromTwitter() {
    let text = encodeURIComponent(
        __("See what I'm really sharing these days. Join me on MeWe - the social network with privacy!")
      ),
      urlEncoded = encodeURIComponent(this.args.link);

    window.open(
      'https://www.twitter.com/intent/tweet?text=' + text + '&url=' + urlEncoded,
      'sharer',
      this.getWindowFeatures()
    );
  }

  getWindowFeatures() {
    return (
      'status=no,height=330,width=700,resizable=yes,left=' +
      (window.screen.width / 2 - 300) +
      ',top=' +
      (window.screen.height / 2 - 165)
    );
  }
}
