import emojisParser from 'mewe/stores/text-parsers/emojis-parser';
import gifUrlsParser from 'mewe/stores/text-parsers/gif-urls-parser';
import mentionsParser from 'mewe/stores/text-parsers/mentions-parser';
import markdownParser from 'mewe/stores/text-parsers/markdown-parser';
import highlightParser from 'mewe/stores/text-parsers/highlight-parser';
import { reduce } from 'lodash';

// TODO made config of parsers easier in models (MW)

let parsersDefault = [markdownParser, mentionsParser, emojisParser, gifUrlsParser, highlightParser];

export default function (text, parsers = parsersDefault, options = {}) {
  return reduce(
    parsers,
    (value, parser) => {
      return parser.toDisplay(value, options);
    },
    text
  );
}
