import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend({
  id: attr('string'),
});

model.reopenClass({
  resourceName: 'page-followers-intervals',
});

export default model;
