/* eslint-disable ember/classic-decorator-no-classic-methods */
import ApiUtil from './api';

class Api extends ApiUtil {
  async translate(params, scope = '') {
    if (params.applicationId) {
      scope = `group/${params.groupId}/application/${params.applicationId}`;
    } else if (params.pending) {
      scope = `group/${params.groupId}/pending/post/${params.postId}`;
    }

    if (scope === 'chat') {
      scope = 'chat/message';
    }

    return await this.post(`/translate/${scope}`, {
      data: JSON.stringify(params),
    });
  }

  getDefault(params) {
    return this.get('/translate/settings', {
      data: JSON.stringify(params),
    });
  }

  saveDefault(params) {
    return this.put('/translate/settings', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
