import { action } from '@ember/object';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PagesApi from 'mewe/api/pages-api';
import { getOffset } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwEmojiSenderDropdown extends PopupOpener {
  layout = layout;
  popupType = 'dropdown-popup';
  closeOnClickOutside = true;
  closeOnHoverOutside = true;
  closeOnScroll = true;

  get dropdownPosition() {
    const isRtl = document.dir;
    let position = this.getDefaultDropdownOptions();
    const parentOffset = getOffset(this.parent);

    Object.assign(position, {
      reposition: { elementHeight: 230 },
      parentOffset: { top: parentOffset.top, left: parentOffset.left },
      placement: { top: false, left: isRtl },
      translate: { x: -20, altY: -18 },
    });

    return position;
  }

  @action
  removeEmoji() {
    if (this.args.post.isComment) {
      PagesApi.removeEmojiFromComment({
        userId: this.args.userId,
        commentId: this.args.post.id,
        emojis: this.args.emojis,
      })
        .then(() => this.args.callback())
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    } else {
      PagesApi.removeEmojiFromPost({
        userId: this.args.userId,
        pageId: this.args.post.pageId,
        postItemId: this.args.post.postItemId,
        emojis: this.args.emojis,
      })
        .then(() => this.args.callback())
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    }
  }

  @action
  removeEmojiAndBan() {
    // removing user emojis after banning can take some time on backend so first do this request to have immediate result and then ban
    this.removeEmoji();
    dispatcher.dispatch('pages', 'banUser', this.args.post.pageId, this.args.userId);
  }
}
