import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { sort, filterBy } from '@ember/object/computed';
import { ActionHandler } from '@ember/-internals/runtime';
import { cond, isObject, identity, stubTrue, flip, conforms, isString, each } from 'lodash';

import Group from 'mewe/stores/models/group-model';
import config from 'mewe/config';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { createDeferred } from 'mewe/shared/utils';

const serializer = JSONSerializer.create();

const state = EmberObject.extend({
  groups: A(),

  groupSorting: ['lastVisited:desc'],

  sortedGroups: sort('groups', 'groupSorting'),

  confirmedGroups: filterBy('sortedGroups', 'isConfirmed', true),

  unconfirmedGroups: filterBy('groups', 'isConfirmed', false),

  openGroups: filterBy('confirmedGroups', 'isPublic', true),

  deferred: createDeferred(),
  connectionIssue: false,
  groupsLoaded: false,
}).create();

if (config.environment != 'prod') {
  var data = (window.Data = window.Data || {});
  data.Group = state;
}

const getGroup = (params) =>
  returnOrCreate(
    state.groups.find((g) => g.id === params.id),
    params.id
  );

const getGroupIfMember = (id) => state.confirmedGroups.find((g) => g.id === id);
const getGroupIfExists = (id) => state.groups.find((g) => g.id === id);

const create = (id) => state.groups.unshiftObject(Group.create({ id: id, isFetching: true }));

const returnOrCreate = cond([
  [isObject, identity],
  [stubTrue, flip(create)],
]);

const getState = cond([
  [conforms({ id: isString }), getGroup],
  [stubTrue, () => state],
]);

let priority = 0;

const self = EmberObject.extend(ActionHandler, {
  getState: getState, // deprecated
  get: getState, // shorthand alias
  getGroupIfMember: getGroupIfMember,
  getGroupIfExists: getGroupIfExists,

  actions: {
    handleMany: (array) => {
      let state = getState();
      let groups = state.groups,
        ids = groups.map((group) => group.id),
        index;
      let toPush = A();

      array.map((group) => {
        group.lastVisited = Date.now() - (priority += 10);
        return group;
      });

      // if group is already in store, just update it
      array.forEach((group) => {
        index = ids.indexOf(group.id);
        if (index > -1) {
          groups[index].setProperties(Object.assign({}, group, { isFetching: false }));
        } else {
          toPush.push(group);
        }
      });

      // if group isn't in store, deserialize it and push
      if (toPush.length) groups.pushObjects(serializer.deserializeAll(Group, toPush, { isFetching: false }));

      if (state.connectionIssue) state.set('connectionIssue', false);

      state.set('groupsLoaded', true);
      state.deferred.resolve();
    },

    handleOne: (data) =>
      serializer.updateInstance(
        getState({ id: data.id }),
        Object.assign(data, { isFetching: false, lastVisited: new Date().getTime() })
      ),

    failToFetchData: (data) => getState({ id: data.id }).setProperties({ error: data.data, isFetching: false }),

    connectionIssue: () => getState().set('connectionIssue', true),

    clear: () => getState().get('groups').clear(),

    repositionGroupToTop(groupId) {
      let groupToReposition = this.getState({ id: groupId });

      if (groupToReposition) {
        groupToReposition.set('lastVisited', Date.now());

        state.notifyPropertyChange('groups');
      }
    },

    increaseNewPostsCounter(postId, groupId) {
      var group = this.getState({ id: groupId });

      if (group.get('newPostsIds').indexOf(postId) === -1) {
        group.get('newPostsIds').pushObject(postId);
        group.set('newPosts', group.get('newPosts') + 1);
      }
    },

    decreaseNewPostsCounter(postId, groupId, removedByCurrentUser) {
      let group = this.getState({ id: groupId });
      let newPostsIds = group.get('newPostsIds');

      // decrease counter only if post was 'removedByCurrentUser' OR
      // removed post is in 'newPostsIds' array, this is array of posts from WS
      // otherwise we don't know if removed post is seen or not so counter can't be decreased
      if (removedByCurrentUser || (newPostsIds && newPostsIds.indexOf(postId) !== -1)) {
        if (newPostsIds) newPostsIds.removeObject(postId);

        if (group.get('newPosts') > 0) {
          // just for sure to avoid negative number
          group.set('newPosts', group.get('newPosts') - 1);
        } else {
          group.set('newPosts', 0);
        }
      }
    },

    resetAllNewPostsCounters() {
      const unreadGroups = getState()
        .get('groups')
        .filter((g) => g.newPosts > 0);
      each(unreadGroups, (g) => this.send('resetNewPostsCounter', g.id));
    },

    resetNewPostsCounter(groupId) {
      const group = this.getState({ id: groupId });

      group.setProperties({
        newPostsIds: A(),
        newPosts: 0,
      });
    },

    removeGroup(groupId) {
      let groups = getState().get('groups');
      let groupFromStore = groups.find((g) => g.id === groupId);

      if (groupFromStore) {
        groups.removeObject(groupFromStore);
      }
    },
  },
});

export default self.create();
