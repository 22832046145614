import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';

export default class AppGroupFeed extends Component {
  @service router;
  @service dynamicDialogs;

  get model() {
    return this.args.model;
  }

  @action
  openInviteMembersDialog() {
    this.dynamicDialogs.openDialog('invitations-dialog', {
      group: this.model.appGroup.group,
    });
  }

  @action
  openPostBoxDialog() {
    const params = {
      theme: Theme.GROUP,
      target: Theme.GROUP,
      groupId: this.model.appGroup.group.id,
      preselectedGroup: this.model.appGroup.group,
    };

    openPostbox(params, this.dynamicDialogs);
  }

  @action
  openPendingPostHashTag(hash) {
    this.router.transitionTo('app.group', { queryParams: { tag: hash } });
  }

  @action
  goToSettings() {
    this.router.transitionTo('app.group.settings', { queryParams: { initialTabNumber: 1 } });
  }
}
