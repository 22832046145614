import Route from 'mewe/routes/route';

export default class ConfirmGroupInviteRoute extends Route {
  model(params) {
    return {
      groupId: window.location.hash.slice(1),
      invitationId: params.id,
    };
  }
}
