import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { A } from '@ember/array';

import { isFeatureHintValid, removeFeatureHint } from 'mewe/utils/feature-hint';
import { FeedTypes } from 'mewe/constants';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';
import Storage from 'mewe/shared/storage';
import PS from 'mewe/utils/pubsub';
import { localCopy } from 'tracked-toolbox';

export default class MwFeedFilter extends Component {
  @service settings;
  @service dynamicDialogs;
  @service account;

  feedTypes = FeedTypes;

  @localCopy('args.scope', FeedTypes.ALL)
  scope;

  @tracked isDiscoverHintUnseen;

  @tracked hintPosition;

  constructor() {
    super(...arguments);

    this.checkHintStatusBind = this.checkHintStatus.bind(this);
    PS.Sub('check.discover.hint', this.checkHintStatusBind);
  }

  get feeds() {
    let feeds = A([
      EmberObject.create({
        name: __('All'),
        scope: FeedTypes.ALL,
        visible: true,
      }),
      EmberObject.create({
        name: __('Following'),
        scope: FeedTypes.CONTACTS,
        visible: true,
      }),
      EmberObject.create({
        name: __('Groups'),
        scope: FeedTypes.GROUPS,
        visible: this.account.flagCommentsFeed === false,
      }),
      EmberObject.create({
        name: __('Pages'),
        scope: FeedTypes.PAGES,
        visible: this.account.flagCommentsFeed === false,
      }),
      EmberObject.create({
        name: __('Discover'),
        scope: FeedTypes.DISCOVER,
        visible: true,
      }),
      EmberObject.create({
        name: __('Threads'),
        scope: FeedTypes.THREADS,
        visible: this.account.flagCommentsFeed === true,
      }),
    ]);

    return feeds.filter((feed) => feed.visible);
  }

  @action
  onDestroy() {
    PS.Unsub('check.discover.hint', this.checkHintStatusBind);
  }

  get selectedFeed() {
    return this.feeds.find((f) => f.scope === this.scope);
  }

  get showDiscoverHint() {
    return this.isDiscoverHintUnseen && get(this.dynamicDialogs, 'components.length') === 0;
  }

  get contactObject() {
    return this.feeds.find((f) => f.scope === FeedTypes.CONTACTS);
  }

  checkHintStatus() {
    isFeatureHintValid('discover').then(({ timesViewed }) => {
      if (timesViewed === 0) {
        this.isDiscoverHintUnseen = true;

        // if it's possible to prevent scroll restoration (most browsers support it) then let's do such hack:
        // (otherwise it's better to have that minor styling issue with overlaying element)
        if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';

          // it'd be complicated change in design to prevent Discover hint layer being on top of app-main-header
          // and also app-dragover-overlay, simple 'hack' is to prevent scrolling page when hint is displayed
          const html = document.querySelector('html');
          html.scrollTop = 0;
          html.style.overflow = 'hidden';
        }

        // hide mobile sidebar button to prevent opening sidebar because it will also affect overlay
        const mobileSidebarBtn = document.querySelector('.mobile-sidebar-btn');
        if (mobileSidebarBtn) {
          mobileSidebarBtn.classList.add('hidden');
        }

        document.querySelector('.app-dragover-overlay').classList.add('active');
      }
    });
  }

  @action
  checkHintPosition(feedType, el) {
    if (feedType.scope === FeedTypes.DISCOVER) {
      const hintWidth = 300;
      const placement = el.getBoundingClientRect();
      const spaceOnRight = window.innerWidth - placement.left - hintWidth;

      if (spaceOnRight < 0) {
        this.hintPosition = htmlSafe(`margin-left: ${spaceOnRight}px`);
      }
    }
  }

  @action
  setFeedScope(feedType) {
    // get last state of filter from storage, we want to keep it for CONTACTS feed after switching feeds
    let feedParams = {
      scope: feedType.scope,
      filter: null,
    };

    if (feedType.scope === this.feedTypes.CONTACTS && Storage.get(Storage.keys.filterFavoritesInContacts)) {
      feedParams.filter = this.feedTypes.FAVORITES;
    }

    this.args.setFeedParams(feedParams);
  }

  @action
  setFeedFilter() {
    // toggle filter values {this.feedTypes.FAVORITES | null}
    Storage.set(Storage.keys.filterFavoritesInContacts, !this.args.filter);
    this.args.setFeedParams({ filter: this.args.filter ? null : this.feedTypes.FAVORITES });
  }

  @action
  dismissDiscoverHint(getStarted) {
    this.isDiscoverHintUnseen = false;

    const mobileSidebarBtn = document.querySelector('.mobile-sidebar-btn');
    if (mobileSidebarBtn) {
      mobileSidebarBtn.classList.remove('hidden');
    }

    document.querySelector('.app-dragover-overlay').classList.remove('active');

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto';
      document.querySelector('html').style.overflow = 'auto';
    }

    removeFeatureHint('discover');

    if (getStarted) {
      if (Storage.get('didSetInterests')) {
        this.setFeedScope(this.feeds.find((f) => f.scope, FeedTypes.DISCOVER));
      } else {
        openFtueRecommendations(this.dynamicDialogs, 'discover-hint-popup');
      }
    }
  }
}
