import ContactsApi from 'mewe/api/contacts-api';
import FollowingApi from 'mewe/api/following-api';
import ContactsStore from 'mewe/stores/contacts-store';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { ds } from 'mewe/stores/ds';
import { next } from '@ember/runloop';

export const fetchUsersCounters = () => {
  if (ds.usersCounters.isFetching) {
    return;
  }

  next(() => {
    ds.usersCounters.setProperties({ isFetching: true });
  });

  FollowingApi.counters()
    .then((data) => {
      ds.usersCounters.setProperties(Object.assign(data, { isLoaded: true }));
    })
    .finally(() => {
      ds.usersCounters.setProperties({ isFetching: false });
    });
}

export const fetchFollowers = (params = {}) => {
  let id = CurrentUserStore.getState().get('id') + '-followers';

  ContactsStore.send('update', id, { isFetching: true, isFetchingMore: !!params.nextPage });

  params.maxResults = params.maxResults || 20;

  FollowingApi.followers(params)
    .then((r) => {
      if (!params.nextPage) {
        ContactsStore.send('clear', id);
      }

      if (r.list?.length) {
        const list = r.list.map((el) => {
          return Object.assign(el.user, { following: el.following, follower: el.follower });
        });
        ContactsStore.send('handle', id, list);
      }

      ContactsStore.getState({ id: id }).set('nextPage', r._links?.nextPage?.href);
    })
    .finally(() => {
      ContactsStore.send('update', id, { isFetching: false, isFetchingMore: false, isInited: true });
    });
}

export const fetchPending = (params = {}) => {
  let id = CurrentUserStore.getState().get('id') + '-requests';

  ContactsStore.send('update', id, { isFetching: true, isFetchingMore: !!params.nextPage });

  params.maxResults = params.maxResults || 20;

  ContactsApi.pending(params)
    .then((r) => {
      if (!params.nextPage) {
        ContactsStore.send('clear', id);
      }

      if (r.list?.length) {
        const list = r.list.map((el) => {
          return Object.assign(el.user, { followRequestReceived: el.followRequestId });
        });
        ContactsStore.send('handle', id, list);
      }

      ContactsStore.getState({ id: id }).set('nextPage', r._links?.nextPage?.href);
    })
    .finally(() => {
      ContactsStore.send('update', id, { isFetching: false, isFetchingMore: false, isInited: true });
    });
}
