import config from 'mewe/config';

export default ({ prefix = '[verbose]', color = 'blue', enabled = true, style }) => {
  const verboseColor = `color: ${color}; ${style}`;

  const _isLogEnabled = () => {
    let isSetInLocalStorage = false;

    try {
      isSetInLocalStorage = localStorage.getItem('meweVerbose') === 'true';
    } catch (e) {}

    return (config.environment !== 'prod' && enabled) || window.meweVerbose || isSetInLocalStorage; // value from localstorage always string
  };

  return {
    isEnabled: _isLogEnabled,

    log: (...args) => {
      if (!_isLogEnabled()) return;

      args.forEach((text) => {
        if (text !== null && (typeof text === 'object' || Array.isArray(text))) {
          console.log(`%c${prefix}`, verboseColor, text);
        } else {
          console.log(`%c${prefix} ${text}`, verboseColor);
        }
      });
    },
  };
};
