import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { PUBLIC_ENTITY_TYPE } from 'mewe/constants';

export default class MwPublicWrapper extends Component {
  @service account;
  @service dynamicDialogs;

  constructor() {
    super(...arguments);
    this.setPageMeta();
  }

  setPageMeta() {
    if (this.isPublicProfile) {
      document.title = __('To follow this profile join or log-in to MeWe today');
    } else if (this.isPublicPage) {
      document.title = __('To follow this page join or log-in to MeWe today');
    } else {
      document.title = __('Join or log-in to MeWe today');
    }
  }

  get publicPost() {
    return this.args.model.singlePosts?.posts?.findBy('postItemId', this.args.model.postId);
  }

  get shouldBlurImages() {
    // blur only for profiles and timeline posts, no blur for pages and page posts
    return this.isPublicProfile || (this.publicPost && !this.publicPost?.pageId);
  }

  @computed('args.model.contact.{id,nsfw,_links.avatar.nsfw,following,public}')
  get isNsfwBlurred() {
    if (this.args.model.contact.following) return false;
    if (this.args.model.contact.id === this.account.activeUser.id) return false;

    return this.args.model.contact._links?.avatar?.nsfw;
  }

  get isPublicProfile() {
    return this.args.model.entityType === PUBLIC_ENTITY_TYPE.PROFILE;
  }

  get isPublicPage() {
    return this.args.model.entityType === PUBLIC_ENTITY_TYPE.PAGE;
  }

  get isPublicPost() {
    return this.args.model.entityType === PUBLIC_ENTITY_TYPE.POST;
  }

  @action
  displayNewToMeWePopup() {
    showNewToMeWePopup(this.dynamicDialogs);
  }
}
