import Route from 'mewe/routes/route';
import StoreApi from 'mewe/api/store-api';

export default class PaypalFailureRoute extends Route {
  model({ transactionId }) {
    return transactionId;
  }

  afterModel(transactionId) {
    StoreApi.updatePaypalFailTrans({ transactionId });
  }
}
