export default function (locale) {
  if (!Intl || !Intl.DateTimeFormat || !Intl.RelativeTimeFormat) return true;

  try {
    new Date().toLocaleTimeString(locale, { timeZone: 'Africa/Addis_Ababa' });
    new Date().toLocaleTimeString(locale, { timeZone: 'America/Los_Angeles' });
    new Date().toLocaleTimeString(locale, { timeZone: 'Asia/Tokyo' });
    new Date().toLocaleTimeString(locale, { timeZone: 'Europe/Berlin' });
  } catch (e) {
    return true;
  }

  return false;
}
