import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import GroupStore from 'mewe/stores/group-store';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class MwPostGroupHeaderComponent extends Component {
  @service dynamicDialogs;
  @service router;

  get post() {
    return this.args.post;
  }

  get group() {
    return this.post?.group;
  }

  get eventData() {
    return this.post?.event2;
  }

  get isGroup() {
    return this.group?.id && !this.isContacts;
  }

  get isEvent() {
    return this.eventData && !this.post?.eventInfo;
  }

  get route() {
    if (this.isEvent) {
      return 'app.event';
    }  else if (this.isGroup) {
      return 'app.group';
    }
  }

  get models() {
    if (this.isEvent) {
      return [this.post?.event2?.id];
    } else if (this.isGroup) {
      return [this.group?.id];
    } else {
      return [];
    } // array has to be always returned
  }

  get avatarLink() {
    if (this.isEvent) {
      return this.eventData?.avatarLink;
    } else if (this.isGroup) {
      return this.group?.avatarLink;
    }
  }

  get hideRightSide() {
    if (!this.isGroup || !this.post) {
      return true;
    }
    if (this.args.isRefPost || this.args.postInPostbox || this.args.inMediaDialog) {
      return true;
    }

    return false;
  }

  get showJoinButton() {
    if (this.hideRightSide) {
      return false;
    }

    return this.post.discoverScope && !this.post.group.isMember && !this.post.group.alreadyApplied;
  }

  get showPendingApproval() {
    if (this.hideRightSide) {
      return false;
    }

    return this.post.discoverScope && !this.post.group.isMember && this.post.group.alreadyApplied;
  }

  @action
  handleClick() {
    if (this.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.post, {
        context: this.args.postContext,
      });
      return false;
    }

    this.dynamicDialogs.closeAll();

    if (this.isGroup && !this.args.postInPostbox) {
      const group = GroupStore.getGroupIfMember(this.group.id);
      if (!group) {
        showGroupPreviewDialog(this.dynamicDialogs, this.group, {
          isDiscoverPostInteraction: false,
          isGroupPreview: true,
          context: this.args.postContext,
        });
        return false;
      } else {
        this.router.transitionTo('app.group', this.group?.id);
      }
    } else if (this.isEvent) {
      this.router.transitionTo('app.event', this.post?.event2?.id);
    }
  }

  @action
  showAppliedDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      closeBtnText: __(`Got it!`),
      message: __(
        `You have applied to join this group. As soon as the group owner approves, you will be able to visit the group and engage with the posts and members.`
      ),
    });
  }

  @action
  closeSPV() {
    this.dynamicDialogs.closeByName('single-post-dialog');
  }
}
