/* eslint-disable lines-between-class-members */
import { A } from '@ember/array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import EmberObject, { action, computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
// eslint-disable-next-line ember/no-observers
import { addObserver, removeObserver } from '@ember/object/observers';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { differenceWith, each } from 'lodash';
import ChatApi from 'mewe/api/chat-api';
import ChatUtils from 'mewe/utils/chat-utils';
import { getElHeight } from 'mewe/utils/elements-utils';
import KeyboardSelectList from '../../common/keyboard-select-list';
import Scrolling from 'mewe/utils/scrolling-utils';
import { restartableTask, timeout } from 'ember-concurrency';
import { run, next } from '@ember/runloop';
import './styles.scss';

export default class MwUserAutocompleteList extends KeyboardSelectList {
  @service dynamicDialogs;

  @tracked opened = false;
  @tracked offset = 0;
  @tracked keyboardListElementActionName = 'toggleContactOrGroup';
  @tracked keyboardListItems = A();
  @tracked element;
  @tracked keyboardListActiveIndex;
  @tracked _searchText;

  @tracked customHeight = htmlSafe('');

  @reads('args.selectedItems')
  selectedItems;

  maxResults = 20;
  scrolling = Scrolling();

  constructor(...args) {
    super(...args);
    if (this.args.allowEmptySearch) {
      this.search.perform(this.args.searchText, true);
    }
    // eslint-disable-next-line ember/no-observers
    addObserver(this, 'args.searchText', this, this.doSearch);
    if (this.args.allowEmptySearch && this.args.chatWindow) {
      // eslint-disable-next-line ember/no-observers
      addObserver(this, 'selectedItems.length', this, this.doSearch);
    }
  }

  doSearch() {
    //This is would prevent the search from being called when the search text is the same as the previous search text, also it would prevent from input focusout
    if (this._searchText === this.args.searchText) return;
    this._searchText = this.args.searchText;
    this.search.perform(this.args.searchText, true);
  }

  setDefaultValues() {
    this.opened = false;
    this.offset = 0;
    this.keyboardListItems = A();
    this.keyboardListElementActionName = 'toggleContactOrGroup';
    this.args.onResetSearchText && this.args.onResetSearchText();
    this.resetKeyboardList();
  }

  @restartableTask
  *search(searchStr, reset) {
    const userPicker = this.keyboardListItems.find((i) => i.isUserPicker);
    let numberOfItems = this.keyboardListItems.length;
    if (numberOfItems && userPicker) {
      numberOfItems = numberOfItems - 1;
    }
    const params = {
      offset: numberOfItems,
      maxResults: this.maxResults,
    };
    if (!searchStr && (!this.args.allowEmptySearch || this.selectedItems?.length)) return this.closeSearchResults();
    if (searchStr) {
      params.query = searchStr;
      searchStr = searchStr.toLowerCase();
    }
    if (this.isDestroying || this.isDestroyed) return;
    this.opened = true;
    if (reset) {
      this.resetKeyboardList();
      next(() => {
        this.keyboardListItems = A();
      });
      params.offset = 0;
    }
    yield timeout(500);
    let candidatesReq = yield ChatApi.getChatCandidates(params);
    if (this.isDestroyed || this.isDestroying) return;
    let chatCandidates = this.transformCandidates(candidatesReq.candidates);
    if (this.showOnlyContacts) {
      chatCandidates = this.filterChatCandidates(chatCandidates);
    }
    run(() => {
      const items = this.keyboardListItems;
      items.pushObjects(chatCandidates);
      const uP = items.find((i) => i.isUserPicker);
      if (uP) items.removeObject(uP);
      items[searchStr ? 'pushObject' : 'unshiftObject']({
        isUserPicker: true,
      });
      if (items.length && params.offset === 0) {
        this.keyboardListActiveIndex = -1;
      }
    });
    if (candidatesReq.candidates.length >= this.maxResults) {
      this.scrolling.bindScrollDownElement(this.memberSearchResults, () => this.search.perform(this.args.searchText));
    }
  }

  @action
  setMemberSearchResults(el) {
    this.memberSearchResults = el;
  }

  transformCandidates(candidates = []) {
    return candidates.map((c) => {
      if (c.contact) {
        c.user = c.contact.user;
      }

      if (c.user) {
        if (!c.user.name) {
          c.user.name = c.user.firstName + ' ' + c.user.lastName;
        }
        c.id = c.user.id;
      } else {
        c.id = c.group.id;
      }

      return c;
    });
  }

  closeSearchResults() {
    if (!this.isDestroying && !this.isDestroyed) {
      this.setDefaultValues();
    }
  }

  @computed('selectedItems.length')
  get showOnlyContacts() {
    return this.selectedItems?.length > 0;
  }

  @action
  setUserList(element) {
    this.element = element;

    if (this.args.chatWindow) {
      const height = getElHeight(document.querySelector('.chat-window_body')) - getElHeight(this.args.inputEl);
      if (height < 435) {
        this.customHeight = htmlSafe('max-height: ' + height + 'px;');
      }
    }

    this.kDownBind = this.kDown.bind(this);
    this.args.inputEl.addEventListener('keydown', this.kDownBind);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    this.args.inputEl?.removeEventListener('keydown', this.kDownBind);
    this.scrolling.unbindScrollDown(this.element.querySelector('.member-search-results'));

    // eslint-disable-next-line ember/no-observers
    removeObserver(this, 'args.searchText', this, this.doSearch);
    if (this.args.allowEmptySearch && this.args.chatWindow) {
      // eslint-disable-next-line ember/no-observers
      removeObserver(this, 'selectedItems.length', this, this.doSearch);
    }
  }

  kDown(e) {
    //only fire on tab only key, for shift + tab leave default
    if (e.keyCode === 9 && !e.shiftKey) {
      this.onTabKey();
      e.preventDefault();
    } else {
      this.keyDown(e);
    }
  }

  filterChatCandidates(chatCandidates) {
    chatCandidates = chatCandidates.filter((c) => c.user); // filter all other candidates than contacts (groups)

    return differenceWith(chatCandidates, this.selectedItems, (candidate, sharedWithItem) => {
      // remove all already selected contacts
      return candidate && candidate.user && sharedWithItem && candidate.id === sharedWithItem.id;
    });
  }

  @action
  toggleContactOrGroup(contactOrGroup) {
    // this element is not selectable but triggers user picker
    if (contactOrGroup.isUserPicker) {
      this.openUserPicker();
      return;
    }

    this.closeSearchResults();

    if (!this.selectedItems) {
      this.args.onSelectedItemsReset && this.args.onSelectedItemsReset();
    }

    const found = this.selectedItems.find((i) => i.id === contactOrGroup.id);

    if (found) {
      this.selectedItems.removeObject(found);
    } else {
      if (this.limit && this.selectedItems.length === this.limit) {
        ChatUtils.chatLimitExceeded(this.dynamicDialogs);
        return;
      }
      this.selectedItems.pushObject(EmberObject.create(contactOrGroup));
    }
  }

  @action
  openUserPicker() {
    const selectedUsers = this.selectedItems.map((i) => i.user);
    const callback = (users) => {
      run(() => {
        each(users, (u) => {
          // checking if already selected, don't want to unselect contacts by this action
          const found = this.selectedItems.length && this.selectedItems.find((i) => i.id === u.id);
          if (!found) this.toggleContactOrGroup({ id: u.id, user: u });
        });
      });

      this.closeSearchResults();
    };
    const closeCallback = () => this.args.inputEl.focus();

    this.dynamicDialogs.openDialog('user-picker-dialog', {
      pickerType: 'addToChat',
      selectedUsers: selectedUsers,
      submitAction: callback,
      closeAction: closeCallback,
    });
  }
}
