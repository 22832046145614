import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class MwHomeStartPageComponent extends Component {
  @action
  redirectToStore() {
    const iosAppLink = 'https://itunes.apple.com/us/app/mewe-network/id918464474?mt=8';
    const androidAppLink = 'https://play.google.com/store/apps/details?id=com.mewe';
    const agent = navigator.userAgent || window.opera;

    if (/iPhone|iPod/i.test(agent)) {
      // check if ios, most cases
      later(() => {
        window.location.href = iosAppLink;
      }, 400);
    } else if (/Android/i.test(agent)) {
      // check if android
      later(() => {
        window.location = androidAppLink;
      }, 400);
    }
  }
}
