import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { fetchUsersCounters } from 'mewe/fetchers/fetch-followers';
import { ds } from 'mewe/stores/ds';

import FunctionalUtils from 'mewe/shared/functional-utils';
import PostApi from 'mewe/api/post-api';

export default class MwSharingInfo extends Component {
  @service account;

  @tracked isSavingChanges;
  @tracked sharingDropdownOpened = false;
  @tracked usersCounters = ds.usersCounters;
  @tracked isCommentsAllowed = !this.post.commentsDisabled;

  constructor() {
    super(...arguments);
    if (!this.usersCounters) {
      fetchUsersCounters();
    }
  }

  get post() {
    return this.args.post;
  }

  get showEveryoneOption() {
    // for stories there is "everyone" option for public users
    // and only followers/favorites options for private users
    if (this.args.contentType === 'story') {
      return this.account.activeUser.public;
    }
    // post shared to everone so we take that for granted that this option is enabled
    if (this.post.everyone) {
      return true;
    }
    return !this.account.activeUser.public;
  }

  get showAnyoneOption() {
    // for stories there is eveyrone option instead of anyone for public users
    // because stories can't be visible outside of MeWe
    if (this.args.contentType === 'story') {
      return false;
    }
    return this.account.activeUser.public;
  }

  get isEveryoneDisabled() {
    return this.post.everyone && this.account.activeUser.public;
  }

  get showDropdownOptions() {
    return this.post.isCurrentUserPostOwner && !this.args.inMediaDialog;
  }

  @action
  toggleDropdown() {
    this.sharingDropdownOpened = !this.sharingDropdownOpened;
  }

  // loading counter on dropdown insert because it's needed only when the dropdown is opened
  @action
  fetchUsersCounters() {
    // we only need 'favoriteFollowers' counter which is enough to load once
    // as any further updates to the counter are handled on client side so the counter should be up to date
    if (!ds.usersCounters.isLoaded) {
      fetchUsersCounters();
    }
  }

  @action
  updateSharingOption(value, event) {
    event.preventDefault();

    // onChange is passed to handle the update instead of default post update
    if (this.args.onChange) {
      this.args.onChange(value);
      this.toggleDropdown();
    } else {
      this.togglePublicPost(value);
    }
  }

  @action
  togglePublicPost(value) {

    if (this.isSavingChanges) return;
    if (value === this.post.sharedTo) return;

    this.isSavingChanges = true;

    if (value === 'friends') {
      PostApi.publishToFriends(this.post.postItemId, this.post.scheduled)
        .then(() => {
          set(this, 'post.public', false);
          set(this, 'post.everyone', false);
          set(this, 'post.closeFriends', true);

          set(this, 'post.permissions.reshare', false);
          set(this, 'post.permissions.repost', true);
        })
        .finally(() => {
          this.isSavingChanges = false;
          this.toggleDropdown();
        })
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    } else {
      PostApi.togglePublicPost(this.post.postItemId, this.post.scheduled, value)
        .then(() => {
          set(this, 'post.public', value == 'public');
          set(this, 'post.everyone', value == 'everyone');
          set(this, 'post.closeFriends', false);

          set(this, 'post.permissions.reshare', value !== 'private');
          set(this, 'post.permissions.repost', value === 'private');
        })
        .finally(() => {
          this.isSavingChanges = false;
          this.toggleDropdown();
        })
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    }
  }

  @action
  togglePostComments() {
    this.isSavingChanges = true;
    PostApi.toggleCommentsDisabled(this.post.postItemId, this.post.scheduled, {
      commentsDisabled: !this.post.commentsDisabled,
    })
      .then(() => {
        this.post.commentsDisabled = !this.post.commentsDisabled;
      })
      .finally(() => (this.isSavingChanges = false))
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }
}
