import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';

import ContactsApi from 'mewe/api/contacts-api';
import AccountApi from 'mewe/api/account-api';
import CurrentUserStore from 'mewe/stores/current-user-store';
import FunctionalUtils from 'mewe/shared/functional-utils';
import InvitationsUtils from 'mewe/utils/invitations-utils.js';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import storage from 'mewe/shared/storage';
import Session from 'mewe/shared/session';
import PS from 'mewe/utils/pubsub';
import Scrolling from 'mewe/utils/scrolling-utils';
let { isDefined } = MiscellaneousUtils;

export default class MwFtue extends Component {
  @service websocket;
  @service dynamicDialogs;

  @tracked stepName = 'findFriends';
  @tracked registrationProviderSupported;
  @tracked contactsSelected = 0;

  @tracked suggestedCount = 0;
  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    this.getInitialParams();
    this.checkProvider();
    this.bindWsHandlers();
  }

  getInitialParams() {
    if (this.args.userData) {
      // store for possible page refresh
      storage.set(storage.keys.ftueUnconfirmedUser, JSON.stringify(this.args.userData));
    } else {
      // no data from parent component so check storage (page refresh ocurred)
      let ftueUnconfirmedUser = storage.get(storage.keys.ftueUnconfirmedUser);
      try {
        ftueUnconfirmedUser = JSON.parse(ftueUnconfirmedUser);
      } catch (e) {}

      if (isDefined(ftueUnconfirmedUser)) {
        this._userData = ftueUnconfirmedUser;
      } else {
        let userFromStore = CurrentUserStore.getState();
        if (userFromStore && userFromStore.id) {
          this._userData = userFromStore;
        } else {
          // no user data for FTUE so go to homepage
          window.location.href = '/';
        }
      }
    }
  }

  @computed('args.userData', '_userData')
  get userData() {
    return this._userData ?? this.args.userData;
  }

  @computed('stepName', 'suggestedCount')
  get ftueHeaderText() {
    switch (this.stepName) {
      case 'findFriends':
        return {
          first: __(`Welcome!`),
          second: __(`Let's find your friends on MeWe!`),
        };
      case 'contactsList':
        return {
          first: __(`Nice!`),
          second: __('Invite friends using your {providerName} address book', {
            providerName: this.providerName
              ? this.providerName.charAt(0).toUpperCase() + this.providerName.slice(1)
              : '',
          }),
          third:
            this.suggestedCount > 0
              ? __(`You have {count} friend already on MeWe`, { count: this.suggestedCount })
              : '',
        };
      case 'confirmEmail':
        return {
          first: __(`Confirm your email`),
          second: __(`It’s the last step!`),
        };
    }
  }

  willDestroy() {
    this.performWSCleanup();

    document.querySelectorAll('.contact-row input').forEach((item) => {
      item.removeEventListener('change', this.recountSelectedContactsBind);
    });

    this.scrolling.unbindScrollDown(document.querySelector('.contacts-list'));
  }

  performWSCleanup() {
    PS.Unsub('contacts.import.failed', this.wsContactImportFailureHandlerBind);
    PS.Unsub('contacts.import.ready', this.wsContactsImportReadyBind);
    PS.Unsub('validation.import.failed', this.wsValidationFailedBind);
  }

  bindWsHandlers() {
    if (this.userData.id) {
      CurrentUserStore.send('handle', { id: this.userData.id }, true);
      this.websocket.open();
    }

    this.wsContactImportFailureHandlerBind = this.wsContactImportFailureHandler.bind(this);
    this.wsContactsImportReadyBind = this.wsContactsImportReady.bind(this);
    this.wsValidationFailedBind = this.wsValidationFailed.bind(this);

    PS.Sub('contacts.import.ready', this.wsContactsImportReadyBind);
    PS.Sub('contacts.import.failed', this.wsContactImportFailureHandlerBind);
    PS.Sub('validation.import.failed', this.wsValidationFailedBind);
  }

  wsValidationFailed(data) {
    // simple preventing this WS message from other tab
    if (!this.contactsImportInitiated) return;

    if (data) {
      if (data.reason === 'unvalidated') {
        FunctionalUtils.error(__('This email address already belongs to another member.'));
      } else {
        // accountInfoMissing
        FunctionalUtils.error(__('This email address has not been confirmed by the email provider.'));
      }
    }

    this.startImportAgain();
  }

  wsContactsImportReady(data) {
    // simple preventing this WS message from other tab
    if (!this.contactsImportInitiated) return;

    this.contacts = A();
    this.offset = 0;
    this.addressBookName = data.addressBookName;
    this.importId = data.importId;

    // set new tokens with validated = true so next api calls succeed
    ContactsApi.autovalidateBeforeImport().then(() => {
      AccountApi.getCurrentUser()
        .then((data) => {
          if (data.unvalidated) {
            this.startImportAgain();
            FunctionalUtils.error(__('This email address already belongs to another member.'));
          } else {
            CurrentUserStore.send('handle', data, true);

            this.emailConfirmed = true;

            FunctionalUtils.info(__('You confirmed your email address.'));

            this.loadContacts();
          }
        })
        .catch(() => {
          this.startImportAgain();
          FunctionalUtils.showDefaultErrorMessage();
        });
    });
  }

  startImportAgain() {
    this.providerName = '';
    this.registrationProviderSupported = false;
    this.stepName = 'findFriends';
  }

  wsContactImportFailureHandler() {
    // simple preventing this WS message from other tab
    if (!this.contactsImportInitiated) return;

    this.stepName = findFriends;
    FunctionalUtils.showDefaultErrorMessage();
  }

  loadContacts() {
    const params = {
      addressbook: this.addressBookName,
      refId: this.importId,
      offset: this.offset,
      maxResults: 80,
    };

    ContactsApi.importAddressbookContacts(params)
      .then((data) => {
        if (data.contacts && data.contacts.length) {
          let contactsToRender = A(this.prepareContacts(data.contacts));

          this.offset = this.offset + data.contacts.length;

          if (!this.contacts || this.contacts.length < 1) {
            this.contacts = contactsToRender;
            this.stepName = 'contactsList';
          } else {
            this.contacts.pushObjects(contactsToRender);
          }

          // change event listeners can be moved to action handlers, now I just copied code in rush
          setTimeout(() => {
            this.recountSelectedContactsBind = this.recountSelectedContacts.bind(this);

            document.querySelectorAll('.contact-row input').forEach((item) => {
              item.addEventListener('change', this.recountSelectedContactsBind);
            });

            this.loadContactsBind = this.loadContacts.bind(this);

            this.scrolling.bindScrollDownElement(document.querySelector('.contacts-list'), this.loadContactsBind);
          }, 0);
        } else {
          if (this.offset === 0) {
            window.setTimeout(() => {
              this.finishFtue();
            }, 1000);
          }
        }
      })
      .catch((data) => {
        // checking for error response data existance to avoid this callback in case of other code error
        if (data && data.data) {
          this.startImportAgain();
          FunctionalUtils.error(__('This email address already belongs to another member.'));
        }
      });
  }

  prepareContacts(importedContacts) {
    let meweContacts = importedContacts.filter((contact) => {
      return contact.isSuggested;
    });

    this.suggestedCount = this.suggestedCount + meweContacts.length;

    let contacts = A();

    importedContacts.forEach((contact) => {
      contact.disabled = !contact.emails.length;
      contact.connections = contact.emails;
      // contact.connections = contact.emails.concat(contact.phoneNumbers); // no ivnviting via sms right now
      contact.multipleConnections = contact.connections && contact.connections.length > 1;
      contact.connectionsString = (contact.connections.join().toLowerCase() + contact.name.toLowerCase()).replace(
        / /g,
        ''
      );

      // if user has two exactly same contacts, we want to prevent displaying them twice
      let allContacts = this.contacts.concat(contacts);
      let doubledContactIndex = allContacts.find(
        (contactInner) => contactInner.connectionsString === contact.connectionsString
      );

      if (doubledContactIndex === undefined) {
        contacts.push(contact);
      }
    });

    return contacts;
  }

  recountSelectedContacts() {
    this.contactsSelected = document.querySelectorAll('.contact-row input:checked').length;
  }

  checkProvider() {
    const email = this.userData.email;

    if (email) {
      if (email.indexOf('@gmail.com') > -1) {
        this.providerName = 'gmail';
        this.registrationProviderSupported = true;
      } else if (email.indexOf('@yahoo.com') > -1) {
        this.providerName = 'yahoo';
        this.registrationProviderSupported = true;
      } else if (email.indexOf('@aol.com') > -1) {
        this.providerName = 'aol';
        this.registrationProviderSupported = true;
      } else if (email.indexOf('@outlook.com') > -1 || email.indexOf('@hotmail.com') > -1) {
        this.providerName = 'windowslive';
        this.registrationProviderSupported = true;
      } else {
        this.registrationProviderSupported = false;
      }
    } else {
      this.providerName = '';
      this.registrationProviderSupported = false;
    }
  }

  @action
  resendEmail(retries) {
    let retriesLeft;

    if (typeof retries !== 'undefined') {
      retriesLeft = Number(retries);
    } else {
      retriesLeft = 3;
    }

    const doneCallback = () => {
      FunctionalUtils.info(
        __('A new validation email has been sent to {email}', {
          email: this.userData.email,
        })
      );
    };

    if (retriesLeft > -1 && this.userData.email) {
      AccountApi.resendConfirmationLink(this.userData.email)
        .then(doneCallback)
        .catch(() => {
          if (retriesLeft - 1 > -1) {
            this.resendEmail((retriesLeft - 1).toString());
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        });
    }
  }

  proceedSending(invitees, ref) {
    const params = {
      emailInvitees: invitees.emailInvitees,
      smsInvitees: invitees.smsInvitees,
      ref: ref,
    };

    InvitationsUtils.sendFollowInvitations(this.dynamicDialogs, params, () => {
      FunctionalUtils.info(__('Your invitations were sent.'));

      window.setTimeout(() => {
        this.finishFtue();
      }, 1000);
    });
  }

  getSelectedEmails() {
    let $selectedRows = document.querySelectorAll('.contact-row input:checked'),
      selectedUsers = {
        emailInvitees: A(),
        smsInvitees: A(),
      };

    $selectedRows.forEach((row) => {
      let $row = row.closest('.contact-row'),
        email = '';

      if ($row.querySelector('.multiple-connections')) {
        $row = $row.querySelector('.multiple-connections');
        if ($row) email = $row.value;
      } else {
        $row = $row.querySelector('.user-email');
        if ($row) email = $row.innerText;
      }

      if (email) selectedUsers = this.addToInvitees(selectedUsers, email);
    });

    return selectedUsers;
  }

  addToInvitees(invitees, address) {
    if (address.indexOf('@') !== -1) {
      invitees.emailInvitees.push({
        emails: address,
      });
    } else {
      invitees.smsInvitees.push({
        numbers: address,
      });
    }

    return invitees;
  }

  @action
  ftueWrapperClick() {
    if (this.preventClickUntil > Date.now()) return false;

    if (this.stepName === 'loadingContacts') {
      this.stepName = 'findFriends';
    }
  }

  @action
  goToStep(stepName) {
    if (stepName === 'confirmEmail') {
      // registered with phone no (sms verification is assumed if user is in ftue) or confirmed email with contacts import
      if (this.userData.tmpPhoneId || this.emailConfirmed) {
        this.finishFtue();
      } else {
        AccountApi.getCurrentUser()
          .then((data) => {
            if (data.unvalidated) {
              if (this.stepName === stepName) {
                // closing dialog when on 'confirmEmail' step
                window.location.href = '/confirm';
              } else {
                this.stepName = stepName;
              }
            } else {
              this.emailConfirmed = true;
              CurrentUserStore.send('handle', data, true);
              this.finishFtue();
            }
          })
          .catch(() => {
            // request can fail if user is not logged in
            // and that means he's not validated yet, show 'confirmEmail' step then
            this.stepName = stepName;
          });
      }
    } else {
      this.stepName = stepName;
    }
  }

  // finishFtue means that user was verified in the process, unverified user stays on 'confirm email' screen
  @action
  finishFtue() {
    if (this.userData.tmpPhoneId || this.emailConfirmed) {
      // finishing FTUE as verified user (email verified by contacts import or registrations with phone number)
      // is treated as "Login" action because now user has fully working account
      Session.isAuthenticated().then(({ isAuthenticated }) => {
        if (isAuthenticated) {
          window.location = `/`;
        } else {
          window.location = `/login?next=${document.location.pathname}${document.location.hash}`;
        }
      });
    } else {
      // code shouldn't get to this condition but let's have it as a fallback
      // basically finishFtue should only be called when user finished FTUE as verified user
      window.location.href = '/';
    }
  }

  @action
  inviteAndContinue() {
    const addressesToInvite = this.getSelectedEmails(),
      totalNumberOfInvitees = addressesToInvite.emailInvitees.length + addressesToInvite.smsInvitees.length;

    if (totalNumberOfInvitees > 0) {
      if (addressesToInvite.emailInvitees.length + addressesToInvite.smsInvitees.length > 0) {
        this.proceedSending(addressesToInvite, 'ftue');
      }
    } else {
      this.finishFtue();
    }
  }

  @action
  openImportWindow(providerArg) {
    this.contactsImportInitiated = true;

    var windowWidth = 600,
      windowHeight = 600,
      valueLeft = window.screenX + (window.innerWidth - windowWidth) / 2,
      valueTop = window.screenY + 50,
      provider;

    if (typeof providerArg === 'string') {
      provider = providerArg;
    } else {
      provider = this.providerName;
    }

    window.open(
      `/contacts-import/${provider}`,
      __('Import Your Addressbook'),
      'left=' + valueLeft + ',top=' + valueTop + ',width=' + windowWidth + ',height=' + windowHeight
    );

    // prevent closing "loading" overlay by clicking on it (SG-33971)
    // it's a hack because "openImportWindow" click event bubbles to "ftueWrapperClick"
    this.preventClickUntil = Date.now() + 500;

    this.stepName = 'loadingContacts';
  }
}
