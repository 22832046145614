import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { postCustomInteraction } from 'mewe/utils/post-utils';
import { FeedTypes } from 'mewe/constants';
import PostUtils from 'mewe/utils/post-utils';
import { Theme } from 'mewe/constants';
import { inject as service } from '@ember/service';
import GroupStore from 'mewe/stores/group-store';
import { tracked } from '@glimmer/tracking';
import { imgHost } from 'mewe/helpers/img-host';
import { templatedString } from 'mewe/helpers/templated-string';
import FunctionalUtils from 'mewe/shared/functional-utils';
import MediafeedApi from 'mewe/api/mediafeed-api';

export default class MwPostMedia extends Component {
  @service dynamicDialogs;
  @service storage;

  @reads('args.post') post;

  gifImageSize = '800x800';

  @tracked gifModelLocal;
  @tracked currentIndex = 0;
  @tracked isLoading = false;

  get imageUrl() {
    const templatedStringOpts = {
      imageSize: '800x800',
      static: '0',
    },
    imgHostOpts = {
      noApiPrefix: true,
      isPublicContent: this.post.isPublicContent,
    };
    return imgHost([templatedString(this.currentMedia.photo._links.img.href, templatedStringOpts, true)], imgHostOpts);
  }

  // dot 1 position from active is same size, 2 positions from active is smaller, 3 positions from active is smallest, further are hidden
  // if first or last dot is active, then two dots next to it are same size as the active dot, then one smaller and one smallest
  get navDots() {
    const count = this.post.mediasCount || 0;

    return Array.from({ length: count }, (_, index) => {
      let diff = Math.abs(index - this.currentIndex);
      let isFirstOrLast = false;

      if (this.currentIndex === 0 || this.currentIndex === count - 1) {
        isFirstOrLast = true;
      }

      if (diff === 0) return 'dot-active';
      if (diff === 1) return '';

      if (isFirstOrLast) diff--;
      if (diff === 1) return '';
      if (diff === 2) return 'dot-small';
      if (diff === 3) return 'dot-micro';
      return 'hidden';
    });
  }

  get currentMedia() {
    return this.post.medias[this.currentIndex];
  }

  get showPhoto() {
    return !this.post.eventInfo;
  }

  get canNavigatePrev() {
    return this.currentIndex > 0 && this.currentIndex < this.post.mediasCount;
  }

  get canNavigateNext() {
    return this.currentIndex < this.post.mediasCount - 1;
  }

  @computed('args.fixedMaxWidth')
  get fixedMaxWidth() {
    return this.args.fixedMaxWidth ?? this.media.photo?.size?.width;
  }

  @computed('currentMedia.photo.animated')
  get gifModel() {
    return this.gifModelLocal ? this.gifModelLocal : this.currentMedia.photo?.animated ? this.currentMedia.photo : null;
  }

  @action
  openMediaDialog() {
    if (this.args.post.isPublicContent) return;
    if (this.args.onOpen) return;

    const post = this.post;
    const media = this.currentMedia;

    if (this.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.post);
      return;
    }
    // - if sharing post preview in postbox then don't open photos in MD
    // - if shared post inside post then don't open photo in MD, SPV will be opened instead
    if (this.args.postInPostbox || this.args.isSharedPost) return;

    const options = {
      postId: media.postItemId,
      fromPost: true,
      groupId: post.groupId || post.group?.id,
      pageId: post.pageId,
      page: post.page,
      post,
      isSharedPost: this.args.isSharedPost,
      isRefPost: this.args.isRefPost,
      postContext: this.args.postContext,
      discoverScope: post.discoverScope,
      theme: this.scope,
      isGroupPreview: post.isGroupPreview,
    };

    if (post.isScheduled) {
      options.isScheduled = true;

      if (!this.scope) {
        options.scope = post.groupId ? 'group' : post.pageId ? 'page' : 'contacts';
      }
    }

    if (post.eventId) {
      options.eventId = post.eventId;
    } else if (post.event2?.id) {
      options.eventId = post.event2?.id;
    }

    // for group events we can't fetch media data for single photo posts, if user is not a group member,
    // so in this case we open MD with just media URL for image preview. For multiphoto posts it will work fine (SG-44642)
    if (options.eventId && post.groupId && post.mediasCount === 1) {
      if (!GroupStore.getGroupIfMember(post.groupId)) {
        options.photoUrl = media.photo._links.img.href;
      }
    }

    if (post.pending) {
      options.pendingPostId = post.postItemId;
      options.post = post;
    } else if (post.medias?.length > 1) {
      options.multiPostId = post.postItemId;
      options.imgId = media.postItemId;
    } else {
      options.post = post;
    }

    if (post.hashTags?.length === 1 && !post.album) options.hashtag = post.hashTags[0];

    if (this.args.openHashTagFromPost) {
      options.openHashTagFromPost = this.args.openHashTagFromPost;
    }

    if (this.args.scopeId) options.scopeId = this.args.scopeId;

    if (this.args.threadId) {
      // no need to pass threadId when group photo is opened from chat info media
      if (this.args.scope === Theme.PRIVATEPOSTS) {
        options.threadId = this.args.threadId;
      }
    } else if (post.threadId) {
      options.threadId = post.threadId;
    }

    if (this.args.isSharedPost) {
      options.userId = post.userId;
    } else if (this.args.scope) {
      options.scope = this.args.scope;
      options.theme = this.args.scope;

      // profile feed opened when photo clicked in profile feed post, or in SPV
      if (this.args.scope === Theme.PROFILE || this.args.isPostDialog) {
        options.userId = post.userId;
      }
    }

    if (post.album && !options.multiPostId && !options.pendingPostId) {
      options.mediaType = 'album';
      options.album = post.album;
      options.userId = post.owner?.id;
    }

    options.imageSize = post.media?.photo?.size;

    if (options.theme === Theme.MYCLOUD) options.groupId = Theme.MYCLOUD;

    if (options.scope == FeedTypes.ALL) {
      options.scope = PostUtils.getPostScopeAndId(post).scope;
    }

    options.allowMultipleInstances = true;

    this.dynamicDialogs.openDialog('media-dialog', options);
  }

  @action
  setGifModel(gifModel) {
    this.gifModelLocal = gifModel;
  }

  @action
  goPrev() {
    if (this.args.post.isPublicContent) return;
    if (this.args.onOpen) return;

    if (this.canNavigatePrev) {
      this.currentIndex--;
    }
  }

  @action
  goNext() {
    if (this.args.post.isPublicContent) return;
    if (this.args.onOpen) return;

    if (this.canNavigateNext) {
      if (this.post.medias.length > this.currentIndex + 1) {
        this.currentIndex++;
      } else {
        this.loadMedia();
      }
    }
  }

  // loading more photos from this multiphoto post
  loadMedia() {
    let mediaSourceUrl;

    const groupId = this.post.groupId;
    const eventId = this.post.eventId;

    const params = {
      after: 4, // get next 4 photos at once
      order: 1,
      pageId: this.post.pageId,
      threadId: this.post.threadId,
      postItemId: this.currentMedia.postItemId,
      multiPostId: this.post.postItemId,
    };

    if (this.post.pending) {
      params.pendingPostId = this.post.postItemId;
    } else if (this.post.isScheduled) {
      params.isScheduled = true;
      params.refId = this.post.postItemId;

      if (this.post.isGroupPost) {
        mediaSourceUrl = `/api/v3/group/${groupId}/scheduled/post/${this.post.multiPostId}/media`;
      } else if (params.pageId) {
        mediaSourceUrl = `/pages/page/${params.pageId}/scheduled/post/${this.post.multiPostId}/media`;
      } else {
        mediaSourceUrl = `/api/v2/home/scheduled/post/${this.post.multiPostId}/media`;
      }
    }

    this.isLoading = true;

    MediafeedApi.getMedia(params, groupId, eventId, mediaSourceUrl)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (data.next?.feed?.length) {
          this.post.medias.push(...data.next.feed);
          this.goNext();
        } else {
          if (data.safeMode) {
            FunctionalUtils.error(__("We're sorry but we couldn't retrieve the posts. Please try again later."));
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        }

      })
      .catch((resp) => {
        if (resp && resp.status) {
          FunctionalUtils.showDefaultErrorMessage();
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.isLoading = false;
      });
  }
}
