import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import DiscoverApi from 'mewe/api/discover-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class MwRecommendPost extends Component {
  @service dynamicDialogs;

  @alias('args.post')
  post;

  @computed('post.isAlreadyRecommended')
  get title() {
    return this.post.isAlreadyRecommended ? __('Un-recommend') : __('Recommend');
  }

  @action
  click() {
    this.closePostDropdown?.();

    const post = this.post; // reference needed for callabck when component will be destroyed already
    const options = {
      postId: this.post.postItemId,
      scope: this.post.postedByPage ? 'page' : 'group',
      scopeId: this.post.postedByPage ? this.post.pageId : this.post.group.id,
    };

    if (this.post.isAlreadyRecommended) {
      DiscoverApi.unrecommendPost(options)
        .then(() => {
          set(post, 'isAlreadyRecommended', false);
        })
        .catch(() => {
          FunctionalUtils.showDefaultErrorMessage();
        });
    } else {
      const confirmCallback = () => {
        DiscoverApi.recommendPost(options)
          .then(() => {
            FunctionalUtils.info(__(`Post was successfully recommended.`));
            set(post, 'isAlreadyRecommended', true);
          })
          .catch((err) => {
            if (err.status === 403) {
              this.dynamicDialogs.openDialog('simple-dialog-new', {
                message: __(`Sorry, you can only recommend one post per day.`),
                closeBtnText: __(`Got it!`),
              });
            } else {
              FunctionalUtils.showDefaultErrorMessage();
            }
          });
      };

      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __(`Recommend this Post`),
        message: __(
          `You are about to recommend this post to possibly be shared with MeWe members outside this group in MeWe Discover. You may recommend up to one post per day from this group. You’ll be notified if the post is approved. Thank you!`
        ),
        cancelButtonText: __('Cancel'),
        okButtonText: __('Recommend'),
        onConfirm: () => confirmCallback(),
      });
    }
  }
}
