import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { next } from '@ember/runloop';
import { each, filter } from 'lodash';
import { inject as service } from '@ember/service';

import fuHelper from 'mewe/utils/fileupload-utils';
import { loadImage, resizeImage } from 'mewe/utils/fileupload-utils';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import MathUtils from 'mewe/utils/math-utils';
import { photoMaxWeightToUploadToServer, photoMaxWidthHeight, fileUploadLimit } from 'mewe/constants';
import Session from 'mewe/shared/session';
import axios from 'axios';

const CancelToken = axios.CancelToken;

class JobObject {
  @tracked file;
  @tracked uploadId;
  @tracked isFinished;
  @tracked progress;
  @tracked photoPreviewUrl;
  @tracked axoisUpload;
}

export default class MwFileAttachment extends Component {
  @service dynamicDialogs;

  formId = MathUtils.generateId();

  @action
  onInsert(element) {
    this.element = element;

    this.inputPhoto = this.element.querySelector('input[name="image"]');
    this.inputFile = this.element.querySelector('input[name="file"]');

    if (!this.inputPhoto && !this.inputFile) return;

    this.args.setChild?.(this);

    addObserver(this, 'args.initPasteZone', this.initPasteZoneChange);

    this.inputChangeBind = this.inputChange.bind(this);
    this.inputPhoto.addEventListener('change', this.inputChangeBind);
    this.inputFile.addEventListener('change', this.inputChangeBind);

    if (this.args.dropZone) {
      this.dropZoneEl = this.element.closest(this.args.dropZone);

      this.dropHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.add(e.dataTransfer.files);
      };

      this.dropHandlerBind = this.dropHandler.bind(this);
      this.dropZoneEl.addEventListener('drop', this.dropHandlerBind);
    }
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.initPasteZone', this.initPasteZoneChange);

    this.inputPhoto.removeEventListener('change', this.inputChangeBind);
    this.inputFile.removeEventListener('change', this.inputChangeBind);
    if (this.pasteHandlerBind && this.pasteZoneEl) this.pasteZoneEl.removeEventListener('paste', this.pasteHandlerBind);
    if (this.dropHandlerBind && this.dropZoneEl) this.dropZoneEl.removeEventListener('drop', this.dropHandlerBind);

    this.cleanAllUploadJobs();
  }

  // function to be tirggered by parent component
  // in case we want to trigger system file selector by click on other element than this component
  uploadClick() {
    this.element.querySelector(`[type=file][name='image']`).click();
  }

  initPasteZoneChange() {
    next(this, () => {
      if (!this.args.dropZone && !this.args.initPasteZone) return;

      this.pasteZoneEl = this.dropZoneEl.querySelector('.ql-editor');
      if (!this.pasteZoneEl) return;

      this.pasteHandler = (e) => {
        if (e.clipboardData && e.clipboardData.files && e.clipboardData.files[0]) {
          this.add(e.clipboardData.files);
        }
      };

      this.pasteHandlerBind = this.pasteHandler.bind(this);
      this.pasteZoneEl.addEventListener('paste', this.pasteHandlerBind);
    });
  }

  uploadStatusChange() {
    this.args.fileUploadUpdate?.({
      fileIds: filter(this.args.uploadJobs, (u) => u.isFinished).map((u) => u.fileId),
      isUploading: !!this.args.uploadJobs.find((u) => !u.isFinished),
    });
  }

  inputChange() {
    const files = this.inputPhoto.files.length ? this.inputPhoto.files : this.inputFile.files;
    this.add(files);
  }

  add(files) {
    each(files, (file) => this.createUpload(file, MathUtils.generateId()));

    // clean inputs in case of user selecting same file again
    this.inputPhoto.value = '';
    this.inputFile.value = '';
  }

  createUpload(file, uploadId) {
    if (this.args.maxFilesCount && this.args.uploadJobs?.length >= this.args.maxFilesCount) {
      FunctionalUtils.error(__('You can upload up to {count} attachments', { count: this.args.maxFilesCount }));
      return;
    }

    let job = new JobObject();

    job.file = file;
    job.uploadId = uploadId;
    job.isFinished = false;
    job.progress = 0;

    let wasRunning = false;

    if (fuHelper.isImageFileTypeSupported(file.type) && this.args.photoUrl) {
      job.attachmentType = 'photo';

      this.uploadUrl = this.args.photoUrl;
      this.args.setUploadType?.('photos');
    } else {
      if (this.args.photosOnly) {
        FunctionalUtils.error(__(`Sorry, wrong image format`));
        return;
      } else if (this.args.fileUrl) {
        job.attachmentType = this.args.attachmentType === 'audio' ? 'audio' : 'doc';

        this.uploadUrl = this.args.fileUrl;
        this.args.setUploadType?.('files');
      }
    }

    if (!this.uploadUrl) return;

    // single file upload, reset before uploading new file
    if (!this.args.allowMultiple && this.args.uploadJobs?.length) {
      this.cleanUploadJob(this.args.uploadJobs[0]);
    }

    if (file && file.size >= fileUploadLimit) {
      fuHelper.fileSizeErrorDialog();
      return false;
    }

    if (fuHelper.isImageFileTypeSupported(file.type)) {
      const params = {
        maxWidth: this.args.maxWidth || 100,
        maxHeight: this.args.maxHeight || 100,
        canvas: job.attachmentType === 'photo',
      };

      loadImage(file, params)
        .then((canvas) => {
          if (wasRunning) {
            return false;
          } else {
            wasRunning = true;
          }

          job.photoPreviewUrl = canvas.toDataURL();
        })
        .catch((error) => {
          if (error) {
            fuHelper.wrongFormatMsg();
          }

          return false;
        });
    }

    if (job.attachmentType !== 'photo' || file.size < photoMaxWeightToUploadToServer) {
      this.uploadFile(job);
    } else {
      resizeImage(file, {
        maxWidth: photoMaxWidthHeight,
        maxHeight: photoMaxWidthHeight,
      }).then((resizedFile) => {
        job.file = resizedFile;
        this.uploadFile(job);
      });
    }
  }

  uploadFile(job) {
    let formData = new FormData();
    formData.append('file', job.file);

    job.axoisUpload = CancelToken.source();

    axios({
      url: this.uploadUrl,
      method: 'POST',
      headers: { 'X-CSRF-Token': Session.getCsrfToken() },
      cancelToken: job.axoisUpload.token,
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          this.progress(job, data);
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(job, res.data);
      })
      .catch((e) => {
        this.fail(job, e.response);
      });

    this.args.updateUploadJobs?.('pushObject', job);
    this.uploadStatusChange();
  }

  progress(job, data) {
    this.safeAction(() => {
      job.progress = parseInt((data.loaded / data.total) * 100, 10);
    });
  }

  done(job, data) {
    this.safeAction(() => {
      job.isFinished = true;
      job.fileId = data.id;

      this.uploadStatusChange();

      if (typeof this.args.onUploadDoneFunc === 'function') {
        this.args.onUploadDoneFunc(data, job);
      }
    });
  }

  fail(job, response = {}) {
    if (response.status == 401) return;

    this.safeAction(() => {
      if (response.data && response.data.errorCode === 700) {
        this.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
      } else if (response.status) {
        FunctionalUtils.showDefaultErrorMessage();
      }

      this.cleanUploadJob(job);
    });
  }

  safeAction(doAfterUpload) {
    if (!this.isDestroyed && !this.isDestroying) doAfterUpload();
  }

  @action
  cleanUploadJob(job) {
    if (!job) return;

    this.safeAction(() => {
      if (job.axoisUpload) job.axoisUpload.cancel();
      this.args.updateUploadJobs?.('removeObject', job);
      this.uploadStatusChange();
    });
  }

  @action
  cleanAllUploadJobs() {
    this.safeAction(() => {
      each(this.uploadJobs, (job) => {
        if (job.axoisUpload) job.axoisUpload.cancel();
      });
      this.args.updateUploadJobs?.('clear');
    });
  }
}
