import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import utils from 'mewe/utils/environment-utils';

export default class MwHomeTextboxComponent extends Component {
  items = [
    { txt: __('Into running?'), imgNo: 1 },
    { txt: __('Into hiking?'), imgNo: 2 },
    { txt: __('Into dreaming?'), imgNo: 3 },
    { txt: __('Into music?'), imgNo: 4 },
    { txt: __('Into gardening?'), imgNo: 5 },
    { txt: __('Into swimming?'), imgNo: 6 },
    { txt: __('Into art?'), imgNo: 7 },
    { txt: __('Into reading?'), imgNo: 8 },
    { txt: __('Into hanging out?'), imgNo: 9 },
    { txt: __('Into dancing?'), imgNo: 10 },
    { txt: __('Into relaxing?'), imgNo: 11 },
    { txt: __('Into traveling?'), imgNo: 12 },
    { txt: __('Into ballooning?'), imgNo: 13 },
    { txt: __('Into cooking?'), imgNo: 14 },
    { txt: __('Into snorkeling?'), imgNo: 15 },
    { txt: __('Into fashion?'), imgNo: 16 },
    { txt: __('Into photography?'), imgNo: 17 },
    { txt: __('Into having fun?'), imgNo: 18 },
    { txt: __('Into family?'), imgNo: 19 },
    { txt: __('Into love?'), imgNo: 20 },
  ];

  timeout = 2000;

  @tracked currentItem;

  constructor() {
    super(...arguments);
    this.initialiseCarousel();
  }

  initialiseCarousel() {
    // select a random item from the list
    this.index = Math.round(Math.random() * (this.items.length - 1));
    this.preloadImage(this.index);
  }

  preloadImage(index) {
    let img = new Image();
    img.src = `${utils.getCdnHost()}/assets/images/homepage/homepage-image-${this.items[index].imgNo}.png`;
    img.onload = () => {
      if (this.isDestroyed || this.isDestroying) return;

      this.items[index].isLoaded = true;

      // first image is loaded, start the carousel
      if (!this.currentItem) {
        this.runCarousel();
      }

      let nextIndex;

      // after loading last image, go to first one
      if (index === this.items.length - 1) {
        nextIndex = 0;
      } else {
        nextIndex = index + 1;
      }

      // keep preloading following images as long as there are more to load
      if (!this.items[nextIndex].isLoaded) {
        this.preloadImage(nextIndex);
      }
    };
  }

  runCarousel() {
    this.currentItem = this.items[this.index];

    setInterval(() => {
      if (this.isDestroyed || this.isDestroying) return;

      let nextIndex;

      if (this.index === this.items.length - 1) {
        nextIndex = 0;
      } else {
        nextIndex = this.index + 1;
      }

      // show next image only if it's loaded, otherwise wait until next interval
      if (this.items[nextIndex].isLoaded) {
        this.currentItem = this.items[nextIndex];
        this.index = nextIndex;
      }
    }, this.timeout);
  }
}
