import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class MwPostSnippetHeaderComponent extends Component {
  @service dynamicDialogs;

  @service router;

  get post() {
    return this.args.post;
  }

  get group() {
    return this.post?.group;
  }

  get eventData() {
    return this.post?.event2;
  }

  get isContacts() {
    return this.post?.contacts || this.group?.id === 'contacts';
  }

  get isPage() {
    return this.args.postedByPage && this.post?.page?.publicLinkId;
  }

  get isGroup() {
    return this.group?.id && !this.isContacts;
  }

  get isEvent() {
    return this.eventData && !this.post?.eventInfo;
  }

  @action
  handleClick() {
    this.dynamicDialogs.closeAll();

    if (this.isGroup) {
      this.router.transitionTo('app.group', this.group?.id);
    } else if (this.isEvent) {
      this.router.transitionTo('app.event', this.post?.event2?.id);
    } else if (this.isPage) {
      this.router.transitionTo('app.publicid.feed', this.post?.page?.publicLinkId);
    } else if (this.isContacts) {
      window.location = '/myworld?scope=all';
    } else {
      this.router.transitionTo('app.group', this.group?.id);
    }
  }
}
