import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { WrapperScope } from 'mewe/constants';
import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';
import { getFeed } from 'mewe/fetchers/fetch-feed';
import { FeedTypes } from 'mewe/constants';

var model = Model.extend({
  id: attr('string'),
  createdAt: attr('string'),
  lastActivity: attr('number'),
  postsCount: attr('number', {
    defaultValue: 0,
  }),
  feed: attr('object', {
    defaultValue: function () {
      return getFeed(this.id, FeedTypes.WRAPPER);
    },
  }),
  type: attr('string'),
  scope: alias('type'),
  eventData: attr('object'),

  name: computed('scope', 'group.name', 'page.name', function () {
    if (this.get('scope') === WrapperScope.PAGE) {
      return this.get('page.name');
    } else if (this.get('scope') === WrapperScope.EVENT) {
      return this.get('eventData.name');
    } else if (this.get('scope') === WrapperScope.GROUP) {
      return this.get('group.name');
    }
  }),

  avatar: computed('scope', 'group.profileImage400x400FullUrl', 'page.avatar', function () {
    if (this.scope === WrapperScope.PAGE) {
      return this.get('page.avatar');
    } else if (this.scope === WrapperScope.GROUP) {
      return this.get('group.profileImage400x400FullUrl');
    }
  }),
});

model.reopenClass({
  resourceName: 'wrapper',
});

export default model;

export const toWrapper = (data) => s.deserializeOne(model, data);
