import { A } from '@ember/array';
import ClientPermissions from 'mewe/utils/permissions-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import isUndefined from 'mewe/utils/isUndefined';

export default {
  getPermittedToPostGroups(confirmedGroups, disableLimitedGroups) {
    let groups = confirmedGroups.filter((g) => (g.permissions || A()).indexOf(ClientPermissions.POST) !== -1);

    if (disableLimitedGroups) {
      groups = this.filterOutLimitedGroups(groups);
    }

    return groups;
  },

  filterOutLimitedGroups(groups) {
    return groups.filter((g) => (g.permissions || A()).indexOf(ClientPermissions.REQMODERATION) === -1);
  },

  getCurrentUserGroupMember(group) {
    let currentUser = CurrentUserStore.getState();

    return {
      confirmed: !isUndefined(group.role), // if there is no role field, it means current user is just invited to this group, so this is group preview
      role: { name: group.role || 'Invited' },
      user: {
        id: currentUser.id,
        name: currentUser.name,
        badges: currentUser.badges,
        _links: { avatar: { href: EnvironmentUtils.getCurrentUserAvatar(null, group.id, currentUser) } },
      },
      isCurrentUser: true,
    };
  },
};

export const getMembershipStatusInfo = (group = {}) => {
  let status;

  if (group.alreadyApplied)
    status = {
      text: __('Applied'),
      icon: 'svg_user_checked',
    };
  else if (group.isMember)
    status = {
      text: __('Joined'),
      icon: 'svg_ok_circle',
    };
  else if (group.isInvited) {
    status = {
      text: __('Invited'),
      icon: 'svg_user_checked',
    };
  }

  return status;
};

export const processMyContactsInGroups = (groups = [], membersData = {}) => {
  if (!groups.length || !membersData.groups?.length || !membersData.users?.length) {
    return;
  }

  membersData.groups.forEach((g) => {
    g.users?.forEach((uId, i) => {
      let user = membersData.users.find((user) => uId == user.id);

      // users have empty 'group' param in avatar url because they might be matched to
      // multiple groups, so we fill this param on our end for each group with its ID
      user._links.avatar.href = user._links.avatar.href.replace('group=', `group=${g.groupId}`);

      g.users[i] = user;
    });

    groups
      .find((gt) => gt.id == g.groupId)
      ?.setProperties({
        groupContacts: g.users,
        groupContactsTotalCount: g.users.length + g.others,
      });
  });
};

export const getGroupContactsText = (group) => {
  if (!group?.groupContacts?.length) {
    return;
  }

  if (group.groupContactsTotalCount === 1) {
    return __('inc. {userName}', {
      userName: group.groupContacts[0].firstName,
    });
  } else {
    return __('inc. {userName} and {count} other friends', {
      userName: group.groupContacts[0].firstName,
      count: group.groupContactsTotalCount - 1,
    });
  }
};
