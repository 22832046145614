import { A } from '@ember/array';
import EmberObject, { observer, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { TaskStates } from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PS from 'mewe/utils/pubsub';
import axios from 'axios';
import MathUtils from 'mewe/utils/math-utils';

const CancelToken = axios.CancelToken;
/*

var task = TaskManager.getTask('id');

task.push(Api.getSomething());

TaskManager.pushToTask('id', promise);

task.on('done') // responses

task.on('oneDone') // response

task.on('error') // error

task.off('done')

task.clear()

*/

let calculateProgress = (promisesA) => {
  let filtered = promisesA.filter((promise) => promise.progressId);
  // let done = promisesA.filter(promise => (promise.readyState == 4));

  let fakeProgress = 98;

  if (!promisesA.length) return 0;
  // if (done.length == promisesA.length) return 0;
  if (!filtered.length) return fakeProgress;

  let progressLoaded = filtered.reduce((sum, promise) => (sum + promise.progress ? promise.progress.loaded : 0), 0);
  let progressTotal = filtered.reduce((sum, promise) => (sum + promise.progress ? promise.progress.total : 0), 0);
  let result = parseInt((progressLoaded / progressTotal) * 100, 10);

  if (result === 0) return 1; // so when it's started, be visible

  return Math.min(fakeProgress, result); // because of normal (not upload) requests, it will be always set to some value lower than 100
};

export const taskAxios = (config) => {
  const source = CancelToken.source(),
    progressId = MathUtils.generateId();
  let progressHandler;

  config.cancelToken = source.token;

  config.onUploadProgress = (progressEvent) => {
    if (progressHandler) progressHandler(progressEvent);
  };

  let promise = axios(config);

  promise.cancel = source.cancel;
  promise.setProgressHandler = (handler) => {
    progressHandler = handler;
  };
  promise.progressId = progressId;

  return promise;
};

export default EmberObject.extend({
  dynamicDialogs: service(),

  state: TaskStates.UNSENT,

  progress: null,

  init() {
    this.promises = A();
    this.responses = A();
    this.errors = A();

    this._super(...arguments);
  },

  on(eventName, callback) {
    window.addEventListener(eventName, callback);
  },

  off(eventName, callback) {
    window.removeEventListener(eventName, callback);
  },

  taskStateObserver: observer('state', function () {
    if (this.state == TaskStates.DONE) {
      const eventDone = new CustomEvent(`done-${this.id}`);
      window.dispatchEvent(eventDone);

      window.onbeforeunload = null;
    }
  }),

  push(promise) {
    window.onbeforeunload = function () {
      return __('Uploading is in progress. Do you really want to leave without finishing?');
    };

    promise.then((data) => {
      this.responses.push(data);

      const eventOneDone = new CustomEvent(`oneDone-${this.id}`);
      window.dispatchEvent(eventOneDone, data);

      this.setProperties({
        progress: calculateProgress(this.promises),
      });
    });

    promise.catch((error) => {
      if (axios.isCancel(error)) return;
      this.get('errors').push(error);

      this.promises.forEach((promise) => {
        if (typeof promise.cancel === 'function') promise.cancel();
      });

      this.set('state', TaskStates.FAIL);

      const eventError = new CustomEvent(`error-${this.id}`);
      window.dispatchEvent(eventError, error);

      PS.Pub('close.crop.dialog');

      if (error.response.data && error.response.data.errorCode === 700) {
        this.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    });

    this.promises.push(promise);

    if (this.cancelAllDone) this.cancelAllDone();

    this.allDonePromise = new Promise((resolve, reject) => {
      Promise.allSettled(this.promises).then(resolve);

      this.cancelAllDone = function () {
        reject();
      };
    });

    this.allDonePromise
      .then(() => {
        this.set('state', TaskStates.DONE);
      })
      .catch(() => {
        // nothing to do there,
        // when added new promise to list, allDonePromise is cancelled and new one created
      });

    this.set('state', TaskStates.LOADING);
    this.set('progress', calculateProgress(this.promises));
  },

  abort() {
    this.promises.forEach((promise) => {
      if (typeof promise.cancel === 'function') promise.cancel();
    });
    window.onbeforeunload = null;
    this.setProperties({
      promises: A(),
      state: TaskStates.UNSENT,
      progress: calculateProgress(this.promises),
    });
  },

  clear() {
    this.promises.forEach((promise) => {
      if (typeof promise.cancel === 'function') promise.cancel();
    });
    window.onbeforeunload = null;
    this.setProperties({
      promises: A(),
      responses: A(),
      errors: A(),
      state: TaskStates.UNSENT,
      progress: 0,
    });
  },

  // to use in hbs
  isLoading: computed('state', function () {
    return this.state == TaskStates.LOADING;
  }),

  updateProgress(id, progressEvent) {
    let found = this.promises.find((promise) => promise.progressId == id);
    if (found)
      found.progress = {
        total: progressEvent.total,
        loaded: progressEvent.loaded,
      };
    this.set('progress', calculateProgress(this.promises));
  },
});
