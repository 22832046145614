/* eslint-disable no-undef */
/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable ember/require-tagless-components */
import { filterBy, and } from '@ember/object/computed';
import { A } from '@ember/array';
import Component from '@glimmer/component';
import { later } from '@ember/runloop';

import { attr } from 'mewe/utils/store-utils/model/attribute';
import Model from 'mewe/utils/store-utils/model/model.js';
import { emailRegex } from 'mewe/shared/utils';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

const nameRegex = /^[\w\d]+[\s\w\d\-]*/;

const User = Model.extend({
  email: attr('string'),
  fname: attr('string'),
  lname: attr('string'),

  emailValid: computed('email', function () {
    return emailRegex.test(this.email || '');
  }),

  emailValidOrEmpty: computed('email', function () {
    return !this.email || emailRegex.test(this.email);
  }),

  fnameValid: computed('fname', function () {
    return !this.fname || nameRegex.test(this.fname);
  }),

  lnameValid: computed('lname', function () {
    return !this.lname || nameRegex.test(this.lname);
  }),

  valid: and('emailValid', 'fnameValid', 'lnameValid'),

  validOrEmpty: and('emailValidOrEmpty', 'fnameValid', 'lnameValid'),
});

export default class MwInviteEmail extends Component {
  constructor() {
    super(...arguments);
    this.setInitialParams();
    this.addUser();
  }

  @tracked users = A();

  @tracked inputEl;

  placeholderText = __('name@example.com');

  setInitialParams() {
    if (this.args.isContactInvitation) {
      this.headerText = __('Invite friends by e-mail');
      this.addUserText = __('Add another e-mail address');
    } else {
      this.headerText = __('Invite members by email');
      this.addUserText = __('Add Another Member');
    }
  }

  @filterBy('users', 'valid', true)
  validUsers;

  @action
  addUser() {
    next(() => {
      this.users.pushObject(
        User.create({
          fname: '',
          lname: '',
        })
      );
    });
    later(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        // autofocus only not in contactInvitation (bad UX as it's at the bottom of dialog) or when added more then 1 user
        if (this.users.length > 1 || !this.args.isContactInvitation) {
          const nodes = this.inputEl;
          if (nodes?.length) {
            const last = nodes[nodes.length - 1];
            last?.focus();
          }
        }
      }
    });
  }

  @computed('users', 'validUsers.length')
  get invalid() {
    if (!this.validUsers.length) {
      return true;
    }

    return this.users.some((u) => !u.validOrEmpty);
  }

  @action
  setInputEl(el) {
    this.inputEl = el;
  }

  @action
  sendInvitations() {
    if (this.validUsers.length) {
      this.args.sendInvitations?.(this.validUsers);
    }
  }
}
