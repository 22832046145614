import Component from '@glimmer/component';
import { addObserver, removeObserver } from '@ember/object/observers';
import { computed } from '@ember/object';
import { action } from '@ember/object';

import Scrolling from 'mewe/utils/scrolling-utils';
import { isEdge } from 'mewe/shared/utils';
import dispatcher from 'mewe/dispatcher';

export default class MwUpcomingEventsList extends Component {
  element;

  constructor() {
    super(...arguments);

    this.scrolling = Scrolling();
    addObserver(this, 'args.collection.items.length', this.bindScrolling);
  }

  @action
  onInsert(element) {
    this.element = element;
    this.bindScrolling();
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element);
    removeObserver(this, 'args.collection.items.length', this.bindScrolling);
  }

  @computed('args.collection.{items.length,canShowMore}')
  get noEventsInfo() {
    return !this.args.collection?.canShowMore && this.args.collection?.items?.length === 0;
  }

  @computed()
  get isEdge() {
    return isEdge();
  }

  bindScrolling() {
    if (this.args.collection?.items?.length && this.args.collection?.canShowMore) {
      this.scrolling.bindScrollDownElement(
        this.element,
        () => {
          dispatcher.dispatch('events', 'loadMore', 'events', 'upcoming-events', true);
        },
        500
      );
    } else {
      this.scrolling.unbindScrollDown(this.element);
    }
  }

}