import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';

export default class MwEventSettingsSidebarLeft extends Component {
  @reads('args.eventData') eventData;

  @tracked activeSettingsTab;

  @computed('eventData.{participationType,groupId}')
  get showMembershipTab() {
    // don't show if it's group event and user is owner of this event - group events can't change event type and owner can't leave event so nothing to do in this tab
    return !this.eventData?.groupId || this.eventData?.participationType !== 'Owner';
  }

  @computed('eventData.group')
  get showPermissionsTab() {
    return !this.eventData?.group;
  }


  @action
  switchSettingsTab(tabIndex) {
    this.args.switchSettingsTab(tabIndex);
  }
}
