import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { set } from '@ember/object';

import PostApi from 'mewe/api/post-api';
import CommentsApi from 'mewe/api/comments-api';

export default class MwFollow extends Component {
  @alias('args.post')
  post;

  @alias('args.comment')
  comment;

  @computed('post.follows', 'comment.follows')
  get isFollowing() {
    if (this.post) {
      return this.post.follows;
    } else if (this.comment) {
      return this.comment.follows;
    }
    return false;
  }

  postFollow() {
    const callback = () => {
      set(this, 'post.follows', !this.post.follows);
    };

    if (this.post.follows) {
      PostApi.unfollowPost(this.post.postItemId).then(callback());
    } else {
      PostApi.followPost(this.post.postItemId).then(callback());
    }
  }

  commentFollow() {
    const callback = () => {
      set(this, 'comment.follows', !this.comment.follows);
    };

    if (this.comment.follows) {
      CommentsApi.unfollowComment(this.comment.id).then(callback());
    } else {
      CommentsApi.followComment(this.comment.id).then(callback());
    }
  }

  @action
  click() {
    if (this.post) {
      this.postFollow();
    } else if (this.comment) {
      this.commentFollow();
    }
  }
}
