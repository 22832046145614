import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class MwChatsOpened extends Component {
  @service chat;

  @action
  onInsert() {
    this.chat.calculateMaxAvailableChats();

    addObserver(this, 'args.state.chatCreationThread', this.chatCreationChange);
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.state.chatCreationThread', this.chatCreationChange);
  }

  chatCreationChange() {
    /*
     * chatCreationThread is a thread matched for currently
     * selected participants during chat creation
     * it changes after every participants change and
     * it should replace the thread before such change
     */
    let chats = A(this.args.state.openedThreads);
    let newChat = chats.find((c) => c.isNewChat);

    /*
     * if there is no opened small chat then chat creation
     * is happening on chat page in chat window
     */
    if (newChat) {
      let chatCreationThread = this.args.state.chatCreationThread;

      /*
       * chatCreationThread is returned from server for existing threads and
       * also for non existing threads
       * in second case it still has all attributes from model but empty messages
       */
      if (chatCreationThread) {
        chatCreationThread.set('selectedItems', newChat.selectedItems);
        let index = chats.indexOf(newChat);
        chats.replace(index, 1, [chatCreationThread]);
      }
    }
  }
}
