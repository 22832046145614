import PurchasesStore from 'mewe/stores/purchases-store';
import StoreApi from 'mewe/api/store-api';
import { A } from '@ember/array';

export const fetchStoreItems = () => {
  StoreApi.getStoreItems().then((data) => {
    PurchasesStore.send('handleStoreItems', data.item);
  });
};

export const fetchSingleItem = (itemId) => {
  return StoreApi.getSingleItemInfo(itemId).then((data) => {
    PurchasesStore.send('handleStoreItems', [data]);
  });
};

export const fetchPurchasedItems = () => {
  return StoreApi.getPurchased();
};

export const fetchSubscriptions = () => {
  PurchasesStore.getState().set('subscriptionsLoaded', false);

  StoreApi.getSubscriptions()
    .then((data) => PurchasesStore.send('handleSubscriptions', data.subscriptions))
    .catch(() => PurchasesStore.send('handleSubscriptions', []));
};

export const fetchPayments = () => {
  PurchasesStore.getState().set('paymentsLoaded', false);

  StoreApi.getPayments()
    .then((data) => PurchasesStore.send('handlePayments', data.items))
    .catch(() => PurchasesStore.send('handlePayments', []));
};
