import Component from '@glimmer/component';
import { action } from '@ember/object';

import 'mewe/assets/styles/scss/_error-pages.scss';

import { getQueryParam } from 'mewe/shared/utils';
import Session from 'mewe/shared/session';

export default class ErrorUnauthorizedRoute extends Component {
  @action
  loginRedirect() {
    Session.logout().finally(() => {
      if (getQueryParam('next')) {
        window.location = `/login?next=${getQueryParam('next')}${window.location.hash}`;
      } else {
        window.location = '/login';
      }
    });
  }

  @action
  myWorldRedirect() {
    window.location = '/myworld';
  }
}
