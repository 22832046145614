import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import PagesApi from 'mewe/api/pages-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsUsersBanned extends Component {
  @service router;

  constructor() {
    super(...arguments);
    this.scrolling = Scrolling();

    this.bindScrollDown();

    addObserver(this, 'args.model.banned.nextPage', this.bindScrollDown);
  }

  willDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'args.model.banned.nextPage', this.bindScrollDown);
  }

  loadMore() {
    var banned = ds.banned.for(this.args.model.pageId);

    if (banned.nextPage)
      dispatcher.dispatch('pages', 'fetchBanned', {
        pageId: this.args.model.pageId,
        ref: banned.nextPage.substr(-24),
      });
  }

  bindScrollDown() {
    if (this.args.model.banned.nextPage) {
      this.scrolling.bindScrollDown(() => {
        this.loadMore();
      }, 400);
    }
  }

  @action
  unban(user) {
    PagesApi.unban({ userId: user.id, pageId: this.args.model.pageId })
      .then(() => {
        this.args.model.banned.items.removeObject(user);
        this.args.model.page.bannedCount -= 1;

        if (!this.args.model.banned.items.length) {
          this.router.transitionTo('app.publicid.settings.users.followers');
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      });
  }

  @action
  closeDropdown(user) {
    set(user, 'open', false);
  }
}
