import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { next } from '@ember/runloop';

import ChatApi from 'mewe/api/chat-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { maxCustomChatNameLength } from 'mewe/constants';

export default class ChatInfoCover extends Component {
  @reads('args.thread') thread;

  @tracked chatName;
  @tracked isEditingChatName;

  @service chat;
  @service account;

  maxAvatars = 5;
  maxCustomChatNameLength = maxCustomChatNameLength;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onUpdate() {
    this.isEditingChatName = false;
  }

  @computed('thread._links.avatarLinkTemplate.href', 'thread.getUserChatParticipant')
  get userChatAvatar() {
    const participant = this.thread.getUserChatParticipant;
    const userChatAvatarTemplate =
      this.thread._links?.avatarLinkTemplate?.href || '/api/v2/photo/profile/{imageSize}/{userId}?f={fingerprint}';
    // TODO: ^ ideally link template should be updated after sending first chat message, test SG-17134 after improving this ^

    if (participant && userChatAvatarTemplate)
      return userChatAvatarTemplate
        .replace('{imageSize}', '150x150')
        .replace('{userId}', participant.id)
        .replace('{fingerprint}', participant.fingerprint);
  }

  @action
  nameChanged(event) {
    if (event.keyCode === 27) {
      this.closeNameEditing();
    } else if (event.keyCode === 13) {
      this.saveChatName();
    }
  }

  @action
  saveChatName() {
    this.closeNameEditing();

    const oldName = this.thread.name;
    let newName = this.chatName.trim();

    // don't save changes if there isn't any thread.name and user confirmed empty value
    if (newName === oldName || (!newName && !oldName)) return;

    // cut name to limit but after first checking it it's not the same as thread.name
    // because then it can be longer if it was not changed and contains particpant's names
    newName = newName.slice(0, maxCustomChatNameLength);

    set(this, 'thread.name', newName);

    ChatApi.renameChat(this.thread.id, { name: newName })
      .then(() => {
        FunctionalUtils.info(__('Chat Settings have been successfully saved.'));
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        set(this, 'thread.name', oldName);
      });
  }

  @action
  closeNameEditing() {
    this.isEditingChatName = false;
  }

  @action
  openNameEditing() {
    this.chatName = this.thread.threadName.slice(0, 25);
    this.isEditingChatName = true;

    next(this, () => {
      this.element.querySelector('.editable-wrapper .h-input_text').focus();
    });
  }
}
