import Component from '@glimmer/component';
import { getQueryStringParams } from 'mewe/shared/utils';

export default class MwHomeHeaderButton extends Component {

  // query params should be carried when switching between login/register routes
  // in order to not loose the possible context coming from public pages or invitation links
  get queryParams() {
    return getQueryStringParams();
  }

}
