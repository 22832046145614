/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { next } from '@ember/runloop';
import { getOwner } from '@ember/application';
import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import NotificationsApi from 'mewe/api/notifications-api';
import NotificationsStore from 'mewe/stores/notifications-store';
import EventsApi from 'mewe/api/events-api';
import fetchThreads from 'mewe/fetchers/fetch-threads';
import dispatcher from 'mewe/dispatcher';

export default class MwNotification extends Component {
  @service router;
  @service chat;
  @service settings;
  @service analytics;
  @service account;
  @service dynamicDialogs;

  get n() {
    return this.args.n;
  }

  get hasDetail() {
    return this.n.imageData || this.n.notificationType === 'contact_birthday' || this.n.docData || this.n.postGifUrl;
  }

  get showNotificationButtons() {
    return (
      this.n.isInvitationAccepted ||
      this.n.isLimited ||
      this.n.isGroupInvitation ||
      this.n.isEventInvitation ||
      this.n.showGroupLink ||
      this.n.isSelectiveGroupInvitation ||
      this.n.redirectToMigration
    );
  }

  get showRegularContent() {
    const nonRegularNotifications = [
      'generic',
      'web3_handle_ready',
      'web3_dark_theme_granted',
      'subscription_trial_ends',
      'cancelled_subscription_promo_code',
    ];

    return nonRegularNotifications.indexOf(this.n.notificationType) === -1;
  }

  openPostCallback(notification) {
    dispatcher.dispatch('notification', 'openPostCallback', notification);
    this.markVisited(notification);
  }

  @action
  markVisited(n) {
    if (n && !n.visited) {
      NotificationsApi.markVisited({ notificationId: n.id });
      set(this, 'n.visited', true);
    }
  }

  @action
  onClick(e) {
    const href = e.target.getAttribute('href');

    if (href) {
      return; // will already be redirected
    }

    if (e.target.closest('.notification-link')) {
      // postponed because calling any action immediately when clicked on Link would cause page reload
      next(this, () => {
        this.args.setNotificationsDropdownOpened?.(false);
        this.markVisited(this.n);
      });
      return;
    }

    this.openPostUnlessIsOnlyAction(this.n);
  }

  @action
  reject(notification) {
    notification.secondaryActionCalled = true;

    if (!notification.group && !notification.event) {
      throw 'post/comment rejecting from notification: group or event object is not specified.';
    }

    if (notification.notificationType === 'limited_post' || notification.notificationType === 'limited_post_edit') {
      const groupId = notification.group ? notification.group.id : null;
      const eventId = notification.event ? notification.event.id : null;
      const callback = () => {
        NotificationsStore.send('removeNotification', notification.id);
      };

      dispatcher.dispatch('feed', 'rejectPost', notification.postData.postItemId, eventId, groupId, callback);
    }
  }

  @action
  approve(notification) {
    notification.secondaryActionCalled = true;

    if (!notification.group && !notification.event) {
      throw 'post approving from notification: group or event object is not specified.';
    }

    if (notification.notificationType === 'limited_post' || notification.notificationType === 'limited_post_edit') {
      const groupId = notification.group ? notification.group.id : null;
      const eventId = notification.event ? notification.event.id : null;
      const callback = () => {
        NotificationsStore.send('removeNotification', notification.id);

        if (eventId) this.router.transitionTo('app.event', eventId);
        else this.router.transitionTo('app.group', groupId, { queryParams: {} });
      };

      dispatcher.dispatch('feed', 'approvePost', notification.postData.postItemId, eventId, groupId, callback);
    }
  }

  @action
  redirectToJoinPage(notification) {
    notification.secondaryActionCalled = true;

    if (!notification.group) {
      throw 'selective group invitation from notification: group object is not specified.';
    }

    if (
      !notification.group._links ||
      !notification.group._links.publicUrl ||
      !notification.group._links.publicUrl.href
    ) {
      throw 'selective group invitation from notification: _links object is not specified.';
    }
    window.location.href = notification.group._links.publicUrl.href + '?context=notification_center';
  }

  @action
  redirectToGroup(notification) {
    if (notification.secondaryActionCalled) {
      notification.secondaryActionCalled = false;
      return false;
    }

    if (!notification.group) {
      throw 'group invitation from notification: group object is not specified.';
    } else {
      this.router.transitionTo('app.group', notification.group.id, { queryParams: {} });
      this.args.setNotificationsDropdownOpened?.(false);
      this.markVisited(notification);
    }
  }

  @action
  redirectToGroupSettings(notification) {
    if (notification.group?.id) {
      this.router.transitionTo('app.group.settings', notification.group.id, { initialTabNumber: 1 });
      this.args.setNotificationsDropdownOpened?.(false);
      this.markVisited(notification);
    }
  }

  @action
  openPostUnlessIsOnlyAction(notification) {
    if (notification.notificationType === 'group_application_invitation') {
      this.redirectToJoinPage(notification);
    } else if (
      notification.notificationType === 'group_invitation' ||
      notification.notificationType === 'group_ownership_transferred'
    ) {
      this.redirectToGroup(notification);
    } else if (notification.notificationType === 'storage_exceeded') {
      dispatcher.dispatch('purchase', 'showItemDetails', 'storage');
    } else if (notification.notificationType === 'storage_exceeded') {
      dispatcher.dispatch('purchase', 'showItemDetails', 'storage');
    } else if (notification.notificationType === 'discover_feature_info') {
      this.redirectToGroupSettings(notification);
    } else if (notification.openProfile) {
      this.openProfile(notification);
    } else if (!notification.isOnlyAction) {
      this.openPost(notification);
    } else {
      // if there is no other action then still can click on notification to mark it as seen
      this.markVisited(notification);
    }
  }

  @action
  openPost(notification) {
    if (notification.secondaryActionCalled) {
      notification.secondaryActionCalled = false;
      return false;
    }

    this.args.setNotificationsDropdownOpened?.(false);
    this.openPostCallback(notification);
  }

  @action
  decline(notification) {
    notification.secondaryActionCalled = true;

    if (!notification.group) {
      throw 'group invitation declining from notification: group object is not specified.';
    }

    GroupApi.declineGroupInvitation(notification.group.id).then(() => {
      this.args.groups.removeObject(notification.group);

      FunctionalUtils.info(
        __('You declined to join the group "{groupName}"', {
          groupName: notification.group.name,
        })
      );

      // TODO - ideally we shoud remove just this one notification from the NotificationStore
      NotificationsStore.send('refreshStore');
    });
  }

  @action
  accept(notification) {
    notification.secondaryActionCalled = true;

    if (!notification.group) {
      throw 'group invitation accepting from notification: group object is not specified.';
    }

    let group = this.args.groups.find((g) => g.id === notification.group.id);

    let appRoute = getOwner(this).lookup(`route:app`);
    let appGroupRoute = getOwner(this).lookup(`route:app.group`);

    const callback = () => {
      FunctionalUtils.info(
        __('You joined the group {groupName}', {
          groupName: notification.group.name,
        })
      );

      // TODO - ideally we should remove just this one notification from the NotificationStore
      NotificationsStore.send('refreshStore');
      fetchThreads(); // refetch threads because after joining group, this group's chat should be somewhere on chats list
      this.router.transitionTo('app.group', notification.group.id, { queryParams: { isFirstGroupOpening: true } });

      if (appRoute && appGroupRoute) {
        let theme = appRoute.get('currentModel.theme');
        let currentGroupId = appGroupRoute.get('currentModel.groupId');

        if (theme === 'group' && currentGroupId && currentGroupId === notification.group.id) {
          location.reload();
        }
      }
    };

    if (group && !group.isConfirmed) {
      dispatcher.dispatch('group', 'acceptGroupInvitation', group, {
        context: 'notification_center',
      }, callback);
    } else {
      GroupApi.fetchGroupData(notification.group.id).then((data) => {
        dispatcher.dispatch('group', 'acceptGroupInvitation', data, {
          context: 'notification_center',
        }, callback);
      });
    }

    // accepting invitation will redirect user to group and show group FTUE dialog, close notifs to prevent other actions until then
    this.args.setNotificationsDropdownOpened?.(false);
  }

  @action
  going(notification) {
    EventsApi.setParticipation(notification.event.id, 'attending')
      .then(() => {
        if (!notification.event) {
          throw "event invitation set 'attending' from notification: event object is not specified.";
        }

        FunctionalUtils.info(
          __('You joined the event {eventName}', {
            eventName: notification.event.name,
          })
        );

        this.args.setNotificationsDropdownOpened?.(false);

        NotificationsStore.send('refreshStore');
        this.router.transitionTo('app.event', notification.event.id);
      })
      .catch((data) => {
        if (data && data.status === 404) {
          FunctionalUtils.error(__('Event has been cancelled.'));
          NotificationsStore.send('removeNotification', notification.id);
        }
      });
  }

  @action
  notGoing(notification) {
    EventsApi.setParticipation(notification.event.id, 'notattending')
      .then(() => {
        if (!notification.event) {
          throw "event invitation set 'notattending' from notification: event object is not specified.";
        }

        FunctionalUtils.info(
          __('You declined to join the event "{eventName}"', {
            eventName: notification.event.name,
          })
        );

        NotificationsStore.send('refreshStore');
      })
      .catch((data) => {
        if (data && data.status === 404) {
          FunctionalUtils.error(__('Event has been cancelled.'));
          NotificationsStore.send('removeNotification', notification.id);
        }
      });
  }

  @action
  previewGroup(notification) {
    this.router.transitionTo('app.group', notification.group.id);
  }

  @action
  openProfile(notification) {
    this.markVisited(notification);
    this.args.setNotificationsDropdownOpened?.(false);
    this.router.transitionTo('app.publicid.posts', notification.actingUsers[0].publicLinkId);
  }

  @action
  startChat(notification) {
    this.args.setNotificationsDropdownOpened?.(false);
    this.chat.openThreadByParticipants(notification.actingUsers, { userIsContact: true });
  }

  @action
  goToGroup(notification) {
    this.router.transitionTo('app.group', notification.group.id);
    this.args.setNotificationsDropdownOpened?.(false);
  }
}
