import { assert } from '@ember/debug';
import ApiUtil from './api';

/**
 * import ChatApi from 'mewe/api/chat-api';
 */
class Api extends ApiUtil {
  /**
   * addRequests - bool - will return also additional field requests if there are any chat requests pending
   */
  getThreads(params) {
    return this.post('/chat/threads', {
      data: JSON.stringify(params),
    });
  }

  /**
   * will return list of requests in same format as normal chats
   */
  getChatRequests(params) {
    let queryString = '';

    if (params && params.offset) {
      queryString = `?offset=${params.offset}`;
    }

    return this.get(`/chat/requests${queryString}`);
  }

  /**
   * return groups or contacts user can chat with - https://github.com/sgrouples/wiki/blob/master/api/ChatApi.md#get-chatcandidates
   */
  getChatCandidates(params) {
    return this.get('/chat/candidates', {
      data: params,
    });
  }

  ignoreAllChatRequests() {
    return this.del('/chat/requests/ignore');
  }

  allowAllChatRequests() {
    return this.put('/chat/requests/allow');
  }

  isChatRequestsAllowed() {
    return this.get('/chat/requests/allow');
  }

  denyAllChatRequests() {
    return this.del('/chat/requests/allow');
  }

  deleteAllChatRequests() {
    return this.del('/chat/requests');
  }

  removeThread(threadId) {
    assert('removeThread() threadId is missing', this.isDefined(threadId));

    return this.del(`/messages/remove-thread/${threadId}`);
  }

  createChatThread(params) {
    return this.post('/chat/thread', {
      data: JSON.stringify(params),
    });
  }

  getChatThread(threadId, params) {
    assert('getChatThread() threadId is missing', this.isDefined(threadId));

    return this.get(`/chat/thread/${threadId}`, {
      data: params,
    });
  }

  getChatThreadMessages(threadId, params) {
    assert('getChatThreadMessages() threadId is missing', this.isDefined(threadId));

    return this.get(`/chat/thread/${threadId}/messages`, {
      data: params,
    });
  }

  sendMessage(threadId, params) {
    assert('sendMessageToThread() threadId is missing', this.isDefined(threadId));

    return this.post(`/chat/thread/${threadId}/message`, {
      data: JSON.stringify(params),
    });
  }

  forward(params) {
    return this.post('/messages/forward', {
      data: params,
    });
  }

  chatSearch(params) {
    params.nm = 1;
    return this.get('/search/chat', {
      data: params,
    });
  }

  hideGroupChat(threadId) {
    return this.post('/messages/thread/' + threadId + '/hide');
  }

  unhideGroupChat(threadId) {
    return this.post('/messages/thread/' + threadId + '/unhide');
  }

  deleteMessage(messageId) {
    return this.del('/messages/message/' + messageId);
  }

  markAsRead(threadId) {
    return this.del('/messages/thread/' + threadId + '/unread');
  }

  getUnreadCount() {
    return this.get('/messages/unread-count');
  }

  chatTyping(threadId) {
    return this.post(`/messages/typing/${threadId}`);
  }

  seen(messageId) {
    return this.post('/messages/message/' + messageId + '/seen');
  }

  edit(threadId, messageId, text) {
    return this.put(`/chat/thread/${threadId}/message/${messageId}`, {
      data: JSON.stringify({
        text: text,
      }),
    });
  }

  deleteGroupChatHistory(groupId, password) {
    const params = {};
    // no password for web3 users
    if (password?.length) {
      params.password = password;
    }

    return this.del(`/chat/group/${groupId}/messages`, {
      data: params,
      contentType: 'application/x-www-form-urlencoded',
      non401: true,
    });
  }

  deleteEventChatHistory(eventId, password) {
    const params = {};
    // no password for web3 users
    if (password?.length) {
      params.password = password;
    }

    return this.del(`/chat/event/${eventId}/messages`, {
      data: params,
      contentType: 'application/x-www-form-urlencoded',
      non401: true,
    });
  }

  leaveChatThread(threadId) {
    return this.del('/chat/thread/' + threadId + '/leave');
  }

  setGroupChatMode(groupId, params) {
    return this.put(`/chat/group/${groupId}/settings`, {
      data: JSON.stringify(params),
    });
  }

  setEventChatMode(eventId, params) {
    return this.put(`/chat/event/${eventId}/settings`, {
      data: JSON.stringify(params),
    });
  }

  markAllAsRead() {
    return this.del('/chat/threads/unread');
  }

  openCall(threadId, params) {
    return this.post('/chat/thread/' + threadId + '/call', {
      data: JSON.stringify(params),
    });
  }

  dismissCall(threadId) {
    return this.del('/chat/thread/' + threadId + '/call');
  }

  getReceivers(threadId, params) {
    assert('getReceivers() threadId is missing', this.isDefined(threadId));

    return this.get(`/chat/thread/${threadId}/participants`, {
      data: params,
    });
  }

  postEmoji(options, emoji) {
    if (!options || !options.model) return;
    const threadId = options.model.threadId || options.model.thread.id;

    return this.post(`/chat/thread/${threadId}/message/${options.model.id}/emojis`, { data: JSON.stringify([emoji]) });
  }

  deleteEmoji(options, params) {
    if (!options || !options.model) return;
    const threadId = options.model.threadId || options.model.thread.id;
    params.emojis = encodeURIComponent(params.emojis);

    return this.del(`/api/v2/chat/thread/${threadId}/message/${options.model.id}/emojis?emojis=${params.emojis}`);
  }

  getEmoji(options, params) {
    if (!options || !options.model) return;
    const threadId = options.model.threadId || options.model.thread.id;

    if (params.nextPage) {
      return this.get(params.nextPage);
    }

    return this.get(`/chat/thread/${threadId}/message/${options.model.id}/emoji?emoji=${params.emoji}`);
  }

  getAllEmojis(options, params) {
    if (!options || !options.model) return;
    const threadId = options.model.threadId || options.model.thread.id;

    if (params.nextPage) {
      return this.get(params.nextPage);
    }

    return this.get(`/chat/thread/${threadId}/message/${options.model.id}/emojis`);
  }

  getMultiuserChatSettings(threadId) {
    return this.get(`/chat/thread/${threadId}/settings`);
  }

  setMultiuserChatSettings(threadId, params) {
    return this.put(`/chat/thread/${threadId}/settings`, {
      data: JSON.stringify(params),
    });
  }

  addUsersToChat(threadId, params) {
    return this.post(`/chat/thread/${threadId}/participants/add`, {
      data: JSON.stringify(params),
    });
  }

  removeChatParticipant(threadId, userId) {
    if (!threadId || !userId) return;

    return this.del(`/chat/thread/${threadId}/participants/${userId}`);
  }

  renameChat(threadId, params) {
    return this.post(`/chat/thread/${threadId}/name`, {
      data: JSON.stringify(params),
    });
  }

  getSettings() {
    return this.get('/privacymail/settings');
  }

  setSettings(params) {
    return this.post('/privacymail/settings', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
