import Component from '@glimmer/component';
import { action } from '@ember/object';
import Sortable from 'sortablejs';

export default class SortableObjects extends Component {
  sortableList;

  @action
  onInsert(element) {
    this.sortableList = new Sortable(element, {
      filter: '.ignore-sort-element',
      dataIdAttr: 'data-id',
      onStart: (event) => {
        event.item.style.opacity = '0.5';
      },
      onMove: (event) => {
        if (event.related.classList.contains('ignore-sort-element')) {
          return -1;
        }
      },
      onEnd: (event) => {
        event.item.style.opacity = '1';
        this.onDragEnd();
      },
    });
  }

  onDragEnd() {
    this.args.sorted(this.sortableList.toArray());
  }
}
