import { computed } from '@ember/object';
import Model from 'mewe/utils/store-utils/model/model.js';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';

import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend(modelProfileId, {
  init() {
    this.set('currentUser', CurrentUserStore.getState());
    this._super();
  },
  id: attr('string'),
  firstName: attr('string'),
  lastName: attr('string'),
  name: attr('string'),
  _links: attr('object'),
  isContact: attr('boolean'),
  verified: attr('boolean'),
  isConfirmed: attr('boolean'),
  donor: attr('boolean'),
  badges: attr('object'),
  role: attr('object'),
  invitation: attr('object'),
  canSendMessage: attr('boolean'),
  joined: attr('string'),
  group: attr('object'),
  invitedBy: attr('object'),
  invitedOn: attr('date'),
  publicLinkId: attr('string'),
  favorite: attr('boolean'),
  follower: attr('boolean'),
  following: attr('boolean'),
  followRequestSent: attr('string'),

  isCurrentUserOwnerOrAdmin: computed(function () {
    return this.get('group.isOwnerAdmin');
  }),

  isGroupOwner: attr('boolean'),

  // is current user an event owner?
  isCurrentUserEventOwner: attr('boolean'),

  isOwnerAdmin: computed('role.name', function () {
    return this.get('role.name') === 'Owner' || this.get('role.name') === 'Admin';
  }),

  isOwner: computed('role.name', function () {
    return this.get('role.name') === 'Owner';
  }),

  isAdmin: computed('role.name', function () {
    return this.get('role.name') === 'Admin';
  }),

  isViewer: computed('role.name', function () {
    return this.get('role.name') === 'Viewer';
  }),

  isContributor: computed('role.name', function () {
    return this.get('role.name') === 'Contributor';
  }),

  isCustom: computed('role.name', function () {
    return this.get('role.name') === 'Custom';
  }),

  isLimited: computed('role.name', 'role.origin', 'role.permissions', function () {
    return (
      this.get('role.name') === 'Limited' ||
      (this.get('role.name') === 'Custom' && this.get('role.origin') === 'Limited') ||
      this.get('role.permissions').includes('req_moderation')
    );
  }),

  canSendReminder: computed('invitation.reminders.canRemind', function () {
    return this.get('invitation.reminders.canRemind');
  }),

  reminderTitle: computed('invitation.reminders', function () {
    if (this.get('invitation.reminders')) {
      if (this.get('invitation.reminders.canRemind')) return __('Send Reminder');
      else if (this.get('invitation.reminders.remindSubscr')) {
        if (this.get('invitation.reminders.remindDelay')) return __('You can send only one reminder per hour');
        else return __('Cannot send reminder');
      } else {
        return __('User has unsubscribed from invitation reminders');
      }
    }
  }),

  isGroupMember: attr('boolean'),
  confirmed: attr('boolean'),
  invited: attr('boolean'),
  registered: attr('boolean'),

  isEventAttendee: attr('boolean'),
  participationType: attr('string'),
  canRemove: attr('boolean'), // field for event attendees
  fingerprint: attr('string'),
  fprint: attr('string'),
});

model.reopenClass({
  resourceName: 'member',
  aliases: {
    userId: 'id',
  },
});

export default model;
