import Model from 'mewe/utils/store-utils/model/model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { Post } from 'mewe/stores/models/post-model';

const Bookmark = Model.extend({
  id: attr('string'),
  createdAt: attr('number'),
  post: belongsTo(Post),
});

export default Bookmark;
