import Model from 'mewe/utils/store-utils/model/model.js';
import { attr, hasMany, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import EventModel from 'mewe/stores/models/event-model';
import Member from 'mewe/stores/models/member-model';
import { getOrCreate } from 'mewe/utils/fp';
import { ds } from 'mewe/stores/ds';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
const serializer = JSONSerializer.create();

let model = Model.extend({
  rootEvent: belongsTo(EventModel, { defaultValue: () => EventModel.create() }),
  isLoadingRootEvent: attr('boolean', { defaultValue: false }),

  eventSettings: attr('object'),

  eventAttendees: hasMany(Member),
  isFetchingAttendees: attr('boolean', { defaultValue: true }),
  noMoreAttendeesResult: attr('boolean', { defaultValue: false }),

  attendingCount: attr('number', { defaultValue: 0 }),
  interestedCount: attr('number', { defaultValue: 0 }),
  notattendingCount: attr('number', { defaultValue: 0 }),
  invitedCount: attr('number', { defaultValue: 0 }),
});

export const isLoading = (id) => getOrCreate(ds.eventPages, model, id).set('isLoadingRootEvent', true);

export const handleRootEvent = (data, callback) => {
  getOrCreate(ds.eventPages, model, data.id).setProperties({
    rootEvent: serializer.deserializeOne(EventModel, data, { isFetching: false }),
    isLoadingRootEvent: false,
  });

  // fetchEvent callback is postponed until this point when event is fetched AND stored (so it can be accessed from the store)
  callback?.(data);
};

export default model;
