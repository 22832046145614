/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Theme } from 'mewe/constants';

export default class MwInviteFriends extends Component {
  @service routing;
  @service dynamicDialogs;

  @action
  openDialogOrRoute(layout) {
    if (layout === 'myworld') {
      this.dynamicDialogs.openDialog('invitations-dialog', { isContactInvitation: true });
    } else if (layout === 'groups' || layout === 'pages') {
      this.dynamicDialogs.openDialog('browse-communities-dialog', {
        theme: layout === 'pages' ? Theme.PAGE : Theme.GROUP,
      });
    }
  }
}
