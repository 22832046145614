import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { factory as popup } from 'mewe/utils/popup-utils';
import GiphyPopup from 'mewe/pods/components/popups/mw-giphy-popup';

export default class MwAttachmentButton extends Component {
  @service pickers;
  element;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  openAttachmentDropdown() {
    this.args.setAttachmentDropdown(true);
  }

  @action
  openGiphyPopup() {
    let origin = '';

    if (this.args.fromChat) {
      origin = 'chat';
    }

    popup(this, GiphyPopup, 'giphy-popup')
      .create({
        selectedGifs: this.args.selectedGifs,
        parent: this.element,
        origin: origin,
        onSelect: (data) => this.args.setGif?.(data),
        onClose: () => this.args.closeAction(),
      })
      .send('open');
  }

  @action
  browseFiles() {
    this.args.browseFiles();
  }

  @action
  openPmPostbox() {
    this.args.openPrivacyMailPostbox('mini-chat');
    this.args.setAttachmentDropdown(false);
  }

  @action
  openAudioRecording() {
    this.args.openAudioRecording();
    this.args.setAttachmentDropdown(false);
  }

  @action
  openStickerPicker() {
    this.pickers.openStickerPicker(this);
  }

  setSticker(sticker) {
    this.args.setSticker(sticker);
  }

  @action
  closeDropdown() {
    this.args.setAttachmentDropdown(false);
  }
}
