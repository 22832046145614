/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { scheduleOnce, next } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import FunctionalUtils from 'mewe/shared/functional-utils';
import Emoji from 'mewe/utils/emoji-utils';
import { findGifUrl } from 'mewe/utils/gif-utils';
import Mentions from 'mewe/utils/mentions-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import PS from 'mewe/utils/pubsub';
import { Theme } from 'mewe/constants';
import PostApi from 'mewe/api/post-api';
import ChatApi from 'mewe/api/chat-api';
import 'mewe/services/chat';
import { hasOnlyWhitespaces } from 'mewe/utils/text-editor-utils';
import { setupEmbeddedVideoPlayer, teardownEmbeddedVideoPlayer } from 'mewe/utils/video-utils';
import { isSafari } from 'mewe/shared/utils';
import { toPost } from 'mewe/stores/models/post-model';
import { getFeed, handleOtherStores } from 'mewe/fetchers/fetch-feed';
import dispatcher from 'mewe/dispatcher';
import storage from 'mewe/shared/storage';

export default class MwChatMessage extends Component {
  @service dynamicDialogs;
  @service dynamicPopups;
  @service account;
  @service router;
  @service chat;

  @reads('args.thread') thread;
  @reads('args.isSmallChat') isSmallChat;
  @reads('args.isChatWindow') isChatWindow;
  @reads('args.chatInfoActive') chatInfoActive;

  @tracked showEditedPopupClass;
  @tracked isRevealed;
  @tracked storyPreviewUrl;
  @tracked isMsgMouseOver;
  @tracked mentionsEditStrategy;
  @tracked gifModel;
  @tracked gifHrefPath;
  @tracked showMoreVisible;
  @tracked model;
  @tracked isSafari;
  @tracked videoPlayPromise;
  @tracked element;

  chatApi = ChatApi;

  setupEmbeddedVideoPlayer = setupEmbeddedVideoPlayer;
  teardownEmbeddedVideoPlayer = teardownEmbeddedVideoPlayer;

  profilePopupSelector = '.chat-message_body .h-mention';

  constructor() {
    super(...arguments);

    this.setInitialArgs();

    addObserver(this, 'message.isInEditMode', this.editModeChange);
    addObserver(this, 'message.highlight', this.messageHighlighted);
  }

  get videoFeedAutoPlaySetting() {
    return storage.get(storage.keys.videoFeedAutoPlaySetting);
  }

  get message() {
    return this.args.message;
  }

  setInitialArgs() {
    if (this.message.attachments?.[0]?.animated) {
      this.gifModel = this.message.attachments[0];
      this.gifHrefPath = '_links.self.href';
    }

    this.showMoreVisible = false;
    this.model = this.message;
    this.isSafari = isSafari();

    if (this.message.story) {
      this.setStoryThumbnail();
    }

    this.showProfilePopupBind = this.showProfilePopup.bind(this);
    this.setMouseOverBind = this.setMouseOver.bind(this);
  }

  @action
  onInsert(element) {
    this.element = element;

    // don't move this to didRender, as didRender is not called if e.g. a ws msg is replaced with one from ChatStore.handleThread or vice versa
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      // if message other than text then trigger 'contentRendered', otherwise text-truncate will trigger that after rendering text
      if (
        this.message.deleted ||
        this.message.privatePost ||
        this.message.call ||
        this.message.expiresIn ||
        this.message.chatEventMessage ||
        !this.message.textDisplay?.length
      ) {
        this.contentRendered();
      }
    });
  }

  @action
  onDestroy() {
    removeObserver(this, 'message.isInEditMode', this.editModeChange);
    removeObserver(this, 'message.highlight', this.messageHighlighted);

    if (this.message.isInEditMode) {
      this.cancelEditingMessage();
    }

    PS.Pub('close.dropdowns.chat-options');
  }

  editModeChange() {
    if (this.message.isInEditMode) {
      next(this, () => this.initEditing());
    }
  }

  messageHighlighted() {
    if (this.message.highlight) {
      this.element.scrollIntoView({ block: 'center' });
      this.chat.scheduleStopHighlight(this.thread.id, this.message.id);
    }
  }

  @computed('message.{replyTo,deleted}')
  get messageReplyClass() {
    const reply = this.message.replyTo;

    if (!reply || this.message.deleted) {
      return '';
    } else if (
      (reply.gifUrls && reply.gifUrls.length) ||
      (reply.attachment && (reply.attachment.aType === 'photo' || reply.attachment.aType === 'video')) ||
      reply.sticker
    ) {
      return 'chat-message--reply chat-message--reply-img';
    } else {
      return 'chat-message--reply chat-message--reply-oneline';
    }
  }

  @computed('message.{privatePost,call,expiresIn}')
  get canReplyTo() {
    return !this.message.privatePost && !this.message.call && !this.message.expiresIn;
  }

  @computed('isSmallChat', 'canReplyTo', 'message.failedToSend')
  get showReplyOption() {
    return this.canReplyTo && !this.isSmallChat && !this.message.failedToSend;
  }

  @computed('message.isConsecutiveReverse', 'storyPreviewUrl')
  get showMessagePointer() {
    return this.storyPreviewUrl || !this.message.isConsecutiveReverse;
  }

  @computed('message.chatEventMessage.length', 'message.textDisplay.length', 'message.{isInEditMode,story}')
  get doNotDisplayMessage() {
    return !this.message.textDisplay?.length && !this.message.chatEventMessage?.length && !this.message.story;
  }

  @computed('element')
  get emojisListElement() {
    return this.element.querySelector('.message_emojis-list');
  }

  @computed('thread.isNotAContact', 'isRevealed', 'message.isMyMessage')
  get isPhotoRevealButtonPresented() {
    return this.thread.isNotAContact && !this.isRevealed && !this.message.isMyMessage;
  }

  @computed('thread.expandedChatSize', 'isChatWindow')
  get isExpanded() {
    return this.thread.expandedChatSize && !this.isChatWindow;
  }

  @computed('isChatWindow', 'thread.expandedChatSize', 'chatInfoActive')
  get getImgMaxWidth() {
    // mobile view
    if (window.innerWidth <= 865 && this.isChatWindow) {
      let maxWidth = window.innerWidth - 110;
      if (window.innerWidth > 620) {
        maxWidth -= 245;
      } // left sidebar is visible, subtract its width and margin
      return maxWidth;
    }

    if (this.isChatWindow) {
      return this.chatInfoActive ? 290 : 480;
    } else {
      return this.thread.expandedChatSize ? 350 : 170;
    }
  }

  @computed('isChatWindow', 'thread.expandedChatSize')
  get getImgMaxHeight() {
    if (window.innerWidth <= 865 && this.isChatWindow) {
      return 200;
    }

    if (this.isChatWindow) {
      return 300;
    } else {
      return this.thread.expandedChatSize ? 200 : 140;
    }
  }

  @computed('getImgMaxWidth')
  get messageAudioWidth() {
    const padding = 2 * 8;
    return this.getImgMaxWidth - padding;
  }

  @computed('message.{story,textServer}', 'messageTextDisplayWithOptions')
  get isSticker() {
    if (this.message.story) {
      return false;
    }
    return Emoji.isValidSticker(this.messageTextDisplayWithOptions);
  }

  @computed('message.textServer')
  get isGif() {
    const text = this.message.textServer;
    // checking if there is gif url but also if there is other text, return true only for gifs without other text
    const textWithoutSpaces = text.length === text.replace(' ', '').length;
    return textWithoutSpaces && !!findGifUrl(text);
  }

  @computed('isChatWindow', 'message.privatePost')
  get showSidebarTime() {
    return this.isChatWindow && !this.message.privatePost;
  }

  // when non 1-1 chat we show user name above avatar
  @computed('message.{isMyMessage,isConsecutiveReverse,privatePost}', 'isChatWindow', 'thread.isUserChat')
  get showAuthorAbove() {
    return (
      !this.thread.isUserChat &&
      !this.message.isMyMessage &&
      !this.message.isConsecutiveReverse &&
      (!this.message.privatePost || !this.isChatWindow)
    );
  }

  // time above messages - only in chat box; in chat window time is on the right of the message
  @computed('isChatWindow', 'message.isConsecutiveReverse')
  get showTimeAbove() {
    return !this.isChatWindow && !this.message.isConsecutiveReverse;
  }

  // show message author name when applicable, additionaly message time if it's not chat window
  @computed('showTimeAbove', 'showAuthorAbove', 'message.chatEventMessage')
  get showDetailsAbove() {
    return !this.message.chatEventMessage && (this.showTimeAbove || this.showAuthorAbove);
  }

  @computed('message.{deleted,emojisItems.length}')
  get messageEmojisVisible() {
    return this.message.emojisItems?.length && !this.message.deleted;
  }

  @computed('getImgMaxHeight', 'getImgMaxWidth', 'message.textDisplay', 'thread.isChatRequest')
  get messageTextDisplayWithOptions() {
    return this.message.getTextDisplayWithOptions({
      maxWidth: this.getImgMaxWidth,
      maxHeight: this.getImgMaxHeight,
      disableHrefs: this.thread.isChatRequest,
    });
  }

  @computed('isSticker', 'isGif', 'message.{aType,deleted}')
  get msgWithoutBubble() {
    let msgAttachment = this.message.aType;

    if (this.message.deleted) {
      return false;
    } else if (msgAttachment === 'photo' || msgAttachment === 'video') {
      return true;
    } else {
      return this.isSticker || this.isGif;
    }
  }

  @computed('message.{deleted,isMyMessage,call}')
  get messageBubbleColorClass() {
    if (this.message.deleted) {
      return 'bubble-grey';
    }

    const isAccentBubble = (this.message.isMyMessage || this.authorOwnerAdminText) && !this.message.call;

    return isAccentBubble ? 'bubble-accent' : 'bubble-white';
  }

  @computed('message.author.{name,isAdmin,isOwner}')
  get messageAuthorTitle() {
    const author = this.message.author || {};
    const badge = author.isOwner ? __('Owner') : author.isAdmin ? __('Admin') : '';
    return htmlSafe(`${author.name} - ${badge}`);
  }

  @computed('message.author.{isAdmin,isOwner}')
  get authorOwnerAdminText() {
    if (this.message.author?.isOwner) {
      return __('Owner');
    } else if (this.message.author?.isAdmin) {
      return __('Admin');
    } else {
      return '';
    }
  }

  @computed('message.isTranslated', 'account.temporaryLanguage')
  get displayTranslationFeature() {
    // message language is not always available or is not detected on the server, in that case we don't display the feature
    const isLangDifferent = this.message.language ? this.account.currentTranslationLanguage !== this.message.language : false;
    const notMyProfile = this.account.activeUser.id !== this.message.authorId;
    const canTranslate = isLangDifferent && notMyProfile && this.message.text && this.hasMinimumRequiredChars;
    return this.message.isTranslated || canTranslate;
  }

  get hasMinimumRequiredChars() {
    return this.message.text.length > this.account.minimumNumberOfCharacters;
  }

  get context() {
    if (this.thread.isGroupChat) {
      return 'group';
    } else if (this.thread.isEventChat) {
      return 'event';
    } else if (this.thread.isUserChat || this.thread.isMultiUsersChat) {
      return 'contacts';
    }
    return null;
  }

  setStoryThumbnail() {
    // show story preview when: story of current user OR story still active/isLive OR stori is in journal
    // BE logic for that: SG-27317
    const isMyStory = this.message.story?.storytellerId === this.account.activeUser.id;
    const showPreview = isMyStory || this.message.story?.isLive || this.message.story?.journalId;
    let url, img;

    if (showPreview) {
      if (this.message.story?.media?.mediaType === 'Video') {
        url = this.message.story.media._links?.thumbnail?.href.replace('{imageSize}', '400x400');
      } else {
        url = this.message.story.media._links?.media?.href.replace('{imageSize}', '400x400');
      }

      this.storyPreviewUrl = url;

      img = new Image();
      img.src = url;

      img.onerror = () => {
        this.unsetStoryThumbnail();
      };
    }
  }

  unsetStoryThumbnail() {
    if (!this.isDestroying && !this.isDestroyed) {
      this.storyPreviewUrl = null;
    }
  }

  hideEditingMessage() {
    if (!this.isDestroyed && !this.isDestroying) {
      set(this, 'message.isInEditMode', false);
    }
  }

  getOriginalTextServer() {
    return toServer(this.originalText, {
      mentionsStrategy: this.mentionsEditStrategy,
      gifUrls: this.getSelectedGifUrls(),
      parseNativeMarkdown: true,
    });
  }

  getEditedTextServer() {
    return toServer(this.message.textEdit, {
      mentionsStrategy: this.mentionsEditStrategy,
      gifUrls: this.getSelectedGifUrls(),
      parseNativeMarkdown: true,
    });
  }

  getSelectedGifUrls() {
    return [...document.querySelectorAll('.selected-gif img')].map((g) => g.src);
  }

  showProfilePopup(target) {
    if (this.isDestroyed && !this.isDestroying) {
      return;
    }

    this.dynamicPopups.openPopup('mw-profile-popup', {
      parent: target,
      owner: { id: target.getAttribute('data-userid') },
      group: this.thread.group,
      insideAnotherScrollable: true,
      inChatMessage: true,
      isMention: true,
    });
  }

  setMouseOver(value, e) {
    if (value === false) {
      if (e.toElement?.classList.contains('c-mw-simple-emoji-picker')) {
        return;
      }
    }
    this.isMsgMouseOver = value;
  }

  @action
  confirmDelete(rollback) {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Delete {count} Message', { count: 1 }),
      message: __('Are you sure you want to delete this message? You will not be able to undo this.'),
      cancelButtonText: __('Abort'),
      okButtonText: __('Delete Message'),
      onConfirm: () => {
        dispatcher.dispatch('chat', 'markAsDeleted', this.thread.id, this.message.id);
      },
      onCancel: () => {
        rollback?.();
      },
    });
  }

  @action
  initEditing() {
    set(this, 'message.isInEditMode', true);
    let mentionsScope = {};

    this.originalText = this.message.textServer;

    if (this.thread.isGroupChat) {
      mentionsScope.groupId = this.thread.id;
    } else if (this.thread.isEventChat) {
      mentionsScope.eventId = this.thread.event.id;
    } else {
      mentionsScope.users = this.thread.observableOthers;
    }

    this.mentionsEditStrategy =
      this.args.mentionsStrategy || Mentions.createTextCompleteStrategy(mentionsScope, this.message);
  }

  @action
  saveEditedMessage() {
    let newValue = this.getEditedTextServer();
    let oldValue = this.getOriginalTextServer();

    if (newValue.length === 0 || hasOnlyWhitespaces(newValue)) {
      set(this, 'message.textEdit', '');

      this.confirmDelete(() => {
        set(this, 'message.textEdit', oldValue);
      });

      this.hideEditingMessage();
      return;
    }

    this.hideEditingMessage();

    if (newValue === oldValue) {
      //value didn't change no need to save
      set(this, 'message.textServer', newValue); // need to set so textServer matches textEdit
      this.args.focusTextarea();
      return;
    }

    set(this, 'message.textServer', newValue);
    set(this, 'message.editedAt', new Date().getTime() / 1000);

    ChatApi.edit(this.thread.id, this.message.id, newValue)
      .catch(() => {
        FunctionalUtils.error(__(`Couldn't edit message`));
        // rollback
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        set(this, 'message.textServer', this.getOriginalTextServer());
      })
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        set(this, 'message.editedAt', data.editedAt);
        this.args.focusTextarea();
      });
  }

  @action
  keyUpAction(text, event) {
    // Escape
    if (event.keyCode === 27) {
      this.cancelEditingMessage();
    }
  }

  @action
  cancelEditingMessage() {
    set(this, 'message.textServer', this.getOriginalTextServer());
    set(this, 'message.text', this.getOriginalTextServer());
    this.hideEditingMessage();
    this.args.focusTextarea();
  }

  @action
  openProfile() {
    const groupId = this.thread.chatType === 'GroupChat' ? this.thread.id : null;
    const eventId = this.thread.chatType === 'EventChat' ? this.thread.event.id : null;
    const owner = this.message.author || this.message.owner;

    PS.Pub('close.dropdowns.chat-options');

    if (owner) {
      const ownerId = owner.id || owner.userId;

      if (eventId) {
        this.router.transitionTo('app.event.attendees.profile', eventId, ownerId);
      } else if (groupId && groupId !== 'contacts') {
        this.router.transitionTo('app.group.members.profile', groupId, ownerId);
      } else {
        this.router.transitionTo('app.publicid', owner.publicLinkId);
      }
    }
  }

  @action
  openPhotoAttachment(attachment) {
    let params;
    const openMediaDialog = (params) => {
      this.dynamicDialogs.openDialog('media-dialog', params);
      // blur chat text editor to prevent closing chat when MediaDialog is closed by ESC key (SG-40536)
      next(this, () => document.activeElement.blur());
    };

    if (attachment.animated) {
      params = {
        mediaType: 'attachment',
        dataProvided: true,
        attachment,
      };
    } else if (attachment._links && attachment._links.self) {
      params = {
        mediaType: 'chat',
        attachment,
        order: 0,
        chatGallery: true,
        threadId: this.thread.id,
        thread: this.thread,
      };
    }

    openMediaDialog({
      ...params,
      allowMultipleInstances: true,
    });
  }

  @action
  openPost() {
    const msg = this.message;
    const post = msg && msg.privatePost instanceof Object ? msg.privatePost : msg; // second option in case of expanded chat dialog

    if (post && post.postItemId) {
      PostApi.getPostDetails({
        scope: Theme.PRIVATEPOSTS,
        postItemId: post.postItemId,
        threadId: msg.threadId,
      })
        .then((data) => {
          handleOtherStores(data);

          if (data.post) {
            data.post.threadId = msg.threadId;
          }

          let postE = toPost(data.post);

          getFeed('single-posts', 'single-posts').get('posts').unshiftObject(postE);

          this.dynamicDialogs.openDialog('single-post-dialog', {
            post: postE,
            threadId: msg.threadId,
            scope: Theme.PRIVATEPOSTS,
          });
        })
        .catch((resp) => {
          if (resp && resp.status === 404) {
            msg.setProperties({
              deleted: true,
              privatePost: null,
            });
            FunctionalUtils.error(__('Sorry, this post has been deleted by the owner.'));
          }
        });
    }
  }

  @action
  markAsRevealed() {
    setTimeout(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.isRevealed = true;
      }
    }, 350);
  }

  @action
  setEditedPopupOffset() {
    const iconPos = this.element.querySelector('.chat-message_edited').getBoundingClientRect();
    const chatPos = this.element.closest('.chat-window, .small-chat').getBoundingClientRect();
    const spaceLeft = iconPos.left - chatPos.left;
    const spaceRight = chatPos.right - iconPos.right; // 15px is edited icon width

    this.showEditedPopupClass = spaceLeft > spaceRight ? 'popup-left' : 'popup-right'; // it's both flag to show popup and class to use for popup
  }

  @action
  closeEditedPopup() {
    this.showEditedPopupClass = null;
  }

  @action
  contentRendered() {
    const isScrollTo = this.message.id === this.thread.scrollTo;
    const isOldestMessage = this.message.id === this.thread.messages[this.thread.messages.length - 1]?.id;

    if (this.message.isWs || this.message.isPre || isScrollTo || isOldestMessage) {
      // notifying containing chat about rendered message
      this.args.messageRendered(this.message, this.element);
    }

    if (isScrollTo && !this.message.highlight) {
      set(this, 'message.highlight', true);
    }
  }

  @action
  playVideo(id) {
    try {
      this.element.querySelector(`#placeholder${id}`).remove();
      this.videoPlayPromise = this.element.querySelector(`#video${id}`).play();
    } catch (e) {
      throw new Error(e);
    }
  }

  @action
  openCall() {
    const options = {
      thread: this.thread,
      videoEnabled: this.message.call?.video,
    };

    dispatcher.dispatch('chat', 'openCall', options);
  }

  @action
  openStory() {
    const story = this.message.story;
    const isMyStory = story?.storytellerId === this.account.activeUser.id;
    const storyAuthor = isMyStory ? this.account.activeUser : this.thread.getUserChatParticipant;

    const params = {
      tellerType: 'User',
      tellerId: storyAuthor.id,
      tellerName: storyAuthor.name,
      avatarHref: storyAuthor._links.avatar.href,
      preloadedEntries: [story],
      initialStoryId: story.storyId,
      initialStory: story,
      isChatReply: true,
      storyLoadError: () => {
        FunctionalUtils.error(__('This story can no longer be viewed.'));
      },
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  retrySending() {
    if (!this.message.isSending) {
      set(this.message, 'isSending', true);
      dispatcher.dispatch('chat', 'retrySending', this.thread, this.message.preId);
    }
  }

  @action
  onVideoEnter(element) {
    // usecase: MD is opened with video plaing inside, small chat pops up in the background
    // when receiving a new message with video, we don't want to pause the video in MD and play the one from chat
    if (this.dynamicDialogs.hasAnyDialogOpened) return;

    const videoEl = element.querySelector('video');
    this.setupEmbeddedVideoPlayer(videoEl);

    if (videoEl) {
      videoEl.onplaying = () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isVideoPlaying = true;
      };
      videoEl.onpause = () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isVideoPlaying = false;
      };
      videoEl.onvolumechange = () => {
        if (this.isVideoPlaying) {
          storage.set(storage.keys.unmuteVideos, !videoEl.muted);
        }
      };
      videoEl.oncanplay = () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        if (
          !this.isVideoPlaying &&
          !this.pageReloaded &&
          (this.videoFeedAutoPlaySetting === null || this.videoFeedAutoPlaySetting)
        ) {
          videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;
          this.videoPlayPromise = videoEl.play().catch((e) => {
            if (e.code === DOMException.ABORT_ERR) {
              return;
            }
            throw e;
          });
        }
      };
      if (
        videoEl.paused &&
        !document.webkitCurrentFullScreenElement &&
        (this.videoFeedAutoPlaySetting === null || this.videoFeedAutoPlaySetting)
      ) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.pageReloaded = true;

        videoEl.muted = true;
        videoEl.currentTime = 0;
        this.videoPlayPromise = videoEl.play().catch((e) => {
          if (e.code === DOMException.ABORT_ERR) {
            return;
          }
          throw e;
        });
        this.videoPlayPromise.then(() => {
          videoEl.muted = storage.get(storage.keys.unmuteVideos) !== true;
          this.pageReloaded = false;
        });
      }
    }
  }

  @action
  onVideoExit(element) {
    const videoEl = element.querySelector('video');
    if (videoEl && !videoEl.paused && !document.webkitCurrentFullScreenElement) {
      if (this.videoPlayPromise) {
        this.videoPlayPromise.then(() => {
          videoEl.pause();
          this.videoPlayPromise = null;
        });
      } else if (!this.videoFeedAutoPlaySetting) {
        videoEl.pause();
      }

      this.teardownEmbeddedVideoPlayer(videoEl);
    }
  }

  @action
  setShowMoreVisible(value) {
    this.showMoreVisible = value;
  }

  @action
  setGifModel(gifModel) {
    this.gifModel = gifModel;
  }

  @action
  handleTranslations(translation) {
    if (translation && !translation.errorCode) {
      this.message.text = translation.data.text;
    }
  }
}
