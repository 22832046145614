import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { computed, action, get, set } from '@ember/object';
import { compact, includes } from 'lodash';
import { bind } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import ContactsStore from 'mewe/stores/contacts-store';
import { fetchFollowers } from 'mewe/fetchers/fetch-followers';
import { isEdge } from 'mewe/shared/utils';
import { maxChatSuggestions, ChatFilter } from 'mewe/constants';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import dispatcher from 'mewe/dispatcher';

const THREAD_HEIGHT = 50;
const FILTERS_HEIGHT = 32;

export default class MwChatsList extends Component {
  @service chat;
  @service account;

  @tracked contactsCollection;

  isEdge = isEdge();
  scrolling = Scrolling();
  dummyThreads = new Array(6);

  constructor() {
    super(...arguments);

    this.contactsCollection = ContactsStore.getState({ id: `${this.account.activeUser.id}-followers` });
    this.updateChatFilterBind = this.updateChatFilter.bind(this);
  }

  get chatState() {
    return this.args.chatState;
  }

  @action
  onInsert(element) {
    this.element = element;

    this.resizeBind = bind(this, this.resize);

    PS.Sub('chat.resize', this.resizeBind);

    this.scrolling.bindScrollDownElement(this.element, () => this.doLoad(), 500);

    if (this.chatState.threadsInited) {
      this.fetchFollowers();
    }
    addObserver(this, 'chatState.threadsInited', this.fetchFollowers);

    addObserver(this, 'filter', this.bindScrolling);
    addObserver(this, 'args.isChatRequestsPage', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element);
    removeObserver(this, 'filter', this.bindScrolling);
    removeObserver(this, 'args.isChatRequestsPage', this.bindScrolling);
    removeObserver(this, 'chatState.threadsInited', this.fetchFollowers);
    PS.Unsub('chat.resize', this.resizeBind);
  }

  // re-bind scrolling after switching filter or between threads/chat requests
  bindScrolling() {
    this.scrolling.unbindScrollDown(this.element);
    this.element.scrollTop = 0;
    this.scrolling.bindScrollDownElement(this.element, () => this.doLoad(), 500);
  }

  @computed('filter')
  get showSuggestions() {
    return !this.filter || this.filter === ChatFilter.ALL;
  }

  @computed('args.filter', '_filter')
  get filter() {
    return this._filter ?? this.args.filter;
  }

  updateChatFilter(filter) {
    set(this, '_filter', filter);
  }

  resize() {
    clearTimeout(this.resizeTimeout);

    // after resizing window check if chat threads fill the list fully, if not then load more
    // (the goal is to avoid situation when list is not scrollable and pagination can't be triggered by scroll)
    this.resizeTimeout = setTimeout(() => {
      const hasMoreThreadsToLoad = this.args.isChatRequestsPage
        ? this.chatState.chatRequests.length >= 20
        : this.chatState?.threads?.length >= 20;
      const isListNonScrollable =
        this.element.offsetHeight > this.chatState.threadsToDisplay.length * THREAD_HEIGHT + FILTERS_HEIGHT;
      if (hasMoreThreadsToLoad && isListNonScrollable) {
        this.doLoad();
      }
    }, 1000);
  }

  @computed('filter', 'args.isInTopMenu', 'chatState.{threadsToDisplay,userChats,groupChats,eventChats}')
  get threadsToDisplayWithFilter() {
    if (this.args.isInTopMenu) return this.chatState.threadsToDisplay;

    switch (this.filter) {
      case ChatFilter.USER:
        return this.chatState.userChats;

      case ChatFilter.GROUP:
        return this.chatState.groupChats;

      case ChatFilter.EVENT:
        return this.chatState.eventChats;

      default:
        return this.chatState.threadsToDisplay;
    }
  }

  getOpenThreadsAmount() {
    return this.chatState.threads?.filter((t) => !t.closed).length;
  }

  fetchFollowers() {
    // fetch contacts only if not already fetched or being fetched and if threads are fetched and there are less threads than maxChatSuggestions
    if (
      this.chatState.threadsInited &&
      this.getOpenThreadsAmount() < maxChatSuggestions &&
      !this.contactsCollection.isFetching
    ) {
      fetchFollowers();
    }
  }

  @computed(
    'contactsCollection.{items.length,isInited,isFetching}',
    'chatState.{threads.length,threadsInited,chatRequests.length,chatRequestsInited}',
  )
  get chatSuggestions() {
    let threadsLength = this.chatState.threads.length + this.chatState.chatRequests.length;

    if (!this.chatState.threadsInited || !this.contactsCollection.isInited || threadsLength > maxChatSuggestions) {
      return A();
    }

    let threads = this.chatState.threads.map((t) => t).concat(this.chatState.chatRequests);

    let userIdsWithExisting1on1Chat = compact(
      threads.map((thread) => {
        if (get(thread, 'closed') || get(thread, 'waitingForCreation')) return;

        let others = thread.observableOthers;

        if (others && others.length === 1) {
          return get(others[0], 'id');
        }
      })
    );

    let contactsToSuggest = this.contactsCollection.items.filter((contact) => {
      return !get(contact, 'blocked') && !includes(userIdsWithExisting1on1Chat, get(contact, 'id'));
    });

    contactsToSuggest = contactsToSuggest.slice(0, maxChatSuggestions - this.getOpenThreadsAmount());

    return contactsToSuggest.map((contact) => {
      return {
        getUserChatParticipant: contact,
        avatar: contact._links.avatar,
        threadName: contact.name,
        publicUrl: contact.publicLinkId,
      };
    });
  }

  @computed('contactsCollectionEmpty', 'chatState.hasNoThreads')
  get noChatsInfo() {
    return this.contactsCollectionEmpty && this.chatState.hasNoThreads;
  }

  @computed('noChatsInfo', 'args.isChatRequestsPage')
  get showNoChatsInfo() {
    return this.noChatsInfo && !this.args.isChatRequestsPage;
  }

  @computed('contactsCollection.isInited', 'contactsCollection.items.length')
  get contactsCollectionEmpty() {
    return (
      !this.contactsCollection || (this.contactsCollection.isInited && this.contactsCollection.items?.length === 0)
    );
  }

  @computed('args.{isChatRequestsPage,isInTopMenu}')
  get isChatRequestsPageAndNotInTopMenu() {
    return this.args.isChatRequestsPage && !this.args.isInTopMenu;
  }

  @computed('args.{isChatRequestsPage,isInTopMenu}')
  get isChatRequestsPageOrTopMenu() {
    return this.args.isChatRequestsPage || this.args.isInTopMenu;
  }

  @computed('chatState.allowRequests', 'chatState.unreadRequestsCountComputed')
  get showNewRequestChatElement() {
    return this.chatState.allowRequests && this.chatState.unreadRequestsCountComputed >= 1;
  }

  doLoad() {
    let offset;

    switch (this.filter) {
      case ChatFilter.USER:
        offset = this.chatState.userChats.length;
        break;

      case ChatFilter.GROUP:
        offset = this.chatState.groupChats.length;
        break;

      case ChatFilter.EVENT:
        offset = this.chatState.eventChats.length;
        break;

      default:
        if (this.args.isChatRequestsPage) {
          offset = this.chatState.chatRequests.length;
        } else {
          offset = this.chatState.threads.length;
        }
    }

    let currentGroupThread = this.chatState.threads.find((t) => t.id === this.groupId);

    // there can be stored hidden thread that should not be counted in offset
    // it may happen when you are inside group which has 'Hidden chat' but we still need it to display in sidebar
    if (currentGroupThread && currentGroupThread.hidden) {
      offset -= 1;
    }

    dispatcher.dispatch('chat', 'fetchMoreThreads', {
      chatType: this.filter,
      chatRequests: this.args.isChatRequestsPage,
      offset: offset,
      doneCb: (data) => {
        if (this.isDestroyed || this.isDestroying) return;

        if ((data.threads && data.threads.length) || (data.requests && data.requests.length)) {
          this.scrolling.bindScrollDownElement(this.element, () => this.doLoad(), 500);
        }
      },
    });
  }

  @action
  openInvitationsDialog() {
    dispatcher.dispatch('contact', 'openContactInvitationDialog');
  }
}
