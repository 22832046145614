import { assert } from '@ember/debug';
import ApiUtil from './api';

class Api extends ApiUtil {
  authorize(params, async) {
    if (params) {
      return this.get('/video/upload/authorize-request', {
        data: params,
        async: async,
      });
    }
  }

  process(params, scope) {
    assert('Missing params in process', params);
    assert('Missing scope in process', scope);

    let apiRoot3 = null;

    if (['group', 'mycloud'].indexOf(scope.split('/')[0]) > -1) {
      apiRoot3 = 'apiRoot3';
    }

    return this.post(
      `/${scope}/post/video`,
      {
        data: JSON.stringify(params),
      },
      apiRoot3
    );
  }
}

export default new Api();
