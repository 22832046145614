import { toUrlParams } from 'mewe/shared/utils';
import axios from 'axios';
import { createDeferred as _createDeferred } from 'mewe/shared/utils';

export default class ExternalApi {
  constructor() {
    this.apiRoot = '';
    this.baseUrlOptions = {};
  }

  createDeferred() {
    const deffered = _createDeferred();
    return deffered.promise;
  }

  request(url, options, urlOptions) {
    var promise = this.createDeferred(),
      urlParams = toUrlParams(Object.assign({}, urlOptions, this.baseUrlOptions)),
      req = axios(Object.assign({ url: this.apiRoot + url + (urlParams ? '?' + urlParams : '') }, options));

    req.then((res) => promise.resolve(this.transformData(res.data))).catch(promise.reject);

    return promise;
  }

  get(url, options, urlOptions) {
    return this.request(url, Object.assign({ type: 'GET' }, options), urlOptions);
  }

  transformData(resp) {
    return resp;
  }
}
