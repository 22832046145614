import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { each, map, groupBy } from 'lodash';
import { addObserver, removeObserver } from '@ember/object/observers';
import { getOffset, getElWidth } from 'mewe/utils/elements-utils';

import CalendarUtils from 'mewe/utils/calendar-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import EventsApi from 'mewe/api/events-api';
import { ds } from 'mewe/stores/ds';
import isUndefined from 'mewe/utils/isUndefined';
import { utcOffsetMillis, shiftToUtc } from 'mewe/utils/datetime-utils';
import { getPopupPosition } from 'mewe/utils/popup-utils';
import { update, clear, handle } from 'mewe/fetchers/fetch-events';
import EmberObject from '@ember/object';

export default class MwCalendar extends Component {
  @service account;
  @service settings;
  @service dynamicDialogs;

  @alias('account.activeUser.locale') locale;
  @alias('account.activeUser.timezone') timezone;

  @alias('calendarDetails.monthDays') monthDays;

  // how many events is displayed in a week row without showing "+ X more"
  @tracked maxEventRows = 3;
  @tracked collection;
  @tracked calendarDetails;
  @tracked popupPosition;
  @tracked newEventParams;
  @tracked rangeStart;
  @tracked rangeEnd;
  @tracked dayPopupPosition;

  constructor() {
    super(...arguments);

    this.collection = ds.events.for('calendar');
  }

  @computed('calendarDetails.monthDays.[].utcDayStart')
  get since() {
    return this.calendarDetails.monthDays[0].utcDayStart;
  }

  @computed('calendarDetails.monthDays.[].utcDayEnd')
  get until() {
    return this.calendarDetails.monthDays[this.calendarDetails.monthDays.length - 1].utcDayEnd;
  }

  @action
  onInsert() {
    CurrentUserStore.getState().deferred.promise.then(() => {
      if (this.isDestroying || this.isDestroyed) return;
      this.renderCalendar();

      addObserver(this, 'rangeStart', this.selectedRangeChange);
      addObserver(this, 'rangeEnd', this.selectedRangeChange);
      addObserver(this, 'collection.items.length', this.renderEvents);
    });
  }

  @action
  onDestroy() {
    removeObserver(this, 'rangeStart', this.selectedRangeChange);
    removeObserver(this, 'rangeEnd', this.selectedRangeChange);
    removeObserver(this, 'collection.items.length', this.renderEvents);
  }

  renderCalendar(year, month) {
    // TODO: probably need to calculate this per each date, as DST can change in between dates
    this.utcDiff = utcOffsetMillis(this.timezone);
    this.calendarDetails = CalendarUtils.getMonthDetails({
      year: year,
      month: month,
      locale: this.locale,
    });

    this.fetchEvents();
  }

  // TODO: move to fetchers
  fetchEvents() {
    update('calendar', { isFetching: true });

    EventsApi.getCalendarEvents(this.args.scope, {
      // since/until params must be in seconds
      since: (this.since + this.utcDiff) / 1000,
      // until time is 1ms before next day, need to add 1ms to avoid float number after dividing by 1000
      until: (this.until + this.utcDiff + 1) / 1000 - 1,
    }).then((data) => {
      clear('calendar');
      handle(
        'calendar',
        data.events.map((el) =>
          el.participation
            ? Object.assign({}, el.event, { participationType: el.participation.participationType })
            : Object.assign({}, el.event)
        )
      );
      update('calendar', { isFetching: false });
    });
  }

  renderEvents() {
    // Days in calendar contain 3 types of elements to render and their order is important for positioning:
    //
    // 1) event to be displayed - it is added to the first day in week containing this event and its width
    //    reaches up to the last day in week containing event (sizeClass of event defines width). It's one element
    //    with a width extended for all days containing this event in this week
    // 2) non-replacable placeholder - invisible element holding space in day for other rendered event (point 1),
    //    while (1) is extended over more than one day in a week we need to hold space for it in other days then first one
    // 3) replacable placeholder - invisible element holding space in day where no other event is rendered but some
    //    event is rendered in the same row in this week. It can be replaced by other event if it will fit
    //    to the space occupied by replacable placeholders
    // Example: event rendered on first two days in a week (Sunday and Monday)
    //    => there is real EVENT (1) on Sunday's first row with 2*dayWidth so it's visible also over Monday
    //    => there is non-replacable placeholder on Monday's first row because event from Sunday is extended over this field
    //    => there is replacable placeholder in fisrt row on remaining days in week, something can get in that place later
    //       if it will fit but otherwise it will take space so that next event added on Monday-Wednesday will take second row
    //       in this week and will not jump to the first row on Wednesday while nothing is visible in that place

    // events are sorted by duration and then rendered in this order, longer events rendered first
    const sortedEvents = each(this.collection.items, (i) => {
      i.duration = i.endDate - i.startDate;
      if (!i.allDay) {
        i.set('startDate', i.startDate + this.utcDiff);
        i.set('endDate', i.endDate + this.utcDiff);
      }
    })
      .sortBy('duration')
      .reverse();

    each(sortedEvents, (ev) => {
      // validation if event should be visible in current month view
      // events can be out of current view if user changed months quickly and response came after that
      const isEventFromCurrentView = ev.startDate < this.until && ev.endDate > this.since;
      if (!isEventFromCurrentView) return;

      // - event could've started before first day of current month view be continued in current month
      //   then first day in view is the first day on which this event will be displayed
      // - also event can last longer than current month view and then last day in view is used as last day to render

      const eventFirstDay =
        this.monthDays.find((day) => ev.startDate >= day.utcDayStart && ev.startDate <= day.utcDayEnd) ||
        this.monthDays[0];
      const eventLastDay =
        this.monthDays.find((day) => ev.endDate >= day.utcDayStart && ev.endDate <= day.utcDayEnd) ||
        this.monthDays[this.monthDays.length - 1];
      const daysContainingEvent = this.monthDays.slice(
        this.monthDays.indexOf(eventFirstDay),
        this.monthDays.indexOf(eventLastDay) + 1
      );

      // adding event to all days that it extends on
      // (used for list of all events on given day, regardles if they are rendered or in "show more" list)
      each(daysContainingEvent, (d) =>
        d.allEvents.pushObject(
          EmberObject.create({
            isFirstDayOfEvent: d.utcDayStart <= ev.startDate,
            isLastDayOfEvent: d.utcDayEnd >= ev.endDate,
            eventData: ev,
          })
        )
      );

      const daysContainingEventByRow = groupBy(daysContainingEvent, 'rowNumber');

      // calculating if there is enough space for an event in each week that contains it
      const displayParamsByWeek = map(daysContainingEventByRow, (daysInRowContainingEvent) => {
        let freeRowNumber = null;

        // checking if there is a row in given week where this event could fully fit
        for (let i = 0; i < this.maxEventRows; i++) {
          // row is occupied if it contains event that is not a replacable placeholder
          const isRowOccupied = daysInRowContainingEvent.find((day) => {
            return day.eventsToRender.objectAt(i) && !day.eventsToRender.objectAt(i).canBeReplaced;
          });

          if (!isRowOccupied) {
            freeRowNumber = i;
            break;
          }
        }

        return {
          weekNumber: daysInRowContainingEvent[0].rowNumber, // which week in caledar
          daysInRowContainingEvent: daysInRowContainingEvent, // which days in the week
          freeRowNumber: freeRowNumber, // which row in the week
        };
      });

      // event can be rendered if there is a free row for it in every week that it needs to be rendered in
      const canRenderEvent = !displayParamsByWeek.find((row) => isUndefined(row.freeRowNumber));
      if (!canRenderEvent) return;

      each(displayParamsByWeek, (displayParams) => {
        const daysInWeek = groupBy(this.monthDays, 'rowNumber')[displayParams.weekNumber];

        // set placeholders for all days in this week
        each(daysInWeek, (day) => {
          const canBeReplaced = displayParams.daysInRowContainingEvent.indexOf(day) === -1;
          const existingPlaceholder = day.eventsToRender.objectAt(displayParams.freeRowNumber);

          if (existingPlaceholder) {
            // update placeholder that can be replaced if it changes be rendering next event in its place
            if (existingPlaceholder.canBeReplaced && !canBeReplaced) {
              existingPlaceholder.set('canBeReplaced', false);
            }
          } else {
            day.eventsToRender.pushObject(
              EmberObject.create({
                // space keeper for proper positioning of multiday events
                isPlaceholder: true,
                // canBeReplaced: flag to know that this space keeping object can be replaced with
                // real event because it's not on a day/row when real object is already taking space
                canBeReplaced: canBeReplaced,
                eventName: ev.name,
              })
            );
          }
        });

        // set event on the first of days in week that it occupies
        displayParams.daysInRowContainingEvent[0].eventsToRender.replace(displayParams.freeRowNumber, 1, [
          EmberObject.create({
            isFirstDayOfEvent: displayParams.daysInRowContainingEvent[0].utcDayStart <= ev.startDate,
            isLastDayOfEvent:
              displayParams.daysInRowContainingEvent[displayParams.daysInRowContainingEvent.length - 1].utcDayEnd >=
              ev.endDate,
            sizeClass: `event-size-${displayParams.daysInRowContainingEvent.length}`,
            eventData: ev,
          }),
        ]);
      });
    });
  }

  eventPickerClosed(sender) {
    sender.removeObserver('isEventPickerOpened', this, 'eventPickerClosed');

    if (this.isDestroyed || this.isDestroying) return;

    this.eventPickerOpenedDay = null;
    this.rangeStart = null;
    this.rangeEnd = null;
  }

  setDayEventPickerPosition(e) {
    this.popupPosition = getPopupPosition(e.target, { popupWidth: 290 });
  }

  selectedRangeChange() {
    this.clearSelectedRange();

    if (this.rangeStart) {
      const mouseDownIndex = this.monthDays.indexOf(this.rangeStart);
      const mouseUpIndex = this.rangeEnd ? this.monthDays.indexOf(this.rangeEnd) : mouseDownIndex;
      const daysToHighlight = this.monthDays.slice(
        Math.min(mouseDownIndex, mouseUpIndex),
        Math.max(mouseDownIndex, mouseUpIndex) + 1
      );

      each(daysToHighlight, (d) => d.set('isHighlighted', true));
    }
  }

  clearSelectedRange() {
    each(this.monthDays, (d) => {
      d.set('isHighlighted', false);
    });
  }

  @action
  calendarMouseLeave() {
    if (!this.eventPickerOpenedDay) {
      this.clearSelectedRange();
    }
  }

  @action
  dateMouseEnter(day) {
    // if eventPicker is opened then prevent changing range highlight until picker is closed
    if (!this.eventPickerOpenedDay) {
      this.rangeEnd = day;
    }
  }

  // click on day and drag to other day for opening event creation with preselected dates
  @action
  dateMouseDown(day, e) {
    if (this.isElementClicked(e)) return;

    if (this.eventPickerOpenedDay) {
      set(this, 'eventPickerOpenedDay.isEventPickerOpened', false);
    }

    this.rangeStart = day;
  }

  @action
  dateMouseUp(day, e) {
    if (!this.args.canCreateEvent) return;
    // mouseDown was not on a calendar day that can be used as start date, ignore mouseUp
    if (!this.rangeStart) return;
    // dont open event picker when clicked on calendar event, or 'more' link or inside more-events element
    if (this.isElementClicked(e)) return;

    let eventStartD = new Date(Math.min(this.rangeStart?.utcDayStart, day.utcDayStart)),
      eventEndD = new Date(Math.max(this.rangeStart?.utcDayStart, day.utcDayStart));

    let newEventParams = {
      eventStartDate: shiftToUtc(this.timezone, eventStartD.getTime() + 14 * 60 * 60 * 1000),
      eventEndDate: shiftToUtc(this.timezone, eventEndD.getTime() + 18 * 60 * 60 * 1000),
    };

    if (this.args.scope !== 'events' && this.args.group?.id) {
      newEventParams.groupId = this.args.group.id;
      this.dynamicDialogs.openDialog('event-create-dialog', newEventParams);
    } else {
      this.newEventParams = newEventParams;
      this.eventPickerOpenedDay = day;

      day.set('isEventPickerOpened', true);
      day.addObserver('isEventPickerOpened', this, 'eventPickerClosed');

      this.setDayEventPickerPosition(e);
    }
  }

  isElementClicked(e) {
    if (e && e.target) {
      if (e.target.classList.contains('more-link')) return true;
      if (e.target.closest('.day-more-list')) return true;

      const eventEl = e.target.closest('.calendar-event');
      if (eventEl && !eventEl.classList.contains('no-visible')) return true;
    }

    return false;
  }

  @action
  showDayPopup(day) {
    this.monthDays.forEach((d) => d.set('showDayPopup', false));
    day.set('showDayPopup', true);
  }

  @action
  dayPopupInsert(el) {
    const width = getElWidth(el);
    const pos = getOffset(el.closest('.day-element'));
    this.dayPopupPosition = pos.left > width ? 'right' : 'left';
  }

  @action
  closeDayPupup(day) {
    day.set('showDayPopup', false);
  }

  @action
  setMonth(offset) {
    let newMonth = this.calendarDetails.month + offset;
    let newYear = this.calendarDetails.year;

    if (newMonth === -1) {
      newYear--;
      newMonth = 11;
    } else if (newMonth === 12) {
      newYear++;
      newMonth = 0;
    }

    this.renderCalendar(newYear, newMonth);
  }
}
