import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { each, unescape } from 'lodash';
import { tracked } from '@glimmer/tracking';
import FeedUtils from 'mewe/utils/feed-utils';
import PostUtils from 'mewe/utils/post-utils';
import PostApi from 'mewe/api/post-api';
import CommentsApi from 'mewe/api/comments-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { epochSeconds } from 'mewe/utils/datetime-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import toEdit from 'mewe/stores/text-parsers/to-edit';
import { allPosts } from 'mewe/stores/models/feed-model';
import twitterText from 'twitter-text';
import { findGifUrl } from 'mewe/utils/gif-utils';
import { Theme } from 'mewe/constants';
import { guidFor } from '@ember/object/internals';

export default class MwEditForm extends Component {
  @tracked elementId = guidFor(this);
  @tracked newValue = toEdit(this.args.model.textServer).trim();
  @tracked oldValue = this.args.model.textServer;

  get textareaId() {
    return `${this.elementId}-edit-text`;
  }

  setCommentInFeedStore(postItemId, textServer, editedAt) {
    let postsInStores = allPosts().filter((p) => p.postItemId === postItemId);

    each(postsInStores, (E) => {
      if (!E) return;
      let commentOrReply = FeedUtils.getCommentOrReplyFromPost(this.args.model, E);

      if (commentOrReply) {
        commentOrReply.textServer = textServer;
        commentOrReply.editedAt = editedAt;
      }
    });
  }

  @action
  parseText() {
    let url = twitterText.extractUrls(this.newValue)[0];

    // do not parse gif link in format that we use in gifs picker, show it as gif instead
    // SG-13193
    let gifUrl = findGifUrl(unescape(url));

    if (gifUrl) {
      this.newValue = this.newValue.replace(gifUrl, ''); // text has to be removed to don't send gif twice, one as gif param and second in text
    }
  }

  @action
  save() {
    if (this.args.model.isComment) {
      this.saveEditedComment();
    } else {
      this.saveEditedPost();
    }
  }

  @action
  saveEditedPost() {
    const post = this.args.model;
    const isSmartSearch = this.args.isSmartSearch;
    const { scope, scopeId } = PostUtils.getPostScopeAndId(post);

    let newValue = toServer(this.newValue, {
      gifUrls: this.args.model.gifUrls,
      mentionsStrategy: this.args.mentionsStrategy,
      hashtagsStrategy: this.args.hashtagsStrategy,
      parseNativeMarkdown: this.args.allowNativeMarkdown,
    });

    let oldValue = this.oldValue,
      state = {
        scope: scope,
        scopeId: scopeId,
      };

    set(this.args.model, 'isEditing', false);

    const isPostEmpty =
      !newValue &&
      !post.medias.length &&
      !post.files.length &&
      !post.poll &&
      !post.refPost &&
      !post.link &&
      !post.sticker;

    if (newValue === oldValue || isPostEmpty) {
      this.cancelEditing();
      return; // text didn't change or was empty when shouldn't
    }

    let eventId = post.eventId;

    if (eventId && this.args.theme == Theme.EVENT) {
      state.eventId = eventId;
    } else if (scope === Theme.GROUP) {
      state.groupId = post.group.id;
    }

    if (post.pending) {
      state.pending = true;
    }

    if (isSmartSearch) {
      // just small hack, there should be some popup informing user that changes need to be propagated, but for now, fine
      post.text = newValue;
    }

    const params = {
      text: newValue,
      threadId: post.threadId,
    };

    // usecase only for album & media dialog
    if (this.isDialog) {
      post.textServer = params.text;
    }

    set(this.args.model, 'textServer', params.text);

    if (this.args.model.scheduled) params.schedule = true;

    // if we want to be more strict - the only supported values are: 'contacts', 'privacymail', 'group' (with groupId)
    PostApi.editPost(params, this.args.model.id, state)
      .then((data) => {
        const hashtags = twitterText.extractHashtags(data.text);
        const time = epochSeconds();
        post.editedAt = time;
        post.hashTags = hashtags; // needed e.g. to open photo from post with hashTag param (SG-18301)

        const postId = post.postItemId || post.id;
        let postsInStores = allPosts().filter((p) => p.postItemId === postId);

        postsInStores.forEach((E) => {
          if (!E) return;
          E.textServer = params.text;
          E.editedAt = time;
          E.hashTags = hashtags; // needed e.g. to open photo from post with hashTag param (SG-18301)
        });
      })
      .catch(function () {
        FunctionalUtils.showDefaultErrorMessage();
        post.textServer = oldValue;
      });
  }

  @action
  saveEditedComment() {
    var newValue = toServer(this.newValue, {
      mentionsStrategy: this.args.mentionsStrategy,
      gifUrls: this.args.model.gifUrls,
      parseNativeMarkdown: this.args.allowNativeMarkdown,
    });

    const oldValue = this.oldValue;
    const oldEditedAt = this.args.model.editedAt;

    set(this.args.model, 'isEditing', false);

    if (newValue === oldValue && !this.args.model.removeLink) {
      this.cancelEditing();
      return; // text didn't change, nothing was changed
    }

    if (
      !newValue &&
      !this.args.model.sticker &&
      !this.args.model.photo &&
      !this.args.model.document &&
      !this.args.model.audio &&
      (!this.args.model.link || this.args.model.removeLink)
    ) {
      this.cancelEditing();
      return; // text is empty when it shouldn't
    }

    const params = {
      text: newValue,
      removeLink: this.args.model.removeLink,
    };

    const postItemId = this.args.model.postItemId;
    this.setCommentInFeedStore(postItemId, params.text, epochSeconds());

    set(this.args.model, 'textServer', params.text);
    set(this.args.model, 'editedAt', epochSeconds());

    CommentsApi.editComment(params, this.args.model.id)
      .then(() => {
        if (params.removeLink) {
          set(this.args.model, 'link', null);
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.setCommentInFeedStore(postItemId, oldValue, null);
        set(this.args.model, 'textServer', oldValue);
        set(this.args.model, 'editedAt', oldEditedAt);
        set(this.args.model, 'removeLink', false);
      });
  }

  @action
  cancelEditing() {
    set(this.args.model, 'isEditing', false);
    set(this.args.model, 'removeLink', false);
    set(this.args.model, 'textServer', this.oldValue);
  }

  @action
  setSelectedGifs(value) {
    set(this.args.model, 'textServer', value);
  }
}
