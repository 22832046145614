import { set } from '@ember/object';
import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Theme } from 'mewe/constants';
import Storage from 'mewe/shared/storage';
import { getOwner } from '@ember/application';
export default class MwAvatar extends Component {
  @service account;
  @service dynamicPopups;
  @service account;

  @reads('args.group') group;

  @reads('args.page') page;

  @reads('args.owner') owner;

  @reads('args.event') event;

  @reads('args.thread') thread;

  @reads('args.contact') contact;

  @reads('args.postedByPage') postedByPage;

  imageSize = this.args.imageSize || '150x150';

  constructor() {
    super(...arguments);
    set(this, 'globals', getOwner(this).lookup('globals:main'));
  }

  /** @computed */
  @computed('event.id', 'group.id', 'owner.{id,profileId}')
  get profileId() {
    if (!this.owner) {
      return;
    }

    return (this.group?.id && this.group?.id != Theme.CONTACTS) || this.event?.id
      ? this.owner.id
      : this.owner.profileId;
  }

  @computed(
    'args.feedTheme',
    'contact.following',
    'account.activeUser.id',
    'owner._links.avatar.nsfw',
    'owner.id',
    'postedByPage',
    'page.isNsfw'
  )
  get isNsfwBlurred() {
    if (this.args.feedTheme === Theme.CONTACTS) {
      return false;
    }

    if (this.postedByPage) {
      return this.page?.isNsfw;
    }

    if (this.contact?.following) {
      return false;
    }
    const isNsfwAllowed = Storage.get(Storage.keys.allowNsfwContentSetting);
    if (this.owner?.id === this.account.activeUser.id || isNsfwAllowed) {
      return false;
    }

    return this.owner?._links?.avatar?.nsfw;
  }

  /*
    The thing about avatars - we have one store to keep user instances.
    users can have different avatars in different groups - but in store we keep
    instance only with one avatar url, default.

    Api endpoint for groups returns user objects with acting authors,
    these have correct avatar url, but we cannot replace them in store
    ( we would need to keep all avatars for all groups)

    Solution is to ignore url in model instance, and construct correct url there.
   */

  @computed(
    'account.activeUser.fprint',
    'group.id',
    'owner._links.avatar.href',
    'owner.{fingerprint,fprint,id}',
    'page.avatar',
    '{postedByPage,imageSize}',
    'account.activeUser.isAuthenticated'
  )
  get avatar() {
    const isUserLoggedIn = Boolean(this.account.isAuthenticated);
    let avatar;

    if (this.postedByPage) {
      avatar = this.page?.avatar;
    } else {
      avatar = EnvironmentUtils.getImgHost(true, !isUserLoggedIn) + this.owner?._links?.avatar?.href;
    }

    if (!avatar) {
      return;
    }

    avatar = avatar.replace('{imageSize}', this.imageSize);

    let staticPart = ~avatar.indexOf('?') ? avatar.substr(0, avatar.indexOf('?')) : avatar;

    let params = [];

    if (this.group && this.group.id !== Theme.CONTACTS) {
      params.push(`group=${this.group.id}`);
    }

    if (!this.postedByPage) {
      if (this.owner?.id === this.account.activeUser?.fprint) {
        params.push(`f=${this.account.activeUser?.fprint}`);
      } else {
        params.push(`f=${this.owner?.fprint || this.owner?.fingerprint || ''}`);
      }
    }

    if (params && params.length) {
      return `${staticPart}?${params.join('&')}`;
    }

    return staticPart;
  }

  @computed('owner.id', 'page.avatar')
  get hidden() {
    return !this.owner?.id && !this.page?.avatar;
  }

  /** @actions */
  @action
  showProfilePopup(element) {
    if (this.args.isAuthorLinkDisabled) {
      return;
    }
    if (this.owner?.id === this.account.activeUser.id) {
      return;
    }
    if (this.postedByPage) {
      return;
    }
    if (this.page && !this.owner?.id) {
      return;
    }
    if (!this.account?.activeUser.id) {
      return;
    }
    // TODO: pages need profile popups

    if (!this.isDestroyed && !this.isDestroying && !this.args?.post?.isPublicContent) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: element,
        avatar: this.avatar,
        owner: this.owner,
        group: this.thread ? this.thread.group : this.group,
        event: this.thread ? this.thread.event : this.event,
        thread: this.thread,
      });
    }
  }
}
