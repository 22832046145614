import cookie from './cookie.js';
import { keys, keysWithoutSuffix, keysShouldRemainInStorage } from 'mewe/shared/keys';
import { each } from 'lodash';

export const getStorageCurrentUser = () => {
  let cacheUser, cacheUserParsed;

  cacheUser = util.get(keys.currentUser);

  if (cacheUser) {
    try {
      cacheUserParsed = JSON.parse(cacheUser);
    } catch (e) {}

    // delete cached 'deferred' object as it cant be parsed to proper Promise, and by its existance
    // it will prevent creating default value of this field in model which is deferred with promise inside
    if (cacheUserParsed?.deferred) {
      delete cacheUserParsed.deferred;
    }
  }

  return cacheUserParsed;
};

const getTitleCountObj = () => {
  let countObj;
  try {
    countObj = JSON.parse(util.get(keys.titleCount));
  } catch (e) {}

  return countObj || { messages: 0, notifications: 0 };
};

export const getTitleCount = () => {
  let countObj = getTitleCountObj();
  return parseFloat(countObj.messages, 10) + parseFloat(countObj.notifications, 10);
};

export const setTitleCount = (type, value) => {
  let countObj = getTitleCountObj();
  countObj[type] = value;
  util.set(keys.titleCount, JSON.stringify(countObj));
};

var util = {
  storage: null,

  cookieStorage: {
    setItem: function (name, value) {
      cookie.set(name, value);
    },
    getItem: function (name) {
      return cookie.get(name);
    },
    removeItem: function (name) {
      cookie.remove(name);
    },
    clear: function () {
      cookie.clear();
    },
  },

  init: function () {
    if (this.canUseLocalStorage()) {
      this.storage = localStorage;
    } else {
      this.storage = this.getSecondaryStorage();
    }
  },

  getSecondaryStorage() {
    if (this.canUseSessionStorage()) {
      return sessionStorage;
    } else {
      return this.cookieStorage;
    }
  },

  canUseLocalStorage: function () {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  },

  canUseSessionStorage: function () {
    var test = 'test';
    try {
      sessionStorage.setItem(test, test);
      sessionStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  },

  set: function (key, obj) {
    key = this.addSuffix(key);

    if (key === false) return;

    this.storage.setItem(key, obj);
  },

  get: function (key) {
    key = this.addSuffix(key);

    if (key === false) return;

    var value = this.storage.getItem(key);
    if (value === 'false') return false;
    if (value === 'true') return true;
    return value;
  },

  remove: function (key) {
    key = this.addSuffix(key);

    if (key === false) return;

    this.storage.removeItem(key);
  },

  clear: function () {
    each(Object.keys(localStorage), (key) => {
      !keysShouldRemainInStorage.includes(key) && localStorage.removeItem(key);
    });
  },

  clearCurrentUsers: function () {
    each(Object.keys(localStorage), (key) => {
      if (key.indexOf('currentUser') === 0) {
        localStorage.removeItem(key);
      }
    });
  },

  addSuffix(key = '') {
    if (window.location.host === 'localhost:7357') return key; // don't run during tests on localhost

    let suffix = '';

    if (keysWithoutSuffix.indexOf(key) !== -1 || key.indexOf('currentUser') === 0) return key;

    let currentUser = getStorageCurrentUser();

    if (currentUser && currentUser.id) {
      suffix += '-' + currentUser.id;
    } else {
      console.error('============== LS error: expected currentUser.id, processed key: "', key, '"');
      return false;
    }

    return key + suffix;
  },
};

util.init();

export default {
  keys: keys,

  get: function (name) {
    return util.get(name);
  },

  set: function (name, value) {
    util.set(name, value);
  },

  remove: function (name) {
    util.remove(name);
  },

  clear: function () {
    util.clear();
  },

  clearCurrentUsersData: function () {
    util.clearCurrentUsers();
  },

  canUseLocalStorage: function () {
    return util.canUseLocalStorage();
  },

  isLocalStorageInUse: function () {
    return util.canUseLocalStorage();
  },

  markRegistration: function () {
    this.set(keys.registration, '1');
  },

  getComments() {
    try {
      return JSON.parse(util.get(keys.composedCommentsData));
    } catch (error) {
      console.log(error.message);
    }
  },

  addSuffix(key) {
    return util.addSuffix(key);
  },
};
