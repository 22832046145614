import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import AccountApi from 'mewe/api/account-api';
import ContactsApi from 'mewe/api/contacts-api';
import ChatApi from 'mewe/api/chat-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwSettingsNotification extends Component {
  @service('settings') desktopSettings;

  @alias('currentUser.settings.notifs') settings;

  @tracked currentUser;
  @tracked savingNotifySounds;
  @tracked savingEmailNotifications;
  @tracked savingMwNotifications;

  constructor() {
    super(...arguments);

    CurrentUserStore.getState().deferred.promise.then(() => {
      this.currentUser = CurrentUserStore.getState();
    });
  }

  @computed('settings')
  get isLoaded() {
    return isDefined(this.settings);
  }

  @action
  emailNotifsChange(newValue) {
    this.save('account', { emailNotifications: newValue }, 'emailNotifications', newValue);
  }

  @action
  chatSoundChange(newValue) {
    this.save('contacts', { allowChatMsgSound: newValue }, 'allowChatMsgSound', newValue);
  }

  @action
  notifsSoundChange(newValue) {
    this.save('contacts', { allowNotifSound: newValue }, 'allowNotifSound', newValue);
  }

  @action
  chatNotifyChange(newValue) {
    this.save('contacts', { chatNotify: newValue }, 'chatNotify', newValue);
  }

  @action
  chatNotificationsIsMentionsOnChange(newValue) {
    this.desktopSettings.save('notificationsIsMentionsOn', newValue);
  }

  @action
  chatNotificationsIsChatOnOnChange(newValue) {
    this.desktopSettings.save('notificationsIsChatOn', newValue);
  }

  save(api, params, key, value, oldValue) {
    if (!this.isLoaded) return;

    if (oldValue === undefined) oldValue = !value;

    const callback = (name, value) => {
      dispatcher.dispatch('client-data', 'saveClientPreference', `settings.notifs.${name}`, value);
      FunctionalUtils.info(__(`Changes in your account have been saved`));
    };

    const failure = (name, oldValue) => {
      this.revertChange(name, oldValue);
      FunctionalUtils.showDefaultErrorMessage();
    };

    const always = (name) => {
      this.toggleSavingFlag(name, false);
    };

    this.toggleSavingFlag(key, true);

    switch (api) {
      case 'contacts':
        ContactsApi.setMyContactsSettings(params)
          .then(() => {
            callback(key, value);
          })
          .catch(() => {
            failure(key, oldValue);
          })
          .finally(() => {
            always(key);
          });

        break;

      case 'account':
        AccountApi.setData(params)
          .then(() => {
            callback(key, value);
          })
          .catch(() => {
            failure(key, oldValue);
          })
          .finally(() => {
            always(key);
          });

        break;

      case 'privatePost':
        ChatApi.setSettings(params)
          .then(() => {
            callback(key, value);
          })
          .catch(() => {
            failure(key, oldValue);
          })
          .finally(() => {
            always(key);
          });

        break;
    }
  }

  toggleSavingFlag(key, value) {
    switch (key) {
      case 'emailNotifications':
        this.savingEmailNotifications = value;
        break;

      case 'chatNotify':
        this.savingMwNotifications = value;
        break;

      case 'allowNotifSound':
      case 'allowChatMsgSound':
        this.savingNotifySounds = value;
        break;
    }
  }

  revertChange(type, revertValue) {
    switch (type) {
      case 'allowChatMsgSound':
        document.querySelector('#chat-sound').checked = revertValue;
        break;

      case 'allowNotifSound':
        document.querySelector('#notif-sound').checked = revertValue;
        break;

      case 'emailNotifications':
        document.querySelector('#subscribe-email').checked = revertValue;
        break;
    }

    dispatcher.dispatch('client-data', 'saveClientPreference', `settings.notifs.${type}`, revertValue);
  }

  @action
  enableNotifications() {
    if (this.currentUser?.notifications?.disabled) {
      dispatcher.dispatch('notification', 'requestPermissions');
    }
  }
}
