/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { getHandleHtmlString } from 'mewe/helpers/handle-html';
import { getThemeFromHtmlClass } from 'mewe/utils/color-utils';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

export default class WebThreeHandleReady extends Component {
  @service settings;

  userHandle = getHandleHtmlString(this.args.notification.web3Data.handle);
  isThemeDark = getThemeFromHtmlClass() === 'dark';
  badge = `
    <div class="c-mw-badge badge">
      <span class="badge-box">
        <svg class="badge-img icn_badge_dsnp${this.isThemeDark ? '_dm' : ''}">
          <use xlink:href="#icn_badge_dsnp${this.isThemeDark ? '_dm' : ''}"></use>
        </svg>
      </span>
    </div>`;

  get getNotificationText() {
    return htmlSafe(
      __('Your universal handle {handle}{badge} has been added to the Social Web.', {
        handle: this.userHandle,
        badge: this.badge,
      })
    );
  }
}
