import Component from '@glimmer/component';
import { action } from '@ember/object';
import { empty, reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import Scrolling from 'mewe/utils/scrolling-utils';

export default class TargetPicker extends Component {
  element;

  scrolling = Scrolling();

  @reads('args.items') items;

  @empty('args.items') isEmpty;

  @action
  onInsert(element) {
    this.element = element;

    if (this.args.onScroll) {
      this.scrolling.bindScrollDownElement(this.element, this.args.onScroll, 500, true);
      addObserver(this, 'args.items', this.itemsLengthChanged);
    }
  }

  @action
  onDestroy() {
    if (this.args.onScroll) {
      removeObserver(this, 'args.items', this.itemsLengthChanged);
      this.scrolling.unbindScrollDown(this.element);
    }
  }

  itemsLengthChanged() {
    this.scrolling.unbindScrollDown(this.element);
    this.scrolling.bindScrollDownElement(this.element, this.args.onScroll, 500, true);
  }
}
