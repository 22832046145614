/**
 * list inside quill will be disabled until they will fix this issue
 * https://github.com/quilljs/quill/issues/2497
 * connected with this ticket
 * https://sgrouples.atlassian.net/browse/SG-28599
 */

import Quill from 'quill/core';
import { isNumber } from 'lodash';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Strike from 'quill/formats/strike';
import Autoformat from './text-editor-autoformat';
import Autocomplete from './text-editor-autocomplete';
import Emoji from './formats/emoji';
import Hashtag from './formats/hashtag';
import HashtagItalic from './formats/hashtagItalic';
import Mention from './formats/mention';
import PlainClipboard from './text-editor-clipboard';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';

Quill.register(Block, true);

Quill.register('modules/clipboard', PlainClipboard, true);

Quill.register({
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/strike': Strike,
  'formats/emoji': Emoji,
  'formats/hashtag': Hashtag,
  'formats/hashtagItalic': HashtagItalic,
  'formats/mention': Mention,
});

export default ({
  context,
  el,
  getEnterNewLine,
  placeholder,
  onCtrlEnter,
  canMarkHashtags,
  tabindex,
  editorClassNames,
  showFormatting,
  hideEmoji,
  hashtagsStrategy,
  mentionsStrategy,
  groupName,
}) => {
  Quill.register('modules/autoformat', Autoformat, true);
  Quill.register('modules/autocomplete', Autocomplete, true);

  let formats = ['mention'];

  if (showFormatting) formats.push(...['bold', 'italic', 'strike']);

  if (canMarkHashtags) {
    formats.push('hashtag');
    formats.push('hashtagItalic');
  }

  if (!hideEmoji) formats.push('emoji', 'data-name', 'src');

  const quill = new Quill(el, {
    formats: formats,
    placeholder: placeholder || '',
    modules: {
      clipboard: true,
      autoformat: {
        enableEmojis: true,
        enableHashtags: canMarkHashtags,
      },
      autocomplete: {
        emberContext: context,
        hashtagsStrategy: canMarkHashtags ? hashtagsStrategy : null,
        mentionsStrategy,
        groupName: groupName,
      },
      keyboard: {
        bindings: {
          tab: false,
          bold: false,
          italic: false,
          strike: false,

          handleEnter: {
            key: 13,
            handler: function () {
              return !getEnterNewLine();
            },
          },

          handleCtrlEnter: {
            key: 13,
            ctrlKey: true,
            handler: function () {
              if (typeof onCtrlEnter === 'function') onCtrlEnter();
            },
          },

          handleCmdEnter: {
            key: 13,
            metaKey: true,
            handler: function () {
              if (typeof onCtrlEnter === 'function') onCtrlEnter();
            },
          },

          handleAltEnter: {
            key: 13,
            altKey: true,
            handler: function (range) {
              this.quill.setSelection(range.index, 'silent');
              this.quill.insertText(range.index, '\n', 'user');
              this.quill.setSelection(range.index + 1, 'silent');
              this.quill.format('linebreak', true, 'user');
            },
          },
        },
      },
    },
  });

  if (isNumber(tabindex)) quill.root.tabIndex = tabindex;

  if (editorClassNames) quill.root.className += ' ' + editorClassNames;

  return quill;
};
