/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import dispatcher from 'mewe/dispatcher';
import * as DOMPurify from 'dompurify';
import ChatStore from 'mewe/stores/chat-store';

export default class MwChatsListElement extends Component {
  @service chat;
  @service router;

  @reads('args.thread') thread;
  @reads('args.chatState') chatState;

  createThreadUserId() {
    return (
      this.thread.selectedItems?.[0]?.user?.id ||
      this.thread.observableOthers?.[0]?.id ||
      this.thread.getUserChatParticipant?.id
    );
  }

  get showDateInTitle() {
    return this.args.showDetails && !this.thread.publicUrl && this.thread.lastMessage;
  }

  get avatar() {
    if (this.args.isSuggestion) {
      return this.thread.avatar;
    }
  }

  get isGroupOrEventChat() {
    return this.thread.isGroupChat || this.thread.isEventChat;
  }

  get textDisplay() {
    return this.args.disableTextLinks
      ? htmlSafe(DOMPurify.sanitize(this.thread.textDisplay, { FORBID_TAGS: ['a'] }))
      : htmlSafe(this.thread.textDisplay);
  }

  @action
  onClick() {
    if (this.args.isRequest) {
      return this.router.transitionTo('app.chat.requests');
    }

    if (!this.thread.id) {
      return this.router.transitionTo('app.chat.create-thread', { queryParams: { userId: this.createThreadUserId() } });
    }

    if (this.args.isInTopMenu) {
      if (this.args.isSuggestion) {
        this.router.transitionTo('app.chat.create-thread', { queryParams: { userId: this.thread.id } });
      } else {
        this.router.transitionTo('app.chat.thread', this.thread.id);
      }
    } else {
      this.openThread();
    }
  }

  @action
  openMiniChat(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.openThread();
  }

  openThread(requestAuthorId) {
    const thread = this.thread;

    if (thread.id) {
      let chatState = this.chatState;
      let isThreadOpenedFromTopMenuOnChatPage = chatState.isChatPage && this.args.isInTopMenu;

      if (thread.minimized && (this.args.isInTopMenu || !chatState.isChatPage)) {
        ChatStore.send('toggleMinimize', thread);
      }

      let options = {
        thread: thread,
        doNotChangeThread: isThreadOpenedFromTopMenuOnChatPage,
        doNotClose: isThreadOpenedFromTopMenuOnChatPage,
      };

      let threadId = thread.id;

      if (chatState.isChatPage && !isThreadOpenedFromTopMenuOnChatPage) {
        this.chat.transitionToThread(thread);
      } else {
        dispatcher.dispatch('chat', 'openThread', options);
      }
    } else {
      if (this.args.isSuggestion) {
        this.chat.openThreadByParticipants([thread.getUserChatParticipant], { isSuggestion: true });
      } else if (this.args.isRequest) {
        this.chat.openThreadByParticipants([thread.lastAuthor]);
      } else {
        this.chat.openThreadByParticipants([thread.others[0] || requestAuthorId]);
      }
    }
  }
}
