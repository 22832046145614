import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set, get } from '@ember/object';
import { inject as service } from '@ember/service';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Locales, Theme } from 'mewe/constants';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';

export default class MwSidebarTopics extends Component {
  @service dynamicDialogs;

  @tracked topics = this.args.topics;

  defaultImageUrl = EnvironmentUtils.getCdnHost() + '/assets/predefined/categories/Tech.jpg';

  get noFeatured() {
    return get(this.topics, 'loaded') && get(this.topics, 'featured.length') === 0;
  }

  get noFeaturedGroupsText() {
    return __('Currently there are no featured topics in {language}', {
      language: Locales[get(this.topics, 'locale').replace('_', '-')],
    });
  }

  @action
  openGroupCreationDialog() {
    this.dynamicDialogs.openDialog('group-create');
  }

  @action
  openPublicDirectory(topic) {
    this.openPublicDirectoryDialog({ openTopic: topic.name });
  }

  @action
  openPublicDirectoryDialog(options) {
    this.dynamicDialogs.openDialog('browse-communities-dialog', {
      theme: Theme.GROUP,
      options,
    });
  }

  @action
  openInterestsPicker() {
    openFtueRecommendations(this.dynamicDialogs, 'sidebar-groups');
  }

  @action
  setLocale(locale) {
    set(this, 'topics.locale', locale);
  }
}
