import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';

export default class AppGroupMembersProfile extends Component {
  @service router;

  constructor() {
    super(...arguments);
    window.scrollTo(0, 0);
  }

  @computed('routing.currentRouteName')
  get linkPath() {
    switch (this.router.currentRouteName) {
      case 'app.event.attendees.profile':
        return 'app.event.attendees';
      case 'app.group.members.profile':
        return 'app.group.members';
      default:
        return 'app.group.index';
    }
  }
}
