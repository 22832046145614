import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupStore from 'mewe/stores/group-store';
import ChatApi from 'mewe/api/chat-api';
import HomeApi from 'mewe/api/home-api';
import ChatStore from 'mewe/stores/chat-store';
import { ds } from 'mewe/stores/ds';
import dispatcher from 'mewe/dispatcher';

export default class MwSettingsGroups extends Component {
  @tracked groupsState;

  constructor() {
    super(...arguments);

    this.groupsState = GroupStore.getState();
  }

  @action
  toggleAlerts(group) {
    const rollback = () => {
      FunctionalUtils.showDefaultErrorMessage();
      group.set('isMuted', !group.isMuted);
    };

    if (group.isMuted) {
      HomeApi.unmuteGroupWrapper(group.id)
        .then(() => {
          FunctionalUtils.info(
            __(`Alerts for group {groupName} has been turned on`, {
              groupName: group.name,
            })
          );
        })
        .catch(rollback);
    } else {
      HomeApi.muteGroupWrapper(group.id)
        .then(() => {
          FunctionalUtils.info(
            __(`Alerts for group {groupName} has been turned off`, {
              groupName: group.name,
            })
          );
          GroupStore.send('resetNewPostsCounter', group.id);
          ds.wrappers.items.removeObject(ds.wrappers.items.find((i) => i.id === group.id));
        })
        .catch(rollback);
    }

    group.set('isMuted', !group.isMuted);
  }

  @action
  toggleChat(group) {
    if (group.chatMode === 'off') {
      return;
    }

    const thread = ChatStore.getThreadById(group.id);
    const rollback = () => {
      FunctionalUtils.showDefaultErrorMessage();
      group.set('hiddenChat', !group.hiddenChat);
    };

    if (group.hiddenChat) {
      ChatApi.unhideGroupChat(group.id)
        .then(() => {
          FunctionalUtils.info(
            __(`Chat for group {groupName} has been turned on`, {
              groupName: group.name,
            })
          );
          ChatStore.send('unhideGroupChat', thread);
          GroupStore.getState({ id: group.id }).set('hiddenChat', false);
        })
        .catch(rollback);
    } else {
      ChatApi.hideGroupChat(group.id)
        .then(() => {
          FunctionalUtils.info(
            __(`Chat for group {groupName} has been turned off`, {
              groupName: group.name,
            })
          );
          if (thread) {
            dispatcher.dispatch('chat', 'hideGroupChat', thread);
          }
        })
        .catch(rollback);
    }

    group.set('hiddenChat', !group.hiddenChat);
  }
}
