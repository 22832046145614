/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

import PostApi from 'mewe/api/post-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import PostUtils from 'mewe/utils/post-utils';
import { Theme } from 'mewe/constants';
import { getOffset } from 'mewe/utils/elements-utils';
import { inject as service } from '@ember/service';
const EL_SCROLLABLE_CLASS = '.shares-list_content';

export default class MwPostbarShareButton extends Component {
  @service account;

  @reads('args.post') post;

  @tracked sharesList = A();
  @tracked isLoadingShared;
  @tracked sharedUserDropdownOpened;
  @tracked topDropdown;

  timeoutId = null;
  timeoutValue = 350;
  fetchLimit = 10;
  scrolling = Scrolling();

  @computed('post.{sharesCount,isCurrentUserPostOwner,isPagePostAdmin,discoverScope,isGroupPreview}')
  get canShowSharedList() {
    return (
      this.post.sharesCount &&
      this.post.discoverScope !== 'group' &&
      !this.post.isGroupPreview &&
      (this.post.isCurrentUserPostOwner || this.post.isPagePostAdmin)
    );
  }

  @computed('post.sharesCount')
  get sharesCount() {
    return this.post?.sharesCount ? `${this.post.sharesCount}` : '';
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  willDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector(EL_SCROLLABLE_CLASS));
  }

  @action
  handleMouseLeave() {
    clearTimeout(this.timeoutId);
  }

  getSharesList() {
    if (this.isLoadingShared) return;

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;
      if (!data || !data.list) return;

      // combine array of results with array of users
      data.list.forEach((item) => {
        item.user = data.users.find((u) => u.id === item.userId);

        if (item.user) {
          item.user.profileId = item.user.publicLinkId;
        }
      });

      if (this.nextPage) {
        this.sharesList.pushObjects(data.list);
      } else {
        this.sharesList = data.list;
      }

      if (data._links && data._links.nextPage) {
        this.scrolling.bindScrollDownElement(this.element.querySelector(EL_SCROLLABLE_CLASS), () =>
          this.getSharesList()
        );
      } else {
        this.scrolling.unbindScrollDown(this.element.querySelector(EL_SCROLLABLE_CLASS));
      }

      this.nextPage = data._links && data._links.nextPage ? data._links.nextPage.href : null;
      this.isLoadingShared = false;
    };

    this.isLoadingShared = true;

    PostApi.resharesList({
      postItemId: this.post.postItemId,
      nextPage: this.nextPage,
      limit: this.fetchLimit,
      scope: this.getScope(),
    }).then(callback);
  }

  getScope() {
    let { scope, scopeId } = PostUtils.getPostScopeAndId(this.post);

    switch (scope) {
      case Theme.PRIVATEPOSTS:
        return `/privateposts/${scopeId}`;
      case Theme.GROUP:
      case Theme.GROUPS:
        return `/group/${scopeId}`;
      case Theme.PAGE:
      case Theme.PAGES:
        return `/pages/page/${scopeId}`;
      case Theme.EVENT:
        return `/event/${scopeId}`;
      default:
        return `/home`;
    }
  }

  @action
  shareAction(target) {
    this.args.shareAction(target);
  }

  @action
  closeList() {
    this.sharedUserDropdownOpened = false;
  }

  @action
  mouseEnterAction() {
    if (!this.canShowSharedList) return;

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      const spaceTop = getOffset(this.element).top - window.scrollY;
      const spaceBottom = window.innerHeight - spaceTop;

      this.topDropdown = spaceTop > spaceBottom;

      this.sharedUserDropdownOpened = true;

      document.dispatchEvent(new MouseEvent('click', { bubbles: true })); // click will close 'Share' dropdown in case it's opened

      next(() => {
        // don't fetch on dropdown opening if there are results fetched already
        if (!this.sharesList.length) {
          this.getSharesList();
        } else if (this.nextPage) {
          this.scrolling.bindScrollDownElement(this.element.querySelector(EL_SCROLLABLE_CLASS), () =>
            this.getSharesList()
          );
        }
      });
    }, this.timeoutValue);
  }
}
