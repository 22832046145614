import { computed } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model.js';

import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = Model.extend({
  question: attr('string'),
  options: attr('array', {
    defaultValue: function () {
      return A();
    },
  }),
  closed: attr('boolean'),
  ends: attr('string'),
  closeVotesFetched: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),
  votesCount: computed('options.@each.votes', function () {
    return this.options.reduce((a, o) => a + o.votes, 0);
  }),

  userVoted: computed('options.@each.votes', function () {
    return this.options.some((o) => o.selected);
  }),

  photosCount: computed('options.length', function () {
    return this.options.filter((o) => o.image).length;
  }),

  // minutes are rounded up from 30s so new polls have the same time shown as what was selected from postbox
  getDaysHoursMinutesLeft: function (startsMillis = new Date().getTime()) {
    startsMillis = parseInt(startsMillis, 10);

    const timeLeft = {},
      ends = this.get('ends');

    if (ends) {
      const diff = parseInt(ends, 10) * 1000 - startsMillis;

      if (diff <= 0) {
        timeLeft.days = 0;
        timeLeft.hours = 0;
        timeLeft.minutes = 0;
      } else {
        timeLeft.days = Math.floor(diff / (1000 * 60 * 60 * 24));
        timeLeft.hours = Math.floor((diff - timeLeft.days * 1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        timeLeft.minutes = Math.round(
          (diff - timeLeft.days * 1000 * 60 * 60 * 24 - timeLeft.hours * 1000 * 60 * 60) / (1000 * 60)
        );

        if (timeLeft.minutes === 60) {
          timeLeft.minutes = 0;
          timeLeft.hours += 1;
        }
      }
    } else {
      timeLeft.days = 0;
      timeLeft.hours = 0;
      timeLeft.minutes = 0;
    }

    return timeLeft;
  },
});

model.reopenClass({
  resourceName: 'poll',
});

export default model;
