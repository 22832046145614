import { getOwner } from '@ember/application';
import { htmlSafe } from '@ember/template';
import { getElHeight, getElWidth, getOffset } from 'mewe/utils/elements-utils';

export const reposition = (element, popupSize, centerValue, forceSidePosition) => {
  if (!element) return;
  if (!centerValue) centerValue = 0;

  const elementOffset = element.getBoundingClientRect();

  let positionTop = elementOffset.top;
  let positionLeft = elementOffset.left;
  let outerWidth = elementOffset.width;

  //fix for bug with svg outer width being "NaNpx" just use parent as element
  if (outerWidth === 'NaNpx') {
    outerWidth = element.parentNode.clientWidht;
  }

  let position = {
    top: null,
    left: null,
  };

  // top offset should be related to window margin, not to whole document
  positionTop += window.scrollY;

  let canDisplayAbove = elementOffset.top > popupSize.height, // check if whole emoji selector will be displayed, if we display it above button
    canDisplayBelow = popupSize.height < window.innerHeight - elementOffset.top, // check if whole emoji selector will be displayed, if we display it below button
    spaceOnRight = window.innerWidth - elementOffset.left - outerWidth - popupSize.width,
    spaceOnLeft = elementOffset.left - popupSize.width;

  if (canDisplayAbove) {
    position.top = positionTop - popupSize.height;
  } else if (canDisplayBelow) {
    position.top = positionTop + getElHeight(element);
  } else {
    position.top = window.scrollY + 10; // align to the window top, 10 is top offset
  }

  const sidePositioningMargin = 3;
  // force popup to be on the right or left to the opener element to don't cover it (if possible)
  if (forceSidePosition && (spaceOnRight - sidePositioningMargin > 0 || spaceOnLeft - sidePositioningMargin > 0)) {
    position.left =
      spaceOnRight - sidePositioningMargin > 0
        ? positionLeft + outerWidth + sidePositioningMargin
        : positionLeft - popupSize.width - sidePositioningMargin;
  } else {
    position.left = spaceOnRight > 0 ? positionLeft : spaceOnRight + positionLeft;

    // quick hack for SG-19344 for not overflowing screen, needs better positioning for RTL
    const isRtl = document.dir;
    if (!isRtl) position.left += centerValue;
  }

  return position;
};

const simpleDropdownHelpers = {
  centerDropdownStyle: (parentOffsetLeft, parentWidth, elementWidth) =>
    parentOffsetLeft - elementWidth / 2 + parentWidth / 2,
  bottomDropdownStyle: (windowHeight, parentOffsetTop, margin, yTranslate) =>
    windowHeight - parentOffsetTop + margin + yTranslate,
  topDropdownStyle: (parentOffsetTop, parentHeight, margin, yTranslate) =>
    parentOffsetTop + parentHeight + margin + yTranslate,
  leftDropdownStyle: (parentOffsetLeft, xTranslate) => parentOffsetLeft + xTranslate,
  outsideLeftDropdownStyle: (parentOffsetLeft, elementWidth, xTranslate) =>
    parentOffsetLeft + xTranslate - elementWidth,
  rightDropdownStyle: (windowWidth, parentOffsetLeft, parentWidth, xTranslate) =>
    windowWidth - parentOffsetLeft - parentWidth + xTranslate,
  outsideRightDropdownStyle: (windowWidth, parentOffsetLeft, parentWidth, elementWidth, xTranslate) =>
    windowWidth - parentOffsetLeft - parentWidth - elementWidth + xTranslate,
};

export const repositionSimpleDropdown = (dropdownApi) => {
  const margin = dropdownApi.margin;
  let xTranslate = dropdownApi.translate && dropdownApi.translate.x ? dropdownApi.translate.x : 0;
  let yTranslate = dropdownApi.translate && dropdownApi.translate.y ? dropdownApi.translate.y : 0;
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  let dropdownStyle = '',
    anticloseStyle = '';

  if (dropdownApi.reposition && dropdownApi.reposition.elementHeight) {
    if (!dropdownApi.placement.top) {
      if (windowHeight - (dropdownApi.parentOffset.top - window.scrollY) < dropdownApi.reposition.elementHeight) {
        dropdownApi.placement.top = true;
        dropdownApi.placement.bottom = false;
        if (dropdownApi.translate.altY) yTranslate = dropdownApi.translate.altY; // top/bottom was reversed comparing to initial params, alternative translation for such usecase can be applied
      }
    } else {
      if (dropdownApi.parentOffset.top < dropdownApi.reposition.elementHeight) {
        dropdownApi.placement.top = false;
        dropdownApi.placement.bottom = true;
        if (dropdownApi.translate.altY) yTranslate = dropdownApi.translate.altY; // top/bottom was reversed comparing to initial params, alternative translation for such usecase can be applied
      }
    }
  }

  if (dropdownApi.reposition && dropdownApi.reposition.elementWidth) {
    if (dropdownApi.placement.center !== undefined && dropdownApi.placement.center) {
      if (
        simpleDropdownHelpers.centerDropdownStyle(
          dropdownApi.parentOffset.left,
          dropdownApi.parentSize.width,
          dropdownApi.reposition.elementWidth
        ) <= 0
      ) {
        dropdownApi.placement.center = undefined;
        dropdownApi.placement.left = true;
      }
    }
  }

  if (dropdownApi.reposition && dropdownApi.reposition.elementWidth) {
    if (dropdownApi.placement.outsideLeft !== undefined && !dropdownApi.placement.outsideLeft) {
      if (
        simpleDropdownHelpers.outsideRightDropdownStyle(
          windowWidth,
          dropdownApi.parentOffset.left,
          dropdownApi.parentSize.width,
          dropdownApi.reposition.elementWidth,
          xTranslate
        ) <= 0
      ) {
        dropdownApi.placement.outsideLeft = true;
      }
    }
  }

  if (document.documentElement.getAttribute('dir') === 'rtl') {
    if (dropdownApi.placement.left !== undefined) dropdownApi.placement.left = !dropdownApi.placement.left;
    if (dropdownApi.placement.outsideLeft !== undefined)
      dropdownApi.placement.outsideLeft = !dropdownApi.placement.outsideLeft;
  }

  if (dropdownApi.placement.top !== undefined) {
    if (dropdownApi.placement.top) {
      dropdownStyle += `bottom: ${simpleDropdownHelpers.bottomDropdownStyle(
        windowHeight,
        dropdownApi.parentOffset.top,
        margin,
        yTranslate
      )}px; `;
    } else {
      dropdownStyle += `top: ${simpleDropdownHelpers.topDropdownStyle(
        dropdownApi.parentOffset.top,
        dropdownApi.parentSize.height,
        margin,
        yTranslate
      )}px; `;
    }
  }

  if (dropdownApi.placement.left !== undefined) {
    if (dropdownApi.placement.left) {
      dropdownStyle += `left: ${simpleDropdownHelpers.leftDropdownStyle(
        dropdownApi.parentOffset.left,
        xTranslate
      )}px; `;
    } else {
      dropdownStyle += `right: ${simpleDropdownHelpers.rightDropdownStyle(
        windowWidth,
        dropdownApi.parentOffset.left,
        dropdownApi.parentSize.width,
        xTranslate
      )}px; `;
    }
  }

  if (dropdownApi.placement.outsideLeft !== undefined) {
    if (dropdownApi.placement.outsideLeft) {
      dropdownStyle += `left: ${simpleDropdownHelpers.outsideLeftDropdownStyle(
        dropdownApi.parentOffset.left,
        dropdownApi.reposition.elementWidth,
        xTranslate
      )}px; `;
    } else {
      dropdownStyle += `right: ${simpleDropdownHelpers.outsideRightDropdownStyle(
        windowWidth,
        dropdownApi.parentOffset.left,
        dropdownApi.parentSize.width,
        dropdownApi.reposition.elementWidth,
        xTranslate
      )}px; `;
    }
  }

  if (dropdownApi.placement.center !== undefined && dropdownApi.placement.center) {
    dropdownStyle += `left: ${simpleDropdownHelpers.centerDropdownStyle(
      dropdownApi.parentOffset.left,
      dropdownApi.parentSize.width,
      dropdownApi.reposition.elementWidth
    )}px; `;
  }

  if (dropdownApi.placement && dropdownApi.placement.positionType === 'fixed') {
    dropdownStyle += 'position: fixed;';
  }

  if (dropdownApi.anticlose) {
    anticloseStyle += `width: ${dropdownApi.anticlose.parentWidth}px; height: ${dropdownApi.anticlose.parentHeight}px; `;

    if (dropdownApi.placement.left !== undefined) {
      if (dropdownApi.placement.left) anticloseStyle += `left: ${Math.abs(xTranslate)}px; `;
      else anticloseStyle += `right: ${Math.abs(xTranslate)}px; `;
    }

    if (dropdownApi.placement.center) {
      anticloseStyle += `left: calc(50% - ${dropdownApi.anticlose.parentWidth / 2}px); `;
    }
  }

  return {
    placement: dropdownApi.placement,
    dropdownStyle: htmlSafe(dropdownStyle),
    anticloseStyle: htmlSafe(anticloseStyle),
  };
};

export const factory = (ctx, klass, name) => {
  let owner = getOwner(ctx);
  let existingFactory = owner.factoryFor(`component:${name}`);

  if (!existingFactory) {
    owner.register(`component:${name}`, klass);
    existingFactory = owner.factoryFor(`component:${name}`);
  }

  return existingFactory;
};

export const getPopupPosition = (el, params) => {
  const popupWidth = params.popupWidth,
    popupMargin = params.popupMargin || 10,
    elWidth = getElWidth(el);

  const spaceOnLeft = getOffset(el).left;
  const spaceOnRight = window.innerWidth - spaceOnLeft - elWidth;

  const popupPos = { left: `${elWidth / 2 - popupWidth / 2}px`, right: 'auto' };

  if (spaceOnLeft < popupWidth / 2) {
    popupPos.left = `-${spaceOnLeft - popupMargin}px`;
  } else if (spaceOnRight < popupWidth / 2) {
    popupPos.right = `-${spaceOnRight - popupMargin}px`;
    popupPos.left = 'auto';
  }

  return {
    popupPosition: htmlSafe(`left: ${popupPos.left}; right: ${popupPos.right}`),
  };
};
