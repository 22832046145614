import Component from '@glimmer/component';
import { action, set } from '@ember/object';
export default class MwSearchInput extends Component {
  @action
  onInsertInputSearch(element) {
    if (this.args.focusOnLoad) {
      element.focus();
    }
  }

  @action
  closeSearch() {
    set(this, 'searchPhrase', '');

    if (typeof this.args.clearSearch === 'function') {
      this.args.clearSearch();
    } else {
      this.args.doSearch?.();
    }
  }

  @action
  keyUp(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }

    this.args.doSearch?.(this.args.searchPhrase, event);
  }
}
