import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Inputs',
  component: 'StorybookInputs',
  excludeStories: /.*Data$/,
};

const Template = (args) => ({
  template: hbs`
    <div class='px-20 py-20'>

      <div class="d-flex">
        <div class="theme-mewe px-16 py-16" style='background: #FFFFFF;'>
          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" disabled="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" checked="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" checked="true" disabled="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>
        </div>

        <div class="theme-dark px-16 py-16" style='background: #000000;'>
          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" disabled="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" checked="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>

          <div class="mb-12">
            <Input class="mw-checkbox" type="checkbox" checked="true" disabled="true" />
            <label><span class="mw-checkbox_box"></span></label>
          </div>
        </div>

        <div class="px-16 py-16 f-16">
          <p class="mb-8">Checkbox OFF</p>
          <p class="mb-8">Checkbox OFF Disabled</p>
          <p class="mb-8">Checkbox ON</p>
          <p class="mb-8">Checkbox ON Disabled</p>
        </div>
      </div>

      <div class="d-flex mt-20">
        <div class="theme-mewe px-16 py-16" style='background: #FFFFFF;'>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              disabled="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              checked="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              checked="true"
              disabled="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

        </div>

        <div class="theme-dark px-16 py-16" style='background: #000000;'>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              disabled="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              checked="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

          <div class="mb-8">
            <input
              type="radio"
              class="m-input_radio"
              checked="true"
              disabled="true"
            />
            <label>
              <span class="radio-dot"><span class="radio-dot-inner"></span></span>
            </label>
          </div>

        </div>

        <div class="px-16 py-16 f-16">
          <p class="mb-8">Radio OFF</p>
          <p class="mb-8">Radio OFF Disabled</p>
          <p class="mb-8">Radio ON</p>
          <p class="mb-8">Radio ON Disabled</p>
        </div>
      </div>


      <div class="d-flex mt-20">
        <div class="theme-mewe px-16 py-16" style='background: #FFFFFF;'>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              checked="true"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              checked="true"
              disabled="true"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              disabled="true"
            />
            <label for="switch-lm"></label>
          </div>

        </div>

        <div class="theme-dark px-16 py-16" style='background: #000000;'>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              checked="true"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              checked="true"
              disabled="true"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
            />
            <label for="switch-lm"></label>
          </div>

          <div class="mb-8">
            <Input
              class="m-input_checkbox-switch-l"
              type="checkbox"
              id="switch-lm"
              disabled="true"
            />
            <label for="switch-lm"></label>
          </div>

        </div>

        <div class="px-16 py-16 f-20">
          <p class="mb-8">Switch ON</p>
          <p class="mb-8">Switch ON Disabled</p>
          <p class="mb-8">Switch OFF</p>
          <p class="mb-8">Switch OFF Disabled</p>
        </div>
      </div>

    </div>
  `,
  context: args,
});

export const Inputs = Template.bind({});
