import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class MwMewePlaybox extends Component {
  @service dynamicDialogs;

  constructor() {
    super(...arguments);
    set(this.args.message, 'isLoaded', false);
  }

  @action
  onInsert() {
    this.preLoadMedia();
  }

  get contentType() {
    return `mewe-playbox--${this.args.message.disappearingContentType}`;
  }

  get timeType() {
    const time = this.args.message.expiresInPretty;

    if (time.endsWith('h')) {
      return 'hours';
    } else if (time.endsWith('m')) {
      return 'minutes';
    } else if (time.endsWith('s')) {
      return 'seconds';
    }

    return '';
  }

  get played() {
    return this.args.message.played;
  }

  preLoadMedia() {
    // loading DC content to prevent waiting for it after opening in MD
    const media = this.args.message.attachments?.[0]?._links?.self?.href;
    if (!media) {
      return;
    }

    const preloadedUrl = EnvironmentUtils.getMediaHost(true) + media.replace('{imageSize}', '1600x1600');

    let disappearingContent = new Image();
    disappearingContent.src = preloadedUrl;

    this.preLoadedMedia = disappearingContent;
  }

  @action
  openDisappearingAttachment() {
    const contentType = this.args.message.disappearingContentType;

    if (contentType === 'mewe_me' || contentType === 'mewe_we') {
      set(this.args.message, 'playerOpened', !this.args.message.playerOpened);
    } else {
      this.dynamicDialogs.openDialog('media-dialog', {
        mediaType: 'expirable',
        dataProvided: true,
        expirableData: {
          preLoadedMedia: this.preLoadedMedia,
          attachments: this.args.message.attachments,
          msgId: this.args.message.id,
          threadId: this.args.message.threadId,
        },
        allowMultipleInstances: true,
      });
    }
  }
}
