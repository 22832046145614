export const createDeferred = function () {
  const deferred = {};
  const promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  deferred.promise = promise;
  deferred.promise.resolve = deferred.resolve;
  deferred.promise.reject = deferred.reject;

  return deferred;
};

export const loadScript = function (src) {
  return new Promise(function (resolve, reject) {
    let s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};

export const getQueryStringParams = function (url) {
  const urlToUse = typeof url === 'string' ? url : window.location.href;
  const queryString = urlToUse.split('?')[1] || '';

  return queryString.split('&').reduce((params, param) => {
    const [key, value] = param.split('=').map(decodeURIComponent);
    if (key) {
      params[key] = value;
    }
    return params;
  }, {});
};

export const getQueryParam = function (param) {
  var result = window.location.href.match(new RegExp('(\\?|&)' + param + '(\\[\\])?=([^(&|#)]*)'));
  return result ? result[3] : undefined;
};

// extracting 'next' param from url allows keeping url params inside it (next=/foo&bar=xxx)
// getting 'next' param with `getQueryParam` would separate it from other params (next=/foo, bar=xxx)
export const getNextFromUrl = function () {
  const nextIndex = window.location.href.indexOf('?next=');
  let nextUrl;

  if (nextIndex !== -1) {
    // `/login?next=/foo&bar=xxx` -> `/foo&bar=xxx`
    nextUrl = window.location.href.substring(nextIndex + 6);
    // `/foo&bar=xxx` -> `/foo?bar=xxx`
    nextUrl = nextUrl.replace('&', '?');
  }

  return nextUrl;
};

export const getRedirToInnerAppQueryParam = function () {
  // do not use getQueryParam function here because there might be url params inside 'next' link
  // so we need full text after 'next=' to to replace firts '&' to '?'
  // sample link: HOST/login?next=/profileHandle&mergeId=3c7e9744-b781-4629-b106-3e6cee1514d9
  let nextUrl = '';
  const nextIndex = window.location.href.indexOf('next=');

  if (nextIndex > -1) {
    nextUrl = window.location.href.slice(nextIndex + 'next='.length).replace('&', '?');
    nextUrl = decodeURIComponent(nextUrl);
  }
  return urlToInnerAppUrl(window.location.protocol + '//' + window.location.host, nextUrl, window.location.hash);
};

export const toUrlParams = function (params = '') {
  if (window.URLSearchParams) {
    return new URLSearchParams(Object.entries(params)).toString();
  } else {
    // jquery implementation of $.param function, as fallback for IE11 (homepage)
    const jqParam = function (a) {
      var s = [];
      var add = function (k, v) {
        v = typeof v === 'function' ? v() : v;
        v = v === null ? '' : v === undefined ? '' : v;
        s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
      };
      var buildParams = function (prefix, obj) {
        var i, len, key;

        if (prefix) {
          if (Array.isArray(obj)) {
            for (i = 0, len = obj.length; i < len; i++) {
              buildParams(prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']', obj[i]);
            }
          } else if (String(obj) === '[object Object]') {
            for (key in obj) {
              buildParams(prefix + '[' + key + ']', obj[key]);
            }
          } else {
            add(prefix, obj);
          }
        } else if (Array.isArray(obj)) {
          for (i = 0, len = obj.length; i < len; i++) {
            add(obj[i].name, obj[i].value);
          }
        } else {
          for (key in obj) {
            buildParams(key, obj[key]);
          }
        }
        return s;
      };

      return buildParams('', a).join('&');
    };

    return jqParam(params);
  }
};

// expected format /{scope}/show/{postId}?optionalQueryParams
export const extractParamsFromPostLink = (url) => {
  const result = {
    scope: null,
    postId: null,
    queryParams: {}
  };

  // Extract scope and postId
  const match = url.match(/\/([^/]+)\/show\/([^/?]+)/);
  if (match) {
    result.scope = match[1];
    result.postId = match[2];
  }

  // Extract query parameters
  const queryString = url.split('?')[1];
  if (queryString) {
    const pairs = queryString.split('&');
    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      result.queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });
  }

  return result;
};

export const objectToQueryString = (obj) => {
  return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
};

// those params are used on BE only to track registration origin in MP event 'Account Created - Server'
export const addSignupUrlParams = function (params, urlParams) {
  // publicLinkId - BE will recognise if that's page or user handle
  if (urlParams.upId) params.publicLinkId = urlParams.upId;
  if (urlParams.ppId) params.publicLinkId = urlParams.ppId;

  // gpId can contain publicId or `id=groupId` depending on usecase
  if (urlParams.gpId) {
    const nonPublicId = ~urlParams.gpId.indexOf('id-') ? urlParams.gpId.replace('id-', '') : null;
    if (nonPublicId) {
      params.groupId = nonPublicId;
    } else {
      params.groupPublicId = urlParams.gpId;
    }
  }
  // epId can contain publicId or `id=eventId` depending on usecase
  if (urlParams.epId) {
    const nonPublicId = ~urlParams.epId.indexOf('id-') ? urlParams.epId.replace('id-', '') : null;
    if (nonPublicId) {
      params.eventId = nonPublicId;
    } else {
      params.eventPublicId = urlParams.epId;
    }
  }

  // group or user invitation
  if (urlParams.inId) {
    params.invitationId = urlParams.inId;
  }
  return params;
};

export const urlToInnerAppUrl = function (currentHost, url, hash) {
  if (!url) return;

  url = url.replace(currentHost, '');

  if (url && url.length && url[0] === '/') url = url.slice(1, url.length);

  return (currentHost || '') + '/' + url + (hash || '');
};

export const extractPhoneNumberCharacters = function (string) {
  return string.replace(/[^0-9|'+']+/g, ''); // remove all characters that are not a number or '+' sign
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^\+?[0-9 \-\(\)]{9,15}$/i;
// dsnp handle requires 3 characters because later there is suffix added to it
// and overall it will be more than 4 characters required by mewe handle
export const dsnpHandleRegex = /^[a-zA-Z0-9_]{3,20}$/;
export const meweHandleRegex = /^[a-zA-Z0-9_]{4,16}$/;
export const pageHandleRegex = /^[a-zA-Z0-9_]{4,16}$/;

/**
 * browser utils
 */

export const isMobile = function () {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isWindows = function () {
  return navigator.platform.indexOf('Win') > -1;
};

export const isMac = function () {
  return navigator.platform.indexOf('Mac') > -1;
};

export const isIE = function () {
  const ua = window.navigator.userAgent,
    msie = ua.indexOf('MSIE '), // older IE
    trident = ua.indexOf('Trident/'); // newer IE

  return msie > 0 || trident > 0;
};

// detects old EdgeHTML based Edge (e.g. Edge 18), not new Chromium based (e.g. Edge 81)
export const isEdge = function () {
  return window.navigator.userAgent.indexOf('Edge') > -1;
};

export const isIEorEdge = function () {
  const isIE = false || !!document.documentMode;
  return isIE || !!window.StyleMedia;
};

export const isFirefox = function () {
  return typeof InstallTrigger !== 'undefined';
};

export const isSafari = function () {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window.safari || safari.pushNotification)
  );
};

export const isFirefox81 = function () {
  const match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
  const ver = match ? parseInt(match[1]) : 0;
  return ver && ver >= 81;
};

export const isChrome85 = function () {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  // navigator.brave to distinguis Brave browser from Chrome (not possible by userAgent as they are completely the same)
  return raw ? !!(parseInt(raw[2], 10) >= 85) : false;
};
