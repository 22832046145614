import Component from '@glimmer/component';
import { action } from '@ember/object';
import i18next from 'i18next';
import { tracked } from '@glimmer/tracking';

export default class MwHomeLangPicker extends Component {
  @tracked currentLang = i18next.language;

  @tracked currentLangText;

  @tracked openLanguages = false;

  langOptions = {
    en: 'English',
    'zh-HK': '中文(香港)',
    'zh-CN': '中文(简体)',
    ja: '日本語',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    nl: 'Nederlands',
    'pt-BR': 'Português (Brasil)',
    ar: 'العربية',
    cs: 'Čeština‏',
    da: 'Dansk',
    nb: 'Norsk (bokmål)',
    fi: 'Suomi',
    sv: 'Svenska',
    ko: '한국어',
    it: 'Italiano',
    pl: 'Polski',
    el: 'Ελληνικά',
    he: 'עברית‏',
  };

  constructor() {
    super(...arguments);
    this.currentLangText = this.langOptions[this.currentLang];
  }

  @action
  changeLocale(locale) {
    this.openLanguages = false;
    this.currentLangText = this.langOptions[locale];
    this.args.changeLocale?.(locale);
  }
}
