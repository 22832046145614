import Component from '@glimmer/component';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { A } from '@ember/array';
import { next } from '@ember/runloop';
import Scrolling from 'mewe/utils/scrolling-utils';
import PostApi from 'mewe/api/post-api';
import { maxResultsTagsList, Theme } from 'mewe/constants';

const EL_CLASS = '.hashtag-selector_list';

export default class MwHashtagSelector extends Component {
  element;
  scrolling = Scrolling();
  @tracked hashtagDropdownOpened;
  @tracked isLoadingTags;
  @tracked tags;
  @tracked tagsSearch;

  constructor() {
    super(...arguments);
    // target can change e.g. when postbox opened on /groups, and user changes group to post to
    addObserver(this, 'args.target.group.id', this.reset);
    this.reset();
  }

  reset() {
    this.tags = A();
    this.isLoadingTags = false;
    this.initialLoadDone = false;
    this.tagsSearch = '';
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.target.group.id', this.reset);
  }

  @computed('tags.length', 'args.selectedTags.length', 'isLoadingTags', 'tagsSearch')
  get showNoResultsTags() {
    return (
      this.tags.length === 0 && this.args.selectedTags.length === 0 && !this.isLoadingTags && !this.tagsSearch.length
    );
  }

  loadTags(reload) {
    const options = {
      maxResults: maxResultsTagsList,
      offset: reload ? 0 : this.tags.length,
    };
    let scope, scopeId;

    if (this.args.target === Theme.CONTACTS) {
      scope = Theme.CONTACTS;
    } else if (this.args.group?.id) {
      scope = Theme.GROUP;
      scopeId = this.args.group.id;
    } else if (this.args.page?.id) {
      scope = Theme.PAGE;
      scopeId = this.args.page.id;
    } else if (this.args.eventId) {
      scope = Theme.EVENT;
      scopeId = this.args.eventId;
    } else {
      scope = Theme.MYCLOUD;
    }

    this.isLoadingTags = true;

    if (this.tagsSearch.trim()) {
      options.term = this.tagsSearch.trim();
    }

    PostApi.getHashTags(options, scope, scopeId)
      .then((d) => {
        if (this.isDestroyed || this.isDestroying) return;

        const tagsSearch = this.tagsSearch.trim();
        let loadedTags = d.hashtags || A();

        // check if response is for current search value or some other search request
        if ((options.term && !tagsSearch.length) || (!options.term && tagsSearch.length)) return;

        if (tagsSearch && tagsSearch.length) {
          // checking if 'searchWord' tag exists in loaded tags, and adding it if not
          const existingTag = loadedTags.find((loadedTag) => loadedTag.name === tagsSearch);
          if (existingTag) {
            set(existingTag, 'selected', true);
          } else {
            loadedTags.unshift({ name: tagsSearch, isNewTag: true });
          }
        }

        if (this.args.selectedTags.length) {
          loadedTags = loadedTags.filter((loadedTag) => {
            const alreadySelected = this.args.selectedTags.find((selectedTag) => selectedTag.name === loadedTag.name);
            return !alreadySelected;
          });
        }

        if (options.offset === 0) this.tags = A(loadedTags);
        else this.tags.pushObjects(loadedTags);

        if (loadedTags.length === maxResultsTagsList) {
          this.scrolling.bindScrollDownElement(this.element.querySelector(EL_CLASS), () => this.loadTags());
        } else {
          this.scrolling.unbindScrollDown(this.element.querySelector(EL_CLASS));
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.isLoadingTags = false;
        this.initialLoadDone = true;
      });
  }

  @action
  clearSearch() {
    this.reset();
    this.loadTags();
  }

  @action
  searchTags(phrase, event) {
    const searchValue = event.target.value.replace(' ', '_');

    this.tagsSearch = searchValue;
    this.isLoadingTags = true;

    clearTimeout(this.searchTimeout);

    // automatically add tag on enter press
    if (event.keyCode === 13 && searchValue.length) {
      let existingTag = this.tags.find((tag) => tag.name === searchValue);

      if (existingTag) this.toggleTagSelect(existingTag);
      else {
        existingTag = this.args.selectedTags.find((tag) => tag.name === searchValue);
        if (!existingTag) {
          this.args.updateSelectedTags('unshiftObject', { name: searchValue, isNewTag: true, selected: true });
        }
      }

      this.tagsSearch = '';
    }

    let timeout = setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) return;
      this.tags = A();
      this.loadTags();
    }, 500);

    this.searchTimeout = timeout;
  }

  @action
  toggleTagSelect(tag) {
    // next() to keep DOM element on page until dropdown will check if it should close after click
    next(this, () => {
      if (tag.selected) {
        if (!tag.isNewTag) {
          this.tags.pushObject(tag);
        }
        this.args.updateSelectedTags('removeObject', tag);
      } else {
        // if user typed a new tag with '#' then remove it because we add it later
        if (tag.name) {
          tag.name = tag.name.replace('#', '');
        }

        this.args.updateSelectedTags('pushObject', tag);
        this.tags.removeObject(tag);

        if (tag.isNewTag) {
          this.tagsSearch = '';
          this.loadTags(true);
        }
      }

      set(tag, 'selected', !tag.selected);
    });
  }

  @action
  openHashtagDropdown() {
    this.hashtagDropdownOpened = true;

    if (!this.initialLoadDone) {
      this.loadTags();
    }
  }

  @action
  closeHashtagDropdown() {
    this.hashtagDropdownOpened = false;
  }
}
