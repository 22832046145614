import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { findGifUrl, gifUrlToGif, gifUrlToStill, gifUrlToMp4, gfycatToStill, gfycatToGif } from 'mewe/utils/gif-utils';
import { isElementInViewport } from 'mewe/utils/miscellaneous-utils-light';
import { debug } from '@ember/debug';

const viewportTolerance = 300;

export default class GfyGifModifier extends Modifier {
  modify(element, positionalArgs, namedArgs) {
    this.options = namedArgs;
    this.element = element;
    this.removeIO();
    this.createIO();

    registerDestructor(this, this.removeIO.bind(this));
  }

  createIO() {
    if (!findGifUrl(this.options.text)) return;

    const el = this.element;
    const cb = this.setUpGifState.bind(this);
    const options = {
      root: this.getScrollElement ? this.getScrollElement() : null,
      rootMargin: `${viewportTolerance}px`, // how many px allowed to be outside of viewport height or width for gif to load
      threshold: 0, // how much of the element should get into intersection to trigger callback (0 = just first pixel)
    };

    const io = new IntersectionObserver(cb, options);
    io.observe(el);

    this.gfyIO = { io, el };

    this.setUpGifState();
  }

  removeIO() {
    if (this.gfyIO) {
      const { io, el } = this.gfyIO;
      io.unobserve(el);
    }

    if (this.gfyElements?.length) {
      this.gfyElements?.forEach((elem) => {
        elem.removeEventListener('click', this.gfyGifClicked);
      });
    }
  }

  setUpGifState(ioState) {
    let isInView;
    if (ioState?.length) {
      // MSEDGE might not support 'isIntersecting' property, fallback to 'intersectionRatio'
      isInView = ioState[0].isIntersecting || ioState[0].intersectionRatio > 0;
    } else {
      isInView = isElementInViewport(this.element, viewportTolerance, viewportTolerance);
    }

    this.playOrPauseGfyGif(isInView);
  }

  playOrPauseGfyGif(isInView) {
    this.gfyElements = this.element.querySelectorAll('.gif:not(.truncate-copy)');

    this.gfyElements?.forEach((elem) => {
      // 1. If 'video-gif' class then it's image to be replaced with video when isInView
      // 2. If .is('video') then play/pause depending on isInView
      // 3. Otherwise toggle between animated gif url and gif static image preview
      if (elem.classList.contains('video-gif')) {
        if (isInView) {
          const videoEl = document.createElement('video');
          const sourceEl = document.createElement('source');
          videoEl.appendChild(sourceEl);
          videoEl.setAttribute('muted', true);
          videoEl.muted = true;
          videoEl.setAttribute('loop', true);
          videoEl.setAttribute('autoplay', true);
          videoEl.setAttribute('class', 'gif');
          videoEl.setAttribute('style', elem.getAttribute('style'));
          sourceEl.setAttribute('type', 'video/mp4');
          sourceEl.setAttribute('src', gifUrlToMp4(elem.getAttribute('src')));

          elem.parentNode.replaceChild(videoEl, elem);
        }
      } else if (elem.nodeName === 'VIDEO') {
        if (!isInView) {
          const imageEl = document.createElement('img');
          imageEl.setAttribute('class', 'gif video-gif');
          imageEl.setAttribute('style', elem.getAttribute('style'));
          imageEl.setAttribute('src', gifUrlToStill(elem.querySelector('source').src));

          elem.parentNode.replaceChild(imageEl, elem);
        }
      } else {
        let src = elem.getAttribute('src') || '',
          newSrc = isInView ? gifUrlToGif(src) : gifUrlToStill(src);

        if (newSrc !== src) elem.setAttribute('src', newSrc);
      }

      elem.addEventListener('click', this.gfyGifClicked);
    });
  }

  gfyGifClicked(e) {
    const elem = e.target;

    if (!elem.matches('.gif')) return;

    if (elem.nodeName === 'VIDEO') {
      try {
        if (elem.paused) {
          elem.play();
          elem.classList.remove('stopped');
        } else {
          elem.pause();
          elem.classList.add('stopped');
        }
      } catch (e) {
        debug(e);
      }
    } else {
      const src = elem.src || '';

      if (src.indexOf('giphy.com') === -1) {
        elem.setAttribute('src', src.indexOf('.jpg') === -1 ? gfycatToStill(src) : gfycatToGif(src));
      } else {
        let still = '_s.gif',
          gif = '.gif';
        if (src.indexOf(still) === -1) {
          elem.setAttribute('src', src.replace(gif, still));
        } else {
          elem.setAttribute('src', src.replace(still, gif));
        }
      }
    }

    e.preventDefault();
    return false;
  }
}
