import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { scheduleOnce } from '@ember/runloop';
import { getElHeight } from 'mewe/utils/elements-utils';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class SimpleTrim extends Component {
  element;
  noTrimLength = 25;
  visible = false;
  needTruncate = false;
  didTruncate = false;

  constructor() {
    super(...arguments);
    this.noTrimLength = this.args.maxLengthForNoTrim ? this.noTrimLength : this.args.maxLengthForNoTrim;
    addObserver(this, 'args.maxLengthForNoTrim', this.maxLengthForNoTrimChange);
    addObserver(this, 'args.text', this.textChange);
  }

  maxLengthForNoTrimChange() {
    this.noTrimLength = this.args.maxLengthForNoTrim ? this.noTrimLength : this.args.maxLengthForNoTrim;
    this.doTruncation();
  }

  textChange() {
    scheduleOnce('afterRender', this, this.doTruncation);
  }

  @computed('args.text')
  get trimText() {
    return this.args.escape ? this.args.text : htmlSafe(this.args.text);
  }

  @action
  onInsert(element) {
    this.element = element;
    if (!this.didTruncate) {
      scheduleOnce('afterRender', this, this.doTruncation);
    }
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.maxLengthForNoTrim', this.maxLengthForNoTrimChange);
    removeObserver(this, 'args.text', this.textChange);
  }

  doTruncation() {
    if (this.isDestroying || this.isDestroyed) return;
    if (this.args.text.length < this.noTrimLength) return;

    let containsSticker = this.element.getElementsByClassName('sticker')[0];
    if (containsSticker) return;

    if (getElHeight(this.element) > this.args.height) {
      this.needTruncate = true;
    }

    this.didTruncate = true;
  }

  @action
  toggle() {
    this.visible = !this.visible;
  }
}
