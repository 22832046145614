import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

export default class SingleTarget extends Component {
  @service settings;

  @action
  click(e) {
    e.preventDefault();
    this.args.selectContact?.(this.args.item);
  }

  @computed('args.style')
  get isTargetUser() {
    return this.args.style === 'style-privatepost' || this.args.style === 'style-dropdown';
  }

  @action
  unSelectContact(user) {
    // this allows click event to be handled first before removing item from list
    // (prevents e.g. closing dropdown because clicked event no longer exists in DOM when handling click event SG-21127)
    next(this, () => {
      this.args?.unSelectContact(user);
    });
  }
}
