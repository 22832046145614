import Helper from '@ember/component/helper';
import PS from 'mewe/utils/pubsub';
import { getThemeFromHtmlClass } from 'mewe/utils/color-utils';

export default Helper.extend({
  init() {
    this._super(...arguments);

    const callback = () => this.recompute();

    PS.Sub('theme.changed', callback);
  },

  compute(value) {
    const testedTheme = value[0];
    const currentTheme = getThemeFromHtmlClass();

    // (is-theme 'custom') => checking if any other theme than 'mewe' is active
    if (testedTheme === 'custom') {
      return currentTheme && currentTheme !== 'mewe' && currentTheme !== 'dark';
    }

    // checking if 'mewe' theme is active
    if (testedTheme === 'mewe') {
      return !currentTheme || currentTheme === 'mewe';
    }

    // checking for specific theme
    return currentTheme === testedTheme;
  },
});
