import EmberObject from '@ember/object';

const sizeH = 'photo.size.height';
const sizeW = 'photo.size.width';

export let standarizePhotos = (minHeight) => (image) => {
  if (!(image instanceof EmberObject)) image = EmberObject.create(image);

  // some videos don't have thumbnail
  if (image.get('photo') && image.get('photo.size')) {
    if (!image.get(sizeH)) image.set(sizeH, minHeight);
    if (!image.get(sizeW)) image.set(sizeW, minHeight);

    // if image is too small, let resize it
    if (image.get(sizeH) > minHeight) {
      image.set('width', image.get(sizeW));
      image.set('height', image.get(sizeH));
    } else {
      image.set('height', minHeight);
      image.set('width', image.get(sizeW) * (minHeight / image.get(sizeH)));
    }
  } else {
    image.set('width', minHeight);
    image.set('height', minHeight);
  }

  return image;
};
