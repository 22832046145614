import Component from '@glimmer/component';
import { action } from '@ember/object';
import { each } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce, next } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { run } from '@ember/runloop';

import { getElHeight } from 'mewe/utils/elements-utils';

/**
 * component used to truncate text to a specified height.
 *
 * {{text-truncate text=textDisplay height=190 }}
 */

export default class TextTruncate extends Component {
  buttonHeight = 40; // Height in px of the show button
  overflowLimit = 20; // Truncated element has to overflow given height by more than this value to display 'Show more' btn, to avoid 2px truncation
  truncateAboveStringLen = 220; // if pure text, only try to truncate it if it has more than these chars, as calling .height() causes reflow
  showSeeMoreButton = true;
  showSeeLessButton = false;
  outsideElement = null;

  @tracked wasTruncated = false; // Whether the text needed truncating or was short enough already

  get text() {
    return this.args.text;
  }

  get height() {
    return this.args.height;
  }

  // Max height in px of the visible part, default 250
  @tracked _maxHeight;
  get maxHeight() {
    return this._maxHeight ?? this.height ?? 250;
  }

  @tracked _truncate = true; // Whether or not to truncate the text
  get truncate() {
    return this.args.truncate ?? this._truncate;
  }

  setTruncate(value) {
    this._truncate = value;
    this.args.setTruncate?.(value);
    this.setShowMoreVisible();
  }

  @tracked _didTruncation = false; // Keeps track of whether or not doTruncate has been run
  get didTruncation() {
    return this.args.didTruncation ?? this._didTruncation;
  }

  setDidTruncation(value) {
    this._didTruncation = value;
    this.args.setDidTruncation?.(value);
  }

  @tracked _showMoreVisible = false;
  get showMoreVisible() {
    return this.args.showMoreVisible ?? this._showMoreVisible;
  }

  get notEscape() {
    return this.args.notEscape ?? true;
  }

  get style() {
    return this.showMoreVisible ? htmlSafe(`max-height: ${this.maxHeight}px;`) : '';
  }

  get heightToDisplay() {
    return this.maxHeight - this.buttonHeight;
  }

  @action
  onInsert(element) {
    this.element = element;
    this.renderUpdate();
  }

  /**
   * arguments that should trigger reTruncate after update:
   * - text, textServer - after text change, may be WS update about edited text
   * or edited by current user
   * - chat sizes - after changing small chat to expanded small chat or vice versa
   * - commentsOpened - after opening comments table
   * if the comments were hidden before, height couldn't be measured
   * so retruncate after opening
   *
   * reTruncate if show more is visible because it may be not needed after expanding
   * or if it's not visible and was not truncated before because it may be needed after shrinking
   */
  @action
  contentUpdated() {
    if (!this.resettingState && (this.showMoreVisible || !this.wasTruncated)) {
      /**
       * chat size change has transition effect,
       * this delay is needed for element resize before it will be measured
       */
      this.resettingState = true;
      next(this, () => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.resetState();
          this.resettingState = false;
        }
      });
    }
  }

  renderUpdate() {
    if (!this.didTruncation && this.truncate) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroying || this.isDestroyed) return;
        this.doTruncation();
        this.args.textRendered?.();
      });
    } else {
      this.args.textRendered?.();
    }
  }

  // should be triggered whenever those are updated: truncate, wasTruncated
  setShowMoreVisible() {
    const newValue = this.truncate && this.wasTruncated;
    this._showMoreVisible = newValue;
    this.args.setShowMoreVisible?.(newValue);
  }

  resetState() {
    const truncate = this.truncate;

    if (truncate) {
      // trigger a rerender/retruncate
      this.setTruncate(false);
      this.setDidTruncation(false);

      // afterRender - because first there have to be those property changes above rendered and then element can be measured and truncation can be done
      scheduleOnce('afterRender', this, () => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.setTruncate(truncate);
          this.renderUpdate();
        }
      });
    }
  }

  doTruncation() {
    if (this.isDestroyed || this.isDestroying) return;

    run(() => {
      // displayed height has to be bigger if it would cut yt video or gif in the middle
      const nonCutElements = this.element.querySelectorAll('.gif');

      if (nonCutElements.length) {
        each(nonCutElements, (el) => {
          const elTopPosition = el.offsetTop;

          let elHeight = getElHeight(el);
          let elBottomPosition = elTopPosition + elHeight;

          // SG-16695 element with gif url (with .gif class) in comments have 210px height but are measured as smaller before loading
          if (this.args.skin === 'comment-text' && el.classList.contains('ql-editor')) {
            if (elHeight < 200) {
              elHeight = 200;
              elBottomPosition = elTopPosition + elHeight;
            }
          }

          if (elTopPosition <= this.heightToDisplay && elBottomPosition >= this.heightToDisplay) {
            this._maxHeight = elBottomPosition + this.buttonHeight + 10; // 10 -> margin or padding of gif/yt element
            return; // return to do this only for one yt/gif element
          }
        });

        // SG-15069 if there is only one gif and it's overflowing trimmed visible area (video is not visible at all)
        // then move it after h-trim component so that it's visible without expanding trimmed text
        if (
          (this.args.skin === 'post-text' || this.args.skin === 'comment-text') &&
          this.element.querySelectorAll('.gif').length === 1
        ) {
          const firstGifEl = this.element.querySelector('.gif');

          if (firstGifEl.offsetTop > this.heightToDisplay && !this.outsideElement) {
            // additional class to recognise this as copy of gif outside truncation
            // SG-28342 - this copy can't be replaced in DOM by static image because stored 'outsideElement' will be lost
            firstGifEl.classList.add('truncate-copy');
            this.element.parentNode.insertBefore(firstGifEl, this.element?.nextSibling);
            this.outsideElement = firstGifEl;
          }
        }
      }

      if (nonCutElements.length || this.getTextToTruncateLength() > this.truncateAboveStringLen) {
        // element height is measured before setting didTruncation with its natural height and then it's limited to given height (otherwise couldn't be measured)
        // in template it's done by {{#if didTruncation}} {{else}}
        const totalHeight = getElHeight(this.element);

        this.wasTruncated = totalHeight >= this.heightToDisplay + this.overflowLimit;
      } else {
        this.wasTruncated = false;
      }

      this.setDidTruncation(true);
      this.setShowMoreVisible();
    });
  }

  getTextToTruncateLength() {
    let newLinesmatches;

    // count every new line as 20 characters to avoid post with many new lines and few characters counted as small one
    if (this.args.textServer?.length) {
      newLinesmatches = this.args.textServer.match(/\n/gm);
      return this.args.textServer.length + (newLinesmatches ? newLinesmatches.length * 20 : 0);
    }

    if (this.text?.length) {
      newLinesmatches = this.text.match(/<br>/gm);
      return this.text.length + (newLinesmatches ? newLinesmatches.length * 20 : 0);
    }

    return 0;
  }

  @action
  toggleTruncate() {
    if (this.args.isPublicContent) return;
    if (this.outsideElement) {
      this.outsideElement.remove();
    }

    const truncateOldValue = this.truncate;
    this.setTruncate(!this.truncate);

    if (!truncateOldValue) {
      // Need to reset state when the text is retruncated via the 'See Less' button
      this.resetState();
    }
  }

  @action
  clickAction(e) {
    if (e.target.classList.contains('text-truncate_button')) {
      return;
    }

    this.args.clickAction?.();
  }
}
