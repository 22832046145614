import axios from 'axios';
import Session from 'mewe/shared/session';
import { storeCollectionItems } from 'mewe/stores/models/collection';
import FunctionalUtils from 'mewe/shared/functional-utils';
import * as Sentry from '@sentry/ember';

export const fetchChallenges = () => {
  let failedCount = 0;
  let promiseResolve;
  let promiseReject;

  const promise = new Promise((resolve, reject) => {
    promiseResolve = resolve;
    promiseReject = reject;
  });

  const doRequest = () => {
    axios
      .get('/api/v2/account/challenges-available', {
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
          'content-type': 'application/json; charset=UTF-8',
        },
      })
      .then((res) => {
        // - if there was a failure before success (but less than 5 times), let's report that
        // - after 5th failure we report that anyways without waiting for success, but just once to avoid spamming Sentry
        if (failedCount > 0 && failedCount < 5) {
          Sentry.captureException(new Error(`fetch-challenges failed ${failedCount} times before success`));
        }

        if (res.data?.challenges?.length) {
          storeCollectionItems(ds.challenges, res.data.challenges);
        }

        promiseResolve();
      })
      .catch((error) => {
        if (!error.response || error.response.status !== 403) {
          FunctionalUtils.showDefaultErrorMessage();
          return;
        }

        failedCount++;

        // let's set some limit on retries to avoid infinite loop
        if (failedCount === 10) {
          promiseReject();
          return;
        }

        // - if there was 5th failure, let's report that anyways without waiting for success, but just once to avoid spamming Sentry
        // why reporting after 5th failure and not 10th? waiting for too long with reporting can lead user
        // to refresh/leave page earlier and we'd not get the report at all. 5 failures should take around ~5s so it seems reasonable time
        if (failedCount === 5) {
          Sentry.captureException(
            new Error(`fetch-challenges failed 5 or more times, it is unknown if it succeeded after all`)
          );
        }

        doRetry();
      });
  };

  // increasing timeout for each retry, doesn't make sense to retry immediately
  const doRetry = () => {
    setTimeout(() => {
      doRequest();
    }, failedCount * 500);
  };

  doRequest();

  return promise;
};
