import Component from '@glimmer/component';
import { openSinglePost } from 'mewe/utils/open-post-details';
import { action } from '@ember/object';

export default class ChatMessageLink extends Component {
  @action
  handleClick(e) {
    const isMewePost = openSinglePost(this.args.link._links.url.href);
    if (isMewePost) {
      e.preventDefault();
    }
  }
}
