var m = Object.defineProperty;
var T = (a, t, e) => t in a ? m(a, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : a[t] = e;
var h = (a, t, e) => (T(a, typeof t != "symbol" ? t + "" : t, e), e);
function k(a) {
  return !(a !== a || a === 1 / 0 || a === -1 / 0);
}
function R(a, t, e) {
  if (!k(t))
    throw new TypeError("Expected `limit` to be a finite number");
  if (!k(e))
    throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [], n = 0;
  const o = function() {
    n++;
    const i = setTimeout(function() {
      n--, s.length > 0 && o(), r = r.filter(function(u) {
        return u !== i;
      });
    }, e);
    r.indexOf(i) < 0 && r.push(i);
    const c = s.shift();
    c.resolve(a.apply(c.self, c.args));
  }, l = function(...i) {
    const c = this;
    return new Promise(function(u, p) {
      s.push({
        resolve: u,
        reject: p,
        args: i,
        self: c
      }), n < t && o();
    });
  };
  return l.abort = function() {
    r.forEach(clearTimeout), r = [], s.forEach(function(i) {
      i.reject(function() {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, l;
}
const _ = function(a, t) {
  const e = {};
  for (const s in a) {
    const r = a[s];
    t.indexOf(s) > -1 && r !== null && (e[s] = r);
  }
  return e;
}, b = (a) => a === "email", w = () => ({
  singleTag: "hr"
}), $ = () => ({
  tag: "blockquote"
}), x = () => ({
  tag: "ul"
}), P = (a) => ({
  tag: [
    "pre",
    {
      tag: "code",
      attrs: a.attrs
    }
  ]
}), S = () => ({
  singleTag: "br"
}), E = (a) => ({
  tag: `h${a.attrs.level}`
}), C = (a) => ({
  singleTag: [
    {
      tag: "img",
      attrs: _(a.attrs, ["src", "alt", "title"])
    }
  ]
}), O = () => ({
  tag: "li"
}), M = () => ({
  tag: "ol"
}), H = () => ({
  tag: "p"
}), A = () => ({
  tag: "b"
}), L = () => ({
  tag: "strike"
}), U = () => ({
  tag: "u"
}), I = () => ({
  tag: "strong"
}), N = () => ({
  tag: "code"
}), q = () => ({
  tag: "i"
}), z = (a) => {
  const t = { ...a.attrs }, { linktype: e = "url" } = a.attrs;
  return b(e) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), {
    tag: [
      {
        tag: "a",
        attrs: t
      }
    ]
  };
}, j = (a) => ({
  tag: [
    {
      tag: "span",
      attrs: a.attrs
    }
  ]
}), F = {
  nodes: {
    horizontal_rule: w,
    blockquote: $,
    bullet_list: x,
    code_block: P,
    hard_break: S,
    heading: E,
    image: C,
    list_item: O,
    ordered_list: M,
    paragraph: H
  },
  marks: {
    bold: A,
    strike: L,
    underline: U,
    strong: I,
    code: N,
    italic: q,
    link: z,
    styled: j
  }
}, V = function(a) {
  const t = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;"
  }, e = /[&<>"']/g, s = RegExp(e.source);
  return a && s.test(a) ? a.replace(e, (r) => t[r]) : a;
};
class y {
  constructor(t) {
    h(this, "marks");
    h(this, "nodes");
    t || (t = F), this.marks = t.marks || [], this.nodes = t.nodes || [];
  }
  addNode(t, e) {
    this.nodes[t] = e;
  }
  addMark(t, e) {
    this.marks[t] = e;
  }
  render(t) {
    if (t && t.content && Array.isArray(t.content)) {
      let e = "";
      return t.content.forEach((s) => {
        e += this.renderNode(s);
      }), e;
    }
    return console.warn(
      "The render method must receive an object with a content field, which is an array"
    ), "";
  }
  renderNode(t) {
    const e = [];
    t.marks && t.marks.forEach((r) => {
      const n = this.getMatchingMark(r);
      n && e.push(this.renderOpeningTag(n.tag));
    });
    const s = this.getMatchingNode(t);
    return s && s.tag && e.push(this.renderOpeningTag(s.tag)), t.content ? t.content.forEach((r) => {
      e.push(this.renderNode(r));
    }) : t.text ? e.push(V(t.text)) : s && s.singleTag ? e.push(this.renderTag(s.singleTag, " /")) : s && s.html && e.push(s.html), s && s.tag && e.push(this.renderClosingTag(s.tag)), t.marks && t.marks.slice(0).reverse().forEach((r) => {
      const n = this.getMatchingMark(r);
      n && e.push(this.renderClosingTag(n.tag));
    }), e.join("");
  }
  renderTag(t, e) {
    return t.constructor === String ? `<${t}${e}>` : t.map((r) => {
      if (r.constructor === String)
        return `<${r}${e}>`;
      {
        let n = `<${r.tag}`;
        if (r.attrs)
          for (const o in r.attrs) {
            const l = r.attrs[o];
            l !== null && (n += ` ${o}="${l}"`);
          }
        return `${n}${e}>`;
      }
    }).join("");
  }
  renderOpeningTag(t) {
    return this.renderTag(t, "");
  }
  renderClosingTag(t) {
    return t.constructor === String ? `</${t}>` : t.slice(0).reverse().map((s) => s.constructor === String ? `</${s}>` : `</${s.tag}>`).join("");
  }
  getMatchingNode(t) {
    const e = this.nodes[t.type];
    if (typeof e == "function")
      return e(t);
  }
  getMatchingMark(t) {
    const e = this.marks[t.type];
    if (typeof e == "function")
      return e(t);
  }
}
class v {
  constructor() {
    h(this, "isCDNUrl", (t = "") => t.indexOf("/cdn/") > -1);
    h(this, "getOptionsPage", (t, e = 25, s = 1) => ({
      ...t,
      per_page: e,
      page: s
    }));
    h(this, "delay", (t) => new Promise((e) => setTimeout(e, t)));
    h(this, "arrayFrom", (t = 0, e) => [...Array(t)].map(e));
    h(this, "range", (t = 0, e = t) => {
      const s = Math.abs(e - t) || 0, r = t < e ? 1 : -1;
      return this.arrayFrom(s, (n, o) => o * r + t);
    });
    h(this, "asyncMap", async (t, e) => Promise.all(t.map(e)));
    h(this, "flatMap", (t = [], e) => t.map(e).reduce((s, r) => [...s, ...r], []));
  }
  stringify(t, e, s) {
    const r = [];
    for (const n in t) {
      if (!Object.prototype.hasOwnProperty.call(t, n))
        continue;
      const o = t[n], l = s ? "" : encodeURIComponent(n);
      let i;
      typeof o == "object" ? i = this.stringify(
        o,
        e ? e + encodeURIComponent("[" + l + "]") : l,
        Array.isArray(o)
      ) : i = (e ? e + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(o), r.push(i);
    }
    return r.join("&");
  }
}
class J {
  constructor(t) {
    h(this, "baseURL");
    h(this, "timeout");
    h(this, "headers");
    h(this, "responseInterceptor");
    h(this, "fetch");
    h(this, "ejectInterceptor");
    h(this, "url");
    h(this, "parameters");
    this.baseURL = t.baseURL, this.headers = t.headers || [], this.timeout = t != null && t.timeout ? t.timeout * 1e3 : 0, this.responseInterceptor = t.responseInterceptor, this.fetch = (...e) => t.fetch ? t.fetch(...e) : fetch(...e), this.ejectInterceptor = !1, this.url = "", this.parameters = {};
  }
  get(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("get");
  }
  post(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("post");
  }
  put(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("put");
  }
  delete(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("delete");
  }
  async _responseHandler(t) {
    const e = [], s = {
      data: {},
      headers: {},
      status: 0,
      statusText: ""
    };
    t.status !== 204 && await t.json().then((r) => {
      s.data = r;
    });
    for (const r of t.headers.entries())
      e[r[0]] = r[1];
    return s.headers = { ...e }, s.status = t.status, s.statusText = t.statusText, s;
  }
  async _methodHandler(t) {
    let e = `${this.baseURL}${this.url}}`, s = null;
    if (t === "get") {
      const i = new v();
      e = `${this.baseURL}${this.url}?${i.stringify(
        this.parameters
      )}`;
    } else
      s = JSON.stringify(this.parameters);
    const r = new URL(e), n = new AbortController(), { signal: o } = n;
    let l;
    this.timeout && (l = setTimeout(() => n.abort(), this.timeout));
    try {
      const i = await this.fetch(`${r}`, {
        method: t,
        headers: this.headers,
        body: s,
        signal: o
      });
      this.timeout && clearTimeout(l);
      const c = await this._responseHandler(i);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(c)) : this._statusHandler(c);
    } catch (i) {
      return i;
    }
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(t) {
    const e = /20[0-6]/g;
    return new Promise((s, r) => {
      if (e.test(`${t.status}`))
        return s(t);
      const n = {
        message: new Error(t.statusText || `status: ${t.status}`),
        response: t
      };
      r(n);
    });
  }
}
let f = {};
const d = {};
class Q {
  constructor(t, e) {
    h(this, "client");
    h(this, "maxRetries");
    h(this, "throttle");
    h(this, "accessToken");
    h(this, "cache");
    h(this, "helpers");
    h(this, "relations");
    h(this, "links");
    h(this, "richTextResolver");
    h(this, "resolveNestedRelations");
    if (!e) {
      const n = t.region ? `-${t.region}` : "", o = t.https === !1 ? "http" : "https";
      t.oauthToken ? e = `${o}://api${n}.storyblok.com/v1` : e = `${o}://api${n}.storyblok.com/v2`;
    }
    const s = new Headers();
    s.set("Content-Type", "application/json"), s.set("Accept", "application/json"), s.forEach((n, o) => {
      t.headers && t.headers[o] && s.set(o, t.headers[o]);
    });
    let r = 5;
    t.oauthToken && (s.set("Authorization", t.oauthToken), r = 3), t.rateLimit && (r = t.rateLimit), t.richTextSchema ? this.richTextResolver = new y(t.richTextSchema) : this.richTextResolver = new y(), t.componentResolver && this.setComponentResolver(t.componentResolver), this.maxRetries = t.maxRetries, this.throttle = R(this.throttledRequest, r, 1e3), this.accessToken = t.accessToken || "", this.relations = {}, this.links = {}, this.cache = t.cache || { clear: "manual" }, this.helpers = new v(), this.resolveNestedRelations = !1, this.client = new J({
      baseURL: e,
      timeout: t.timeout || 0,
      headers: s,
      responseInterceptor: t.responseInterceptor,
      fetch: t.fetch
    });
  }
  setComponentResolver(t) {
    this.richTextResolver.addNode("blok", (e) => {
      let s = "";
      return e.attrs.body.forEach((r) => {
        s += t(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(t) {
    return t.version || (t.version = "published"), t.token || (t.token = this.getToken()), t.cv || (t.cv = d[t.token]), Array.isArray(t.resolve_relations) && (t.resolve_relations = t.resolve_relations.join(",")), t;
  }
  factoryParamOptions(t, e) {
    return this.helpers.isCDNUrl(t) ? this.parseParams(e) : e;
  }
  makeRequest(t, e, s, r) {
    const n = this.factoryParamOptions(
      t,
      this.helpers.getOptionsPage(e, s, r)
    );
    return this.cacheResponse(t, n);
  }
  get(t, e) {
    e || (e = {});
    const s = `/${t}`, r = this.factoryParamOptions(s, e);
    return this.cacheResponse(s, r);
  }
  async getAll(t, e, s) {
    const r = (e == null ? void 0 : e.per_page) || 25, n = `/${t}`, o = n.split("/"), l = s || o[o.length - 1], i = 1, c = await this.makeRequest(n, e, r, i), u = c.total ? Math.ceil(c.total / r) : 1, p = await this.helpers.asyncMap(
      this.helpers.range(i, u),
      (g) => this.makeRequest(n, e, r, g + 1)
    );
    return this.helpers.flatMap(
      [c, ...p],
      (g) => Object.values(g.data[l])
    );
  }
  post(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("post", s, e));
  }
  put(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("put", s, e));
  }
  delete(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("delete", s, e));
  }
  getStories(t) {
    return this.get("cdn/stories", t);
  }
  getStory(t, e) {
    return this.get(`cdn/stories/${t}`, e);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _cleanCopy(t) {
    return JSON.parse(JSON.stringify(t));
  }
  _insertLinks(t, e) {
    const s = t[e];
    s && s.fieldtype == "multilink" && s.linktype == "story" && typeof s.id == "string" && this.links[s.id] ? s.story = this._cleanCopy(this.links[s.id]) : s && s.linktype === "story" && typeof s.uuid == "string" && this.links[s.uuid] && (s.story = this._cleanCopy(this.links[s.uuid]));
  }
  _insertRelations(t, e, s) {
    if (s.indexOf(`${t.component}.${e}`) > -1) {
      if (typeof t[e] == "string")
        this.relations[t[e]] && (t[e] = this._cleanCopy(this.relations[t[e]]));
      else if (t[e] && t[e].constructor === Array) {
        const r = [];
        t[e].forEach((n) => {
          this.relations[n] && r.push(this._cleanCopy(this.relations[n]));
        }), t[e] = r;
      }
    }
  }
  iterateTree(t, e) {
    const s = (r) => {
      if (r != null) {
        if (r.constructor === Array)
          for (let n = 0; n < r.length; n++)
            s(r[n]);
        else if (r.constructor === Object) {
          if (r._stopResolving)
            return;
          for (const n in r)
            (r.component && r._uid || r.type === "link") && (this._insertRelations(
              r,
              n,
              e
            ), this._insertLinks(r, n)), s(r[n]);
        }
      }
    };
    s(t.content);
  }
  async resolveLinks(t, e) {
    let s = [];
    if (t.link_uuids) {
      const r = t.link_uuids.length, n = [], o = 50;
      for (let l = 0; l < r; l += o) {
        const i = Math.min(r, l + o);
        n.push(t.link_uuids.slice(l, i));
      }
      for (let l = 0; l < n.length; l++)
        (await this.getStories({
          per_page: o,
          language: e.language,
          version: e.version,
          by_uuids: n[l].join(",")
        })).data.stories.forEach(
          (c) => {
            s.push(c);
          }
        );
    } else
      s = t.links;
    s.forEach((r) => {
      this.links[r.uuid] = { ...r, _stopResolving: !0 };
    });
  }
  async resolveRelations(t, e) {
    let s = [];
    if (t.rel_uuids) {
      const r = t.rel_uuids.length, n = [], o = 50;
      for (let l = 0; l < r; l += o) {
        const i = Math.min(r, l + o);
        n.push(t.rel_uuids.slice(l, i));
      }
      for (let l = 0; l < n.length; l++)
        (await this.getStories({
          per_page: o,
          language: e.language,
          version: e.version,
          by_uuids: n[l].join(",")
        })).data.stories.forEach((c) => {
          s.push(c);
        });
    } else
      s = t.rels;
    s && s.length > 0 && s.forEach((r) => {
      this.relations[r.uuid] = { ...r, _stopResolving: !0 };
    });
  }
  async resolveStories(t, e) {
    var r, n;
    let s = [];
    if (typeof e.resolve_relations < "u" && e.resolve_relations.length > 0 && (typeof e.resolve_relations == "string" && (s = e.resolve_relations.split(",")), await this.resolveRelations(t, e)), e.resolve_links && ["1", "story", "url"].indexOf(e.resolve_links) > -1 && ((r = t.links) != null && r.length || (n = t.link_uuids) != null && n.length) && await this.resolveLinks(t, e), this.resolveNestedRelations)
      for (const o in this.relations)
        this.iterateTree(this.relations[o], s);
    t.story ? this.iterateTree(t.story, s) : t.stories.forEach((o) => {
      this.iterateTree(o, s);
    });
  }
  cacheResponse(t, e, s) {
    return new Promise((r, n) => {
      const o = this.helpers.stringify({ url: t, params: e }), l = this.cacheProvider();
      if (this.cache.clear === "auto" && e.version === "draft" && this.flushCache(), e.version === "published" && t != "/cdn/spaces/me") {
        const i = l.get(o);
        if (i)
          return r(i);
      }
      try {
        (async () => {
          var u;
          const i = await this.throttle("get", t, e);
          let c = { data: i.data, headers: i.headers };
          if ((u = i.headers) != null && u["per-page"] && (c = Object.assign({}, c, {
            perPage: i.headers["per-page"] ? parseInt(i.headers["per-page"]) : 0,
            total: i.headers["per-page"] ? parseInt(i.headers.total) : 0
          })), i.status != 200)
            return n(i);
          (c.data.story || c.data.stories) && await this.resolveStories(c.data, e), e.version === "published" && t != "/cdn/spaces/me" && l.set(o, c), c.data.cv && e.token && (e.version == "draft" && d[e.token] != c.data.cv && this.flushCache(), d[e.token] = c.data.cv), r(c);
        })();
      } catch {
      }
    });
  }
  throttledRequest(t, e, s) {
    return this.client[t](e, s);
  }
  cacheVersions() {
    return d;
  }
  cacheVersion() {
    return d[this.accessToken];
  }
  setCacheVersion(t) {
    this.accessToken && (d[this.accessToken] = t);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(t) {
            return f[t];
          },
          getAll() {
            return f;
          },
          set(t, e) {
            f[t] = e;
          },
          flush() {
            f = {};
          }
        };
      default:
        return {
          get() {
          },
          getAll() {
          },
          set() {
          },
          flush() {
          }
        };
    }
  }
  flushCache() {
    return this.cacheProvider().flush(), this;
  }
}
export {
  y as RichtextResolver,
  F as RichtextSchema,
  J as SbFetch,
  v as SbHelpers,
  Q as default
};
