import Component from '@glimmer/component';
import { set } from '@ember/object';
import { later } from '@ember/runloop';
import { action } from '@ember/object';

import { isEmpty, each } from 'lodash';
import toServer from 'mewe/stores/text-parsers/to-server';

export default class MwGroupQuestions extends Component {
  getQuestionElement(q) {
    return document.querySelector(`#${this.args.skin}-question-${this.args.groupQuestions.indexOf(q)} .ql-editor`);
  }

  @action
  removeQuestionOnBackspace(question, value, event) {
    this.args?.questionsChange?.();

    if (!event) return;

    let key = event.which || event.keyCode,
      isBackspace = key === 8;

    // let's don't remove last textarea so there will be at leas one empty question
    if (
      isBackspace &&
      isEmpty(toServer(question.text, { parseNativeMarkdown: true })) &&
      this.args.groupQuestions.length > 1
    ) {
      this.args.groupQuestions.removeObject(question);
    }
  }

  @action
  addQuestion() {
    let q = { text: '' };
    this.args.groupQuestions.pushObject(q);
    this.args?.questionsChange?.();
    later(
      this,
      () => {
        if (this.isDestroying || this.isDestroyed) return;
        this.getQuestionElement(q).focus();
      },
      0
    ); // need to wait until new question element will be rendered
  }

  @action
  editQuestionConfirmation(q) {
    // this is only for group settings and editing questions (text is not empty)
    if (this.args.skin !== 'settings' || isEmpty(toServer(q.text, { parseNativeMarkdown: true }))) return;

    each(this.args.groupQuestions, (q) => {
      set(q, 'showEditConfirmation', false);
    }); // if any other popup is opened then close it

    if (q.needsConfirmation !== false) {
      set(q, 'showEditConfirmation', true); // this field is also used for disabled attr to prevent text pasting
      set(q, 'needsConfirmation', true);
      this.getQuestionElement(q).blur();
    }
  }

  @action
  confirmEditing(q) {
    set(q, 'showEditConfirmation', false);
    set(q, 'needsConfirmation', false); // after confirming editing user can click inside answer input until unfocusing without next prompting
    later(
      this,
      () => {
        if (this.isDestroying || this.isDestroyed) return;
        this.editor.focus();
      },
      0
    ); // timeout needed for text-editor to loose disabled attr, put focus at the end of text
  }

  @action
  cancelEditing(q) {
    set(q, 'showEditConfirmation', false);
    this.getQuestionElement(q).blur();
  }

  @action
  questionUnfocused(q) {
    if (this.args.skin !== 'settings' || isEmpty(toServer(q.text, { parseNativeMarkdown: true }))) return;

    set(q, 'needsConfirmation', true);
  }

  @action
  setEditor(editor) {
    this.editor = editor;
  }
}
