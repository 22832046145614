import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class MwPostEvent extends Component {
  @service router;
  @service dynamicDialogs;

  @service dynamicDialogs;

  @action
  goToEvent(event) {
    if (this.args.post?.isPublicContent && this.args.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.args.post);
      return;
    }
    if (this.args.post.isGroupPreview) {
      return;
    }

    this.dynamicDialogs.closeByName('single-post-dialog');
    this.router.transitionTo('app.event', event.id);
  }
}
