import StringTransform from './string';
import NumberTransform from './number';
import BooleanTransform from './boolean';
import DateTransform from './date';
import ArrayTransform from './array';
//import ObjectTransform from './object';

export default {
  string: StringTransform.create(),
  number: NumberTransform.create(),
  boolean: BooleanTransform.create(),
  date: DateTransform.create(),
  array: ArrayTransform.create(),
  //'object': ObjectTransform.create()
};
