import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default class CropBox extends Component {
  @computed('args.imgUrl', 'args.dimensions')
  get style() {
    let cropLeft = parseInt(this.args.dimensions.left, 10);
    let cropTop = parseInt(this.args.dimensions.top, 10);
    let cropHeight = parseInt(this.args.dimensions.height, 10);
    let cropWidth = parseInt(this.args.dimensions.width, 10);

    return htmlSafe(`
      top: ${cropTop}px;
      left: ${cropLeft}px;
      width: ${cropWidth}px;
      height: ${cropHeight}px;
    `);
  }

  @computed('args.dimensions')
  get imgStyle() {
    let cropLeft = -parseInt(this.args.dimensions.left, 10) - 1;
    let cropTop = -parseInt(this.args.dimensions.top, 10) - 1;
    let wrapperWidth = parseInt(this.args.dimensions.wrapper?.width || 0, 10);

    return htmlSafe(`
      width: ${wrapperWidth}px;
      margin-top: ${cropTop}px;
      margin-left: ${cropLeft}px;
    `);
  }
}
