/* eslint-disable lines-between-class-members */
import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

export default class About extends Route {
  @service('storyblok') storyblok;

  model() {
    return this.storyblok.loadStory(`cms/about`);
  }
}
