import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import { each } from 'lodash';
import { htmlSafe } from '@ember/template';

import Scrolling from 'mewe/utils/scrolling-utils';
import GroupStore from 'mewe/stores/group-store';
import EmojiPicker from 'mewe/pods/components/pickers/mw-emoji-picker';
import { Theme, FeedTypes } from 'mewe/constants';

const EL_CLASS = '.emoji-senders_content';
const ALL = 'all';

export default class MwEmojiSenders extends Component {
  @service chat;
  @service account;
  @service dynamicDialogs;

  @reads('args.model') model;

  @tracked currentEmojiTab = this.args.isAllTab ? ALL : this.args.emoji?.unicode;
  @tracked currentEmoji = this.args.emoji;
  @tracked emojiSenders = this.args.emojiSenders || A();
  @tracked allEmojisSenders;
  @tracked isLoading = true;
  @tracked _isAllTab;
  @tracked nextPage;

  @tracked parent;
  @tracked dropdownId;
  @tracked dropdownUserId;
  @tracked dropdownEmojis;
  @tracked dropdownCallback;
  @tracked showSendersDropdown;

  maxResults = 15;
  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    // close emoji picker if it's opened and emoji senders dropdown is being opened
    if (EmojiPicker(getOwner(this)).isOpen()) {
      EmojiPicker().send('close');
    }
  }

  @action
  onInsert(element) {
    this.element = element;
    this.getSenders(true); // element needed first to bind scrolling after fetching
  }

  @computed('args.maxHeight')
  get customStyle() {
    if (this.args.maxHeight) {
      return htmlSafe(`max-height: ${this.args.maxHeight}px`);
    }

    return htmlSafe('');
  }

  @computed('model.emojisItems')
  get allEmojis() {
    return this.model?.emojisItems;
  }

  @computed('currentEmojiTab')
  get allTabSelected() {
    return this.currentEmojiTab === ALL;
  }

  @computed('currentEmoji.unicode')
  get currentEmojiUnicode() {
    return this.currentEmoji?.unicode;
  }

  @computed('model.groupId')
  get isUniversalGroup() {
    const group = this.model.groupId ? GroupStore.getState({ id: this.model.groupId }) : null;

    return group?.isUniversal;
  }

  @computed('args.scope', 'model.page.isOwnerAdmin', 'args.commentPost.page.isOwnerAdmin')
  get showSenderDropdown() {
    const scope = this.args.scope;
    return (
      (scope === Theme.PAGE || scope === Theme.PAGES || scope === FeedTypes.ALL) &&
      (this.model.page?.isOwnerAdmin || this.args.commentPost?.page?.isOwnerAdmin)
    );
  }

  prepareEmojiUnicode(emojiData) {
    return each(emojiData, (emojis) => {
      return each(emojis.emojis, (emoji, key) => {
        emojis.emojis[key] = emoji;
      });
    });
  }

  getSenders(isFirstLoad = false) {
    // on first load ignore isLoading flag as its initial state is true
    if (this.isLoading && !isFirstLoad) return;

    const params = {
      nextPage: this.nextPage,
      maxResults: this.maxResults,
    };

    if (!this.allTabSelected) {
      params.emoji = this.currentEmoji?.unicode;
    }

    const options = {
      scope: this.args.scope,
      model: this.model,
    };

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      let emojiData = data.users || data.usersWithEmojis;
      const allEmojisSenders = data && data.totalUsers;

      if (allEmojisSenders && !this.allEmojisSenders) {
        this.allEmojisSenders = allEmojisSenders;
      }

      emojiData = this.allTabSelected ? this.prepareEmojiUnicode(emojiData) : emojiData;

      const arr = A();

      if (this.allTabSelected) {
        each(emojiData, (data) => {
          data.user.emojis = data.emojis;

          if (data.user.id !== this.account.activeUser.id) {
            data.user.isNsfw = data.user._links.avatar.nsfw;
          }

          data.user.profileId = data.user.publicLinkId;

          arr.push(data.user);
        });
      } else {
        each(emojiData, (data) => {
          if (data.id !== this.account.activeUser.id) {
            data.isNsfw = data._links.avatar.nsfw;
          }

          data.profileId = data.publicLinkId;
          arr.push(data);
        });
      }

      if (this.nextPage) {
        this.emojiSenders.pushObjects(arr);
      } else {
        this.emojiSenders = arr;
        this.notifyEnmojiSendersUpdated(arr);
      }

      if (data._links && data._links.nextPage) {
        this.scrolling.bindScrollDownElement(this.element.querySelector(EL_CLASS), () => {
          this.getSenders();
        });
      } else {
        this.scrolling.unbindScrollDown(this.element.querySelector(EL_CLASS));

        if (this.emojiSenders.length !== this.args.emoji?.counter) {
          const emoji = this.model.emojisItems?.find((e) => e.unicode === this.currentEmoji?.unicode);

          if (emoji && this.emojiSenders?.length) {
            if (this.model.emojiCounts && this.currentEmoji?.unicode) {
              this.model.emojiCounts[this.currentEmoji.unicode] = this.emojiSenders.length;
            }
            emoji.counter = this.emojiSenders.length;
          }
        }
      }

      this.nextPage = data._links && data._links.nextPage ? data._links.nextPage.href : null;
      this.isLoading = false;
    };

    this.isLoading = true;

    if (this.allTabSelected) {
      this.args.api.getAllEmojis(options, params).then(callback);
    } else {
      this.args.api.getEmoji(options, params).then(callback);
    }
  }

  notifyEnmojiSendersUpdated(enmojiSenders) {
    if (this.args.onEmojiSendersUpdate) {
      this.args.onEmojiSendersUpdate(enmojiSenders);
    }
  }

  @action
  changeEmojiTab(tab) {
    if (this.isLoading) {
      return;
    }

    this.currentEmojiTab = tab;
    this.nextPage = null;
    this.emojiSenders = null;
    this._isAllTab = tab === ALL;

    if (this.allTabSelected) {
      this.currentEmoji = null;
    } else {
      this.currentEmoji = this.model.emojisItems?.find((postEmoji) => postEmoji.unicode == tab);
    }

    this.getSenders();
  }

  @action
  closeEmojiSendersDialog() {
    this.args.close();
  }

  @action
  openChat(user) {
    this.dynamicDialogs.closeAll();

    this.args.close();

    this.chat.openThreadByParticipants([user]);
  }

  get isAllTab() {
    return this._isAllTab ?? this.args.isAllTab;
  }

  @action
  openOptionsPopup(user) {
    const className = 'dropdown-opener--emoji-sender-dropdown';
    const btns = Array.from(this.element.getElementsByClassName(className));
    const dropdownElement = this.element.querySelector('.emoji-senders-dropdown');

    this.dropdownUserId = user.id;
    this.dropdownId = `${className}-${this.dropdownUserId}`;

    if (btns && btns.length) {
      this.parent = btns.find((btn) => btn.getAttribute('data-userid') === this.dropdownUserId);
    }

    if (dropdownElement) {
      if (dropdownId === dropdownElement.getAttribute('data-dropdownid')) return;
    }

    this.dropdownEmojis = this.isAllTab ? user.emojis : [this.currentEmojiUnicode];
    this.dropdownCallback = () => this.emojiSenders.removeObject(user);

    this.showSendersDropdown = true;
  }

  @action
  dropdownClose() {
    this.showSendersDropdown = false;
  }
}
