import { next, scheduleOnce, bind } from '@ember/runloop';
import * as Sentry from '@sentry/ember';
import PS from 'mewe/utils/pubsub';
import { repositionSimpleDropdown } from 'mewe/utils/popup-utils';
import { getElWidth, getElHeight } from 'mewe/utils/elements-utils';
import { computed } from '@ember/object';

export default {
  popupOnTop: false,

  openClass: 'popup-is-opened',

  popupType: 'dropdown-popup',

  dontCloseAt: '.dropdown--dont-close',

  classNameBindings: ['dropdownPosition.placement.top:dropdown--position-top'],

  attributeBindings: ['dropdownStyle.dropdownStyle:style', 'dropdownId:data-dropdownid'],

  init() {
    this._super(...arguments);
    this.closeBinded = this.close.bind(this);
    PS.Sub('close.dropdowns', this.closeBinded);
  },

  willDestroyElement() {
    this.close();
    this._super();
  },

  //to override in popups
  getPlacement() {
    return null;
  },

  position() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;
      let placement = this.getPlacement();
      if (placement && this.element) {
        this.element.style.top = `${placement.top}px`;
        this.element.style.left = `${placement.left}px`;
        this.element.style.right = `${placement.right}px`;

        // used for mw-emoji-list-popup
        if (placement.marginRight) {
          this.element.style.marginRight = `${placement.marginRight}px`;
        }
      }
    });
  },

  dropdownStyle: computed('dropdownPosition', function () {
    if (this.isDestroyed || this.isDestroying) return;
    if (this.popupType !== 'dropdown-popup') return;

    const dropdownPosition = this.dropdownPosition;

    return repositionSimpleDropdown(dropdownPosition);
  }),

  // includeWindowScroll if parent is the body and scrolls
  getDefaultDropdownOptions(includeWindowScroll) {
    // missing parent seems to cause errors in sentry, trying to verify that (https://exceptions.mewe.com/organizations/mewe/issues/1423)
    if (!this.parent) {
      Sentry.captureException(new Error('getDefaultDropdownOptions: ' + this.classNames));
      this.parent = document.body;
    }

    const parent = this.parent;
    const position = parent.getBoundingClientRect();

    return {
      margin: 15,
      parentOffset: {
        top: includeWindowScroll ? position.top + window.scrollY : position.top,
        left: position.left,
      },
      parentSize: {
        width: getElWidth(parent),
        height: getElHeight(parent),
      },
    };
  },

  mouseMoveEvent(event) {
    // 'mouseMoveWrapper' allows to set different element as area for mouse move than
    // 'parent' which can be treated as dropdown positioning element
    const mouseMoveWrapper = this.mouseMoveWrapper || this.parent;
    const target = event.target;
    const popup = this.element;

    if (!mouseMoveWrapper) return;
    if (mouseMoveWrapper !== target && !mouseMoveWrapper.contains(target)) {
      // if popupTimeout is set then popup is waiting to be opened
      // in that case don't check if target is contained by popup element
      // we want target to be only inside mouseMoveWrapper element for opening with timeout
      if (this.popupTimeout) {
        this.send('close');
      } else if (popup && target != popup && !popup.contains(target)) {
        if (this.dontCloseOnHoverElement && target.closest(this.dontCloseOnHoverElement)) return;

        this.send('close');
      }
    }
  },

  setMouseMoveEvent() {
    this._mouseMoveEventListener = bind(this, this.mouseMoveEvent);
    document.body.addEventListener('mousemove', this._mouseMoveEventListener);
  },

  keyDownEvent(e) {
    const KEY_ESC = 27,
      KEY_TAB = 9;
    if (e.keyCode === KEY_ESC || e.keyCode === KEY_TAB) this.send('close');
  },

  setKeyPressCloseEvent() {
    if (this.closeOnKeyPress) {
      this.keyDownEventBind = this.keyDownEvent.bind(this);
      document.body.addEventListener('keydown', this.keyDownEventBind);
    }
  },

  mouseLeaveEvent(event) {
    if (event.target.closest && event.target.closest(this.dontCloseAt)) return;
    this.send('close');
  },

  setDocumentMouseLeaveEvent() {
    this.mouseLeaveEventBind = this.mouseLeaveEvent.bind(this);
    document.addEventListener('mouseleave', this.mouseLeaveEventBind);
  },

  beforeCloseWrapper() {
    this.beforeCloseBind = this.beforeClose.bind(this);
    document.addEventListener('mouseup', this.beforeCloseBind);
  },

  beforeClose(event) {
    if (this.isDestroyed || this.isDestroying) return;

    if (event.type === 'mouseup') {
      const clickedInsideDropdownCloseArea =
        event.target.closest('.dropdown--close') || !event.target.closest(`#${this.element?.id}`);
      const clickedOnAllowToCloseElement = !event.target.closest(this.dontCloseAt);

      const canClose = clickedInsideDropdownCloseArea && clickedOnAllowToCloseElement;

      if (canClose) {
        document.removeEventListener('mouseup', this.beforeCloseBind);

        next(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.send('close');
        });
      }
    }
  },

  setDropdownPopupCloseEvent() {
    this.beforeCloseWrapperBind = this.beforeCloseWrapper.bind(this);
    document.addEventListener('mousedown', this.beforeCloseWrapperBind);
  },

  scrollEvent(e) {
    let scrolledInsideDropdownCloseArea;

    if (e.target.closest) {
      scrolledInsideDropdownCloseArea = e.target.closest(`#${this.element?.id}`);
    }

    // insideAnotherScrollable - prevent scrolling whole page when scrolling over popup element, happens because popup is placed in main page container
    // useful when popup is inside another scrollable element and scrolling moves whole page in the background instead of scrollable element SG-17198
    if (!scrolledInsideDropdownCloseArea || this.insideAnotherScrollable) {
      this.send('close');
    }
  },

  setScrollCloseEvent() {
    this.scrollEventBind = this.scrollEvent.bind(this);

    if (this.closeOnScroll) {
      window.addEventListener('scroll', this.scrollEventBind);
    }

    // scroll close when parent element containing popup is scrolled
    if (this.closeOnScrollContainer) {
      // close popup when messages list is scrolled
      this.scrollWrapper = this.parent.closest('.popup-scroll-close');
      this.scrollWrapper?.addEventListener('scroll', this.scrollEventBind);
    }
  },

  isPopupToClose() {
    return (
      this.popupType === 'dropdown-popup' ||
      this.popupType === 'giphy-popup' ||
      this.popupType === 'simple-emoji-picker'
    );
  },

  open() {
    if (document.getElementsByClassName(this.popupType).length > 0) return;

    this.setKeyPressCloseEvent();
    this.setScrollCloseEvent();

    if (this.popupType !== 'dropdown-popup') this.position();
    this.append();

    // some popups might be hidden before setting location especially when they contains autofocus
    if (this.element) {
      this.element.classList.remove('hidden');
    }

    // rework after complete transition to plain JS
    if (this.parent) {
      if (this.parent instanceof Element) {
        if (Array.isArray(this.openClass)) {
          this.parent.classList.add(...this.openClass);
        } else {
          this.parent.classList.add(this.openClass);
        }
      } else {
        this.parent.addClass(this.openClass);
      }
    }

    this.clearPopupTimeout();

    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;

      if (typeof this.onOpen === 'function') this.onOpen(this.popupId);

      if (this.isPopupToClose()) {
        next(() => {
          this.setDropdownPopupCloseEvent();
        });
      }
    });
  },

  clearPopupTimeout() {
    clearTimeout(this.popupTimeout);
    if (!this.isDestroyed && !this.isDestroying) {
      this.set('popupTimeout', null);
    }
  },

  close() {
    // rework after complete transition to plain JS
    if (this.parent) {
      if (this.parent instanceof Element) {
        this.parent.classList.remove(this.openClass);
      } else {
        this.parent.removeClass(this.openClass);
      }
    }

    if (this.closeOnKeyPress) document.body.removeEventListener('keydown', this.keyDownEventBind);
    if (this.closeOnHoverOutside) {
      document.body.removeEventListener('mousemove', this._mouseMoveEventListener);
      document.removeEventListener('mouseleave', this.mouseLeaveEventBind);
    }
    if (this.closeOnScroll) window.removeEventListener('scroll', this.scrollEventBind);
    if (this.scrollWrapper) this.scrollWrapper.removeEventListener('scroll', this.scrollEventBind);
    if (this.isPopupToClose()) document.removeEventListener('mousedown', this.beforeCloseWrapperBind);

    PS.Unsub('close.dropdowns', this.closeBinded);

    this.clearPopupTimeout();

    if (!this.isDestroying && !this.isDestroyed) this.destroy();
  },

  actions: {
    open() {
      if (this.closeOnHoverOutside) {
        this.setMouseMoveEvent();
        this.setDocumentMouseLeaveEvent();
      }

      if (this.delayUntilOpen) {
        this.set(
          'popupTimeout',
          setTimeout(() => this.open(), this.delayUntilOpen)
        );
      } else {
        this.open();
      }
    },

    close() {
      this.close();
    },
  },
};
