import { camelize } from '@ember/string';
import { A } from '@ember/array';
import { isString } from 'lodash';

export let splitRoutes = A();
export let waitRoutes = A();
export let allRoutes = A();
export let map = {};
export let parents = {};

function canNest(dsl) {
  return dsl.parent && dsl.parent !== 'application';
}

function getFullName(dsl, name, resetNamespace) {
  if (canNest(dsl) && resetNamespace !== true) {
    return dsl.parent + '.' + name;
  } else {
    return name;
  }
}

let _route = (self, name, _path, callback) => {
  if (_path) {
    if (isString(_path)) self.route(name, { path: _path }, callback);
    else self.route(name, _path);
  } else {
    self.route(name);
  }
};

export let route = (self, name, _path, callback) => {
  if (!map[self.parent]) map[self.parent] = A();

  if (!parents[self.parent]) parents[getFullName(self, name)] = self.parent;

  map[self.parent].push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let split = (self, name, _path, callback) => {
  splitRoutes.push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let wait = (self, name, _path, callback) => {
  waitRoutes.push(getFullName(self, name));

  allRoutes.push(getFullName(self, name));

  _route(self, name, _path, callback);
};

export let camelizedRoute = (text) => {
  var camelized = camelize(text.replace(/\./g, ' '));
  camelized = camelized.charAt(0).toUpperCase() + camelized.substr(1) + 'Route';
  return camelized;
};

export let dashed = (text) => text.replace(/\./g, '-');

export let slashed = (text) => text.replace(/\./g, '/');
