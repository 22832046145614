import Component from '@glimmer/component';
import { getOwner } from '@ember/application';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwPageTabs extends Component {
  @service router;

  @computed('args.page.{isFollower,isOwnerAdmin}')
  get showPagePermissions() {
    return !this.args.page?.isOwnerAdmin && this.args.page?.isFollower;
  }

  @action
  refreshFeed(forceRefresh) {
    //only refresh when on feed page
    if (this.router.currentRouteName != 'app.publicid.feed') return;
    //only refresh when there are new posts
    if (this.args.page?.newPosts > 0 || forceRefresh) {
      let route = getOwner(this).lookup(`route:app.publicid.feed`);
      if (route) route.send('refreshFeed');
    }
  }
}
