import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action } from '@ember/object';

import ChatStore from 'mewe/stores/chat-store';
import MediafeedApi from 'mewe/api/mediafeed-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import { Post } from 'mewe/stores/models/post-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { photosToGrid } from 'mewe/utils/photo-grid-utils/photo-grid-util';
import { standarizePhotos } from 'mewe/utils/photo-grid-utils/utils';
import { getElWidth } from 'mewe/utils/elements-utils';
import { Theme, FeedTypes } from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';

const Serializer = JSONSerializer.create();

export default class MwMediaFeed extends Component {
  limit = 20;
  scrolling = Scrolling();

  @tracked loading;

  @tracked gotResponse = false;
  @tracked thumbnails = A();
  @tracked mediaFeed = A();

  constructor() {
    super(...arguments);

    this.reloadAlbumFeedBind = this.reloadAlbumFeed.bind(this);
    PS.Sub('photoAlbums.reload', this.reloadAlbumFeedBind);
  }

  setInitialAttrs() {
    this.thumbnails = A();
    this.mediaFeed = A();
    this.gotResponse = false;
  }

  @action
  onInsert(element) {
    this.element = element;
    this.renderThumbnails();
  }

  @action
  onDestroy() {
    PS.Unsub('photoAlbums.reload', this.reloadAlbumFeedBind);
    this.scrolling.unbindScrollDown(this.element);
  }

  get invisible() {
    return !this.gotResponse || false;
  }

  get theme() {
    return this.args.scope || this.args.groupId;
  }

  reloadAlbumFeed(albumName) {
    if (albumName && this.args.album === albumName) {
      this.setInitialAttrs();
      this.renderThumbnails();
    }
  }

  createNewGrid(photos) {
    const containerWidth = getElWidth(this.element);
    const targetHeight = this.targetHeight || 250;
    return photosToGrid(photos.map(standarizePhotos(targetHeight)), targetHeight, containerWidth);
  }

  renderThumbnails() {
    if (this.loading) return;

    const galleryViewType = this.args.galleryViewType;

    const params = {
      limit: this.limit,
      order: this.args.multiPostId || this.args.album ? 1 : this.args.order,
      nextPage: this.nextPageUrl,
      threadId: this.args.threadId,
      pageId: this.args.pageId,
    };

    if (this.args.hashtag) {
      params.hashtag = this.args.hashtag;
    }

    params.userId = this.args.userId;

    if (galleryViewType !== 'album') {
      params.multiPostId = this.args.multiPostId;
    }

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      if (data.safeMode && !data.feed.length) {
        FunctionalUtils.error(__(`We're sorry but we couldn't retrieve the posts. Please try again later.`));
      }

      let media = data.feed || data.thumbnails || data.media;

      let posts = A();
      const PostThemed = Post.extend({
        postScope: this.args.scope,
      });

      Serializer.deserializeMany(posts, PostThemed, media);

      const grid = this.createNewGrid(posts);
      const nextPage = data._links?.nextPage?.href;

      this.mediaFeed.pushObjects(grid);

      if (nextPage) {
        this.scrolling.bindScrollDownElement(this.element, () => {
          this.renderThumbnails();
        });
      } else {
        this.scrolling.unbindScrollDown(this.element);
      }

      this.loading = false;
      this.nextPageUrl = nextPage;
    };

    this.loading = true;

    if (galleryViewType === 'album') {
      let albumScope = this.args.scope;

      if (albumScope === FeedTypes.ALL) {
        if (params.pageId) albumScope = FeedTypes.PAGE;
        else if (this.args.eventId && this.args.eventId !== FeedTypes.CONTACTS) albumScope = FeedTypes.GROUP;
        else if (this.args.eventId) albumScope = FeedTypes.EVENT;
        else albumScope = FeedTypes.CONTACTS;
      }

      MediafeedApi.getAlbumMedia(
        params,
        this.args.groupId,
        this.args.userId,
        this.args.album?.name || this.args.album,
        albumScope,
        this.args.eventId
      ).then(callback);
    } else if (galleryViewType === 'chat') {
      MediafeedApi.getGalleryDataForChatImg(params).then(callback);
    } else if (galleryViewType === 'poll') {
      let optionsWithImages = this.args.pollPost?.poll?.options?.filter((o) => o.image);

      const data = {
        media: optionsWithImages.map((o) => {
          return {
            photo: o.image,
          };
        }),
      };

      callback(data);
      this.scrolling.unbindScrollDown(this.element);
    } else if (galleryViewType === 'default') {
      let groupId;

      if (this.args.scope === Theme.MYCLOUD) {
        groupId = Theme.MYCLOUD;
      } else {
        groupId = this.args.groupId;
      }

      MediafeedApi.getMedia(params, groupId, this.args.eventId).then(callback);
    }
  }

  get placeholderText() {
    let state = ChatStore.getState();
    let thread = state.threads.find((t) => t.id === this.args.threadId);

    if (!thread) return '';

    let participants = thread.participants;

    if (thread.isGroupChat || thread.isEventChat) {
      return __('No shared photos yet!');
    } else if (participants.length > 1) {
      const otherName = thread.others?.[0]?.firstName;

      if (participants.length === 2) {
        return __('You and {name} haven’t shared any photos yet!', { name: otherName });
      } else {
        return __('You, {name} and {count} others haven’t shared any photos yet!', {
          name: otherName,
          count: participants.length - 2,
        });
      }
    }
  }

  @action
  openInMediaDialog(post) {
    let postType,
      thread,
      state = ChatStore.getState();

    if (this.args.galleryViewType === 'album') {
      postType = 'album';
    } else if (this.args.groupId === Theme.PRIVATEPOSTS || this.args.scope === Theme.PRIVATEPOSTS) {
      // SG-15974
      postType = 'privacymail';
      thread = state.threads.find((t) => t.id === this.args.threadId);
    } else if (this.args.hashtag) {
      postType = 'hashtag';
    } else if (this.args.galleryViewType === 'chat') {
      postType = 'chat';
      thread = state.threads.find((t) => t.id === this.args.threadId);
    } else if (this.args.galleryViewType === 'poll') {
      postType = 'poll';
    }

    this.args.openFromGalleryView?.(post, postType, this.args.order, thread);
  }

  @action
  placeholderButtonClick() {
    this.args.onPlaceholderButtonClick();
  }
}
