import { computed } from '@ember/object';
import { A } from '@ember/array';
import { each } from 'lodash';
import Component from '@ember/component';
import { later } from '@ember/runloop';

import layout from './template.hbs';
import './styles.scss';

import EmojiUtils from 'mewe/utils/emoji-utils';
import MasonryLayout from 'mewe/utils/photo-grid-utils/masonry-layout';
import Scrolling from 'mewe/utils/scrolling-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import GiphyApi from 'mewe/api/giphy-api';
import GfycatApi from 'mewe/api/gfycat-api';
import PopupOpener from 'mewe/utils/popup-opener-base';
import { reposition } from 'mewe/utils/popup-utils';
import { getElHeight, getWindowHeight } from 'mewe/utils/elements-utils';

export default Component.extend(PopupOpener, {
  layout: layout,

  classNames: ['giphy-popup', 'hidden', 'flex-wrap items-center content-start w-100 p-absolute o-hidden radius-16'],
  classNameBindings: ['smallVersion:giphy-popup--small-version'],

  scrolling: Scrolling(),
  maxResults: 15,

  fullSizePopupHeight: 550,
  maxColumnWidth: 200,

  closeOnClickOutside: true,
  closeOnKeyPress: true,
  dontCloseAt: '.dropdown--dont-close',

  giphyCategories: [
    {
      category: 'Awesome',
      url: 'https://media2.giphy.com/media/l2Sq5KYZeg6QiHp3q/200w_d.gif',
    },
    {
      category: 'Applause',
      url: 'https://media4.giphy.com/media/3o6Zt3AC93PIPAdQ9a/200w_d.gif',
    },
    {
      category: 'Awkward',
      url: 'https://media1.giphy.com/media/3o7TKE82I9YKYVVhRu/200w_d.gif',
    },
    {
      category: 'Bacon',
      url: 'https://media0.giphy.com/media/3oEdv9R4D62GPrVY4g/200w_d.gif',
    },
    {
      category: 'Bubbles',
      url: 'https://media1.giphy.com/media/3o6ozuvSRcSg3McDBK/200w_d.gif',
    },
    {
      category: 'Crying',
      url: 'https://media0.giphy.com/media/8xOLf7iywEycg/200w_d.gif',
    },
    {
      category: 'Dance',
      url: 'https://media3.giphy.com/media/TlK63EEk7IznsP18dk4/200w_d.gif',
    },
    {
      category: 'Deal with it',
      url: 'https://media3.giphy.com/media/MGCTZalz2doFq/200w_d.gif',
    },
    {
      category: 'Do not want',
      url: 'https://media3.giphy.com/media/g7ZlV1riFzVHW/200w_d.gif',
    },
    {
      category: 'Eww',
      url: 'https://media4.giphy.com/media/KZvT5AWF4ADAI/200w_d.gif',
    },
    {
      category: 'Excited',
      url: 'https://media1.giphy.com/media/inyqrgp9o3NUA/200w_d.gif',
    },
    {
      category: 'Eye Roll',
      url: 'https://media4.giphy.com/media/l0MYBJTSpJwGbWxGM/200w_d.gif',
    },
    {
      category: 'Facepalm',
      url: 'https://media3.giphy.com/media/3QGyuRd6TtaX6/200w_d.gif',
    },
    {
      category: 'Fist Bump',
      url: 'https://media2.giphy.com/media/DiHVguRg0LzQk/200w_d.gif',
    },
    {
      category: 'Frustrated',
      url: 'https://media0.giphy.com/media/uDRezGbZW1uo0/200w_d.gif',
    },
    {
      category: 'Good Luck',
      url: 'https://media1.giphy.com/media/4mQAsYNaFGbHG/200w_d.gif',
    },
    {
      category: 'Goodbye',
      url: 'https://media0.giphy.com/media/8ZVP2yAQKjOyQ/200w_d.gif',
    },
    {
      category: 'Happy Dance',
      url: 'https://media0.giphy.com/media/62VtuAEOyZiQE/200w_d.gif',
    },
    {
      category: 'Hearts',
      url: 'https://media2.giphy.com/media/ZkwbvaFT7ZaAU/200w_d.gif',
    },
    {
      category: 'High Five',
      url: 'https://media4.giphy.com/media/xTiTnFB13ZbIM6AgFi/200w_d.gif',
    },
    {
      category: 'Hug',
      url: 'https://media2.giphy.com/media/xT8qBajgkwehpxK2C4/200w_d.gif',
    },
    {
      category: 'IDK',
      url: 'https://media4.giphy.com/media/l46CbvTsdSDqRqIGA/200w_d.gif',
    },
    {
      category: 'Kiss',
      url: 'https://media2.giphy.com/media/lTQF0ODLLjhza/200w_d.gif',
    },
    {
      category: 'LOL',
      url: 'https://media1.giphy.com/media/3o6Zt5RdV09nL4kRd6/200w_d.gif',
    },
    {
      category: 'Mic Drop',
      url: 'https://media4.giphy.com/media/3o6Zthv4W72jUHRCUg/200w.gif',
    },
    {
      category: 'Mind Blown',
      url: 'https://media1.giphy.com/media/l0NwHXQy3kUSfFF60/200w_d.gif',
    },
    {
      category: 'No',
      url: 'https://media2.giphy.com/media/3oEjHYuwHzWUW4uses/200w_d.gif',
    },
    {
      category: 'Oh Snap',
      url: 'https://media0.giphy.com/media/26AHLBZUC1n53ozi8/200w_d.gif',
    },
    {
      category: 'OMG',
      url: 'https://media4.giphy.com/media/l4Ho0UxScKzfY0HIc/200w_d.gif',
    },
    {
      category: 'Okay',
      url: 'https://media0.giphy.com/media/wvrn7MMemcOB2/200w_d.gif',
    },
    {
      category: 'Oops',
      url: 'https://media1.giphy.com/media/Q8OIR3s0hT5p6/200w_d.gif',
    },
    {
      category: 'Please',
      url: 'https://media4.giphy.com/media/3oEjHBYcltJ4d6vSAE/200w_d.gif',
    },
    {
      category: 'Scared',
      url: 'https://media2.giphy.com/media/26FxNqvIrO3gnvcU8/200w_d.gif',
    },
    {
      category: 'Seriously',
      url: 'https://media2.giphy.com/media/26ufbvEIUS45ojxSw/200w_d.gif',
    },
    {
      category: 'Shocked',
      url: 'https://media4.giphy.com/media/l0HlV1c3zy6LCfCbS/200w_d.gif',
    },
    {
      category: 'Shrug',
      url: 'https://media1.giphy.com/media/3o6ZtgxMIv066qLPlS/200w_d.gif',
    },
    {
      category: 'Slow Clap',
      url: 'https://media4.giphy.com/media/26hitHKYT2iHlKY0g/200w_d.gif',
    },
    {
      category: 'Slow Motion',
      url: 'https://media3.giphy.com/media/3owypj0wKVYwSmDaPm/200w_d.gif',
    },
    {
      category: 'Smiling',
      url: 'https://media2.giphy.com/media/3o6gEb8J29QNy3L4ly/200w_d.gif',
    },
    {
      category: 'Thank You',
      url: 'https://media3.giphy.com/media/3oEjHWXddcCOGZNmFO/200w_d.gif',
    },
    {
      category: 'Thumbs Down',
      url: 'https://media2.giphy.com/media/izuCvXe50UhWg/200w_d.gif',
    },
    {
      category: 'Thumbs Up',
      url: 'https://media1.giphy.com/media/VUePBU7DuAkcE/200w_d.gif',
    },
    {
      category: 'Wink',
      url: 'https://media2.giphy.com/media/3o7abERY79vph15FV6/200w_d.gif',
    },
    {
      category: 'Yawn',
      url: 'https://media4.giphy.com/media/uSGwdLt9jPouA/200w_d.gif',
    },
    {
      category: 'Yes',
      url: 'https://media3.giphy.com/media/F68TnCvFfrU5O/200w_d.gif',
    },
    {
      category: 'You Got This',
      url: 'https://media0.giphy.com/media/4Vtk42BGiL1T2/200w_d.gif',
    },
  ],

  gfycatCategories: [
    {
      category: 'Awesome',
      url: 'https://thumbs.gfycat.com/SpecificComposedBuckeyebutterfly-max-1mb.gif',
    },
    {
      category: 'Applause',
      url: 'https://thumbs.gfycat.com/FrighteningWelltodoCero-max-1mb.gif',
    },
    {
      category: 'Awkward',
      url: 'https://thumbs.gfycat.com/FelineColdHarpyeagle-max-1mb.gif',
    },
    {
      category: 'Bacon',
      url: 'https://thumbs.gfycat.com/MistyFrighteningErin-max-1mb.gif',
    },
    {
      category: 'Bubbles',
      url: 'https://thumbs.gfycat.com/AnotherThankfulCanvasback-max-1mb.gif',
    },
    {
      category: 'Crying',
      url: 'https://thumbs.gfycat.com/FlamboyantFailingJellyfish-max-1mb.gif',
    },
    {
      category: 'Dance',
      url: 'https://thumbs.gfycat.com/FarflungLargeAlaskajingle-max-1mb.gif',
    },
    {
      category: 'Deal with it',
      url: 'https://thumbs.gfycat.com/BronzeSadAtlanticspadefish-max-1mb.gif',
    },
    {
      category: 'Do not want',
      url: 'https://thumbs.gfycat.com/FatApprehensiveHarrier-max-1mb.gif',
    },
    {
      category: 'Eww',
      url: 'https://thumbs.gfycat.com/SaltyVigilantDunnart-max-1mb.gif',
    },
    {
      category: 'Excited',
      url: 'https://thumbs.gfycat.com/FamousJadedJohndory-max-1mb.gif',
    },
    {
      category: 'Eye Roll',
      url: 'https://thumbs.gfycat.com/NiceDisgustingAfricanclawedfrog-max-1mb.gif',
    },
    {
      category: 'Facepalm',
      url: 'https://thumbs.gfycat.com/CookedConcernedCoati-max-1mb.gif',
    },
    {
      category: 'Fist Bump',
      url: 'https://thumbs.gfycat.com/ArcticCloseFreshwatereel-max-1mb.gif',
    },
    {
      category: 'Frustrated',
      url: 'https://thumbs.gfycat.com/EnchantingSkinnyEquine-max-1mb.gif',
    },
    {
      category: 'Good Luck',
      url: 'https://thumbs.gfycat.com/ConcreteOilyHoneycreeper-max-1mb.gif',
    },
    {
      category: 'Goodbye',
      url: 'https://thumbs.gfycat.com/SpecificThirstyIbadanmalimbe-max-1mb.gif',
    },
    {
      category: 'Happy Dance',
      url: 'https://thumbs.gfycat.com/QuickGeneralElephantbeetle-max-1mb.gif',
    },
    {
      category: 'Hearts',
      url: 'https://thumbs.gfycat.com/WarpedDisloyalEasteuropeanshepherd-max-1mb.gif',
    },
    {
      category: 'High Five',
      url: 'https://thumbs.gfycat.com/GrandFlamboyantAfricanbushviper-max-1mb.gif',
    },
    {
      category: 'Hug',
      url: 'https://thumbs.gfycat.com/ViciousDesertedGraysquirrel-max-1mb.gif',
    },
    {
      category: 'IDK',
      url: 'https://thumbs.gfycat.com/FlashyGlisteningAsiantrumpetfish-max-1mb.gif',
    },
    {
      category: 'Kiss',
      url: 'https://thumbs.gfycat.com/CourteousCanineBeagle-max-1mb.gif',
    },
    {
      category: 'LOL',
      url: 'https://thumbs.gfycat.com/FrenchDismalIcelandichorse-max-1mb.gif',
    },
    {
      category: 'Mic Drop',
      url: 'https://thumbs.gfycat.com/UnacceptablePoliteGrayling-max-1mb.gif',
    },
    {
      category: 'Mind Blown',
      url: 'https://thumbs.gfycat.com/AngryScratchyBoilweevil-max-1mb.gif',
    },
    {
      category: 'No',
      url: 'https://thumbs.gfycat.com/ScratchyCalculatingGerbil-max-1mb.gif',
    },
    {
      category: 'Oh Snap',
      url: 'https://thumbs.gfycat.com/SingleGeneralAdamsstaghornedbeetle-max-1mb.gif',
    },
    {
      category: 'OMG',
      url: 'https://thumbs.gfycat.com/ThankfulUnsteadyAdouri-max-1mb.gif',
    },
    {
      category: 'Okay',
      url: 'https://thumbs.gfycat.com/LikableValidAlpineroadguidetigerbeetle-max-1mb.gif',
    },
    {
      category: 'Oops',
      url: 'https://thumbs.gfycat.com/ComfortableConsiderateHornshark-max-1mb.gif',
    },
    {
      category: 'Please',
      url: 'https://thumbs.gfycat.com/HotGrossApe-max-1mb.gif',
    },
    {
      category: 'Scared',
      url: 'https://thumbs.gfycat.com/InsignificantDependableAnemoneshrimp-max-1mb.gif',
    },
    {
      category: 'Seriously',
      url: 'https://thumbs.gfycat.com/UltimatePeacefulBasilisk-max-1mb.gif',
    },
    {
      category: 'Shocked',
      url: 'https://thumbs.gfycat.com/ImmediatePoisedAiredaleterrier-max-1mb.gif',
    },
    {
      category: 'Shrug',
      url: 'https://thumbs.gfycat.com/PartialCarelessArabianhorse-max-1mb.gif',
    },
    {
      category: 'Slow Clap',
      url: 'https://thumbs.gfycat.com/InnocentUnlinedDormouse-max-1mb.gif',
    },
    {
      category: 'Slow Motion',
      url: 'https://thumbs.gfycat.com/ArtisticEducatedAsianwaterbuffalo-max-1mb.gif',
    },
    {
      category: 'Smiling',
      url: 'https://thumbs.gfycat.com/SlightDeliriousArcherfish-max-1mb.gif',
    },
    {
      category: 'Thank You',
      url: 'https://thumbs.gfycat.com/ParallelImpishAustraliankelpie-max-1mb.gif',
    },
    {
      category: 'Thumbs Down',
      url: 'https://thumbs.gfycat.com/DifficultCarefulBrant-max-1mb.gif',
    },
    {
      category: 'Thumbs Up',
      url: 'https://thumbs.gfycat.com/SpecificComposedBuckeyebutterfly-max-1mb.gif',
    },
    {
      category: 'Wink',
      url: 'https://thumbs.gfycat.com/LikelyRipeAardvark-max-1mb.gif',
    },
    {
      category: 'Yawn',
      url: 'https://thumbs.gfycat.com/ShockedHopefulEstuarinecrocodile-max-1mb.gif',
    },
    {
      category: 'Yes',
      url: 'https://thumbs.gfycat.com/EmbarrassedUnhealthyAcaciarat-max-1mb.gif',
    },
    {
      category: 'You Got This',
      url: 'https://thumbs.gfycat.com/HomelyPaleAustralianfurseal-max-1mb.gif',
    },
  ],

  editor: null,

  setEditor(editor) {
    this.editor = editor;
  },

  init() {
    this.setProperties({
      provider: 'giphy',
      gifCategories: this.giphyCategories,
      gifs: A(),
      loading: false,
      offset: 0,
      emojies: EmojiUtils.emojiByCategory().people,
      emojiesShown: false,
      searchStarted: false,
      popupType: this.classNames[0],
      smallVersion: this.isWindowSmallerThanPopup(),
      bindGifsScrollLoadingBind: this.bindGifsScrollLoading.bind(this),
    });

    this._super(...arguments);
  },

  popupSize: computed('smallVersion', function () {
    const small = {
      width: 296,
      height: 389,
    };

    const big = {
      width: 443,
      height: 549,
    };

    return this.smallVersion ? small : big;
  }),

  didRender() {
    later(this, () => {
      if (!this.isDestroying && !this.isDestroyed) {
        if (this.editor) this.editor.focus();
      }
    });
  },

  willDestroyElement() {
    this.clean();

    document.removeEventListener('scroll', this.closeOnEventBind);

    this._super();
  },

  categoriesShown: computed('searchStarted', 'emojiesShown', function () {
    return !this.searchStarted && !this.emojiesShown;
  }),

  bindGifsScrollLoading() {
    if (!this.isDestroying && !this.isDestroyed) {
      this.loadGifs();
    }
  },

  // can't use videos here or chrome crashes when approx. 40 videos have been added to the page (removing nonvisible videos doesn't help)
  loadGifs() {
    const gifsEl = this.element.querySelector('.giphy-gifs');

    if (!gifsEl) return;

    let scrollTop = gifsEl.scrollTop,
      scrollBottom = getElHeight(gifsEl) + scrollTop;

    this.setProperties({
      scrollTop: scrollTop,
      scrollBottom: scrollBottom,
    });

    let visibleGifs = this.gifs.filter((gif) => this.isGifVisible(gif, scrollTop, scrollBottom));

    each(visibleGifs, (gif) => {
      let img = new Image();
      img.onload = () =>
        later(this, () => {
          if (!this.isDestroying && !this.isDestroyed) {
            const gifEl = this.element.querySelector(`.gif-img[src='${gif.downSampledUrl}']`);
            if (gifEl) gifEl.classList.add('loaded');
          }
        });

      img.src = gif.downSampledUrl;
    });
  },

  isGifVisible(gif, scrollTop, scrollBottom) {
    return (
      (gif.position.top >= scrollTop && gif.position.top <= scrollBottom) ||
      (gif.position.bottom >= scrollTop && gif.position.bottom <= scrollBottom)
    );
  },

  isWindowSmallerThanPopup() {
    return getWindowHeight() < this.fullSizePopupHeight + this.fullSizePopupHeight / 3;
  },

  gifUrlWithWidthAndHeight(gif) {
    let url = gif.url.toString() || '';

    if (url.indexOf('giphy.com') === -1) {
      return `${gif.url}#h=${gif.originalHeight || gif.height}w=${gif.originalWidth || gif.width}`;
    } else {
      url = url.replace(/[?]response_id=[^& #]+/, '');
      url = url.replace('&amp;ct=g', '');

      let end = url.slice(url.lastIndexOf('/'));

      if (end.indexOf('w') !== -1) {
        return `${url}#h=${gif.height}`;
      } else {
        return `${url}#w=${gif.width}`;
      }
    }
  },

  getPlacement() {
    const isRtl = document.dir;

    const placement = reposition(this.parent, this.popupSize),
      rectParent = this.parent.getBoundingClientRect(),
      rectParentLeft = rectParent.left + document.body.scrollLeft;

    //adjust placement for chat giphy
    if (this.origin === 'chat' || this.origin === 'expanded-chat') {
      this.element.style.position = 'fixed';

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      placement.top = placement.top - scrollTop;
    }

    if (this.origin === 'chat') {
      if (!isRtl) placement.left = rectParentLeft - this.popupSize.width + 30;
    } else if (this.origin === 'expanded-chat') {
      if (isRtl) placement.left = rectParentLeft - this.popupSize.width + 30;
      else placement.left = rectParentLeft;
    }

    return placement;
  },

  getDialogWrapper() {
    return document.querySelector('.dialog_wrapper');
  },

  clean() {
    const gifsEl = this.element.querySelector('.giphy-gifs');

    if (gifsEl) {
      gifsEl.removeEventListener('scroll', this.bindGifsScrollLoadingBind);
    }
  },

  actions: {
    showEmojiesIfNoSearchTerm(term) {
      term = (toServer(term, { parseNativeMarkdown: true }) || '').trim();
      if (!term) this.set('emojiesShown', true);
    },

    selectGif(gif) {
      const gifToSend = this.gifUrlWithWidthAndHeight(gif);

      // gif can either be passed to 'onSelect' callback (used in chat where it's sent immediately)
      // of pushed to 'selectedGifs' (in postbox or comment it is stored until sending)
      this.onSelect?.(gifToSend);
      this.selectedGifs?.pushObject(gifToSend);

      this.send('close');
    },

    searchFromTextEditor(text) {
      clearTimeout(this.searchTimeoutId);

      text = toServer(text, { parseNativeMarkdown: true });

      this.setProperties({
        searchText: text,
        gifs: A(),
        offset: 0,
        cursor: null,
        emojiesShown: false,
      });

      this.send('search', text);
    },

    search(term, autoUpdate) {
      if (!term) {
        this.clean();

        this.setProperties({
          gifs: A(),
          searchStarted: false,
        });
        return;
      }

      this.set('searchStarted', true);

      if (autoUpdate) {
        if (this.editor) {
          this.editor.update(term);
        }
      }

      const offset = this.offset;

      const doSearch = () => {
        const onSuccess = (resp, isFirstPage, hasMore) => {
          const gifsEl = this.element.querySelector('.giphy-gifs');
          let newGifs;

          if (isFirstPage) {
            const options = {
              columnsCount: 2,
              columnWidth: this.smallVersion ? 125 : this.maxColumnWidth,
              gapSize: 4,
            };

            this.set('masonryLayoutGifs', new MasonryLayout(resp.data, options));
            newGifs = this.get('masonryLayoutGifs.items');
            this.set('gifs', A(newGifs));

            if (gifsEl) {
              gifsEl.addEventListener('scroll', this.bindGifsScrollLoadingBind);
            }
          } else {
            let layoutGifs = this.masonryLayoutGifs;
            if (layoutGifs) {
              newGifs = layoutGifs.processNewItems(resp.data);
              this.gifs.pushObjects(newGifs);
            }
          }

          this.incrementProperty('offset', this.maxResults);
          this.set('cursor', resp.next || resp.cursor);

          this.loadGifs();

          if (hasMore) {
            this.scrolling.bindScrollDownElement(
              gifsEl,
              () => {
                this.send('search', term);
              },
              200,
              true // allow to bind scrolling while postbox is opened
            );
          }
        };

        if (this.provider === 'gfycat') {
          GfycatApi.search({
            search_text: term.replace(/[:]/g, '').replace(/_/g, ' '), // remove : and _ from emojies
            cursor: this.cursor,
            count: this.maxResults,
          })
            .then((resp) => {
              if (this.isDestroyed || this.isDestroying) return;

              resp.data = resp.data.map((image) => {
                const ratio = image.width ? image.height / image.width : 1;
                image.originalWidth = image.width;
                image.originalHeight = image.height;
                image.height = this.maxColumnWidth * ratio;
                image.width = this.maxColumnWidth;

                return image;
              });
              onSuccess(resp, !this.cursor, resp.cursor);
            })
            .finally(() => {
              if (this.isDestroyed || this.isDestroying) return;

              this.setProperties({
                searchTimeoutId: null,
                loading: false,
              });
            });
        } else {
          GiphyApi.search({
            searchTerm: term.replace(/[:]/g, '').replace(/_/g, ' '), // remove : and _ from emojies
            offset: offset,
            maxResults: this.maxResults,
          })
            .then((resp) => {
              if (this.isDestroyed || this.isDestroying) return;

              onSuccess(resp, offset === 0, resp.data.length >= this.maxResults);
            })
            .finally(() => {
              if (this.isDestroyed || this.isDestroying) return;

              this.setProperties({
                searchTimeoutId: null,
                loading: false,
              });
            });
        }
      };

      this.setProperties({
        loading: true,
        searchTimeoutId: setTimeout(doSearch, 500),
      });
    },

    addEmoji(emoji) {
      this.send('search', emoji.shortname, true);
      this.set('emojiesShown', false);
    },

    open() {
      this._super(...arguments);

      if (this.getDialogWrapper()) {
        this.scrolling.bindScrollTreshold(
          this.getDialogWrapper(),
          () => {
            this.send('close');
          },
          10,
          'sticker-picker-close'
        );
      }
    },

    close() {
      this._super(...arguments);

      // closing mw-attachment popup
      window.setTimeout(() => {
        document.body.click();
      }, 0);

      const onClose = this.onClose;
      if (onClose) onClose();

      this.scrolling.unbindScrollDown(this.getDialogWrapper());
    },
  },
});
