import dispatcher from 'mewe/dispatcher';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Theme } from 'mewe/constants';
import { getQueryStringParams, extractParamsFromPostLink } from 'mewe/shared/utils';

const getRegexpHost = () => {
  return EnvironmentUtils.getHost()
    .toString()
    .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp constants
};

const getPostRegExp = () => {
  return new RegExp(
    '(?:(?:(?:http|https)://){0,1}' +
      getRegexpHost() +
      ')/(?:(?:(post)/show/([0-9a-zA-Z]+))|(?:(event|group|page)/([0-9a-zA-Z]+)/show/([0-9a-zA-Z]+))|(?:([0-9a-zA-Z]+)/show/([0-9a-zA-Z]+)))',
    'ig'
  );
};

export const openSinglePost = (url) => {
  if (!url) {
    return;
  }

  // new format of links is common for all scopes and contains
  // url params to indicate the scope if needed. Old handling should
  // be deleted after grace period.
  const postLinkParams = extractParamsFromPostLink(url);
  const queryParams = postLinkParams.queryParams;

  let newScope, groupId, eventId, pageId;

  if (postLinkParams.postId) {
    if (queryParams.groupId) {
      newScope = Theme.GROUP;
      groupId = queryParams.groupId;
    } else if (queryParams.eventId) {
      newScope = Theme.EVENT;
      eventId = queryParams.eventId;
    } else if (queryParams.ppId) {
      newScope = Theme.PAGE_LINK_ID;
      pageId = queryParams.ppId;
    } else {
      // newScope = Theme.CONTACTS;
      // here will be handling timeline links (without url params)
      // but only after removing old links handling
    }

    if (newScope) {
      dispatcher.dispatch('feed', 'openSinglePost', {
        postItemId: postLinkParams.postId,
        scope: newScope,
        groupId,
        eventId,
        pageId,
      });
      return true;
    }
  }

  // handling old format. To be removed after grace period.
  // **********
  const match = getPostRegExp().exec(url);
  if (!match || match.length === 0) {
    return;
  }

  let myWorldScope = match[1],
    postItemId;

  if (myWorldScope) {
    postItemId = match[2];

    dispatcher.dispatch('feed', 'openSinglePost', {
      scope: Theme.CONTACTS,
      postItemId: postItemId,
      isPostFromUrl: true,
    });
  } else {
    let feedScope = match[3],
      feedId = match[4];

    postItemId = match[5];
    if (feedScope) {
      dispatcher.dispatch('feed', 'openSinglePost', {
        scope: feedScope,
        groupId: feedId,
        eventId: feedId,
        pageId: feedId,
        postItemId: postItemId,
      });
    } else {
      const pagePublicLinkId = match[6];
      const postId = match[7];
      dispatcher.dispatch('feed', 'openSinglePost', {
        scope: Theme.PAGE_LINK_ID,
        pageId: pagePublicLinkId,
        postItemId: postId,
      });
    }
  }

  return true;
};
