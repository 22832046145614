import { inject as service } from '@ember/service';
import Route from 'mewe/routes/route';
import Session from 'mewe/shared/session';

export default class Error500Route extends Route {
  @service router;

  redirect(redir) {
    if (redir?.path !== '500') {
      this.router.transitionTo('error-500');
    }

    document.body.classList.add('error-page');

    Session.isAuthenticated().then(() => {
      // we do not care if useer is authenticated, just if API is back;
      window.location = '/';
    });
  }
}
