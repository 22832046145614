import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

import EventUtils from 'mewe/utils/event-utils';

export default class MwEventRepeatSelector extends Component {
  @service account;
  @service dynamicDialogs;

  reminderOptions = EventUtils.getReminderOptions();
  repeatOptions = EventUtils.getRepeatOptions();

  @computed('args.repeatObj', 'args.startDateTS')
  get repeatText() {
    if (this.args.repeatObj) {
      return EventUtils.getSummaryText(this.args.repeatObj, this.args.startDateTS, this.account.activeUser.locale);
    } else {
      return '';
    }
  }

  @computed('args.repeatObj')
  get repeatValue() {
    if (this.args.repeatObj) {
      return this.repeatOptions[this.args.repeatObj.repeatValue];
    } else {
      return this.repeatOptions.none;
    }
  }

  @computed('args.reminder')
  get reminderText() {
    const selectedOption = this.reminderOptions.find(({ time }) => time == this.args.reminder);
    return selectedOption ? selectedOption.text : 'None';
  }

  handleRepeat(repeatObj) {
    this.dynamicDialogs.openDialog('event-repeat-dialog', {
      repeat: repeatObj,
      startDate: this.args.startDateTS,
      endDate: this.args.endDateTS,
      onOkClick: (repeatObj) => {
        this.args.updateRepeat(repeatObj);
      },
    });
  }

  @action
  setRepeat(index) {
    if (index === 'none') {
      this.args.updateRepeat(null);
      return;
    }
    this.handleRepeat({ repeatValue: index });

    this.repeat = this.repeatOptions[index];
  }

  @action
  editRepeat() {
    this.handleRepeat(this.args.repeatObj);
  }
}
