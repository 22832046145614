import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { each } from 'lodash';
import { tracked } from '@glimmer/tracking';

import { sortMediaOptions } from 'mewe/utils/options';

export default class AppProfileMedia extends Component {
  @tracked tab;
  @tracked order;
  @tracked albumView;
  @tracked albumName;
  @tracked sortMediaOptions;

  constructor() {
    super(...arguments);

    this.order = 0;
    this.tab = 'stream';

    this.sortMediaOptions = sortMediaOptions();

    each(this.sortMediaOptions.subOptions, (o) => {
      if (o.order === this.order) {
        set(o, 'selected', true);
      } else {
        set(o, 'selected', false);
      }
    });
  }

  get showFilters() {
    return (
      this.args.model.contact.isMe ||
      this.args.model.contact.public ||
      (this.args.model.contact.public === false && this.args.model.contact.following)
    );
  }

  @action
  showTab(tab) {
    this.tab = tab;
    this.resetTabs();
  }

  @action
  orderMedia(option) {
    this.order = option.order;
  }

  @action
  openAlbumStream(album) {
    this.albumName = album.name;
    this.albumView = true;
  }

  @action
  resetTabs() {
    this.albumName = '';
    this.albumView = false;
  }
}
