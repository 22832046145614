export default (c, log) => {
  log(' ');

  if (c.postToShare) log(`-- RESHARE --`);
  if (c.postToRepost) log(`-- REPOST --`);
  if (!c.postToShare && !c.postToRepost) log('--- POST ---');

  log(` from: ${c.theme}`);
  log(`   to: ${c.target}`);
  log(`   id: ${c.groupId || c.eventId || (c.page ? c.page.id : null)}`);

  log(' ');
  log('--- open with ---');
  log(` openWithPhotoBrowser: ${c.openWithPhotoBrowser}`);
  log(` openWithVideoBrowser: ${c.openWithVideoBrowser}`);
  log(`  openWithFileBrowser: ${c.openWithFileBrowser}`);
  log(`openWithAlbumDropdown: ${c.openWithAlbumDropdown}`);

  if (c.postToRepost) {
    log(' ');
    log('-- prefill --');

    if (c.postToRepost.link) log(`prefill link ${c.postToRepost.link}`);

    if (c.postToRepost.gifUrls) log(`prefill gif urls ${c.postToRepost.gifUrls.length}`);

    if (c.postToRepost.medias && c.postToRepost.medias.length) log(`prefill medias ${c.postToRepost.medias.length}`);

    if (c.postToRepost.video) log(`prefill video ${c.postToRepost.video}`);

    if (c.postToRepost.files && c.postToRepost.files.length) log(`prefill files ${c.postToRepost.files.length}`);

    if (c.postToRepost.album) log(`prefill album ${c.postToRepost.album}`);
  }
};
