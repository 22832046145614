import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';
import { A } from '@ember/array';

import PhotosApi from 'mewe/api/photos-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { Theme } from 'mewe/constants';
import { isValidUrlParam } from 'mewe/utils/miscellaneous-utils';
import { restartableTask, timeout } from 'ember-concurrency';

export default class MwAlbumPicker extends Component {
  limit = 15;

  @tracked albums = A();
  @tracked addToAlbumDropdownOpened;
  @tracked albumName = '';
  @tracked isNameInvalid;

  constructor() {
    super(...arguments);

    if (this.args.isAddToAlbumDialog) {
      this.addToAlbumDropdownOpened = true;
      this.focusAlbumInput();
    }

    // adding photos to preselected album -> check if it's full (MW-387)
    // adding photo post to album -> load albums upon opening dialog
    if (this.args.selectedAlbumName || this.args.isAddToAlbumDialog) {
      this.loadAlbums.perform(true);
    }
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  get albumNameValue() {
    return (this.albumName || '').trim();
  }

  @restartableTask
  *loadAlbums(isFirstLoad = false) {
    yield timeout(400);

    // chekcing for true because it can be also event object
    isFirstLoad = isFirstLoad === true;
    // reset error state on any change
    this.isNameInvalid = false;
    // clear albums list
    this.albums = null;

    const eventId = this.args.eventId,
      groupId = this.args.groupId,
      pageId = this.args.pageId;

    let params = {
        limit: this.limit,
        toAdd: this.args.photosLength,
        term: isFirstLoad ? this.args.selectedAlbumName : this.albumNameValue
      },
      scope,
      scopeId;

    if (eventId) {
      scopeId = eventId;
      scope = Theme.EVENT;
    } else if (pageId) {
      scopeId = pageId;
      scope = Theme.PAGE;
    } else if (groupId && groupId !== Theme.GROUPS && groupId !== Theme.MYCLOUD) {
      if (groupId !== 'contacts') {
        scopeId = groupId;
        scope = Theme.GROUP;
      }
    } else if (this.args.target === Theme.MYCLOUD) {
      scope = Theme.MYCLOUD;
    }

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      // adding photos to preselected album -> check if it's full (MW-387)
      if (isFirstLoad && this.args.selectedAlbumName) {
        if (data.albums?.[0]?.full) {
          this.args.setAlbumName('');
          FunctionalUtils.error(__('Album size limit reached.'));
        }
      } else {
        this.albums = data.albums || A();
      }
    };

    const fail = (resp) => {
      if (resp && resp.status) {
        FunctionalUtils.showDefaultErrorMessage();
      }
    };

    PhotosApi.getAlbumNames(scope, scopeId, params).then(callback).catch(fail);
  }

  focusAlbumInput() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;

      const inputEl = this.element.querySelector('.album-picker_content input');
      inputEl?.focus();
    });
  }

  validateAlbumName() {
    const value = this.albumNameValue;
    const isExisitAndFull = this.albums.find(album => album.name === value && album.full);
    let isNotValid = !value || value.trim().length === 0 || isExisitAndFull;

    if (!isNotValid) {
      if (!isValidUrlParam(value)) {
        isNotValid = true;
        FunctionalUtils.error(__('Album name is incorrect'));
      }
    }

    this.isNameInvalid = isNotValid;
  }

  @action
  addAlbum() {
    this.validateAlbumName();

    if (!this.isNameInvalid) {
      this.addToAlbumDropdownOpened = false;
      this.args.setAlbumName(this.albumNameValue);
    }
  }

  @action
  selectAlbum(album) {
    if (album.full) {
      return;
    }

    if (this.albumName === album.name) {
      this.args.setAlbumName('');
    } else {
      this.addToAlbumDropdownOpened = false;

      this.args.setAlbumName(album.name);
    }
  }

  @action
  openAddToAlbumDropdown() {
    // clean error if there is any from previous opening
    this.isNameInvalid = false;

    this.addToAlbumDropdownOpened = true;
    this.loadAlbums.perform();
    this.focusAlbumInput();
  }

  @action
  closeDropdown() {
    this.addToAlbumDropdownOpened = false;
  }
}
