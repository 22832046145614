/* eslint-disable lines-between-class-members */
import { set } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';
import { tracked } from '@glimmer/tracking';
import PagesApi from 'mewe/api/pages-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import dispatcher from 'mewe/dispatcher';

export default class AppPageSettingsAdmin extends Component {
  @alias('args.model.appPage.page') page;

  @service dynamicDialogs;
  @service account;

  @tracked loaded;
  @tracked isDeleteFormVisible;
  @tracked showDeleteButton;
  @tracked published;
  @tracked allowComments;
  @tracked passwordError;
  @tracked password = '';
  @tracked admins = A();
  @tracked isSaving;

  constructor() {
    super(...arguments);

    PagesApi.getAdmins(this.page.id).then((data) => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.admins.pushObjects(data.admins);
      }
    });

    PagesApi.getSettings(this.page.id).then((data) => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.allowComments = data.pFollComm;
      }
    });

    this.setLoaded();

    addObserver(this, 'page.published', this.setLoaded);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    removeObserver(this, 'page.published', this.setLoaded);
  }

  setLoaded() {
    if (isDefined(this.page.published)) {
      this.loaded = true;
      this.published = this.page.published;
    }
  }

  @computed('isSaving', 'loaded')
  get isPending() {
    return this.isSaving || !this.loaded;
  }

  @action
  passwordChanged() {
    this.passwordError = false;
    this.showDeleteButton = this.password.length > 5;
  }

  @action
  toggleCommenting() {
    if (!this.loaded) {
      return;
    }

    this.isSaving = true;

    PagesApi.saveSettings(this.page.id, { allowFollowersToCommentPost: !this.allowComments })
      .then(() => {
        FunctionalUtils.info(__('Page Settings have been successfully saved.'));
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  @action
  selectNewOwner() {
    if (this.page.hasActiveSubscription) {
      dispatcher.dispatch('pages', 'cancelSubscriptionAlert');
      return;
    }

    dispatcher.dispatch('pages', 'selectNewOwner', this.page, null, true);
  }

  @action
  confirmPageDeleting() {
    let page = this.page;

    PagesApi.deletePage(page.id, this.password)
      .then(() => {
        FunctionalUtils.info(__('Page {pageName} has been successfully deleted.', { pageName: page.name }));
        location.href = '/myworld';
      })
      .catch((data) => {
        if (data.status === 403) {
          this.passwordError = true;
        } else {
          FunctionalUtils.showDefaultErrorMessage();
        }
      });
  }

  @action
  showDeleteForm() {
    if (this.page.hasActiveSubscription) {
      dispatcher.dispatch('pages', 'cancelSubscriptionAlert');
      return;
    }

    // web3 users dont need to enter password
    if (this.account.activeUser.dsnpHandle) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm Deletion'),
        message: __(
          'Are you ABSOLUTELY sure you want to delete this page? <br><br> You will NOT be able to undo this.'
        ),
        noEscaping: true,
        cancelButtonText: __('Cancel'),
        okButtonText: __(`Yes, I'm absolutely sure`),
        onConfirm: () => {
          this.confirmPageDeleting();
        },
      });
    } else {
      this.isDeleteFormVisible = true;
    }
  }

  @action
  addAdmin(user) {
    PagesApi.addAdmin(this.page.id, user.id).then(() => {
      this.admins.pushObject({ user });
      FunctionalUtils.info(__('Admin successfully added'));
    });
  }

  @action
  removeAdmin(admin) {
    PagesApi.removeAdmin(this.page.id, admin.user.id).then(() => {
      this.admins.removeObject(admin);

      if (admin.user.id === this.account.activeUser.id) {
        window.location = `/p/${this.args.model.page.publicLinkId}`;
      } else {
        FunctionalUtils.info(__('Admin successfully removed'));
      }
    });
  }

  @action
  visibilityChange(visibility) {
    this.published = visibility;

    if (!this.loaded || this.page.published === this.published) {
      return;
    }

    this.isSaving = true;

    let xhr;
    if (this.published) {
      xhr = PagesApi.publish({ id: this.page.id });
    } else {
      xhr = PagesApi.unpublish({ id: this.page.id });
    }

    xhr
      .then(() => {
        this.isSaving = false;
        this.page.toggleProperty('published');
        FunctionalUtils.info(__('Page Settings have been successfully saved.'));
      })
      .catch((response) => {
        if (response && response.status === 403) {
          this.isSaving = false;
          this.published = false;
          dispatcher.dispatch('pages', 'buySubscriptionAlert', this.page);
          return;
        }
      });
  }
}
