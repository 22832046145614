/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { dropTask, restartableTask } from 'ember-concurrency';

import AccountApi from 'mewe/api/account-api';
import ChatApi from 'mewe/api/chat-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import { maxContacts } from 'mewe/constants';
import FollowingApi from 'mewe/api/following-api';

export default class MwSettingsPrivacy extends Component {
  @service account;
  @service analytics;
  @service dynamicDialogs;

  @alias('account.activeUser.settings.privacy') settings;

  @tracked isSavingPublicProfile;
  @tracked isSearchDirectory = this.settings.allowSearchDirectory;
  @tracked isSavingChatRequests;
  @tracked isFollowersLimitReached = false;

  constructor() {
    super(...arguments);

    this.loadCounters();
  }

  @computed('settings')
  get isLoaded() {
    return isDefined(this.settings);
  }

  async loadCounters() {
    const counters = await FollowingApi.counters();
    this.isFollowersLimitReached = (counters?.followers || 0) >= maxContacts;
  }

  get isPublicSwitchDisabled() {
    return this.isFollowersLimitReached && this.settings.public;
  }

  get enableSearchDirectory() {
    return !this.isSearchDirectory && !this.account.public;
  }

  @action
  allowChatRequestsChange(newValue) {
    if (!this.isLoaded) return;

    this.isSavingChatRequests = true;

    const alwaysCb = () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.isSavingChatRequests = false;
      },
      catchCb = () => FunctionalUtils.showDefaultErrorMessage(),
      thenCb = () => {
        dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.privacy.allowChatRequests', newValue);
        FunctionalUtils.info(__('Changes in your account have been saved'));
      };

    if (newValue) {
      ChatApi.allowAllChatRequests().then(thenCb).catch(catchCb).finally(alwaysCb);
    } else {
      ChatApi.denyAllChatRequests().then(thenCb).catch(catchCb).finally(alwaysCb);
    }
  }

  @dropTask
  *toggleSearchDirectory() {
    this.isSearchDirectory = !this.isSearchDirectory;
    const params = {
      active: this.isSearchDirectory,
    };

    try {
      yield AccountApi.setData(params);
      FunctionalUtils.info(__('Changes in your account have been saved'));
    } catch (e) {
      FunctionalUtils.showDefaultErrorMessage();
    }
  }

  @restartableTask
  *toggleFollowListVisiblity() {
    const params = {
      hideFollowers: !this.settings.hideFollowers,
    };

    try {
      yield AccountApi.setData(params);
      FunctionalUtils.info(__('Changes in your account have been saved'));
    } catch (e) {
      FunctionalUtils.showDefaultErrorMessage();
    }
  }

  @action
  async publicProfileChange(event) {
    event.preventDefault();

    if (!this.isLoaded) return;

    const newState = !this.settings.public;
    const args = {
      okButtonText: __(`Make public`),
      title: __(`Are you sure you want to make your profile public?`),
      message: __(
        `By making your profile public, all follow requests will automatically be accepted and your profile content will be visible to anyone on or outside of MeWe.`
      ),
      onConfirm: async () => {
        //if user is private with disabled search directory
        //we want it to be enabled before making profile public
        if (this.enableSearchDirectory) {
          await this.toggleSearchDirectory.perform();
        }

        this.savePublicProfile(newState);
      },
    };

    if (this.settings.public) {
      delete args.cancelButtonText;
      delete args.title;

      args.okButtonText = __(`Make private`);
      (args.noEscaping = true), (args.imagePath = '/assets/images/search_directory.png');
      args.learnMoreLink = 'https://support.mewe.com/hc/en-us/sections/360004377293-FAQ';
      args.message = __(
        `By making your profile private, all of your previous posts shared with Anyone on or outside of MeWe <b>will be visible to My Followers only.</b> Any publicly shared post links will not be visible.`
      );
    }

    this.dynamicDialogs.openDialog('simple-dialog-new', args);
  }

  savePublicProfile(newState) {
    this.isSavingPublicProfile = true;

    const params = {
      public: newState,
    };

    //This will set hideFollowers false when profile became public
    if (newState && this.settings.hideFollowers) {
      params.hideFollowers = false;
    }

    AccountApi.setData(params)
      .then(() => {
        FunctionalUtils.info(__('Changes in your account have been saved'));
        dispatcher.dispatch('client-data', 'saveClientPreference', 'public', params.public);
        dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.privacy.public', params.public);

        if (newState && this.settings.hideFollowers) {
          dispatcher.dispatch('client-data', 'saveClientPreference', 'settings.privacy.hideFollowers', false);
        }

        this.analytics.sendEvent('onboardingDialog', {
          type: 'Account Privacy Changed',
          selected_privacy: newState ? 'public' : 'private',
        });
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.isSavingPublicProfile = false;
      });
  }
}
