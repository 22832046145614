import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';

import EventsApi from 'mewe/api/events-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EventUtils from 'mewe/utils/event-utils';
import dispatcher from 'mewe/dispatcher';
import GroupPermissions from 'mewe/utils/group-permissions-utils';

export default class MwEventSettingsPermissions extends Component {
  @service account;

  @reads('args.eventData') eventData;

  @tracked loaded;
  @tracked isSavingModeration;
  @tracked moderated;

  constructor() {
    super(...arguments);

    if (this.account.activeUser?.id && this.eventData?.participationType) {
      this.eventDataReady();
    }

    addObserver(this, 'eventData.participationType', this, this.eventDataReady);
  }

  willDestroy() {
    removeObserver(this, 'eventData.participationType', this, this.eventDataReady);
    super.willDestroy(...arguments);
  }

  eventDataReady() {
    if (!this.loaded && this.eventData?.participationType) {
      this.moderated = this.eventData.moderated;
      this.loaded = true;
    }
  }

  @computed('eventData.participationType', 'eventData.group')
  get isPostApprovalVisible() {
    return this.eventData.participationType === 'Owner' && !this.eventData.group;
  }

  @computed('loaded', 'eventData.moderated', 'moderated')
  get isModerationSaveVisible() {
    return this.loaded && this.eventData.moderated !== this.moderated;
  }


  @computed('eventData.group')
  get permissions() {
    if (this.eventData?.group) return GroupPermissions.getPermissionsNames(this.eventData?.group?.permissions, true);
    else {
      let perms = ['post', 'comment', 'reshare'];

      if (this.eventData?.canInvite) {
        perms.push('invite');
      }

      return GroupPermissions.getPermissionsNames(perms, true);
    }
  }

  @action
  saveModeration() {
    let params = EventUtils.getEventObjectFromEventData(this.eventData);
    params.moderated = this.moderated;

    this.isSavingModeration = true;

    EventsApi.setEventDetails(this.eventData.id, params)
      .then(() => {
        FunctionalUtils.info(__('Event Settings have been successfully saved.'));
        dispatcher.dispatch('events', 'saveEventState', 'rootEvent.moderated', params.moderated, this.eventData.id);
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.moderated = !params.moderated;
      })
      .finally(() => {
        this.isSavingModeration = false;
      });
  }
}
