import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';

export default class Storyblok extends Route {
  @service('storyblok') storyblok;

  model() {
    return this.storyblok.loadStory(`cms/move-to-web3`);
  }
}
