import { A } from '@ember/array';
import { isEmpty, each } from 'lodash';
import MarkdownIt from 'markdown-it';
import { tokenize as originalEmphasis } from 'markdown-it/lib/rules_inline/emphasis.js';
import originalLinkify from 'markdown-it/lib/rules_core/linkify.js';
import { gifUrlRegExp } from 'mewe/utils/gif-utils';
import { hasOnlyWhitespaces, hasAnyLetterOrDigit } from 'mewe/utils/text-editor-utils';
import { regex, getEmojisWithIndices } from 'mewe/utils/emoji-utils';
import TurndownService from 'turndown';
import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[markdown-parser]', color: 'navy', enabled: false });
const log = verbose.log;

// turn off default escaping
TurndownService.prototype.escape = (s) => s;

const turndownService = new TurndownService({
  emDelimiter: '*',
  strongDelimiter: '**',
  codeBlockStyle: 'fenced',
  fence: '```',
});

turndownService.addRule('paragraph', {
  filter: 'div',
  replacement: function (content) {
    return content + '\n';
  },
});

turndownService.addRule('strike', {
  filter: 's',
  replacement: function (content) {
    return '~~' + content + '~~';
  },
});

turndownService.addRule('br', {
  filter: 'br',
  replacement: function () {
    return '\n';
  },
});

turndownService.addRule('keep', {
  filter: ['img'],
  replacement: function (content, node) {
    return node.outerHTML;
  },
});

let md = new MarkdownIt({
  html: false,
  linkify: true,
  typographer: false, //SG-17077
  breaks: true, // TODO: match html-parser break parsing to this or vice versa
}).disable(['image']); // mobile apps don't support inline images, http-images can cause whole site to be blocked

/*

 http://markdown-it.github.io
 https://github.com/markdown-it/linkify-it
 http://markdown-it.github.io/linkify-it/

 I even did the thing to use twitter-text to produce A()() markdown links before actual markdown in chain of parsers
 it works nicely, but twitter-text just doesn't mark urls we would need
 e.g. this one is rather super important: mewe.com#video
 it marks only mewe.com part
 with plugged twitter-text I have already information that _ was found in link and it was super easy
 I think we cannot solve this, it would require our custom own parser, low-level one and we just cannot afford something like that now, it would require small team to work on it for weeks
 and now I just cannot combine existing libraries into solution I would be satisfy with
 I am just suprised from twitter-text, I was thinking it is super magnificent library, but maybe only for twitter usecases it cannot detect even port in url
 it cannot detect even port in url
 */

// for edge cases like host=www.foo.com
// the optional http:// in front is for cases where markdown-it autoprepends http:// to the start (e.g. host=www.foo.com -> http://host=www.foo.com)
const matchWordAttachedToUrl = (url) => url.match(/^((?:http:\/\/)?[a-zA-Z0-9]+[=;\?$,])/);

function customizeMarkdownIt(md) {
  // https://sgrouples.atlassian.net/browse/SG-29507
  md.linkify.set({ fuzzyEmail: false });

  const defaultValidateLink = md.validateLink;

  md.validateLink = (url) => {
    // url contains mention
    if (url.indexOf('%7B%7B') !== -1) return false;

    if (defaultValidateLink(url)) {
      let val = url.trim().toLowerCase();

      // if there's something that is not a domain as first thing before ;, fail
      const spl = val.split(';');
      if (spl.length) {
        if (defaultValidateLink(spl[0]) && md.linkify.re.schema_test.test(spl[0])) {
          return isEmpty(val.match(gifUrlRegExp));
        } else {
          return false;
        }
      } else {
        return isEmpty(val.match(gifUrlRegExp));
      }
    } else return false;
  };

  const defaultNormalizeLink = md.normalizeLink;

  md.normalizeLink = (url) => {
    let wordAttachedToUrl = matchWordAttachedToUrl(url);

    if (wordAttachedToUrl && wordAttachedToUrl.length) {
      let startOfUrl = wordAttachedToUrl[1],
        restOfUrl = url.replace(startOfUrl, '');
      startOfUrl = startOfUrl.replace('http://', '');

      if (!md.linkify.re.schema_test.test(restOfUrl) && restOfUrl.indexOf('javascript:') !== 0) {
        restOfUrl = 'http://' + restOfUrl;
      }

      return defaultNormalizeLink(startOfUrl) + defaultNormalizeLink(restOfUrl);
    }

    if (!md.linkify.re.schema_test.test(url) && url.indexOf('javascript:') !== 0) {
      url = 'http://' + url;
    }

    return defaultNormalizeLink(url);
  };

  const defaultNormalizeLinkText = md.normalizeLinkText;

  md.normalizeLinkText = (url) => {
    let wordAttachedToUrl = matchWordAttachedToUrl(url);

    if (wordAttachedToUrl && wordAttachedToUrl.length) {
      return defaultNormalizeLinkText(url.replace(wordAttachedToUrl[1], '')); //e.g. host= is moved outside of link
    }

    return defaultNormalizeLinkText(url);
  };

  const defaultLinkRender =
    md.renderer.rules.link_open || ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options));

  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    let token = tokens[idx];

    if (options && options.disableHrefs) {
      token.attrSet('disabled', 'true');
      token.attrSet('href', '#');

      return defaultLinkRender(tokens, idx, options, env, self);
    }

    token.attrSet('target', '_blank');
    token.attrSet('rel', 'nofollow noopener noreferrer');
    token.attrSet('class', 'color-app');

    let href = token.attrGet('href');

    if (href) {
      let wordAttachedToUrl = matchWordAttachedToUrl(href);

      if (wordAttachedToUrl && wordAttachedToUrl.length) {
        let startOfUrl = wordAttachedToUrl[1],
          restOfUrl = href.replace(startOfUrl, '');

        token.attrSet('href', restOfUrl);

        return startOfUrl + defaultLinkRender(tokens, idx, options, env, self);
      }
    }

    return defaultLinkRender(tokens, idx, options, env, self);
  };

  md.renderer.rules.paragraph_open = (tokens, idx) => {
    let token = tokens[idx];

    if (token.hidden) {
      return '';
    }

    return `<p dir="auto">`;
  };

  function meweHeading(state, startLine, endLine, silent) {
    //require('./rules_block/heading');

    /*
     * added by Kajo - modified because of
     * although it is perfectly fine according commonmark spec - https://spec.commonmark.org/0.25/#example-48
     * but we don't want to have empty posts/ comments/ chats
     * SG-15981
     */
    if (/^(#)+$/.test(state.src.trim())) {
      return false;
    }

    var ch,
      level,
      tmp,
      token,
      pos = state.bMarks[startLine] + state.tShift[startLine],
      max = state.eMarks[startLine];

    // if it's indented more than 3 spaces, it should be a code block
    if (state.sCount[startLine] - state.blkIndent >= 4) {
      return false;
    }

    ch = state.src.charCodeAt(pos);

    if (ch !== 0x23 /* # */ || pos >= max) {
      return false;
    }

    // count heading level
    level = 1;
    ch = state.src.charCodeAt(++pos);
    while (ch === 0x23 /* # */ && pos < max && level <= 6) {
      level++;
      ch = state.src.charCodeAt(++pos);
    }

    if (level > 6 || (pos < max && !md.utils.isSpace(ch))) {
      return false;
    }

    if (silent) {
      return true;
    }

    // Let's cut tails like '    ###  ' from the end of string

    max = state.skipSpacesBack(max, pos);
    tmp = state.skipCharsBack(max, 0x23, pos); // #
    if (tmp > pos && md.utils.isSpace(state.src.charCodeAt(tmp - 1))) {
      max = tmp;
    }

    state.line = startLine + 1;

    token = state.push('heading_open', 'h' + String(level), 1);
    token.markup = '########'.slice(0, level);
    token.map = [startLine, state.line];

    token = state.push('inline', '', 0);
    token.content = state.src.slice(pos, max).trim();
    token.map = [startLine, state.line];
    token.children = A();

    token = state.push('heading_close', 'h' + String(level), -1);
    token.markup = '########'.slice(0, level);

    return true;
  }

  // don't add <em></em> around /_test_ so double _ work inside links
  // we cannot detect it is link, because linkify runs after block rules, so we know it is a link after we know it is emphasis
  function emphasis(state, silent) {
    if (silent) return false;

    let marker = state.src.charCodeAt(state.pos);

    if (marker === 0x5f /* _ */) {
      let prevPos = state.pos - 1;

      if (prevPos !== -1) {
        const prev = state.src.charCodeAt(prevPos);

        // problem described in https://github.com/markdown-it/markdown-it/issues/38
        if (prev === 47 /* / */ || prev === 45 /* - */) return false;
        else return originalEmphasis(state, silent);
      } else return originalEmphasis(state, silent);
    } else return originalEmphasis(state, silent);
  }

  md.block.ruler.at('heading', meweHeading, { alt: ['paragraph', 'reference', 'blockquote'] });
  md.inline.ruler.at('emphasis', emphasis);

  // empty / only space containing links turned into []{}
  function linkify(state) {
    if (!state.md.options.linkify) return;

    let token, textToken, i;

    each(state.tokens, (b) => {
      if (b.type === 'inline') {
        for (i = 0; i < b.children.length; ++i) {
          token = b.children[i];

          if (token.type === 'link_open') {
            if (i < b.children.length - 1 && b.children[i + 1] && b.children[i + 1].type === 'link_close') {
              textToken = new state.Token('text', '', 0);
              textToken.content = '[]{}';
              b.children = b.children
                .slice(0, i + 1)
                .concat(textToken)
                .concat(b.children.slice(i + 1));
              i++; // new child token added, skip over it
            } else if (
              i < b.children.length - 2 &&
              b.children[i + 1] &&
              b.children[i + 1].type === 'text' &&
              b.children[i + 2] &&
              b.children[i + 2].type === 'link_close'
            ) {
              const text = (b.children[i + 1].content || '').trim();

              if (isEmpty(text)) {
                b.children[i + 1].content = '[]{}';
              } else {
                // checking if text contains emoji, we don't hidden link under clickable emoji (SG-28737)
                const emojisInText = getEmojisWithIndices(text);

                if (emojisInText && emojisInText.length) {
                  const textWithoutEmojis = text.replace(new RegExp(regex, 'g'), '');

                  // no other text than emoji, use emoji shortname text as link text
                  if (!hasAnyLetterOrDigit(textWithoutEmojis)) {
                    const emojiShortName = emojisInText[0].emoji.shortname.replace(new RegExp(/:/, 'g'), '');
                    b.children[i + 1].content = emojiShortName;
                  }
                  // there is some text except the emoji, use that text without emoji
                  else {
                    b.children[i + 1].content = textWithoutEmojis;
                  }
                }
              }
            }
          }
        }
      }
    });

    return originalLinkify(state);
  }

  md.core.ruler.at('linkify', linkify);
}

customizeMarkdownIt(md);

const guard = (text) => (text ? text : '');
const unescapeBlockQuotes = (t) => t.replace(/^(?:&gt;)(?:[ ]{0,1}(?:&gt;)+)*/gm, (m) => m.replace(/&gt;/g, '>'));
const escapeSlashes = (t) => t.replace(/\\/gm, '&#92;');
const unescapeMarkdown = (text) => escapeSlashes(unescapeBlockQuotes(text.replace(/\u200B/g, '')));

// list of rules that you can enable/disable
// node_modules/markdown-it/lib/parser_inline.js
// node_modules/markdown-it/lib/parser_block.js
// node_modules/markdown-it/lib/parser_core.js
let mdToEdit = new MarkdownIt().disable([
  'list',
  'link',
  'backticks',
  'heading',
  'lheading',
  'hr',
  'code',
  'fence',
  'blockquote',
  'image',
]);

const mdDisabledHrefs = new MarkdownIt({
  html: false,
  linkify: true,
  disableHrefs: true, // links will be rendered but with disabled=true and href='#'
  typographer: false, //SG-17077
  breaks: true, // TODO: match html-parser break parsing to this or vice versa
}).disable(['image']); // mobile apps don't support inline images, http-images can cause whole site to be blocked

customizeMarkdownIt(mdDisabledHrefs);

const parseConcealedMd = (text) => {
  const markdownStylingRegex = /.*[~\*]{2,3}(.+)[~\*]{2,3}.*/g;

  /**
   * * markdownStylingRegex matches any text that contains 2 or 3 "~" or "*" signs
   * at 2 different spots in the text and necessarily something in between
   * i.e. "hello~~#hello hello~~", "hello **there #awesome**", "MeWe is ***#awesome***"
   */

  // order of replacement is important, e.g. "**" before "*"
  // to avoid replacing first "*" and leaveing second "*" not replaced at all
  return text.replace(markdownStylingRegex, (textToAlter, group) => {
    return textToAlter
      .replace('~~' + group + '~~', '<s>' + group + '</s>')
      .replace('***' + group + '***', '<b><i>' + group + '</i></b>')
      .replace('**' + group + '**', '<b>' + group + '</b>')
      .replace('*' + group + '*', '<e>' + group + '</e>');
  });
};

export default {
  toDisplay: (text, options) => {
    let r;

    if (options && options.disableHrefs) {
      r = mdDisabledHrefs.render(unescapeMarkdown(guard(text)));
    } else {
      r = md.render(unescapeMarkdown(guard(text)));
    }

    const onlyWhitespaces = text ? hasOnlyWhitespaces(text) : null;

    if (!r && text && !onlyWhitespaces) return '<p dir="auto">[]{}</p>';

    // hack for MarkdownIt issue
    // https://github.com/markdown-it/markdown-it/issues/680
    // https://sgrouples.atlassian.net/browse/SG-28594
    r = r.replace(/.*\*\<s\>(.+)\<\/s\>\*.*/g, function (text, group) {
      return text.replace('*<s>' + group + '</s>*', '<em><s>' + group + '</s></em>');
    });

    // SG-30392 - Make sure to parse markdown in the text when it is not separated by space
    r = parseConcealedMd(r);

    //log(`markdown-parser:toDisplay('${text}') => '${r}'`);

    return r;
  },

  toEdit: (text) => mdToEdit.renderInline(unescapeMarkdown(guard(text))),

  toServer: (text) => {
    let out = turndownService.turndown(text);

    // temporary hack for https://sgrouples.atlassian.net/browse/SG-28571
    // should be removed when we will enable lists in quill editor
    out = out.replace(/~~- /g, '- ~~');
    out = out.replace(/\*\*- /g, '- **');
    out = out.replace(/\*- /g, '- *');

    //log(`markdown-parser:toServer('${text}') => '${out.replace(/\n/g, '↵')}'`);

    return out;
  },
};
