import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';

import { isUndefined } from 'mewe/utils/miscellaneous-utils';
import dispatcher from 'mewe/dispatcher';

export default class SidebarLeftPage extends Component {
  @reads('args.isOpenPage') isOpenPage;

  @computed('args.model.page.id')
  get isAllPagesView() {
    return isUndefined(this.args.model?.page?.id);
  }

  @computed('args.model.page.{id,isOwnerAdmin}')
  get showReportButton() {
    return this.args.model?.page?.id && !this.args.model?.page?.isOwnerAdmin;
  }

  @action
  create() {
    dispatcher.dispatch('pages', 'create');
  }

  @action
  openReportPageDialog() {
    dispatcher.dispatch('pages', 'reportPage', { page: this.args.model?.page });
  }
}
