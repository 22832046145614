import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import utils from 'mewe/utils/environment-utils';
import { imgFormats } from 'mewe/constants';

export function imgHost(value, options) {
  let imgLink = value.join('') || '';

  // image blob, no host for that. We do show blob until image is processed in case when setting background photos
  if (imgLink.indexOf('data:image') !== -1) return imgLink;

  if (options.defaultExt) {
    let fileExt = imgLink.split('.').pop();

    if (fileExt) {
      const paramIndex = fileExt.indexOf('?');
      if (paramIndex !== -1) fileExt = fileExt.slice(0, paramIndex);
    }

    const hasImgExt = fileExt && imgFormats.indexOf(fileExt) !== -1;

    if (!hasImgExt) {
      const pos = imgLink.indexOf('?');

      if (pos > 0) {
        imgLink = imgLink.substr(0, pos) + '.' + options.defaultExt + imgLink.substr(pos);
      } else {
        imgLink += '.' + options.defaultExt;
      }
    }
  }

  let host =
    imgLink.indexOf('/app/') === 0 || imgLink.indexOf('https') === 0
      ? ''
      : utils.getImgHost(options.noApiPrefix, options.isPublicContent);

  if (imgLink.indexOf(host) > -1) return htmlSafe(imgLink);

  return htmlSafe(host + imgLink).toString();
}

export default buildHelper(imgHost);
