import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import EmberObject, { computed, observer } from '@ember/object';
import { A } from '@ember/array';
import Component from '@ember/component';

import { next } from '@ember/runloop';

import EmojiUtils from 'mewe/utils/emoji-utils';
import StorageUtils from 'mewe/shared/storage';
import Scrolling from 'mewe/utils/scrolling-utils';
import PurchasesStore from 'mewe/stores/purchases-store';
import PS from 'mewe/utils/pubsub';
import { reposition } from 'mewe/utils/popup-utils';
import { chunk } from 'lodash';
import Verbose from 'mewe/utils/verbose';
import layout from './template.hbs';
import './styles.scss';

const verbose = Verbose({ prefix: '[Emoji Picker]', color: 'darkorange', enabled: false }).log;

const ACTIVE = ['active', 'color-app'];
const HIDDEN = 'hidden';
const RECENT = 'including-recent';
const KEY_ESC = 27,
  KEY_TAB = 9;
const ROW_HEIGHT = 46;
const ROWS_ON_PAGE = 6;
const EMOJI_IN_ROW = 9;
const POSITION_OFFSET = 150;

const DEFAULT_ICONS = (tone = '') => {
  return A([
    EmojiUtils.emoji(`:slight_smile:`),
    EmojiUtils.emoji(`:joy:`),
    EmojiUtils.emoji(`:star_struck:`),
    EmojiUtils.emoji(`:thumbsdown${tone}:`),
    EmojiUtils.emoji(`:thumbsup${tone}:`),
    EmojiUtils.emoji(`:heart:`),
  ]);
};

const CATEGORIES = [
  {
    title: __('Recently Used'),
    name: 'recent',
    class: 'svg_emoji-category_recent',
  },
  {
    title: __('Purchased'),
    name: 'purchased',
    class: 'svg_emoji-category_purchased',
  },
  {
    title: __('Smileys & People'),
    name: 'people',
    class: 'svg_emoji-category_people',
  },
  {
    title: __('Animals & Nature'),
    name: 'nature',
    class: 'svg_emoji-category_nature',
  },
  {
    title: __('Food & Drink'),
    name: 'food',
    class: 'svg_emoji-category_food',
  },
  {
    title: __('Activity'),
    name: 'activity',
    class: 'svg_emoji-category_activity',
  },
  {
    title: __('Travel & Places'),
    name: 'travel',
    class: 'svg_emoji-category_travel',
  },
  {
    title: __('Objects'),
    name: 'objects',
    class: 'svg_emoji-category_objects',
  },
  {
    title: __('Symbols'),
    name: 'symbols',
    class: 'svg_emoji-category_symbols',
  },
  {
    title: __('Custom'),
    name: 'custom',
    class: 'svg_emoji-category_custom',
  },
  {
    title: __('Flags'),
    name: 'flags',
    class: 'svg_emoji-category_flags',
  },
];

let buildThresholdList = () => {
  let thresholds = [];
  let numSteps = 10;

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

let intersectCallback = (rows) => (entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      let index = parseInt(entry.target.id.replace('erow-', ''));

      for (let i = -ROWS_ON_PAGE; i < ROWS_ON_PAGE * 2; i++)
        if (index + i >= 0 && rows[index + i]) rows[index + i].set('visible', true);
    }
  });
};

let buildRows = (categories, emojiByCategory) => {
  let rows = A();

  let categoryHeaderRows = {};

  let innerHeight = 0;

  verbose('categories');
  verbose(categories);
  verbose('emojiByCategory');
  verbose(emojiByCategory);

  categories
    .map((c) => c.name)
    .forEach((category, ci) => {
      verbose(category);

      if (!emojiByCategory[category]) return;

      let emojiFiltered = emojiByCategory[category].filter((el) => el && !el.duplicate);

      const chunks = chunk(emojiFiltered, EMOJI_IN_ROW);

      if (chunks.length) {
        rows.push(
          EmberObject.create({
            visible: ci == 0 ? true : false,
            title: category,
          })
        );

        categoryHeaderRows[category] = rows.length;

        innerHeight += ROW_HEIGHT;

        chunks.forEach((chunk, ri) => {
          rows.push(
            EmberObject.create({
              visible: ci == 0 && ri < ROWS_ON_PAGE ? true : false,
              emojis: chunk,
              category: category,
              noTone: category === 'recent', // in recent emojis we need to show original tone, not current set SG-22664
            })
          );

          innerHeight += ROW_HEIGHT;
        });
      }
    });

  return { rows, categoryHeaderRows, innerHeight };
};

let setIntersectionObservers = (rows) => {
  let list = buildThresholdList();

  let options = {
    root: document.querySelector('#inner-emojis'),
    threshold: list,
  };

  let observer = new IntersectionObserver(intersectCallback(rows), options);

  next(() =>
    rows.forEach((row, i) => {
      if (i % 6 == 0) {
        const el = document.getElementById(`erow-${i}`);
        if (el) observer.observe(el);
      }
    })
  );
};

const MwEmojiPickerComponent = Component.extend({
  layout: layout,

  classNames: ['c-mw-emoji-picker', 'emoji-popup', 'flex-column'],
  classNameBindings: ['open::invisible'],
  attributeBindings: ['ariaHidden:aria-hidden'],

  recent: A(),
  icons: {},
  defaultIcons: {},

  open: false,

  term: '',

  searchTimeout: null,
  searchResults: null,

  routing: service('-routing'),

  pickers: service(),

  purchase: service(),

  ariaHidden: computed('open', function () {
    return (!this.open).toString();
  }),

  scrolling: Scrolling(),

  init: function () {
    this._super(...arguments);

    this.categories = CATEGORIES;

    if (EmojiUtils.isHashMapReady()) {
      this.setIcons();

      PS.Sub('emoji.hashmap.change', () => {
        this.rerender();
      });
    } else {
      PS.Sub('emoji.hashmap.ready', () => {
        this.setIcons();

        PS.Sub('emoji.hashmap.change', () => {
          this.rerender();
        });
      });
    }

    this.closeOnOutsideBind = this.closeOnOutside.bind(this);
    this.closeOnEscBind = this.closeOnEsc.bind(this);

    this.append();

    this.pickers.register({
      name: 'emoji',
      close: () => {
        this.send('close');
      },
    });
  },

  setIcons() {
    const emojiByCategory = EmojiUtils.emojiByCategory();

    const recent = this.loadRecentIcons();

    if (recent) emojiByCategory.recent = recent;

    const skin = this.selectedSkinTone,
      thumbTone = skin ? `_tone${skin}` : '';

    const { rows, innerHeight, categoryHeaderRows } = buildRows(this.categories, emojiByCategory);

    this.setProperties({
      innerHeight: htmlSafe(`height: ${innerHeight}px`),
      allRows: rows,
      categoryHeaderRows: categoryHeaderRows,
      icons: emojiByCategory,
      recent: recent,
      defaultIcons: DEFAULT_ICONS(thumbTone),
    });

    if (emojiByCategory.purchased.length) this.set('purchased', true);

    setIntersectionObservers(rows);
  },

  // call only when necessary because it reloads all icons
  // needed e.g. when skin tone was changed
  rerender() {
    this.setProperties({
      icons: {},
      defaultIcons: A(),
      searchResults: A(),
    });

    // reload search results after icons were reloaded
    this.changeTerm();

    next(() => this.setIcons());
  },

  didInsertElement: function () {
    this.set('open', false);

    this.changeTabOnScrollBind = this.changeTabOnScroll.bind(this);

    this.element.querySelector('.inner-emojis').addEventListener('scroll', this.changeTabOnScrollBind);

    this._super();
  },

  addClassGroupColor: function () {
    this.classList.add('color-app');
  },

  removeClassGroupColor: function () {
    this.classList.remove('color-app');
  },

  closeOnEsc: function (e) {
    if (e.keyCode === KEY_ESC || e.keyCode === KEY_TAB) {
      this.send('close');
    }
  },

  willDestroyElement: function () {
    document.removeEventListener('keydown', this.closeOnEscBind);
    document.removeEventListener('mousedown', this.closeOnOutsideBind);

    this.element.querySelector('.inner-emojis').removeEventListener('scroll', this.changeTabOnScrollBind);

    const emojiTabsLiEls = this.element.querySelectorAll('.emoji-tabs li');

    emojiTabsLiEls.forEach((el) => {
      el.removeEventListener('mouseover', this.addClassGroupColor);
      el.removeEventListener('mouseleave', this.removeClassGroupColor);
    });
  },

  isSearching: computed('term.length', function () {
    return this.term && this.get('term.length') > 1;
  }),

  isSearchingLoading: computed('term.length', 'searchResults', function () {
    return this.term && this.get('term.length') > 1 && !this.get('searchResults');
  }),

  changeTabOnScroll: function () {
    const emojisListEl = this.element.querySelector('.inner-emojis');

    if (this.term.length) {
      this.element.querySelector('.emoji-tabs .active').classList.remove(ACTIVE);
      return;
    }

    const position = {
      people: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.people}`).offsetTop - POSITION_OFFSET,
      nature: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.nature}`).offsetTop - POSITION_OFFSET,
      food: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.food}`).offsetTop - POSITION_OFFSET,
      activity: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.activity}`).offsetTop - POSITION_OFFSET,
      travel: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.travel}`).offsetTop - POSITION_OFFSET,
      objects: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.objects}`).offsetTop - POSITION_OFFSET,
      symbols: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.symbols}`).offsetTop - POSITION_OFFSET,
      flags: emojisListEl.querySelector(`#erow-${this.categoryHeaderRows.flags}`).offsetTop - POSITION_OFFSET,
    };

    // purchased/custom/recent category doesn't have to be visible/present
    const purchasedListEl = emojisListEl.querySelector(`h1[name='purchased']`),
      customListEl = emojisListEl.querySelector(`h1[name='custom']`),
      recentListEl = emojisListEl.querySelector(`h1[name='recent']`);

    if (recentListEl) {
      position.recent = recentListEl.offsetTop - POSITION_OFFSET;
    }

    if (purchasedListEl) {
      position.purchased = purchasedListEl.offsetTop - POSITION_OFFSET;
    } else if (customListEl) {
      position.custom = customListEl.offsetTop - POSITION_OFFSET;
    }

    const pos = emojisListEl.scrollTop;

    if (pos < position.people) {
      if (!position.purchased && position.recent) {
        this.highlightTab('recent');
      } else if (position.purchased && pos < position.purchased && position.recent) {
        this.highlightTab('recent');
      } else {
        this.highlightTab('purchased');
      }
    } else if (pos > position.people && pos < position.nature) {
      this.highlightTab('people');
    } else if (pos > position.nature && pos < position.food) {
      this.highlightTab('nature');
    } else if (pos > position.food && pos < position.activity) {
      this.highlightTab('food');
    } else if (pos > position.activity && pos < position.travel) {
      this.highlightTab('activity');
    } else if (pos > position.travel && pos < position.objects) {
      this.highlightTab('travel');
    } else if (pos > position.objects && pos < position.symbols) {
      this.highlightTab('objects');
    } else if (pos > position.symbols && pos < position.custom) {
      this.highlightTab('symbols');
    } else if (pos > position.custom && pos < position.flags) {
      this.highlightTab('custom');
    } else if (pos > position.flags) {
      this.highlightTab('flags');
    }
  },

  highlightTab: function (className) {
    const emojiTabsEl = this.element.querySelector('.emoji-tabs'),
      tabEl = emojiTabsEl.querySelector(`.${className}`),
      activeEl = emojiTabsEl.querySelector('.active');

    if (activeEl) activeEl.classList.remove(...ACTIVE);
    if (tabEl) tabEl.classList.add(...ACTIVE);
  },

  loadRecentIcons: function () {
    let emojiStorage = StorageUtils.get(StorageUtils.keys.latestEmojiKey),
      emojisFromStorage = A();

    if (emojiStorage !== null && emojiStorage !== undefined) {
      emojisFromStorage = emojiStorage.split(',');

      let emojis = A();

      if (emojisFromStorage && emojisFromStorage.length) {
        emojisFromStorage.forEach((el) => {
          emojis.push(EmojiUtils.emoji(el));
        });
      }

      return emojis;
    } else {
      return A();
    }
  },

  addEmojiToStorage: function (emojiShortname, emojiStorage) {
    if (!emojiStorage) return;

    let emojisFromStorage = emojiStorage.split(','),
      emojisToLS = '';

    if (emojisFromStorage.indexOf(emojiShortname) > -1) {
      emojisFromStorage.splice(emojisFromStorage.indexOf(emojiShortname), 1);
    }

    emojisFromStorage.unshift(emojiShortname);

    if (emojisFromStorage.length > this.recentLimit) {
      emojisFromStorage.splice(this.recentLimit + 1, emojisFromStorage.length - this.recentLimit);
    }
    emojisToLS = emojisFromStorage.join(',');
    StorageUtils.set(StorageUtils.keys.latestEmojiKey, emojisToLS);
  },

  isOpen: function () {
    return this.open;
  },

  changeTerm: observer('term', function () {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.set('searchTimeout', null);
    }

    let term = this.term;
    if (term === '' || term.length === 1) {
      this.set('searchResults', A());
    } else {
      const searchEmoji = () => {
        this.set('searchResults', EmojiUtils.emojiByTerm(term));
      };
      this.set('searchTimeout', setTimeout(searchEmoji, 500));
    }
  }),

  calculatedClass: computed('recent', 'purchased', function () {
    let classes = 'emoji-tabs';

    if (this.get('recent.length')) classes += ' including-recent';

    if (this.get('purchased')) {
      classes += ' including-purchased';
    }

    return htmlSafe(classes);
  }),

  getDialogWrapper() {
    return document.querySelector('.dialog_wrapper');
  },

  closeOnOutside(e) {
    if (!this.open) return;

    if (this.element !== e.target && this.element.contains(e.target)) return;

    if (!this.button || e.target != this.button) {
      this.send('close');
    }
  },

  actions: {
    reposition(element, forceSidePosition) {
      const { width, height } = this.element.getBoundingClientRect();
      const placement = reposition(element, { width, height }, 45, forceSidePosition);

      // +100px => in side positioning (picker on a side of opener el) need to compensate picker's margin-left: -100px
      // not applicable for RTL, we don't have margin in that case right now
      if (forceSidePosition && !document.dir) placement.left += 100;

      this.set('spaceTop', placement.top);
      this.set('spaceLeft', placement.left);
    },

    changeTab: function (name) {
      if (!EmojiUtils.isHashMapReady()) return;

      let callback = () => {
        if (this.isDestroyed || this.isDestroying) return;

        let emojisListEl = this.element.querySelector('.inner-emojis'),
          position = emojisListEl.scrollTop;

        this.highlightTab(name);

        const firstEmojiEl = emojisListEl.querySelector(`#erow-${this.categoryHeaderRows[name]}`);

        if (firstEmojiEl) {
          let firstEmojiPosTop = firstEmojiEl.offsetTop;
          let experimentalOffset = 0;

          if (firstEmojiPosTop) {
            position = firstEmojiPosTop - POSITION_OFFSET + experimentalOffset;
          }
        }

        emojisListEl.scrollTop = position;
      };

      if (this.term) {
        this.set('term', '');
        this.setIcons();
        setTimeout(callback);
      } else {
        callback();
      }
    },

    setCallback: function (callback) {
      this.set('callback', callback);
    },

    setAfterOpen: function (callback) {
      this.set('afterOpen', callback);
    },

    setAfterClose: function (callback) {
      this.set('afterClose', callback);
    },

    addEmoji: function (emoji) {
      if (!EmojiUtils.isHashMapReady()) return;

      let tone = EmojiUtils.get('selectedSkinTone');

      if (
        tone > 0 &&
        emoji.shortname.indexOf('_tone') == -1 &&
        (emoji.diversities || (emoji.keywords || []).includes('diversity'))
      ) {
        emoji = EmojiUtils.skins()[emoji.shortname][tone - 1];
      }

      this.callback(emoji);

      // adding to localstorage part
      let emojiStorage = StorageUtils.get(StorageUtils.keys.latestEmojiKey);
      if (emojiStorage === null) {
        // create localstorage item and add emoji to it
        StorageUtils.set(StorageUtils.keys.latestEmojiKey, emoji.shortname);
      } else {
        // add emoji as first and remove redundant ones
        this.addEmojiToStorage(emoji.shortname, emojiStorage);
      }

      this.send('close');
    },

    selectSkin: function (number) {
      if (!EmojiUtils.isHashMapReady()) return;

      if (this.get('selectedSkinTone') !== number) {
        EmojiUtils.set('selectedSkinTone', number);

        if (number > 0) {
          const thumbTone = `_tone${number}`;

          this.setProperties({
            term: 'diversity',
            selectedSkinTone: number,
            defaultIcons: DEFAULT_ICONS(thumbTone),
          });

          this.set('searchResults', EmojiUtils.emojiByTerm(this.term));
        } else {
          this.setProperties({
            selectedSkinTone: number,
            defaultIcons: DEFAULT_ICONS(),
          });

          if (this.term == 'diversity') this.set('term', '');
        }
      }
    },

    open: function (params) {
      this.purchase.getPurchased().then((response) => {
        if (this.isDestroying || this.isDestroyed) return;
        this.set('hasPremium', PurchasesStore.getState().hasPremium);
      });

      this.pickers.open({ name: 'emoji' });

      this.set('open', true);
      this.set('selectedSkinTone', EmojiUtils.get('selectedSkinTone'));

      if (this.getDialogWrapper()) {
        this.scrolling.bindScrollTreshold(
          this.getDialogWrapper(),
          () => {
            this.send('close');
          },
          10,
          'sticker-picker-close'
        );
      }

      this.element.style.position = 'absolute';
      this.element.style.bottom = 'auto';
      this.element.style.top = `${this.spaceTop}px`;
      this.element.style.left = `${this.spaceLeft}px`;

      const emojiStorage = StorageUtils.get(StorageUtils.keys.latestEmojiKey);

      if (emojiStorage === null && !this.element.querySelector('.emoji-tabs .active')) {
        if (this.purchased) {
          this.element.querySelector('.emoji-tabs .purchased').classList.add(...ACTIVE);
        } else {
          this.element.querySelector('.emoji-tabs .people').classList.add(...ACTIVE);
        }
      } else if (this.recent && this.recent.length && !this.element.querySelector('.emoji-tabs .active')) {
        const recentEl = this.element.querySelector('.emoji-tabs .recent');
        recentEl.classList.add(...ACTIVE);
        recentEl.classList.remove(HIDDEN);
        this.element.querySelector('.emoji-tabs').classList.add(RECENT);
      }

      document.addEventListener('mousedown', this.closeOnOutsideBind);
      document.addEventListener('keydown', this.closeOnEscBind);

      /**
       * use groups color class here instead of color-app
       * to not remove this class from active tab on mouse leave
       */
      const emojiTabsLiEls = this.element.querySelectorAll('.emoji-tabs li');

      emojiTabsLiEls.forEach((el) => {
        el.addEventListener('mouseover', this.addClassGroupColor);
        el.addEventListener('mouseleave', this.removeClassGroupColor);
      });

      this.set('beforeStoreRedirection', params && params.beforeStoreRedirection);
    },

    clearSearch: function () {
      this.set('term', '');
    },

    close: function () {
      this.set('open', false);
      this.element.classList.add('invisible');

      // reseting those properties on close to avoid problems when reopening (SG-28676)
      this.element.style.position = 'absolute';
      this.element.style.bottom = 'auto';
      this.element.style.top = 'auto';
      this.element.style.left = 'auto';

      this.set('term', '');
      this.setIcons();

      next(() => {
        this.changeTabOnScroll();
      });

      document.removeEventListener('mousedown', this.closeOnOutsideBind);
      document.removeEventListener('keydown', this.closeOnEscBind);

      if (this.afterClose) this.afterClose();

      this.scrolling.unbindScrollDown(this.getDialogWrapper());
    },

    goToStore() {
      this.send('close');

      const doRedirect = () => {
        this.get('routing').transitionTo('app.store.emojis', []);
      };

      if (typeof this.beforeStoreRedirection === 'function') {
        this.beforeStoreRedirection(doRedirect);
      } else {
        doRedirect();
      }
    },
  },
});

let instance = null;

export default function (owner) {
  if (instance) {
    return instance;
  } else {
    if (!owner) return null;

    owner.register('component:-mw-emoji-picker', MwEmojiPickerComponent);

    let ComponentFactory = owner.factoryFor('component:-mw-emoji-picker');

    instance = ComponentFactory.create();

    return instance;
  }
}
