import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import FunctionalUtils from 'mewe/shared/functional-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwSidebarLeftChatSettings extends Component {
  @service account;

  @tracked saving = false;
  @tracked chatSettingsDropdownOpened = false;

  @tracked allowChatMsgSound;
  @tracked autoOpenMiniChat;

  constructor() {
    super(...arguments);

    this.account.activeUser?.deferred.promise.then(() => {
      this.allowChatMsgSound = this.account.activeUser?.allowChatMsgSound;
      this.autoOpenMiniChat = this.account.activeUser?.autoOpenMiniChat;
    });
  }

  @action
  markAllAsRead() {
    dispatcher.dispatch('chat', 'markAllAsRead');
    this.toggleSidebarLeftChatSettings(false);
  }

  @action
  toggleSidebarLeftChatSettings(value) {
    this.chatSettingsDropdownOpened = value;
  }

  @action
  toggleAndSaveSetting(settingName) {
    if (this.saving) return;

    let params = {},
      rollbackParams = {};

    rollbackParams[settingName] = this[settingName];
    params[settingName] = !this[settingName];

    this.saving = true;

    const done = () => FunctionalUtils.info(__('Changes in your account have been saved'));
    const getRollbackParamsOnFail = () => {
      FunctionalUtils.showDefaultErrorMessage();
      this[settingName] = rollbackParams[settingName];
      return rollbackParams;
    };
    const always = () => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.saving = false;
      }
    };

    dispatcher.dispatch('client-data', 'setNotificationSettings', params, done, getRollbackParamsOnFail, always);
  }
}
