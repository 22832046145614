/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class MwDsnpConfirmFailureComponent extends Component {
  @tracked promisesToWaitFor = [];

  constructor() {
    super(...arguments);

    this.imagePromise = new Promise((resolve) => {
      // preload background photo to make rendering smoother
      let img = new Image();
      img.src = '/assets/images/falling-img.png';
      img.onload = () => {
        if (this.isDestroyed || this.isDestroying) return;
        resolve();
      };
    });

    this.promisesToWaitFor = this.args.promisesToWaitFor?.concat(this.imagePromise);
  }

  get canTryAgain() {
    return ~['expired', 'tokenConsumed'].indexOf(this.args.failureReason) && this.args.retryRedirect;
  }
}
