import { Mp3Encoder, WavHeader } from 'lamejs';

export const bufferToMp3 = function (aBuffer) {
  function setUint16(data) {
    btwView.setUint16(btwPos, data, true);
    btwPos += 2;
  }

  function setUint32(data) {
    btwView.setUint32(btwPos, data, true);
    btwPos += 4;
  }

  function _wavToMp3(channels, sampleRate, samples) {
    const buffer = [];
    const mp3enc = new Mp3Encoder(channels, sampleRate, 192);
    const samplesPerFrame = 1152;
    let remaining = samples.length;

    for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
      let mono = samples.subarray(i, i + samplesPerFrame);
      let mp3buf = mp3enc.encodeBuffer(mono);
      if (mp3buf.length > 0) {
        buffer.push(new Int8Array(mp3buf));
      }
      remaining -= samplesPerFrame;
    }
    let d = mp3enc.flush();
    if (d.length > 0) {
      buffer.push(new Int8Array(d));
    }

    const mp3Blob = new Blob(buffer, {
      type: 'audio/mp3',
    });

    return mp3Blob;
  }

  // we have hardcoded 1 channel because of issues with Firefox SG-32202
  // 2 channels are for professional use which we do not expect to be a common case
  const numOfChan = 1; // aBuffer.numberOfChannels;
  const btwLength = aBuffer.length * numOfChan * 2 + 44;
  const btwArrBuff = new ArrayBuffer(btwLength);
  const btwView = new DataView(btwArrBuff);
  const btwChnls = [];
  let btwIndex;
  let btwSample;
  let btwOffset = 0;
  let btwPos = 0;

  setUint32(0x46464952); // "RIFF"
  setUint32(btwLength - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"
  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(aBuffer.sampleRate);
  setUint32(aBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit
  setUint32(0x61746164); // "data" - chunk
  setUint32(btwLength - btwPos - 4); // chunk length

  for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) btwChnls.push(aBuffer.getChannelData(btwIndex));

  while (btwPos < btwLength) {
    for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
      btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset]));
      btwSample = (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0;
      btwView.setInt16(btwPos, btwSample, true);
      btwPos += 2;
    }
    btwOffset++;
  }

  const wavHdr = WavHeader.readHeader(new DataView(btwArrBuff));
  const wavSamples = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);

  return _wavToMp3(wavHdr.channels, wavHdr.sampleRate, wavSamples);
};
