import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

var model = createDisplayEditServerModel([
  'text',
  'currentCity',
  'job',
  'company',
  'college',
  'highSchool',
  'relationshipStatus',
  'interests',
  'email',
  'phone',
]).extend({
  language: attr('string'),
});

model.reopenClass({
  resourceName: 'profile',
});

export default model;
