import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { toMillis } from 'mewe/utils/datetime-utils';

export default buildHelper(function (params, opts) {
  let tz = CurrentUserStore.getState().get('timezone'),
    locale = CurrentUserStore.getState().get('jsLocale');

  let date = params[0];

  if (!date || date === 'N/A' || date === __('N/A')) return date;

  date = new Date(toMillis(date));

  if (opts.iso) {
    date = date.toISOString();
  } else if (opts.year || opts.month || opts.day || opts.weekday) {
    date = date.toLocaleDateString(locale, { timeZone: tz, ...opts });
  } else {
    date = date.toLocaleTimeString(locale, { timeZone: tz, ...opts });
  }

  return htmlSafe(date);
});
