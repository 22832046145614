import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
export default buildHelper(function (value) {
  let link = value.join('');

  if (!link) return '';

  if (link.indexOf('http') !== 0) link = 'https://' + link;

  return htmlSafe(link);
});
