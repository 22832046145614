import { getOwner } from '@ember/application';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import Scrolling from 'mewe/utils/scrolling-utils';
import PageAnalyticsBase from '../app-page-statistics';

export default class AppPageStatisticsIndex extends PageAnalyticsBase {
  routeName = 'route:app.publicid.statistics.index';

  @reads('args.model.page') page;
  @reads('args.model') model;

  @action
  onInsert() {
    this.scrolling = Scrolling();

    if (this.model.feed.nextPage) {
      this.bindScrolling();
    }

    addObserver(this, 'model.feed.nextPage', this.bindScrolling);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    removeObserver(this, 'model.feed.nextPage', this.bindScrolling);
  }

  bindScrolling() {
    this.scrolling.bindScrollDown(() => this.loadMorePosts(), 1200);
  }

  @computed('dateRanges.length')
  get dateRangeWeek() {
    if (this.dateRanges.length) return this.dateRanges[0];
  }

  @computed('dateRanges.length')
  get dateRangeMonth() {
    if (this.dateRanges.length) return this.dateRanges[1];
  }

  @computed('model.overview.engagement.total.count')
  get summaryItems() {
    return [
      {
        title: __('New Page Followers'),
        count: this.model.overview.newFollowers?.count || 0,
        change: this.model.overview.newFollowers?.change || 0,
      },
      {
        title: __('Post Engagements'),
        count: this.model.overview.engagement?.total.count || 0,
        change: this.model.overview.engagement?.total.change || 0,
      },
    ];
  }

  @action
  loadMorePosts() {
    const route = getOwner(this).lookup(this.routeName);
    if (route) route.send('loadMorePosts');
  }
}
