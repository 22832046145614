import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

export default class MwHomeCardCarouselComponent extends Component {

  get cardListStyle() {
    const isRtl = document.dir;
    let style = '';

    if (this.args.cardsCount) {
      style += `width: calc(100% * ${this.args.cardsCount});`;
      style += `transform: translateX(calc(${isRtl ? '' : '-'}${this.args.stepNumber} * calc(100% / ${this.args.cardsCount})));`;
    }

    return htmlSafe(style);
  }
}
