import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import AccountApi from 'mewe/api/account-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { getQueryStringParams, emailRegex } from 'mewe/shared/utils';
import { trimAndLower } from 'mewe/utils/miscellaneous-utils';
import config from 'mewe/config';
import Session from 'mewe/shared/session';

export default class MwSiwaIdentifier extends Component {
  @service authentication;
  @service dynamicDialogs;
  @service router;

  challengeOrigin = 'login';
  arkoseKey = config.arkoseKeyLog;
  hcaptchaSiteKey = config.hcaptcha.login;
  hcaptchaElemClass = 'h-captcha_login';
  challengeToken;
  challengeProvider;
  onChallengeFailed;

  @tracked afterChallengeCallback;
  @tracked challengeReady;

  @tracked stepNumber;
  @tracked error;
  @tracked isRedirectingSiwa;
  @tracked promisesToWaitFor;
  @tracked resendTimer = 0;

  @tracked loginEmail = '';
  @tracked invalidLoginEmail = true;
  @tracked loginPhoneCode = '';
  @tracked invalidLoginPhone = true;
  @tracked newEmail = '';
  @tracked invalidNewEmail = true;
  @tracked newEmailTaken = false;
  @tracked invalidNewPhone = true;
  @tracked newPhoneTaken = false;
  @tracked smsCode = '';
  @tracked password = '';
  @tracked passwordError = false;
  @tracked isEmailFlow = true;
  @tracked smsCodeError = false;
  @tracked isCheckingSmsCode = false;
  @tracked isConfirmSmsCodeInProgress = false;
  @tracked isIdentifierSubmitted = false;
  @tracked isNewIdentifierSubmitted = false;
  @tracked loginRequestInProgress = false;

  minPasswordLength = 6;

  constructor() {
    super(...arguments);

    this.urlParams = getQueryStringParams();

    const promise = Session.isAuthenticated();
    this.promisesToWaitFor = [promise];

    promise.then(({ isAuthenticated }) => {
      // if there is any user logged in then redirect to app and don't show this view
      if (isAuthenticated) {
        window.location.href = '/';
      } else {
        // emailUpdated - user is redirected here from email verification process
        if (this.urlParams.emailUpdated) {
          this.stepNumber = 3;
        } else {
          this.stepNumber = 0;
        }
      }
    });
  }

  get isSubmitIdentifierDisabled() {
    const invalidIdentifier = this.isEmailFlow ? this.invalidLoginEmail : this.invalidLoginPhone;
    return !this.challengeReady || this.loginRequestInProgress || invalidIdentifier || this.invalidPassword;
  }

  get loginEmailValue() {
    return trimAndLower(this.loginEmail);
  }

  get newEmailValue() {
    return trimAndLower(this.newEmail);
  }

  get showLoginEmailError() {
    return this.isIdentifierSubmitted && this.invalidLoginEmail;
  }

  get showNewEmailError() {
    return this.isNewIdentifierSubmitted && (this.invalidNewEmail || this.newEmailTaken);
  }

  get showBackArrow() {
    return this.stepNumber === 2;
  }

  @action
  loginEmailUpdated() {
    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    const isValid = emailRegex.test(this.loginEmailValue);
    this.invalidLoginEmail = !isValid;
  }

  @action
  newEmailUpdated() {
    // reset submitted state on any change
    this.isNewIdentifierSubmitted = false;
    const isValid = emailRegex.test(this.newEmailValue);
    this.invalidNewEmail = !isValid;
    this.newEmailTaken = false;
  }

  get showLoginPhoneError() {
    return this.isIdentifierSubmitted && this.invalidLoginPhone;
  }

  get showNewPhoneError() {
    return this.isNewIdentifierSubmitted && this.invalidNewPhone;
  }

  @action
  loginPhoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    this.loginPhoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.loginPhoneNumber = number.replace(this.loginPhoneCode, '');
    this.invalidLoginPhone = !params.isValidNumber;
  }

  @action
  newPhoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    // reset submitted state on any change
    this.isNewIdentifierSubmitted = false;

    this.newPhoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.newPhoneNumber = number.replace(this.newPhoneCode, '');
    this.invalidNewPhone = !params.isValidNumber;
  }

  get passwordValue() {
    return this.password.trim();
  }

  get invalidPassword() {
    return this.passwordValue.length < this.minPasswordLength;
  }

  get showPasswordError() {
    return this.isIdentifierSubmitted && (this.invalidPassword || this.passwordError);
  }

  get isNewIdentifierValid() {
    return this.isEmailFlow ? !this.invalidNewEmail : !this.invalidNewPhone;
  }

  get reqOptions() {
    return {
      traceId: this.urlParams.trc,
    };
  }

  @action
  passwordUpdated() {
    this.passwordError = false;
    // clean also identifier errors - if user sbmitted incorrect password we show
    // error also on identifier fields, after updating password we should unlock submitting
    this.invalidLoginEmail = false;
    this.invalidLoginPhone = false;
  }

  @action
  smsCodeUpdated() {
    this.smsCodeError = false;
  }

  @action
  resendSMSCode() {
    this.confirmNewIdentifier(true);
    this.smsCodeError = false;
  }

  @action
  toggleEmailOrPhone() {
    this.isEmailFlow = !this.isEmailFlow;
  }

  @action
  stepBack() {
    this.invalidNewEmail = false;
    this.invalidNewPhone = false;
    this.stepNumber--;
  }

  @action
  doPasswordLogin(e) {
    // prevent default form submittion
    e.preventDefault();

    this.isIdentifierSubmitted = true;
    this.loginRequestInProgress = true;

    this.afterChallengeCallback = (challengeToken, provider, onChallengeFailed) => {
      this.onChallengeFailed = onChallengeFailed;

      const loginParams = {
        session_token: challengeToken,
        challenge_provider: provider,
        password: this.passwordValue,
      };

      if (this.isEmailFlow) {
        loginParams.username = this.loginEmailValue;
      } else {
        loginParams.phoneNumber = this.loginPhoneCode + this.loginPhoneNumber;
      }

      PublicPagesApi.siwaFallbackLogin(loginParams, this.reqOptions)
        .then((res) => {
          this.loginToken = res.token;
          this.stepNumber = 1;
        })
        .catch((err) => {
          if (err.status === 401) {
            this.passwordError = true;
            this.invalidLoginEmail = true;
            this.invalidLoginPhone = true;
          } else {
            FunctionalUtils.message(
              __('You already have your Web3 account and cannot use this method to login. Please go to the <u id=\"error-action\">login page.</u>'),
              'error',
              () => window.location.href = '/login',
              { duration: 10000 }
            );
          }
        })
        .finally(() => {
          this.loginRequestInProgress = false;
        });

    };
  }

  @action
  confirmNewIdentifier(isResendAction) {
    const newIdentifier = this.isEmailFlow ? this.newEmailValue : (this.newPhoneCode + this.newPhoneNumber);

    if (isResendAction === true) {
      this.confirmNewIdentiferFn();
    } else {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Important notice'),
        okButtonText: __(`Confirm`),
        cancelButtonText: __('Cancel'),
        message: __(
          `From now on you will only be able to use {identifier} to log in to MeWe. All other emails and/or phone numbers will be removed from your account.`,
          { identifier: newIdentifier }
        ),
        onConfirm: () => this.confirmNewIdentiferFn(),
        onClose: () => {},
      });
    }
  }

  confirmNewIdentiferFn() {
    this.isNewIdentifierSubmitted = true;
    this.isNewIdentifierInProgress = true;

    const newIdentifierCallback = () => {
      this.setResendTimer();
      this.stepNumber = 2;
    };
    const newIdentifierFailback = (err) => {
      if (err?.data?.errorCode === 109) {
        this.newEmailTaken = true;
        return;
      }

      if (err?.status === 404 || err?.status === 420) {
        FunctionalUtils.error(__('For security reasons we need you to log in again.'));
        later(() => {
          window.location.reload();
        }, 3000);
        return;
      }

      FunctionalUtils.showDefaultErrorMessage();
    };
    const newIdentifierFinished = () => {
      this.isNewIdentifierInProgress = false;
    };

    if (this.isEmailFlow) {
      if (!this.invalidNewEmail) {
        this.isNewIdentifierInProgress = true;

        const params = {
          token: this.loginToken,
          toAdd: this.newEmailValue,
        };

        AccountApi.setSiwaFallbackEmail(params, this.reqOptions)
        .then(newIdentifierCallback)
        .catch(newIdentifierFailback)
        .finally(newIdentifierFinished);
      }
    } else {
      if (!this.invalidNewPhone) {
        this.isNewIdentifierInProgress = true;

        AccountApi.sendSiwaFallbackSmsCode({
          token: this.loginToken,
          countryCode: this.newPhoneCode,
          phone: this.newPhoneNumber,
        }, this.reqOptions)
        .then(data => {
          this.tmpPhoneId = data.tmpPhoneId;
          newIdentifierCallback();
        })
        .catch(error => {
          if (error.status === 400) {
            if (error.data.errorCode === 115) {
              this.invalidNewPhone = true;
            } else if (error.data.alreadyTaken) {
              this.newPhoneTaken = true;
            }
          } else {
            FunctionalUtils.showDefaultErrorMessage();
          }
        })
        .finally(newIdentifierFinished);
      }
    }
  }

  get isSmsCodeValid() {
    return this.smsCode.length > 5;
  }

  get isConfirmSmsCodeDisabled() {
    return this.isSmsCodeValid || this.isConfirmSmsCodeInProgress;
  }

  @action
  confirmSmsCode() {
    this.isConfirmSmsCodeInProgress = true;

    AccountApi.confirmSiwaFallbackSmsCode({
      token: this.loginToken,
      code: this.smsCode,
      tmpPhoneId: this.tmpPhoneId,
    }, this.reqOptions)
    .then(() => {
      this.stepNumber = 3;
    })
    .catch(() => {
      this.siwaCodeError
      FunctionalUtils.showDefaultErrorMessage();
    })
    .finally(() => {
      this.isConfirmSmsCodeInProgress = false;
    });
  }

  @action
  resendEmail() {
    this.confirmNewIdentifier(true);
  }

  setResendTimer() {
    // postpone enabling resend button, user should wait at least a moment for the email/sms
    this.resendTimer = 30;
    // interval to update timer every second by subtracting 1
    this.timerInterval = setInterval(() => {
      if (this.resendTimer > 0) {
        this.resendTimer--;
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  get resendTimerDisplay() {
    return this.resendTimer < 10 ? `0:0${this.resendTimer}` : `0:${this.resendTimer}`;
  }

  @action
  startSiwa() {
    this.isRedirectingSiwa = true;

    const startSiwaCallback = () => {
      this.isRedirectingSiwa = false;
    };

    this.authentication.startSiwa(startSiwaCallback);
  }

  @action
  challengeReadyCallback() {
    this.challengeReady = true;
  }

  @action
  goToLogin() {
    this.router.transitionTo('login');
  }

}