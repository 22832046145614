import { modifier } from 'ember-modifier';

import CurrentUserStore from 'mewe/stores/current-user-store';

let _timeoutValue = 500;
let _timeoutId = null;

export default modifier((element, [callback, selector], options) => {
  const elements = selector ? element.querySelectorAll(selector) : [element];

  if (!elements || !elements.length) return;
  if (typeof callback !== 'function') return;
  if (options.disabled) return;

  const mouseEnterFunc = (e) => {
    if (e.target.getAttribute('data-everyone')) return;

    let userId = e.target.getAttribute('data-userid');
    if (userId && userId.indexOf('id=') > -1) userId = userId.substr(3);

    if (userId && userId === CurrentUserStore.getState().get('id')) return;

    let tId = setTimeout(() => {
      callback(e.target);
      _timeoutId = null;
    }, _timeoutValue);

    _timeoutId = tId;
  };

  const mouseLeaveFunc = () => {
    if (_timeoutId) {
      clearTimeout(_timeoutId);
    }
  };

  const bindEvents = () => {
    elements.forEach((el) => {
      el.addEventListener('mouseenter', mouseEnterFunc);
      el.addEventListener('mouseleave', mouseLeaveFunc);
    });
  };

  const unbindEvents = () => {
    elements.forEach((el) => {
      el.removeEventListener('mouseenter', mouseEnterFunc);
      el.removeEventListener('mouseleave', mouseLeaveFunc);
    });

    if (_timeoutId) {
      clearTimeout(_timeoutId);
    }
  };

  bindEvents();

  return unbindEvents;
});
